import React from 'react';
import { useHistory } from 'react-router';

import shouldRedirectToWeekly from 'js/util/weekly-redirect';
import { useMountEffect } from 'js/util/custom-hooks';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';

export const DetermineRedirect: React.FC = () => {
  const history = useHistory();

  useMountEffect(() => {
    (async () => {
      const prevPathname = history.location.pathname;
      const shouldWeekly = await shouldRedirectToWeekly();
      const currPathname = history.location.pathname;
      // This checks if other logic was run to determine the path, which should always take priority
      if (prevPathname === currPathname) {
        history.push(shouldWeekly ? '/weekly' : '/home');
      }
    })();
  });

  return <SVGLoader />;
};
