import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { borders, margins, FlexColumn, Text, media, colors } from 'css/css';

import { useOutsideAlerter } from 'js/util/custom-hooks';
import { getIsMobile } from 'js/util/util';
import { SimpleModalWrap, useSimpleModal, WhiteCardHeader } from 'js/components/shared/MobileModal';
import { CadenceSelector } from 'js/components/autopilot/components/CadenceSelector';
import { AutopilotHeaders, CadenceType, CadenceTypes } from 'js/components/autopilot/constants';
import { useUser, useUserAvailability, useUserContextProvider } from 'js/providers/UserProvider';
import { postAutopilotCadence } from 'js/util/api';
import { useNotifContext } from 'js/util/notif-context';
import { apiFailure } from 'js/util/strings';
import { SettingsSection } from 'js/components/settings/components/shared-components/EditableSection';

import { SectionsTitles } from '../../constants';

enum Steps {
  closed,
  selection,
}

export const MeetingFrequency = () => {
  const [selectedCadence, setSelectedCadence] = useState<CadenceType>(CadenceTypes.Weekly);

  const userAvailability = useUserAvailability();
  const { fetchUser, updateUserAvailability } = useUserContextProvider();
  const { showNotif } = useNotifContext();
  const user = useUser();

  const [step, setStep] = useState(Steps.closed);
  const isMobile = getIsMobile();
  const isEditButtonVisible =
    !!userAvailability?.availability_autopilot?.autopilot &&
    !user.autopilot_paused_until &&
    (isMobile || step === Steps.closed);

  const { openModal, closeModal, wrapperProps } = useSimpleModal();

  const containerRef = useRef<HTMLInputElement>(null);
  useOutsideAlerter(containerRef, () => setStep(Steps.closed));

  useEffect(() => {
    if (userAvailability?.autopilot_cadence) {
      const cadenceType = Object.values(CadenceTypes).find(
        type => type.value === userAvailability?.autopilot_cadence,
      );

      if (cadenceType) {
        setSelectedCadence(cadenceType);
      }
    }
  }, [userAvailability]);

  useEffect(() => {
    if (userAvailability?.autopilot_cadence) {
      const cadenceType = Object.values(CadenceTypes).find(
        type => type.value === userAvailability?.autopilot_cadence,
      );

      if (cadenceType) {
        setSelectedCadence(cadenceType);
      }
    }
  }, [userAvailability]);

  const handleEditClick = () => {
    if (!isEditButtonVisible) return;
    setStep(Steps.selection);
    openModal();
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const getSelectedCadenceText = () => {
    if (user.autopilot_paused_until) {
      return 'Paused';
    }
    const cadence = Object.values(CadenceTypes).find(
      option => option.value === selectedCadence.value,
    );
    return cadence?.title;
  };

  const submitCadence = async (cadence: CadenceType) => {
    const res = await postAutopilotCadence({ cadence: cadence.value });
    if (res.ok) {
      await Promise.all([fetchUser(), updateUserAvailability()]);
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }

    if (isMobile) {
      handleCloseModal();
    } else {
      setStep(Steps.closed);
    }
  };

  return (
    <Container ref={containerRef}>
      <SettingsSection
        onClick={handleEditClick}
        headingTitle={SectionsTitles.MEETINGS_FREQUENCY}
        selectedOptions={getSelectedCadenceText()}
        isVisible={isEditButtonVisible}
      />
      {step === Steps.selection &&
        (!isMobile ? (
          <SelectMenu role="listbox">
            <CadenceSelector onClick={submitCadence} />
          </SelectMenu>
        ) : (
          <SimpleModalWrap {...wrapperProps} isAboveNavbar noAction>
            <WhiteCardHeader title="Meeting frequency" onClose={handleCloseModal} />
            <Text style={{ padding: margins.size3, paddingTop: 0, textAlign: 'center' }}>
              {AutopilotHeaders.Cadence.title}
            </Text>
            <CadenceSelector onClick={submitCadence} />
          </SimpleModalWrap>
        ))}
    </Container>
  );
};

export const Container = styled(FlexColumn)`
  align-items: flex-start;
  position: relative;
  ${media.mobile} {
    margin: 0;
    border: none;
  }
`;

export const SelectMenu = styled(FlexColumn)<{ $shouldAddPadding?: boolean }>`
  position: absolute;
  left: 0;
  top: ${margins.size4};
  background-color: ${colors.whiteMain};
  width: 100%;
  margin: 0px;
  border: ${borders.variation};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  z-index: 2;
  ${p => p.$shouldAddPadding && `padding: ${margins.size3};`};
`;
