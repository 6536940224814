import Bowser from 'bowser';
import SVG from 'react-inlinesvg';
import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

import { FlexColumn, Heading1, Heading2, margins, Text, media, colors } from 'css/css';

import { Modal } from 'js/components/shared/NewModal';
import { useMediaDevicesContext } from 'js/components/callv2/contexts/MediaDevicesContext';
import { useUIStateContext } from 'js/components/callv2/contexts/UIStateContext';

import videoOffIcon from 'img/call/video-off.svg';
import audioOffIcon from 'img/call/microphone-off.svg';

export const DeviceInUseModal: React.FC = () => {
  const { setShowDeviceInUseModal, showDeviceInUseModal } = useUIStateContext();
  const { camError } = useMediaDevicesContext();

  const camInUse = camError === 'in-use';

  const handleModalClose = () => {
    setShowDeviceInUseModal(false);
  };

  const browser = useMemo(() => Bowser.parse(navigator.userAgent), []);

  return (
    <Modal showModal={showDeviceInUseModal} handleClose={handleModalClose}>
      <FlexColumn alignItems="center">
        {camInUse ? (
          <StyledIcon alt="" aria-hidden="true" src={videoOffIcon} />
        ) : (
          <div style={{ display: 'flex' }}>
            <StyledIcon alt="" aria-hidden="true" src={videoOffIcon} />
            <div style={{ margin: `0 ${margins.size2}` }} />
            <StyledIcon alt="" aria-hidden="true" src={audioOffIcon} />
          </div>
        )}
        <Heading1 style={{ margin: `${margins.size4} 0`, textAlign: 'center' }}>
          {camInUse
            ? 'Another app is already using your camera'
            : 'Another app is already using your camera or microphone'}
        </Heading1>
        <Heading2 style={{ marginBottom: margins.size4 }}>
          {camInUse
            ? 'Lunchclub needs to use your camera'
            : 'Lunchclub needs to use your camera or microphone'}
        </Heading2>
        <Text style={{ marginBottom: margins.size4 }}>
          {browser.browser.name === 'Firefox'
            ? camInUse
              ? 'Another app is using your camera. Quit that app, close your browser, and try joining this call again.'
              : 'Another app is using your camera or microphone. Quit that app, close your browser, and try joining this call again.'
            : camInUse
            ? 'Another app is using your camera. Quit that app and reload this call to use your camera for this call.'
            : 'Another app is using your camera or microphone. Quit that app and reload this call to use your camera and microphone for this call.'}
        </Text>
      </FlexColumn>
    </Modal>
  );
};

const StyledIcon = styled(SVG)`
  width: ${margins.size6};
  height: ${margins.size6};
  margin: ${margins.size4} 0;
  color: ${colors.blackMain};

  ${media.mobile} {
    width: ${margins.size5};
    height: ${margins.size5};
    margin: ${margins.size2} 0;
  }
`;
