/* eslint-disable no-unused-expressions */

import {
  DailyEventObjectParticipant,
  DailyEventObjectTrack,
  DailyParticipant,
  DailyParticipantUpdateOptions,
} from '@daily-co/daily-js';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import { sortByKey } from 'js/components/callv2/utils/sorting';

import { useCallV2Context } from '../CallContext';
import { useParticipantsContext } from '../ParticipantsContext';
import { isLocalId, isScreenId } from '../ParticipantsContext/utils';

import { initialTracksState, tracksReducer } from './tracksState';
import { AudioTracks, VideoTracks } from './types';

/**
 * Maximum amount of concurrently subscribed most recent speakers.
 */
const MAX_RECENT_SPEAKER_COUNT = 6;
/**
 * Threshold up to which all videos will be subscribed.
 * If the remote participant count passes this threshold,
 * cam subscriptions are defined by UI view modes.
 */
const SUBSCRIBE_ALL_VIDEO_THRESHOLD = 9;

interface ContextValue {
  audioTracks: AudioTracks;
  resumeVideoTrack(id: string): void;
  updateCamSubscriptions(ids: string[], pausedIds?: string[]): void;
  videoTracks: VideoTracks;
}

// eslint-disable-next-line no-console
const noop = () => console.error('No parent TracksContextProvider found!');

const TracksContext = createContext<ContextValue>({
  audioTracks: {},
  resumeVideoTrack: noop,
  updateCamSubscriptions: noop,
  videoTracks: {},
});

export const TracksContextProvider: React.FC = ({ children }) => {
  const { daily } = useCallV2Context();
  const { participants } = useParticipantsContext();
  const [state, dispatch] = useReducer(tracksReducer, initialTracksState);

  const recentSpeakerIds = useMemo(
    () =>
      participants
        .filter(p => Boolean(p.lastActiveDate) && !p.isLocal)
        .sort((a, b) => sortByKey(a, b, 'lastActiveDate'))
        .slice(-MAX_RECENT_SPEAKER_COUNT)
        .map(p => p.id)
        .reverse(),
    [participants],
  );

  const pauseVideoTrack = useCallback((id: string) => {
    /**
     * Ignore undefined, local or screenshare.
     */
    if (!id || isLocalId(id) || isScreenId(id)) return;
    if (!rtcpeers.soup.implementationIsAcceptingCalls) {
      return;
    }
    const consumer = rtcpeers.soup?.findConsumerForTrack(id, 'cam-video');
    if (!consumer) return;
    rtcpeers.soup?.pauseConsumer(consumer);
  }, []);

  const resumeVideoTrack = useCallback(
    (id: string) => {
      /**
       * Ignore undefined, local or screenshare.
       */
      if (!id || isLocalId(id) || isScreenId(id)) return;

      const videoTrack = daily?.participants()?.[id]?.tracks?.video;
      if (!videoTrack?.subscribed) {
        daily?.updateParticipant(id, {
          setSubscribedTracks: true,
        });
        return;
      }
      if (!rtcpeers.soup.implementationIsAcceptingCalls) {
        return;
      }
      const consumer = rtcpeers.soup?.findConsumerForTrack(id, 'cam-video');
      if (!consumer) return;
      rtcpeers.soup?.resumeConsumer(consumer);
    },
    [daily],
  );

  const remoteParticipantIds = useMemo(() => participants.filter(p => !p.isLocal).map(p => p.id), [
    participants,
  ]);

  /**
   * Updates cam subscriptions based on passed ids.
   *
   * @param ids Array of ids to subscribe to, all others will be unsubscribed.
   * @param pausedIds Array of ids that should be subscribed, but paused.
   */
  const updateCamSubscriptions = useCallback(
    (ids: string[], pausedIds: string[] = []) => {
      if (!daily) return;
      const subscribedIds =
        remoteParticipantIds.length <= SUBSCRIBE_ALL_VIDEO_THRESHOLD
          ? [...remoteParticipantIds]
          : [...ids, ...recentSpeakerIds];
      const updates: Record<string, DailyParticipantUpdateOptions> = remoteParticipantIds.reduce(
        (u, id) => {
          const shouldSubscribe = subscribedIds.includes(id);
          const isSubscribed = daily.participants()?.[id]?.tracks?.video?.subscribed;
          if (isLocalId(id) || isScreenId(id) || (shouldSubscribe && isSubscribed)) return u;

          return {
            ...u,
            [id]: {
              setSubscribedTracks: {
                audio: true,
                screenAudio: true,
                screenVideo: true,
                video: shouldSubscribe,
              },
            },
          };
        },
        {},
      );

      dispatch({
        type: 'UPDATE_SUBSCRIPTIONS',
        subscriptions: {
          video: subscribedIds.reduce(
            (v, id) => ({
              ...v,
              [id]: {
                id,
                paused: pausedIds.includes(id) || !ids.includes(id),
              },
            }),
            {},
          ),
        },
      });

      ids
        .filter(id => !pausedIds.includes(id))
        .forEach(id => {
          const p = daily.participants()?.[id];
          if (p?.tracks?.video?.subscribed) {
            resumeVideoTrack(id);
          }
        });

      daily.updateParticipants(updates);
    },
    [daily, remoteParticipantIds, recentSpeakerIds, resumeVideoTrack],
  );

  useEffect(() => {
    if (!daily) return () => undefined;

    const trackStoppedQueue: [DailyParticipant, MediaStreamTrack][] = [];

    const handleTrackStarted = (event?: DailyEventObjectTrack) => {
      if (event && event.participant) {
        if (state.subscriptions.video?.[event.participant.session_id]?.paused) {
          pauseVideoTrack(event.participant.session_id);
        }

        /**
         * If track for participant was recently stopped, remove it from queue,
         * so we don't run into a stale state.
         */
        const stoppingIdx = trackStoppedQueue.findIndex(
          ([p, t]) =>
            p.session_id === event.participant?.session_id && t.kind === event?.track.kind,
        );
        if (stoppingIdx >= 0) {
          trackStoppedQueue.splice(stoppingIdx, 1);
        }

        dispatch({
          type: 'TRACK_STARTED',
          participant: event.participant,
          track: event.track,
        });
      }
    };

    const trackStoppedBatchInterval = setInterval(() => {
      if (!trackStoppedQueue.length) return;
      dispatch({
        type: 'TRACKS_STOPPED',
        items: trackStoppedQueue.splice(0, trackStoppedQueue.length),
      });
    }, 3000);

    const handleTrackStopped = (event?: DailyEventObjectTrack) => {
      if (event && event.participant) {
        trackStoppedQueue.push([event.participant, event.track]);
      }
    };

    const handleParticipantLeft = (event?: DailyEventObjectParticipant) => {
      if (event) {
        dispatch({
          type: 'REMOVE_TRACKS',
          participant: event.participant,
        });
      }
    };

    const joinedSubscriptionQueue: string[] = [];

    const handleParticipantJoined = (event?: DailyEventObjectParticipant) => {
      if (event) {
        joinedSubscriptionQueue.push(event.participant.session_id);
      }
    };

    const joinBatchInterval = setInterval(() => {
      if (!joinedSubscriptionQueue.length) return;
      const ids = joinedSubscriptionQueue.splice(0);
      const dailyParticipants = daily.participants();
      const updates = ids.reduce<Record<string, DailyParticipantUpdateOptions>>((o, id) => {
        const { subscribed } = dailyParticipants?.[id]?.tracks?.audio;
        if (!subscribed) {
          return {
            ...o,
            [id]: {
              setSubscribedTracks: {
                audio: true,
                screenAudio: true,
                screenVideo: true,
              },
            },
          };
        }
        if (rtcpeers?.getCurrentType?.() === 'peer-to-peer') {
          return {
            ...o,
            [id]: {
              setSubscribedTracks: true,
            },
          };
        }
        return o;
      }, {});
      daily.updateParticipants(updates);
    }, 100);

    daily.on('track-started', handleTrackStarted);
    daily.on('track-stopped', handleTrackStopped);
    daily.on('participant-joined', handleParticipantJoined);
    daily.on('participant-left', handleParticipantLeft);
    return () => {
      clearInterval(joinBatchInterval);
      clearInterval(trackStoppedBatchInterval);
      daily.off('track-started', handleTrackStarted);
      daily.off('track-stopped', handleTrackStopped);
      daily.off('participant-joined', handleParticipantJoined);
      daily.off('participant-left', handleParticipantLeft);
    };
  }, [daily, pauseVideoTrack, state.subscriptions.video]);

  useEffect(() => {
    Object.values(state.subscriptions.video).forEach(({ id, paused }) => {
      if (paused) {
        pauseVideoTrack(id);
      }
    });
  }, [pauseVideoTrack, state.subscriptions.video]);

  return (
    <TracksContext.Provider
      value={{
        audioTracks: state.audioTracks,
        resumeVideoTrack,
        updateCamSubscriptions,
        videoTracks: state.videoTracks,
      }}
    >
      {children}
    </TracksContext.Provider>
  );
};

export const useTracksContext = () => useContext(TracksContext);
