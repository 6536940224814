/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import styled from 'styled-components/macro';

import { Heading1, margins } from 'css/css';

import { useResource } from 'js/util/use-resource';
import { useNotifContext } from 'js/util/notif-context';
import { APIFailure } from 'js/components/shared/APIFailure';
import { GreyPage } from 'js/components/shared/page-wrappers';
import shouldRedirectToWeekly from 'js/util/weekly-redirect';
import { DynamicForm } from 'js/components/shared/DynamicForm';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { Checkbox } from 'js/components/shared/Checkbox';
import {
  DarkCardBackground,
  WhiteCard,
  WhiteCardAction,
  WhiteCardHeader,
  WhitePrompt,
  WhiteSubprompt,
} from 'js/components/shared/MobileModal';
import { useDataContext } from 'js/providers/DataContextProvider';
import { useUserContextProvider } from 'js/providers/UserProvider';
import { useDidUpdateEffect, useMountEffect } from 'js/util/custom-hooks';
import {
  getMatchInfo,
  postMatchFeedback,
  getFeedbackV2,
  getAvailability,
  postAvailability,
} from 'js/util/api';
import { feedbackPage } from 'js/util/strings';
import { getParameterByName, addParameterToURL, getIsMobile, getIsIOSApp } from 'js/util/util';

import { FeedbackTextbox } from './FeedbackTextbox';
import { HiddenTextbox } from './HiddenTextbox';
import { TextMessage } from './TextMessage';
import { Question } from './Question';
import { FirstQuestion } from './FirstQuestion';
import { MeetQuestion } from './MeetQuestion';

enum InitialQuestions {
  Star = 0,
  Meet = -1,
}

const QUESTION_TYPES = {
  MESSAGE: 0,
  MCQ: 1,
  EMOJI: 2,
  FREEFORM: 3,
  COLLAPSABLE_FREEFORM: 4,
};
const MAX_TEXTFORM_LENGTH = 500;
const MAX_OPTION_INPUT_LENGTH = 250;

export const FeedbackForm: React.FC = () => {
  const { fetchUser, userAvailability, updateUserAvailability } = useUserContextProvider();

  const { allActiveLocales } = useDataContext();
  const history = useHistory();
  const { showNotif } = useNotifContext();

  const [match, setMatch] = useState(null);
  const [matchFirstName, setMatchFirstName] = useState('');
  const [matchFullName, setMatchFullName] = useState('');
  const [matchProfilePicture, setMatchProfilePicture] = useState('');
  const [questionIds, setQuestionIds] = useState({});
  const [questions, setQuestions] = useState({});
  const [isInPersonMatch, setIsInPersonMatch] = useState<boolean>();
  const [hasWantToMeetSimilar, setHasWantToMeetSimilar] = useState<boolean>();

  const [questionIdStack, setQuestionIdStack] = useState([0]);
  const [didMeet, setDidMeet] = useState(false);
  const [didPost, setDidPost] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [hadMeeting, setHadMeeting] = useState(null);
  const [starRating, setStarRating] = useState(null);
  const [responses, setResponses] = useState([]);
  const [wantToMeetSimilar, setWantToMeetSimilar] = useState<boolean | null>(null);

  const [textFeedback, setTextFeedback] = useState<string | null>(null);
  const [privateNote, setPrivateNote] = useState<string | null>(null);
  const [successStory, setSuccessStory] = useState<string | null>(null);
  const [shareStory, setShareStory] = useState<boolean>(true);

  const [optionInputs, setOptionInputs] = useState({});

  const [maxSessionFeedback, setMaxSessionFeedback] = useState(0);

  const [loading, setLoading] = useState(true);
  const [dontScroll, setDontScroll] = useState(false);
  const [apiError, setApiError] = useState(false);

  const [pastAvailability, setPastAvailability] = useState<any>({});
  const [resubmitAvailability, setResubmitAvailability] = useState(false);

  const [previousAppFeedback] = useResource<{ filled: boolean }>('discover/mobile/feedback');

  const fetchPastAvailability = async () => {
    const res = await getAvailability();
    if (res.status === 200) {
      const pastObject = res.getJson.last_week_availability;
      if (pastObject?.time_slots.length) setPastAvailability(pastObject);
    }
  };

  const fetchMatchInfo = async (feedbackCode: any) => {
    const res = await getMatchInfo(feedbackCode);
    if (res.status === 200) {
      setMatchProfilePicture(res.getJson.match.image);
      setMatchFirstName(res.getJson.match.first_name || '');
      setMatchFullName(
        `${res.getJson.match.first_name || ''} ${res.getJson.match.last_name || ''}`,
      );
    } else setApiError(true);
  };

  const fetchFeedbackQuestions = async (feedbackCode: any) => {
    const res = await getFeedbackV2(feedbackCode); // Despite this, returns V1.
    if (res.status === 200) {
      setQuestions(res.getJson.questions);
      setQuestionIds(res.getJson.question_ids);
      setIsInPersonMatch(res.getJson.is_in_person);
      setHasWantToMeetSimilar(res.getJson.has_want_to_meet_similar);
      if (res.getJson.prev_feedback) {
        const {
          success_story: prevSuccessStory,
          share_story: prevShareStory,
          text_feedback: prevTextFeedback,
          private_note: prevPrivateNote,
        } = res.getJson.prev_feedback;

        setSuccessStory(prevSuccessStory);
        setShareStory(prevShareStory == null || prevShareStory);
        setTextFeedback(prevTextFeedback);
        setPrivateNote(prevPrivateNote);
      }
    } else setApiError(true);
  };

  const pushNextPage = async () => {
    let maxStarRating = maxSessionFeedback;
    // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
    if (starRating && starRating > maxSessionFeedback) {
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      setMaxSessionFeedback(parseInt(starRating, 10));
      // @ts-ignore
      maxStarRating = starRating;
    }
    if (getParameterByName('ref') && getParameterByName('ref') !== 'call') {
      history.push(addParameterToURL('msf', maxStarRating, `/${getParameterByName('ref')}`));
    } else if (
      getIsIOSApp() &&
      starRating === 5 &&
      previousAppFeedback &&
      !previousAppFeedback.filled
    ) {
      fetchUser().then(() => history.push('/mobile-root/feedback'));
    } else if (starRating === 5 || resubmitAvailability) {
      fetchUser().then(() => history.push('/invite'));
    } else {
      const shouldWeekly = await shouldRedirectToWeekly();
      const otherPath = starRating === 4 ? '/invite' : '/home';
      history.push(shouldWeekly ? `/weekly?msf=${maxSessionFeedback}` : otherPath);
    }
  };

  useMountEffect(() => {
    const feedbackCode: any = getParameterByName('feedback_code');
    setMatch(feedbackCode);
    const sessionFeedback = getParameterByName('msf');
    if (sessionFeedback) {
      setMaxSessionFeedback(parseInt(sessionFeedback, 10));
    }

    const fixGhostReport = async () => {
      const resp = await postMatchFeedback({
        feedbackCode,
        didCommunicate: true,
      });
      if (resp.status === 200) {
        setTimeout(() => pushNextPage(), 3000);
      } else {
        setApiError(true);
      }
    };

    if (getParameterByName('didmeet')) {
      setDidMeet(true);
      fixGhostReport();
    }

    const fetchAllData = async () => {
      await Promise.all([
        fetchMatchInfo(feedbackCode),
        fetchFeedbackQuestions(feedbackCode),
        fetchPastAvailability(),
      ]);
      const rating = getParameterByName('rating');
      if (rating) {
        // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        setStarRating(parseInt(rating, 10));
        setDontScroll(true);
        // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
        setHadMeeting(true);
      }
      if (getParameterByName('noMeeting')) {
        setDontScroll(true);
        // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
        setHadMeeting(false);
      }
    };

    fetchAllData().then(() => setLoading(false));
  });

  const submitFeedback = async (noRedirect: any) => {
    if (submitting) return;
    setSubmitting(true);
    const filteredResponses = filterResponses(responses);
    const filteredOptionInputs = filterOptionInputs(optionInputs);
    const finalResponses = mergeResponsesAndOptionInputs(filteredResponses, filteredOptionInputs);

    if (resubmitAvailability) {
      const { time_slots: timeslots, locale } = pastAvailability;
      const res = await postAvailability({
        timeslots: timeslots.map((timeslot: string) =>
          moment(timeslot)
            .add(1, 'week')
            .format('YYYY-MM-DD HH:mm:ss'),
        ),
        locale,
        // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
        neighborhoods: [allActiveLocales.find(obj => obj.id === locale).videoNeighborhood],
        numberOfMeetings: timeslots.length,
      });
      await updateUserAvailability();
      if (res.status !== 200)
        showNotif({
          message: "Not enough Clubpoints to make last week's selection",
          level: 'error',
        });
    }
    // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
    if (starRating > maxSessionFeedback) {
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      setMaxSessionFeedback(parseInt(starRating, 10));
    }
    const resp = await postMatchFeedback({
      feedbackCode: match,
      hadMeeting,
      starRating,
      wantToMeetSimilar,
      responses: finalResponses,
      // @ts-ignore ts-migrate(2339) FIXME: Property 'length' does not exist on type 'never'.
      textFeedback: textFeedback?.length > 0 ? textFeedback : null,
      // @ts-ignore ts-migrate(2339) FIXME: Property 'length' does not exist on type 'never'.
      privateNote: privateNote?.length > 0 ? privateNote : null,
      successStory:
        // @ts-ignore ts-migrate(2339) FIXME: Property 'length' does not exist on type 'never'.
        successStory?.length > 0 && hadMeeting && starRating === 5 ? successStory : null,
      // @ts-ignore ts-migrate(2339) FIXME: Property 'length' does not exist on type 'never'.
      shareStory: successStory?.length > 0 && hadMeeting && starRating === 5 ? shareStory : null,
      isStarClickPost: !didPost,
    });
    setSubmitting(false);
    if (resp.status === 200) {
      setDidPost(true);
      if (noRedirect) return;
      pushNextPage();
    } else {
      setApiError(true);
    }
  };

  const getComponentStack = () => {
    const newQuestionComponentStack = questionIdStack.map(id => {
      // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const currQuestion = questions[id];
      const isLeaf = Boolean(
        id !== InitialQuestions.Star && id !== InitialQuestions.Meet && !currQuestion.options,
      );

      if (id === InitialQuestions.Star) {
        return {
          id: InitialQuestions.Star,
          isLeaf,
          component: (
            <FirstQuestion
              matchFirstName={matchFirstName}
              starRating={starRating}
              setStarRating={(rating: any) => {
                setStarRating(rating);
                // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
                setHadMeeting(true);
              }}
              setNoMeeting={() => {
                setStarRating(null);
                // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
                setHadMeeting(false);
              }}
              hadMeeting={hadMeeting}
            />
          ),
        };
      }
      if (id === InitialQuestions.Meet) {
        return {
          id: InitialQuestions.Meet,
          isLeaf,
          component: (
            <MeetQuestion
              matchFirstName={matchFirstName}
              response={wantToMeetSimilar}
              setResponse={setWantToMeetSimilar}
            />
          ),
        };
      }
      switch (currQuestion.question_type) {
        case QUESTION_TYPES.MESSAGE:
          return {
            id,
            isLeaf,
            component: (
              <TextMessage
                // @ts-ignore ts-migrate(2339) FIXME: Property 'GHOSTED' does not exist on type '{}'.
                ghosted={id === questionIds.GHOSTED}
                // @ts-ignore ts-migrate(2339) FIXME: Property 'GHOSTED' does not exist on type '{}'.
                guidelines={id === questionIds.GHOSTED || id === questionIds.BUSY_WEEK}
                matchFirstName={matchFirstName}
                header={currQuestion.text}
                body={currQuestion.body}
              />
            ),
          };
        case QUESTION_TYPES.FREEFORM:
          if (starRating === 5) {
            return {
              id,
              isLeaf: true,
              component: (
                <FeedbackTextbox
                  id={currQuestion.options[0].id}
                  value={successStory}
                  question={currQuestion.options[0].text}
                  setValue={setSuccessStory}
                  maxLength={MAX_TEXTFORM_LENGTH}
                  checkValue={shareStory}
                  setCheckValue={setShareStory}
                  displayCheck={starRating === 5}
                />
              ),
            };
          }
          return {
            id,
            isLeaf: true,
            component: (
              <FeedbackTextbox
                id={currQuestion.options[1].id}
                value={textFeedback}
                question={currQuestion.options[1].text}
                setValue={setTextFeedback}
                maxLength={MAX_TEXTFORM_LENGTH}
              />
            ),
          };
        default:
          return {
            id,
            isLeaf,
            component: (
              <Question
                emoji={currQuestion.question_type === QUESTION_TYPES.EMOJI}
                question={currQuestion}
                onClickAnswer={onClickAnswer}
                responses={responses}
                matchFirstName={matchFirstName}
                setDontScroll={setDontScroll}
                maxLength={MAX_OPTION_INPUT_LENGTH}
                onOptionInputChange={onOptionInputChange}
                // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                optionInputValue={optionInputs[id] ? optionInputs[id].inputValue : ''}
              />
            ),
          };
      }
    });
    if (
      newQuestionComponentStack[newQuestionComponentStack.length - 1].isLeaf &&
      newQuestionComponentStack[newQuestionComponentStack.length - 1].id !==
        // @ts-ignore ts-migrate(2339) FIXME: Property 'FREEFORM_SECTION' does not exist on type... Remove this comment to see the full error message
        questionIds.FREEFORM_SECTION &&
      starRating === 5
    ) {
      newQuestionComponentStack.push({
        // @ts-ignore ts-migrate(2339) FIXME: Property 'FREEFORM_SECTION' does not exist on type... Remove this comment to see the full error message
        id: questionIds.FREEFORM_SECTION,
        isLeaf: true,
        component: (
          <FeedbackTextbox
            // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            id={questions[questionIds.FREEFORM_SECTION].options[0].id}
            value={successStory}
            // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            question={questions[questionIds.FREEFORM_SECTION].options[0].text}
            setValue={setSuccessStory}
            maxLength={MAX_TEXTFORM_LENGTH}
            checkValue={shareStory}
            setCheckValue={setShareStory}
            displayCheck={starRating === 5}
          />
        ),
      });
    } else if (
      newQuestionComponentStack[newQuestionComponentStack.length - 1].isLeaf &&
      newQuestionComponentStack[newQuestionComponentStack.length - 1].id !==
        // @ts-ignore ts-migrate(2339) FIXME: Property 'FREEFORM_SECTION' does not exist on type... Remove this comment to see the full error message
        questionIds.FREEFORM_SECTION
    ) {
      newQuestionComponentStack.push({
        // @ts-ignore ts-migrate(2339) FIXME: Property 'FREEFORM_SECTION' does not exist on type... Remove this comment to see the full error message
        id: questionIds.FREEFORM_SECTION,
        isLeaf: true,
        component: (
          <FeedbackTextbox
            // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            id={questions[questionIds.FREEFORM_SECTION].options[1].id}
            value={textFeedback}
            // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            question={questions[questionIds.FREEFORM_SECTION].options[1].text}
            setValue={setTextFeedback}
            maxLength={MAX_TEXTFORM_LENGTH}
          />
        ),
      });
    }
    if (
      newQuestionComponentStack[newQuestionComponentStack.length - 1].isLeaf &&
      newQuestionComponentStack[newQuestionComponentStack.length - 1].id !==
        // @ts-ignore ts-migrate(2339) FIXME: Property 'COLLAPSABLE_FREEFORM_SECTION' does not e... Remove this comment to see the full error message
        questionIds.COLLAPSABLE_FREEFORM_SECTION &&
      hadMeeting
    ) {
      newQuestionComponentStack.push({
        // @ts-ignore ts-migrate(2339) FIXME: Property 'COLLAPSABLE_FREEFORM_SECTION' does not e... Remove this comment to see the full error message
        id: questionIds.COLLAPSABLE_FREEFORM_SECTION,
        isLeaf: true,
        component: (
          <HiddenTextbox
            // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            id={questions[questionIds.COLLAPSABLE_FREEFORM_SECTION].options[0].id}
            value={privateNote}
            // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            header={questions[questionIds.COLLAPSABLE_FREEFORM_SECTION].options[0].text}
            matchFirstName={matchFirstName}
            setValue={setPrivateNote}
            maxLength={MAX_TEXTFORM_LENGTH}
          />
        ),
      });
      if (starRating === 5) {
        newQuestionComponentStack.push({
          // @ts-ignore ts-migrate(2339) FIXME: Property 'COLLAPSABLE_FREEFORM_SECTION' does not e... Remove this comment to see the full error message
          id: questionIds.COLLAPSABLE_FREEFORM_SECTION,
          isLeaf: true,
          component: (
            <HiddenTextbox
              // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              id={questions[questionIds.COLLAPSABLE_FREEFORM_SECTION].options[1].id}
              value={textFeedback}
              // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              header={questions[questionIds.COLLAPSABLE_FREEFORM_SECTION].options[1].text}
              matchFirstName={matchFirstName}
              setValue={setTextFeedback}
              maxLength={MAX_TEXTFORM_LENGTH}
            />
          ),
        });
      }

      if (pastAvailability.time_slots?.length && !userAvailability?.has_signed_up_weekly) {
        const slots = pastAvailability.time_slots;

        const getTimesString = () => {
          if (slots.length > 1) {
            return `Sign me up for next week at the same times: ${slots
              .map((timeslot: string, i: number) => {
                if (i === slots.length - 1) return moment(timeslot).format('dddd ha.');
                return moment(timeslot).format('dddd ha,');
              })
              .join(' ')}`;
          }
          return `Sign me up for next week at the same time, ${moment(slots[0]).format(
            'dddd ha.',
          )}`;
        };

        newQuestionComponentStack.push({
          // @ts-ignore ts-migrate(2339) FIXME: Property 'COLLAPSABLE_FREEFORM_SECTION' does not e... Remove this comment to see the full error message
          id: questionIds.COLLAPSABLE_FREEFORM_SECTION,
          isLeaf: true,
          component: (
            <div style={{ marginTop: margins.size3, width: '100%' }}>
              <Checkbox
                checked={resubmitAvailability}
                setCheckValue={setResubmitAvailability}
                desc={getTimesString()}
              />
            </div>
          ),
        });
      }
    }
    return newQuestionComponentStack;
  };

  const canSubmit = () =>
    (!textFeedback || textFeedback?.length < MAX_TEXTFORM_LENGTH) &&
    (!privateNote || privateNote?.length < MAX_TEXTFORM_LENGTH) &&
    (!successStory || successStory?.length < MAX_TEXTFORM_LENGTH);

  const filterResponses = (r: any) =>
    r.filter((response: any) => questionIdStack.includes(response[0]));

  const filterOptionInputs = (optInputs: any) =>
    Object.keys(optInputs)
      .filter(
        qId =>
          questionIdStack.includes(parseInt(qId, 10)) &&
          optInputs[qId].inputValue.trim().length > 0,
      )
      .reduce((filteredOptInputs, qId) => {
        const filtered = { ...filteredOptInputs };
        // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        filtered[qId] = optInputs[qId];
        return filtered;
      }, {});

  const mergeResponsesAndOptionInputs = (resp: any, optInputs: any) => {
    const finalResponses: any = [];
    resp.forEach((response: any) => {
      if (
        optInputs &&
        Object.keys(optInputs).includes(response[0].toString()) &&
        response[1] === optInputs[response[0]].answerId
      ) {
        finalResponses.push([...response, optInputs[response[0]].inputValue.trim()]);
      } else {
        finalResponses.push([...response, null]);
      }
    });
    return finalResponses;
  };

  const onOptionInputChange = (questionId: any, answerId: any, inputValue: any) => {
    let finalInputValue = inputValue;
    if (inputValue.length > MAX_OPTION_INPUT_LENGTH + 1) {
      finalInputValue = inputValue.slice(0, MAX_OPTION_INPUT_LENGTH + 1);
    }
    const newOptionInputs = { ...optionInputs };
    // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    newOptionInputs[questionId] = { answerId, inputValue: finalInputValue };
    setOptionInputs(newOptionInputs);
  };

  const onClickAnswer = (questionId: any, answerId: any) => {
    let newResponses = [...responses];
    // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const question = questions[questionId];
    const answer = question.options.find((option: any) => option.id === answerId);
    const questionIndex = questionIdStack.indexOf(questionId);
    newResponses = newResponses.filter(response => response[0] !== questionId);
    // @ts-ignore ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
    newResponses.push([questionId, answerId]);
    clipQuestionIdStackAndAppend(questionIndex, answer.nextQuestionId);
    setResponses(newResponses);
  };

  const clipQuestionIdStackAndAppend = (questionIndex: any, nextQuestionId: any) => {
    const newQuestionIdStack = [...questionIdStack];
    if (
      newQuestionIdStack.length > questionIndex + 1 &&
      newQuestionIdStack[questionIndex + 1] !== nextQuestionId
    ) {
      newQuestionIdStack.splice(questionIndex + 1);
    }
    if (
      typeof nextQuestionId === 'number' &&
      newQuestionIdStack[questionIndex + 1] !== nextQuestionId
    ) {
      newQuestionIdStack.push(nextQuestionId);
    }
    // Also append following questions if they have been answered previously
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const existingResponse = responses.find(
        (response: any) => response[0] === newQuestionIdStack[newQuestionIdStack.length - 1],
      );
      if (existingResponse) {
        const question = questions[existingResponse[0]];
        // @ts-ignore
        const optionPicked = question.options.find((opt: any) => opt.id === existingResponse[1]);
        if (optionPicked.nextQuestionId) {
          newQuestionIdStack.push(optionPicked.nextQuestionId);
        } else break;
      } else break;
    }
    if (JSON.stringify(newQuestionIdStack) !== JSON.stringify(questionIdStack)) {
      setQuestionIdStack(newQuestionIdStack);
    }
  };

  useEffect(() => {
    if (dontScroll) {
      setDontScroll(false);
      return;
    }
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }, [questionIdStack]);

  const getNextQuestion = () => {
    // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
    if (starRating < 3) {
      // @ts-ignore ts-migrate(2339) FIXME: Property 'LOW_RATING_VIDEO' does not exist on type... Remove this comment to see the full error message
      return isInPersonMatch ? questionIds.LOW_RATING : questionIds.LOW_RATING_VIDEO;
    }
    // @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
    if (starRating < 5) {
      // @ts-ignore ts-migrate(2339) FIXME: Property 'MID_RATING_VIDEO' does not exist on type... Remove this comment to see the full error message
      return isInPersonMatch ? questionIds.MID_RATING : questionIds.MID_RATING_VIDEO;
    }

    return isInPersonMatch
      ? // @ts-ignore ts-migrate(2339) FIXME: Property 'HIGH_RATING_VIDEO' does not exist on typ... Remove this comment to see the full error message
        questionIds.WHICH_OBJECTIVE
      : // @ts-ignore ts-migrate(2339) FIXME: Property 'HIGH_RATING_VIDEO' does not exist on typ... Remove this comment to see the full error message
        questionIds.HIGH_RATING_VIDEO;
  };

  useDidUpdateEffect(() => {
    const nextQuestionId = getNextQuestion();
    clipQuestionIdStackAndAppend(1, nextQuestionId);
  }, [wantToMeetSimilar]);

  useEffect(() => {
    let nextQuestionId = null;
    if (hadMeeting === false) {
      // @ts-ignore ts-migrate(2339) FIXME: Property 'WHY_DIDNT_MEET' does not exist on type '... Remove this comment to see the full error message
      nextQuestionId = questionIds.WHY_DIDNT_MEET;
    } else if (starRating && hasWantToMeetSimilar) {
      nextQuestionId = InitialQuestions.Meet;
    } else if (starRating) {
      nextQuestionId = getNextQuestion();
    }
    clipQuestionIdStackAndAppend(0, nextQuestionId);
    // Submit on landing from prefilled email, to capture navigate away case
    if (hadMeeting !== null && !didPost) {
      submitFeedback(true);
    }
  }, [starRating, hadMeeting]);

  const canSkip = starRating || hadMeeting === false;

  const renderPage = () => {
    if (loading) return <SVGLoader />;

    if (didMeet) return <Heading1>{feedbackPage.didMeet}</Heading1>;

    if (!match || apiError) return <APIFailure />;

    return (
      <DynamicForm
        getComponentStack={getComponentStack}
        skipButton={canSkip}
        skipOnClick={() => submitFeedback(false)}
        finishButtonOnClick={() => canSubmit() && submitFeedback(false)}
        finishButtonInvalid={!canSubmit()}
      />
    );
  };

  if (getIsMobile()) {
    return (
      <GreyPage>
        <DarkCardBackground>
          <div style={{ position: 'absolute', bottom: 0 }}>
            <WhiteSubprompt style={{ paddingBottom: 0 }}>
              <img
                src={matchProfilePicture}
                style={{
                  width: 45,
                  height: 45,
                  borderRadius: 22,
                  display: 'block',
                  marginBottom: 6,
                }}
                alt="profile"
              />
              {matchFullName}
            </WhiteSubprompt>
            <WhitePrompt>Let us know how your meeting went!</WhitePrompt>
            {didMeet ? (
              <WhiteCard>
                <Heading1>{feedbackPage.didMeet}</Heading1>
              </WhiteCard>
            ) : (!loading && !match) || apiError ? (
              <>
                <WhiteCard style={{ minHeight: 400 }}>Sorry an error occurred</WhiteCard>
                <WhiteCardAction onClick={() => history.replace('/weekly')} title="OK" />
              </>
            ) : (
              <WhiteFeedbackFormCard
                loading={loading}
                getComponentStack={getComponentStack}
                skipButton={canSkip}
                skipOnClick={() => submitFeedback(false)}
                finishButtonOnClick={() => canSubmit() && submitFeedback(false)}
                finishButtonInvalid={!canSubmit()}
              />
            )}
          </div>
        </DarkCardBackground>
      </GreyPage>
    );
  }

  return <GreyPage>{renderPage()}</GreyPage>;
};

interface WhiteFeedbackFormCardProps {
  getComponentStack: () => { component: React.ReactNode; isLeaf: boolean }[];
  skipButton: boolean;
  skipOnClick: () => void;
  finishButtonOnClick: () => void;
  finishButtonInvalid: boolean;
  loading: boolean;
}

const WhiteFeedbackFormCard: React.FC<WhiteFeedbackFormCardProps> = ({
  skipButton,
  skipOnClick,
  finishButtonInvalid,
  finishButtonOnClick,
  getComponentStack,
  loading,
}) => {
  const components = getComponentStack();
  const [step, setStep] = useState(0);

  const activeQuestionRef = React.useRef<any>();
  const lastStepIdx = components.length - 1;

  /*
  This effect is a little complicated because we don't want to refactor the logic that builds
  the component stack right now. This just implements two rules:

  - If a new component appears at the end of the array, (meaning the user made a selection)
    in the current question, advance by a step.

  - If a new component appears AND the current box now has a text input in it, focus the input
    instead of advancing. This handles the case where you click "Other" and a box appears to type
    in your comment. In this case, the user clicks "Next" manually when they're done
  */
  React.useEffect(() => {
    const inputEl = activeQuestionRef.current?.querySelector('input');
    if (inputEl && !inputEl.disabled) {
      inputEl.focus();
    } else if (lastStepIdx > step) {
      setTimeout(() => setStep(step + 1), 250);
    }
  }, [lastStepIdx]);

  return (
    <>
      <WhiteCard style={{ minHeight: 400 }}>
        <WhiteCardHeader
          onPrev={step > 0 ? () => setStep(step - 1) : undefined}
          onClose={skipButton ? skipOnClick : undefined}
          title="Private feedback"
        />
        {loading ? (
          <SVGLoader />
        ) : (
          <div
            style={{
              position: 'relative',
              margin: `-${margins.size2}`,
              marginTop: margins.size2,
            }}
          >
            {components.map(({ component }, idx) => (
              <QuestionWrap
                key={idx}
                ref={idx === step ? activeQuestionRef : undefined}
                style={{ left: `${(idx - step) * 100}%`, maxHeight: 330, overflowY: 'scroll' }}
              >
                {component}
              </QuestionWrap>
            ))}
          </div>
        )}
      </WhiteCard>
      {step === components.length - 1 ? (
        <WhiteCardAction
          hide={step === 0 && components.length === 1}
          disabled={finishButtonInvalid}
          onClick={finishButtonOnClick}
          title="Continue"
        />
      ) : (
        <WhiteCardAction onClick={() => setStep(step + 1)} title="Continue" />
      )}
    </>
  );
};

const QuestionWrap = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  transition: left 280ms ease-in-out;
  padding: 0 ${margins.size2};
`;
