import React from 'react';
import styled from 'styled-components/macro';

import { colors, HideMobile, FlexRow, Clickable, Text } from 'css/css';

import EditPencil from 'img/shared/edit-pencil.svg';

export const DesktopEditButton = ({
  show,
  startEditing,
  save,
  editing = false,
  becomeSave = false,
}: any) => {
  if (show)
    return (
      <HideMobile>
        <FlexRow flexDirection="row-reverse">
          {!editing && (
            <input
              type="image"
              src={EditPencil}
              onClick={startEditing}
              alt="Edit button"
              style={{ borderRadius: '5px' }}
            />
          )}
          {editing && becomeSave && (
            <Clickable onClick={save}>
              <SaveButton>save</SaveButton>
            </Clickable>
          )}
        </FlexRow>
      </HideMobile>
    );
  return null;
};

const SaveButton = styled(Text)`
  color: ${colors.primaryMain};
`;
