import React from 'react';
import { useHistory } from 'react-router';

import { Card, colors, FlexColumn, Heading1, margins, Text } from 'css/css';

import { GoogleSignIn } from 'js/components/shared/GoogleSignIn';
import { GreyPage } from 'js/components/shared/page-wrappers';
import { useUser } from 'js/providers/UserProvider';
import { useMountEffect } from 'js/util/custom-hooks';
import shouldRedirectToWeekly from 'js/util/weekly-redirect';
import { addParameterToURL, getParameterByName } from 'js/util/util';
import { useNotifContext } from 'js/util/notif-context';

export const GooglePermissions: React.FC = () => {
  const history = useHistory();

  const { showNotif } = useNotifContext();

  useMountEffect(() => {
    if (getParameterByName('withPermissions')) {
      const passedMessage = getParameterByName('message');
      if (passedMessage) showError(passedMessage);
      return;
    }
    window.location.reload();
    // @ts-ignore
    window.location = addParameterToURL('withPermissions', 1);
  });

  const user = useUser();
  const onLogin = async () =>
    (await shouldRedirectToWeekly()) ? history.push('/weekly') : history.push('/home');

  const showError = (message: any) => {
    showNotif({
      message,
      level: 'error',
      autoDismiss: 10,
    });
  };
  return (
    <GreyPage>
      <Card style={{ padding: margins.size5 }}>
        <Heading1 style={{ marginBottom: margins.size4 }} color={colors.blackMain}>
          Sign in with Google
        </Heading1>

        <div style={{ textAlign: 'left' }}>
          <Text color={colors.blackMain} style={{ marginBottom: margins.size4 }}>
            To improve your Lunchclub experience, we suggest connecting your Google contact and
            calendar data.
          </Text>

          <Text style={{ marginBottom: margins.size2 }}>
            - We use your Google contacts to ensure that your matches are relevant and to not match
            you with people you already know on the platform. Additionally, we show your
            pre-existing contacts on Lunchclub.
          </Text>

          <Text style={{ marginBottom: margins.size4 }}>
            - Your calendar data helps us schedule meetings and set up Google Meet links for easy
            connection at the time of the call.
          </Text>

          <Text color={colors.blackMain} style={{ marginBottom: margins.size4 }}>
            We are serious about your privacy and will never share your private data with other
            users or third parties without your consent.
          </Text>

          <FlexColumn>
            <GoogleSignIn
              buttonText="Sign in with Google"
              onLogIn={onLogin}
              email={user.email}
              isRegistering
              promptPermissions
            />
          </FlexColumn>
        </div>
      </Card>
    </GreyPage>
  );
};
