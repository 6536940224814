import { css } from 'styled-components/macro';

import { colors, globalTransitionSettings } from './css';

/**
 * isHovering - custom hover state
 * underlineColor - color of the original text
 */
export type UnderlineAnimationProps = { isHovering?: boolean };

export const underlineHover = css`
  transform-origin: bottom left;
  transform: scaleX(1);
`;

export const underlineAnimation = css<UnderlineAnimationProps>`
  position: relative;

  &:before {
    content: '';
    width: 100%;
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 0;

    background-color: ${colors.primaryMain};
    transform-origin: bottom right;

    transform: scaleX(0);
    transition: ${globalTransitionSettings};
    ${p => p.isHovering && 'transform-origin: bottom left; transform: scaleX(1);'}
  }

  &:hover:before {
    ${underlineHover}
  }
`;
