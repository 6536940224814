import React from 'react';
import styled from 'styled-components/macro';

import { colors, FlexRow, fonts, globalTransitionSettings, margins, media, Text } from 'css/css';

import { SmallArrow } from './SmallArrow';

interface OptionProps {
  text: string;
  onClick: () => void;
  selected: boolean;
  withArrow?: boolean;
}

export const AutoselectableOption: React.FC<OptionProps> = ({
  text,
  onClick,
  selected,
  withArrow,
}) => (
  <TextTile role="radio" aria-checked={selected} onClick={onClick} selected={selected}>
    {withArrow ? (
      <FlexRow style={{ width: '100%', justifyContent: 'space-between' }}>
        <Text>{text}</Text> <SmallArrow color={colors.blackLight} direction="right" />
      </FlexRow>
    ) : (
      <Text>{text}</Text>
    )}
  </TextTile>
);

type TextTileTypes = {
  selected?: boolean;
};

const TextTile = styled.div<TextTileTypes>`
  max-width: 350px;
  width: 90%;
  margin: 2px auto;
  padding: ${margins.size3};
  background-color: ${p => (p.selected ? colors.greyMain : colors.greyLight)};
  min-height: 40px;
  border-radius: 3px;
  text-align: left;
  cursor: pointer;
  transition: ${globalTransitionSettings};

  ${media.desktop} {
    &:hover {
      background-color: ${colors.greyMain};
    }
  }
  ${media.mobile} {
    margin: 0;
    width: 100%;
    max-width: 100%;
    text-align: center;
    &:active {
      background-color: ${colors.greyMain};
    }
    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    ${Text} {
      color: ${colors.primaryMain};
      font-family: ${fonts.extraBold};
    }
  }
`;
