import React from 'react';

import { FlexColumn, FlexRow, margins } from 'css/css';

import { UserMe } from 'types/user';

import { Avatar } from 'js/components/shared/Avatar';
import { Clubpoints } from 'js/components/shared/Clubpoints';

import { MenuText } from './styles';

interface Props {
  user: UserMe;
}

export const DropdownProfile: React.FC<Props> = ({ user }) => (
  <FlexRow width="100%" justifyContent="space-between">
    <FlexRow justifyContent="flex-start">
      <Avatar
        src={user.image}
        ownProfile
        style={{
          maxWidth: 30,
          minWidth: 30,
          minHeight: 30,
          maxHeight: 30,
          marginRight: margins.size3,
          border: '2px solid white',
        }}
      />

      <FlexColumn alignItems="flex-start">
        <MenuText>
          {user.first_name} {user.last_name}
        </MenuText>
      </FlexColumn>
    </FlexRow>
    <Clubpoints />
  </FlexRow>
);
