import { useEffect, useMemo } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';

import { useParticipantsContext } from '../contexts/ParticipantsContext';
import { useTracksContext } from '../contexts/TracksContext';

const useResumeVideoTrack = () => {
  const { currentSpeaker, participants } = useParticipantsContext();
  const { resumeVideoTrack } = useTracksContext();

  /**
   * Always auto-resume video for screens, pinned participants and active speaker.
   */
  useEffect(() => {
    if (!currentSpeaker) return () => undefined;
    /**
     * Throttle calls to resume video for speaker, to avoid
     * accidentally subscribing to all participants on participant-joined.
     */
    const timeout = setTimeout(() => {
      resumeVideoTrack(currentSpeaker.id);
    }, 500);

    participants.forEach(p => resumeVideoTrack(p?.id));
    return () => {
      clearTimeout(timeout);
    };
  }, [currentSpeaker, resumeVideoTrack, participants]);

  return undefined;
};

const SYNC_DELAY = 50;
const useSyncCamSubscriptions = () => {
  const { updateCamSubscriptions } = useTracksContext();
  const { participants } = useParticipantsContext();

  const otherParticipantIds = useMemo(
    () => participants.filter(({ isLocal }) => !isLocal).map(({ id }) => id),
    [participants],
  );

  useDeepCompareEffect(() => {
    const timeout = setTimeout(() => {
      if (otherParticipantIds.length < 1) return;

      // Only subscribe to the other participant in the call
      updateCamSubscriptions([otherParticipantIds[0]], []);
    }, SYNC_DELAY);
    return () => {
      clearTimeout(timeout);
    };
  }, [otherParticipantIds]);
};

export const useDailyTracks = () => {
  useResumeVideoTrack();
  useSyncCamSubscriptions();
};
