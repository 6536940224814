import React, { useState } from 'react';
import styled from 'styled-components/macro';
import format from 'string-format';
import SVG from 'react-inlinesvg';

import { borders, colors, fontSizes, margins, Heading3, FlexRow } from 'css/css';

import { StringLengthIndicator } from 'js/components/shared/StringLengthIndicator';
import { MobileFocusingTextarea } from 'js/components/shared/MobileFocusingTextarea';

import privateNoteIcon from 'img/feedback/private-note.svg';
import additionalFeedbackIcon from 'img/feedback/additional-feedback.svg';

const getIcon = {
  PRIVATE_NOTE: privateNoteIcon,
  ADDITIONAL_FEEDBACK: additionalFeedbackIcon,
};

export const HiddenTextbox: React.FC<any> = ({
  id,
  value,
  setValue,
  maxLength,
  header,
  matchFirstName,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container
      display={isOpen}
      onClick={() => !isOpen && setIsOpen(true)}
      error={value && value.length > maxLength}
    >
      <Header onClick={() => setIsOpen(!isOpen)}>
        {/* @ts-ignore */}
        <Icon src={getIcon[id]} />

        <Heading3 style={{ textAlign: 'left' }}>{format(header, matchFirstName)}</Heading3>
      </Header>

      <div style={{ maxHeight: isOpen ? '200px' : '0px', display: isOpen ? 'initial' : 'none' }}>
        <StyledTextbox
          role="textbox"
          value={value}
          resetValue={originalValue => setValue(originalValue)}
          onChange={(e: any) => setValue(e.target.value)}
          mobileFocusBehavior="always"
          mobileFocusLabel=""
        />

        <StringLengthIndicator minLength={0} maxLength={maxLength} string={value} />
      </div>
    </Container>
  );
};

const Icon = styled(SVG)`
  padding-bottom: 2px;
  margin-right: ${margins.size2};
`;
const Header = styled(FlexRow)`
  margin-bottom: ${margins.size2};
  cursor: pointer;
`;

const StyledTextbox = styled(MobileFocusingTextarea)`
  outline: none;
  border: none;
  height: 125px;
  width: 100%;
  padding: ${margins.size3};
  background: ${colors.greyLight};
  border-radius: 3px;
  resize: none;
  font-size: ${fontSizes.size2};
  color: ${colors.blackMid};
  &::placeholder {
    color: ${colors.blackLight};
  }
`;

type ContainerTypes = {
  error?: boolean;
  display?: boolean;
};

const Container = styled.div<ContainerTypes>`
  margin: 2px 0px;
  width: 100%;
  background: ${colors.whiteMain};
  border: ${p => (p.error ? borders.error : borders.standard)};
  border-radius: 5px;
  padding: ${margins.size4} ${margins.size4} ${margins.size3} ${margins.size4};
  cursor: pointer;
  &:hover {
    background-color: ${p => !p.display && colors.greyLight};
  }
`;
