import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import {
  FlexColumn,
  fonts,
  margins,
  colors,
  globalTransitionSettings,
  Text,
  Heading3,
  SubText,
} from 'css/css';

import { Objective } from 'types/objectives';

import { useResource } from 'js/util/use-resource';

import {
  objectiveMeetSvg,
  objectiveInvestSvg,
  objectiveCompaniesSvg,
  objectiveBusinessSvg,
  objectiveCofounderSvg,
  objectiveMentorSvg,
  objectiveExploreSvg,
  objectiveRaiseSvg,
} from 'img/registration-v2';

import { RegistrationStepProps } from '../MobileRegistrationContent';

import { OnboardingFlowPage } from './OnboardingFlowPage';
import {
  CheckmarkAnimated,
  NUX_HEADER_STEPS,
  ObjectivesAndInterestsDecs,
  OnboardingSteps,
} from './shared';
import { ProgressBar } from './ProgressBar';

const MobileObjectiveWhitelist = [
  { name: 'Meet interesting people', icon: objectiveMeetSvg },
  { name: 'Invest', icon: objectiveInvestSvg },
  { name: 'Find a job', icon: objectiveCompaniesSvg },
  { name: 'Business development', icon: objectiveBusinessSvg },
  { name: 'Find a cofounder', icon: objectiveCofounderSvg },
  { name: 'Mentor others', icon: objectiveMentorSvg },
  { name: 'Explore new projects', icon: objectiveExploreSvg },
  { name: 'Raise funding', icon: objectiveRaiseSvg },
];

export const StepObjectivesMobile: React.FC<RegistrationStepProps> = ({
  values,
  setValues,
  submitUserInfo,
}) => {
  const [objectives] = useResource<Objective[]>('discover/objectives');
  const history = useHistory();

  const onNext = async () => {
    await submitUserInfo({ objectiveIds: values.selectedObjectives.map(obj => obj.id) }, () => {
      history.push(`/registration/interests${history.location.search}`);
    });
  };

  return (
    <OnboardingFlowPage
      step={OnboardingSteps.ObjectivesAndInterests}
      onNext={onNext}
      onPrev={() => history.push(`/registration/city${history.location.search}`)}
      nextDisabled={values.selectedObjectives.length === 0}
      title={NUX_HEADER_STEPS.GoalsAndInterests.title}
      subtitle={NUX_HEADER_STEPS.GoalsAndInterests.subtitle}
    >
      <ObjectiveBoxContainer>
        <ProgressBar numCompleted={0} total={2} />
        <Heading3>{ObjectivesAndInterestsDecs.Objectives.title}</Heading3>
        <SubText color={colors.blackMid} style={{ paddingBottom: margins.size3 }}>
          {ObjectivesAndInterestsDecs.Objectives.subtitle}
        </SubText>
        <ObjectiveItemGrid>
          {MobileObjectiveWhitelist.map(({ name, icon }) => {
            const obj = (objectives || []).find(o => o.name === name);
            const selected = !!obj && !!values.selectedObjectives.find(o => o.name === name);
            const disabled = !selected && values.selectedObjectives.length === 3;
            return (
              <ObjectiveItem
                key={name}
                objective={obj || 'loading'}
                icon={icon}
                selected={selected}
                disabled={disabled}
                onClick={() =>
                  obj && !disabled
                    ? setValues({
                        ...values,
                        selectedObjectives: selected
                          ? values.selectedObjectives.filter(other => other.id !== obj.id)
                          : [...values.selectedObjectives, obj],
                      })
                    : undefined
                }
              />
            );
          })}
          <div style={{ height: 70 }} />
        </ObjectiveItemGrid>
      </ObjectiveBoxContainer>
    </OnboardingFlowPage>
  );
};

const ObjectiveItem: React.FC<{
  objective: Objective | 'loading';
  selected: boolean;
  disabled: boolean;
  icon: string;
  onClick: () => void;
}> = ({ selected, disabled, objective, onClick, icon }) => {
  return (
    <ObjectiveItemContainer
      onClick={onClick}
      role="button"
      tabIndex={disabled ? undefined : 0}
      disabled={disabled}
      selected={selected}
      hide={objective === 'loading'}
    >
      {objective !== 'loading' && (
        <>
          <img src={icon} style={{ filter: disabled ? 'saturate(0)' : '' }} alt="icon" />
          <Text color={colors.blackMain} style={{ marginTop: 5, height: '2.8em' }}>
            {objective.name}
          </Text>
        </>
      )}
      {selected && <CheckmarkAnimated style={{ position: 'absolute', right: 15, bottom: 15 }} />}
    </ObjectiveItemContainer>
  );
};

const ObjectiveBoxContainer = styled(FlexColumn)`
  background-color: ${colors.whiteMain};
  margin: ${margins.size2};
  padding: ${margins.size2};
  border-radius: 10px;
  justify-content: flex-start;
  overflow-y: scroll;
  transition: ${globalTransitionSettings};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ObjectiveItemGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;
  row-gap: 8px;
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ObjectiveItemContainer = styled.div<{ disabled: boolean; selected: boolean; hide: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 45vw;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${margins.size3};
  font-size: 16px;
  font-family: ${fonts.bold};
  line-height: 1.4rem;
  background: white;
  border-radius: 15px;
  position: relative;
  user-select: none;
  color: ${({ disabled }) => (disabled ? colors.blackLight : colors.blackMain)};
  border: ${({ selected, hide }) =>
    hide
      ? '0px'
      : selected
      ? `2px solid ${colors.secondaryMain}`
      : `2px solid ${colors.greyLight}`};
  outline: none;
`;
