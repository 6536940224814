import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import partition from 'lodash/partition';

import { margins, fonts, Heading2, colors } from 'css/css';

import { BackendLocale } from 'js/components/weekly/types';
import { useResource } from 'js/util/use-resource';
import { setElliotToken } from 'js/util/util';
import { SpinningGlobe } from 'js/components/shared/SpinningGlobe';
import { ProgressBarStyle } from 'js/components/registration/new-onboarding/ProgressBar';
import { useMountEffect } from 'js/util/custom-hooks';

import stars from 'img/shared/stars.svg';

import { RegistrationStepProps } from '../MobileRegistrationContent';
import { CitySelectMobile } from '../cities/CitySelectMobile';

import { OnboardingFlowPage } from './OnboardingFlowPage';
import { OnboardingSteps } from './shared';

export const StepCitiesMobile: React.FC<RegistrationStepProps> = ({
  submitUserInfo,
  setValues,
}) => {
  const history = useHistory();

  const [locales] = useResource<BackendLocale[]>('discover/locales', { include_tz_locales: false });
  const [featuredLocales] = partition(locales || [], locale => locale.is_featured);

  const [focused, setFocused] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selected, setSelected] = useState<
    { locale: BackendLocale } | { googlePlaceId: string } | null
  >(null);

  useMountEffect(() => {
    if (window.bridge) window.hj('trigger', 'fpm');
  });

  const onNext = async (value = selected) => {
    if (!value) {
      return;
    }

    setValues(obj => ({
      ...obj,
      requireLinkedin: true,
    }));

    setSaving(true);
    try {
      await submitUserInfo(
        'locale' in value
          ? { locale: value.locale.id }
          : { googlePlaceId: value.googlePlaceId, autoLocale: true },
        () => history.push(`/registration/objectives${history.location.search}`),
      );
    } catch (err) {
      setSaving(false);
    }
  };

  return (
    <OnboardingFlowPage
      step={OnboardingSteps.Location}
      progressStyle={ProgressBarStyle.LIGHT}
      background={`no-repeat ${colors.blackMain} url(${stars})`}
      onNext={onNext}
      onPrev={() => {
        // log out the user so the destination page doesn't redirect them back here
        setElliotToken(null);
        history.push(window.bridge ? '/mobile-root' : '/login');
      }}
      nextDisabled={!selected}
      nextLoading={saving}
    >
      <StepCitiesMobileWrapper>
        <Prompt
          style={{
            alignSelf: 'flex-start',
          }}
        >
          Where do you call home?
        </Prompt>
        <PositionedContainer focused={focused}>
          <CitySelectMobile
            focused={focused}
            setFocused={setFocused}
            featuredLocales={featuredLocales}
            onSubmit={value => {
              setSelected(value);
              onNext(value);
            }}
          />
        </PositionedContainer>
      </StepCitiesMobileWrapper>
      <SpinningGlobe />
    </OnboardingFlowPage>
  );
};

const PositionedContainer = styled.div<{ focused: boolean }>`
  width: 90vw;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: ${({ focused }) => (focused ? '65px' : '50%')};
  transform: translate(0, -50%);
  transition: top 300ms ease-out;
  z-index: 1;
`;
const Prompt = styled(Heading2)`
  font-family: ${fonts.extraBold};
  font-size: 18px;
  margin-bottom: ${margins.size3};
  color: white;
`;

const StepCitiesMobileWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
