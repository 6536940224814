import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { media, borders, colors, margins, FlexRow, SubText, Text } from 'css/css';

import { ProductUser } from 'types/user';

import { Button } from 'js/components/shared/Button';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { COMMUNITIES } from 'js/util/util';

interface Props {
  editing: boolean;
  passedUser?: ProductUser;
}

export const CommunitySettings: React.FC<Props> = ({ editing, passedUser }) => {
  const user = useUser();
  const { updateUser } = useUserContextProvider();
  const { organizations: userOrganizationIds } = passedUser || user;

  const communities = userOrganizationIds?.filter((commId: number) => COMMUNITIES[commId]) || [];
  const [leftCommunities, setLeftCommunities] = useState<number[]>([]);
  const [editingCommunities, setEditingCommunities] = useState<number | null>(null);

  const leaveOrJoinCommunity = async (commId: number) => {
    setEditingCommunities(commId);
    if (communities.includes(commId)) {
      await updateUser({ organizations: communities.filter(community => community !== commId) });
      setLeftCommunities([...leftCommunities, commId]);
    } else {
      await updateUser({ organizations: [...communities, commId] });
      setLeftCommunities(leftCommunities.filter(community => community !== commId));
    }
    setEditingCommunities(null);
  };

  const getCommunity = (community: number) => {
    if (editing)
      return (
        <CommunityButton key={community}>
          <FlexRow justifyContent="space-between" width="100%">
            <FlexRow>
              <CommunityIcon src={COMMUNITIES[community].img} alt="" />
              <Text style={{ marginLeft: margins.size3 }} color={colors.blackMain}>
                {COMMUNITIES[community].name}
              </Text>
            </FlexRow>
            <Button
              style={{ margin: 0 }}
              small
              secondary={leftCommunities.includes(community)}
              tertiary={!leftCommunities.includes(community)}
              onClick={() => leaveOrJoinCommunity(community)}
              loading={editingCommunities === community}
            >
              {leftCommunities.includes(community) ? 'rejoin' : 'leave'}
            </Button>
          </FlexRow>
        </CommunityButton>
      );
    return (
      <FlexRow key={community} style={{ marginRight: margins.size2 }}>
        <CommunityIcon src={COMMUNITIES[community].img} alt="" />
        <SubText color={colors.blackMid}>{COMMUNITIES[community].name}</SubText>
      </FlexRow>
    );
  };

  const distinctCommunities = Array.from(new Set([...communities, ...leftCommunities])).sort();
  if (distinctCommunities?.length === 0) return null;
  return (
    <CommunityContainer isEditing={editing}>
      {distinctCommunities.map((community: number) => getCommunity(community))}
    </CommunityContainer>
  );
};

type CommunityRowTypes = { isEditing: boolean };
const CommunityContainer = styled.div<CommunityRowTypes>`
  display: flex;
  flex-direction: ${p => (p.isEditing ? 'column' : 'row')};
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  left: ${p => !p.isEditing && margins.size4};
  margin-left: ${p => (p.isEditing ? '0px' : margins.size3)};
  margin-right: ${margins.size4};
  margin-bottom: ${p => (p.isEditing ? margins.size3 : margins.size4)};
  ${media.mobile} {
    margin-bottom: 0;
  }
`;

const CommunityIcon = styled.img`
  margin-right: ${margins.size2};
  max-height: 24px;
  max-width: 24px;
`;

const CommunityButton = styled(FlexRow)`
  min-height: 54px;
  width: 100%;
  padding: ${margins.size3};
  padding-left: ${margins.size3};

  background-color: ${colors.whiteMain};

  border: ${borders.variation};
  border-radius: 5px;
  margin-bottom: ${margins.size1};

  ${media.mobile} {
    border: none;
    padding: ${margins.size2} 0;
    border-bottom: ${borders.variation};
    margin-bottom: 0;
    &:last-child {
      border-bottom: 0;
    }
  }
`;
