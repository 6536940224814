import React from 'react';
import styled from 'styled-components/macro';

import { borders, colors, margins, globalTransitionSettings } from 'css/css';

export const MultipleChoiceSelector = ({ question, onClickAnswer, checkSelected }: any) => (
  <Selector>
    {question.options.map((ans: any, idx: any) => (
      <Answer
        selected={checkSelected(ans.id, question.id)}
        bottom={idx === question.options.length - 1}
        onClick={() => onClickAnswer(ans.id, question.id)}
        key={ans.id}
      >
        {ans.text}
      </Answer>
    ))}
  </Selector>
);

const Selector = styled.div`
  border: ${borders.standard};
  box-sizing: border-box;
  border-radius: 3px;
  overflow: hidden;
  margin-top: ${margins.size4};
`;

type AnswerTypes = {
  selected: boolean;
  bottom: boolean;
};
const Answer = styled.div<AnswerTypes>`
  color: ${p => (p.selected ? colors.whiteMain : colors.blackMid)};
  background-color: ${p => (p.selected ? colors.blackMid : colors.whiteMain)};
  padding: ${margins.size2} ${margins.size4};
  border-bottom: ${p => (p.bottom ? '' : borders.standard)};
  cursor: pointer;
  transition: ${globalTransitionSettings};
  &:hover {
    background-color: ${p => (p.selected ? colors.blackMid : colors.greyLight)};
  }
`;
