import React from 'react';
import styled from 'styled-components/macro';

import { margins, colors, globalTransitionSettings } from 'css/css';

import { TextInput } from 'js/components/shared/TextInput';
import ProfileUpload from 'js/components/shared/profile-upload';

import linkedInSvg from 'img/shared/social-icons/linkedin-icon.svg';
import twitterSvg from 'img/shared/social-icons/twitter-icon.svg';

export const ProfileData = ({
  linkedInLink,
  twitterLink,
  setLinkedInLink,
  setTwitterLink,
  profilePicture,
  setProfilePicture,
  requireLinkedIn,
}: {
  linkedInLink: string;
  twitterLink: string;
  profilePicture: string;
  setLinkedInLink: (link: string) => void;
  setTwitterLink: (link: string) => void;
  setProfilePicture: (link: string) => void;
  requireLinkedIn: boolean;
}) => {
  return (
    <>
      <ProfileUpload
        currentImage={profilePicture}
        onDelete={() => setProfilePicture('')}
        onUpload={(newPicture: any) => setProfilePicture(newPicture)}
      />

      <ButtonsAndLinkContainer>
        <SocialURLsContainer>
          <SocialURL>
            <SocialIcon src={twitterSvg} alt="Twitter Icon" />

            <TextInput
              onChange={setTwitterLink}
              value={twitterLink}
              placeholder="Twitter url (optional)"
            />
          </SocialURL>
          <SocialURL>
            <SocialIcon src={linkedInSvg} alt="LinkedIn Icon" />

            <div style={{ width: '100%' }}>
              <TextInput
                onChange={setLinkedInLink}
                value={linkedInLink}
                placeholder={`LinkedIn url${!requireLinkedIn ? ' (optional)' : '(required)'}`}
              />
              <div style={{ marginTop: margins.size1, textAlign: 'left' }}>
                <LinkText
                  href="https://www.linkedin.com/in/edit/contact-info/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Find your LinkedIn profile url
                </LinkText>
              </div>
            </div>
          </SocialURL>
        </SocialURLsContainer>
      </ButtonsAndLinkContainer>
    </>
  );
};

const ButtonsAndLinkContainer = styled.div`
  max-width: 320px;
  margin: 0 auto;
`;

const SocialURLsContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: ${margins.size4};
`;

const SocialURL = styled.div`
  display: flex;
  margin: ${margins.size1};
`;

const SocialIcon = styled.img`
  width: ${margins.size4};
  height: ${margins.size4};
  margin-top: ${margins.size2};
  margin-right: ${margins.size3};
`;

const LinkText = styled.a`
  color: ${colors.blackLight};
  transition: ${globalTransitionSettings};
  text-decoration: underline;
  &:hover {
    color: ${colors.blackMid};
  }
`;
