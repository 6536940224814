const notEqualToPrevious = <T extends { key: string }>(elem: T, index: number, array: T[]) =>
  index === 0 || elem.key !== array[index - 1].key;

// returns arr with duplicates removed (in the original order)
export const unique = <T>(arr: T[], getKey: (elem: T) => string) =>
  arr
    .map((elem: T, index: number) => ({
      val: elem,
      index,
      key: getKey(elem),
    }))
    .sort((a, b) => a.key.localeCompare(b.key))
    .filter(notEqualToPrevious)
    .sort((a, b) => a.index - b.index)
    .map(({ val }) => val);
