import { AllCardTypes, RegularCardType } from './types';

export const REGULAR_CARDS: RegularCardType[] = [
  AllCardTypes.IntroSuggestion,
  AllCardTypes.Discover,
  AllCardTypes.Similar,
  AllCardTypes.FriendFinder,
  AllCardTypes.EndorsementSuggestion,
  AllCardTypes.Survey,
  AllCardTypes.Content,
];

export const explanationHeaders = {
  [AllCardTypes.IntroSuggestion]: {
    heading: 'Make Helpful Introductions',
    desc:
      'We think some of your friends should be connected. Make an introduction with a click of a button.',
  },
  [AllCardTypes.Discover]: {
    heading: 'Discover New People',
    desc: 'Explore people you might meet and let us know what kind of matches are most relevant.',
  },
  [AllCardTypes.Similar]: {
    heading: 'Discover New People',
    desc: 'Explore people you might meet and let us know what kind of matches are most relevant.',
  },
  [AllCardTypes.Personality]: {
    heading: 'Take a Personality Test',
    desc:
      'Introducing our new <em>Lunchclub x Truity</em> personality test. Discover your DISC profile!',
  },
  [AllCardTypes.FriendFinder]: {
    heading: 'Signify Your Connections',
    desc: (firstName: string) =>
      `Insight into your network will help us curate better matches for both you and your connections.<br><br>*We’re showing you ${firstName} because they are a Google contact.`,
  },
  [AllCardTypes.EndorsementSuggestion]: {
    heading: 'Endorse Others',
    desc:
      'We value your opinion on your connections. Endorse people you know on their area of expertise.',
  },
  [AllCardTypes.Survey]: {
    heading: 'Improve Your Experience',
    desc: 'We’d like to make your experience better by learning more about you.',
  },
  [AllCardTypes.Content]: {
    heading: 'Get More Relevant Matches',
    desc: 'Help us make more relevant matches by letting us know what content you like.',
  },
};

export const CARD_TRANSITION_ENTER_DURATION = 90; // in terms of milliseconds
export const CARD_TRANSITION_LEAVE_DURATION = 150; // in terms of milliseconds
