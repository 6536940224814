import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router';
import moment from 'moment';

import {
  FlexColumn,
  margins,
  Text,
  colors,
  Heading2,
  SubText,
  FlexRow,
  Heading3,
  media,
  lineHeights,
  fontSizes,
} from 'css/css';

import { ProductUser } from 'types/user';

import {
  confirmDiscoverBookingMatch,
  getUserProfile,
  postFeedAction,
  renewDiscoverBookingReservation,
  skipDiscoverBookingSuggestion,
} from 'js/util/api';
import { apiFailure } from 'js/util/strings';
import { useNotifContext } from 'js/util/notif-context';
import { Avatar } from 'js/components/shared/Avatar';
import { Button } from 'js/components/shared/Button';
import { useMountEffect } from 'js/util/custom-hooks';
import { useUser } from 'js/providers/UserProvider';
import { useChatContext } from 'js/providers/ChatContextProvider';
import {
  CircularButton,
  MemberProfileBody,
  MEMBER_PROFILE_MAX_WIDTH,
  TopNavContainer,
} from 'js/components/member';
import { getIsMobile, getTimezoneAbbr } from 'js/util/util';
import {
  ColoredClickable,
  DeckCardContainer,
  DECK_CARD_CONTAINER_WIDTH,
  HeightContainer,
} from 'js/components/homepage/styles';
import { DiscoverBookingCardData, FeedActionType, UserInfo } from 'js/components/homepage/types';
import { CARD_TRANSITION_LEAVE_DURATION } from 'js/components/homepage/constants';
import { useFontSizeFillingContainer } from 'js/util/use-font-size-filling-container';
import { Headline } from 'js/components/homepage/shared/UserDescriptionBoxes';
import { LargeArrow } from 'js/components/shared/LargeArrow';

import { skipArrow } from 'img/homepage/feed';

const SKIP_MARGIN_BOTTOM = parseInt(margins.size2, 10);
const DEFAULT_POPUP_HEIGHT = 147;

interface Props {
  deckCardId: number;
  cardId: string;
  data: DiscoverBookingCardData;
  completeCard: (cardId: string) => void;
  fetchSuggestion?: () => Promise<void>; // TO-DO: get rid of optional
}

export const DiscoverBookingCard: React.FC<Props> = ({
  deckCardId,
  cardId,
  data,
  completeCard,
  fetchSuggestion,
}) => {
  const { showNotif } = useNotifContext();
  const history = useHistory();
  const { initChat } = useChatContext();

  const [wasAccepted, setWasAccepted] = useState<boolean>();
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [isSkipLoading, setIsSkipLoading] = useState(false);
  const [shouldDisplayProfile, setShouldDisplayProfile] = useState(false);
  const [userProfile, setUserProfile] = useState<ProductUser>();
  const [meetingPopupHeight, setMeetingPopupHeight] = useState<number>(DEFAULT_POPUP_HEIGHT);
  const [deckCardContentHeight, setDeckCardContentHeight] = useState<number>();

  const { user, time_slots: timeSlots, suggestion_id: suggestionId } = data;
  const timeSlot = timeSlots[0];
  const isProfileDisplayed = shouldDisplayProfile && userProfile !== undefined;
  const isMobile = getIsMobile();
  const alreadyRespondedNotif = {
    message: 'This card was already responded.',
    level: 'error' as const,
  };

  const VIEW_PROFILE_HEIGHT = 24;
  const SKIP_HEIGHT = 44;

  const confirmMatch = async () => {
    if (isConfirmLoading) {
      return;
    }

    setIsConfirmLoading(true);
    const res = await confirmDiscoverBookingMatch({
      suggestionId,
      timeSlot,
    });
    if (res.ok) {
      if (fetchSuggestion) {
        await fetchSuggestion();
      }

      const isAlreadyResponded = res.getJson.status === 'ALREADY_RESPONDED';
      if (isAlreadyResponded) {
        showNotif(alreadyRespondedNotif);
      }

      setWasAccepted(true);
      setTimeout(() => {
        completeCard(cardId);

        if (!isAlreadyResponded) {
          initChat();
          history.push(`/chat/${user.profile}`);
        }
      }, CARD_TRANSITION_LEAVE_DURATION);
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
    setIsConfirmLoading(false);
  };

  const rejectAndFetchTheNextCard = async () => {
    if (fetchSuggestion) {
      await fetchSuggestion();
    }
    setWasAccepted(false);
    setTimeout(() => completeCard(cardId), CARD_TRANSITION_LEAVE_DURATION);
  };

  const skipSuggestion = async () => {
    if (isSkipLoading) {
      return;
    }

    setIsSkipLoading(true);
    const res = await skipDiscoverBookingSuggestion({
      suggestionId,
    });
    if (res.ok) {
      if (res.getJson.status === 'ALREADY_RESPONDED') {
        showNotif(alreadyRespondedNotif);
      }

      rejectAndFetchTheNextCard();
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
    setIsSkipLoading(false);
  };

  const loadUserProfile = async () => {
    const res = await getUserProfile(user.profile);
    if (res.ok) {
      setUserProfile(res.getJson);
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
  };

  const viewProfile = () => {
    if (userProfile === undefined) {
      loadUserProfile();
    }
    postFeedAction(deckCardId, { type: FeedActionType.SeeProfile, publicId: user.profile });
    setShouldDisplayProfile(true);
  };

  const closeProfile = () => setShouldDisplayProfile(false);

  useMountEffect(() => {
    const intervalId = setInterval(
      async () => {
        const res = await renewDiscoverBookingReservation({ suggestionId });
        if (res.ok && res.getJson.status === 'ALREADY_RESPONDED') {
          await rejectAndFetchTheNextCard();
        }
      },
      1000 * 30, // every 30 seconds
    );
    return () => clearInterval(intervalId);
  });

  const skip = <Skip handleClick={skipSuggestion} />;

  const meetingPopup = (isFixed: boolean) => (
    <HeightContainer
      setHeight={isFixed ? () => undefined : setMeetingPopupHeight}
      isFullHeight={false}
      isFullWidth
    >
      <MeetingPopup
        user={user}
        timeSlot={timeSlot}
        handleClick={confirmMatch}
        isLoading={isConfirmLoading}
        isFixed={isFixed}
        isProfile={isProfileDisplayed}
      />
    </HeightContainer>
  );

  const deckCard = (
    <DeckCardContainer
      deckCardId={deckCardId}
      isLoading={isConfirmLoading || isSkipLoading}
      isSelected={wasAccepted !== undefined}
      areItemsWrapped={false}
      feedSession="DiscoverBooking"
      style={isMobile ? {} : { width: '100%' }}
      setContentHeight={setDeckCardContentHeight}
    >
      {deckCardContentHeight && meetingPopupHeight && (
        <UserDescription
          user={user}
          maxHeight={
            deckCardContentHeight -
            VIEW_PROFILE_HEIGHT -
            SKIP_HEIGHT -
            SKIP_MARGIN_BOTTOM -
            meetingPopupHeight
          }
        />
      )}
      <ColoredClickable onClick={viewProfile}>
        <Text style={{ textDecoration: 'underline' }}>View profile</Text>
      </ColoredClickable>
      {isMobile && skip}
      {isMobile && meetingPopup(false)}
    </DeckCardContainer>
  );

  const discoverBookingCard = isMobile ? (
    deckCard
  ) : (
    <FlexColumn style={{ margin: '0 auto' }}>
      {skip}
      {deckCard}
      {meetingPopup(false)}
    </FlexColumn>
  );

  const memberProfile = (memberUser: ProductUser) => (
    <MemberProfileContainer mobilePaddingBottom={meetingPopupHeight}>
      {!isMobile && <Back handleClick={closeProfile} />}
      {!isMobile && meetingPopup(false)}
      <MemberProfileBody
        user={memberUser}
        hasBottomNav={false}
        handleClose={isMobile ? closeProfile : undefined}
      />
      {isMobile && meetingPopup(true)}
    </MemberProfileContainer>
  );

  return isProfileDisplayed ? memberProfile(userProfile) : discoverBookingCard;
};

const Back = ({ handleClick }: { handleClick: () => void }) => (
  <TopNavContainer>
    <CircularButton onClick={handleClick}>
      <LargeArrow direction="left" color={colors.blackMain} />
    </CircularButton>
  </TopNavContainer>
);

const Skip = ({ handleClick }: { handleClick: () => void }) => (
  <SkipContainer>
    <SubText style={{ marginRight: margins.size2 }}>See next profile</SubText>
    <CircularButton onClick={handleClick}>
      <img src={skipArrow} alt="Skip arrow" />
    </CircularButton>
  </SkipContainer>
);

const SkipContainer = styled(FlexRow)`
  width: 100%;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: ${SKIP_MARGIN_BOTTOM}px;
  width: ${DECK_CARD_CONTAINER_WIDTH}px;
`;

interface MeetingPopupProps {
  user: UserInfo;
  timeSlot: string;
  handleClick: () => void;
  isLoading: boolean;
  isFixed: boolean;
  isProfile: boolean;
}

const MeetingPopup: React.FC<MeetingPopupProps> = ({
  user,
  timeSlot,
  handleClick,
  isLoading,
  isFixed,
  isProfile,
}) => {
  const { locale_info: localeInfo } = useUser();
  const { first_name: firstName, image, profile } = user;

  const isMobile = getIsMobile();
  const userTimezone = getTimezoneAbbr(localeInfo.timezone);
  const gap = isMobile ? margins.size2 : margins.size3;

  return (
    <MeetingPopupContainer $isFixed={isFixed} $isProfile={isProfile}>
      <FlexColumn style={{ gap }}>
        <FlexRow justifyContent="flex-start" style={{ width: '100%', gap }}>
          <Avatar src={image} avatarId={profile} size2 />
          <FlexColumn alignItems="flex-start">
            <Heading3>Would you like to meet {firstName}?</Heading3>
            <Text>
              {moment(timeSlot).format('dddd MMM DD [at] h:mma')} {userTimezone}
            </Text>
          </FlexColumn>
        </FlexRow>
        <Button onClick={handleClick} large loading={isLoading}>
          Book meeting
        </Button>
      </FlexColumn>
    </MeetingPopupContainer>
  );
};

const MEETING_POPUP_MARGIN = parseInt(margins.size2, 10);

const MeetingPopupContainer = styled.div<{ $isFixed: boolean; $isProfile: boolean }>`
  width: 100%;
  background-color: ${colors.whiteMain};
  border-radius: 10px;
  padding: ${margins.size3};
  max-width: ${p => (p.$isProfile ? MEMBER_PROFILE_MAX_WIDTH : DECK_CARD_CONTAINER_WIDTH)}px;

  ${media.mobile} {
    z-index: 2;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    ${p =>
      p.$isFixed &&
      `position: fixed; 
       bottom: calc(env(safe-area-inset-bottom));
       width: calc(100% - ${MEETING_POPUP_MARGIN * 2}px);
       margin: ${MEETING_POPUP_MARGIN}px;`}
  }
  ${media.desktop} {
    margin: ${margins.size2} auto;
  }
`;

const UserDescription = ({ user, maxHeight }: { user: UserInfo; maxHeight: number }) => {
  const { image, name, headline, profile: profileId } = user;
  const isMobile = getIsMobile();

  const AVATAR_HEIGHT = parseInt(margins.size5, 10);
  const AVATAR_MARGIN_BOTTOM = parseInt(margins.size2, 10);
  const NAME_HEIGHT = 24;

  const maxHeightOfHeadline =
    maxHeight - AVATAR_HEIGHT - AVATAR_MARGIN_BOTTOM - NAME_HEIGHT - 16 - 16;
  const { textboxRef, fontSize } = useFontSizeFillingContainer(
    headline,
    parseInt(fontSizes.size2, 10),
    maxHeightOfHeadline,
    lineHeights.header,
    10,
  );

  return (
    <UserDescriptionContainer>
      <Avatar
        src={image}
        avatarId={profileId}
        size3
        style={{ marginBottom: AVATAR_MARGIN_BOTTOM }}
      />
      <Heading2
        style={{
          color: colors.blackMid,
        }}
      >
        {name}
      </Heading2>
      <Headline ref={textboxRef} style={isMobile ? { fontSize } : {}}>
        {headline}
      </Headline>
    </UserDescriptionContainer>
  );
};

const UserDescriptionContainer = styled(FlexColumn)`
  margin-bottom: ${margins.size3};
  margin-top: auto;
  width: 100%;
  gap: ${margins.size2};
`;

const MemberProfileContainer = styled.div<{ mobilePaddingBottom?: number }>`
  ${media.mobile} {
    height: 100%;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    z-index: 4;
    padding-bottom: calc(
      ${p => p.mobilePaddingBottom ?? DEFAULT_POPUP_HEIGHT}px + env(safe-area-inset-bottom)
    );
    background-color: ${colors.whiteMain};
  }
  ${media.desktop} {
    display: flex;
    flex-direction: column;
  }
`;
