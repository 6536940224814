import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { Heading2, margins, colors, globalTransitionSettings } from 'css/css';

import { Button } from 'js/components/shared/Button';

import settingsCameraIcon from 'img/call/settings-video.svg';
import settingsSpeakerIcon from 'img/call/settings-speaker.svg';
import settingsMicrophoneIcon from 'img/call/settings-microphone.svg';

import { useCallPermissionsContext, getDevicePermissions } from './CallPermissionsContext';

interface Props {
  isVisible: boolean;
  handleClose: () => void;
}
export const SettingsModal: React.FC<Props> = ({ isVisible, handleClose }) => {
  const [cameraDevices, setCameraDevices] = useState<MediaDeviceInfo[] | undefined>();
  const [speakerDevices, setSpeakerDevices] = useState<MediaDeviceInfo[] | undefined>();
  const [microphoneDevices, setMicrophoneDevices] = useState<MediaDeviceInfo[] | undefined>();

  const {
    selectedCamera,
    selectedSpeaker,
    selectedMicrophone,
    setCamera,
    setSpeaker,
    setMicrophone,
  } = useCallPermissionsContext();

  useEffect(() => {
    (async () => {
      const { isAudioEnabled, isVideoEnabled, devices } = await getDevicePermissions();

      /**
       * Media Devices with an empty label implies that the device hasn't been given permission by the user
       *  In this case, we represent a list of unauthorized devices as undefined instead
       *
       * Source: https://stackoverflow.com/a/60300833/13796346
       */

      if (isVideoEnabled) {
        const availableCameras = devices.filter(d => d.kind === 'videoinput' && d.label !== '');

        setCameraDevices(availableCameras);
      } else {
        setCameraDevices(undefined);
      }

      if (isAudioEnabled) {
        const availableSpeakers = devices.filter(d => d.kind === 'audiooutput' && d.label !== '');
        const availableMicrophones = devices.filter(d => d.kind === 'audioinput' && d.label !== '');

        setSpeakerDevices(availableSpeakers);
        setMicrophoneDevices(availableMicrophones);
      } else {
        setSpeakerDevices(undefined);
        setMicrophoneDevices(undefined);
      }
    })();
  }, [isVisible]);

  const getOptions = (devices: MediaDeviceInfo[]) =>
    devices.map(device => (
      <option key={device.deviceId} value={device.deviceId}>
        {device.label}
      </option>
    ));

  const isAudioEnabled = speakerDevices && microphoneDevices;
  const isVideoEnabled = cameraDevices && isAudioEnabled;

  return (
    <Backdrop isVisible={isVisible} onClick={handleClose}>
      <Content isVisible={isVisible} onClick={e => e.stopPropagation()}>
        <Heading2 style={{ marginBottom: margins.size3 }}>Your video and audio settings</Heading2>
        <Select
          icon={settingsCameraIcon}
          onChange={e => setCamera(e.target.value)}
          disabled={!isVideoEnabled}
          value={selectedCamera}
        >
          {isVideoEnabled ? (
            getOptions(cameraDevices as MediaDeviceInfo[])
          ) : (
            <option selected>Camera blocked</option>
          )}
        </Select>
        <Select
          icon={settingsSpeakerIcon}
          onChange={e => setSpeaker(e.target.value)}
          disabled={!isAudioEnabled}
          value={selectedSpeaker}
        >
          {isAudioEnabled ? (
            getOptions(speakerDevices as MediaDeviceInfo[])
          ) : (
            <option selected>Speaker blocked</option>
          )}
        </Select>
        <Select
          icon={settingsMicrophoneIcon}
          onChange={e => setMicrophone(e.target.value)}
          disabled={!isAudioEnabled}
          value={selectedMicrophone}
        >
          {isAudioEnabled ? (
            getOptions(microphoneDevices as MediaDeviceInfo[])
          ) : (
            <option selected>Microphone blocked</option>
          )}
        </Select>
        <Button onClick={handleClose}>Done</Button>
      </Content>
    </Backdrop>
  );
};

const Backdrop = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: ${globalTransitionSettings};
  opacity: ${p => (p.isVisible ? 1 : 0)};
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
`;

const Content = styled.div<{ isVisible: boolean }>`
  width: 600px;
  background-color: ${colors.whiteMain};
  padding: ${margins.size4};
  border-radius: 5px;
  text-align: start;

  transition: ${globalTransitionSettings};
  transform: translateY(${p => (p.isVisible ? 0 : '-50px')});
`;

const Select = styled.select<{ icon: string }>`
  color: #444;
  margin-bottom: ${margins.size3};
  padding: ${margins.size2};
  padding-left: 30px;
  width: 100%;
  border: 1px solid ${colors.greyMain};
  border-radius: 5px;
  background-image: url(${p => p.icon}),
    linear-gradient(${colors.whiteMain} 0%, ${colors.greyLight} 100%);
  background-repeat: no-repeat;
  background-position: left ${margins.size2} top 50%, 0 0;

  &:hover {
    cursor: pointer;
    border: 1px solid ${colors.primaryMain};
  }
`;
