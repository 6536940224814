import React, { useState } from 'react';
import moment, { Moment } from 'moment';

import { Text, FlexRow, margins } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { useTestBuckets, useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { SimpleModalWrap, useSimpleModal, WhiteCardHeader } from 'js/components/shared/MobileModal';
import { AutoselectableOption } from 'js/components/shared/AutoselectableOption';

import { DeletionModal } from '../components/DeletionModal';
import { ToggleSection } from '../components/shared-components/ToggleSection';
import { SettingsSectionsTitles } from '../constants';

const MONTH_DAY = 'MMM Do';
const ISO = 'YYYY-MM-DD hh:mm:ss';

export const PauseOrDelete: React.FC = () => {
  const { snooze_until } = useUser();
  const { cohesiveAutopilotTest } = useTestBuckets();

  const { updateUser } = useUserContextProvider();

  const [deletingUser, setDeletingUser] = useState(false);

  const { openModal, closeModal, wrapperProps } = useSimpleModal();

  const snoozeOptions = [1, 2, 3, 4].map(i => {
    const targetTime = moment.utc().add(7 * i, 'days');
    return { id: i, value: targetTime, name: targetTime.format(MONTH_DAY) };
  });

  if (snooze_until) {
    snoozeOptions.unshift({ id: 0, value: moment.utc(), name: 'Unpause' });
  }

  const updateSnoozeTime = (snoozeUntil: Moment) => {
    closeModal();
    updateUser({ snoozeUntil: snoozeUntil.format(ISO) });
  };

  return (
    <>
      {!cohesiveAutopilotTest && (
        <>
          <SimpleModalWrap {...wrapperProps}>
            <WhiteCardHeader title="Pause account" onClose={closeModal} />
            <Text style={{ textAlign: 'center', marginBottom: margins.size3 }}>
              How long would you like to pause your account?
            </Text>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {snoozeOptions.map(snooze => (
                <AutoselectableOption
                  key={snooze.id}
                  text={snooze.name}
                  selected={false}
                  onClick={() => updateSnoozeTime(snooze.value)}
                />
              ))}
            </div>
          </SimpleModalWrap>

          <ToggleSection
            headingTitle={SettingsSectionsTitles.PAUSE_ACCOUNT}
            subtitle={
              snooze_until
                ? `Paused until ${moment(new Date(snooze_until))
                    .utc()
                    .format(MONTH_DAY)}`
                : `Take a break from meeting new people on Lunchclub. You will still be able to chat with your past matches.`
            }
            isOn={!!snooze_until}
            onClick={openModal}
          />
        </>
      )}

      <FlexRow adapting alignItems="left">
        <Button
          onClick={() => setDeletingUser(true)}
          tertiary
          large
          style={{ margin: 0, background: 'none' }}
        >
          Delete my account
        </Button>
      </FlexRow>
      <DeletionModal showModal={deletingUser} handleClose={() => setDeletingUser(false)} />
    </>
  );
};
