import React from 'react';
import styled from 'styled-components/macro';

import { Text, colors, FlexColumn, FlexRow, media, fonts, fontSizes, borders } from 'css/css';

import { CadenceTypes, CadenceType } from '../constants';

interface Props {
  onClick: (cadence: CadenceType) => void;
}

export const CadenceSelector: React.FC<Props> = ({ onClick }) => {
  return (
    <CadenceSelectorContainer>
      {Object.keys(CadenceTypes).map(option => (
        <>
          <SettingsCadenceSelectorItem onClick={() => onClick(CadenceTypes[option])} key={option}>
            <Text>{CadenceTypes[option].title}</Text>
          </SettingsCadenceSelectorItem>
        </>
      ))}
    </CadenceSelectorContainer>
  );
};

const CadenceSelectorContainer = styled(FlexColumn)`
  border-radius: 12px;
  width: 100%;
  margin: 0;
`;

const BaseCadenceSelectorItem = styled(FlexRow)`
  border: 1px solid ${colors.greyMain};
  justify-content: space-between;
  border-top: ${borders.transparent};
  border-bottom: ${borders.transparent};
  width: 100%;
  padding: 12px;
  text-align: center;

  ${Text} {
    color: ${colors.blackMid};
  }

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-top: 1px solid ${colors.greyMain};
  }
  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px solid ${colors.greyMain};
  }
  &:hover {
    cursor: pointer;
    background-color: ${colors.greyLight};
  }
`;

const SettingsCadenceSelectorItem = styled(BaseCadenceSelectorItem)`
  border: none;
  &:first-child {
    border-top: none;
  }
  &:last-child {
    border-bottom: none;
  }
  ${media.desktop} {
    text-align: left;

    &:first-child {
      border-top: none;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  ${media.mobile} {
    background-color: ${colors.greyLight};
    text-align: center;
    justify-content: center;
    &:hover {
      background-color: ${colors.greyMain};
    }
    ${Text} {
      font-family: ${fonts.bold};
      font-size: ${fontSizes.size2};
      color: ${colors.primaryMain};
    }
  }
`;
