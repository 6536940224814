import React from 'react';

import { UnblockPermissionsModal } from 'js/components/call/UnblockPermissionsModal';
import { ScreenSharePermissionModal } from 'js/components/call/ScreenSharePermissionModal';

import { useUIStateContext } from '../../contexts/UIStateContext';

import { DeviceInUseModal } from './DeviceInUseModal';
import { DeviceNotFoundModal } from './DeviceNotFoundModal';
import { SettingsModal } from './SettingsModal';
import { FeedbackModal } from './FeedbackModal';
import { CallFeedbackModal } from './CallFeedbackModal';
import { CallExitModal } from './CallExitModal';

export const Modals: React.FC = () => {
  const {
    setShowUnblockModal,
    showUnblockModal,
    showScreenSharePermissionModal,
    setShowScreenSharePermissionModal,
  } = useUIStateContext();

  return (
    <>
      <SettingsModal />
      <FeedbackModal />
      <CallExitModal />
      <CallFeedbackModal />
      <DeviceNotFoundModal />
      <DeviceInUseModal />
      <UnblockPermissionsModal showModal={showUnblockModal} setShowModal={setShowUnblockModal} />
      <ScreenSharePermissionModal
        showModal={showScreenSharePermissionModal}
        setShowModal={setShowScreenSharePermissionModal}
      />
    </>
  );
};
