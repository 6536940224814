import React, { createRef, useState } from 'react';
import styled from 'styled-components/macro';

import { colors, fonts, margins, Text } from 'css/css';

import { useAutoCompleteSearch } from 'js/util/react-select';
import { BackendLocale } from 'js/components/weekly/types';
import { SmallArrow } from 'js/components/shared/SmallArrow';

import { searchXSvg, searchSvg, locationSvg } from 'img/registration-v2';

import { CheckmarkAnimated } from '../mobile/shared';

import { CurrentLocationButton } from './CurrentLocationButton';

type CitySelectProps = {
  focused: boolean;
  featuredLocales: BackendLocale[];
  setFocused: (focused: boolean) => void;
  onSubmit: (value: { locale: BackendLocale } | { googlePlaceId: string } | null) => void;
};

export const CitySelectMobile: React.FC<CitySelectProps> = ({
  focused,
  setFocused,
  featuredLocales,
  onSubmit,
}) => {
  const { query, setSearchQuery, searchResults, isDebouncing, error } = useAutoCompleteSearch();
  const [filled, setFilled] = useState(false);
  const inputRef = createRef<HTMLInputElement>();
  const onClear = () => {
    setSearchQuery('');
    onSubmit(null);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <CitySelectMobileContainer>
      {filled ? (
        <CitySelectRightIcon onClick={onClear}>
          <CheckmarkAnimated />
        </CitySelectRightIcon>
      ) : (
        query.length > 0 && (
          <CitySelectRightIcon onClick={onClear}>
            <img src={searchXSvg} alt="clear" />
          </CitySelectRightIcon>
        )
      )}
      {query.length === 0 && !focused && (
        <InputPlaceholder>
          <img src={searchSvg} alt="search" style={{ marginRight: 10 }} />
          Search for your city
        </InputPlaceholder>
      )}
      <input
        ref={inputRef}
        type="text"
        value={query}
        onChange={e => {
          setSearchQuery(e.target.value);
          onSubmit(null);
        }}
        onFocus={() => {
          setFocused(true);
          setFilled(false);
        }}
        onBlur={() => {
          setFocused(false);
        }}
      />
      {!focused && (
        <>
          <CurrentLocationButton
            onGotGooglePlaceId={googlePlaceId => onSubmit({ googlePlaceId })}
          />
          <Text color={colors.whiteMain} style={{ padding: `${margins.size2} ${margins.size4}` }}>
            We connect you to people you should know. In your city or around the world!
          </Text>
        </>
      )}
      {!focused || filled ? (
        <div />
      ) : query.length === 0 ? (
        <ResultsMenu>
          {featuredLocales.slice(0, 5).map(l => (
            <Result
              key={l.id}
              onMouseDown={e => {
                e.preventDefault();
                if (inputRef.current) inputRef.current.blur();
                setSearchQuery(l.name || '');
                onSubmit({ locale: l });
                setFilled(true);
              }}
            >
              <img src={locationSvg} alt="location" />
              <div style={{ marginLeft: 10, flex: 1 }}>{l.name}</div>
              <SmallArrow color={colors.whiteMain} direction="right" />
            </Result>
          ))}
          <Result style={{ opacity: 0.5, marginLeft: 36 }}>And many more!</Result>
        </ResultsMenu>
      ) : searchResults.length > 0 ? (
        <ResultsMenu>
          {searchResults.map(r => (
            <Result
              key={r.place_id}
              onMouseDown={e => {
                e.preventDefault();
                if (inputRef.current) inputRef.current.blur();
                setSearchQuery(r.description);
                onSubmit({ googlePlaceId: r.place_id });
                setFilled(true);
              }}
            >
              <img src={locationSvg} alt="location" />
              <div style={{ marginLeft: 10, flex: 1 }}>{r.description}</div>
              <SmallArrow direction="right" color={colors.whiteMain} />
            </Result>
          ))}
        </ResultsMenu>
      ) : isDebouncing ? (
        <ResultsMenu>
          <ResultsPlaceholder>Loading...</ResultsPlaceholder>
        </ResultsMenu>
      ) : error ? (
        <ResultsMenu>
          <ResultsPlaceholder>
            Sorry, we were unable to load locations, please try again.
          </ResultsPlaceholder>
        </ResultsMenu>
      ) : (
        undefined
      )}
    </CitySelectMobileContainer>
  );
};

const CitySelectMobileContainer = styled.div`
  width: 100%;
  position: relative;
  & > input {
    width: 100%;
    padding: 10px 16px;
    outline: none;
    border: none;
    border-radius: 10px;
    font-size: 18px;
  }
`;

const InputPlaceholder = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fonts.bold};
  color: ${colors.blackMain};
  user-select: none;
  pointer-events: none;
`;

const CitySelectRightIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 14px;
`;

const ResultsMenu = styled.div`
  color: white;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 56px;
  animation: Dropdown 150ms linear 200ms normal forwards;
  max-height: 0;
  overflow-y: scroll;

  @keyframes Dropdown {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 50vh;
    }
  }
`;

const ResultsPlaceholder = styled.div`
  padding: 10px 16px;
  opacity: 0.7;
`;

const Result = styled.div`
  padding: 10px 16px;
  display: flex;
  align-items: center;
`;
