import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import {
  Heading3,
  Text,
  FlexColumn,
  media,
  colors,
  margins,
  Clickable,
  borders,
  BUTTON_HEIGHT,
} from 'css/css';

import Channel from 'types/channel';

import { Backdrop } from 'js/components/shared/MobileModal';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { postBlockProfile } from 'js/util/api';
import { useOutsideAlerter } from 'js/util/custom-hooks';
import { useNotifContext } from 'js/util/notif-context';

interface Props {
  setIsSeeMoreDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  firstName: string;
  isBlocked: boolean;
  profileId: string;
  openScheduler?: (isReconnect: boolean) => void;
  selectedChannel?: Channel;
  setIsBlocked?: React.Dispatch<React.SetStateAction<boolean>>;
  isFirstTimeScheduling?: boolean;
}

export const MoreProfileOptions: React.FC<Props> = ({
  setIsSeeMoreDropdownOpen,
  firstName,
  isBlocked,
  profileId,
  openScheduler,
  selectedChannel,
  setIsBlocked,
  isFirstTimeScheduling,
}) => {
  const { blockUser } = useChatContext();
  const { showNotif } = useNotifContext();

  const seeMoreDropdownRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(seeMoreDropdownRef, () => setIsSeeMoreDropdownOpen(false));

  const blockOrUnblockUser = (unblock = false) => {
    postBlockProfile({ profileId, unblock });
    blockUser(profileId, unblock); // Updates chat block status
    showNotif({
      message: unblock ? `You've unblocked ${firstName}.` : `You've blocked ${firstName}.`,
    });
    setIsSeeMoreDropdownOpen(false);
    if (setIsBlocked) setIsBlocked(!unblock);
  };

  const isMobileAndInCall = !!window.bridge?.exitChat;

  return (
    <StyledBackdrop isOpen>
      <DropdownContainer onClick={e => e.stopPropagation()} ref={seeMoreDropdownRef}>
        {isBlocked ? (
          <>
            <BlockedNotif>
              <Heading3>You&apos;ve blocked {firstName}</Heading3>
              <Text>
                {firstName} is blocked from sending you messages and will not appear in your
                Lunchclub suggestions.
              </Text>
            </BlockedNotif>
            <DropdownButton onClick={() => blockOrUnblockUser(true)}>
              <Heading3>Unblock</Heading3>
            </DropdownButton>
          </>
        ) : (
          <>
            {!isMobileAndInCall && !!selectedChannel?.matchCode && (
              <>
                <Link to={`/call/${selectedChannel.matchCode}?ref=chat`} style={{ width: '100%' }}>
                  <DropdownButton isTop>
                    <Heading3>Join meeting room</Heading3>
                  </DropdownButton>
                </Link>
                {selectedChannel?.canReconnect && openScheduler && (
                  <DropdownButton onClick={() => openScheduler(true)}>
                    <Heading3>Reconnect</Heading3>
                  </DropdownButton>
                )}
                {selectedChannel?.canReschedule && openScheduler && (
                  <DropdownButton onClick={() => openScheduler(false)}>
                    <Heading3>{isFirstTimeScheduling ? 'Schedule meeting' : 'Reschedule'}</Heading3>
                  </DropdownButton>
                )}
              </>
            )}
            <DropdownButton
              isTop={!selectedChannel?.matchCode}
              onClick={() => blockOrUnblockUser()}
            >
              <Heading3>Block</Heading3>
            </DropdownButton>
            <a
              style={{ textDecoration: 'none', width: '100%' }}
              href="mailto:support+report@lunchclub.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <DropdownButton>
                <Heading3>Report</Heading3>
              </DropdownButton>
            </a>
          </>
        )}
      </DropdownContainer>
    </StyledBackdrop>
  );
};
const DropdownContainer = styled(FlexColumn)`
  width: 100%;
  position: initial;

  ${media.mobile} {
    position: fixed;
    bottom: 0;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    background-color: ${colors.whiteMain};
    border-radius: 15px 15px 0px 0px;
    margin: 0px;
    padding-bottom: env(safe-area-inset-bottom);
  }
`;

const DropdownButton = styled(Clickable)<{ isTop?: boolean }>`
  width: 100%;
  border-bottom: ${borders.standard};
  min-height: ${BUTTON_HEIGHT};

  ${media.mobile} {
    border-bottom: none;
  }
`;

const BlockedNotif = styled.div`
  width: 100%;
  border-bottom: ${borders.standard};
  padding: ${margins.size4};
  ${media.mobile} {
    border-bottom: none;
  }
`;

const StyledBackdrop = styled(Backdrop)`
  ${media.desktop} {
    background-color: ${colors.whiteMain};
    z-index: 0;
    width: 100%;
    position: static;
    transition: none;
    -webkit-transition-property: none;
    -moz-transition-property: none;
    -o-transition-property: none;
    transition-property: none;
    animation: none;
    height: unset;
  }
  ${media.mobile} {
    z-index: 4;
  }
`;
