import React from 'react';

import { margins, FlexColumn, Heading1 } from 'css/css';

import { Button } from 'js/components/shared/Button';

export const NewMeetingCard = ({ navigateToWeekly }: any) => (
  <FlexColumn>
    <Heading1>You in next week?</Heading1>

    <Button style={{ marginTop: margins.size4 }} onClick={navigateToWeekly}>
      I&apos;m in!
    </Button>
  </FlexColumn>
);
