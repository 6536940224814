import React, { useEffect, useState } from 'react';

import { margins, FlexRow, Heading2, Heading3 } from 'css/css';

import { ProductUser } from 'types/user';

import { CommunitySettings } from 'js/components/shared/CommunitySettings';
import { useUser } from 'js/providers/UserProvider';

import communitiesIcon from 'img/profile/communities.svg';

import { LineBreak } from './shared';

interface Props {
  editing: boolean;
  passedUser?: ProductUser;
}

export const Communities: React.FC<Props> = ({ editing, passedUser }) => {
  const user = useUser();
  const { organizations: communities } = passedUser || user;
  const [hasCommunities, setHasCommunities] = useState(false);

  useEffect(() => {
    setHasCommunities(!!communities?.length);
  }, [passedUser, editing]);

  if (!hasCommunities) return null;
  return (
    <>
      {editing && <LineBreak />}
      <FlexRow style={{ marginBottom: margins.size2 }} justifyContent="space-between">
        <FlexRow>
          {!editing && <img style={{ marginRight: margins.size3 }} src={communitiesIcon} alt="" />}
          {editing || passedUser ? (
            <Heading2>Communities</Heading2>
          ) : (
            <Heading3>Communities I’m in</Heading3>
          )}
        </FlexRow>
      </FlexRow>
      <CommunitySettings editing={editing} passedUser={passedUser} />
    </>
  );
};
