import React, { useEffect, useState } from 'react';
import LogRocket from 'logrocket';

import { useUser } from 'js/providers/UserProvider';

import { getIsMobile, isProduction } from './util';

export const LogWithLogRocket: React.FC = () => {
  const user = useUser();

  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    const readyToRegister =
      user && user.first_name && user.last_name && user.profile_id && !registered && isProduction();
    if (readyToRegister) {
      setRegistered(true);

      const { profile_id: publicId, first_name: firstName, last_name: lastName, email } = user;
      const name = `${firstName} ${lastName}`;

      if (getIsMobile()) {
        LogRocket.init('34ux6f/lunchclub-mobile');
      } else {
        LogRocket.init('34ux6f/lunchclub');
      }

      LogRocket.identify(publicId, {
        name,
        email,
      });
    }
  }, [user]);

  return <></>;
};
