import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';

import { FlexColumn, Heading1, Text, margins, Heading2, media, colors } from 'css/css';

import { Modal } from 'js/components/shared/NewModal';
import { useUIStateContext } from 'js/components/callv2/contexts/UIStateContext';
import { useMediaDevicesContext } from 'js/components/callv2/contexts/MediaDevicesContext';
import { Button } from 'js/components/shared/Button';

import videoOffIcon from 'img/call/video-off.svg';
import audioOffIcon from 'img/call/microphone-off.svg';

export const DeviceNotFoundModal: React.FC = () => {
  const { setShowDeviceNotFoundModal, showDeviceNotFoundModal } = useUIStateContext();
  const { camError, micError } = useMediaDevicesContext();

  const camNotFound = camError === 'not-found';
  const micNotFound = micError === 'not-found';

  const handleModalClose = () => {
    setShowDeviceNotFoundModal(false);
  };
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Modal hideScrollbar showModal={showDeviceNotFoundModal} handleClose={handleModalClose}>
      <FlexColumn alignItems="center">
        {camNotFound && micNotFound ? (
          <div style={{ display: 'flex' }}>
            <StyledIcon alt="" aria-hidden="true" src={videoOffIcon} />
            <div style={{ margin: `0 ${margins.size2}` }} />
            <StyledIcon alt="" aria-hidden="true" src={audioOffIcon} />
          </div>
        ) : camNotFound ? (
          <StyledIcon alt="" aria-hidden="true" src={videoOffIcon} />
        ) : (
          <StyledIcon alt="" aria-hidden="true" src={audioOffIcon} />
        )}

        <Heading1 style={{ margin: `${margins.size4} 0`, textAlign: 'center' }}>
          {camNotFound && micNotFound
            ? 'No camera and microphone detected'
            : camNotFound
            ? 'No camera detected'
            : 'No microphone detected'}
        </Heading1>
        <Heading2 style={{ marginBottom: margins.size4 }}>
          Lunchclub is unable to detect{' '}
          {camNotFound && micNotFound
            ? 'both your camera and microphone'
            : camNotFound
            ? 'your camera'
            : 'your microphone'}
        </Heading2>
        <Text style={{ marginBottom: margins.size4 }}>
          {camNotFound && micNotFound
            ? 'Your match will not be able to see or hear you. Please try connecting a camera and microphone, then reload this call.'
            : camNotFound
            ? 'Your match will not be able to see you. Please try connecting a camera, then reload this call.'
            : 'Your match will not be able to hear you. Please try connecting a microphone, then reload this call.'}
        </Text>
        <Button onClick={handleReload}>Reload call</Button>
      </FlexColumn>
    </Modal>
  );
};

const StyledIcon = styled(SVG)`
  width: ${margins.size6};
  height: ${margins.size6};
  margin: ${margins.size4} 0;
  color: ${colors.blackMain};

  ${media.mobile} {
    width: ${margins.size5};
    height: ${margins.size5};
    margin: ${margins.size2} 0;
  }
`;
