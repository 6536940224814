import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';

import { colors, fontSizes, Heading1, Heading2, margins } from 'css/css';

import { postMobileFeedback } from 'js/util/api';

import promptFeedbackSvg from 'img/mobile-root/prompt-feedback.svg';
import { xSvg } from 'img/weekly-v2';

import { Button } from '../shared/Button';
import { MobileFocusingTextarea } from '../shared/MobileFocusingTextarea';

enum FeedbackResponse {
  GOOD = 0,
  BAD = 1,
}

export const PromptForFeedback: React.FC<RouteComponentProps> = ({ history }) => {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [userFeedback, setUserFeedback] = useState<string>();

  const onFinish = () => {
    history.push('/invite');
  };

  if (openFeedback) {
    return (
      <PromptForNotificationsContainer>
        <Heading2>Tell us how we can improve?</Heading2>
        <StyledTextbox
          mobileFocusBehavior="always"
          mobileFocusLabel="How can we improve"
          placeholder="Enter your feedback here"
          id="Feedback freeform"
          value={userFeedback}
          onChange={e => setUserFeedback(e.target.value)}
        />

        <Button
          onClick={() => {
            postMobileFeedback(FeedbackResponse.BAD, userFeedback);
            onFinish();
          }}
          style={{ marginTop: margins.size3 }}
        >
          Send
        </Button>
        <Button
          onClick={() => {
            postMobileFeedback(FeedbackResponse.BAD);
            onFinish();
          }}
          secondary
          style={{ background: 'transparent' }}
        >
          Skip
        </Button>
      </PromptForNotificationsContainer>
    );
  }

  return (
    <PromptForNotificationsContainer>
      <div>
        <div
          tabIndex={0}
          role="button"
          onClick={onFinish}
          style={{ padding: 10, position: 'absolute', right: 0, top: 0, outline: 'none' }}
        >
          <img alt="close" src={xSvg} />
        </div>
        <img src={promptFeedbackSvg} alt="bell" style={{ marginBottom: margins.size4 }} />
        <Heading1>Enjoying Lunchclub?</Heading1>
        <Button
          large
          onClick={() => {
            postMobileFeedback(FeedbackResponse.GOOD);
            if (window.bridge?.promptForAppStoreReview) {
              window.bridge.promptForAppStoreReview();
            }
            onFinish();
          }}
          style={{ marginTop: margins.size2 }}
        >
          Yes! This is great
        </Button>
        <br />
        <Button
          secondary
          large
          style={{ marginTop: margins.size2 }}
          onClick={() => {
            setOpenFeedback(true);
          }}
        >
          No, not really
        </Button>
        <br />
      </div>
    </PromptForNotificationsContainer>
  );
};

const PromptForNotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: ${margins.size4};
  background: ${colors.whiteMain};
  position: absolute;
  inset: 0;
`;

const StyledTextbox = styled(MobileFocusingTextarea)`
  margin-top: ${margins.size3};
  outline: none;
  border: none;
  height: 125px;
  width: 100%;
  padding: ${margins.size3};
  background: ${colors.greyLight};
  border-radius: 3px;
  resize: none;
  font-size: ${fontSizes.size2};
  color: ${colors.blackMid};
  &::placeholder {
    color: ${colors.blackLight};
  }
`;
