import React, { useEffect, useState } from 'react';

import { FlexColumn, Heading1, margins, Text } from 'css/css';

import { CoachingPlanEligibilityDetails } from 'js/api/coaching-plan';
import { Button } from 'js/components/shared/Button';
import { Modal } from 'js/components/shared/NewModal';
import { postTestActivity } from 'js/util/api';
import { getPlatform } from 'js/util/util';

import { CoachingPlanVisualization } from './CoachingPlanInfo';
import { CoachingPlanCopies } from './CoachingPlanCopies';

interface Props {
  showCoachingPlan: boolean;
  details: CoachingPlanEligibilityDetails;
  planType: number;
  accept: () => void;
  decline: () => void;
}

export const CoachingPlanOptIn: React.FC<Props> = ({
  showCoachingPlan,
  details,
  planType,
  accept,
  decline,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!modalOpen && showCoachingPlan) {
      postTestActivity(`coaching-plan-${planType}`, `${getPlatform()}-view`);
    }
    setModalOpen(showCoachingPlan);
  }, [showCoachingPlan]);

  const handleClose = () => {
    postTestActivity(`coaching-plan-${planType}`, `${getPlatform()}-decline`);
    decline();
    setModalOpen(false);
  };

  const handleAccept = async () => {
    setIsLoading(true);
    postTestActivity(`coaching-plan-${planType}`, `${getPlatform()}-accept`);
    await accept();
    setModalOpen(false);
    setIsLoading(false);
  };

  return (
    <Modal hideScrollbar showModal={modalOpen} handleClose={handleClose}>
      <FlexColumn style={{ textAlign: 'center' }}>
        <Heading1 style={{ marginBottom: margins.size1 }}>
          Curated matches just for {details.plural}!
        </Heading1>
        <Text style={{ maxWidth: '400px' }}>{CoachingPlanCopies.Heading(details)}</Text>
        <CoachingPlanVisualization matches={details.matches} />
        <FlexColumn style={{ width: '100%' }}>
          <Button
            style={{ marginTop: margins.size1 }}
            large
            onClick={handleAccept}
            loading={isLoading}
          >
            Sign up for the plan!
          </Button>
        </FlexColumn>
      </FlexColumn>
    </Modal>
  );
};
