import { useSound } from './useSound';

import joinSoundMP3 from 'audio/call/join.mp3';

export const useSoundLoader = () => {
  const joinSound = useSound(joinSoundMP3);

  const load = () => {
    joinSound.load();
  };

  return { joinSound, load };
};
