import React from 'react';
import styled from 'styled-components/macro';

import { colors, SubText } from 'css/css';

interface Props {
  string: string;
  stringToRemove?: string;
  minLength: number;
  maxLength: number;
  noDisplay?: boolean;
}

export const StringLengthIndicator: React.FC<Props> = ({
  string,
  stringToRemove,
  minLength,
  maxLength,
  noDisplay,
}) => {
  let stringLength = string ? string.length : 0;
  if (stringToRemove) stringLength -= stringToRemove.length;

  const remainingCharacters = maxLength - stringLength;

  if (stringLength >= minLength && stringLength <= maxLength) {
    return (
      <StyledSubText $noDisplay={noDisplay} color={colors.blackLight}>
        {remainingCharacters} {remainingCharacters === 1 ? 'character' : 'characters'} remaining
      </StyledSubText>
    );
  }

  return (
    <StyledSubText color={colors.tertiary2Main} $noDisplay={noDisplay}>
      {stringLength} {stringLength === 1 ? 'character' : 'characters'}
      {stringLength > maxLength - 50
        ? ` (maximum ${maxLength} characters)`
        : minLength
        ? ` (minimum ${minLength} characters)`
        : ''}
    </StyledSubText>
  );
};

const StyledSubText = styled(SubText)<{ $noDisplay?: boolean }>`
  display: ${p => (p.$noDisplay ? 'none' : 'block')};
  text-align: right;
`;
