import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import Tree from 'react-d3-tree';
import { RawNodeDatum } from 'react-d3-tree/lib/types/common';

import { Card, Text, margins, colors, FlexRow, Link, Heading2 } from 'css/css';

import { getInviteTree } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';
import { getIsMobile } from 'js/util/util';
import { GreyPage } from 'js/components/shared/page-wrappers';
import { Avatar } from 'js/components/shared/Avatar';
import { Button } from 'js/components/shared/Button';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';

import { meetingsIcon } from 'img/connections';

export interface InviteTreeUser {
  name: string;
  attributes?: {
    image: string;
    name: string;
    profile: string;
    num_meetings: number;
  };
  children?: InviteTreeUser[];
}

// TODO: LCC
export const InviteTree: React.FC = () => {
  const history = useHistory();

  const [inviteTree, setInviteTree] = useState<InviteTreeUser>();
  const [isLoading, setIsLoading] = useState(true);
  const [numConvertedInvites, setNumConvertedInvites] = useState<number>();
  const [numSpawnedMeetings, setNumSpawnedMeetings] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const isMobile = getIsMobile();
  useMountEffect(() => {
    const fetchTree = async () => {
      const res = await getInviteTree();
      if (res.ok) {
        const { getJson } = res;
        setInviteTree(getJson.invite_tree);
        setNumConvertedInvites(getJson.total_invited);
        setNumSpawnedMeetings(getJson.total_meetings);
        setIsLoading(false);
      }
    };
    fetchTree();
  });

  const getContainerWidth = () => {
    if (containerRef.current) {
      return containerRef.current.offsetWidth;
    }
    return 0;
  };

  const Header = () => (
    <FlexRow style={{ marginBottom: margins.size2 }}>
      <Heading2 style={{ width: '100%', textAlign: 'left' }}>
        {!isMobile && 'Thanks to you, '}
        <span style={{ color: colors.secondaryMid }}>
          {numConvertedInvites} people have joined
        </span>{' '}
        {!isMobile && 'Lunchclub, and'}
        <span style={{ color: colors.primaryMain }}>
          {' '}
          {numSpawnedMeetings * 2} new connections were made.
        </span>
      </Heading2>
      <Button
        style={{ marginLeft: margins.size2 }}
        maxWidth="250px"
        onClick={() => history.push('/invite?ref=tree')}
      >
        Plant another seed
      </Button>
    </FlexRow>
  );

  const renderCustomNode = ({ nodeDatum }: { nodeDatum: RawNodeDatum }) => {
    // Attributes should always be valid
    if (!nodeDatum.attributes) return <g />;
    return (
      <g>
        {/* `foreignObject` requires width & height to be explicitly set. */}
        <foreignObject x="-50px" y="-70px" width="100px" height="120px">
          <Link to={`/member/${nodeDatum.attributes.profile}`}>
            {/* @ts-ignore */}
            <Avatar avatarId={nodeDatum.name} size4 src={nodeDatum.attributes.image} />
          </Link>
          <InfoContainer>
            <Text bold color={colors.blackMain}>
              {nodeDatum.name}
            </Text>
            <FlexRow justifyContent="center" alignItems="baseline">
              <img src={meetingsIcon} alt="" style={{ marginRight: margins.size1 }} />
              <Text>{nodeDatum.attributes.num_meetings}</Text>
            </FlexRow>
          </InfoContainer>
        </foreignObject>
      </g>
    );
  };

  return (
    <GreyPage>
      <Header />
      <WrapperCard ref={containerRef}>
        {isLoading ? (
          <SVGLoader />
        ) : (
          <Tree
            data={inviteTree as RawNodeDatum}
            orientation="vertical"
            renderCustomNodeElement={rd3tProps => renderCustomNode({ ...rd3tProps })}
            translate={{ x: getContainerWidth() / 2, y: 140 }}
            enableLegacyTransitions
            zoom={isMobile ? 0.5 : 1}
          />
        )}
      </WrapperCard>
    </GreyPage>
  );
};

const WrapperCard = styled(Card)`
  max-width: 900px;
  height: 80vh;
  padding: ${margins.size4};
`;

const InfoContainer = styled.div`
  width: 100%;
  height: 50%;
  margin-top: -${margins.size3};
  background-color: ${colors.greyLight};
  border-radius: 5px;
  padding-top: ${margins.size3};
`;
