import React, { useState, SetStateAction, useMemo } from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';
import moment, { Moment } from 'moment';

import {
  FlexColumn,
  colors,
  Heading3,
  margins,
  FlexRow,
  Clickable,
  Text,
  SubText,
  media,
} from 'css/css';
import { underlineAnimation } from 'css/animations';

import { getIsMobile } from 'js/util/util';

import close from 'img/shared/close-small.svg';

import { SchedulerCalendar } from './SchedulerCalendar';
import { CustomTimesSelector } from './CustomTimesSelector';

interface Props {
  userTimezone: string;
  selectedCustomTimeslots: string[];
  setSelectedCustomTimeslots: React.Dispatch<SetStateAction<string[]>>;
  hasReachedLimit: boolean;
  calendarState: 'closed' | 'selecting day' | 'selecting times';
  setCalendarState: React.Dispatch<SetStateAction<'closed' | 'selecting day' | 'selecting times'>>;
  setIsSuggestedTimeslotsOpen: React.Dispatch<SetStateAction<boolean>>;
  timeslotsSuggestedByMatch: string[];
}

export const CustomTimes: React.FC<Props> = ({
  userTimezone,
  selectedCustomTimeslots,
  setSelectedCustomTimeslots,
  hasReachedLimit,
  calendarState,
  setCalendarState,
  setIsSuggestedTimeslotsOpen,
  timeslotsSuggestedByMatch,
}) => {
  const [selectedDay, setSelectedDay] = useState<Moment | null>(null);
  const isMobile = useMemo(() => getIsMobile(), []);
  const handleHeaderButtonClick = () => {
    if (calendarState === 'closed' && !hasReachedLimit) {
      setIsSuggestedTimeslotsOpen(false);
      setCalendarState('selecting day');
      return;
    }
    if (calendarState === 'selecting day') {
      setCalendarState('selecting times');
    }
  };

  const deleteTimeslot = (timeslot: string) =>
    setSelectedCustomTimeslots(prev => prev.filter(t => t !== timeslot));

  return (
    <CustomTimesContainer>
      <Heading3>Add custom times</Heading3>
      <SubText style={{ marginBottom: margins.size2 }}>({userTimezone})</SubText>
      <CalendarContainer style={{ width: '100%' }}>
        {calendarState === 'closed' ? (
          <SelectDateAndTimeButton
            noOutline={hasReachedLimit}
            onClick={handleHeaderButtonClick}
            style={{ textAlign: 'left', width: '100%' }}
            $isInvalid={hasReachedLimit}
          >
            <CalendarHeaderButton $isInvalid={hasReachedLimit}>
              Select date and time
            </CalendarHeaderButton>
          </SelectDateAndTimeButton>
        ) : (
          calendarState === 'selecting times' &&
          !isMobile && (
            <Heading3 style={{ marginLeft: '8px', marginRight: 'auto' }}>
              {selectedDay?.format('ddd DD')}
            </Heading3>
          )
        )}
        {calendarState === 'selecting day' && (
          <SchedulerCalendar
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            openTimesSelector={() => setCalendarState('selecting times')}
          />
        )}
        {calendarState === 'selecting times' && (
          <CustomTimesSelector
            hasReachedLimit={hasReachedLimit}
            selectedDay={selectedDay}
            resetSelectedDay={() => setSelectedDay(null)}
            setCalendarState={setCalendarState}
            selectedCustomTimeslots={selectedCustomTimeslots}
            setSelectedCustomTimeslots={setSelectedCustomTimeslots}
            timeslotsSuggestedByMatch={timeslotsSuggestedByMatch}
          />
        )}
      </CalendarContainer>
      {!!selectedCustomTimeslots.length && (
        <SelectedTimeslotsContainer>
          {selectedCustomTimeslots.sort().map(timeslot => (
            <SelectedCustomTime key={timeslot}>
              <Clickable
                onClick={() => deleteTimeslot(timeslot)}
                style={{ height: '18px', marginRight: margins.size3 }}
              >
                <DeleteTimeslot alt="Delete timeslot" />
              </Clickable>
              <Text color={colors.whiteMain}>{moment(timeslot).format('MMM D h:mm a')}</Text>
            </SelectedCustomTime>
          ))}
        </SelectedTimeslotsContainer>
      )}
    </CustomTimesContainer>
  );
};

const CustomTimesContainer = styled(FlexColumn)`
  width: 100%;
  height: 100%;
  min-height: 340px;
  background-color: ${colors.greyLight};
  border-radius: 12px;
  padding: ${margins.size3};

  ${media.mobile} {
    padding-bottom: calc(max(env(safe-area-inset-bottom), 16px) + 52px);
    min-height: unset;
    height: 100%;
    flex-grow: 1;
  }
`;

const CalendarContainer = styled(FlexColumn)`
  width: 100%;
  background-color: ${colors.whiteMain};
  border-radius: 12px;
  padding: ${margins.size3};

  ${media.mobile} {
    min-height: 50px;
    margin-bottom: ${margins.size1};
  }
`;

export const CalendarHeaderButton = styled(Heading3)<{ $isInvalid?: boolean }>`
  color: ${p => (p.$isInvalid ? colors.primaryFaded : colors.primaryMain)};
  width: fit-content;
  ${p => !p.$isInvalid && underlineAnimation};
`;

const SelectedTimeslotsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: ${margins.size1};
  margin-top: ${margins.size1};
  margin-bottom: ${margins.size1};

  ${media.mobile} {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: ${margins.size5};
    margin-top: 0;
  }
`;

const SelectedCustomTime = styled(FlexRow)`
  background-color: ${colors.blackMain};
  padding: ${margins.size3};
  border-radius: 12px;
`;

const DeleteTimeslot = styled(SVG).attrs(() => ({ src: close }))`
  > path {
    stroke: ${colors.whiteMain};
  }
`;

const SelectDateAndTimeButton = styled(Clickable)<{ $isInvalid: boolean }>`
  ${p => p.$isInvalid && 'cursor: default;'};
`;
