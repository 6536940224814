import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import moment from 'moment';
import { Client as Twilio } from '@twilio/conversations';
import { Conversation } from '@twilio/conversations/lib/conversation';
import { Message } from '@twilio/conversations/lib/message';

import Channel, { BackendMessage, LocalMessage } from 'types/channel';

import { useUser } from 'js/providers/UserProvider';
import { getChatToken, getChannels, postMatchEventStatus } from 'js/util/api';
import { NETWORK_TYPES, EVENT_STATUS, consolidateTimeslots } from 'js/util/util';

// eslint-disable-next-line no-console
const noop = () => console.error('No parent ChatContextProvider found!');

type SavedTimeslots = Record<
  string,
  {
    timeslots: Record<'suggested' | 'custom', string[]>;
    confirmedTimeslot: string;
  }
>;

export const ChatContext = createContext<{
  twilio?: Twilio;
  setTwilio: React.Dispatch<React.SetStateAction<Twilio | undefined>>;
  channels: Channel[];
  selectedProfileId: string;
  setSelectedProfileId: React.Dispatch<React.SetStateAction<string>>;
  unreadCount: number;
  setUnreadCount: React.Dispatch<React.SetStateAction<number>>;
  isFetching: boolean;
  fetchPreviousPage: (profileId: string) => void;
  blockUser: (profileId: string, unblock: boolean) => void;
  confirmCall: (profileId: string, isForReconnect?: boolean) => void;
  savedTimeslots: SavedTimeslots;
  setSavedTimeslots: React.Dispatch<React.SetStateAction<SavedTimeslots>>;
  consolidatedTimeslots: Record<string, string[]>;
  updateChannel: (profileId: string, updater: (ch: Channel) => Channel) => void;
  updateLCMessages: () => Promise<void>;
  addLocalMessage: (profileId: string, message: LocalMessage) => void;
  clearLocalMessages: (profileId: string) => void;
  isPopupOpen: boolean;
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  lastMessageStatus: 'sending' | 'sent' | 'sent ';
  setLastMessageStatus: React.Dispatch<React.SetStateAction<'sending' | 'sent' | 'sent '>>;
  initChat: () => void;
}>({
  twilio: undefined,
  setTwilio: noop,
  channels: [],
  selectedProfileId: '',
  setSelectedProfileId: noop,
  unreadCount: 0,
  setUnreadCount: noop,
  isFetching: false,
  fetchPreviousPage: noop,
  blockUser: noop,
  confirmCall: noop,
  savedTimeslots: {},
  setSavedTimeslots: noop,
  consolidatedTimeslots: {},
  updateChannel: noop,
  updateLCMessages: async () => undefined,
  addLocalMessage: noop,
  clearLocalMessages: noop,
  isPopupOpen: false,
  setIsPopupOpen: noop,
  lastMessageStatus: 'sent ',
  setLastMessageStatus: noop,
  initChat: noop,
});

const sortByLatest = (a: Channel, b: Channel) => {
  const conversationRank = (c: Channel) => {
    if (
      c.localMessages.length > 0 ||
      c.messages.length > 0 ||
      c.lcMessages.length > 0 ||
      c.networkType === NETWORK_TYPES.MATCH
    ) {
      return 0;
    }
    if (c.networkType === NETWORK_TYPES.INVITEE) {
      return 1;
    }
    if (c.networkType === NETWORK_TYPES.INVITER) {
      return 2;
    }
    return 3;
  };

  const rankA = conversationRank(a);
  const rankB = conversationRank(b);

  if (rankA !== rankB) {
    return rankA < rankB ? -1 : 1;
  }

  const latestMessageA = moment(a.messages[a.messages.length - 1]?.dateUpdated || a.created);
  const latestMessageB = moment(b.messages[b.messages.length - 1]?.dateUpdated || b.created);

  const latestLocalA = moment(
    a.localMessages[a.localMessages.length - 1]?.dateUpdated || a.created,
  );
  const latestLocalB = moment(
    b.localMessages[b.localMessages.length - 1]?.dateUpdated || b.created,
  );

  const latestLunchclubA = moment(a.lcMessages[a.lcMessages.length - 1]?.dateUpdated || a.created);
  const latestLunchclubB = moment(b.lcMessages[b.lcMessages.length - 1]?.dateUpdated || b.created);

  const latestA = moment.max(latestMessageA, latestLocalA, latestLunchclubA);
  const latestB = moment.max(latestMessageB, latestLocalB, latestLunchclubB);

  if (!latestA.isSame(latestB)) {
    return latestA.isAfter(latestB) ? -1 : 1;
  }

  return a.name < b.name ? -1 : 1;
};

const hasUnreadMessages = (conversation: Conversation | undefined, myProfileId: string) => {
  if (!conversation?.attributes) return false;
  const myAttributes = (conversation?.attributes as any)?.[myProfileId];
  if (myAttributes && !myAttributes.hasRead) {
    return true;
  }
  return false;
};

const calculateUnreadCount = (myProfileId: string, channels: Channel[]) =>
  channels.reduce(
    (acc, channel) => (hasUnreadMessages(channel.conversation, myProfileId) ? acc + 1 : acc),
    0,
  );

export const useChatContext = () => useContext(ChatContext);

export const ChatContextProvider: React.FC = ({ children }) => {
  const [selectedProfileId, setSelectedProfileId] = useState<string>('');
  const [twilio, setTwilio] = useState<Twilio>();
  const [channels, setChannels] = useState<Channel[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [newConversationAdded, setNewConversationAdded] = useState<Conversation>();
  const [savedTimeslots, setSavedTimeslots] = useState<SavedTimeslots>({});
  const [consolidatedTimeslots, setConsolidatedTimeslots] = useState<Record<string, string[]>>({});
  const lastFetchedPageIndexes = useRef<Record<string, number>>({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [lastMessageStatus, setLastMessageStatus] = useState<'sending' | 'sent' | 'sent '>('sent ');

  const haveConversationsUpdated = useRef(false);

  const user = useUser();

  const myProfileId = user?.profile_id;

  const findChannel = (uniqueName: string) =>
    channels.find(pc => uniqueName.includes(pc.profileId));

  const conversationUpdatedHandler = ({ conversation }: { conversation: Conversation }) => {
    setUnreadCount(calculateUnreadCount(myProfileId, channels));

    const channel = findChannel(conversation.uniqueName);
    if (!channel) {
      return;
    }
    updateChannel(channel.profileId, (ch: Channel) => ({
      ...ch,
      conversation,
    }));

    if (window.bridge?.conversationUpdated) {
      const unread = hasUnreadMessages(conversation, myProfileId);
      window.bridge.conversationUpdated(channel.profileId, unread);
    }
  };

  const getFilteredMessages = (messages: any): any => {
    return messages.filter((message: any) => !message?.attributes?.lunchclub_message);
  };

  useEffect(() => {
    if (channels.length && !haveConversationsUpdated.current) {
      haveConversationsUpdated.current = true;
      const updatedChannels = channels.map(ch => {
        const newConversation = ch?.conversation
          ?.on('messageAdded', (message: Message) => {
            addMessageFromTwilio(ch.profileId, message);
          })
          .on('updated', conversationUpdatedHandler);
        return { ...ch, conversation: newConversation };
      });
      setChannels(updatedChannels);
      if (twilio) {
        twilio.on('conversationAdded', (conversation: Conversation) => {
          setNewConversationAdded(conversation);
        });
      }
    }
  }, [channels]);

  useEffect(() => {
    setConsolidatedTimeslots(
      selectedProfileId
        ? consolidateTimeslots([
            ...(savedTimeslots[selectedProfileId]?.timeslots?.suggested || []),
            ...(savedTimeslots[selectedProfileId]?.timeslots?.custom || []),
          ])
        : {},
    );
  }, [selectedProfileId, savedTimeslots]);

  useEffect(() => {
    if (newConversationAdded) {
      (async () => {
        const messagePaginator = await newConversationAdded.getMessages();
        const channel = findChannel(newConversationAdded.uniqueName);
        if (!channel) {
          initChat(true);
          return;
        }
        newConversationAdded.on('messageAdded', (message: Message) => {
          addMessageFromTwilio(channel.profileId, message);
        });
        newConversationAdded.on('updated', conversationUpdatedHandler);
        updateChannel(channel.profileId, (ch: Channel) => ({
          ...ch,
          conversation: newConversationAdded,
          uniqueName: newConversationAdded.uniqueName,
          messagePaginator,
          messages: getFilteredMessages(messagePaginator.items),
        }));
        channel.localMessages.forEach(localMessage => {
          sendLocalMessageToTwilio(channel.profileId, newConversationAdded, localMessage);
        });
      })();
    }
  }, [newConversationAdded]);

  const updateChannel = (profileId: string, updater: (ch: Channel) => Channel) => {
    setChannels(chs =>
      chs.map(c => (profileId === c.profileId ? updater(c) : c)).sort(sortByLatest),
    );
  };

  useEffect(() => {
    if (channels.length) {
      setUnreadCount(calculateUnreadCount(myProfileId, channels));
    }
  }, [twilio, channels.length, myProfileId]);

  useEffect(() => {
    if (user?.verified) {
      initChat();
    }
  }, [user?.verified]);

  const fetchPreviousPage = async (profileId: string) => {
    const channel = channels.find(pc => pc.profileId === profileId);
    if (
      !channel ||
      !channel.messagePaginator?.hasPrevPage ||
      channel.pageIndex < lastFetchedPageIndexes.current[profileId]
    )
      return;
    lastFetchedPageIndexes.current[profileId] = channel.pageIndex + 1;
    const prevMessagePaginator = await channel.messagePaginator.prevPage();
    updateChannel(profileId, (ch: Channel) => ({
      ...ch,
      messagePaginator: prevMessagePaginator,
      pageIndex: channel.pageIndex + 1,
      messages: getFilteredMessages(prevMessagePaginator.items).concat(ch.messages),
    }));
  };

  const blockUser = async (profileId: string, unblock: boolean) => {
    const channel = channels.find(pc => pc.profileId === profileId);
    if (!channel) {
      return;
    }
    updateChannel(profileId, (ch: Channel) => ({
      ...ch,
      isBlocked: !unblock,
    }));
  };

  const confirmCall = async (profileId: string, isForReconnect = false) => {
    const channel = channels.find(pc => pc.profileId === profileId);
    if (!channel || !channel.matchCode) {
      return;
    }
    if (!isForReconnect) {
      await postMatchEventStatus({ matchCode: channel.matchCode, status: EVENT_STATUS.confirmed });
    }

    updateChannel(profileId, (ch: Channel) => ({
      ...ch,
      userEventStatus: EVENT_STATUS.confirmed,
    }));
  };

  const addMessageFromTwilio = async (profileId: string, message: Message) => {
    const channel = channels.find(pc => pc.profileId === profileId);
    if (!channel || (message?.attributes as any)?.lunchclub_message) {
      return;
    }

    updateChannel(profileId, (ch: Channel) => ({
      ...ch,
      messages: ch.messages.find(x => x.sid === message.sid)
        ? ch.messages
        : [...ch.messages, message],
      localMessages: ch.localMessages.filter(
        localMessage =>
          message.body !== localMessage.body ||
          JSON.stringify(message.attributes) !== JSON.stringify(localMessage.attributes),
      ),
    }));
    setLastMessageStatus('sent');
    setTimeout(() => {
      setLastMessageStatus('sent ');
    }, 500);
  };

  // Conversation may not have assigned to the channel when this is called.
  // Therefore we can't just use `channel` instead of `profileId` and `conversation`.
  const sendLocalMessageToTwilio = async (
    profileId: string,
    conversation: Conversation,
    message: LocalMessage,
  ) => {
    await conversation.updateAttributes({
      ...conversation.attributes,
      [profileId]: { hasRead: false },
      ...message.conversationAttributes,
    });
    conversation.sendMessage(message.body, message.attributes);
  };

  const addLocalMessage = (profileId: string, message: LocalMessage) => {
    const channel = channels.find(pc => pc.profileId === profileId);
    if (!channel) {
      return;
    }
    updateChannel(profileId, (ch: Channel) => ({
      ...ch,
      localMessages: [...ch.localMessages, message],
    }));
    if (channel.conversation) {
      sendLocalMessageToTwilio(profileId, channel.conversation, message);
    }
  };

  const clearLocalMessages = async (profileId: string) => {
    const channel = channels.find(pc => pc.profileId === profileId);
    if (!channel) {
      return;
    }
    updateChannel(profileId, (ch: Channel) => ({
      ...ch,
      localMessages: [],
    }));
  };

  const updateLCMessages = async () => {
    const channelsRes = await getChannels();
    if (!channelsRes.ok) {
      return;
    }

    const fetchedConversations = channelsRes.getJson.conversations;
    const profileIdToLCMessages = fetchedConversations.reduce(
      (obj: Record<string, BackendMessage[]>, conversation) => ({
        ...obj,
        [conversation.profile]: conversation.lc_messages,
      }),
      {},
    );

    setChannels(chs =>
      chs.map(ch => ({
        ...ch,
        lcMessages:
          ch.profileId in profileIdToLCMessages
            ? profileIdToLCMessages[ch.profileId]
            : ch.lcMessages,
      })),
    );
  };

  const initChat = async (inBackground?: boolean) => {
    if (!inBackground) setIsFetching(true);
    const [channelsRes, tokenRes] = await Promise.all([getChannels(), getChatToken()]);
    if (!channelsRes.ok || !tokenRes.ok) return;
    const fetchedChannels: Channel[] = channelsRes.getJson.conversations.map(c => ({
      profileId: c.profile,
      avatar: c.image,
      name: c.name,
      firstName: c.first_name,
      metDate: c.met_date,
      created: c.last_updated,
      matchCode: c.match_code,
      userEventStatus: c.user_event_status,
      matchUserEventStatus: c.match_user_event_status,
      uniqueName: c.conversation_name,
      messages: [],
      pageIndex: 0,
      lcMessages: c.lc_messages,
      localMessages: [],
      isBlocked: c.blocked,
      canReschedule: c.can_reschedule_match,
      canReconnect: c.should_show_reconnect_header,
      isInPersonMatch: c.is_in_person_match,
      isSlantMatch: c.is_slant_match,
      isMorpheusMatch: c.is_morpheus_match,
      isMatchPreviouslyRescheduled: c.is_match_previously_rescheduled,
      reconnectHeaderSource: c.reconnect_header_source,
      networkType: c.network_type,
      prescheduledReconnectDate: c.prescheduled_reconnect_date,
    }));

    const initialTwilio = await Twilio.create(tokenRes.getJson.token);

    const convPaginators = [await initialTwilio.getSubscribedConversations()];
    while (convPaginators[convPaginators.length - 1].hasNextPage) {
      // eslint-disable-next-line no-await-in-loop
      convPaginators.push(await convPaginators[convPaginators.length - 1].nextPage());
    }
    const subscribedConversations = convPaginators.reduce<Conversation[]>(
      (acc, cur) => acc.concat(cur.items),
      [],
    );

    const convWithMessagePaginators = await Promise.all(
      subscribedConversations.map(async conv => ({
        conversation: conv,
        messagePaginator: await conv.getMessages(),
      })),
    );

    const chWithConversations: Channel[] = fetchedChannels.map(ch => {
      const match = convWithMessagePaginators.find(cwmp =>
        cwmp.conversation.uniqueName.includes(ch.profileId),
      );

      if (!match) return ch;
      const updateConversationMetDate = async (passedMatch: any, newMetDate: string) => {
        await passedMatch.conversation.updateAttributes({
          ...passedMatch.conversation.attributes,
          metDate: newMetDate,
        });
      };
      const adjustedMetDate = moment
        .tz(ch.metDate, user.locale_info.timezone)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
      if (ch.metDate && adjustedMetDate !== (match.conversation.attributes as any).metDate) {
        updateConversationMetDate(match, adjustedMetDate);
      }
      lastFetchedPageIndexes.current[ch.profileId] = 0;
      return {
        ...ch,
        conversation: match.conversation,
        uniqueName: match.conversation.uniqueName,
        messagePaginator: match.messagePaginator,
        messages: getFilteredMessages(match.messagePaginator.items),
      };
    });

    initialTwilio.on('tokenAboutToExpire', async () => {
      const newTokenRes = await getChatToken();
      if (newTokenRes.ok) {
        initialTwilio.updateToken(newTokenRes.getJson.token);
      }
    });

    haveConversationsUpdated.current = false;
    chWithConversations.sort(sortByLatest);
    setTwilio(initialTwilio);
    setChannels(chWithConversations);
    setUnreadCount(calculateUnreadCount(myProfileId, chWithConversations));

    setInterval(updateLCMessages, 1000 * 60); // every minute
    setIsFetching(false);
  };

  const chatContextValue = {
    twilio,
    setTwilio,
    channels,
    selectedProfileId,
    setSelectedProfileId,
    unreadCount,
    setUnreadCount,
    isFetching,
    fetchPreviousPage,
    blockUser,
    confirmCall,
    savedTimeslots,
    setSavedTimeslots,
    consolidatedTimeslots,
    updateChannel,
    updateLCMessages,
    addLocalMessage,
    clearLocalMessages,
    isPopupOpen,
    setIsPopupOpen,
    lastMessageStatus,
    setLastMessageStatus,
    initChat,
  };

  return <ChatContext.Provider value={chatContextValue}>{children}</ChatContext.Provider>;
};
