import React from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import { colors, globalTransitionSettings } from 'css/css';

import microphoneMuted from 'img/call/microphone-off.svg';
import microphoneUnmuted from 'img/call/microphone-on.svg';
import cameraOff from 'img/call/camera-off.svg';
import cameraOn from 'img/call/camera-on.svg';
import screenShareOff from 'img/call/screen-share-off.svg';
import screenShareOn from 'img/call/screen-share-on.svg';
import endIcon from 'img/call/end.svg';

import { useCallPermissionsContext } from './CallPermissionsContext';
import { VideoControls } from './VideoControls';

type Type = 'audio' | 'video' | 'screen' | 'end';

interface Props {
  type: Type;
  className?: string;
}

export const Control: React.FC<Props> = ({ type, className }) => {
  const {
    isAudioOn,
    isScreenShareOn,
    setAudioOn,
    setScreenShareOn,
    endCall,
  } = useCallPermissionsContext();

  if (type === 'video') {
    return <VideoControls />;
  }

  const isActive = type === 'audio' ? isAudioOn : type === 'screen' ? isScreenShareOn : false;

  const handleSettingClick = () => {
    // Note that this switch purposely contains seemingly redundant or verbose code.
    // This is done to provide this code segment two important features:
    // 1- An explicit on, or off feature, rather than re-utilizing existing state.
    // 2- A default state of off if code encounters unknown state.
    switch (type) {
      case 'audio':
        setAudioOn(!isAudioOn);
        break;
      case 'screen':
        setScreenShareOn(!isScreenShareOn);
        break;
      default:
        endCall();
    }
  };

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleSettingClick();
  };

  const Icon = isActive ? Icons[type].componentOn : Icons[type].componentOff;

  const ariaLabel = isActive ? Icons[type].enabledAriaLabel : Icons[type].disabledAriaLabel;

  return (
    <ControlContainer
      className={className}
      type={type}
      isActive={isActive}
      onClick={onClick}
      title={ariaLabel}
      role="button"
    >
      <Icon isActive={isActive} aria-label={ariaLabel} />
    </ControlContainer>
  );
};

const ControlContainer = styled.div<{ type: Type; isActive?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  transition: ${globalTransitionSettings};
  background-color: ${p =>
    p.type === 'end'
      ? colors.tertiary2Main
      : p.type === 'screen'
      ? p.isActive
        ? colors.whiteMain
        : colors.blackMain
      : p.isActive
      ? colors.blackMain
      : colors.whiteMain};

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

interface Icon {
  isActive: boolean;
}

const AudioMuted = styled(SVG).attrs({
  src: microphoneMuted,
  'alt-text': 'Audio icon',
})<Icon>`
  color: ${colors.blackMain};
`;
const AudioUnmuted = styled(SVG).attrs({ src: microphoneUnmuted, 'alt-text': 'Audio icon' })<
  Icon
>``;

const CameraOffIcon = styled(SVG).attrs(() => ({ src: cameraOff, 'alt-text': 'Video icon' }))<
  Icon
>``;
const CameraOnIcon = styled(SVG).attrs(() => ({ src: cameraOn, 'alt-text': 'Video icon' }))<Icon>``;

const ScreenShareOffIcon = styled(SVG).attrs(() => ({
  src: screenShareOff,
  'alt-text': 'Turn screen share on',
}))<Icon>``;
const ScreenShareOnIcon = styled(SVG).attrs(() => ({
  src: screenShareOn,
  'alt-text': 'Turn screen share off',
}))<Icon>``;

const EndCallIcon = () => <SVG src={endIcon} alt-text="End call icon" />;

export const Icons: Record<
  Type,
  {
    componentOn: any;
    componentOff: any;
    enabledAriaLabel: string;
    disabledAriaLabel: string;
  }
> = {
  audio: {
    componentOn: AudioUnmuted,
    componentOff: AudioMuted,
    enabledAriaLabel: 'Turn off audio (ctrl + d)',
    disabledAriaLabel: 'Turn on audio (ctrl + d)',
  },
  video: {
    componentOn: CameraOnIcon,
    componentOff: CameraOffIcon,
    enabledAriaLabel: 'Turn off video (ctrl + e)',
    disabledAriaLabel: 'Turn on video (ctrl + e)',
  },
  end: {
    componentOn: EndCallIcon,
    componentOff: EndCallIcon,
    enabledAriaLabel: 'Leave call',
    disabledAriaLabel: 'Leave call',
  },
  screen: {
    componentOn: ScreenShareOnIcon,
    componentOff: ScreenShareOffIcon,
    enabledAriaLabel: 'End sharing your screen',
    disabledAriaLabel: 'Start sharing your screen',
  },
};
