import React, { useState, useEffect, createContext, useContext } from 'react';
import DailyIFrame, { DailyCall, DailyCallOptions } from '@daily-co/daily-js';

import { Heading1 } from 'css/css';

import { ProductUser } from 'types/user';

import { useUser } from 'js/providers/UserProvider';
import { GreyPage } from 'js/components/shared/page-wrappers';

import { useSharedCallContext } from '../call/SharedCallContext';

export type SidebarView = 'profile' | 'chat' | '';

interface CallContextState {
  call?: DailyCall;
  match?: ProductUser;
  matchCode: string;
  feedbackCode?: string;
  startTime?: string;
  areControlsVisible: boolean;
  setAreControlsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  sidebarView: SidebarView;
  setSidebarView: React.Dispatch<React.SetStateAction<SidebarView>>;
  hasContext: boolean;
  allowScreenShare: boolean;
  allowVideoProcessing: boolean;
}

const DEFAULT_STATE: CallContextState = {
  matchCode: '',
  /* eslint-disable no-console */
  sidebarView: '',
  areControlsVisible: false,
  setAreControlsVisible: () => console.error('No parent CallContext found!'),
  setSidebarView: () => console.error('No parent CallContext found!'),
  hasContext: false,
  allowScreenShare: false,
  allowVideoProcessing: false,
};

const CallContext = createContext<CallContextState>(DEFAULT_STATE);

export const useCallContext = () => useContext(CallContext);

// https://github.com/daily-co/daily-js/issues/154 Missing userName property in DailyCallOptions type definition
interface DailyCallOptionsWithUsername extends DailyCallOptions {
  userName: string;
}

export const CallContextProvider: React.FC = ({ children }) => {
  const { match, matchCode, feedbackCode, startTime, url, hasContext } = useSharedCallContext();

  const [call, setCall] = useState<DailyCall>();
  const user = useUser();

  const [sidebarView, setSidebarView] = useState<SidebarView>('');
  const [areControlsVisible, setAreControlsVisible] = useState(true);
  const allowScreenShare = DailyIFrame.supportedBrowser().supportsScreenShare;
  const allowVideoProcessing = DailyIFrame.supportedBrowser().supportsVideoProcessing;

  useEffect(() => {
    if (url) {
      setCall(
        DailyIFrame.createCallObject({
          dailyConfig: {
            experimentalChromeVideoMuteLightOff: true, // Hides and shows green dot on Macbook cameras.
          },
        }),
      );
    }
  }, [url]);

  useEffect(
    () => () => {
      if (call) {
        call.leave();
      }
    },
    [call],
  );

  useEffect(() => {
    if (call && user) {
      call.join({
        url,
        userName: user.profile_id,
      } as DailyCallOptionsWithUsername);
    }
  }, [call, user]);

  return !DailyIFrame.supportedBrowser().supported ? (
    <UnsupportedBrowser />
  ) : (
    <CallContext.Provider
      value={{
        call,
        match,
        matchCode,
        feedbackCode,
        startTime,
        areControlsVisible,
        setAreControlsVisible,
        sidebarView,
        setSidebarView,
        hasContext,
        allowScreenShare,
        allowVideoProcessing,
      }}
    >
      {children}
    </CallContext.Provider>
  );
};

const UnsupportedBrowser = () => (
  <GreyPage>
    <Heading1>Unfortunately video calls are not yet supported on this browser!</Heading1>
    <br />
    <Heading1>
      Please try Safari for iOS, Chrome for Android, or Chrome on desktop for the best experience.
    </Heading1>
  </GreyPage>
);
