import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { useHistory } from 'react-router';

import { getDefaultCallDevices, postDefaultCallDevices } from 'js//util/api';
import { getIsMobile } from 'js/util/util';

import { useCallContext } from './CallContext';

interface CallPermissionsContextState {
  isAudioOn: boolean;
  isVideoOn: boolean;
  isScreenShareOn: boolean;
  setIsAudioOn: React.Dispatch<React.SetStateAction<boolean>>;
  setIsVideoOn: React.Dispatch<React.SetStateAction<boolean>>;
  setIsScreenShareOn: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCamera?: string;
  selectedSpeaker?: string;
  selectedMicrophone?: string;
  setAudioOn: (status: boolean) => void;
  setVideoOn: (status: boolean) => void;
  setScreenShareOn: (status: boolean) => void;
  setSpeaker: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCamera: React.Dispatch<React.SetStateAction<string | undefined>>;
  setMicrophone: React.Dispatch<React.SetStateAction<string | undefined>>;
  showUnblockModal: boolean;
  setShowUnblockModal: React.Dispatch<React.SetStateAction<boolean>>;
  showScreenSharePermissionModal: boolean;
  setShowScreenSharePermissionModal: React.Dispatch<React.SetStateAction<boolean>>;
  endCall: (starRatingFromModal?: number, redirect?: () => void) => void;
}

const DEFAULT_STATE: CallPermissionsContextState = {
  isAudioOn: false,
  isVideoOn: false,
  isScreenShareOn: false,
  /* eslint-disable no-console */
  setIsAudioOn: () => console.error('No parent CallPermissionsContext found!'),
  setIsVideoOn: () => console.error('No parent CallPermissionsContext found!'),
  setIsScreenShareOn: () => console.error('No parent CallPermissionsContext found!'),
  setAudioOn: () => console.error('No parent CallPermissionsContext found!'),
  setVideoOn: () => console.error('No parent CallPermissionsContext found!'),
  setScreenShareOn: () => console.error('No parent CallPermissionsContext found!'),
  setSpeaker: () => console.error('No parent CallPermissionsContext found!'),
  setCamera: () => console.error('No parent CallPermissionsContext found!'),
  setMicrophone: () => console.error('No parent CallPermissionsContext found!'),
  showUnblockModal: false,
  setShowUnblockModal: () => console.error('No parent CallPermissionsContext found!'),
  showScreenSharePermissionModal: false,
  setShowScreenSharePermissionModal: () => console.error('No parent CallPermissionsContext found!'),
  endCall: () => console.error('No parent CallPermissionsContext found!'),
};

export const CallPermissionsContext = createContext<CallPermissionsContextState>(DEFAULT_STATE);

export const useCallPermissionsContext = () => useContext(CallPermissionsContext);

const AVAILABLE_DEVICES_POLL_INTERVAL_TIME = 1000;

type toggleDeviceArgs = {
  status?: boolean;
  blocked?: boolean;
};

export const CallPermissionsContextProvider: React.FC = ({ children }) => {
  const { call, feedbackCode } = useCallContext();
  const history = useHistory();

  const [isAudioOn, setIsAudioOn] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [isScreenShareOn, setIsScreenShareOn] = useState(false);
  const [showUnblockModal, setShowUnblockModal] = useState(false);
  const [showScreenSharePermissionModal, setShowScreenSharePermissionModal] = useState(false);
  const [didSetDevices, setDidSetDevices] = useState(false);
  const [didSetDeviceIds, setDidSetDeviceIds] = useState(false);

  // Selected device ids
  const [selectedCamera, setSelectedCamera] = useState<string>();
  const [selectedSpeaker, setSelectedSpeaker] = useState<string>();
  const [selectedMicrophone, setSelectedMicrophone] = useState<string>();

  const isMobile = getIsMobile();

  const setAudioOn = ({ status = false, blocked = false }: toggleDeviceArgs) => {
    if (!didSetDeviceIds) {
      return;
    }
    if (blocked) {
      setSelectedMicrophone(undefined);
      setSelectedSpeaker(undefined);
    }
    // Must have an audio device connected to toggle audio
    else if (!selectedMicrophone) {
      setShowUnblockModal(true);
    } else if (call) {
      call.setLocalAudio(status);
    }
  };

  const setVideoOn = ({ status = false, blocked = false }: toggleDeviceArgs) => {
    if (!didSetDeviceIds) {
      return;
    }
    if (blocked) {
      setSelectedCamera(undefined);
    }
    // Must have a video device connected to toggle audio
    else if (!selectedCamera) {
      setShowUnblockModal(true);
    } else if (call) {
      call.setLocalVideo(status);
    }
  };

  const setScreenShareOn = async ({ status = false, blocked = false }: toggleDeviceArgs) => {
    if (blocked || !call) {
      setIsScreenShareOn(false);
    } else if (!status) {
      call.stopScreenShare();
    } else {
      try {
        call.startScreenShare({
          mediaStream: await navigator.mediaDevices.getDisplayMedia({ video: true }),
        });
      } catch (err) {
        if (
          err instanceof DOMException &&
          ((err.name === 'NotAllowedError' && err.message === 'Permission denied by system') ||
            err.code === err.NOT_FOUND_ERR)
        )
          setShowScreenSharePermissionModal(true);
      }
    }
  };

  const findDeviceIdWithPriority = (
    devices: MediaDeviceInfo[],
    deviceKind: string,
    defaultDeviceId: string | null,
  ) => {
    const chosenDeviceId =
      !!defaultDeviceId &&
      devices.find(d => d.kind === deviceKind && d.label !== '' && d.deviceId === defaultDeviceId)
        ?.deviceId;
    if (chosenDeviceId) return chosenDeviceId;
    return devices.find(d => d.kind === deviceKind && d.label !== '')?.deviceId;
  };

  const setAvailableDevices = async () => {
    try {
      const { isAudioEnabled, isVideoEnabled, devices } = await getDevicePermissions();

      const res = await getDefaultCallDevices(isMobile);
      const { video_device_id, speaker_device_id, microphone_device_id } = res?.getJson;

      if (isVideoEnabled) {
        const cameraDeviceId = findDeviceIdWithPriority(devices, 'videoinput', video_device_id);
        setSelectedCamera(cameraDeviceId);
      }

      if (isAudioEnabled) {
        const speakerDeviceId = findDeviceIdWithPriority(devices, 'audiooutput', speaker_device_id);
        setSelectedSpeaker(speakerDeviceId);

        const microphoneDeviceId = findDeviceIdWithPriority(
          devices,
          'audioinput',
          microphone_device_id,
        );
        setSelectedMicrophone(microphoneDeviceId);
      }
      setDidSetDeviceIds(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    // Prompt user for microphone & camera access on initial render
    (async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      } catch (e) {
        console.error(e);
      }
      await setAvailableDevices();
      setDidSetDevices(true);
    })();
  }, []);

  const pollAvailableDevices = useCallback(async () => {
    try {
      const { isAudioEnabled, isVideoEnabled } = await getDevicePermissions();

      if (selectedCamera !== undefined && !isVideoEnabled) {
        setVideoOn({ blocked: true });
      }
      if (selectedMicrophone !== undefined && selectedSpeaker !== undefined && !isAudioEnabled) {
        setAudioOn({ blocked: true });
      }
    } catch (e) {
      console.error(e);
    }
  }, [selectedCamera, selectedMicrophone]);

  useEffect(() => {
    if (didSetDevices) {
      /**
       * Poll available devices every 1s since users can disable device permissions during a call
       * Inspired by: https://github.com/jitsi/lib-jitsi-meet/blob/fad985e95a9e8a4fb8a1b8b1ad2cfef75370c866/modules/RTC/RTCUtils.js#L412-L416
       */

      const pollInterval = window.setInterval(
        pollAvailableDevices,
        AVAILABLE_DEVICES_POLL_INTERVAL_TIME,
      );
      return () => window.clearInterval(pollInterval);
    }
    return () => undefined;
  }, [didSetDevices, pollAvailableDevices]);

  useEffect(() => {
    if (call) {
      call.setInputDevicesAsync({
        videoDeviceId: selectedCamera,
      });
    }
  }, [call, selectedCamera]);

  useEffect(() => {
    if (call) {
      call.setInputDevicesAsync({
        audioDeviceId: selectedMicrophone,
      });
    }
  }, [call, selectedMicrophone]);

  useEffect(() => {
    if (call) {
      call.setOutputDevice({ outputDeviceId: selectedSpeaker });
    }
  }, [call, selectedSpeaker]);

  useEffect(() => {
    if (didSetDeviceIds && call && selectedCamera && selectedMicrophone && selectedSpeaker) {
      postDefaultCallDevices({
        videoDeviceId: selectedCamera,
        speakerDeviceId: selectedSpeaker,
        microphoneDeviceId: selectedMicrophone,
        isMobile,
      });
    }
  }, [didSetDeviceIds, call, selectedCamera, selectedMicrophone, selectedSpeaker, isMobile]);

  const endCall = (starRatingFromModal?: number, redirect?: () => void) => {
    setAudioOn({ status: false });
    setVideoOn({ status: false });
    setScreenShareOn({ status: false });
    if (feedbackCode && !redirect) {
      const ratingParam = starRatingFromModal !== undefined ? `&rating=${starRatingFromModal}` : '';
      history.push(`/feedback?feedback_code=${feedbackCode}${ratingParam}&ref=call`);
    } else if (redirect) {
      redirect();
    } else {
      history.push('/home');
    }
  };

  return (
    <CallPermissionsContext.Provider
      value={{
        isAudioOn,
        isVideoOn,
        isScreenShareOn,
        setIsAudioOn,
        setIsVideoOn,
        setIsScreenShareOn,
        selectedCamera,
        selectedSpeaker,
        selectedMicrophone,
        setAudioOn: (status: boolean) => setAudioOn({ status }),
        setVideoOn: (status: boolean) => setVideoOn({ status }),
        setScreenShareOn: (status: boolean) => setScreenShareOn({ status }),
        setSpeaker: setSelectedSpeaker,
        setCamera: setSelectedCamera,
        setMicrophone: setSelectedMicrophone,
        showUnblockModal,
        setShowUnblockModal,
        showScreenSharePermissionModal,
        setShowScreenSharePermissionModal,
        endCall,
      }}
    >
      {children}
    </CallPermissionsContext.Provider>
  );
};

export const getDevicePermissions = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();

  const isAudioEnabled = !!devices.find(d => d.kind === 'audioinput' && d.label !== '');

  // Disabling audio also prevents video from working on Dailyco's side
  const isVideoEnabled =
    isAudioEnabled && !!devices.find(d => d.kind === 'videoinput' && d.label !== '');

  return { isAudioEnabled, isVideoEnabled, devices };
};
