import React from 'react';

import { margins, FlexRow } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { runAndScroll } from 'js/util/util';

export const Nav = ({ onBack, onNext, nextInvalid, loading, invalidMessage }: any) => {
  return (
    <FlexRow
      style={{ maxWidth: '500px', margin: `${margins.size4} auto`, flexWrap: 'wrap-reverse' }}
    >
      {onBack && (
        <Button
          secondary
          style={{ margin: `${margins.size1} auto` }}
          onClick={() => runAndScroll(onBack)}
          maxWidth="240px"
          invalidMessage={invalidMessage}
        >
          Back
        </Button>
      )}

      <Button
        invalid={nextInvalid}
        onClick={() => runAndScroll(onNext)}
        maxWidth="240px"
        style={{ margin: `${margins.size1} auto` }}
        loading={loading}
        invalidMessage={invalidMessage}
      >
        Next
      </Button>
    </FlexRow>
  );
};
