import React, { useState } from 'react';

import { postIntroResponse, postInvitees } from 'js/util/api';
import { apiFailure } from 'js/util/strings';
import { INVITE_SOURCES, simpleStringHash, INTRO_RESPONSES } from 'js/util/util';
import { useNotifContext } from 'js/util/notif-context';

import { DeckCardContainer, CardQuestionWithHeightContainer } from '../styles';
import { UserDescriptionBoxes } from '../shared/UserDescriptionBoxes';
import { yesNoButtons } from '../shared/DeckCardButtons';
import { IntroCardData } from '../types';
import { CARD_TRANSITION_LEAVE_DURATION } from '../constants';

interface Props {
  deckCardId: number;
  cardId: string;
  data: IntroCardData;
  completeCard: (cardId: string) => void;
}

export const IntroCard: React.FC<Props> = ({ deckCardId, cardId, data, completeCard }) => {
  const { showNotif } = useNotifContext();

  const [cardQuestionHeight, setCardQuestionHeight] = useState<number>();
  const [deckCardContentHeight, setDeckCardContentHeight] = useState<number>();
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejected, setRejected] = useState(false);

  const { friend_1: profile1, friend_2: profile2, id: suggestionId } = data;

  const isProfileOneOffPlatform = 'email' in profile1;
  const isProfileTwoOffPlatform = 'email' in profile2;
  const hasOffPlatformUser = isProfileOneOffPlatform || isProfileTwoOffPlatform;
  const profile1AvatarId = isProfileOneOffPlatform
    ? simpleStringHash(profile1.email)
    : simpleStringHash(profile1.profile);
  let profile2AvatarId = isProfileTwoOffPlatform
    ? simpleStringHash(profile2.email)
    : simpleStringHash(profile2.profile);
  profile2AvatarId += profile1AvatarId === profile2AvatarId ? 1 : 0;

  const showNotifError = () =>
    showNotif({
      message: apiFailure.message,
      level: 'error',
    });

  const submitAcceptIntro = async () => {
    if (loading) return;
    setLoading(true);
    if (hasOffPlatformUser) {
      const res1 = await postIntroResponse({
        responseId: INTRO_RESPONSES.ACCEPT,
        suggestionId,
      });
      const offPlatformEmails = [
        ...(isProfileOneOffPlatform ? [profile1.email] : []),
        ...(isProfileTwoOffPlatform ? [profile2.email] : []),
      ];
      const res2 = await postInvitees({
        emails: offPlatformEmails,
        introSuggestionId: suggestionId,
        source: INVITE_SOURCES.FEED_INTRO_SUGGESTION,
      });
      if (res1.status === 200 && res2.status === 200) {
        setClicked(true);
        setTimeout(() => completeCard(cardId), CARD_TRANSITION_LEAVE_DURATION);
      } else {
        showNotifError();
      }
    } else {
      const res = await postIntroResponse({
        responseId: INTRO_RESPONSES.ACCEPT,
        suggestionId,
      });
      if (res.ok) {
        setClicked(true);
        setTimeout(() => completeCard(cardId), CARD_TRANSITION_LEAVE_DURATION);
      } else {
        showNotifError();
      }
    }
    setLoading(false);
  };

  const submitRejectIntro = async () => {
    if (loading) return;
    setLoading(true);
    const res = await postIntroResponse({ responseId: INTRO_RESPONSES.REJECT, suggestionId });
    if (res.ok) {
      setRejected(true);
      setTimeout(() => completeCard(cardId), CARD_TRANSITION_LEAVE_DURATION);
    } else {
      showNotifError();
    }
    setLoading(false);
  };

  const submitIntro = (shouldAccept: boolean) =>
    shouldAccept ? submitAcceptIntro() : submitRejectIntro();

  return (
    <DeckCardContainer
      deckCardId={deckCardId}
      isLoading={loading}
      buttons={{
        kind: 'emoji',
        values: yesNoButtons(submitIntro),
      }}
      isSelected={clicked || rejected}
      setContentHeight={setDeckCardContentHeight}
      feedSession="Regular"
    >
      <CardQuestionWithHeightContainer setHeight={setCardQuestionHeight}>
        Do you want to introduce {profile1.name}
        {` and `}
        {profile2.name}?
      </CardQuestionWithHeightContainer>
      {deckCardContentHeight !== undefined && cardQuestionHeight !== undefined && (
        <UserDescriptionBoxes
          deckCardId={deckCardId}
          profiles={[
            {
              id: profile1AvatarId,
              description: profile1.headline,
              subDescription: isProfileOneOffPlatform ? profile1.sub_headline : undefined,
              imageSrc: profile1.image,
            },
            {
              id: profile2AvatarId,
              description: profile2.headline,
              subDescription: isProfileTwoOffPlatform ? profile2.sub_headline : undefined,
              imageSrc: profile2.image,
            },
          ]}
          isIntro
          maxHeight={deckCardContentHeight - cardQuestionHeight}
        />
      )}
    </DeckCardContainer>
  );
};
