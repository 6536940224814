import React, { useState, useEffect, SetStateAction } from 'react';
import styled from 'styled-components/macro';
import moment, { Moment } from 'moment';

import {
  FlexColumn,
  colors,
  Heading3,
  margins,
  FlexRow,
  fontSizes,
  fonts,
  Clickable,
  Text,
  SubText,
  media,
} from 'css/css';

import { AvailibilityExplanation } from 'js/components/shared/availibility-explanation';
import { consolidateTimeslots } from 'js/util/util';
import { SmallArrow } from 'js/components/shared/SmallArrow';

import { TimeslotButton } from './TimeslotButton';

interface Props {
  userTimezone: string;
  currWeekTimeslots: string[][];
  busyTimeslots: string[];
  selectedSuggestedTimeslots: string[];
  setSelectedSuggestedTimeslots: React.Dispatch<SetStateAction<string[]>>;
  currentTimeWithBuffer: string;
  startDay: Moment;
  startWeek: number;
  setStartWeek: React.Dispatch<SetStateAction<number>>;
  hasReachedLimit: boolean;
  isSuggestedTimeslotsOpen: boolean;
  setIsSuggestedTimeslotsOpen: React.Dispatch<SetStateAction<boolean>>;
  closeCustomTimes: () => void;
  timeslotsSuggestedByMatch: string[];
}

export const SuggestedTimes: React.FC<Props> = ({
  userTimezone,
  currWeekTimeslots,
  busyTimeslots,
  selectedSuggestedTimeslots,
  setSelectedSuggestedTimeslots,
  currentTimeWithBuffer,
  startDay,
  startWeek,
  setStartWeek,
  hasReachedLimit,
  isSuggestedTimeslotsOpen,
  setIsSuggestedTimeslotsOpen,
  closeCustomTimes,
  timeslotsSuggestedByMatch,
}) => {
  const [selectedDay, setSelectedDay] = useState(0);
  const [consolidatedSelectedTimeslots, setConsolidatedSelectedTimeslots] = useState<
    Record<string, string[]>
  >({});

  useEffect(() => {
    setConsolidatedSelectedTimeslots(consolidateTimeslots(selectedSuggestedTimeslots));
  }, [selectedSuggestedTimeslots]);

  if (!currWeekTimeslots || !currWeekTimeslots[selectedDay]) {
    return null;
  }

  const busyTimeslotsExist = busyTimeslots.length > 0;
  return (
    <SuggestedTimesContainer>
      <Heading3>Suggested times</Heading3>
      <SubText style={{ marginBottom: margins.size2 }}>({userTimezone})</SubText>
      <CalendarContainer style={{ width: '100%' }}>
        <WeekHeading startDay={startDay} startWeek={startWeek} setStartWeek={setStartWeek} />
        <FlexRow style={{ width: '100%', justifyContent: 'space-between' }}>
          {currWeekTimeslots.map((day, i) => (
            <FlexColumn style={{ marginBottom: margins.size2 }} key={day[0]}>
              <Text color={colors.blackLight}>{moment(day[0]).format('dd')}</Text>
              <DayButton
                key={day[0]}
                $isSelected={selectedDay === i && isSuggestedTimeslotsOpen}
                onClick={() => {
                  closeCustomTimes();
                  setIsSuggestedTimeslotsOpen(true);
                  setSelectedDay(i);
                }}
                $hasSelectedTimes={
                  !!consolidatedSelectedTimeslots[moment(day[0]).format('ddd MMM D')]
                }
              >
                <DayButtonText
                  $isSelected={selectedDay === i && isSuggestedTimeslotsOpen}
                  $hasSelectedTimes={
                    !!consolidatedSelectedTimeslots[moment(day[0]).format('ddd MMM D')]
                  }
                >
                  {moment(day[0]).format('D')}
                </DayButtonText>
              </DayButton>
            </FlexColumn>
          ))}
        </FlexRow>
        {isSuggestedTimeslotsOpen && (
          <>
            <TimeslotsContainer>
              {currWeekTimeslots[selectedDay].map(timeslot => (
                <TimeslotButton
                  key={timeslot}
                  selectedSuggestedTimeslots={selectedSuggestedTimeslots}
                  setSelectedSuggestedTimeslots={setSelectedSuggestedTimeslots}
                  timeslot={timeslot}
                  currentTimeWithBuffer={currentTimeWithBuffer}
                  busyTimeslots={busyTimeslots}
                  hasReachedLimit={hasReachedLimit}
                  timeslotsSuggestedByMatch={timeslotsSuggestedByMatch}
                />
              ))}
            </TimeslotsContainer>
            <AvailibilityExplanation shouldDisplay={busyTimeslotsExist} />
          </>
        )}
      </CalendarContainer>
    </SuggestedTimesContainer>
  );
};

interface WeekHeadingProps {
  startDay: Moment;
  startWeek: number;
  setStartWeek: React.Dispatch<SetStateAction<number>>;
}

const WeekHeading: React.FC<WeekHeadingProps> = ({ startDay, startWeek, setStartWeek }) => {
  const endDay = startDay.clone().add(5, 'days');
  const shouldDisplayNextMonth = !startDay.isSame(endDay, 'month');
  return (
    <FlexRow justifyContent="space-between" style={{ width: '100%', marginBottom: margins.size3 }}>
      {startWeek > 0 ? (
        <Clickable onClick={() => setStartWeek(prev => (prev > 0 ? prev - 1 : prev))}>
          <SmallArrow direction="left" color={colors.primaryMain} />
        </Clickable>
      ) : (
        <div style={{ width: '14px' }} />
      )}
      <FlexColumn>
        <Heading3>
          {startDay.format('MMMM')} {shouldDisplayNextMonth && `- ${endDay.format('MMMM')}`}
        </Heading3>
      </FlexColumn>
      {startWeek < 2 ? (
        <Clickable onClick={() => setStartWeek(prev => prev + 1)}>
          <SmallArrow direction="right" color={colors.primaryMain} />
        </Clickable>
      ) : (
        <div style={{ width: '14px' }} />
      )}
    </FlexRow>
  );
};

const SuggestedTimesContainer = styled(FlexColumn)`
  width: 100%;
  background-color: ${colors.greyLight};
  border-radius: 12px;
  padding: ${margins.size3};
`;

const CalendarContainer = styled(FlexColumn)`
  width: 100%;
  background-color: ${colors.whiteMain};
  border-radius: 12px;
  padding: ${margins.size3};
`;

export const DayButton = styled(Clickable)<{
  $isSelected: boolean;
  $hasSelectedTimes?: boolean;
  $isInvalid?: boolean;
}>`
  padding: ${margins.size2};
  border-radius: 50%;
  width: 36px;
  height: 36px;
  ${p => p.$hasSelectedTimes && `background-color: ${colors.blackMain};`}
  ${p => p.$isSelected && `background-color: ${colors.primaryMain};`}

  &:focus {
    ${p => p.$isInvalid && `outline: none;`}
  }

  &:hover {
    ${p =>
      !p.$hasSelectedTimes &&
      !p.$isSelected &&
      !p.$isInvalid &&
      `background-color: ${colors.greyLight};`}
  }
`;

export const DayButtonText = styled(Text)<{
  $isSelected: boolean;
  $hasSelectedTimes?: boolean;
  $isInvalid?: boolean;
}>`
  ${p =>
    p.$isSelected &&
    `color: ${colors.whiteMain}; font-family: ${fonts.bold};
  font-size: ${fontSizes.size2}; `}
  ${p => p.$hasSelectedTimes && `color: ${colors.whiteMain};`}
  ${p => p.$isInvalid && `color: ${colors.greyMain};`}

`;

const TimeslotsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  grid-gap: ${margins.size1};

  ${media.mobile} {
    grid-template-columns: repeat(4, 1fr);
  }
`;
