import React from 'react';
import styled from 'styled-components/macro';

import { FlexRow, Heading3, colors, margins, FlexColumn, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { useChatContext } from 'js/providers/ChatContextProvider';

import calendar from 'img/chat/calendar-grey.svg';

interface Props {
  isMyMessage: boolean;
  isFirstTimeScheduling: boolean;
  isReconnecting: boolean;
  type: 'request' | 'canceled' | 'confirmed';
  timeslots: string[];
  userTimezone: string;
  meetingTime: string;
  isLastSchedulerMessage: boolean;
  isScheduling: boolean;
}

export const SchedulerMessage: React.FC<Props> = ({
  isMyMessage,
  isFirstTimeScheduling,
  isReconnecting,
  type,
  timeslots = [],
  userTimezone = '',
  meetingTime = '',
  isLastSchedulerMessage,
  isScheduling,
}) => {
  const { setIsPopupOpen } = useChatContext();

  const messageData = {
    request: {
      header: `Suggested ${timeslots.length > 1 ? 'times' : 'a time'}`,
      body: `${timeslots?.length} available time${timeslots?.length > 1 ? 's' : ''}`,
    },
    canceled: {
      header: `Canceled ${
        isReconnecting ? 'reconnect' : isFirstTimeScheduling ? 'schedule' : 'reschedule'
      } request.`,
      body: '',
    },
    confirmed: {
      header: 'Confirmed time',
      body: `${meetingTime} ${userTimezone}.`,
    },
  };

  const { header, body } = messageData[type];

  return (
    <>
      <ConfirmationMessage style={{ alignSelf: isMyMessage ? 'flex-end' : 'flex-start' }}>
        <FlexRow style={{ gap: margins.size2 }}>
          <img src={calendar} alt="Calendar" />
          <FlexColumn alignItems="flex-start" style={{ textAlign: 'left' }}>
            <Heading3>{header}</Heading3>
            {body && <Text>{body}</Text>}
          </FlexColumn>
        </FlexRow>
        {isLastSchedulerMessage && isScheduling && (
          <Button
            large
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              setIsPopupOpen(true);
            }}
          >
            Review times
          </Button>
        )}
      </ConfirmationMessage>
    </>
  );
};

const ConfirmationMessage = styled(FlexColumn)`
  background-color: ${colors.greyLight};
  border-radius: 20px;
  padding: ${margins.size3};
  align-items: center;
  margin-top: 2px;
  gap: ${margins.size3};
`;
