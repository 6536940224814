import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { borders, colors, media, FlexColumn } from 'css/css';

import { ProductUser } from 'types/user';

import { getUserProfile } from 'js/util/api';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import {
  ProfileAvatarImage,
  ProfileBackground,
  ProfileMobile,
} from 'js/components/profile/ProfileMobile';

interface Props {
  profileId: string;
}

export const ConversationProfileCard: React.FC<Props> = ({ profileId }) => {
  const [match, setMatch] = useState<ProductUser>();

  useEffect(() => {
    setMatch(undefined);
    (async () => {
      const res = await getUserProfile(profileId);
      if (res.ok) {
        setMatch(res.getJson);
      }
    })();
  }, [profileId]);

  return (
    <ProfileContainer>
      {!match ? (
        <div style={{ margin: 'auto' }}>
          <SVGLoader center={false} large />
        </div>
      ) : (
        <>
          <ProfileBackground noCover style={{ minHeight: 100 }}>
            <ProfileAvatarImage alt="profile" src={match.image} avatarId={match.profile_id} />
          </ProfileBackground>
          <ProfileMobile profile={match} endorsements={match.endorsements} />
        </>
      )}
    </ProfileContainer>
  );
};

const ProfileContainer = styled(FlexColumn)`
  height: 100%;
  width: 30%;
  align-items: end;
  border-left: ${borders.standard};
  text-align: left;
  overflow-y: auto;
  background-color: ${colors.greyLight};
  ${media.tablet} {
    display: none;
  }
`;
