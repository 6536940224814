import React from 'react';
import styled from 'styled-components/macro';

import { borders, colors, margins, fontSizes, FlexColumn, FlexRow, SubText } from 'css/css';

import { cleanNames, MIN_HEADLINE_LENGTH, MAX_HEADLINE_LENGTH } from 'js/util/util';
import { onboarding } from 'js/util/strings';

import emailIcon from 'img/registration/profile/email-icon.svg';
import greenAvatar from 'img/registration/profile/green-avatar.svg';
import redAvatar from 'img/registration/profile/red-avatar.svg';
import orangeAvatar from 'img/registration/profile/orange-avatar.svg';

import { StringLengthIndicator } from './StringLengthIndicator';

const { profile } = onboarding;

const avatars = [greenAvatar, redAvatar, orangeAvatar];

export const DefaultSampleHeadlines = [
  'Kim is a Product Designer at Facebook who writes about design in everyday life. She loves working on impactful products, and talking to people about them.',
  'Sami is a mechanical engineer who is working on a new startup idea in the EV battery space.',
  'Omar is an opera singer who writes his own blog.',
];

export const Headline = ({
  headline,
  setHeadline,
  firstName,
}: {
  headline: string;
  setHeadline: (str: string) => void;
  firstName: string;
}) => {
  const cleanFirstName = cleanNames(firstName);
  // removes hardcoded 'FirstName is'
  const headlineChanged = (event: any) => {
    const incomingHeadline = event.target.value;
    const toRemove = `${cleanFirstName} is `;
    const writtenPart = incomingHeadline.substring(toRemove.length);
    setHeadline(writtenPart);
  };
  return (
    <>
      <ProfileTextbox
        // @ts-ignore
        placeholder={profile.placeholder}
        value={`${cleanFirstName} is ${headline}`}
        onChange={headlineChanged}
      />

      <StringLengthIndicator
        string={headline}
        minLength={MIN_HEADLINE_LENGTH}
        maxLength={MAX_HEADLINE_LENGTH}
      />

      <>
        <div
          style={{
            display: 'flex',
            marginTop: margins.size3,
            marginBottom: margins.size2,
            color: colors.blackLight,
          }}
        >
          <img src={emailIcon} alt="Examples" />

          <div style={{ marginLeft: margins.size2 }}>Sample intros</div>
        </div>

        <FlexColumn>
          {DefaultSampleHeadlines.map((sampleHeadline, index) => (
            <FlexRow width="100%" alignItems="flex-start">
              <img src={avatars[index % 3]} alt="Avatar" />

              <IntroContainer>
                <SubText color={colors.blackMid}>{sampleHeadline}</SubText>
              </IntroContainer>
            </FlexRow>
          ))}
        </FlexColumn>
      </>
    </>
  );
};

const ProfileTextbox = styled.textarea`
  outline: none;
  border: none;
  height: 125px;
  width: 100%;
  padding: ${margins.size3};
  background: ${colors.greyLight};
  border-radius: 3px;
  resize: none;
  font-size: ${fontSizes.size2};
  color: ${colors.blackMid};
  &::placeholder {
    color: ${colors.blackLight};
  }
`;

const IntroContainer = styled.div`
  text-align: left;
  border: ${borders.standard};
  padding: ${margins.size4};
  margin-left: ${margins.size2};
  margin-bottom: ${margins.size1};
  flex: 1;
  border-radius: 3px;
`;
