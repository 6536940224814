import React from 'react';
import styled from 'styled-components/macro';

import {
  Card,
  media,
  Clickable,
  SubText,
  colors,
  margins,
  Heading3,
  globalTransitionSettings,
  cardHoverShadow,
} from 'css/css';

import { ConditionalLink } from 'js/components/shared/ConditionalLink';
import { NETWORK_TYPES, changeSlashDelimitedDateToDot } from 'js/util/util';

import joined from 'img/homepage/joined.svg';
import firstMeeting from 'img/homepage/first-meeting.svg';
import nthMeeting from 'img/homepage/nth-meeting.svg';

import { Avatar } from '../Avatar';

import { EVENT_TYPES } from './constants';

const getCardDetails = (
  numMatches: any,
  eventType: any,
  networkType: any,
  lastMet: any,
  eventDate: any,
  happened: any,
) => {
  const cardDetails = { event: null, eventImg: null, networkTypeString: null, eventDotDate: null };
  let futureMatch = false;
  let futureEvent = false;
  const todaysDate = new window.Date();

  if (lastMet) {
    const matchDate = new window.Date(lastMet);
    futureMatch = matchDate.setHours(0, 0, 0, 0) > todaysDate.setHours(0, 0, 0, 0);
  }
  if (eventDate) {
    const eventDateObject = new window.Date(eventDate);
    futureEvent = eventDateObject.setHours(0, 0, 0, 0) > todaysDate.setHours(0, 0, 0, 0);
  }
  if (eventType === EVENT_TYPES.JOINED) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
    cardDetails.event = (
      <OverflowHeader color={colors.secondaryMid}>joined Lunchclub</OverflowHeader>
    );
    cardDetails.eventImg = joined;
  } else if (eventType === EVENT_TYPES.MEETING) {
    if (numMatches === 1) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
      cardDetails.event = (
        <OverflowHeader color={colors.tertiary3Main}>
          {futureEvent ? 'will have' : 'had'} their first meeting
        </OverflowHeader>
      );
      cardDetails.eventImg = firstMeeting;
    } else {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
      cardDetails.event = (
        <OverflowHeader color={colors.tertiary1Main}>{`had ${numMatches} meetings`}</OverflowHeader>
      );
      cardDetails.eventImg = nthMeeting;
    }
  } else if (eventType === EVENT_TYPES.PENDING) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
    cardDetails.event = <OverflowHeader color={colors.blackLight}>invite pending</OverflowHeader>;
  }
  if (networkType === NETWORK_TYPES.INVITEE) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
    cardDetails.networkTypeString = <NetworkTypeString>invited by you</NetworkTypeString>;
  }
  if (networkType === NETWORK_TYPES.INVITER) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
    cardDetails.networkTypeString = <NetworkTypeString>invited you</NetworkTypeString>;
  }
  if (networkType === NETWORK_TYPES.MATCH) {
    const dotDate = changeSlashDelimitedDateToDot(lastMet);
    let str = 'you met';
    if (!happened) {
      str = 'matched on';
    } else if (futureMatch) {
      str = 'you will meet';
    }
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
    cardDetails.networkTypeString = <NetworkTypeString>{`${str} ${dotDate}`}</NetworkTypeString>;
  }
  if (eventDate) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
    cardDetails.eventDotDate = (
      <OnDate hasUpperDate={!!cardDetails.networkTypeString}>{`${
        numMatches !== 1 && eventType === EVENT_TYPES.MEETING
          ? futureEvent
            ? 'next '
            : 'last '
          : ''
      }on ${changeSlashDelimitedDateToDot(eventDate)}`}</OnDate>
    );
  }
  return cardDetails;
};

export const NetworkCard = ({
  name,
  image,
  profileId,
  numMatches,
  eventType,
  networkType,
  lastMet,
  joinedDate,
  email,
  eventDate,
  happened,
  urlRef,
}: any) => {
  const { event, eventImg, networkTypeString, eventDotDate } = getCardDetails(
    numMatches,
    eventType,
    networkType,
    lastMet,
    eventDate || joinedDate,
    happened,
  );
  const link = name && name !== 'None None' && profileId;
  return (
    <Container link={link}>
      <ConditionalLink
        style={{ textDecoration: 'none' }}
        to={`/member/${profileId}?ref=${urlRef}`}
        link={link}
      >
        <UserCard>
          <ArangeMobile>
            <RelativeContainer>
              <Avatar
                avatarId={name}
                size4
                src={image}
                alt={name}
                style={{ margin: margins.size4 }}
              />

              <CornerImage src={eventImg || ''} alt="" />
            </RelativeContainer>

            <ProfileText>
              <OverflowHeader>{name && name !== 'None None' ? name : email}</OverflowHeader>
              {event && event}
              {eventDotDate && eventDotDate}
              {networkTypeString && networkTypeString}
            </ProfileText>
          </ArangeMobile>
        </UserCard>
      </ConditionalLink>
    </Container>
  );
};

type ContainerTypes = { link: boolean };
const Container = styled(Clickable)<ContainerTypes>`
  ${media.mobile} {
    width: 100%;
  }
  transition: ${globalTransitionSettings};
  cursor: ${p => !p.link && 'default'};
  &:hover {
    box-shadow: ${p => p.link && cardHoverShadow};
  }
`;

const OverflowHeader = styled(Heading3)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OverflowDetails = styled(SubText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UserCard = styled(Card)`
  min-width: 215px;
  height: 230px;
  background-color: ${colors.whiteMain};
  padding-bottom: ${margins.size4};
  ${media.mobile} {
    height: 120px;
    width: 100%;
  }
`;

const ProfileText = styled.div`
  max-width: 215px;
  ${media.mobile} {
    margin: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 120px;
  }
`;

const ArangeMobile = styled.div`
  ${media.mobile} {
    display: flex;
  }
`;

const CornerImage = styled.img`
  position: absolute;
  top: 55px;
  left: 110px;
  ${media.mobile} {
    top: 70px;
    left: 70px;
  }
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const NetworkTypeString = styled(OverflowDetails)`
  ${media.desktop} {
    margin-top: ${margins.size3};
  }
`;

type OnDateTypes = { hasUpperDate: boolean };
const OnDate = styled(OverflowDetails)<OnDateTypes>`
  color: ${colors.blackMid};
  ${media.desktop} {
    margin-bottom: -${p => p.hasUpperDate && margins.size3};
  }
`;
