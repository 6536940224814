import React from 'react';

import { TimesEditor } from 'js/components/settings/components/Autopilot/TimesEditor';
import { MeetingFrequency } from 'js/components/settings/components/Autopilot/MeetingFrequency';
import { PauseWeeklyMeetigns } from 'js/components/settings/components/Autopilot/PauseWeeklyMeetings';
import { CoachingPlanUnsubscribe } from 'js/components/settings/components/CoachingPlanUnsubscribe';
import { useMountEffect } from 'js/util/custom-hooks';

import { TargetLocale } from '../components/Autopilot/TargetLocale';

export const Availabilities = () => {
  useMountEffect(() => {
    window.hj('trigger', 'cohesive');
  });

  return (
    <>
      <TimesEditor />
      <MeetingFrequency />
      <TargetLocale />
      <PauseWeeklyMeetigns />
      <CoachingPlanUnsubscribe />
    </>
  );
};
