import React, { useState } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components/macro';

import {
  colors,
  margins,
  FlexColumn,
  FlexRow,
  Heading2,
  Heading3,
  Link,
  Text,
  borders,
} from 'css/css';

import { LunchclubMatch } from 'types/matches';

import { Avatar } from 'js/components/shared/Avatar';
import { useMountEffect } from 'js/util/custom-hooks';

export const UpcomingMatchesDesktop: React.FC<{
  matches: LunchclubMatch[];
  userTimezone: string;
  is24Hour: boolean;
}> = ({ matches, userTimezone, is24Hour }) => (
  <>
    {matches
      .sort((a, b) => moment(a.time_slot_with_exact_time).diff(b.time_slot_with_exact_time))
      .map((match, i) => (
        <UpcomingMatch
          key={match.match_user.public_id}
          initialOpen={i === 0}
          match={match}
          userTimezone={userTimezone}
          is24Hour={is24Hour}
        />
      ))}
  </>
);

interface UpcomingMatchProps {
  match: LunchclubMatch;
  initialOpen: boolean;
  userTimezone: string;
  is24Hour: boolean;
}

const UpcomingMatch: React.FC<UpcomingMatchProps> = ({
  match,
  initialOpen,
  userTimezone,
  is24Hour,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { time_slot_with_exact_time: timeslot } = match;
  const {
    headline,
    image,
    first_name: firstName,
    last_name: lastName,
    city,
    public_id: profileLink,
  } = match.match_user;

  useMountEffect(() => {
    if (initialOpen) setIsOpen(true);
  });

  const cardComponent = isOpen && (
    <>
      <Container style={{ width: '100%', padding: margins.size3, marginBottom: margins.size3 }}>
        <Link to={`/member/${profileLink}`} style={{ width: 'fit-content' }}>
          <FlexRow style={{ marginBottom: margins.size2, width: 'fit-content' }}>
            <Avatar size2 src={image} alt={firstName || 'Profile Image'} />
            <FlexColumn alignItems="flex-start" style={{ paddingLeft: margins.size3 }}>
              <Heading3>
                {firstName} {lastName}
              </Heading3>
              {city && <Text color={colors.blackLight}>{city}</Text>}
            </FlexColumn>
          </FlexRow>
        </Link>
        <Text style={{ marginBottom: margins.size2 }}>{headline}</Text>
        <Link to={`/chat/${profileLink}`}>
          <Heading3 color={colors.primaryMain}>Message {firstName}</Heading3>
        </Link>
      </Container>
    </>
  );

  return (
    <Container onClick={() => setIsOpen(!isOpen)}>
      <Heading2 style={{ marginBottom: isOpen ? margins.size2 : '0px' }}>
        {moment(timeslot).format(`ddd MMM. D, ${is24Hour ? 'H:mm' : 'h:mm a'}`)} {userTimezone}
      </Heading2>
      {cardComponent}
    </Container>
  );
};

const Container = styled(FlexColumn)`
  align-items: flex-start;
  width: 600px;
  border-radius: 10px;
  border: ${borders.variation};
  background-color: ${colors.whiteMain};
  padding: ${margins.size4};
  cursor: pointer;
  margin-top: ${margins.size1};
  max-width: 600px;
  text-align: left;
`;
