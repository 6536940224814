import React from 'react';
import styled from 'styled-components/macro';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import { Redirect } from 'react-router-dom';

import { margins, Heading2 } from 'css/css';

import { IconCarousel } from 'js/components/registration/IconCarousel';
import { objectiveIcons } from 'js/util/util';

import { MultipleChoiceSelector } from '../selectors/MultipleChoiceSelector';

export const ObjectiveDetails = ({
  objectives,
  onSubmit,
  onPrev,
  selectedObjectiveDetails,
  setSelectedObjectiveDetails,
  profile,
  buttonLoading,
}: any) => {
  if (objectives.length <= 0) {
    return <Redirect to={{ pathname: `/registration/objectives` }} />;
  }

  const onClickAnswer = (response: any, questionId: any) => {
    const clonedState = cloneDeep(selectedObjectiveDetails);
    if (clonedState.some((qa: any) => qa.question_id === questionId)) {
      const changingQuestion = clonedState.find((qa: any) => qa.question_id === questionId);
      if (changingQuestion.response === response) {
        remove(clonedState, (el: any) => el.question_id === questionId);
      } else {
        changingQuestion.response = response;
      }
    } else {
      clonedState.push({ question_id: questionId, response });
    }
    setSelectedObjectiveDetails(clonedState);
  };

  const checkSelected = (response: any, questionId: any) => {
    const question = selectedObjectiveDetails.find((qa: any) => qa.question_id === questionId);
    return question && question.response === response;
  };

  // TODO: Improve code duplication

  const pages = objectives.map((obj: any) =>
    profile ? (
      <ObjectiveDetailsInnerWrapper>
        {obj.questions.map((que: any) => (
          <ObjectiveQuestion key={que.id}>
            <Heading2>{que.text}</Heading2>

            <MultipleChoiceSelector
              question={que}
              onClickAnswer={onClickAnswer}
              checkSelected={checkSelected}
            />
          </ObjectiveQuestion>
        ))}
      </ObjectiveDetailsInnerWrapper>
    ) : (
      {
        name: obj.name,
        // @ts-ignore
        icon: objectiveIcons.find((o: any) => o.id === obj.id).imgSrc,
        component: (
          <>
            <Heading2>{obj.name}</Heading2>
            {obj.questions.map((que: any) => (
              <ObjectiveQuestion key={que.id}>
                <Heading2>{que.text}</Heading2>

                <MultipleChoiceSelector
                  question={que}
                  onClickAnswer={onClickAnswer}
                  checkSelected={checkSelected}
                />
              </ObjectiveQuestion>
            ))}
          </>
        ),
      }
    ),
  );

  if (profile) {
    return pages[0];
  }

  return (
    <IconCarousel pages={pages} onFinish={onSubmit} onBack={onPrev} buttonLoading={buttonLoading} />
  );
};

const ObjectiveDetailsInnerWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

const ObjectiveQuestion = styled.div`
  margin-top: ${margins.size4};
  text-align: left;
`;
