import React from 'react';

import { colors, FlexRow, fonts, Heading3, HideMobile, margins, Text } from 'css/css';

import { GoogleSignIn } from 'js/components/shared/GoogleSignIn';
import { SimpleModalWrap, useSimpleModal, WhiteCardHeader } from 'js/components/shared/MobileModal';
import { getIsMobile } from 'js/util/util';
import { SettingsSection } from 'js/components/settings/components/shared-components/EditableSection';

import googleSvg from 'img/settings/google.svg';
import lightbulbSvg from 'img/settings/lightbulb.svg';
import removeSvg from 'img/settings/remove.svg';
import { google } from 'img/verify';

import { Body } from '../styles';
import { SectionsTitles } from '../constants';

import { SettingsOption } from './SettingsOption';

const WHY_GOOGLE_ACCOUNTS = `We recommend connecting as many Google accounts as possible to improve the relevance of your matches!`;

export const ConnectedAccountEditor: React.FC<{
  accounts: string[];
  onAddedAccount: () => void;
  onRemoveAccount: (email: string) => void;
}> = ({ accounts, onAddedAccount, onRemoveAccount }) => {
  const { openModal, closeModal, wrapperProps } = useSimpleModal();

  if (getIsMobile()) {
    return (
      <div>
        <SimpleModalWrap {...wrapperProps}>
          <WhiteCardHeader title="Connected accounts" onClose={closeModal} />
          <div style={{ textAlign: 'center', marginBottom: margins.size3 }}>
            <img src={lightbulbSvg} alt="lightbulb" style={{ marginBottom: margins.size3 }} />
            <Text>{WHY_GOOGLE_ACCOUNTS}</Text>
          </div>
          {accounts.map(email => (
            <FlexRow key={email}>
              <div
                style={{ margin: margins.size3, marginRight: 0 }}
                tabIndex={-1}
                role="button"
                onClick={() => onRemoveAccount(email)}
              >
                <img src={removeSvg} alt="remove" />
              </div>
              <div style={{ margin: margins.size3 }}>{email}</div>
            </FlexRow>
          ))}
          <div style={{ borderTop: `1px solid ${colors.greyLight}` }}>
            <GoogleSignIn
              onLogIn={onAddedAccount}
              isRegistering
              customButton={
                <FlexRow justifyContent="flex-start">
                  <img
                    style={{ width: 24, margin: margins.size3 }}
                    src={google}
                    alt="Google logo"
                  />
                  <div style={{ fontFamily: fonts.extraBold, color: colors.blackMain }}>
                    Add Google account
                  </div>
                </FlexRow>
              }
            />
          </div>
        </SimpleModalWrap>
        <SettingsSection
          onClick={openModal}
          headingTitle={SectionsTitles.CONNECTED_ACCOUNTS}
          selectedOptions={accounts}
        />
      </div>
    );
  }
  return (
    <>
      <SettingsOption
        title="Connected accounts"
        bodyComponent={
          <HideMobile>
            <Body>{WHY_GOOGLE_ACCOUNTS}</Body>
          </HideMobile>
        }
        values={accounts}
        onRemoveValue={email => email && onRemoveAccount(email)}
        icon={googleSvg}
        style={{ marginBottom: margins.size4 }}
      />
      <Heading3 style={{ marginBottom: margins.size2 }}>Add an account</Heading3>
      <GoogleSignIn onLogIn={onAddedAccount} isRegistering buttonText="Add a Google account" />
    </>
  );
};
