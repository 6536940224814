import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

import { Card, Clickable, FlexRow, margins } from 'css/css';

import { CoachingPlanDetails, PastAvailability } from 'types/availability';
import { LunchclubMatch } from 'types/matches';

import EditPencil from 'img/shared/edit-pencil.svg';

import { ExistingMeetingCard } from './ExistingMeetingCard';
import { FeedbackCard } from './FeedbackCard';
import { NewMeetingCard } from './NewMeetingCard';
import { PassedWeekCard } from './PassedWeekCard';

interface Props {
  pastAvailability?: PastAvailability;
  pastMatches: LunchclubMatch[];
  setUserSubmittingFeedback: React.Dispatch<React.SetStateAction<boolean>>;
  missingFeedback: string[];
  coachingPlanDetails?: CoachingPlanDetails;
}

export const AvailabilityComponent: React.FC<Props> = ({
  pastAvailability,
  pastMatches,
  setUserSubmittingFeedback,
  coachingPlanDetails,
  missingFeedback,
}) => {
  const history = useHistory();

  const editThisWeeksAvailability = () => history.push(`/weekly`);

  const matchesToReview = pastMatches.filter(match =>
    missingFeedback.includes(match.feedback_code),
  );

  let cardContent = <NewMeetingCard navigateToWeekly={() => editThisWeeksAvailability()} />;
  if (missingFeedback.length) {
    cardContent = (
      <FeedbackCard
        matchesToReview={matchesToReview}
        startSubmittingFeedback={setUserSubmittingFeedback}
      />
    );
  } else if (pastAvailability && pastAvailability.passed) {
    cardContent = <PassedWeekCard />;
  } else if (
    pastAvailability &&
    (pastAvailability.timeslots.length || pastAvailability.slantOptIn)
  ) {
    cardContent = (
      <ExistingMeetingCard
        thisWeeksAvailability={pastAvailability}
        coachingPlan={coachingPlanDetails}
      />
    );
  }

  const showEditButton =
    !!(pastAvailability?.timeslots?.length || pastAvailability?.slantOptIn) &&
    missingFeedback.length === 0;

  return (
    <Card style={{ marginBottom: margins.size2 }}>
      {showEditButton && (
        <FlexRow justifyContent="flex-end">
          <Clickable onClick={() => history.push(`/weekly`)}>
            <img src={EditPencil} alt="Edit availability" />
          </Clickable>
        </FlexRow>
      )}
      <CardContainer showEditButton={showEditButton}>{cardContent}</CardContainer>
    </Card>
  );
};

type CardContainerTypes = {
  showEditButton: boolean;
};
const CardContainer = styled.div<CardContainerTypes>`
  padding: ${p =>
    p.showEditButton ? `0px ${margins.size4} ${margins.size4} ${margins.size4}` : margins.size4};
  text-align: left;
`;
