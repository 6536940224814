import React from 'react';
import styled from 'styled-components/macro';

import { borders, colors, fontSizes, margins, media } from 'css/css';

import { StringLengthIndicator } from 'js/components/shared/StringLengthIndicator';
import { Checkbox } from 'js/components/shared/Checkbox';
import { feedbackPage } from 'js/util/strings';
import { MobileFocusingTextarea } from 'js/components/shared/MobileFocusingTextarea';

import successStoryIcon from 'img/feedback/success-story.svg';

import { QuestionHeading } from './Question';

export const FeedbackTextbox: React.FC<any> = ({
  id,
  value,
  setValue,
  maxLength,
  question,
  checkValue,
  setCheckValue,
  displayCheck,
}) => {
  const getIcon = () => {
    if (id === 'SUCCESS_STORY') {
      return <Icon src={successStoryIcon} />;
    }
    return null;
  };
  return (
    <Container>
      <QuestionHeading>
        {getIcon()}
        {question}
      </QuestionHeading>

      <StyledTextbox
        role="textbox"
        value={value}
        resetValue={originalValue => setValue(originalValue)}
        onChange={(e: any) => setValue(e.target.value)}
        error={value && value.length > maxLength}
        mobileFocusBehavior="always"
        mobileFocusLabel={question}
      />

      <StringLengthIndicator
        minLength={0}
        maxLength={maxLength}
        string={value}
        noDisplay={!(value && value.length > maxLength)}
      />
      {displayCheck && (
        <Checkbox
          checked={checkValue}
          setCheckValue={setCheckValue}
          desc={feedbackPage.successStoryShare}
          hiddenDesc={feedbackPage.expandedSuccessStoryShare}
        />
      )}
    </Container>
  );
};
const Icon = styled.img`
  margin-right: ${margins.size3};
  vertical-align: sub;
`;

type StyledTextboxError = {
  error?: boolean;
};
const StyledTextbox = styled(MobileFocusingTextarea)<StyledTextboxError>`
  outline: none;
  height: 125px;
  width: 100%;
  padding: ${margins.size3};
  background: ${colors.greyLight};
  border-radius: 3px;
  resize: none;
  font-size: ${fontSizes.size2};
  color: ${colors.blackMid};
  &::placeholder {
    color: ${colors.blackLight};
  }
  border: ${p => (p.error ? borders.error : `none`)};
`;

const Container = styled.div`
  margin-top: ${margins.size7};
  margin-bottom: ${margins.size6};
  width: 100%;
  ${media.mobile} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
