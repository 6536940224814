import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useHistory, useRouteMatch } from 'react-router';

import { FlexRow, colors, borders, media } from 'css/css';

import { LunchclubMatch } from 'types/matches';
import Channel from 'types/channel';

import { GreyPage } from 'js/components/shared/page-wrappers';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { getMatchFeedback, postMatchEventStatus } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';
import { useUserAvailability } from 'js/providers/UserProvider';
import { getIsMobile, getParameterByName, EVENT_STATUS } from 'js/util/util';
import { useChatContext } from 'js/providers/ChatContextProvider';

import { ConversationList } from './ConversationList';
import { ConversationContainer } from './ConversationContainer';
import { NewUserChat } from './NewUserChat';
import { ConversationProfileCard } from './ConversationProfileCard';

export enum ChatTabs {
  List = 1,
  Conversation = 2,
}

export const Chat: React.FC = () => {
  const routeMatch = useRouteMatch<{ id: string }>('/chat/:id');

  const [pastMatches, setPastMatches] = useState<LunchclubMatch[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<Channel | undefined>();
  const [openTab, setOpenTab] = useState<ChatTabs>(1);

  const availability = useUserAvailability();
  const history = useHistory();
  const {
    channels,
    isFetching,
    selectedProfileId,
    setSelectedProfileId,
    updateLCMessages,
  } = useChatContext();
  const profileIdFromParams = getParameterByName('chat_id');
  const actionFromParams = getParameterByName('action');
  const isFromPushNotif = getParameterByName('ref_pn') !== null;
  const isMobile = getIsMobile();

  useEffect(() => {
    if (profileIdFromParams) {
      setSelectedProfileId(profileIdFromParams);
      const updateTo = `/chat/${profileIdFromParams}`;
      history.replace(updateTo);
      setOpenTab(ChatTabs.Conversation);
    } else if (routeMatch?.params.id) {
      setSelectedProfileId(routeMatch?.params.id);
      setOpenTab(ChatTabs.Conversation);
    } else if (!isMobile && channels[0]?.profileId) {
      // Note: do not optimistically drill down into the first chat on mobile,
      // because it takes the user away from the chat list
      const updateTo = `/chat/${channels[0].profileId}`;
      history.replace(updateTo);
      setSelectedProfileId(channels[0].profileId);
    } else {
      setOpenTab(ChatTabs.List);
      setSelectedProfileId('');
    }
  }, [profileIdFromParams, routeMatch?.params.id, channels.length]);

  useEffect(() => {
    setSelectedChannel(channels.find(c => c.profileId === selectedProfileId));
  }, [selectedProfileId, channels]);

  useEffect(() => {
    if (actionFromParams === 'confirm' && selectedChannel) {
      if (selectedChannel.matchCode) {
        postMatchEventStatus({
          matchCode: selectedChannel.matchCode,
          status: EVENT_STATUS.confirmed,
        });
      }
      const updateTo = `/chat/${selectedChannel.profileId}`;
      history.replace(updateTo);
    }
  }, [selectedChannel, actionFromParams]);

  useMountEffect(() => {
    loadOutstandingFeedback();
  });

  useMountEffect(() => {
    if (isFromPushNotif && !isFetching) {
      updateLCMessages();
    }
  });

  const loadOutstandingFeedback = async () => {
    const res = await getMatchFeedback();
    if (res.ok) {
      setPastMatches(res.getJson);
    }
  };

  const hasSignedUp = !!availability?.has_signed_up_weekly;

  return (
    <StyledGreyPage
      fixedFullHeight
      style={{ background: colors.whiteMain }}
      shouldDisplayNavbar={!isMobile || openTab === ChatTabs.List || channels.length === 0}
    >
      {isFetching ? (
        <SVGLoader />
      ) : channels.length === 0 ? (
        <NewUserChat hasSignedUp={hasSignedUp} />
      ) : (
        <Container>
          <ConversationList
            isOpen={!isMobile || openTab === ChatTabs.List}
            setOpenTab={setOpenTab}
          />
          {!!selectedChannel && (
            <ConversationContainer
              selectedChannel={selectedChannel}
              pastMatches={pastMatches}
              inCall={false}
              isOpen={!isMobile || openTab === ChatTabs.Conversation}
              setOpenTab={setOpenTab}
            />
          )}
          {!isMobile && selectedChannel && (
            <ConversationProfileCard profileId={selectedChannel.profileId} />
          )}
        </Container>
      )}
    </StyledGreyPage>
  );
};

const StyledGreyPage = styled(GreyPage)`
  ${media.mobile} {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const Container = styled(FlexRow)`
  position: relative;
  background-color: ${colors.whiteMain};
  border: ${borders.standard};
  border-radius: 5px;
  height: 100%;
  ${media.mobile} {
    margin-left: -15px;
    margin-right: -15px;
    border: none;
    display: block;
  }
`;
