import React from 'react';
import styled from 'styled-components/macro';

import { FlexRow, margins } from 'css/css';

import { getIsMobile } from 'js/util/util';
import { useUIStateContext } from 'js/components/callv2/contexts/UIStateContext';

import { SmallControl } from './SmallControl';

export const Controls: React.FC = () => {
  const {
    areControlsVisible,
    setShowSettingsModal,
    setShowFeedbackModal,
    sidebarView,
  } = useUIStateContext();
  const isMobile = getIsMobile();
  return (
    <ControlsContainer isVisible={areControlsVisible && (!isMobile || !sidebarView)}>
      <SmallControl type="settings" action={() => setShowSettingsModal(true)} />
      <SmallControl type="feedback" action={() => setShowFeedbackModal(true)} />
    </ControlsContainer>
  );
};

const ControlsContainer = styled(FlexRow)<{ isVisible: boolean }>`
  gap: ${margins.size2};
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
  position: absolute;
  bottom: ${margins.size3};
  left: ${margins.size4};
`;
