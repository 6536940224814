import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import ReactAvatarEditor from 'react-avatar-editor';

import { margins, FlexRow, colors, globalTransitionSettings } from 'css/css';

import ImageIcon from 'img/shared/image-icon.svg';

import { SaveAndCancel } from '../selectors/SaveAndCancel';

const SCRUBBER_HEIGHT = '18px';
const TRACK_HEIGHT = '8px';

export const AvatarEditor = ({ userImage, onSubmit, onCancel }: any) => {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState();
  const [rotate] = useState(0);
  const editorRef = useRef(null);

  const handleScale = (e: any) => {
    const newScale = parseFloat(e.target.value);
    setScale(newScale);
  };

  const handlePositionChange = (newPosition: any) => {
    setPosition(newPosition);
  };

  return (
    <div style={{ width: '100%' }}>
      <FlexRow justifyContent="center" centered>
        <ReactAvatarEditor
          scale={scale}
          width={250}
          height={250}
          position={position}
          onPositionChange={handlePositionChange}
          rotate={rotate}
          borderRadius={125}
          image={userImage}
          ref={editorRef}
        />
      </FlexRow>

      <FlexRow
        justifyContent="center"
        centered
        style={{ marginBottom: margins.size5, marginTop: margins.size5, maxWidth: '300px' }}
      >
        <Icon src={ImageIcon} alt="small-pic" />

        <Slider
          name="scale"
          type="range"
          onChange={handleScale}
          min="1"
          max="3"
          step="0.01"
          defaultValue="1"
        />

        <Icon big src={ImageIcon} alt="big-pic" />
      </FlexRow>

      <SaveAndCancel
        clickCancel={() => onCancel()}
        clickSave={() => onSubmit(editorRef)}
        style={{ maxWidth: '500px', margin: '0 auto' }}
      />
    </div>
  );
};

const Slider = styled.input`
  display: block;
  appearance: none;
  width: 100%;
  height: ${SCRUBBER_HEIGHT};
  margin: 0px ${margins.size3};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${TRACK_HEIGHT};
    background: ${colors.greyLight};
    border-radius: 4px;
  }
  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: ${SCRUBBER_HEIGHT};
    width: ${SCRUBBER_HEIGHT};
    background: ${colors.primaryMain};
    border-radius: 100%;
    border: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: ${globalTransitionSettings};
  }
  &::-moz-range-track,
  &::-moz-range-progress {
    width: 100%;
    height: ${TRACK_HEIGHT};
    background: ${colors.greyLight};
  }
  &::-moz-range-progress {
    background: ${colors.greyLight};
  }
  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: ${SCRUBBER_HEIGHT};
    width: ${SCRUBBER_HEIGHT};
    background: ${colors.primaryMain};
    border-radius: 100%;
    border: 0;
    transition: ${globalTransitionSettings};
  }
  &::-ms-track {
    width: 100%;
    height: ${TRACK_HEIGHT};
    border: 0;
    color: transparent;
    background: transparent;
  }
  &::-ms-fill-lower {
    background: ${colors.greyLight};
  }
  &::-ms-fill-upper {
    background: ${colors.greyLight};
  }
  &::-ms-thumb {
    appearance: none;
    height: ${SCRUBBER_HEIGHT};
    width: ${SCRUBBER_HEIGHT};
    background: ${colors.primaryMain};
    border-radius: 100%;
    border: 0;
    transition: ${globalTransitionSettings};
    top: 0;
    margin: 0;
    box-shadow: none;
  }
  &:hover,
  &:focus {
    &::-webkit-slider-thumb {
      background-color: ${colors.primaryDark};
    }
    &::-moz-range-thumb {
      background-color: ${colors.primaryDark};
    }
    &::-ms-thumb {
      background-color: ${colors.primaryDark};
    }
  }
`;

type IconTypes = { big?: boolean };
const Icon = styled.img<IconTypes>`
  width: ${p => (p.big ? '60px' : '30px')};
  height: auto;
`;
