import React from 'react';
import styled from 'styled-components/macro';

import { FlexRow, colors, media, margins, globalTransitionSettings } from 'css/css';

import { NotificationBadge } from 'js/components/shared/NotificationBadge';

interface Props {
  isSelected?: boolean;
  isStroked?: boolean;
  isExpandable?: boolean;
  notificationCount?: number;
  onClick?: () => void;
}

export const NavIconContainer: React.FC<Props> = ({
  isSelected,
  isStroked,
  isExpandable,
  notificationCount = 0,
  onClick,
  children,
}) => (
  <NavIconContainerContainer
    isSelected={isSelected}
    isStroked={isStroked}
    isExpandable={isExpandable}
    onClick={onClick}
  >
    <ChildrenContainer isSelected={isSelected} isExpandable={isExpandable}>
      {children}
    </ChildrenContainer>
    <NotificationBadge count={notificationCount} />
  </NavIconContainerContainer>
);

const getStroke = (p: { isSelected?: boolean }) =>
  p.isSelected ? colors.whiteMain : colors.primaryDark;

interface NavIconContainerContainer {
  isSelected?: boolean;
  isStroked?: boolean;
  isExpandable?: boolean;
}

interface ChildrenContainer {
  isSelected?: boolean;
  isExpandable?: boolean;
}

const ChildrenContainer = styled(FlexRow)<ChildrenContainer>`
  transition: ${globalTransitionSettings};
  ${media.desktop} {
    &:hover {
      opacity: ${p => (p.isSelected || p.isExpandable ? '50%' : '100%')};
    }
  }
`;

const NavIconContainerContainer = styled(FlexRow)<NavIconContainerContainer>`
  position: relative;
  width: ${p => (p.isExpandable ? '60px' : '35px')};
  height: 35px;
  justify-content: center;
  border-radius: 18px;
  background-color: transparent;
  user-select: none;

  ${media.desktop} {
    margin-left: ${margins.size4};

    &:hover {
      cursor: pointer;
    }
    &:hover > svg > path {
      fill: ${p => (p.isStroked ? undefined : getStroke)};
      stroke: ${p => (p.isStroked ? getStroke : undefined)};
    }
    &:hover > svg > rect {
      stroke: ${getStroke};
    }
  }
  ${media.mobile} {
    width: 35px;
    background-color: transparent;
  }
`;
