import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';

import {
  FlexColumn,
  colors,
  Heading3,
  margins,
  FlexRow,
  Clickable,
  media,
  Text,
  BUTTON_HEIGHT,
} from 'css/css';

import Channel from 'types/channel';

import { useUser } from 'js/providers/UserProvider';
import { getSchedulerBusyTimeslots } from 'js/util/api';
import { generateTimeslots, getIsMobile } from 'js/util/util';
import { useMountEffect } from 'js/util/custom-hooks';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { Button } from 'js/components/shared/Button';

import cross from 'img/weekly/x.svg';

import { SuggestedTimes } from './SuggestedTimes';
import { CustomTimes } from './CustomTimes';

interface Props {
  closeScheduler?: () => void;
  setIsTimeslotsConfirmationVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  userTimezone: string;
  isModal?: boolean;
  timeslotsSuggestedByMatch?: string[];
  isUserScheduleInitiator?: boolean;
  selectedChannel: Channel;
}

export const SchedulerTimeslotsModal: React.FC<Props> = ({
  closeScheduler,
  setIsTimeslotsConfirmationVisible,
  userTimezone,
  isModal,
  timeslotsSuggestedByMatch = [],
  isUserScheduleInitiator,
  selectedChannel,
}) => {
  const { savedTimeslots, setSavedTimeslots } = useChatContext();
  const [isLoading, setIsLoading] = useState(false);
  const [startWeek, setStartWeek] = useState(0);
  const [selectedSuggestedTimeslots, setSelectedSuggestedTimeslots] = useState<string[]>([]);
  const [selectedCustomTimeslots, setSelectedCustomTimeslots] = useState<string[]>([]);
  const [busyTimeslots, setBusyTimeslots] = useState<string[]>([]);
  const [isSuggestedTimeslotsOpen, setIsSuggestedTimeslotsOpen] = useState(true);
  const [customTimesCalendarState, setCustomTimesCalendarState] = useState<
    'closed' | 'selecting day' | 'selecting times'
  >('closed');

  const { profileId, conversation } = selectedChannel;
  const isScheduling = (conversation?.attributes as any)?.isScheduling;

  const allSelectedTimeslots = [...selectedSuggestedTimeslots, ...selectedCustomTimeslots];
  const user = useUser();
  const MAX_SELECTED_TIMES = 5;
  const hasReachedLimit = allSelectedTimeslots.length >= MAX_SELECTED_TIMES;
  const isMobile = useMemo(() => getIsMobile(), []);

  useEffect(() => {
    if (profileId) {
      setSelectedSuggestedTimeslots(savedTimeslots[profileId]?.timeslots?.suggested || []);
      setSelectedCustomTimeslots(savedTimeslots[profileId]?.timeslots?.custom || []);
    }
  }, [profileId]);

  useMountEffect(() => {
    if (user.has_google) {
      (async () => {
        const res = await getSchedulerBusyTimeslots();
        if (res.ok) {
          setBusyTimeslots(res.getJson);
        }
      })();
    }
  });

  const { startDay, currTimeslots, currentTimeWithBuffer } = generateTimeslots({
    startWeek,
    startTime: '8:00',
    endTime: '18:00',
    numDays: 6,
    userTimezone: user.locale_info.timezone,
    bufferHours: 0,
    cutOffTime: '17:00',
  });

  const handleSave = async () => {
    if (setSavedTimeslots && profileId) {
      setIsLoading(true);
      const shouldResetSavedTimeslots =
        !isScheduling ||
        !isUserScheduleInitiator ||
        !(
          savedTimeslots[profileId]?.timeslots.suggested === selectedSuggestedTimeslots &&
          savedTimeslots[profileId]?.timeslots.custom === selectedCustomTimeslots
        );
      if (shouldResetSavedTimeslots) {
        await setSavedTimeslots(prev => ({
          ...prev,
          [profileId]: {
            ...prev[profileId],
            timeslots: { suggested: selectedSuggestedTimeslots, custom: selectedCustomTimeslots },
          },
        }));
        if (setIsTimeslotsConfirmationVisible && !isModal) {
          setIsTimeslotsConfirmationVisible(true);
        }
      }

      if (closeScheduler) {
        closeScheduler();
      }
      setIsLoading(false);
    }
  };

  if (!currTimeslots) {
    return null;
  }

  return (
    <SchedulerContainer onClick={e => e.stopPropagation()} $isModal={!!isModal}>
      {closeScheduler && (
        <SchedulerModalHeader
          allSelectedTimeslots={allSelectedTimeslots}
          closeScheduler={closeScheduler}
          hasReachedLimit={hasReachedLimit}
        />
      )}
      <FlexColumn style={{ gap: margins.size2, width: '100%', flexGrow: 1 }}>
        <SuggestedTimes
          userTimezone={userTimezone}
          currWeekTimeslots={currTimeslots}
          busyTimeslots={busyTimeslots}
          selectedSuggestedTimeslots={selectedSuggestedTimeslots}
          setSelectedSuggestedTimeslots={setSelectedSuggestedTimeslots}
          currentTimeWithBuffer={currentTimeWithBuffer}
          startDay={startDay}
          startWeek={startWeek}
          setStartWeek={setStartWeek}
          hasReachedLimit={hasReachedLimit}
          isSuggestedTimeslotsOpen={isSuggestedTimeslotsOpen}
          setIsSuggestedTimeslotsOpen={setIsSuggestedTimeslotsOpen}
          closeCustomTimes={() => setCustomTimesCalendarState('closed')}
          timeslotsSuggestedByMatch={timeslotsSuggestedByMatch}
        />
        <CustomTimes
          userTimezone={userTimezone}
          selectedCustomTimeslots={selectedCustomTimeslots}
          setSelectedCustomTimeslots={setSelectedCustomTimeslots}
          hasReachedLimit={hasReachedLimit}
          calendarState={customTimesCalendarState}
          setCalendarState={setCustomTimesCalendarState}
          setIsSuggestedTimeslotsOpen={setIsSuggestedTimeslotsOpen}
          timeslotsSuggestedByMatch={timeslotsSuggestedByMatch}
        />
        <SaveButton
          handleSave={handleSave}
          allSelectedTimeslots={allSelectedTimeslots}
          isMobile={isMobile}
          isModal={!!isModal}
          isLoading={isLoading}
        />
      </FlexColumn>
    </SchedulerContainer>
  );
};

const SaveButton = ({
  handleSave,
  allSelectedTimeslots,
  isMobile,
  isModal,
  isLoading,
}: {
  handleSave: () => void;
  allSelectedTimeslots: string[];
  isMobile: boolean;
  isModal: boolean;
  isLoading: boolean;
}) => (
  <SaveTimeslotsButton
    large={!isMobile}
    onClick={handleSave}
    invalid={!allSelectedTimeslots.length}
    loading={isLoading}
  >
    {isModal ? 'Send ' : 'Save '}
    {`${allSelectedTimeslots.length} availabilit${allSelectedTimeslots.length !== 1 ? 'ies' : 'y'}`}
  </SaveTimeslotsButton>
);
interface SchedulerModalHeaderProps {
  allSelectedTimeslots: string[];
  closeScheduler: () => void;
  hasReachedLimit: boolean;
}

const SchedulerModalHeader: React.FC<SchedulerModalHeaderProps> = ({
  allSelectedTimeslots,
  closeScheduler,
  hasReachedLimit,
}) => {
  return (
    <FlexRow style={{ width: '100%', height: '74px' }} justifyContent="center">
      <FlexColumn style={{ marginLeft: 'auto', marginRight: `-${margins.size3}` }}>
        <Heading3>
          {allSelectedTimeslots.length
            ? `${allSelectedTimeslots.length} time${
                allSelectedTimeslots.length > 1 ? 's' : ''
              } selected`
            : 'Add your availabilities'}
        </Heading3>
        <Text style={{ color: hasReachedLimit ? colors.tertiary2Main : colors.blackMid }}>
          Select up to 5
        </Text>
      </FlexColumn>
      <Clickable
        onClick={closeScheduler}
        style={{ marginRight: margins.size2, marginLeft: 'auto' }}
      >
        <img src={cross} alt="close" style={{ color: colors.blackLight }} />
      </Clickable>
    </FlexRow>
  );
};

const SchedulerContainer = styled(FlexColumn)<{ $isModal: boolean }>`
  position: relative;
  border-radius: 12px;
  padding: ${margins.size3} ${margins.size2};
  width: 600px;
  background-color: ${colors.whiteMain};
  align-self: flex-start;
  margin: auto;
  min-height: 100%;
  height: fit-content;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

  ${media.mobile} {
    border-radius: 0;
    width: 100%;
    position: absolute;
    overflow-y: scroll;
    top: 0;
    left: 0;
    padding-bottom: ${p => (p.$isModal ? 'env(safe-area-inset-bottom)' : 0)};
    padding-top: env(safe-area-inset-top);
    ${p => !p.$isModal && 'min-height: 100vh'};
    height: unset;
    ${p =>
      p.$isModal &&
      `border-top-left-radius: 15px; border-top-right-radius: 15px; padding-top: ${margins.size3}`};
  }
`;

const SaveTimeslotsButton = styled(Button)`
  height: ${BUTTON_HEIGHT};
  min-height: ${BUTTON_HEIGHT};
  ${media.mobile} {
    position: fixed;
    bottom: ${margins.size3};
    right: ${margins.size4};
  }
`;
