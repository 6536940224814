import React, { forwardRef, useImperativeHandle } from 'react';
import styled, { CSSProperties } from 'styled-components/macro';

import {
  colors,
  fontSizes,
  fonts,
  margins,
  media,
  globalTransitionSettings,
  BUTTON_HEIGHT,
} from 'css/css';

import { MobileFocusBehavior, useMobileFocusingBehavior } from 'js/util/use-mobile-focus-behavior';

interface TextInputProps extends StyledInputProps {
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  type?: string;
  id?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  style?: CSSProperties;
  onEnter?: () => void;
  className?: string;
  enterKeyHint?: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send';
  name?: string;
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  mobileFocusBehavior?: MobileFocusBehavior;
  mobileFocusLabel?: string;
  autoComplete?: string;
  pattern?: string;
}

export const TextInput = forwardRef(
  (
    {
      id,
      color,
      value,
      placeholder,
      onChange,
      type,
      disabled,
      style,
      onEnter,
      className,
      enterKeyHint,
      autoFocus,
      name,
      onClick,
      onFocus,
      onBlur,
      mobileFocusBehavior,
      mobileFocusLabel,
      autoComplete,
      ...styledInputProps
    }: TextInputProps,
    externalRef,
  ) => {
    const ref = React.createRef<HTMLInputElement>();
    const focus = useMobileFocusingBehavior(ref, {
      behavior: mobileFocusBehavior || 'if-under-keyboard',
      isTextArea: false,
      label: mobileFocusLabel || '',
    });
    useImperativeHandle(externalRef, () => ref.current);

    return (
      <>
        {focus.background}
        <StyledInput
          ref={ref}
          id={id}
          color={color}
          style={{ ...style, ...focus.styles }}
          type={type || ((placeholder || '').toLowerCase().includes('email') ? 'email' : 'text')}
          value={value}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onFocus={e => {
            focus.onFocus(e);
            if (onFocus) {
              onFocus();
            }
          }}
          onBlur={() => {
            focus.onBlur();
            if (onBlur) {
              onBlur();
            }
          }}
          onChange={event => onChange(event.target.value)}
          onKeyPress={e => {
            if (e.key === 'Enter' || e.key === 'Return') {
              e.currentTarget.blur();
              if (onEnter) {
                onEnter();
              }
            }
          }}
          disabled={disabled}
          className={className}
          enterKeyHint={enterKeyHint}
          name={name}
          onClick={onClick}
          autoComplete={autoComplete}
          {...styledInputProps}
        />
      </>
    );
  },
);

type StyledInputProps = {
  maxWidth?: string;
  maxWidthMobile?: string;
  tiny?: boolean;
  color?: string;
  $backgroundColor?: string;
  height?: string;
};

// https://github.com/styled-components/styled-components/issues/3417
const StyledInput = styled.input.withConfig<StyledInputProps>({
  shouldForwardProp: key => key !== 'maxWidthMobile' && key !== 'maxWidth',
})`
  width: 100%;
  max-width: ${p => (p.maxWidth ? p.maxWidth : '350px')};
  margin: 2px auto;
  padding: ${p =>
    p.tiny ? `${margins.size1} ${margins.size2}` : `${margins.size2} ${margins.size3}`};
  background-color: ${p => (p.$backgroundColor ? p.$backgroundColor : colors.greyLight)};
  height: ${p => (p.height ? p.height : !p.tiny && BUTTON_HEIGHT)};
  color: ${p => p.color || colors.blackMid};
  -webkit-text-fill-color: ${p => p.color || colors.blackMid}; // Safari
  opacity: 1; // Safari
  border-radius: 5px;
  border: none;
  box-sizing: border-box;
  font-family: ${fonts.regular};
  font-size: ${p => (p.tiny ? fontSizes.size1 : fontSizes.size2)};
  text-overflow: scroll;
  cursor: text;
  &::placeholder {
    color: ${colors.blackLight};
  }
  &:before {
    content: 'hi';
    position: absolute;
    z-index: 99;
    background: red;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  ${media.mobile} {
    max-width: ${p => (p.maxWidthMobile ? p.maxWidthMobile : '350px')};
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: ${colors.blackMain};
    -webkit-box-shadow: 0 0 0px 1000px ${colors.whiteMain} inset;
    transition: ${globalTransitionSettings};
  }
`;
