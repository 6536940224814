import React from 'react';
import styled from 'styled-components/macro';

import { margins, FlexRow, Heading3, Text, Heading2, EditTextArea, colors } from 'css/css';

import { ProductUser } from 'types/user';

import { useUser } from 'js/providers/UserProvider';

import { createConversationObj, Conversation, ConversationState } from './constants';

interface Props {
  editing?: boolean;
  passedUser?: ProductUser;
  conversationState?: ConversationState;
  setConversationState?: (val: ConversationState) => void;
}

export const ConversationStarters: React.FC<Props> = ({
  editing,
  passedUser,
  conversationState,
  setConversationState,
}) => {
  const user = useUser();

  const {
    ask_me_about: askMeAbout,
    like_to_learn: likeToLearn,
    just_learned: justLearned,
    top_of_mind: topOfMind,
    side_hustle: sideHustle,
  } = passedUser || user;

  const conversations = conversationState
    ? createConversationObj({ ...conversationState })
    : createConversationObj({
        askMeAbout,
        likeToLearn,
        justLearned,
        topOfMind,
        sideHustle,
      });

  const updateConversationState = (conversationVariableName: string, conversationValue: string) => {
    if (setConversationState && conversationState) {
      const updatedConversations = {
        ...conversationState,
        [conversationVariableName]: conversationValue,
      };
      setConversationState(updatedConversations);
    }
  };

  return (
    <>
      {editing && (
        <FlexRow style={{ marginBottom: margins.size4 }} justifyContent="space-between">
          <Heading2>Conversation Starters</Heading2>
        </FlexRow>
      )}
      {Object.values(conversations).map((conversation: Conversation) => (
        <ConversationStarter
          key={conversation.title}
          editing={editing || false}
          conversation={conversation}
          conversationValue={
            conversationState ? conversationState[conversation.variableName] : conversation.value
          }
          setConversationValue={(val: string) =>
            updateConversationState(conversation.variableName, val)
          }
        />
      ))}
    </>
  );
};

interface ConversationProps {
  editing: boolean;
  conversation: Conversation;
  conversationValue: string;
  setConversationValue: (val: string) => void;
}

const ConversationStarter: React.FC<ConversationProps> = ({
  editing,
  conversation,
  conversationValue,
  setConversationValue,
}) => {
  if (editing) {
    return (
      <>
        <FlexRow width="100%" style={{ marginBottom: margins.size2 }}>
          <img style={{ marginRight: margins.size3 }} src={conversation.icon} alt="" />
          <Heading3>{conversation.title}</Heading3>
        </FlexRow>
        <EditTextArea
          style={{
            backgroundColor: colors.greyLight,
            border: 'none',
            marginBottom: margins.size4,
          }}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setConversationValue(e.target.value)
          }
          value={conversationValue || ''}
        />
      </>
    );
  }

  return conversation.value ? (
    <>
      <FlexRow style={{ marginBottom: margins.size2 }}>
        <img
          style={{ marginRight: margins.size3 }}
          src={conversation.icon}
          alt={conversation.title}
        />
        <Heading3>{conversation.title}</Heading3>
      </FlexRow>
      <IndentedText>{conversation.value}</IndentedText>
    </>
  ) : null;
};

const IndentedText = styled(Text)`
  position: relative;
  left: ${margins.size4};
  margin-left: ${margins.size3};
  margin-right: ${margins.size4};
  margin-bottom: ${margins.size4};
`;
