import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import {
  Card,
  FlexColumn,
  FlexRow,
  Heading1,
  Heading2,
  Heading3,
  margins,
  media,
  Text,
} from 'css/css';

import { CoachingPlanEligibilityDetails, CoachingPlanMatchMeta } from 'js/api/coaching-plan';
import { getIsMobile } from 'js/util/util';

import { faceMapper } from './CoachingPlanAvatars';
import { CoachingPlanCopies } from './CoachingPlanCopies';

interface Props {
  details: CoachingPlanEligibilityDetails;
}

export const CoachingPlanInfo: React.FC<Props> = ({ details }) => {
  const isMobile = getIsMobile();
  const title = `The ${details.singular}-curated matches`;
  const heading = `Meet ${details.singular}-curated matches?`;

  return (
    <CoachingPlanSelectorContainer>
      <InfoCard shadow>
        {isMobile ? <Heading2>{title}</Heading2> : <Heading1>{title}</Heading1>}

        <CoachingPlanVisualization matches={details.matches} />
      </InfoCard>
      <FlexColumn
        style={{ marginTop: margins.size3, textAlign: 'center', marginInline: margins.size3 }}
      >
        {isMobile ? <Heading3>{heading}</Heading3> : <Heading1>{heading}</Heading1>}
        <Text style={{ marginTop: margins.size1 }}>{CoachingPlanCopies.Heading(details)}</Text>
      </FlexColumn>
    </CoachingPlanSelectorContainer>
  );
};

interface VisProps {
  matches: CoachingPlanMatchMeta[];
}

export const CoachingPlanVisualization: React.FC<VisProps> = ({ matches }) => {
  const [willMeetExpert, setWillMeetExpert] = useState(false);

  useEffect(() => {
    if (matches.some(m => m.type_name === 'EXPERT')) {
      setWillMeetExpert(true);
    }
  }, [matches]);

  return (
    <VisContainer>
      {matches.map(match => (
        <Child meetingExpert={willMeetExpert} isExpert={match.type_name === 'EXPERT'}>
          <FlexRow>
            {Array.from({ length: match.count }).map((_, j) => (
              <CoachFace
                // eslint-disable-next-line react/no-array-index-key
                key={`${match.type_name}${j}`}
                alt={`${match.type_name} face`}
                src={faceMapper(match.type_name)}
                offset={j > 0}
              />
            ))}
          </FlexRow>
          <Text style={{ marginTop: margins.size2 }}>{match.display_name}</Text>
        </Child>
      ))}
    </VisContainer>
  );
};

const VisContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  max-width: 270px;
  ${media.mobile} {
    max-width: 200px;
  }
  justify-content: space-around;
  margin: ${margins.size3} auto;
`;

const Child = styled(FlexColumn)<{ meetingExpert: boolean; isExpert: boolean }>`
  &:not(:first-child) {
    margin-top: ${margins.size3};
  }
  width: ${props => (props.isExpert ? '100%' : 'auto')};
  &:nth-child(2) {
    margin-top: ${props => (props.meetingExpert ? margins.size3 : '0')};
  }
`;

const CoachingPlanSelectorContainer = styled(FlexColumn)`
  border-radius: 12px;
  max-width: 600px;
  height: 100%;
  margin: ${margins.size3};
  ${media.mobile} {
    margin: 0;
  }
`;

const CoachFace = styled.img<{ offset?: boolean }>`
  width: 76px;
  ${media.mobile} {
    width: 42px;
  }

  margin-left: ${props => (props.offset ? '-15px' : '0')};
`;

const InfoCard = styled(Card)`
  text-align: center;
  padding-top: ${margins.size4};
  padding-bottom: ${margins.size3};
  height: 100%;
  margin-inline: ${margins.size3};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 100%;
  max-width: calc(100% - 32px);
  justify-content: center;
`;
