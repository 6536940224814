import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components/macro';
import { useHotkeys } from 'react-hotkeys-hook';

import { colors, margins, media, Clickable, globalTransitionSettings } from 'css/css';

import closeSvg from 'img/icons/x.svg';

interface Props extends ComponentPropsWithoutRef<'div'> {
  showModal: boolean;
  handleClose?: () => void;
  hideScrollbar?: boolean;
}

export const Modal: React.FC<Props> = ({
  showModal,
  handleClose,
  hideScrollbar = false,
  children,
  ...rest
}) => {
  useHotkeys(
    'esc',
    e => {
      e.preventDefault();
      if (handleClose) handleClose();
    },
    {},
    [],
  );

  return (
    <Backdrop onClick={handleClose} isVisible={showModal} {...rest}>
      <Content
        onClick={e => e.stopPropagation()}
        isVisible={showModal}
        hideScrollbar={hideScrollbar}
      >
        {handleClose && (
          <TopRightFixedContainer>
            <Clickable onClick={handleClose}>
              <img src={closeSvg} alt="Close modal" style={{ color: colors.blackLight }} />
            </Clickable>
          </TopRightFixedContainer>
        )}
        {children}
      </Content>
    </Backdrop>
  );
};

const Backdrop = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: ${margins.size7};

  transition: ${globalTransitionSettings};
  opacity: ${p => (p.isVisible ? 1 : 0)};
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};

  ${media.mobile} {
    padding-top: 80px;
    padding-left: ${margins.size3};
    padding-right: ${margins.size3};
  }
`;

const Content = styled.div<{ isVisible: boolean; hideScrollbar: boolean }>`
  width: 100%;
  max-width: 600px;
  background-color: ${colors.whiteMain};
  padding: ${margins.size4};
  border-radius: 5px;
  text-align: start;
  max-height: 80vh;
  overflow-y: ${p => (p.hideScrollbar ? 'auto' : 'scroll')};
  position: relative;

  transition: ${globalTransitionSettings};
  transform: translateY(${p => (p.isVisible ? 0 : '-50px')});
`;

const TopRightFixedContainer = styled.div`
  position: absolute;
  top: ${margins.size3};
  right: ${margins.size3};
`;
