import React from 'react';
import styled from 'styled-components/macro';

import {
  BUTTON_HEIGHT,
  colors,
  fonts,
  fontSizes,
  globalTransitionSettings,
  margins,
  media,
} from 'css/css';

import { useNotifContext } from 'js/util/notif-context';

import loader from 'img/shared/button-loading.svg';

interface Props {
  small?: boolean;
  large?: boolean;
  maxWidth?: string;
  plain?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  onClick?: (e: any) => void;
  loading?: boolean;
  customLoader?: any;
  adapting?: boolean;
  active?: boolean;
  display?: string;
  invalid?: boolean;
  invalidCallback?: (e: any) => void;
  invalidMessage?: string;
  style?: any;
  children?: any;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const Button: React.FC<Props> = ({
  small,
  large,
  maxWidth,
  plain,
  secondary,
  tertiary,
  onClick,
  loading,
  customLoader,
  adapting,
  active,
  display,
  invalid,
  invalidCallback,
  invalidMessage,
  style,
  children,
  className,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { showNotif } = useNotifContext();

  const handleClick = (e: any) => {
    if (invalid) {
      if (invalidCallback) invalidCallback(e);
      if (invalidMessage) {
        showNotif({
          message: invalidMessage,
          level: 'error',
        });
      }
    } else if (!loading && onClick) onClick(e);
  };

  const handleMouseEvent = (callback?: (e: any) => void) => (e: any) => {
    if (!loading && !invalid && callback) {
      callback(e);
    }
  };

  const handleMouseEnter = handleMouseEvent(onMouseEnter);
  const handleMouseLeave = handleMouseEvent(onMouseLeave);

  return (
    <StyledButton
      onClick={handleClick}
      active={!!active}
      display={display}
      invalid={!!loading || !!invalid}
      small={!!small}
      large={!!large}
      maxWidth={maxWidth}
      plain={!!plain}
      secondary={!!secondary}
      tertiary={!!tertiary}
      style={style}
      adapting={!!adapting}
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {loading ? (
        customLoader ? (
          // eslint-disable-next-line no-unneeded-ternary
          customLoader
        ) : small ? (
          <img src={loader} alt="" />
        ) : (
          <>
            <img src={loader} alt="" /> Loading
          </>
        )
      ) : (
        children
      )}
    </StyledButton>
  );
};

type ButtonTypes = {
  active: boolean;
  display?: string;
  invalid: boolean;
  small: boolean;
  large: boolean;
  maxWidth?: string;
  plain: boolean;
  secondary: boolean;
  tertiary: boolean;
  adapting: boolean;
};

const backgroundColorFor = (p: ButtonTypes) =>
  p.active && p.secondary
    ? colors.greyMain
    : p.secondary && p.invalid
    ? colors.greyLight
    : p.invalid
    ? colors.primaryLight
    : p.plain
    ? 'transparent'
    : p.secondary
    ? colors.greyLight
    : p.tertiary
    ? colors.tertiary2Light
    : colors.primaryMain;

const StyledButton = styled.button<ButtonTypes>`
  width: ${p => (p.large ? '100%' : p.maxWidth || 'fit-content')};
  display: ${p => p.display || 'inline'};
  user-select: none;
  margin: 0 auto;
  max-width: 100%;
  padding: ${p => (p.small ? `0px ${margins.size2}` : p.plain ? 0 : `0px ${margins.size3}`)};
  background-color: ${p => backgroundColorFor(p)};
  color: ${p =>
    p.secondary && p.invalid
      ? colors.blackLight
      : p.invalid
      ? colors.whiteMain
      : p.small && p.secondary
      ? colors.blackMain
      : p.plain
      ? colors.blackMain
      : p.tertiary
      ? colors.tertiary2Main
      : p.secondary
      ? colors.primaryMain
      : colors.whiteMain};
  border-radius: ${p => (p.plain ? 0 : '10px')};
  font-feature-settings: 'cv11' 1;
  -webkit-font-feature-settings: 'cv11' 1;
  -ms-font-feature-settings: 'cv11' 1;
  -moz-font-feature-settings: 'cv11' 1;
  border: none;
  ${p => p.plain && `border-bottom: 1px solid ${colors.blackMain};`}
  height: ${p => (p.small ? '24px' : p.plain ? 'unset' : BUTTON_HEIGHT)};
  font-family: ${p => (p.small || p.plain ? fonts.regular : fonts.bold)};
  font-size: ${p => (p.small ? fontSizes.size1 : fontSizes.size2)};
  cursor: ${p => (p.invalid ? 'default' : 'pointer')};
  transition: ${globalTransitionSettings};

  &:focus {
    outline: ${p => (p.invalid ? 'none' : '5px auto -webkit-focus-ring-color')};
  }

  &:hover {
    background-color: ${p =>
      p.invalid
        ? ''
        : p.secondary
        ? colors.greyMain
        : p.tertiary
        ? colors.tertiary2Mid
        : p.plain
        ? 'transparent'
        : colors.primaryDark};
        ${p => p.plain && `color: ${colors.primaryMain}`}
  }

  ${media.mobile} {
    ${p => p.adapting && `width: 100%;`};
    font-size: ${p => (p.small ? '13px' : '15px')};

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: ${p => backgroundColorFor(p)};
    }
  }
`;
