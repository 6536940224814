import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';

import {
  colors,
  FlexColumn,
  FlexRow,
  Heading3,
  margins,
  Text,
  globalTransitionSettings,
} from 'css/css';

import Channel from 'types/channel';

import { EVENT_STATUS } from 'js/util/util';
import { useUser } from 'js/providers/UserProvider';
import { Avatar } from 'js/components/shared/Avatar';

import warningSvg from 'img/chat/warning.svg';
import confirmedSvg from 'img/chat/confirmed-status.svg';
import declinedSvg from 'img/chat/declined-status.svg';
import pendingSvg from 'img/chat/pending-status.svg';

const EVENT_STATUS_DATA = {
  pending: {
    copy: 'Pending',
    svg: pendingSvg,
  },
  confirmed: {
    copy: 'Confirmed',
    svg: confirmedSvg,
  },
  declined: {
    copy: 'Declined',
    svg: declinedSvg,
  },
};

interface Props {
  selectedChannel: Channel;
  metDate?: string;
  userTimezone: string;
}

export const CalendarStatus: React.FC<Props> = ({ selectedChannel, metDate, userTimezone }) => {
  const { avatar: matchUserAvatar, userEventStatus, matchUserEventStatus } = selectedChannel;

  const user = useUser();
  const userName = `${user.first_name || ''} ${user.last_name || ''}`;
  const userAvatar = user.image;

  const isCallConfirmed =
    userEventStatus === EVENT_STATUS.confirmed && matchUserEventStatus === EVENT_STATUS.confirmed;

  const isCallDeclined =
    userEventStatus === EVENT_STATUS.declined || matchUserEventStatus === EVENT_STATUS.declined;

  const statusHeadline = isCallDeclined
    ? `${'Reschedule '} ${
        userEventStatus === EVENT_STATUS.declined && matchUserEventStatus !== EVENT_STATUS.declined
          ? 'or confirm '
          : ''
      } ${'your call!'}`
    : isCallConfirmed
    ? 'Call confirmed!'
    : userEventStatus === EVENT_STATUS.pending
    ? 'Confirm your call!'
    : 'Awaiting confirmation';

  const statusSvg = isCallConfirmed
    ? EVENT_STATUS_DATA.confirmed.svg
    : userEventStatus !== EVENT_STATUS.confirmed || matchUserEventStatus === EVENT_STATUS.declined
    ? warningSvg
    : EVENT_STATUS_DATA.pending.svg;

  return (
    <EventStatusContainer>
      <FlexRow style={{ marginBottom: margins.size2 }}>
        <FlexColumn style={{ alignItems: 'flex-start' }}>
          <Heading3>{statusHeadline}</Heading3>
          <Text>
            {moment(metDate).format(' dddd MMM DD [at] h:mma')} {userTimezone}
          </Text>
        </FlexColumn>
      </FlexRow>
      {!isCallConfirmed ? (
        <UserEventStatusContainer>
          <UserEventStatusBox
            eventStatus={matchUserEventStatus}
            name={selectedChannel.name}
            avatar={matchUserAvatar}
          />
          <UserEventStatusBox eventStatus={userEventStatus} name={userName} avatar={userAvatar} />
        </UserEventStatusContainer>
      ) : (
        <FlexRow style={{ marginBottom: margins.size2 }}>
          <AvatarStatusBox
            userAvatar={userAvatar}
            matchUserAvatar={matchUserAvatar}
            statusSvg={statusSvg}
          />
          <FadedText>Join the meeting room here on {moment(metDate).format('dddd')}!</FadedText>
        </FlexRow>
      )}
    </EventStatusContainer>
  );
};

const UserEventStatusBox = ({
  eventStatus,
  name,
  avatar,
}: {
  eventStatus: number | null;
  name: string;
  avatar: string;
}) => {
  const eventStatusData =
    eventStatus === EVENT_STATUS.confirmed
      ? EVENT_STATUS_DATA.confirmed
      : eventStatus === EVENT_STATUS.declined
      ? EVENT_STATUS_DATA.declined
      : EVENT_STATUS_DATA.pending;
  const eventStatusText = eventStatusData.copy;
  const eventStatusSvg = eventStatusData.svg;

  return (
    <UserEventStatus style={{ marginRight: '0px' }}>
      <FlexRow>
        <Avatar src={avatar} size1 style={{ marginRight: margins.size2 }} />
        <Text> {name} </Text>
      </FlexRow>
      <FlexRow>
        <FadedText style={{ margin: `0px ${margins.size2}` }}>{eventStatusText}</FadedText>
        <img src={eventStatusSvg} alt="Status" />
      </FlexRow>
    </UserEventStatus>
  );
};

const AvatarStatusBox = ({
  userAvatar,
  matchUserAvatar,
  statusSvg,
}: {
  userAvatar: string;
  matchUserAvatar: string;
  statusSvg: string;
}) => {
  return (
    <AvatarStatusContainer>
      <Avatar src={matchUserAvatar} size3 style={{ border: `2px solid ${colors.whiteMain}` }} />
      <Avatar
        src={userAvatar}
        size3
        style={{ position: 'relative', left: '-20px', border: `2px solid ${colors.whiteMain}` }}
      />
      <img
        src={statusSvg}
        alt=""
        style={{
          position: 'relative',
          left: '-36px',
          transition: globalTransitionSettings,
        }}
      />
    </AvatarStatusContainer>
  );
};

const EventStatusContainer = styled(FlexColumn)`
  margin-bottom: ${margins.size2};
  width: 100%;
  align-items: flex-start;
`;

const UserEventStatusContainer = styled(FlexRow)`
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
`;

const UserEventStatus = styled(FlexRow)`
  border-radius: 12px;
  margin-right: ${margins.size2};
  width: 100%;
  background: none;
  padding: ${margins.size1} 0px;
  justify-content: space-between;
`;

const AvatarStatusContainer = styled(FlexRow)`
  width: 84px;
  height: 48px;
  margin-right: ${margins.size2};
  align-items: flex-end;
`;

const FadedText = styled(Text)`
  color: ${colors.blackLight};
`;
