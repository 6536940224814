import React from 'react';
import styled from 'styled-components/macro';

import { margins, FlexColumn, FlexRow, FadedText, Link, colors } from 'css/css';
import { underlineAnimation } from 'css/animations';

import { Button } from 'js/components/shared/Button';
import { feedbackPage } from 'js/util/strings';
import { Stars } from 'js/components/shared/selectors/Stars';

import greyLock from 'img/feedback/grey-lock.svg';

import { QuestionHeading } from './Question';

export const FirstQuestion: React.FC<any> = ({
  matchFirstName,
  matchProfileLink,
  starRating,
  setStarRating,
  setNoMeeting,
  hadMeeting,
}) => (
  <FlexColumn>
    <QuestionHeading style={{ marginBottom: margins.size2 }}>
      How relevant was your match with{' '}
      <Link inline to={matchProfileLink}>
        <HoverHeading1 color={colors.secondaryMid}>{`${matchFirstName}?`}</HoverHeading1>
      </Link>
    </QuestionHeading>

    <FlexRow alignItems="flex-start" style={{ marginBottom: margins.size3 }}>
      <img style={{ marginRight: margins.size1 }} src={greyLock} alt="lock" />{' '}
      <FadedText>{feedbackPage.privacyStatement}</FadedText>
    </FlexRow>

    <Stars
      updateStarCount={setStarRating}
      rating={starRating}
      containerStyle={{ marginTop: margins.size3 }}
      starSize="58px"
    />

    <Button
      onClick={setNoMeeting}
      secondary={hadMeeting === null || hadMeeting}
      style={{ margin: `${margins.size6} auto 0px` }}
    >
      We didn&apos;t meet
    </Button>
  </FlexColumn>
);

const HoverHeading1 = styled.span`
  color: ${colors.primaryMain};
  white-space: nowrap;
  ${underlineAnimation}
`;
