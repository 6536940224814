import React, { ComponentPropsWithRef, memo, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';

import { colors } from 'css/css';

import { Participant } from 'js/components/callv2/contexts/ParticipantsContext/types';
import { useVideoTrack } from 'js/components/callv2/contexts/TracksContext/useVideoTrack';

interface Props extends ComponentPropsWithRef<'video'> {
  participant: Participant;
  isMirrored?: boolean;
  disabled?: boolean;
  isLocal: boolean;
  radius?: boolean;
}

export const TileVideo = memo(
  ({ participant, disabled = false, isLocal, radius, ...props }: Props) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    // const [isMirrored, setIsMirrored] = useState(false);
    const isMirrored = isLocal;
    const videoTrack = useVideoTrack(participant.id);

    // /**
    //  * Update mirroring when video track changes
    //  */
    // useEffect(() => {
    //   if (videoRef && videoRef.current && videoTrack) {
    //     const videoTrackSettings = videoTrack.getSettings();
    //     const isUsersFrontCamera =
    //       'facingMode' in videoTrackSettings && videoTrackSettings.facingMode === 'user';
    //     // only apply mirror effect to user facing camera
    //     if (isMirrored !== isUsersFrontCamera) {
    //       setIsMirrored(isUsersFrontCamera);
    //     }
    //   }
    // }, [isMirrored, participant, videoTrack]);

    useEffect(() => {
      if (videoRef && videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }, [participant.id]);

    useEffect(() => {
      if (videoRef && videoRef.current && videoTrack) {
        videoRef.current.srcObject = new MediaStream([videoTrack]);
      }
    }, [videoTrack, videoTrack?.id]);

    return (
      <VideoElement
        autoPlay
        muted
        playsInline
        ref={videoRef}
        isMirrored={isMirrored}
        disabled={disabled}
        radius={radius}
        {...props}
      />
    );
  },
);

const VideoElement = styled.video<{ isMirrored?: boolean; disabled?: boolean; radius?: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: ${colors.blackMain};
  box-shadow: inset 0px 0px 5px #000;
  transform: ${p => p.isMirrored && 'scaleX(-1)'};
  visibility: ${p => (p.disabled ? 'hidden' : 'visible')};
  position: absolute;
  border-radius: ${p => (p.radius ? '10px' : '0')};

  &::-webkit-media-controls {
    display: none;
  }
`;
