import React from 'react';
import styled from 'styled-components/macro';

import { fonts, media, colors, FlexColumn, margins } from 'css/css';

import { SelectOption } from 'js/components/shared/SelectOption';
import { PauseReasons } from 'js/components/settings/constants';

interface Props {
  selectedReasons: string[];
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  clickReasonOption: (reason: string) => void;
}

export const PauseAutopilotReasons: React.FC<Props> = ({
  selectedReasons,
  value,
  setValue,
  clickReasonOption,
}) => {
  const resizeTextInput = (e?: any) => {
    if (e) {
      e.style.height = 'inherit';
      e.style.height = `min(${e.scrollHeight}px, 7em)`;
    }
  };

  return (
    <>
      <FlexColumn style={{ width: '100%' }}>
        {Object.values(PauseReasons).map(option => (
          <SelectOption
            onClick={() => clickReasonOption(option)}
            isSelected={selectedReasons.includes(option)}
            optionText={option}
            isTextLeftAligned
            hasBottomBorder
          />
        ))}
      </FlexColumn>
      <InputContainer>
        <Input
          rows={1}
          value={value}
          placeholder="Add other reason..."
          onChange={e => {
            const newValue = e.target.value.trimStart();
            setValue(newValue);
            resizeTextInput(e.target);
          }}
        />
      </InputContainer>
    </>
  );
};

const InputContainer = styled(FlexColumn)`
  justify-content: center;
  width: 100%;
  margin-top: ${margins.size1};
  padding: ${margins.size2} ${margins.size3};
  border-radius: 10px;
  border: none;
  outline: none;
  min-height: 48px;
  background-color: ${colors.greyLight};
`;

const Input = styled.textarea.attrs(() => ({
  maxLength: 1000,
}))`
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${colors.blackMid};
  resize: none;
  font-family: ${fonts.regular};

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  &::placeholder {
    color: ${colors.blackLight};
  }

  ${media.mobile} {
    color: ${colors.blackMain};
  }
`;
