import React, { ClipboardEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { FlexRow, lineHeights, colors, fontSizes, fonts, margins, borders } from 'css/css';

export const CodeInput = ({
  setCode,
  onSubmit,
}: {
  setCode: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => void;
}) => {
  const input0 = useRef<HTMLInputElement | null>(null);
  const input1 = useRef<HTMLInputElement | null>(null);
  const input2 = useRef<HTMLInputElement | null>(null);
  const input3 = useRef<HTMLInputElement | null>(null);
  const input4 = useRef<HTMLInputElement | null>(null);
  const input5 = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (input0.current) {
      input0.current.focus();
    }
  }, [input0]);

  // Sometimes refs don't mount on initial load, so we trigger a re-render to mount them
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
  }, []);

  const inputs = [
    input0?.current,
    input1?.current,
    input2?.current,
    input3?.current,
    input4?.current,
    input5?.current,
  ];

  const onChange = (event: KeyboardEvent<HTMLInputElement>, i: number): void => {
    if (!(event.ctrlKey || event.metaKey || event.key === 'Tab')) {
      event.preventDefault();
    }

    const targetInput = inputs[i];
    if (!targetInput) return;

    const lastInput = inputs[i - 1];
    const nextInput = inputs[i + 1];

    const keyPressed = event.key;
    if (keyPressed === 'Backspace') {
      targetInput.value = '';
      if (lastInput) {
        lastInput.focus();
      }
    } else if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(keyPressed)) {
      if (!nextInput && targetInput.value) return;
      targetInput.value = event.key;
      if (nextInput) {
        nextInput.focus();
      }
    } else if (keyPressed === 'Enter' && !nextInput) {
      onSubmit();
    }
    const newCode = inputs.reduce((x, input) => (input ? x + input.value : x), '');
    setCode(newCode);
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text').slice(0, 6);
    if (pastedText && /^\d+$/.test(pastedText)) {
      inputs.forEach((inputRef, i) => {
        if (inputRef && pastedText[i] !== undefined) {
          // eslint-disable-next-line no-param-reassign
          inputRef.value = pastedText[i];
        }
      });

      const newCode = inputs.reduce((x, input) => (input ? x + input.value : x), '');
      setCode(newCode);
    }
  };

  return (
    <FlexRow id="otp" style={{ justifyContent: 'space-between', gap: margins.size1 }}>
      <DigitInput
        ref={input0}
        id="0"
        type="text"
        pattern="\d*"
        maxLength={1}
        onKeyDown={e => onChange(e, 0)}
        autoComplete="false"
        onPaste={e => handlePaste(e)}
      />
      <DigitInput
        ref={input1}
        id="1"
        type="text"
        pattern="\d*"
        maxLength={1}
        onKeyDown={e => onChange(e, 1)}
        autoComplete="false"
        onPaste={e => handlePaste(e)}
      />
      <DigitInput
        ref={input2}
        id="2"
        type="text"
        pattern="\d*"
        maxLength={1}
        onKeyDown={e => onChange(e, 2)}
        autoComplete="false"
        onPaste={e => handlePaste(e)}
      />
      <DigitInput
        ref={input3}
        id="3"
        type="text"
        pattern="\d*"
        maxLength={1}
        onKeyDown={e => onChange(e, 3)}
        autoComplete="false"
        onPaste={e => handlePaste(e)}
      />
      <DigitInput
        ref={input4}
        id="4"
        type="text"
        pattern="\d*"
        maxLength={1}
        onKeyDown={e => onChange(e, 4)}
        autoComplete="false"
        onPaste={e => handlePaste(e)}
      />
      <DigitInput
        ref={input5}
        id="5"
        type="text"
        pattern="\d*"
        maxLength={1}
        onKeyDown={e => onChange(e, 5)}
        autoComplete="false"
        onPaste={e => handlePaste(e)}
      />
    </FlexRow>
  );
};

const DigitInput = styled.input`
  height: 48px;
  width: 48px;
  text-align: center;
  border-radius: 12px;
  margin-bottom: 0px;
  margin-right: ${margins.size1};
  font-family: ${fonts.regular};
  font-size: ${fontSizes.size2};
  line-height: ${lineHeights.header};
  color: ${colors.blackMid};
  padding: ${margins.size3};
  border: ${borders.tertiary};
`;
