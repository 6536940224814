import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';

import { FlexColumn, Heading3, colors, margins, Clickable } from 'css/css';

import close from 'img/shared/close-small.svg';
import questionSvg from 'img/chat/question.svg';

interface Props {
  closeHeader: () => void;
  inputHeight: number;
  style: Record<string, any>;
}

export const IcebreakerInputHeader: React.FC<Props> = ({ closeHeader, inputHeight, style }) => {
  return (
    <Container $inputHeight={inputHeight} style={style}>
      <CloseButton onClick={closeHeader}>
        <img src={close} alt="close" />
      </CloseButton>
      <FlexColumn>
        <QuestionSvg alt="question" src={questionSvg} />
        <Heading3>Add your answer</Heading3>
      </FlexColumn>
    </Container>
  );
};

const Container = styled.div<{ $inputHeight: number }>`
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: ${margins.size3};
  max-height: calc(100vh - 100px);
  width: 100%;
  box-shadow: 0px 10px 0px rgba(255, 255, 255, 1), 0px -4px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: calc(${p => p.$inputHeight}px + env(safe-area-inset-bottom));
  transition: all 300ms ease-out;
`;

const QuestionSvg = styled(SVG)`
  & > rect {
    fill: ${colors.whiteMain};
  }
`;

const CloseButton = styled(Clickable)`
  position: absolute;
  top: ${margins.size2};
  right: ${margins.size2};
  max-height: 18px;
`;
