import React from 'react';
import styled from 'styled-components/macro';

import LogoImage from 'img/shared/logo2.svg';

export const Logo = ({ logoWidth, style }: any) => (
  <LogoContainer style={style} logoWidth={logoWidth} src={LogoImage} alt="Logo" />
);

type LogoTypes = { logoWidth: string };
const LogoContainer = styled.img<LogoTypes>`
  width: ${p => p.logoWidth || '120px'};
`;
