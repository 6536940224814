import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

import {
  colors,
  globalTransitionSettings,
  margins,
  media,
  FlexRow,
  HideMobile,
  Link,
} from 'css/css';

import { useMountEffect } from 'js/util/custom-hooks';
import { useUser, useUserAvailability } from 'js/providers/UserProvider';
import { postTestActivity } from 'js/util/api';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { useDataContext } from 'js/providers/DataContextProvider';
import { useFeedContextProvider } from 'js/providers/FeedProvider';

import logoIcon from 'img/shared/logo3.svg';
import xob from 'img/xobhcnul.svg';
import {
  homeSelectedSvg,
  homeSvg,
  calendarSelectedSvg,
  calendarSvg,
  inviteSelectedSvg,
  inviteSvg,
  chatSelectedSvg,
  chatSvg,
} from 'img/navbar-v2';

import { NavIconContainer } from './NavIconContainer';
import { ProfileMenu } from './ProfileMenu';
import { Banner } from './banner';

interface Props {
  setHeaderHeight?: (height: number) => void;
  isGreyPage?: boolean;
}

const isSpecial = Math.random() < 0.1;

interface IconProps {
  refString: string;
  isSelected: (location: string) => boolean;
}

interface ImgProps {
  isSelected: boolean;
  iconSvg: any;
  iconSelectedSvg: any;
  alt: string;
}

const isSameIcon = (s: string) => (a: IconProps, b: IconProps) =>
  a.refString === b.refString && a.isSelected(s) === b.isSelected(s);

const IconImg = styled.div<ImgProps>`
  ${p =>
    `background-image: url(${p.isSelected ? p.iconSelectedSvg : p.iconSvg});
     width: 35px;
     height: 35px;
     transition: ${globalTransitionSettings};
     ${media.desktop} {
       &:hover {
        background-image: url(${p.iconSelectedSvg});
       }
      }
     ${media.mobile} {
       transition-duration: 0s;
     }
  `}
`;

const Home: React.FC<IconProps> = React.memo(({ refString, isSelected }) => {
  const userAvailability = useUserAvailability();
  const { shouldShowDeckNotif } = useFeedContextProvider();
  const hasSignedUp = userAvailability?.has_signed_up_weekly;
  const isOnHome = isSelected('/home');

  return (
    <Link to={`/home${refString}`} title="Home">
      <NavIconContainer
        isSelected={isOnHome}
        notificationCount={isOnHome || !hasSignedUp ? undefined : shouldShowDeckNotif ? 1 : 0}
      >
        <IconImg
          isSelected={isOnHome}
          iconSvg={homeSvg}
          iconSelectedSvg={homeSelectedSvg}
          alt="home"
        />
      </NavIconContainer>
    </Link>
  );
}, isSameIcon('/home'));

const Meeting: React.FC<IconProps> = React.memo(({ refString, isSelected }) => {
  const userAvailability = useUserAvailability();
  const hasSignedUp = Boolean(userAvailability?.has_signed_up_weekly);
  const hasAutopilot = Boolean(userAvailability?.availability_autopilot?.autopilot);

  const isMeetingsSelected = isSelected('/meetings') || isSelected('/weekly');
  const notificationCount =
    hasSignedUp || hasAutopilot || isSelected('/meetings') || isSelected('/weekly') ? 0 : 1;

  return (
    <Link to={`/meetings${refString}`} title="Meetings">
      <NavIconContainer isSelected={isMeetingsSelected} notificationCount={notificationCount}>
        <IconImg
          isSelected={isMeetingsSelected}
          iconSvg={calendarSvg}
          iconSelectedSvg={calendarSelectedSvg}
          alt="meetings"
        />
      </NavIconContainer>
    </Link>
  );
}, isSameIcon('/meetings'));

const Invite: React.FC<IconProps> = React.memo(({ refString, isSelected }) => {
  const isInviteSelected = isSelected('/invite');
  const { setNavbarIconClicked } = useDataContext();

  return (
    <Link to={`/invite${refString}`} title="Invite">
      <NavIconContainer
        isStroked
        isSelected={isInviteSelected}
        onClick={() => isInviteSelected && setNavbarIconClicked('invite')}
      >
        <IconImg
          isSelected={isInviteSelected}
          iconSvg={inviteSvg}
          iconSelectedSvg={inviteSelectedSvg}
          alt="invite"
        />
      </NavIconContainer>
    </Link>
  );
}, isSameIcon('/invite'));

// const Connections: React.FC<IconProps> = React.memo(({ refString, isSelected }) => {
//   const isConnectionsSelected = isSelected('/connections');
//   const { setNavbarIconClicked } = useDataContext();

//   return (
//     <Link to={`/connections${refString}`} title="Connections">
//       <NavIconContainer
//         isSelected={isConnectionsSelected}
//         onClick={() => isConnectionsSelected && setNavbarIconClicked('connections')}
//       >
//         <IconImg
//           isSelected={isConnectionsSelected}
//           iconSvg={connectionsSvg}
//           iconSelectedSvg={connectionsSelectedSvg}
//           alt="connections"
//         />
//       </NavIconContainer>
//     </Link>
//   );
// }, isSameIcon('/connections'));

const Chat: React.FC<IconProps> = React.memo(({ refString, isSelected }) => {
  const { unreadCount } = useChatContext();
  const isChatSelected = isSelected('/chat');
  const { setNavbarIconClicked } = useDataContext();

  return (
    <Link to={`/chat${refString}`} title="Chat">
      <NavIconContainer
        isSelected={isChatSelected}
        notificationCount={isChatSelected ? undefined : unreadCount}
        onClick={() => isChatSelected && setNavbarIconClicked('chat')}
      >
        <IconImg
          isSelected={isChatSelected}
          iconSvg={chatSvg}
          iconSelectedSvg={chatSelectedSvg}
          alt="chat"
        />
      </NavIconContainer>
    </Link>
  );
}, isSameIcon('/chat'));

export const Navbar: React.FC<Props> = props => {
  const history = useHistory();

  const { setHeaderHeight, isGreyPage } = props;
  const user = useUser();
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const navbarRef = useRef<HTMLDivElement>(null);
  const bannerRef = useRef<HTMLDivElement>(null);
  const [navbarHeight, setNavbarHeight] = useState(0);

  const special =
    isSpecial &&
    user &&
    (user.profile_id === '8ba464ad84cc' ||
      user.profile_id === '3cff36c51b84' ||
      user.profile_id === 'e47251d7806c' ||
      user.profile_id === 'a48e1012b937' ||
      user.profile_id === '8ba88f6cfe89' ||
      user.profile_id === 'df2145027358');

  const currentLocation = window.location.pathname;
  const refString = `?ref=${currentLocation.split('/')[1]}-nav`;
  const isSelected = (location: string) => currentLocation.includes(location);

  useEffect(() => {
    if (special) {
      postTestActivity('xob', 'viewed');
    }
  }, [special]);

  useLayoutEffect(() => {
    if (navbarRef.current && setHeaderHeight) {
      setHeaderHeight(
        navbarRef.current.clientHeight +
          (bannerRef?.current?.clientHeight !== undefined ? bannerRef.current.clientHeight : 0),
      );
    }
    return () => {
      if (setHeaderHeight) {
        setHeaderHeight(0);
      }
    };
  }, [navbarRef.current, bannerRef.current, isBannerVisible]);

  useLayoutEffect(() => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.clientHeight);
    }
  }, [navbarRef.current]);

  useMountEffect(() => {
    const icons = [
      homeSelectedSvg,
      homeSvg,
      calendarSelectedSvg,
      calendarSvg,
      inviteSelectedSvg,
      inviteSvg,
      chatSelectedSvg,
      chatSvg,
    ];
    icons.forEach(icon => {
      new Image().src = icon;
    });
  });

  return (
    <>
      <PositionContainer isGreyPage={isGreyPage}>
        <NavbarContainer ref={navbarRef}>
          <HeaderContainer>
            <HideMobile display="flex" style={{ alignItems: 'center' }}>
              <LogoImage
                src={special ? xob : logoIcon}
                style={{ marginRight: margins.size4 }}
                isClickable={!!user}
                alt="Lunchclub Logo"
                onClick={() => !!user && history.push('/home')}
              />
            </HideMobile>
            {user && user.first_name && user.verified ? (
              <IconsContainer>
                <Home refString={refString} isSelected={isSelected} />
                <Meeting refString={refString} isSelected={isSelected} />
                <Chat refString={refString} isSelected={isSelected} />
                {/* {!isMobile && <Connections refString={refString} isSelected={isSelected} />} */}
                <Invite refString={refString} isSelected={isSelected} />
                <ProfileMenu user={user} isSelected={isSelected} />
              </IconsContainer>
            ) : null}
          </HeaderContainer>
        </NavbarContainer>
      </PositionContainer>
      <BannerPositionContainer isGreyPage={isGreyPage} navbarHeight={navbarHeight}>
        <NavbarContainer ref={bannerRef}>
          <Banner setIsBannerVisible={setIsBannerVisible} />
        </NavbarContainer>
      </BannerPositionContainer>
    </>
  );
};

const PositionContainer = styled.div<{ isGreyPage?: boolean }>`
  ${p =>
    p.isGreyPage &&
    ` z-index: 3;
  position: fixed;
  top: 0;
  ${media.mobile} {
    top: unset;
    bottom: 0;
  }`}
`;

const BannerPositionContainer = styled.div<{ isGreyPage?: boolean; navbarHeight: number }>`
  ${p =>
    p.isGreyPage &&
    ` z-index: 2;
  position: fixed;
  top: ${p.navbarHeight}px;
  ${media.mobile} {
    top: unset;
    bottom: ${p.navbarHeight}px;
  }`}
`;

const NavbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: ${globalTransitionSettings};
  ${media.mobile} {
    flex-direction: column-reverse;
    border-bottom: none;
    box-shadow: none;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${margins.size6};
  min-height: ${margins.size6};
  padding: ${margins.size2} ${margins.size3};
  background-color: ${colors.blackMain};
  ${media.mobile} {
    height: auto;
    padding: 0 ${margins.size2};
    background-color: ${colors.blackMain};
    padding-bottom: calc(env(safe-area-inset-top) - ${margins.size3});
  }
`;

const LogoImage = styled.img<{ isClickable: boolean }>`
  height: 35px;
  ${p => p.isClickable && 'cursor: pointer;'};
  margin: ${margins.size3};
  transition: ${globalTransitionSettings};
  &:hover {
    opacity: 50%;
  }
`;

const IconsContainer = styled(FlexRow)`
  ${media.mobile} {
    width: 100%;
    justify-content: space-around;
    padding-top: ${margins.size3};
    padding-bottom: ${margins.size3};
  }
`;
