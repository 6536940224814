export interface Invitee {
  mode: 'email';
  community: number | null;
  email: string;
  first_name: string;
  image: string | null;
  last_name: string;
  num_lc_contacts: number;
  notes?: string[];
}

export interface MobileContactInvitee {
  mode: 'contact';
  phone_number: string;
  name: string;
  num_lc_contacts: number;
}

export interface MobileInvite {
  invite_link?: string;
}

export interface Contact {
  email: string;
  first_name: string;
  last_name: string;
  image: string;
  headline: string;
  is_user: 1 | 0;
}

export enum InviteSuggestionResponse {
  NONE = 0,
  INVITED = 1,
  DECLINED = 2,
}
