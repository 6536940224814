import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import { Clickable, colors, FlexColumn, Heading3, margins, media, Text } from 'css/css';
import { underlineAnimation, UnderlineAnimationProps } from 'css/animations';

import { availability } from 'types/availability';
import { LunchclubMatch } from 'types/matches';

import {
  addParameterToURL,
  getIsMobile,
  getParameterByName,
  getTimezoneAbbr,
  INTERNATIONAL_TIME_LOCALES,
} from 'js/util/util';
import { useDataContext } from 'js/providers/DataContextProvider';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { pauseAutopilotUntil, postAvailability } from 'js/util/api';
import { CoachingPlanEligibilityResult, postAcceptCoachingPlan } from 'js/api/coaching-plan';
import { useResource } from 'js/util/use-resource';
import { apiFailure } from 'js/util/strings';
import { useNotifContext } from 'js/util/notif-context';
import { MobilePageHeader } from 'js/components/shared/page-wrappers';
import { AutopilotStatuses, CadenceTypes } from 'js/components/autopilot/constants';
import { CoachingPlanOptIn } from 'js/components/coaching_plan/CoachingPlanOptIn';
import { CoachingPlanOptInMobile } from 'js/components/coaching_plan/CoachingPlanOptInMobile';
import { useMountEffect } from 'js/util/custom-hooks';

import { CarouselMatchProfileCard } from './components/MobileMatchesCarousel';
import { UpcomingMatchesDesktop } from './components/UpcomingMatchesDesktop';
import { UpcomingAvailabilities } from './components/UpcomingAvailabilities';
import { PendingMatch } from './components/PendingMatchDesktop';

interface Props {
  userAvailability: availability | undefined;
  upcomingMatches: LunchclubMatch[];
}

export const AutopilotMeetings: React.FC<Props> = ({ userAvailability, upcomingMatches = [] }) => {
  const [autopilotState, setAutopilotState] = useState<AutopilotStatuses>(AutopilotStatuses.on);
  const [eligibleCoachingPlan] = useResource<CoachingPlanEligibilityResult>(
    'discover/coaching_plan/eligible',
  );
  const [showCoachingPlan, setShowCoachingPlan] = useState<boolean>();

  const history = useHistory();
  const user = useUser();
  const { allLocales } = useDataContext();
  const { showNotif } = useNotifContext();
  const { fetchUser, updateUserAvailability } = useUserContextProvider();

  const coachingPlanMatchString = userAvailability?.coaching_plan_details?.matches_string;
  const userLocale = allLocales.find(rawLocale => rawLocale.id === user.locale);
  const is24Hour = useMemo(
    () => !!userLocale?.id && INTERNATIONAL_TIME_LOCALES.includes(userLocale.id),
    [userLocale],
  );
  const isMobile = getIsMobile();
  const userTimezone = getTimezoneAbbr(user.locale_info.timezone);
  const upcomingAvailability = userAvailability?.time_slots;
  const hasNoAvailability = upcomingAvailability?.length === 0 && upcomingMatches.length === 0;

  const pendingMatches = userAvailability
    ? userAvailability.time_slots.filter(
        timeslot => !upcomingMatches.find(m => m.time_slot_with_exact_time === timeslot),
      )
    : [];

  const tuesday = 2;
  const today = moment().isoWeekday();
  const thisTuesday =
    today <= tuesday
      ? moment().isoWeekday(tuesday)
      : moment()
          .add(1, 'weeks')
          .isoWeekday(tuesday);
  const nextTuesday = moment(thisTuesday).add(1, 'weeks');
  const thisTuesday8pm = moment(thisTuesday)
    .hour(20)
    .minute(0)
    .second(0)
    .tz(user.locale_info.timezone);
  const nextTuesday8pm = moment(nextTuesday)
    .hour(20)
    .minute(0)
    .second(0)
    .tz(user.locale_info.timezone);

  const [pendingMatchesThisWeek] = _.partition(pendingMatches, m =>
    moment(m).isBetween(thisTuesday8pm, nextTuesday8pm),
  );

  const [upcomingMatchesThisWeek] = _.partition(upcomingMatches, m =>
    moment(m.time_slot_with_exact_time).isBetween(thisTuesday8pm, nextTuesday8pm),
  );

  const hasNoAvailabilityThisWeek =
    pendingMatchesThisWeek.length === 0 && upcomingMatchesThisWeek.length === 0;

  useEffect(() => {
    if (user.autopilot_paused_until === 'Punished' && hasNoAvailability) {
      setAutopilotState(AutopilotStatuses.punished);
    } else if (user.autopilot_paused_until && hasNoAvailability) {
      setAutopilotState(AutopilotStatuses.paused);
    } else if (userAvailability?.passed && hasNoAvailabilityThisWeek) {
      setAutopilotState(AutopilotStatuses.passedThisWeek);
    } else if (!userAvailability?.autopilot_is_current_week && hasNoAvailability) {
      if (userAvailability?.autopilot_cadence === CadenceTypes.Biweekly.value) {
        setAutopilotState(AutopilotStatuses.notCadenceWeekBiweekly);
      } else {
        setAutopilotState(AutopilotStatuses.notCadenceWeekMonthly);
      }
    } else {
      setAutopilotState(AutopilotStatuses.on);
    }
  }, [user, userAvailability]);

  useMountEffect(() => {
    window.hj('trigger', 'cohesive');
  });

  const onClick = ({ enableViaLink }: { enableViaLink?: boolean }) => {
    if (autopilotState === AutopilotStatuses.paused || enableViaLink) {
      (async () => {
        const res = await pauseAutopilotUntil(null);
        if (res.ok) {
          await Promise.all([fetchUser(), updateUserAvailability()]);
        } else {
          showNotif({
            message: apiFailure.message,
            level: 'error',
          });
        }
      })();
    } else {
      history.push(addParameterToURL('tab', 'availabilities', '/settings'));
    }
  };

  const onClickPassWeek = () => {
    if (autopilotState === AutopilotStatuses.on) {
      (async () => {
        const videoNeighborhood = allLocales.find(n => n.id === user.locale)?.videoNeighborhood;
        const res = await postAvailability({
          locale: user.locale,
          timeslots: [],
          neighborhoods: [videoNeighborhood || 0],
          numberOfMeetings: 0,
          autopilotCadence: userAvailability?.autopilot_cadence,
          targetLocale:
            userAvailability?.availability_autopilot?.target_locale !== null ? user.locale : null,
        });
        if (res.ok) {
          await Promise.all([fetchUser(), updateUserAvailability()]);
        } else {
          showNotif({
            message: apiFailure.message,
            level: 'error',
          });
        }
      })();
    }
  };

  useMountEffect(() => {
    const enable = getParameterByName('enable_autopilot');
    if (enable === '1') {
      onClick({ enableViaLink: true });
    }
    const passWeek = getParameterByName('pass_week');
    if (passWeek === '1') {
      onClickPassWeek();
    }
  });

  useEffect(() => {
    if (eligibleCoachingPlan && showCoachingPlan === undefined) {
      setShowCoachingPlan(true);
    }
  }, [eligibleCoachingPlan]);

  const coachingPlanAccept = async () => {
    if (eligibleCoachingPlan?.eligible && eligibleCoachingPlan.type) {
      await postAcceptCoachingPlan(eligibleCoachingPlan.type);
      await Promise.all([fetchUser(), updateUserAvailability()]);
    }
    setShowCoachingPlan(false);
  };

  const coachingPlanDecline = () => {
    setShowCoachingPlan(false);
  };

  return (
    <Container>
      {userAvailability?.coaching_plan_details?.on_coaching_plan && !!coachingPlanMatchString ? (
        <Column>
          <MobilePageHeader style={{ marginBottom: margins.size2 }}>
            Upcoming Curated Matches
          </MobilePageHeader>
          <Heading3 color={colors.blackLight}>with {coachingPlanMatchString}</Heading3>
        </Column>
      ) : (
        <MobilePageHeader style={{ marginBottom: margins.size2 }}>
          Upcoming Matches
        </MobilePageHeader>
      )}

      {(autopilotState === AutopilotStatuses.on || upcomingMatches.length > 0) && (
        <>
          <Text
            color={colors.blackLight}
            style={{
              marginBottom: margins.size3,
            }}
          >
            {isMobile
              ? 'Manage your availabilities in '
              : 'You can manage your availabilities in the '}
            <span>
              <Clickable
                onClick={() =>
                  history.push(addParameterToURL('tab', 'availabilities', '/settings'))
                }
              >
                <AnimatedHeading color={colors.primaryMain}>settings</AnimatedHeading>
              </Clickable>
            </span>
            {isMobile ? '' : ' page!'}
          </Text>
        </>
      )}

      <MatchesCarouselContainer $isEmpty={!upcomingMatches.length}>
        {!!upcomingMatches.length &&
          (isMobile ? (
            upcomingMatches.map(match => (
              <CarouselMatchProfileCard key={match.email} match={match} />
            ))
          ) : (
            <UpcomingMatchesDesktop
              matches={upcomingMatches}
              userTimezone={userTimezone}
              is24Hour={is24Hour}
            />
          ))}

        {pendingMatches?.length ? (
          <>
            {pendingMatches.map(timeslot => {
              if (isMobile) {
                return (
                  <UpcomingAvailabilities
                    timeslots={[timeslot]}
                    autopilotState={autopilotState}
                    onClick={onClick}
                  />
                );
              }
              return (
                <PendingMatch
                  timeslot={timeslot}
                  userTimezone={userTimezone}
                  initialOpen
                  is24Hour={is24Hour}
                />
              );
            })}
          </>
        ) : (
          hasNoAvailability && (
            <UpcomingAvailabilities
              autopilotState={autopilotState}
              onClick={onClick}
              timeslots={pendingMatches}
            />
          )
        )}
      </MatchesCarouselContainer>

      {autopilotState === AutopilotStatuses.on && (
        <>
          <Text color={colors.blackLight} style={{ marginTop: margins.size2 }}>
            {'Busy this week? '}
            <span>
              <Clickable onClick={onClickPassWeek}>
                <AnimatedHeading color={colors.primaryMain}>Skip this week</AnimatedHeading>
              </Clickable>
            </span>
          </Text>
        </>
      )}

      {autopilotState === AutopilotStatuses.on &&
        showCoachingPlan &&
        !!eligibleCoachingPlan?.eligible &&
        !!eligibleCoachingPlan.details &&
        !!eligibleCoachingPlan.type &&
        (isMobile ? (
          <CoachingPlanOptInMobile
            accept={coachingPlanAccept}
            decline={coachingPlanDecline}
            details={eligibleCoachingPlan.details}
            planType={eligibleCoachingPlan.type}
          />
        ) : (
          <CoachingPlanOptIn
            accept={coachingPlanAccept}
            decline={coachingPlanDecline}
            details={eligibleCoachingPlan.details}
            planType={eligibleCoachingPlan.type}
            showCoachingPlan={showCoachingPlan}
          />
        ))}
    </Container>
  );
};

const Container = styled(FlexColumn)`
  margin: 0 auto;
  align-items: flex-start;

  ${media.mobile} {
    height: 100%;
    width: 100%;
    margin: 0;
    align-items: center;
  }
`;

const Column = styled(FlexColumn)`
  align-items: flex-start;
  margin-bottom: ${margins.size3};
  ${media.mobile} {
    align-items: center;
  }
`;

const MatchesCarouselContainer = styled.div<{ $isEmpty: boolean }>`
  padding: 0 ${margins.size3};
  margin: 0 -${margins.size3};
  margin-bottom: ${margins.size2};
  flex: 1;
  ${p => !p.$isEmpty && 'overflow-x: scroll;'};
  scroll-snap-type: x mandatory;
  display: flex;
  justify-content: stretch;
  align-self: stretch;

  scrollbar-color: transparent;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar:horizontal {
    height: 0;
  }
  &:after {
    ${p => !p.$isEmpty && "content: ' '; width: 16px; flex-shrink: 0;"}
  }

  ${media.desktop} {
    height: 100%;
    flex-direction: column;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
  }
`;

const AnimatedHeading = styled(Heading3)<UnderlineAnimationProps>`
  color: ${colors.primaryMain};
  ${underlineAnimation}
`;
