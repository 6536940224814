import React, { useState } from 'react';
import { Transition } from 'react-transition-group';

import { useMountEffect } from 'js/util/custom-hooks';

const defaultTransitionStyles: Record<string, any> = {
  entering: { opacity: 0, transform: `translateY(-%50)`, maxHeight: '0px' },
  entered: { opacity: 1, transform: `translateY(0)`, maxHeight: '300px' },
  exiting: { opacity: 1, transform: `translateY(0)`, maxHeight: '300px' },
  exited: { opacity: 0, transform: `translateY(-%50)`, maxHeight: '0px' },
};

const AnimatedEntry: React.FC<{
  animationGap?: number;
  transitionStyles?: Record<string, any>;
}> = ({ children, animationGap = 200, transitionStyles = defaultTransitionStyles }) => {
  const [animationPhase, setAnimationPhase] = useState(0);

  useMountEffect(() => {
    const animationTimeouts = React.Children.map(children, (_: React.ReactNode, idx) =>
      setTimeout(() => {
        setAnimationPhase(idx);
      }, idx * animationGap),
    );

    return () => animationTimeouts?.forEach(e => clearTimeout(e));
  });

  // Every child passed in must be a react element.
  return (
    <>
      {React.Children.map(
        children,
        (child, idx) =>
          child && (
            <AnimationWrapper trigger={animationPhase >= idx} transitionStyles={transitionStyles}>
              {React.cloneElement(child as React.ReactElement<any>, {
                style: { ...(child as React.ReactElement<any>)?.props.style },
              })}
            </AnimationWrapper>
          ),
      )}
    </>
  );
};

const AnimationWrapper: React.FC<{
  trigger: boolean;
  transitionStyles: Record<string, any>;
}> = ({ trigger, children, transitionStyles }) => {
  return (
    <Transition in={trigger} timeout={200} unmountOnExit>
      {state => (
        <div
          style={{
            transition: 'all 0.5s ease-in-out',
            display: 'contents',
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

export default AnimatedEntry;
