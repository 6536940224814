import React from 'react';
import styled from 'styled-components/macro';

import { colors, margins, FlexRow } from 'css/css';

interface Props {
  numCompleted: number;
  total: number;
}

export const ProgressBar: React.FC<Props> = ({ numCompleted, total }) => {
  return (
    <ProgressFillContainer>
      {[...Array(total).keys()].map(index => (
        <ProgressFill
          isCompleted={index <= numCompleted}
          leftRadius={index === 0}
          rightRadius={index + 1 === total}
        />
      ))}
    </ProgressFillContainer>
  );
};

const ProgressFillContainer = styled(FlexRow)`
  width: 100%;
  height: 8px;
  margin-bottom: ${margins.size3};
  gap: ${margins.size1};
`;

const ProgressFill = styled.div<{
  isCompleted: boolean;
  leftRadius: boolean;
  rightRadius: boolean;
}>`
  background-color: ${p => (p.isCompleted ? colors.secondaryMain : colors.greyMain)};
  height: 8px;
  width: 100%;
  border-radius: ${p =>
    `${p.leftRadius ? '10px' : '0px'} ${p.rightRadius ? '10px 10px' : '0px 0px'} ${
      p.leftRadius ? '10px' : '0px'
    }`};
`;
