import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { FlexRow } from 'css/css';

import { CoachingPlanCoaches, CoachingPlanMatchMeta } from 'js/api/coaching-plan';

import face1 from 'img/coaching-plan/face1.svg';
import face2 from 'img/coaching-plan/face2.svg';
import face3 from 'img/coaching-plan/face3.svg';
import face4 from 'img/coaching-plan/face4.svg';
import expertFace from 'img/coaching-plan/expert-medium.svg';
import founderFace from 'img/coaching-plan/founder-medium.svg';
import investorFace from 'img/coaching-plan/investor-medium.svg';
import expertToken from 'img/coaching-plan/expert-tiny.svg';
import founderToken from 'img/coaching-plan/founder-tiny.svg';
import investorToken from 'img/coaching-plan/investor-tiny.svg';

export const CoachingPlanAvatars: React.FC<{
  matches: CoachingPlanMatchMeta[];
}> = ({ matches }) => {
  const Avatars = matches.map(({ type_name, count }, i) => {
    let ctr = i + (i > 0 ? matches[i - 1].count : 0);
    return (
      <React.Fragment key={type_name}>
        {Array.from({ length: count }).map((_, j) => {
          ctr += 1;
          return <Coach key={`${type_name}-${j + i}`} coach={type_name} order={ctr} />;
        })}
      </React.Fragment>
    );
  });

  return <FlexRow>{Avatars}</FlexRow>;
};

// TODO use this when wildcard is ready
export const newFaceMapper = (coach: CoachingPlanCoaches) => {
  switch (coach) {
    case 'FOUNDER':
      return founderFace;
    case 'INVESTOR':
      return investorFace;
    case 'EXPERT':
      return expertFace;
    case 'WILDCARD':
      return face4;
    default:
      return face4;
  }
};

export const faceMapper = (coach: CoachingPlanCoaches) => {
  switch (coach) {
    case 'FOUNDER':
      return face1;
    case 'INVESTOR':
      return face2;
    case 'EXPERT':
      return face3;
    case 'WILDCARD':
      return face4;
    default:
      return face4;
  }
};

export const tokenMapper = (coach: CoachingPlanCoaches) => {
  switch (coach) {
    case 'FOUNDER':
      return founderToken;
    case 'INVESTOR':
      return investorToken;
    case 'EXPERT':
      return expertToken;
    case 'WILDCARD':
      return face4;
    default:
      return face4;
  }
};

const Coach = ({ coach, order }: { coach: CoachingPlanCoaches; order: number }) => (
  // TODO use tokenMapper when wildcard ready
  <AnimatedImg order={order} alt={`${coach} token`} src={faceMapper(coach)} />
);

const animateIn = keyframes`
 from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;

// TODO set margin to -3px whn wildcard is ready
const AnimatedImg = styled.img<{ order: number }>`
  width: 36px;
  margin-left -12px;
  animation: ${animateIn} 1s forwards;
  animation-fill-mode: both;
  animation-delay: calc(${props => props.order} * 200ms);
`;
