import React from 'react';
import styled from 'styled-components/macro';

import { margins, Heading3, colors } from 'css/css';

export const SecondMatchMessage = () => (
  <Container>
    <Heading3>Most of our users take at least 2 meetings a week</Heading3>
  </Container>
);

const Container = styled.div`
  width: 100%;
  margin-top: ${margins.size2};
  background-color: ${colors.greyLight};
  padding: ${margins.size3};
  border-radius: 5px;
  text-align: left;
`;
