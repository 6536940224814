import React, { useState } from 'react';

import { FlexColumn, margins } from 'css/css';

import { CoachingPlanEligibilityDetails } from 'js/api/coaching-plan';
import { Button } from 'js/components/shared/Button';
import {
  DarkCardBackground,
  WhiteCard,
  WhiteCardHeader,
  WhitePrompt,
  WhiteSubprompt,
} from 'js/components/shared/MobileModal';
import { postTestActivity } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';
import { getPlatform } from 'js/util/util';

import { CoachingPlanVisualization } from './CoachingPlanInfo';
import { CoachingPlanSplash } from './CoachingPlanSplash';
import { CoachingPlanCopies } from './CoachingPlanCopies';

interface Props {
  accept: () => void;
  decline: () => void;
  details: CoachingPlanEligibilityDetails;
  planType: number;
}

export const CoachingPlanOptInMobile: React.FC<Props> = ({
  accept,
  decline,
  details,
  planType,
}) => {
  const [isAccepted, setIsAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useMountEffect(() => {
    postTestActivity(`coaching-plan-${planType}`, `${getPlatform()}-view`);
  });

  const handleClose = () => {
    postTestActivity(`coaching-plan-${planType}`, `${getPlatform()}-decline`);
    decline();
  };

  const handleAccept = async () => {
    setIsLoading(true);
    postTestActivity(`coaching-plan-${planType}`, `${getPlatform()}-accept`);
    await accept();
    setIsAccepted(true);
    setIsLoading(false);
  };

  return (
    <DarkCardBackground>
      <FlexColumn alignItems="flex-start" style={{ position: 'fixed', bottom: 0 }}>
        <>
          <WhitePrompt>Curated matches, just for {details.plural}!</WhitePrompt>
          <WhiteSubprompt>{CoachingPlanCopies.Heading(details)}</WhiteSubprompt>
          <WhiteCard style={{ width: '100%' }}>
            <WhiteCardHeader
              onClose={handleClose}
              title={`Meet these ${details.singular}-curated matches!`}
            />
            {isAccepted ? (
              <CoachingPlanSplash matches={details.matches} />
            ) : (
              <CoachingPlanVisualization matches={details.matches} />
            )}
            {!isAccepted && (
              <FlexColumn>
                <Button
                  style={{ marginTop: margins.size4 }}
                  large
                  onClick={handleAccept}
                  loading={isLoading}
                >
                  Sign up for the plan!
                </Button>
              </FlexColumn>
            )}
          </WhiteCard>
        </>
      </FlexColumn>
    </DarkCardBackground>
  );
};
