import React, { useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components/macro';
import moment from 'moment';
import { nanoid } from 'nanoid';

import { Clickable, colors, FlexColumn, FlexRow, fonts, margins, media } from 'css/css';

import Channel, { LCMessageType, MorpheusMessageType } from 'types/channel';

import {
  onEnterKey,
  getIsMobile,
  NETWORK_TYPES,
  getParameterByName,
  LC_BOT_PUBLIC_ID,
} from 'js/util/util';
import { postChatCreateConversation } from 'js/util/api';
import { useUser } from 'js/providers/UserProvider';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { useNotifContext } from 'js/util/notif-context';
import { apiFailure } from 'js/util/strings';

import send from 'img/chat/send.svg';
import calendar from 'img/shared/calendar.svg';

import { ConversationBubble } from './ConversationBubble';
import { TimeslotsConfirmationInput } from './scheduler/TimeslotsConfirmationInput';
import { PendingMessage, transitionSettings, updateReadStatus } from './ConversationContainer';
import { IcebreakerInputHeader } from './IcebreakerInputHeader';

interface Props {
  selectedChannel: Channel;
  inCall: boolean;
  openScheduler?: () => void;
  isTimeslotsConfirmationVisible: boolean;
  closeTimeslotsConfirmation?: () => void;
  isChangingTimes?: boolean;
  setIsChangingTimes?: React.Dispatch<React.SetStateAction<boolean>>;
  pendingMessage?: PendingMessage;
  setPendingMessage: React.Dispatch<React.SetStateAction<PendingMessage | undefined>>;
  isFirstTimeScheduling?: boolean;
  isReconnect?: boolean;
  metDate?: string;
  isChangingScheduleTimes?: boolean;
  resetIsChangingScheduleTimes?: () => void;
  userTimezone?: string;
  isMobileKeyboardOpen: boolean;
  setIsMobileKeyboardOpen: React.Dispatch<React.SetStateAction<boolean>>;
  inputHeight: number;
  setInputHeight: React.Dispatch<React.SetStateAction<number>>;
  isOpen: boolean;
  callbackOnSent?: () => void;
  shouldShowCalendarIcon?: boolean;
  isUserScheduleInitiator?: boolean;
  setAreBubblesVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  isRespondingToIcebreaker: boolean;
  setIsRespondingToIcebreaker: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConversationInput: React.FC<Props> = ({
  selectedChannel,
  inCall,
  openScheduler,
  isTimeslotsConfirmationVisible,
  closeTimeslotsConfirmation,
  isChangingTimes,
  pendingMessage,
  setPendingMessage,
  isFirstTimeScheduling,
  isReconnect,
  setIsChangingTimes,
  metDate,
  isChangingScheduleTimes,
  resetIsChangingScheduleTimes,
  userTimezone,
  isMobileKeyboardOpen,
  setIsMobileKeyboardOpen,
  inputHeight,
  setInputHeight,
  isOpen,
  callbackOnSent,
  shouldShowCalendarIcon,
  isUserScheduleInitiator,
  setAreBubblesVisible,
  isRespondingToIcebreaker,
  setIsRespondingToIcebreaker,
}) => {
  const [value, setValue] = useState('');
  const [inputValues, setInputValues] = useState<Record<string, string>>({});
  const [hasCreateConversationFailed, setHasCreateConversationFailed] = useState(false);
  const [shouldCreateConversation, setShouldCreateConversation] = useState(false);

  const {
    twilio,
    savedTimeslots,
    setSavedTimeslots,
    addLocalMessage,
    clearLocalMessages,
    isPopupOpen,
    setIsPopupOpen,
    setLastMessageStatus,
  } = useChatContext();
  const { showNotif } = useNotifContext();
  const {
    firstName,
    localMessages,
    profileId,
    conversation,
    isBlocked,
    messages,
    isMorpheusMatch,
  } = selectedChannel;

  const allSavedTimeslots = [
    ...(savedTimeslots[profileId]?.timeslots?.suggested || []),
    ...(savedTimeslots[profileId]?.timeslots?.custom || []),
  ];
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const user = useUser();
  const myProfileId = user.profile_id;
  const isMobile = getIsMobile();
  const confirmedFirstMatchTimeslot = savedTimeslots[profileId]?.confirmedTimeslot;
  const isFromNuxPerfectMatch = getParameterByName('isNuxPerfectMatch');
  const isScheduling = (conversation?.attributes as any)?.isScheduling;
  const shouldShowCalendarButton = !value && (!inCall || isScheduling) && shouldShowCalendarIcon;
  const shouldDisplayBadge = isScheduling && !value && !isPopupOpen && !isUserScheduleInitiator;

  const defaultInputValues: Record<string, string> = {
    changingTimes: 'Sorry, none of those times work for me. Here are some times that do.',
    changingScheduleTimes: 'Actually, these work better for me.',
    firstTimeScheduling: 'Here are some times that work for me. Let me know what works for you!',
    reconnecting: `Hey ${firstName}, it was great to meet you. Let’s reconnect!`,
    rescheduling: `Hi ${firstName}! Our meeting time doesn't work for me, can we reschedule? Thanks!`,
  };

  const hasMessagesWithMatch = messages.find(
    m => m.author !== LC_BOT_PUBLIC_ID && m.author !== 'Lunchclub',
  );

  const shouldDisplayConfirmBubbles =
    !isMorpheusMatch &&
    !isBlocked &&
    !inCall &&
    !hasMessagesWithMatch &&
    !localMessages.length &&
    selectedChannel.networkType === NETWORK_TYPES.MATCH;

  useEffect(() => {
    if (inputContainerRef.current) {
      setInputHeight(inputContainerRef.current.clientHeight);
    }
    return () => {
      setInputHeight(0);
    };
  }, [inputContainerRef.current?.clientHeight, isMobileKeyboardOpen]);

  useEffect(() => {
    if (inputRef.current && !isMobileKeyboardOpen) {
      inputRef.current.blur();
    }
  }, [isMobileKeyboardOpen, inputRef.current]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.enterKeyHint = 'send';
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (!inputRef.current || !profileId) {
      return;
    }
    if (!isMobile) {
      inputRef.current.focus();
    }
  }, [inputRef.current, profileId]);

  useEffect(() => {
    if (!profileId) {
      return;
    }
    const val = inputValues[profileId] || '';
    setValue(val);
  }, [profileId, inputValues]);

  useEffect(() => {
    setHasCreateConversationFailed(false);
    setShouldCreateConversation(!conversation);
  }, [profileId]);

  useEffect(() => {
    if (
      !value &&
      isTimeslotsConfirmationVisible &&
      profileId &&
      !!allSavedTimeslots.length &&
      (isScheduling || !isFromNuxPerfectMatch)
    ) {
      setValue(
        isChangingTimes
          ? defaultInputValues.changingTimes
          : isChangingScheduleTimes
          ? defaultInputValues.changingScheduleTimes
          : isFirstTimeScheduling
          ? defaultInputValues.firstTimeScheduling
          : isReconnect
          ? defaultInputValues.reconnecting
          : defaultInputValues.rescheduling,
      );
    }
  }, [
    isTimeslotsConfirmationVisible,
    profileId,
    savedTimeslots,
    isReconnect,
    isFirstTimeScheduling,
  ]);

  useEffect(() => {
    if (isFromNuxPerfectMatch && confirmedFirstMatchTimeslot) {
      const timeslotInUTC = moment
        .tz(confirmedFirstMatchTimeslot, user.locale_info.timezone)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');

      setPendingMessage({
        profileId,
        message: '',
        messageAttributes: {
          inCall: false,
          confirmedTimeslot: timeslotInUTC,
          isReconnecting: false,
          isScheduling: false,
        },
        conversationAttributes: {
          isScheduling: false,
          isReconnecting: false,
          hasPrevScheduled: true,
          ...{ metDate: timeslotInUTC },
        },
      });
    }

    if (isFromNuxPerfectMatch && !!allSavedTimeslots.length && !isScheduling) {
      setPendingMessage({
        profileId,
        message: '',
        messageAttributes: {
          inCall: false,
          schedulerTimeslots: allSavedTimeslots,
          isReconnecting: false,
          isScheduling: true,
        },
        conversationAttributes: {
          isScheduling: true,
          isReconnecting: false,
          schedulerAuthor: user.profile_id,
        },
      });
    }
  }, [isFromNuxPerfectMatch, confirmedFirstMatchTimeslot]);

  useEffect(() => {
    if (!pendingMessage || !profileId) return;
    if (profileId !== pendingMessage.profileId) {
      setPendingMessage(undefined);
      return;
    }
    if (!hasCreateConversationFailed) {
      const message = {
        message_type: 'local',
        author: myProfileId,
        body: pendingMessage.message,
        attributes: pendingMessage.messageAttributes,
        dateUpdated: moment().format(),
        sid: nanoid(),
        conversationAttributes: pendingMessage.conversationAttributes,
      };
      addLocalMessage(profileId, message);
    }
    if (setIsChangingTimes) {
      setIsChangingTimes(false);
    }
    if (
      !pendingMessage.messageAttributes.confirmedTimeslot &&
      !pendingMessage.messageAttributes.canceledSchedule
    ) {
      setInputValues(prev => ({ ...prev, [profileId]: '' }));
    }

    const createConversationOrAlert = async () => {
      /* TODO: This doesn't have to be called for every send request while conversation doesn't exist.
      This is only called for every request as we can display an error notification. This should moved
      to channels where we'd have an instance of `postChatCreateConversation` for each channel and await
      on the same request.
      */
      const res = await postChatCreateConversation(profileId);
      if (!res.ok) {
        setHasCreateConversationFailed(true);
        clearLocalMessages(profileId);
        if (res.status === 429) {
          showNotif({
            message: "You've exceeded the number of allowed new conversations per day",
            level: 'error',
          });
        } else {
          showNotif({
            message: apiFailure.message,
            level: 'error',
          });
        }
      }
    };

    if (!conversation) {
      createConversationOrAlert();
    }
    setPendingMessage(undefined);
    if (callbackOnSent) {
      callbackOnSent();
    }
  }, [profileId, pendingMessage, conversation]);

  useEffect(() => {
    if (setAreBubblesVisible) {
      setAreBubblesVisible(!!shouldDisplayConfirmBubbles);
    }
  }, [shouldDisplayConfirmBubbles]);

  useEffect(() => {
    setInputValues(prev => ({
      ...prev,
      [profileId]: value,
    }));
  }, [value]);

  const sendMessage = (message: string) => {
    if (!message || !profileId) return;
    if (!selectedChannel) return;
    setLastMessageStatus('sending');
    if (allSavedTimeslots.length) {
      if (closeTimeslotsConfirmation) {
        closeTimeslotsConfirmation();
      }
      setPendingMessage({
        profileId,
        message,
        messageAttributes: {
          inCall,
          schedulerTimeslots: allSavedTimeslots,
          isReconnecting: isReconnect,
          isScheduling: true,
        },
        conversationAttributes: {
          isScheduling: true,
          isReconnecting: isReconnect,
        },
      });
      setSavedTimeslots(prev => ({
        ...prev,
        [profileId]: { ...prev[profileId], timeslots: { suggested: [], custom: [] } },
      }));
      if (isChangingScheduleTimes && resetIsChangingScheduleTimes) {
        resetIsChangingScheduleTimes();
      }
    } else if (isRespondingToIcebreaker) {
      setPendingMessage({
        profileId,
        message,
        messageAttributes: {
          inCall: false,
          messageType: LCMessageType.MORPHEUS,
          morpheusType: MorpheusMessageType.ICEBREAKER_RESPONSE,
        },
      });
    } else {
      setPendingMessage({
        profileId,
        message,
        messageAttributes: {
          inCall,
        },
      });
    }
  };

  if (!selectedChannel || !profileId) return null;

  const createConversation = async (passedProfileId: string) => {
    const res = await postChatCreateConversation(passedProfileId);
    if (!res.ok) {
      setHasCreateConversationFailed(true);
      clearLocalMessages(passedProfileId);
    }
  };

  const submitInput = async (e?: React.KeyboardEvent) => {
    if (!selectedChannel) return;

    updateReadStatus(myProfileId, selectedChannel);

    if (e) {
      if (shouldCreateConversation && !isFromNuxPerfectMatch) {
        setShouldCreateConversation(false);
        createConversation(profileId);
      }

      const isShiftPressed = e.shiftKey;
      const textAreaInput = e?.target;
      onEnterKey(e, async () => {
        if (isShiftPressed) {
          return;
        }
        if (!value && allSavedTimeslots.length) {
          showNotif({ message: 'Please include a message.', level: 'error' });
        }
        e.preventDefault();
        if (!twilio || !profileId || !value) return;

        const messageText = value.trim();
        setValue('');
        setTimeout(() => resizeTextInput(textAreaInput), 100);
        sendMessage(messageText);
      });
    }
  };

  const resizeTextInput = (e?: any) => {
    if (e) {
      e.style.height = 'inherit';
      e.style.height = `min(${e.scrollHeight}px, 7em)`;
    }
  };

  const submitInputOnButtonClick = async () => {
    const textAreaInput = document.getElementsByTagName('textarea')[0];
    textAreaInput.focus();

    if (!selectedChannel || !twilio || !profileId || !value) return;
    if (!value && allSavedTimeslots.length) {
      showNotif({ message: 'Please include a message.', level: 'error' });
    }
    updateReadStatus(myProfileId, selectedChannel);

    const messageText = value.trim();
    setValue('');
    sendMessage(messageText);
    setTimeout(() => resizeTextInput(textAreaInput), 100);
  };

  const clearDefaultInputValue = () => {
    if (Object.values(defaultInputValues).includes(value)) {
      setValue('');
    }
  };

  const handleClick = () => {
    if (!value && (!inCall || isScheduling) && shouldShowCalendarButton) {
      setIsPopupOpen(prev => !prev);
    } else {
      submitInputOnButtonClick();
    }
  };

  return (
    <Transition in={isOpen} appear timeout={transitionSettings.timeout} unmountOnExit>
      {state => (
        <>
          {shouldDisplayConfirmBubbles && (
            <ConversationBubble
              selectedProfileId={profileId}
              metDate={metDate}
              sendMessage={sendMessage}
              style={transitionSettings.styles[state]}
              inputHeight={inputHeight}
            />
          )}
          {isRespondingToIcebreaker && (
            <IcebreakerInputHeader
              style={transitionSettings.styles[state]}
              inputHeight={inputHeight}
              closeHeader={() => setIsRespondingToIcebreaker(false)}
            />
          )}
          <Container ref={inputContainerRef} style={transitionSettings.styles[state]}>
            <InputBackdrop />
            <InputContainer shouldChangeColor={isTimeslotsConfirmationVisible}>
              <TimeslotsConfirmationInput
                openScheduler={openScheduler}
                clearDefaultInputValue={clearDefaultInputValue}
                closeTimeslotsConfirmation={closeTimeslotsConfirmation}
                isTimeslotsConfirmationVisible={isTimeslotsConfirmationVisible}
                userTimezone={userTimezone}
              />
              <FlexRow justifyContent="space-between" style={{ width: '100%', minHeight: '36px' }}>
                <Input
                  rows={1}
                  ref={inputRef}
                  value={value}
                  placeholder={isMobile ? 'Message' : 'Type your message here...'}
                  onChange={e => {
                    setIsPopupOpen(false);
                    const newValue = e.target.value.trimStart();
                    setValue(newValue);
                    resizeTextInput(e.target);
                  }}
                  onKeyDown={submitInput}
                  onClick={() => submitInput()}
                  onFocus={() => {
                    if (isMobile) {
                      setTimeout(() => {
                        setIsMobileKeyboardOpen(true);
                      }, 100);
                    }
                  }}
                  onBlur={() => isMobile && setIsMobileKeyboardOpen(false)}
                />
                <ClickableContainer
                  role="button"
                  onClick={handleClick}
                  onMouseDown={e => e.stopPropagation()}
                >
                  <InputButton
                    src={shouldShowCalendarButton ? calendar : send}
                    alt="Send message"
                  />
                </ClickableContainer>
                {selectedChannel.networkType !== NETWORK_TYPES.LC_BOT && shouldDisplayBadge && (
                  <Badge />
                )}
              </FlexRow>
            </InputContainer>
          </Container>
        </>
      )}
    </Transition>
  );
};

const Container = styled(FlexRow)`
  width: 100%;
  padding: ${margins.size2};
  padding-top: 0;
  position: absolute;
  bottom: 0;
  ${media.mobile} {
    padding-bottom: calc(max(env(safe-area-inset-bottom), ${margins.size2}));
    position: fixed;
    left: 0;
    transition: all 300ms ease-out;
  }
`;

const InputBackdrop = styled.div`
  position: absolute;
  background-color: ${colors.greyLight};
  border-radius: 10px;
  opacity: 0.7;
  width: 96%;
  min-height: 52px;
`;

const InputContainer = styled(FlexColumn)<{ shouldChangeColor: boolean }>`
  position: relative;
  width: 100%;
  padding: ${margins.size2} ${margins.size3};
  border-radius: 10px;
  border: none;
  outline: none;
  min-height: 52px;
  backdrop-filter: blur(10px);
  background-color: ${p => (p.shouldChangeColor ? colors.greyLight : 'transparent')};
  box-shadow: -${margins.size2} calc(max(env(safe-area-inset-bottom), ${margins.size2})) 0 calc(
      max(env(safe-area-inset-bottom), ${margins.size2})
    ) ${colors.whiteMain};
`;

const Input = styled.textarea.attrs(() => ({
  maxLength: 1000,
}))`
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${colors.blackMid};
  resize: none;
  font-family: ${fonts.regular};

  &::placeholder {
    color: ${colors.blackMid};
  }

  ${media.mobile} {
    color: ${colors.blackMain};
  }
`;

const InputButton = styled.img`
  display: initial;
  cursor: pointer;
`;

const ClickableContainer = styled(Clickable)`
  display: flex;
  flex-direction: flex-column;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  margin-right: ${margins.size2};
  margin-left: auto;
  border-radius: 50%;
`;

const Badge = styled.div`
  position: absolute;
  min-width: 10px;
  min-height: 10px;
  top: 15px;
  right: 30px;
  border-radius: 50%;
  background-color: ${colors.tertiary2Main};
`;
