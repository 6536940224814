import React from 'react';
import { useHistory } from 'react-router';

import { FlexColumn, Heading1, Text, margins, Heading2 } from 'css/css';

import { Modal } from 'js/components/shared/NewModal';
import { useUIStateContext } from 'js/components/callv2/contexts/UIStateContext';
import { Button } from 'js/components/shared/Button';
import { useCallV2Context } from 'js/components/callv2/contexts/CallContext';

export const CallExitModal: React.FC = () => {
  const { setShowCallExitModal, showCallExitModal } = useUIStateContext();
  const { leaveCall } = useCallV2Context();
  const history = useHistory();

  const handleModalClose = () => {
    leaveCall();
    history.push('/home');
    setShowCallExitModal(false);
  };

  const handleReload = () => {
    window.location.reload();
  };
  return (
    <Modal hideScrollbar showModal={showCallExitModal}>
      <FlexColumn alignItems="center">
        <Heading1 style={{ margin: `${margins.size4} 0`, textAlign: 'center' }}>
          Multiple tabs found
        </Heading1>
        <Heading2 style={{ marginBottom: margins.size4 }}>
          Lunchclub detected another tab in the same call
        </Heading2>
        <Text style={{ marginBottom: margins.size4 }}>
          Having multiple tabs in the same call can potentially cause audio and video issues. Please
          close any other tabs that are using Lunchclub and reload the page or exit the call on the
          current tab.
        </Text>
        <div style={{ display: 'flex' }}>
          <Button secondary onClick={handleReload}>
            Reload
          </Button>
          <div style={{ margin: `0 ${margins.size2}` }} />
          <Button onClick={handleModalClose}>Exit Call</Button>
        </div>
      </FlexColumn>
    </Modal>
  );
};
