import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import moment, { Moment } from 'moment';

import { Text, margins, colors, FlexColumn, Clickable, Heading2 } from 'css/css';

import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import {
  Backdrop,
  SimpleModalWrap,
  useSimpleModal,
  WhiteCardHeader,
} from 'js/components/shared/MobileModal';
import { getIsMobile } from 'js/util/util';
import { pauseAutopilotUntil, postTestActivity } from 'js/util/api';
import { apiFailure } from 'js/util/strings';
import { useNotifContext } from 'js/util/notif-context';
import { useOutsideAlerter } from 'js/util/custom-hooks';
import { getModalTransitionDuration } from 'js/components/chat/endorsement-modal';
import { Button } from 'js/components/shared/Button';
import { AutoselectableOption } from 'js/components/shared/AutoselectableOption';
import { SelectOption } from 'js/components/shared/SelectOption';

import close from 'img/weekly/x.svg';

import {
  getPauseMeetingsSubtitle,
  PauseSteps,
  PauseUntilValues,
  SettingsSectionsTitles,
} from '../../constants';
import { ToggleSection } from '../shared-components/ToggleSection';

import { PauseAutopilotReasons } from './PauseAutopilotReasons';

export const PauseWeeklyMeetigns = () => {
  const [isSelectionOpen, setIsSelectionOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(PauseSteps.DURATION);
  const [selectedPauseDuration, setSelectedPauseDuration] = useState<number | null>(null);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [value, setValue] = useState<string>('');

  const { autopilot_paused_until } = useUser();
  const { fetchUser, updateUserAvailability } = useUserContextProvider();
  const { openModal, closeModal, wrapperProps } = useSimpleModal();
  const { showNotif } = useNotifContext();
  const isMobile = getIsMobile();
  const transitionDuration = getModalTransitionDuration(true);
  const containerRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(containerRef, () => setIsSelectionOpen(false));

  useEffect(() => {
    if (isSelectionOpen) {
      setSelectedPauseDuration(null);
      setCurrentStep(PauseSteps.DURATION);
      setSelectedReasons([]);
    }
  }, [isSelectionOpen]);

  const pauseOptions = [1, 2, 3, 4, 5].map(i => {
    const targetTime = i === 5 ? 'Infinity' : moment.utc().add(7 * i, 'days');
    return {
      id: i,
      value: targetTime,
      name: PauseUntilValues[i.toString()],
    };
  });

  const updatePauseTime = async (pauseId: number | null) => {
    if (isLoading) return;
    setIsLoading(true);
    const pauseUntil =
      pauseId === null
        ? null
        : pauseOptions[pauseId - 1].value === 'Infinity'
        ? 'Infinity'
        : (pauseOptions[pauseId - 1].value as Moment).format('YYYY-MM-DD hh:mm:ss');
    const res = await pauseAutopilotUntil(pauseUntil);
    if (res.ok) {
      // have to fetch user first to get the paused_until, before getting the availability with no autopilot,
      // otherwise shouldShowAvailability gets set to false briefly, which causes component remount
      await fetchUser();
      await updateUserAvailability();
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
    setCurrentStep(PauseSteps.REASON);
    setIsLoading(false);
  };

  const sendPauseReason = async (selectedPauseReasons: string[]) => {
    if (isLoading) return;
    setIsLoading(true);
    const res = await postTestActivity('autopilot-pause-reasons', selectedPauseReasons.join(', '));
    if (!res.ok) {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
    setIsLoading(false);
    setIsSelectionOpen(false);
    setCurrentStep(PauseSteps.DURATION);
  };

  const clickDurationOption = (optionId: number) => {
    if (selectedPauseDuration === optionId) {
      setSelectedPauseDuration(null);
    } else {
      setSelectedPauseDuration(optionId);
    }
  };

  const clickReasonOption = (option: string) => {
    if (selectedReasons.includes(option)) {
      setSelectedReasons(selectedReasons.filter(r => r !== option));
    } else {
      setSelectedReasons([...selectedReasons, option]);
    }
  };

  const handleButtonClick = () => {
    if (currentStep === PauseSteps.DURATION) {
      updatePauseTime(selectedPauseDuration);
    } else if (currentStep === PauseSteps.REASON) {
      sendPauseReason([...selectedReasons, value]);
    }
  };

  const handleToggleClick = () => {
    if (autopilot_paused_until) {
      updatePauseTime(null);
    } else {
      setIsSelectionOpen(prev => !prev);
      openModal();
    }
  };

  const handleCloseModal = () => {
    closeModal();
    setIsSelectionOpen(false);
    setCurrentStep(PauseSteps.DURATION);
  };

  const getIsButtonInvalid = () => {
    if (currentStep === PauseSteps.DURATION) {
      return !selectedPauseDuration;
    }
    if (currentStep === PauseSteps.REASON) {
      return !(selectedReasons.length || value);
    }
    return false;
  };

  return (
    <div style={{ position: 'relative' }}>
      <ToggleSection
        headingTitle={SettingsSectionsTitles.PAUSE_MEETINGS}
        subtitle={getPauseMeetingsSubtitle({ pausedUntil: autopilot_paused_until })}
        onClick={handleToggleClick}
        isOn={!!autopilot_paused_until}
      />
      {isSelectionOpen &&
        (!isMobile ? (
          <>
            <Backdrop
              isOpen
              onClick={() => setIsSelectionOpen(false)}
              transitionDuration={transitionDuration}
              blur={15}
              style={{ zIndex: 4 }}
            />
            <ModalContainer ref={containerRef}>
              <CloseButton onClick={handleCloseModal}>
                <img src={close} alt="close" style={{ verticalAlign: 'initial' }} />
              </CloseButton>
              <PauseOptionsContainer>
                <Heading2>{currentStep.header}</Heading2>
                <FlexColumn style={{ width: '100%', margin: `${margins.size4} 0` }}>
                  {currentStep === PauseSteps.DURATION &&
                    pauseOptions.map(option => (
                      <SelectOption
                        onClick={() => clickDurationOption(option.id)}
                        isSelected={selectedPauseDuration === option.id}
                        optionText={option.name}
                      />
                    ))}
                  {currentStep === PauseSteps.REASON && (
                    <PauseAutopilotReasons
                      selectedReasons={selectedReasons}
                      value={value}
                      setValue={setValue}
                      clickReasonOption={clickReasonOption}
                    />
                  )}
                </FlexColumn>
                <Button invalid={getIsButtonInvalid()} large onClick={handleButtonClick}>
                  {currentStep.buttonText}
                </Button>
              </PauseOptionsContainer>
            </ModalContainer>
          </>
        ) : (
          <SimpleModalWrap {...wrapperProps} noAction isAboveNavbar>
            <WhiteCardHeader title="Pause recurring meetings" onClose={handleCloseModal} />
            <Text style={{ textAlign: 'center', marginBottom: margins.size3 }}>
              {currentStep.header}
            </Text>
            {currentStep === PauseSteps.DURATION && (
              <div>
                {pauseOptions.map(option => (
                  <AutoselectableOption
                    selected={false}
                    onClick={() => {
                      updatePauseTime(option.id);
                    }}
                    key={option.id}
                    text={option.name}
                  />
                ))}
              </div>
            )}
            {currentStep === PauseSteps.REASON && (
              <>
                <PauseAutopilotReasons
                  selectedReasons={selectedReasons}
                  value={value}
                  setValue={setValue}
                  clickReasonOption={clickReasonOption}
                />
                <Button
                  invalid={getIsButtonInvalid()}
                  large
                  onClick={handleButtonClick}
                  style={{ marginTop: margins.size2 }}
                >
                  {currentStep.buttonText}
                </Button>
              </>
            )}
          </SimpleModalWrap>
        ))}
    </div>
  );
};

const ModalContainer = styled(FlexColumn)`
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 600px;
  justify-content: center;
  background-color: ${colors.whiteMain};
`;

const CloseButton = styled(Clickable)`
  height: 40px;
  width: 40px;
  margin-left: auto;
  padding: 10px;
`;

const PauseOptionsContainer = styled(FlexColumn)`
  width: 100%;
  padding: ${margins.size3} ${margins.size5} ${margins.size4} ${margins.size5};
`;
