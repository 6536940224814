import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import {
  colors,
  margins,
  globalTransitionSettings,
  Card,
  Clickable,
  Heading3,
  FlexRow,
  Text,
} from 'css/css';

import { Clubpoints } from 'js/components/shared/Clubpoints';
import { useUser } from 'js/providers/UserProvider';
import { useMountEffect, useOutsideAlerter } from 'js/util/custom-hooks';
import { SmallArrow } from 'js/components/shared/SmallArrow';

import exit from 'img/weekly/exit.svg';
import global from 'img/weekly/global.svg';
import local from 'img/weekly/local.svg';
import targetLocation from 'img/weekly/target-location.svg';

import { Locale } from '../types';

interface Props {
  locales: Locale[];
  selectedLocale?: number | null;
  setSelectedLocale: (locale: number | null) => void;
  numMeetings: number;
}

const nameCompare = (a: Locale, b: Locale) => {
  const aName = a.name || '';
  const bName = b.name || '';
  if (aName < bName || '') return -1;
  if (aName > bName) return 1;
  return 0;
};

export const LocationSelector: React.FC<Props> = ({
  locales,
  selectedLocale,
  setSelectedLocale,
  numMeetings,
}) => {
  const user = useUser();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sortedLocales, setSortedLocales] = useState<Locale[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  useMountEffect(() => {
    const availableLocales = locales
      .filter(locale => locale.large && locale.active)
      .sort(nameCompare);
    setSortedLocales(availableLocales);
  });

  useOutsideAlerter(containerRef, () => setDropdownOpen(false));

  const isHome = selectedLocale !== null && selectedLocale === user.locale;
  const hasTargetLocale = selectedLocale !== null;
  const targetLocaleName =
    (selectedLocale !== null && locales.find(locale => locale.id === selectedLocale)?.name) || '';
  const pointsCost = hasTargetLocale && !isHome ? 5 * numMeetings : 0;

  return (
    <div style={{ width: '100%' }} ref={containerRef}>
      <Clickable
        style={{ width: '100%', marginBottom: margins.size1 }}
        onClick={() => setDropdownOpen(prev => !prev)}
        noOutline
      >
        <Container>
          <FlexRow>
            <FlexRow>
              {hasTargetLocale ? (
                <>
                  <Icon
                    src={isHome ? local : targetLocation}
                    alt={`Someone in ${targetLocaleName}`}
                  />
                  <Heading3 style={{ textDecoration: 'underline' }}>{targetLocaleName}</Heading3>
                </>
              ) : (
                <>
                  <Icon src={global} alt="Best match anywhere" />
                  <Heading3>Best match anywhere</Heading3>
                </>
              )}
            </FlexRow>
          </FlexRow>
          <FlexRow>
            {!!pointsCost && <Clubpoints clubpoints={pointsCost} />}
            {dropdownOpen ? (
              <img src={exit} alt="cancel" />
            ) : (
              <SmallArrow direction="down" color={colors.primaryMain} />
            )}
          </FlexRow>
        </Container>
      </Clickable>
      <MainSelector
        isOpen={dropdownOpen}
        locales={sortedLocales}
        close={() => setDropdownOpen(false)}
        setSelectedLocale={setSelectedLocale}
      />
    </div>
  );
};

const Container = styled(Card)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  width: 100%;
  padding: 0 ${margins.size3};
`;

interface MainSelectorProps {
  isOpen: boolean;
  locales: Locale[];
  close: () => void;
  setSelectedLocale: (locale: number | null) => void;
}
const MainSelector: React.FC<MainSelectorProps> = ({
  isOpen,
  locales,
  close,
  setSelectedLocale,
}) => {
  const user = useUser();
  const [selectingCity, setSelectingCity] = useState(false);

  useEffect(() => {
    setSelectingCity(false);
  }, [isOpen]);

  const setLocaleSelection = (localeId: number | undefined) => {
    if (localeId === 0 || localeId) setSelectedLocale(localeId);
    else setSelectedLocale(null);
    close();
  };

  const homeLocale = user.locale_info.name;
  const canSelectHome = !!locales.find(locale => locale.id === user.locale);

  return (
    <MainSelectorContainer showOptions={selectingCity ? 5 : isOpen ? 3 : 0}>
      {selectingCity ? (
        <>
          {locales
            .filter(locale => locale.id !== user.locale)
            .map(locale => (
              <Clickable
                style={{ minHeight: '44px' }}
                key={locale.id}
                onClick={() => setLocaleSelection(locale.id)}
              >
                <Row>
                  <Text color={colors.blackMain}>{locale.name}</Text>
                </Row>
              </Clickable>
            ))}
        </>
      ) : (
        <>
          <Clickable onClick={() => setLocaleSelection(undefined)}>
            <Row>
              <Icon src={global} alt="Match with someone anywhere" />
              <Text>Best match anywhere</Text>
            </Row>
          </Clickable>
          {canSelectHome && (
            <Clickable onClick={() => setLocaleSelection(user.locale)}>
              <Row>
                <Icon src={local} alt={`Match with someone in ${homeLocale}`} />
                <Text>Current city ({homeLocale})</Text>
              </Row>
            </Clickable>
          )}
          <Clickable onClick={() => setSelectingCity(true)}>
            <Row justifyContent="space-between">
              <Heading3>Select by city</Heading3>
              <SmallArrow direction="right" color={colors.blackMain} />{' '}
            </Row>
          </Clickable>
        </>
      )}
    </MainSelectorContainer>
  );
};

const MainSelectorContainer = styled(Card)<{ showOptions: number }>`
  width: 100%;
  height: 100%;
  max-height: ${p => (p.showOptions === 5 ? '222px' : p.showOptions === 3 ? '134px' : '0px')};
  transition: ${globalTransitionSettings};
  margin-bottom: ${p => (p.showOptions ? margins.size1 : '0px')};
  opacity: ${p => (p.showOptions ? '100%' : '0%')};
  overflow-y: scroll;
`;

const Row = styled(FlexRow)`
  height: 44px;
  padding: 0 ${margins.size3};
  align-items: center;
  transition: ${globalTransitionSettings};
  :hover {
    background-color: ${colors.greyLight};
  }
`;

const Icon = styled(SVG)`
  height: 16px;
  min-height: 16px;
  margin-right: ${margins.size3};
`;
