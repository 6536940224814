import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';
import { DailyParticipant } from '@daily-co/daily-js';

import { Heading3, margins, colors, globalTransitionSettings, FlexColumn } from 'css/css';

import { useMove } from 'js/util/custom-hooks';
import { getIsMobile } from 'js/util/util';
import { Button } from 'js/components/shared/Button';

import screenShareIcon from 'img/call/screen-share-big.svg';

import { useCallContext } from './CallContext';
import { VideoElement, Unselectable } from './Video';

interface Props {
  className?: string;
  participant: DailyParticipant;
  onClick?: () => void;
  fullScreenWidth: number;
  fullScreenHeight: number;
}

export const ScreenShare: React.FC<Props> = ({
  className,
  participant,
  onClick,
  fullScreenWidth,
  fullScreenHeight,
}) => {
  const [size, setSize] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(0.5625);
  const isMobile = getIsMobile();

  const videoRef = useRef<HTMLVideoElement>(null);

  const { sidebarView, setAreControlsVisible } = useCallContext();

  useEffect(() => {
    if (!participant) {
      return;
    }
    const maxScreenShareWidth = Math.floor(fullScreenHeight / aspectRatio);
    const offset = !sidebarView ? 168 + 16 : 0;
    const screenShareWidth = Math.min(fullScreenWidth - offset, maxScreenShareWidth);

    setSize(screenShareWidth);
  }, [participant, fullScreenWidth, fullScreenHeight, sidebarView]);

  // Update video aspect ratio if remote screen share window gets resized
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return undefined;
    const handleResize = () => {
      if (!video) return;
      const width = video.videoWidth;
      const height = video.videoHeight;
      if (width && height) {
        setAspectRatio(height / width);
      }
    };

    handleResize();
    video.addEventListener('resize', handleResize);

    return () => video.removeEventListener('resize', handleResize);
  }, [videoRef, participant]);

  useEffect(() => {
    if (videoRef?.current && participant?.screenVideoTrack) {
      videoRef.current.srcObject = new MediaStream([participant.screenVideoTrack]);
    }
  }, [participant.screenVideoTrack]);

  // Video stream cleanup
  useEffect(
    () => () => {
      if (!videoRef.current) {
        return;
      }

      const videoStream = videoRef.current.srcObject;

      if (videoStream) {
        const videoTracks = (videoStream as MediaStream).getTracks();

        videoTracks.forEach(track => {
          track.stop();
        });
      }
    },
    [],
  );

  const { x, y, handleMouseMove } = useMove();
  useEffect(() => {
    if (!isMobile) {
      setAreControlsVisible(true);
    }
  }, [x, y]);

  if (isMobile) {
    return null;
  }

  return (
    <ScreenShareContainer
      className={className}
      size={size}
      aspectRatio={aspectRatio}
      isSidebarVisible={!!sidebarView}
      onMouseMove={handleMouseMove}
    >
      {participant.local ? (
        <LocalScreenShare onClick={onClick} />
      ) : (
        <VideoElement ref={videoRef} muted autoPlay playsInline radius />
      )}
    </ScreenShareContainer>
  );
};

const LocalScreenShare: React.FC<LocalScreenShareProps> = ({ onClick }) => {
  return (
    <LocalScreenShareContainer>
      <ScreenShareIcon />
      <Unselectable>
        <Heading3 color={colors.whiteMain}>You are sharing your screen!</Heading3>
      </Unselectable>
      <Button onClick={onClick} style={{ marginTop: margins.size4 }}>
        Stop Sharing
      </Button>
    </LocalScreenShareContainer>
  );
};

const getPxSize = (p: ScreenShareContainer) => `${p.size}px`;
interface ScreenShareContainer {
  size: number;
  aspectRatio: number;
  isSidebarVisible: boolean;
}
const ScreenShareContainer = styled.div<ScreenShareContainer>`
  position: relative;
  width: ${getPxSize};
  height: calc(${getPxSize} * ${p => p.aspectRatio});
  transition: ${globalTransitionSettings};
`;

interface LocalScreenShareProps {
  onClick?: () => void;
}

const LocalScreenShareContainer = styled(FlexColumn)`
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${colors.blackMid};
  border-radius: 10px;
`;

const ScreenShareIcon = styled(SVG).attrs({
  src: screenShareIcon,
  'alt-text': 'Screen Share icon',
})``;
