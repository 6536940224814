import React, { useEffect, useState } from 'react';

import { margins, FlexColumn, Heading1, Heading2, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { Modal } from 'js/components/shared/NewModal';
import { useUser } from 'js/providers/UserProvider';
import { postTestActivity } from 'js/util/api';
import { describeWeeklyTimeslots, getIsMobile, INTERNATIONAL_TIME_LOCALES } from 'js/util/util';

import autopilotSvg from 'img/weekly/autopilot.svg';

interface Props {
  showModal: boolean;
  timeslots: string[];
  accept: () => void;
  decline: () => void;
}

export const AutopilotOptInModal: React.FC<Props> = ({ showModal, timeslots, accept, decline }) => {
  const [modalOpen, setModalOpen] = useState(showModal);
  const user = useUser();

  useEffect(() => {
    setModalOpen(showModal);
  }, [showModal]);

  const handleClose = () => {
    const platform = window?.bridge ? 'ios' : getIsMobile() ? 'mobile' : 'desktop';
    postTestActivity('autopilot-all', `${platform}-decline`);
    decline();
    setModalOpen(false);
  };

  const show24hourTime = INTERNATIONAL_TIME_LOCALES.includes(user.locale);

  return (
    <Modal showModal={modalOpen} handleClose={handleClose}>
      <FlexColumn style={{ textAlign: 'center' }}>
        <Heading1 style={{ margin: `${margins.size2} 0px` }}>Lunchclub on autopilot</Heading1>
        <img src={autopilotSvg} alt="" />

        <Heading2 style={{ marginBottom: margins.size2 }}>Leave the work to Lunchclub!</Heading2>
        <Text style={{ marginBottom: margins.size2 }}>
          Lock your {timeslots.length > 1 ? 'times' : 'time'} in and we will send you{' '}
          {timeslots.length > 1 ? 'new matches' : 'a new match'} every week by default.
          <br />
          You can opt-out or change at any time.
        </Text>

        <Text style={{ margin: `${margins.size4} 0px` }}>
          Your weekly {timeslots.length > 1 ? 'times' : 'time'}:{' '}
          <span style={{ fontWeight: 'bold' }}>
            {describeWeeklyTimeslots(timeslots, show24hourTime)}
          </span>
        </Text>

        <Button large onClick={accept} style={{ marginTop: margins.size3 }}>
          Turn on autopilot
        </Button>
      </FlexColumn>
    </Modal>
  );
};
