import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { FlexColumn, media } from 'css/css';

import { MinimalEndorsementCard } from 'js/components/homepage/types';
import { FeedDeck } from 'js/components/homepage/FeedDeck';
import { getIsMobile } from 'js/util/util';

import { Top } from './Top';

interface FeedProps {
  cards: MinimalEndorsementCard[];
  closeModal: () => void;
  deckCompletedCallback: () => void;
}

export const Feed: React.FC<FeedProps> = ({ cards, closeModal, deckCompletedCallback }) => {
  const [openHeader, setOpenHeader] = useState<(continuation?: () => void) => void>();
  const [closeHeader, setCloseHeader] = useState<(continuation?: () => void) => void>();
  const [isDeckCompleted, setIsDeckCompleted] = useState(false);

  const isMobile = getIsMobile();

  return (
    <FeedContainer>
      <Top
        closeModal={closeModal}
        button={!isDeckCompleted ? { kind: 'explanation', openHeader, closeHeader } : undefined}
        header={!isDeckCompleted && isMobile ? 'Endorse your friends!' : ''}
      />
      <FeedBody
        cards={cards}
        deckCompletedCallback={() => {
          setIsDeckCompleted(true);
          deckCompletedCallback();
        }}
        openHeader={openHeader}
        setOpenHeader={setOpenHeader}
        closeHeader={closeHeader}
        setCloseHeader={setCloseHeader}
      />
    </FeedContainer>
  );
};

const FeedContainer = styled(FlexColumn)`
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: env(safe-area-inset-top);
`;

interface FeedBodyProps {
  cards: MinimalEndorsementCard[];
  deckCompletedCallback: () => void;
  openHeader?: (continuation?: () => void) => void;
  setOpenHeader: React.Dispatch<
    React.SetStateAction<((continuation?: (() => void) | undefined) => void) | undefined>
  >;
  closeHeader?: (continuation?: () => void) => void;
  setCloseHeader: React.Dispatch<
    React.SetStateAction<((continuation?: (() => void) | undefined) => void) | undefined>
  >;
}

const FeedBody: React.FC<FeedBodyProps> = ({
  cards,
  deckCompletedCallback,
  openHeader,
  setOpenHeader,
  closeHeader,
  setCloseHeader,
}) => {
  const [completedCardIds, setCompletedCardIds] = useState<string[]>([]);

  return (
    <FeedBodyContainer>
      <FeedDeck
        feed={{ session: 'Endorsement', cards }}
        completedCardIds={new Set(completedCardIds)}
        completeCard={(cardId: string) => setCompletedCardIds([...completedCardIds, cardId])}
        deckCompletedCallback={deckCompletedCallback}
        openHeader={openHeader}
        setOpenHeader={setOpenHeader}
        closeHeader={closeHeader}
        setCloseHeader={setCloseHeader}
      />
    </FeedBodyContainer>
  );
};

const FeedBodyContainer = styled(FlexColumn)`
  width: 100%;
  ${media.mobile} {
    flex-grow: 1;
  }
`;
