import React, { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

import { colors, fonts, fontSizes, FlexRow } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { getSpecialInvite, postTestActivity, postUser } from 'js/util/api';
import {
  COMMUNITY_CODE_TO_ID,
  getParameterByName,
  onEnterKey,
  setElliotToken,
  validateEmail,
} from 'js/util/util';
import { useMountEffect } from 'js/util/custom-hooks';
import { ORGANIZATION, ORG_INVITE_CODE } from 'js/util/organizations';
import { useNotifContext } from 'js/util/notif-context';

interface Props {
  marginTop?: string;
  urlParams?: string;
  isInvalid?: boolean;
}

export const LOGIN_FAILED_MESSAGE =
  'An account with this email already exists, please log in or complete your email verification.';

export const RegisterEmail: React.FC<Props> = ({ marginTop, urlParams, isInvalid }) => {
  const history = useHistory();
  const { showNotif } = useNotifContext();
  const [email, setEmail] = useState('');
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);

  const specialCode = getParameterByName('i');
  const orgId = Number(getParameterByName('orgId')) || ORGANIZATION.LUNCHCLUB;
  const commName: string | null = getParameterByName('commId');

  useMountEffect(() => {
    const handleSpecialInviteParam = async () => {
      const res = await getSpecialInvite(specialCode);
      if (res.ok) {
        const { email: newPrefillEmail, invite_code: inviteCode } = res.getJson;
        history.push(`/?invite_code=${inviteCode}&i=${specialCode}`);
        setEmail(newPrefillEmail);
      }
    };
    if (specialCode) handleSpecialInviteParam();
  });

  const submitEmail = async () => {
    if (!email || !validateEmail(email)) {
      showNotif({
        message: 'Please enter a valid email.',
        level: 'error',
      });
      return;
    }
    if (isSubmittingEmail) return;

    setIsSubmittingEmail(true);
    const res = await postUser({ email, specialCode });
    if (res.status === 200) {
      postTestActivity('landing-tests-email', urlParams || '');
      const { auth_token: newToken, skip_verification: skipVerification } = res.getJson;
      if (newToken) {
        setElliotToken(newToken);
      }

      const inviteCode = getParameterByName('invite_code');
      const commNameAfterInvite = commName || (inviteCode === 'trends' ? 'trends' : null);
      const selectedOrg: number = commNameAfterInvite
        ? COMMUNITY_CODE_TO_ID[commNameAfterInvite]
        : orgId;
      const orgCode = ORG_INVITE_CODE[selectedOrg];
      const inviteCodeStr = inviteCode || orgCode ? `&invite_code=${inviteCode || orgCode}` : '';

      const skipVerificationStr = skipVerification ? '&skip_verification=true' : '';
      history.push(
        `registration/hello?orgId=${selectedOrg}${inviteCodeStr}${skipVerificationStr}&ref=email-signup`,
      );
    }
    if (res.status === 401) {
      showNotif({
        message: LOGIN_FAILED_MESSAGE,
        level: 'error',
      });
    }
    setIsSubmittingEmail(false);
  };

  return (
    <FlexRow style={{ marginTop: marginTop || '24px', width: '100%' }}>
      <EmailInput
        value={email}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        placeholder="Enter your email"
        type="email"
        onKeyPress={(e: any) => onEnterKey(e, submitEmail)}
      />
      <Button
        style={{
          margin: '0px',
          marginLeft: '-1px',
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
          borderTopRightRadius: '6px',
          borderBottomRightRadius: '6px',
          fontFamily: fonts.regular,
          whiteSpace: 'nowrap',
          backgroundColor: colors.primaryMain,
          boxShadow: `0px 0px 0px 1px ${colors.primaryMain} inset`,
        }}
        invalid={isInvalid}
        onClick={submitEmail}
      >
        Get started
      </Button>
    </FlexRow>
  );
};

const EmailInput = styled.input`
  margin-bottom: 0px;
  font-family: ${fonts.regular};
  font-size: ${fontSizes.size3};
  color: ${colors.blackMid};
  font-feature-settings: 'cv11' 1;
  -webkit-font-feature-settings: 'cv11' 1;
  -ms-font-feature-settings: 'cv11' 1;
  -moz-font-feature-settings: 'cv11' 1;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;

  background-color: ${colors.whiteMain};
  box-shadow: 0px 0px 0px 1px ${colors.blackMid} inset;
  -webkit-appearance: none;
  border: none;
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;

  padding: 0px 18px;
  height: 44px;
  width: 100%;

  &::placeholder {
    color: ${colors.blackMid};
  }
`;
