import React, { createContext, useCallback, useContext, useState } from 'react';
import DailyIFrame from '@daily-co/daily-js';
import { useHistory } from 'react-router-dom';

import { ProductUser } from 'types/user';

import { useNetworkState, TUseNetworkState } from 'js/components/callv2/hooks/useNetworkState';
import { useSharedCallContext } from 'js/components/call/SharedCallContext';

import { useCallMachine, TUseCallMachine } from './useCallMachine';

export type VideoQuality = 'auto' | 'high' | 'low' | 'bandwidth-saver';

interface ContextValue {
  // Lunchclub state
  match?: ProductUser;
  matchCode: string;
  feedbackCode?: string;
  startTime?: string;
  hasContext: boolean;
  url: string | undefined;
  leaveCall: (starRatingFromModal?: number) => void;

  // Dailyco state
  daily: TUseCallMachine['daily'];
  networkState: TUseNetworkState;
  setVideoQuality: (q: VideoQuality) => void;
  state: TUseCallMachine['state'];
  videoQuality: VideoQuality;
  allowScreenShare: boolean;
  allowVideoProcessing: boolean;
}

// eslint-disable-next-line no-console
const noop = () => console.error('No parent CallV2ContextProvider found!');

const CallV2Context = createContext<ContextValue>({
  // Lunchclub state
  matchCode: '',
  hasContext: false,
  leaveCall: noop,
  url: undefined,

  // Dailyco state
  daily: null,
  networkState: 'good',
  setVideoQuality: noop,
  state: 'ready',
  videoQuality: 'auto',
  allowScreenShare: false,
  allowVideoProcessing: false,
});

export const CallV2ContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [videoQuality, setVideoQuality] = useState<VideoQuality>('auto');

  const { match, matchCode, feedbackCode, startTime, hasContext, url } = useSharedCallContext();

  // Keeps track of call state
  const { daily, state } = useCallMachine(url);
  const networkState = useNetworkState({ co: daily, quality: videoQuality });
  const allowScreenShare = DailyIFrame.supportedBrowser().supportsScreenShare;
  const allowVideoProcessing = DailyIFrame.supportedBrowser().supportsVideoProcessing;

  const leaveCall = useCallback(
    (starRatingFromModal?: number) => {
      if (!daily) return;
      // If we're in the error state, we've already "left", so just clean up
      daily.destroy();
      if (feedbackCode) {
        const ratingParam =
          starRatingFromModal !== undefined ? `&rating=${starRatingFromModal}` : '';
        history.push(`/feedback?feedback_code=${feedbackCode}${ratingParam}&ref=call`);
      } else history.push('/home');
    },
    [daily, state, feedbackCode],
  );

  return (
    <CallV2Context.Provider
      value={{
        // Lunchclub state
        match,
        matchCode,
        feedbackCode,
        startTime,
        hasContext,
        url,

        // Dailyco state
        daily,
        leaveCall,
        networkState,
        setVideoQuality,
        state,
        videoQuality,
        allowScreenShare,
        allowVideoProcessing,
      }}
    >
      {children}
    </CallV2Context.Provider>
  );
};

export const useCallV2Context = () => useContext(CallV2Context);
