import React, { useEffect } from 'react';
import styled, { CSSProperties } from 'styled-components/macro';

import { BUTTON_HEIGHT, colors, fonts, margins } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { StringLengthIndicator } from 'js/components/shared/StringLengthIndicator';

import { xSvg } from 'img/weekly-v2';

import { getIsMobile } from './util';

export type MobileFocusBehavior = 'always' | 'if-under-keyboard' | 'never';

const ANIM_DURATION = 250;

export function useMobileFocusingBehavior(
  ref: React.RefObject<HTMLInputElement | HTMLTextAreaElement>,
  opts: {
    behavior: MobileFocusBehavior;
    label: string;
    isTextArea: boolean;
  },
  buttonText = 'Save',
  characterCount?: { headline: string; min: number; max: number },
  buttonOnClick?: () => void,
  isButtonInvalid?: boolean,
) {
  const [finalFloat, setFinalFloat] = React.useState(false);
  const [initialFloat, setInitialFloat] = React.useState<null | {
    width: number;
    height: number;
    left: number;
    top: number;
  }>(null);

  const safeAreaInsetTop = parseFloat(
    getComputedStyle(document.body)
      .getPropertyValue('--safeAreaInsetTop')
      .replace('px', '')
      .trim(),
  );

  const baseStyles: CSSProperties = {
    boxSizing: 'border-box',
    transition: `top ${ANIM_DURATION}ms ease-out, left ${ANIM_DURATION}ms ease-out, background ${ANIM_DURATION}ms ease-out`,
  };

  if (!opts.isTextArea) {
    baseStyles.transition += `, width ${ANIM_DURATION}ms ease-out, height ${ANIM_DURATION}ms ease-out`;
  }

  const styles: CSSProperties =
    initialFloat && finalFloat
      ? {
          position: 'fixed',
          zIndex: 100,
          padding: opts.isTextArea ? 0 : undefined,
          top: (opts.label.length > 0 ? 100 : 60) + safeAreaInsetTop,
          left: 20,
          maxWidth: window.innerWidth - 40,
          width: window.innerWidth - 40,
          height: opts.isTextArea ? Math.max(200, initialFloat.height) : initialFloat.height,
          background: colors.whiteMain,
        }
      : initialFloat
      ? { position: 'fixed', zIndex: 100, ...initialFloat }
      : { position: 'relative' };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (finalFloat === false) {
      timeout = setTimeout(() => setInitialFloat(null), ANIM_DURATION);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [finalFloat]);

  return {
    background: initialFloat && (
      <InputFloatBackground final={finalFloat} onMouseDown={e => e.preventDefault()}>
        <InputFloatX
          onClick={e => {
            if (ref.current) ref.current.blur();
            e.preventDefault();
          }}
          role="button"
          tabIndex={0}
        >
          <img src={xSvg} alt="x" />
        </InputFloatX>
        <InputFloatLabel>{opts.label}</InputFloatLabel>
        {characterCount && (
          <CharacterCountIndicator>
            <StringLengthIndicator
              string={characterCount.headline}
              minLength={characterCount.min}
              maxLength={characterCount.max}
            />
          </CharacterCountIndicator>
        )}
        <InputFloatSaveButton
          invalid={isButtonInvalid}
          large
          onClick={() => {
            if (buttonOnClick) {
              buttonOnClick();
            } else if (ref.current) {
              ref.current.blur();
            }
          }}
        >
          {buttonText}
        </InputFloatSaveButton>
      </InputFloatBackground>
    ),
    styles: { ...baseStyles, ...styles },
    final: finalFloat,
    onBlur: () => {
      setFinalFloat(false);
      if (window.bridge && opts.behavior === 'always') {
        window.bridge.setShrinkViewportForKeyboard(false);
      }
    },
    onFocus: (e: React.FocusEvent<any>) => {
      if (!e.target || !getIsMobile()) {
        return;
      }
      if (window.bridge && opts.behavior === 'always') {
        window.bridge.setShrinkViewportForKeyboard(true);
      }
      const pos = e.target.getBoundingClientRect();
      if (
        opts.behavior === 'always' ||
        (opts.behavior === 'if-under-keyboard' && pos.top + pos.height > window.innerHeight - 350)
      ) {
        setInitialFloat({
          top: pos.top,
          width: pos.width,
          height: pos.height,
          left: pos.left,
        });
        window.requestAnimationFrame(() => window.requestAnimationFrame(() => setFinalFloat(true)));
      }
    },
  };
}

const InputFloatBackground = styled.div<{ final: boolean }>`
  z-index: 99;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  opacity: ${p => (p.final ? 1 : 0)};
  transition: opacity ${ANIM_DURATION}ms ease-out;
`;

const InputFloatLabel = styled.div`
  position: absolute;
  top: env(safe-area-inset-top);
  font-family: ${fonts.extraBold};
  font-size: 18px;
  left: 20px;
  margin-top: 35px;
  padding-right: 55px;
  height: 50px;
  display: flex;
  flex-direction: column;
  vertical-align: bottom;
  pointer-events: none;
  justify-content: flex-end;
`;

const InputFloatX = styled.div`
  padding: 15px;
  margin-top: 5px;
  position: absolute;
  top: env(safe-area-inset-top);
  right: 5px;
`;

const InputFloatSaveButton = styled(Button)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  margin: ${margins.size2};
  margin-bottom: calc(max(${margins.size2}, env(safe-area-inset-bottom)));
`;

const CharacterCountIndicator = styled.div`
  position: fixed;
  bottom: calc(
    ${BUTTON_HEIGHT} + calc(max(${margins.size2}, env(safe-area-inset-bottom))) + ${margins.size2}
  );
  left: 0;
  margin: ${margins.size2};
  margin-bottom: calc(max(${margins.size2}, env(safe-area-inset-bottom)));
`;
