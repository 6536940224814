import React from 'react';
import styled from 'styled-components/macro';

import { colors, margins, media, Card, Heading1, Heading2 } from 'css/css';

import { GreyPage } from 'js/components/shared/page-wrappers';

import { ExplanationText } from './style';
import { SpendOptions, EarnOptions } from './options';

export const Clubpoints: React.FC = () => (
  <GreyPage>
    <PageContainer>
      <InfoContainer>
        <Content>
          <Heading1 style={{ marginBottom: margins.size5, textAlign: 'center' }}>
            Use Clubpoints to supercharge your experience!
          </Heading1>

          <SectionHeading>What are Clubpoints?</SectionHeading>

          <ExplanationText>
            Clubpoints are a type of currency on Lunchclub meant to reward our most active and
            engaged Lunchclubbers.
          </ExplanationText>

          <SectionHeading>How do I spend Clubpoints?</SectionHeading>

          <ExplanationText>
            Whenever you sign up for a weekly meeting, you will be able to spend Clubpoints. You can
            use your Clubpoints to:
          </ExplanationText>

          <SpendOptions />

          <LineBreak />

          <SectionHeading>How do I earn Clubpoints?</SectionHeading>

          <ExplanationText>
            You can earn more Clubpoints by inviting your friends and making connections! Here’s a
            breakdown:
          </ExplanationText>

          <EarnOptions />
        </Content>
      </InfoContainer>
    </PageContainer>
  </GreyPage>
);

const PageContainer = styled.div`
  margin: 0 auto;
  text-align: left;
  max-width: 600px;
`;

const InfoContainer = styled(Card)`
  margin: 0 auto;
  ${media.mobile} {
    margin-top: 0px;
  }
`;

const Content = styled.div`
  margin: ${margins.size5};
  ${media.mobile} {
    margin: ${margins.size4};
  }
`;

const SectionHeading = styled(Heading2)`
  margin-top: ${margins.size4};
  ${media.mobile} {
    margin-top: ${margins.size4};
  }
`;

const LineBreak = styled.div`
  border: 0px;
  height: 1px;
  background-color: ${colors.primaryLight};
`;
