import React, { useState } from 'react';
import styled from 'styled-components/macro';
import omit from 'lodash/omit';

import { Card, colors, Heading2, Heading3, margins, media } from 'css/css';

import { PageFilters } from 'js/components/shared/PageFilters';
import { GreyPage } from 'js/components/shared/page-wrappers';
import { useMountEffect } from 'js/util/custom-hooks';
import { getIsMobile, getParameterByName } from 'js/util/util';
import { useTestBuckets, useUser, useUserAvailability } from 'js/providers/UserProvider';

import { MobilePageHeader } from '../shared/page-wrappers';

import { SETTINGS_PAGES } from './constants';
import { Account, Availabilities, Communities, Goals, Notifications } from './pages';
import { PauseOrDelete } from './pages/PauseOrDelete';
import { NotificationsMobile } from './pages/NotificationsMobile';

export const Settings = () => {
  const [selectedFilter, setSelectedFilter] = useState(SETTINGS_PAGES.ACCOUNT.id);
  const { cohesiveAutopilotTest } = useTestBuckets();
  const user = useUser();
  const userAvailability = useUserAvailability();

  const shouldShowAvailability =
    !!cohesiveAutopilotTest &&
    !!(
      user.visual_settings?.has_completed_autopilot_flow ||
      userAvailability?.availability_autopilot?.autopilot ||
      user.autopilot_paused_until ||
      userAvailability?.coaching_plan_details?.on_coaching_plan
    );

  useMountEffect(() => {
    const tab = getParameterByName('tab');
    if (tab) {
      setTimeout(() => {
        const elementToScrollTo = document.getElementById(tab);
        if (elementToScrollTo) {
          elementToScrollTo.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
      // Note: Switching to communities on mobile will lead to weird results
      // but is not currently an entrypoint.
      const tabToSwitch = SETTINGS_PAGES[tab.toLocaleUpperCase()] || SETTINGS_PAGES.ACCOUNT;
      setSelectedFilter(tabToSwitch.id);
    }
  });

  if (getIsMobile()) {
    return (
      <GreyPage fixedFullHeight>
        <MobilePageHeader>Settings</MobilePageHeader>
        <div style={{ textAlign: 'left' }}>
          <Heading3 id="account">Account</Heading3>
          <WhiteSectionBox>
            <Account />
          </WhiteSectionBox>
          {shouldShowAvailability && (
            <>
              <Heading3 id="availabilities">Availabilities</Heading3>
              <WhiteSectionBox>
                <Availabilities />
              </WhiteSectionBox>
            </>
          )}
          <Heading3 id="notifications">Notifications</Heading3>
          <WhiteSectionBox>
            <NotificationsMobile />
          </WhiteSectionBox>
          <Heading3 id="goals">Goals</Heading3>
          <WhiteSectionBox>
            <Goals />
          </WhiteSectionBox>
          <Heading3 id="communities">Communities</Heading3>
          <WhiteSectionBox>
            <Communities />
          </WhiteSectionBox>
          <WhiteSectionBox style={{ padding: cohesiveAutopilotTest ? 0 : margins.size2 }}>
            <PauseOrDelete />
          </WhiteSectionBox>
        </div>
      </GreyPage>
    );
  }

  return (
    <GreyPage>
      <FixedBackgroundBlocker />
      <PageFilters
        filters={
          getIsMobile()
            ? omit(SETTINGS_PAGES, 'COMMUNITIES')
            : shouldShowAvailability
            ? SETTINGS_PAGES
            : omit(SETTINGS_PAGES, 'AVAILABILITIES')
        }
        selectedFilter={selectedFilter}
        changeFilter={setSelectedFilter}
      />
      <MenuContainer>
        {(() => {
          switch (selectedFilter) {
            case SETTINGS_PAGES.GOALS.id:
              return <Goals />;
            case SETTINGS_PAGES.COMMUNITIES.id:
              return <Communities />;
            case SETTINGS_PAGES.NOTIFICATIONS.id:
              return <Notifications />;
            case SETTINGS_PAGES.AVAILABILITIES.id:
              return <Availabilities />;
            default:
              return <Account />;
          }
        })()}
      </MenuContainer>
    </GreyPage>
  );
};

const FixedBackgroundBlocker = styled.div`
  background-color: ${colors.greyLight};
  position: fixed;
  transform: translateX(-50%);
  left: 50%;
  width: 100%;
  z-index: 1;
  height: 24px;
  margin-top: -${margins.size4};
  ${media.desktop} {
    height: 0px;
  }
  ${media.tablet} {
    height: 52px;
  }
  ${media.mobile} {
    display: none;
  }
`;
const MenuContainer = styled(Card)`
  width: 100%;
  max-width: 586px;
  text-align: left;
  padding: ${margins.size5};

  ${media.tablet} {
    margin-top: 28px;
  }

  ${media.mobile} {
    border: none;
    margin-top: calc(env(safe-area-inset-top) + 56px);
    padding: ${margins.size3};
    padding-bottom: ${margins.size7};
  }
`;

const WhiteSectionBox = styled.div`
  background: ${colors.whiteMain};
  padding: ${margins.size4};
  border-radius: 12px;
  margin-top: ${margins.size2};
  margin-bottom: ${margins.size3};

  ${Heading2} {
    font-size: 16px;
  }
`;
