import { Invitee } from 'types/invites';

const filterNullNames = (inviteeList: Invitee[]) =>
  inviteeList.filter(invitee => invitee.first_name !== null && invitee.last_name !== null);

export const getInviteSuggestionsCache = (
  setInviteSuggestions: (inviteeList: Invitee[], note?: string) => void,
) => {
  try {
    if (window?.localStorage) {
      const inviteSuggestionsFromCacheAsString = window.localStorage.getItem(INVITE_SUGGESTIONS);
      if (inviteSuggestionsFromCacheAsString != null) {
        setInviteSuggestions(
          filterNullNames(JSON.parse(inviteSuggestionsFromCacheAsString) as Invitee[]),
        );
        return true;
      }
    }
  } catch (err) {
    return false;
  }
  return false;
};
export const setInviteSuggestionsCache = (inviteSuggestionsToCache: Invitee[]) => {
  try {
    if (window && 'localStorage' in window) {
      window.localStorage.setItem(INVITE_SUGGESTIONS, JSON.stringify(inviteSuggestionsToCache));
    }
  } catch (err) {
    // continue despite error
  }
};

const INVITE_SUGGESTIONS = 'INVITE_SUGGESTIONS';
export const MAX_DISPLAYED_INVITE_SUGGESTIONS = 8;
