import React from 'react';
import styled from 'styled-components/macro';

import {
  colors,
  margins,
  media,
  FlexColumn,
  Heading2,
  globalTransitionSettings,
  BUTTON_HEIGHT,
  Clickable,
} from 'css/css';

interface Props {
  filters: { [name: string]: { id: number; text: string } };
  selectedFilter: number;
  changeFilter: (id: number) => void;
}

export const PageFilters: React.FC<Props> = ({ filters, selectedFilter, changeFilter }) => {
  return (
    <TabColumn alignItems="left">
      {Object.values(filters).map(filter => (
        <TabWrapper key={filter.id} selected={selectedFilter === filter.id}>
          <NoOutlineClickable onClick={() => changeFilter(filter.id)}>
            <TabHeader selected={selectedFilter === filter.id}>{filter.text}</TabHeader>
          </NoOutlineClickable>
        </TabWrapper>
      ))}
    </TabColumn>
  );
};

const TabColumn = styled(FlexColumn)`
  position: fixed;
  margin-left: 10px;
  margin-right: ${margins.size4};
  max-width: 600px;
  z-index: 1;
  @media screen and (max-width: 1200px) {
    flex-direction: row;
    justify-content: flex-start;
    padding: 0px;
    margin-top: -${margins.size3};
    transform: translateX(-50%);
    left: 50%;
    width: inherit;
  }
  ${media.mobile} {
    top: 0;
    margin-top: 0;
    justify-content: space-between;
    background: ${colors.greyLight};
    padding: calc(env(safe-area-inset-top) + ${margins.size2}) ${margins.size3} 0;
    font-size: 13px;
  }
`;

const NoOutlineClickable = styled(Clickable)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${BUTTON_HEIGHT};
  border-radius: 5px;
  transition: ${globalTransitionSettings};
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${colors.primaryLight};
    & > p {
      color: ${colors.primaryMain};
    }
  }

  @media screen and (max-width: 1200px) {
    &:hover {
      background-color: unset;
    }
  }
`;

const TabWrapper = styled.div<{ selected: boolean }>`
  text-align: left;
  margin-bottom: ${margins.size2};
  margin-right: ${margins.size3};
  transition: ${globalTransitionSettings};
  ${media.mobile} {
    margin-bottom: 0px;
    margin-right: 0px;
    text-align: center;
    border-bottom: ${p => (p.selected ? `3px solid ${colors.primaryMain}` : 'none')};
  }
`;

type TabHeaderTypes = { selected: boolean };
const TabHeader = styled(Heading2)<TabHeaderTypes>`
  color: ${p => (p.selected ? colors.primaryMain : colors.blackLight)};
  padding: ${margins.size1} ${margins.size2};
  transition: ${globalTransitionSettings};
  @media screen and (max-width: 1200px) {
    background-color: ${colors.greyLight};
    padding: 0px;
  }
  ${media.mobile} {
    background-color: transparent;
  }
`;
