import Bowser from 'bowser';

export enum Browser {
  EDGE = 'Microsoft Edge',
  CHROME = 'Chrome',
  FIREFOX = 'Firefox',
  OPERA = 'Opera',
  SAFARI = 'Safari',
}

export const isAndroidChrome = (browserName?: string) => {
  const browser = Bowser.parse(navigator.userAgent);
  const name = browserName ?? browser?.browser?.name;
  return (
    name === Browser.CHROME &&
    browser?.platform?.type === 'mobile' &&
    browser?.os?.name === 'Android'
  );
};

export const isAndroidMobile = () => {
  const browser = Bowser.parse(navigator.userAgent);
  return browser?.os?.name === 'Android' && browser?.platform?.type === 'mobile';
};

export const isIOSMobile = () => {
  const browser = Bowser.parse(navigator.userAgent);
  return browser?.os?.name === 'iOS' && browser?.platform?.type === 'mobile';
};

export const isSafari = () => {
  const browser = Bowser.parse(navigator.userAgent);
  return browser?.browser?.name === Browser.SAFARI;
};

export const isMacOS = () => {
  const browser = Bowser.parse(navigator.userAgent);
  return browser?.os?.name === 'macOS';
};
