import React, { CSSProperties, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Transition, TransitionStatus } from 'react-transition-group';
import styled from 'styled-components/macro';

import { colors, fonts, globalTransitionSettings } from 'css/css';

import { useUserContextProvider } from 'js/providers/UserProvider';
import { createOrActivateToken, postTestActivity, postUser, postUserLogin } from 'js/util/api';
import {
  addParameterToURL,
  getElliotToken,
  getParameterByName,
  setElliotToken,
} from 'js/util/util';
import { useNotifContext } from 'js/util/notif-context';
import { useResource } from 'js/util/use-resource';
import { useMountEffect } from 'js/util/custom-hooks';

import { TextInput } from '../shared/TextInput';

import { LandingPage } from './LandingPage';
import { EnterCode } from './EnterCode';
import { EnterEmail } from './EnterEmail';

export const TRANSITION_DURATION = 300;
export type pageState = 'landing' | 'logging-in' | 'registering';

export const transitionSettings: Record<TransitionStatus, CSSProperties> = {
  unmounted: {},
  entering: { transform: 'translateX(0)' },
  entered: { transform: 'translateX(0)' },
  exiting: { transform: 'translateX(100%)' },
  exited: { transform: 'translateX(100%)' },
};

export type UserState =
  | 'landing'
  | 'register-email'
  | 'email-loading'
  | 'login-email'
  | 'login-code'
  | 'code-loading';

export const MobileRoot: React.FC<RouteComponentProps> = ({ history }) => {
  const { fetchUser, updateUserAvailability } = useUserContextProvider();
  const { showNotif } = useNotifContext();
  const [email, setEmail] = useState('');
  const [userState, setUserState] = useState<UserState>('landing');
  const [tempToken, setTempToken] = useState('');
  const [code, setCode] = useState('');
  const [swappedToken, setSwappedToken] = useState(false);
  const [deferredDeepLink] = useResource<{ params: string }>('discover/mobile/deferred_deep_link');

  const isUserLoggingIn = ['email-loading', 'login-email'].includes(userState);
  const isUserRegistering = ['email-loading', 'register-email'].includes(userState);

  useEffect(() => {
    if (!deferredDeepLink) return;

    const deepLinkInvite = getParameterByName('invite_code', deferredDeepLink.params);
    const currentInvite = getParameterByName('invite_code');
    if (deepLinkInvite && !currentInvite) {
      const newLink = addParameterToURL('invite_code', deepLinkInvite);
      const newLinkNoHost = newLink.replace(window.location.origin, '');
      history.push(newLinkNoHost);
    }

    const deepLinkSpecialInvite = getParameterByName('i', deferredDeepLink.params);
    const currentSpecialParams = getParameterByName('i');
    if (deepLinkInvite && !currentSpecialParams) {
      const newLink = addParameterToURL('i', deepLinkSpecialInvite);
      const newLinkNoHost = newLink.replace(window.location.origin, '');
      history.push(newLinkNoHost);
    }
  }, [deferredDeepLink]);

  useMountEffect(() => {
    if (getElliotToken() && !history.location.search.includes('session-invalid')) {
      history.push('/login');
    }
  });

  useEffect(() => {
    if (swappedToken) {
      Promise.all([fetchUser(), updateUserAvailability()]).then(() => history.push('/login'));
    }
  }, [swappedToken]);

  const onSubmit = async () => {
    if (userState === 'email-loading') return;
    if (!email) return;

    setUserState('email-loading');

    if (isUserLoggingIn) {
      const res = await postUserLogin({
        email,
        isFromApp: !!window.bridge,
      });
      if (res.ok) {
        setUserState('login-code');
        setTempToken(res.getJson.token);
      } else if (res.status === 403) {
        showNotif({
          message: 'We couldn’t find an account associated with that email!',
          level: 'error',
        });
        setUserState('login-email');
      } else {
        showNotif({
          message: 'Unknown error occurred, please try again.',
          level: 'error',
        });
        setUserState('login-email');
      }
    } else if (isUserRegistering) {
      const res = await postUser({ email, specialCode: '' });
      if (res.status === 200) {
        postTestActivity('landing-tests-email', '');
        const { auth_token } = res.getJson;
        if (auth_token) {
          setElliotToken(auth_token);
        }
        history.push(`/mobile-root/notifications`);
      } else if (res.status === 401) {
        showNotif({
          message: 'We found an account associated with that email! Login instead!',
          level: 'error',
        });
        setUserState('login-email');
      } else {
        showNotif({
          message: 'Unknown error occurred, please try again.',
          level: 'error',
        });
        setUserState('register-email');
      }
    }
  };

  const validateCode = async () => {
    if (!code || code.length !== 6) return;
    if (userState === 'code-loading') return;

    setUserState('code-loading');
    const res = await createOrActivateToken(tempToken, true, code);
    if (res.ok) {
      setElliotToken(res.getJson.auth_token);
      setSwappedToken(true);
    } else {
      showNotif({ message: 'Invalid code', level: 'error' });
      setUserState('login-code');
    }
  };

  return (
    <>
      <LandingPage setUserState={setUserState} />
      <Transition
        appear
        in={['register-email', 'email-loading', 'login-email'].includes(userState)}
        timeout={{
          appear: TRANSITION_DURATION,
          enter: TRANSITION_DURATION,
          exit: 0,
        }}
        unmountOnExit
      >
        {state => (
          <EnterEmail
            email={email}
            setEmail={setEmail}
            userState={userState}
            setUserState={setUserState}
            isUserLoggingIn={isUserLoggingIn}
            onSubmit={onSubmit}
            transitionStyles={transitionSettings[state]}
          />
        )}
      </Transition>
      {['login-code', 'code-loading'].includes(userState) && (
        <EnterCode code={code} setCode={setCode} userState={userState} onSubmit={validateCode} />
      )}
    </>
  );
};

export const MobileRootContainer = styled.div<{ color: string }>`
  background-color: ${p => p.color};
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  user-select: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 300ms ease-in-out;
  overflow: hidden;
  transform: translateX(0%);
`;

export const GrayEmailInput = styled(TextInput)`
  border-radius: 10px;
  background: ${colors.greyLight};
  font-family: ${fonts.semiBold};
  text-align: center;
  height: 44px;
  font-size: 16px;
  transition: ${globalTransitionSettings};
  &::placeholder {
    text-align: center;
  }
  &:focus {
    text-align: left;
    &::placeholder {
      text-align: left;
    }
  }
`;
