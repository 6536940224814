import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment-timezone';

import { colors, fonts, margins, FlexRow, Text, FlexColumn, media, BUTTON_HEIGHT } from 'css/css';

import { DialogBox } from 'js/components/shared/DialogBox';
import { useUser } from 'js/providers/UserProvider';

import clippy from 'img/weekly/clippy.svg';

import { AUTOPILOT_TESTS } from '.';

interface Props {
  shortWeekly: boolean;
  weekString: string | number;
  autopilotVersion: number | undefined;
  onCoachingPlan?: boolean;
}

export const TopBanner: React.FC<Props> = ({
  shortWeekly,
  weekString,
  autopilotVersion,
  onCoachingPlan,
}) => {
  const user = useUser();

  const dayOfTheWeek = moment()
    .tz(user.locale_info.timezone)
    .format('dddd');
  const { streak: userStreak } = user;

  const getClippyText = () => {
    if (onCoachingPlan) {
      return ['Welcome back to Curated Matches!', ''];
    }
    if (autopilotVersion === AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX) {
      return [
        'Welcome to Lunchclub!',
        'Our members all meet someone new every week. Pick your weekly time below!',
      ];
    }
    if (shortWeekly) {
      return ['Welcome to Lunchclub!', `Sign up for your first match ${weekString} week.`];
    }
    if (userStreak) {
      return [
        'Keep up your streak!',
        `Sign up for
${userStreak + 1} weeks in a row.`,
      ];
    }
    return [
      `Happy ${dayOfTheWeek}!`,
      `Excited to introduce you to someone new ${weekString} week.`,
    ];
  };

  return (
    <ClippyContainer>
      <FlexRow alignItems="flex-start">
        <ClippyImg src={clippy} />
        <FlexColumn alignItems="flex-start">
          <DialogBox>
            <Dialog>
              <Title>{getClippyText()[0]}</Title>
              <ClippySubText style={{ marginLeft: margins.size1 }}>
                {getClippyText()[1]}
              </ClippySubText>
            </Dialog>
          </DialogBox>
        </FlexColumn>
      </FlexRow>
    </ClippyContainer>
  );
};

const ClippyContainer = styled(FlexRow)`
  margin-bottom: ${margins.size2};
  justify-content: space-between;
  min-height: ${BUTTON_HEIGHT};
`;

const ClippyImg = styled.img`
  padding: ${margins.size2} 0;
  margin-right: ${margins.size1};
`;

const Dialog = styled(Text)`
  text-align: left;
  margin-left: ${margins.size2};
  width: 100%;
`;

const Title = styled.span`
  color: ${colors.blackMain};
  margin-right: 2px;
  font-family: ${fonts.semiBold};
`;

const ClippySubText = styled.span`
  ${media.mobile} {
    display: 'none';
  }
`;
