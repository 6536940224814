import React from 'react';
import styled from 'styled-components/macro';

import { Text, FlexRow, colors, FlexColumn, margins, SubText, Link } from 'css/css';

import { Endorsement } from 'types/endorsements';

import { LC_BOT_PUBLIC_ID } from 'js/util/util';

import { Avatar } from '../shared/Avatar';

export const ProfileEndorsements: React.FC<{ endorsements: Endorsement[] }> = ({
  endorsements,
}) => {
  if (endorsements.length === 0) return null;
  const sortedEndorsements = [...endorsements].sort(
    (a, b) => b.num_endorsements - a.num_endorsements, // decreasing order
  );

  return (
    <ProfileEndorsementsContainer>
      {sortedEndorsements.map(endorsement => (
        <EndorsementLine endorsement={endorsement} />
      ))}
    </ProfileEndorsementsContainer>
  );
};

const ProfileEndorsementsContainer = styled(FlexColumn)`
  padding: ${margins.size3};
  gap: ${margins.size3};
  border: 1px solid ${colors.greyLight};
  align-items: flex-start;
  border-radius: 10px;
`;

const EndorsementLine = ({ endorsement }: { endorsement: Endorsement }) => {
  const { featured_endorser: featuredEndorser, num_endorsements: numEndorsements } = endorsement;
  const featuredEndorserName =
    featuredEndorser.public_id === LC_BOT_PUBLIC_ID
      ? 'Lunchclub'
      : featuredEndorser.first_name +
        (featuredEndorser.last_name.length > 0 ? ' ' : '') +
        featuredEndorser.last_name;

  return (
    <FlexColumn style={{ alignItems: 'flex-start' }}>
      <EndorsementCategory>{endorsement.category}</EndorsementCategory>
      <FlexRow justifyContent="flex-start">
        <Link to={`/member/${featuredEndorser.public_id}`} style={{ marginRight: margins.size2 }}>
          <Avatar src={featuredEndorser.image} avatarId={featuredEndorser.public_id} size1 />
        </Link>
        <SubText>
          {featuredEndorserName}
          {numEndorsements > 1 && ` and ${numEndorsements - 1} other`}
          {numEndorsements > 2 && 's'}
        </SubText>
      </FlexRow>
    </FlexColumn>
  );
};

const EndorsementCategory = styled(Text)`
  &:first-letter {
    text-transform: uppercase;
  }
  margin-bottom: ${margins.size1};
`;
