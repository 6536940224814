import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { colors, FlexColumn, FlexRow, Heading1, Heading3, margins, SubText, Text } from 'css/css';

import { CoachingPlanEligibilityResult, postAcceptCoachingPlan } from 'js/api/coaching-plan';
import { OnboardingFlowPage } from 'js/components/registration/mobile/OnboardingFlowPage';
import { ProgressBar } from 'js/components/registration/mobile/ProgressBar';
import {
  getNuxAutopilotHeaderSteps,
  OnboardingSteps,
  StepMobileContainer,
} from 'js/components/registration/mobile/shared';
import { Button } from 'js/components/shared/Button';
import { BasicPage } from 'js/components/shared/page-wrappers';
import { useDataContext } from 'js/providers/DataContextProvider';
import { useTestBuckets, useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { postAvailability, postTestActivity } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';
import { useNotifContext } from 'js/util/notif-context';
import { apiFailure } from 'js/util/strings';
import { useResource } from 'js/util/use-resource';
import { addParameterToURL, getIsMobile, getPlatform, getTimezoneAbbr } from 'js/util/util';

import { SmallArrow } from '../shared/SmallArrow';
import { CoachingPlanAvatars } from '../coaching_plan/CoachingPlanAvatars';
import { CoachingPlanInfo } from '../coaching_plan/CoachingPlanInfo';
import { CoachingPlanSplash } from '../coaching_plan/CoachingPlanSplash';
import { SVGLoader } from '../shared/loaders/SVGLoader';

import { TimesSelector } from './components/TimesSelector';
import { Confirmation } from './Confirmation';
import { AutopilotSteps, DEFAULT_CADENCE, getHeader, getSubheader } from './constants';

export const Autopilot: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(AutopilotSteps.CHECK_COACHING_PLAN_ELIGIBILITY);
  const [selectedTimeslots, setSelectedTimeslots] = useState<string[]>([]);
  const [signedUpCoachingPlanType, setSignedUpCoachingPlanType] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasSeenThisFlow, setHasSeenThisFlow] = useState(false);
  const { cohesiveAutopilotTest } = useTestBuckets();
  const [coachingPlanEligibility] = useResource<CoachingPlanEligibilityResult>(
    'discover/coaching_plan/eligible',
  );
  const { fetchUser, updateUserAvailability, updateUser } = useUserContextProvider();
  const { allLocales } = useDataContext();
  const { showNotif } = useNotifContext();

  const history = useHistory();
  const user = useUser();
  const userTimezone = getTimezoneAbbr(user.locale_info.timezone);
  const nuxHeaderSteps = getNuxAutopilotHeaderSteps();

  const isMobile = getIsMobile();
  const platform = window?.bridge ? 'ios-app' : isMobile ? 'mobile-web' : 'desktop';

  useMountEffect(() => {
    window.hj('trigger', 'cohesive');
    // We want to show this flow to the user only once
    setHasSeenThisFlow(
      user.visual_settings && 'has_completed_autopilot_flow' in user.visual_settings,
    );
  });

  useEffect(() => {
    if (currentStep === AutopilotSteps.CHECK_COACHING_PLAN_ELIGIBILITY && coachingPlanEligibility) {
      if (coachingPlanEligibility.eligible) {
        postTestActivity(
          `coaching-plan-${coachingPlanEligibility.type || 0}`,
          `${getPlatform()}-view`,
        );
        setCurrentStep(AutopilotSteps.COACHING_PLAN);
      } else {
        setCurrentStep(AutopilotSteps.TIMESLOTS);
      }
    }
  }, [coachingPlanEligibility, currentStep]);

  if (hasSeenThisFlow || !cohesiveAutopilotTest) {
    history.push('/home');
    return null;
  }

  const onNext = () => {
    setCurrentStep(step => {
      if (step === AutopilotSteps.COACHING_PLAN) {
        return signedUpCoachingPlanType
          ? AutopilotSteps.COACHING_PLAN_SPLASH
          : AutopilotSteps.TIMESLOTS;
      }
      if (step === AutopilotSteps.COACHING_PLAN_SPLASH) {
        return AutopilotSteps.TIMESLOTS;
      }
      if (step === AutopilotSteps.TIMESLOTS && selectedTimeslots.length) {
        submitAutopilot();
        return AutopilotSteps.CONFIRMATION;
      }
      if (step === AutopilotSteps.CONFIRMATION || step === AutopilotSteps.SETTINGS) {
        history.push(addParameterToURL('tab', 'availabilities', '/settings'));
        return step;
      }
      updateUser({
        visualSettings: {
          has_completed_autopilot_flow: false,
        },
      });
      history.push('/home');
      return step;
    });
  };

  const clickCoachingPlanSignup = () => {
    postTestActivity(
      `coaching-plan-${coachingPlanEligibility?.type || 0}`,
      `${getPlatform()}-accept`,
    );
    setSignedUpCoachingPlanType(coachingPlanEligibility?.type);
    // Don't use onNext, joinedCoachingPlan state might not be visible!
    setCurrentStep(AutopilotSteps.COACHING_PLAN_SPLASH);
    setTimeout(() => {
      onNext();
    }, 3000);
  };

  const clickCoachingPlanSkip = () => {
    postTestActivity(
      `coaching-plan-${coachingPlanEligibility?.type || 0}`,
      `${getPlatform()}-decline`,
    );
    onNext();
  };

  const getNextLabel = () => {
    if (currentStep === AutopilotSteps.TIMESLOTS) {
      return selectedTimeslots.length ? (isMobile ? 'Next' : 'Save my times') : 'Skip for now';
    }
    return 'Finish';
  };

  const getIsButtonSecondary = () => {
    if (currentStep === AutopilotSteps.TIMESLOTS) {
      return !selectedTimeslots.length;
    }
    return false;
  };

  const submitAutopilot = async () => {
    if (!selectedTimeslots.length) {
      return;
    }
    setIsLoading(true);
    postTestActivity('new-user-autopilot', `${platform}-accept`);

    const videoNeighborhood = allLocales.find(n => n.id === user.locale)?.videoNeighborhood;

    const res = await postAvailability({
      locale: user.locale,
      timeslots: selectedTimeslots,
      neighborhoods: [videoNeighborhood || 0],
      numberOfMeetings: selectedTimeslots.length,
      autopilotCadence: DEFAULT_CADENCE.value,
      autopilot: true,
    });
    if (res.ok) {
      if (signedUpCoachingPlanType) {
        await postAcceptCoachingPlan(signedUpCoachingPlanType);
      }
      await Promise.all([fetchUser(), updateUserAvailability()]);
      updateUser({
        visualSettings: {
          has_completed_autopilot_flow: true,
        },
      });
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
  };

  if (currentStep === AutopilotSteps.CHECK_COACHING_PLAN_ELIGIBILITY) {
    return <SVGLoader />;
  }

  if (currentStep === AutopilotSteps.CONFIRMATION || currentStep === AutopilotSteps.SETTINGS) {
    return (
      <Confirmation
        selectedTimeslots={selectedTimeslots}
        buttonOnClick={onNext}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        buttonText="Show me where"
      />
    );
  }

  if (currentStep === AutopilotSteps.COACHING_PLAN_SPLASH) {
    return (
      <Centered>
        <div style={{ maxWidth: '600px' }}>
          <CoachingPlanSplash matches={coachingPlanEligibility?.details?.matches ?? []} />
        </div>
      </Centered>
    );
  }

  if (!isMobile) {
    const Footer = () => {
      if (currentStep === AutopilotSteps.COACHING_PLAN) {
        return (
          <BottomFixedContainer>
            <Button style={{ marginTop: margins.size5 }} large onClick={clickCoachingPlanSignup}>
              Sign up for the plan
              <SmallArrow
                direction="right"
                color={colors.whiteMain}
                style={{ marginLeft: margins.size2 }}
              />
            </Button>
            <Button
              style={{ marginTop: margins.size1 }}
              large
              secondary
              onClick={clickCoachingPlanSkip}
            >
              Skip for now
              <SmallArrow
                direction="right"
                color={colors.primaryMain}
                style={{ marginLeft: margins.size2 }}
              />
            </Button>
          </BottomFixedContainer>
        );
      }
      return (
        <FixedButton
          onClick={onNext}
          large
          loading={isLoading}
          $isSecondary={getIsButtonSecondary()}
          secondary={getIsButtonSecondary()}
        >
          {getNextLabel()}
        </FixedButton>
      );
    };

    return (
      <BasicPage showHeader={false}>
        {currentStep === AutopilotSteps.TIMESLOTS && signedUpCoachingPlanType && (
          <FlexRow justifyContent="center">
            <CoachingPlanAvatars matches={coachingPlanEligibility?.details?.matches ?? []} />
          </FlexRow>
        )}
        <FixedContainer>
          <FlexColumn style={{ maxWidth: '450px' }}>
            <Heading1>
              {signedUpCoachingPlanType
                ? 'When would you like to meet your curated-matches each week?'
                : getHeader(currentStep)}
            </Heading1>
            <Text>
              {signedUpCoachingPlanType
                ? 'You can update this at any time!'
                : getSubheader(currentStep)}
            </Text>
            {currentStep === AutopilotSteps.TIMESLOTS && (
              <Text>The times are in {userTimezone}. Each meeting is 45 minutes long.</Text>
            )}
          </FlexColumn>
          {currentStep === AutopilotSteps.COACHING_PLAN && coachingPlanEligibility?.details ? (
            <CoachingPlanInfo details={coachingPlanEligibility.details} />
          ) : (
            currentStep === AutopilotSteps.TIMESLOTS && (
              <TimesSelector
                selectedTimeslots={selectedTimeslots}
                setSelectedTimeslots={setSelectedTimeslots}
                selectedCadence={DEFAULT_CADENCE.value}
              />
            )
          )}
        </FixedContainer>
        <Footer />
      </BasicPage>
    );
  }

  // Mobile View
  if (currentStep === AutopilotSteps.COACHING_PLAN && coachingPlanEligibility?.details) {
    return (
      <BasicPage showHeader={false}>
        <CoachingPlanInfo details={coachingPlanEligibility.details} />
        <MobileFooterContainer>
          <Button large onClick={clickCoachingPlanSignup}>
            Sign up for the plan
          </Button>
          <Button
            style={{ marginTop: margins.size1 }}
            large
            secondary
            onClick={clickCoachingPlanSkip}
          >
            Skip for now
          </Button>
        </MobileFooterContainer>
      </BasicPage>
    );
  }
  return (
    <OnboardingFlowPage
      step={OnboardingSteps.Autopilot}
      onNext={onNext}
      nextLabel={getNextLabel()}
      isNextButtonSecondary={getIsButtonSecondary()}
      nextLoading={isLoading}
      title={nuxHeaderSteps.Autopilot.title}
      subtitle={nuxHeaderSteps.Autopilot.subtitle}
    >
      <StepMobileContainer style={{ alignItems: 'center' }}>
        <ProgressBar total={2} numCompleted={currentStep - 1} />
        <Heading3 style={{ textAlign: 'center', marginBottom: margins.size3 }}>
          {signedUpCoachingPlanType
            ? 'When would you like to meet new matches?'
            : getHeader(currentStep)}
        </Heading3>
        {currentStep === AutopilotSteps.TIMESLOTS && (
          <>
            <SubText style={{ paddingBottom: margins.size1 }}>{getSubheader(currentStep)}</SubText>
            <SubText style={{ paddingBottom: margins.size3 }}>Times are in {userTimezone}</SubText>
          </>
        )}
        {currentStep === AutopilotSteps.TIMESLOTS && (
          <TimesSelector
            selectedTimeslots={selectedTimeslots}
            setSelectedTimeslots={setSelectedTimeslots}
            selectedCadence={DEFAULT_CADENCE.value}
          />
        )}
      </StepMobileContainer>
    </OnboardingFlowPage>
  );
};

const FixedContainer = styled(FlexColumn)`
  position: fixed;
  left: 50%;
  top: 10%;
  max-width: 600px;
  transform: translateX(-50%);
`;

const BottomFixedContainer = styled(FixedContainer)`
  bottom: ${margins.size4};
  top: unset;
  width: 100%;
`;

const MobileFooterContainer = styled(FixedContainer)`
  bottom: ${margins.size3};
  margin-top: ${margins.size3};
  top: unset;
  width: calc(100% - 32px);
`;

const FixedButton = styled(Button)<{ $isSecondary: boolean }>`
  position: fixed;
  bottom: ${margins.size4};
  left: 50%;
  max-width: 600px;
  transform: translateX(-50%);
  ${p => !p.$isSecondary && 'box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);'}
`;

const Centered = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`;
