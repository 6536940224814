import React, { useLayoutEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components/macro';
import moment from 'moment';
import { useHistory } from 'react-router';

import { FlexColumn, media, colors } from 'css/css';

import Channel from 'types/channel';

import { MoreProfileOptions } from 'js/components/shared/MoreProfileOptions';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { postChatCreateConversation } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';

import { transitionSettings } from '../ConversationContainer';
import { ChatTabs } from '..';

import { TopContainer } from './TopContainer';

interface Props {
  selectedChannel: Channel;
  setIsSchedulerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isFirstTimeScheduling: boolean;
  metDate?: string;
  setIsReconnect: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenTab?: React.Dispatch<React.SetStateAction<ChatTabs>>;
  isOpen: boolean;
  setHeaderHeight: React.Dispatch<React.SetStateAction<number>>;
}

export const ConversationHeader: React.FC<Props> = ({
  selectedChannel,
  setIsSchedulerOpen,
  isFirstTimeScheduling,
  metDate,
  setIsReconnect,
  setOpenTab,
  isOpen,
  setHeaderHeight,
}) => {
  const [isSeeMoreDropdownOpen, setIsSeeMoreDropdownOpen] = useState(false);

  const { setSelectedProfileId } = useChatContext();
  const { firstName, profileId, isBlocked, conversation } = selectedChannel;

  const history = useHistory();
  const isUpcoming = isFirstTimeScheduling || moment(metDate).isAfter(moment());

  const headerContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (headerContainerRef.current) {
      setHeaderHeight(headerContainerRef.current.clientHeight);
    }
  }, [headerContainerRef.current]);

  useMountEffect(() => {
    return () => setSelectedProfileId('');
  });

  const openScheduler = (isReconnect: boolean) => {
    setIsSchedulerOpen(true);
    setIsReconnect(isReconnect);
    if (!conversation) {
      postChatCreateConversation(profileId);
    }
  };

  return (
    <>
      <Transition
        in={isOpen}
        appear
        timeout={transitionSettings.timeout}
        onExited={() => {
          history.push('/chat');
          setSelectedProfileId('');
        }}
      >
        {state => (
          <div style={{ width: '100%' }}>
            <Container
              alignItems="flex-start"
              style={transitionSettings.styles[state]}
              ref={headerContainerRef}
            >
              <TopContainer
                selectedChannel={selectedChannel}
                isFirstTimeScheduling={isFirstTimeScheduling}
                isUpcoming={isUpcoming}
                metDate={metDate}
                setIsSeeMoreDropdownOpen={setIsSeeMoreDropdownOpen}
                setOpenTab={setOpenTab}
              />
            </Container>
            {isSeeMoreDropdownOpen && (
              <MoreProfileOptions
                setIsSeeMoreDropdownOpen={setIsSeeMoreDropdownOpen}
                isBlocked={isBlocked}
                firstName={firstName}
                profileId={profileId}
                openScheduler={openScheduler}
                selectedChannel={selectedChannel}
                isFirstTimeScheduling={isFirstTimeScheduling}
              />
            )}
          </div>
        )}
      </Transition>
    </>
  );
};

const Container = styled(FlexColumn)`
  width: 100%;
  ${media.mobile} {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: ${colors.whiteMain};
    padding-top: env(safe-area-inset-top);
    transition: all 300ms ease-out;
  }
`;
