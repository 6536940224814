import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';

import {
  borders,
  colors,
  margins,
  media,
  Heading3,
  FlexRow,
  globalTransitionSettings,
} from 'css/css';

import { Contact, MobileContactInvitee } from 'types/invites';

import { getMobileContactsInvitees, getUserContacts } from 'js/util/api';
import { useOutsideAlerter, useMountEffect } from 'js/util/custom-hooks';
import { useNotifContext } from 'js/util/notif-context';
import { invitePage } from 'js/util/strings';
import { getIsIOSApp, validateEmail } from 'js/util/util';

import searchIcon from 'img/shared/search-light.svg';

import { TextInput } from './TextInput';
import { Button } from './Button';
import { Avatar } from './Avatar';

interface Props {
  buttonOnClick: (email: string) => void;
  mobileContactOnClick: (phoneNumber: string) => void;
  buttonText: string;
  noUsers: boolean;
  fullWidth?: boolean;
  isMobile?: boolean;
  isEmailTab: boolean;
  loading?: boolean;
}

export const Autocomplete: React.FC<Props> = ({
  buttonOnClick,
  mobileContactOnClick,
  buttonText,
  noUsers,
  fullWidth,
  isMobile,
  isEmailTab,
  loading = false,
}) => {
  const { showNotif } = useNotifContext();

  const [filteredContacts, setFilteredContacts] = useState<Contact[] | MobileContactInvitee[]>([]);
  const [hoverChoice, setHoverChoice] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [mobileContacts, setMobileContacts] = useState<MobileContactInvitee[]>([]);

  const isMobileApp = getIsIOSApp();

  useMountEffect(() => {
    const loadMobileContacts = async () => {
      const res = await getMobileContactsInvitees(30, undefined);
      if (res.ok) {
        setMobileContacts(res.getJson);
      }
    };
    const loadContacts = async () => {
      const res = await getUserContacts();
      if (res.status === 200) {
        if (noUsers) {
          setContacts(res.getJson.filter(contact => !contact.is_user));
        } else {
          setContacts(res.getJson);
        }
      }
    };
    if (isMobileApp) {
      loadMobileContacts();
    }
    loadContacts();
  });

  const dropdownRef = useRef<HTMLUListElement>(null);
  useOutsideAlerter(dropdownRef, () => {
    setFilteredContacts([]);
    setShowContacts(false);
  });

  const onChange = (e: string) => {
    const newUserInput = e;
    const newFilteredContacts = isEmailTab
      ? contacts.filter(contact => filterRequirement(contact, newUserInput))
      : mobileContacts.filter(contact => mobileContactsFilter(contact, newUserInput));
    setFilteredContacts(newFilteredContacts.slice(0, 4));
    setShowContacts(true);
    setUserInput(e);
  };

  const matches = (keyword: string, text: string) => {
    if (keyword.length === 0) {
      return false;
    }
    return text.toLowerCase().includes(keyword.toLowerCase());
  };

  const mobileContactsFilter = (contact: MobileContactInvitee, newUserInput: string) => {
    const keys = [`${contact.name}`, contact.phone_number];
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      if (matches(newUserInput, key)) return true;
    }
    return false;
  };

  const filterRequirement = (contact: Contact, newUserInput: string) => {
    const keys = [`${contact.first_name} ${contact.last_name}`, contact.email];
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      if (matches(newUserInput, key)) return true;
    }
    return false;
  };

  const onClick = (e: any) => {
    setFilteredContacts([]);
    setShowContacts(false);
    const selectedEmail = e.currentTarget.getElementsByClassName('email')[0].innerText;
    if (isMobile) {
      buttonOnClick(selectedEmail);
      setUserInput('');
    } else {
      setUserInput(selectedEmail);
    }
  };

  const submitEmail = () => {
    if (!userInput || loading) {
      return;
    }
    const cleanedEmail = userInput.trim();
    if (!validateEmail(cleanedEmail)) {
      showNotif({
        message: `Please enter a valid email`,
        level: 'error',
      });
      return;
    }
    buttonOnClick(userInput);
  };
  const validContacts = showContacts && userInput && filteredContacts.length;

  return (
    <Container>
      {(isEmailTab && contacts.length) || (!isEmailTab && mobileContacts.length) ? (
        <ContactsWrapper>
          <ContactsSearchTextInput
            placeholder={invitePage.autocompleteCta}
            mobileFocusBehavior="never"
            onChange={onChange}
            value={userInput}
            enterKeyHint="send"
            maxWidth={fullWidth ? '100%' : '350px'}
            maxWidthMobile="100%"
            onEnter={() => {
              submitEmail();
              setUserInput('');
            }}
          />
          {!!validContacts &&
            (isEmailTab ? (
              <ContactsListComponent
                dropdownRef={dropdownRef}
                filteredContacts={filteredContacts as Contact[]}
                hoverChoice={hoverChoice}
                onClick={onClick}
                setHoverChoice={setHoverChoice}
              />
            ) : (
              <MobileContactsListComponent
                dropdownRef={dropdownRef}
                filteredContacts={filteredContacts as MobileContactInvitee[]}
                onClick={mobileContactOnClick}
              />
            ))}
        </ContactsWrapper>
      ) : (
        <ContactsWrapper>
          <TextInput
            placeholder={invitePage.autocompleteCtaNoContacts}
            onChange={setUserInput}
            enterKeyHint="send"
            value={userInput}
            style={{ marginRight: margins.size1 }}
            maxWidthMobile="500px"
            onEnter={() => {
              submitEmail();
              setUserInput('');
            }}
          />
        </ContactsWrapper>
      )}

      {!isMobile && (
        <Button
          onClick={() => {
            submitEmail();
            setUserInput('');
          }}
          large
          invalid={!validateEmail(userInput)}
          maxWidth="170px"
          loading={loading}
          invalidMessage="Please enter a valid email"
        >
          {buttonText}
        </Button>
      )}
    </Container>
  );
};

interface ContactsListComponentProps {
  dropdownRef: React.RefObject<HTMLUListElement>;
  filteredContacts: Contact[];
  hoverChoice: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  setHoverChoice: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactsListComponent: React.FC<ContactsListComponentProps> = ({
  dropdownRef,
  filteredContacts,
  hoverChoice,
  onClick,
  setHoverChoice,
}) => (
  <Contacts ref={dropdownRef}>
    {filteredContacts.map(contact => {
      return (
        <ContactList
          hoverChoice={hoverChoice}
          key={contact.email}
          onMouseEnter={() => setHoverChoice(true)}
          onMouseLeave={() => setHoverChoice(false)}
        >
          <ContactRow onClick={onClick}>
            <StyledProfileImage src={contact.image} alt="User" />

            <RightContainer>
              <Heading3 style={{ textAlign: 'left', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {contact.first_name} {contact.last_name}
              </Heading3>

              <p
                style={{ textAlign: 'left', textOverflow: 'ellipsis', overflow: 'hidden' }}
                className="email"
              >
                {contact.email}
              </p>
            </RightContainer>
          </ContactRow>
        </ContactList>
      );
    })}
  </Contacts>
);

interface ListProps {
  dropdownRef: React.RefObject<HTMLUListElement>;
  filteredContacts: MobileContactInvitee[];
  onClick: (phoneNumber: string) => void;
}

const MobileContactsListComponent: React.FC<ListProps> = ({
  dropdownRef,
  filteredContacts,
  onClick,
}) => (
  <Contacts ref={dropdownRef}>
    {filteredContacts.map((contact: MobileContactInvitee) => {
      return (
        <ContactList key={contact.name}>
          <ContactRow onClick={() => onClick(contact.phone_number)}>
            <Avatar
              avatarId={contact.phone_number}
              size2
              src={null}
              style={{ marginLeft: margins.size3 }}
            />
            <RightContainer>
              <Heading3 style={{ textAlign: 'left', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {contact.name}
              </Heading3>
              <p
                style={{ textAlign: 'left', textOverflow: 'ellipsis', overflow: 'hidden' }}
                className="email"
              >
                {contact.phone_number}
              </p>
            </RightContainer>
          </ContactRow>
        </ContactList>
      );
    })}
  </Contacts>
);

export const ContactsSearchTextInput = styled(TextInput)`
  background-image: url(${searchIcon});
  background-size: 16px;
  background-repeat: no-repeat;
  text-indent: ${margins.size4};
  background-position: ${margins.size3} center;
  transition: ${globalTransitionSettings};

  &::placeholder {
    opacity: 50%;
  }
  &:focus {
    background-image: none;
    background-size: 0px;
    text-indent: 0px;
  }
  &:-webkit-autofill {
    background-size: 0px;
    background-image: none;
    text-indent: 0px;
  }
`;

const StyledProfileImage = styled.img`
  margin-left: ${margins.size3};
  width: 100%;
  max-width: 40px;
  object-fit: cover;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
`;

const RightContainer = styled.div`
  display: inline-block;
  word-break: break-all;
  margin-top: ${margins.size3};
  margin-left: ${margins.size3};
  flex: 1;
  min-width: 0;
  white-space: nowrap;
`;

type ContactListTypes = { hoverChoice?: boolean };
const ContactList = styled.li<ContactListTypes>`
  color: ${colors.blackMid};
  background-color: ${p => (p.hoverChoice ? colors.greyLight : colors.whiteMain)};
  ${media.mobile} {
    padding: 0px;
  }
`;

const Contacts = styled.ul`
  border-top-width: 0;
  list-style: none;
  margin-top: 0px;
  overflow-y: auto;
  padding-left: 0;
  position: absolute;
  z-index: 11;
  max-width: 302px;
  width: 100%;
  ${media.mobile} {
    max-width: initial;
    border-radius: 15px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${margins.size1};
  ${media.mobile} {
    display: block;
    margin-bottom: ${margins.size3};
    input {
      border-radius: 10px;
      font-size: 16px;
      &::placeholder {
        text-align: center;
      }
    }
  }
`;

const ContactRow = styled(FlexRow)`
  background-color: ${colors.whiteMain};
  color: ${colors.blackMid};
  border: ${borders.standard};
  border-top: none;
  margin: 0px;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${colors.greyLight};
  }
`;

const ContactsWrapper = styled.div`
  width: 100%;
  margin-right: ${margins.size1};
  position: relative;
  min-width: 310px;
  ${media.mobile} {
    min-width: initial;
  }
`;
