import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { margins, FlexColumn, MobileScrollFade, Heading3, colors } from 'css/css';

import { InviteSuggestionResponse, MobileContactInvitee } from 'types/invites';

import { InviteCardRowMobile } from 'js/components/shared/InviteCardRow';
import { Button } from 'js/components/shared/Button';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { ImpressionTracker } from 'js/components/shared/ImpressionTracker';
import {
  apiFetch,
  postTestActivity,
  updateMobileContactsInvitee,
  getMobileContactsInvitees,
} from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';

import promptContactsSvg from 'img/mobile-root/prompt-contacts.svg';

interface Props {
  sendMobileInvite: (phoneNumber: string) => void;
}

export const InviteSuggestionsMobileContacts: React.FC<Props> = ({ sendMobileInvite }) => {
  const [suggestionsLoading, setSuggestionsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState<MobileContactInvitee[]>([]);
  const [excludePhoneNumbers, setExcludePhoneNumbers] = useState<string[]>([]);
  const [hasLoadedAllSuggestions, setHasLoadedAllSuggestions] = useState(false);

  const sendInvite = async (phoneNumber: string) => {
    setSuggestions(x => x.filter(y => y.phone_number !== phoneNumber));
    await sendMobileInvite(phoneNumber);
  };

  const skipInvite = (phoneNumber: string) => {
    setSuggestions(x => x.filter(y => y.phone_number !== phoneNumber));
    updateMobileContactsInvitee(phoneNumber, InviteSuggestionResponse.DECLINED);
  };

  const loadInviteSuggestions = async (count: number) => {
    setSuggestionsLoading(true);
    const res = await getMobileContactsInvitees(count, excludePhoneNumbers);
    if (res.status === 200) {
      setSuggestions(x => [...x, ...res.getJson]);
      setExcludePhoneNumbers(x => [...x, ...res.getJson.map(y => y.phone_number)]);
      if (res.getJson.length === 0) setHasLoadedAllSuggestions(true);
    }
    setSuggestionsLoading(false);
  };

  useMountEffect(() => {
    loadInviteSuggestions(30);
  });

  const handleScroll: React.UIEventHandler<HTMLDivElement> = e => {
    if (hasLoadedAllSuggestions || suggestionsLoading) {
      return;
    }
    const approachingBottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop < 700;

    if (approachingBottom) {
      loadInviteSuggestions(20);
    }
  };

  const FullDivLoader = () => (
    <div style={{ marginTop: margins.size4 }}>
      <SVGLoader center={false} />;
    </div>
  );

  const ConnectContactsCta = () => (
    <FlexColumn style={{ flexGrow: 1, justifyContent: 'center', gap: margins.size4 }}>
      <img src={promptContactsSvg} alt="3 people" width="305px" />

      <Heading3 style={{ margin: `${margins.size4} 0px` }}>
        Whoa! We do not have your contacts.
      </Heading3>
      <Button
        onClick={() => {
          postTestActivity('invites_mobile_contact', 'accept');
          if (window.bridge) {
            window.bridge.retrieveContacts(true, contacts => {
              apiFetch('POST', `discover/mobile/contacts`, { contacts });
            });
            setSuggestionsLoading(true);
            setTimeout(() => {
              loadInviteSuggestions(20);
            }, 1000);
          }
        }}
        style={{ padding: margins.size4 }}
      >
        <FlexColumn style={{ height: '100%', width: '100%', justifyContent: 'center' }}>
          Connect Contacts
        </FlexColumn>
      </Button>
    </FlexColumn>
  );

  const noSuggestions = suggestions?.length === 0 && !suggestionsLoading;
  const isInitialLoad = suggestions?.length === 0 && suggestionsLoading;

  return (
    <Container>
      {isInitialLoad ? (
        <FullDivLoader />
      ) : noSuggestions ? (
        <ConnectContactsCta />
      ) : (
        <div
          style={{ overflowY: 'scroll', overflowX: 'hidden', paddingBottom: margins.size3 }}
          onScroll={handleScroll}
        >
          <MobileScrollFade />
          {suggestions.map(invitee => (
            <ImpressionTracker
              key={invitee.phone_number}
              impressionId={`invite,${invitee.phone_number}`}
            >
              <InviteCardRowMobile
                user={invitee}
                sendInvite={() => sendInvite(invitee.phone_number)}
                skipInvite={() => skipInvite(invitee.phone_number)}
              />
            </ImpressionTracker>
          ))}
          {suggestionsLoading && <SVGLoader center={false} small />}
        </div>
      )}
    </Container>
  );
};

const Container = styled(FlexColumn)`
  align-items: stretch;
  overflow: scroll;
  background-color: ${colors.whiteMain};
  height: 100%;
  padding: 0px ${margins.size3};
`;
