import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { colors, margins, Card, FlexRow, FlexColumn, Heading3, fonts, media } from 'css/css';

import { ProductUser } from 'types/user';

import { ProfilePictureUploader } from 'js/components/registration/mobile/ProfilePictureUploader';
import { Button } from 'js/components/shared/Button';
import { RoundBackButton } from 'js/components/shared/mobile/RoundBackButton';
import { GreyPage } from 'js/components/shared/page-wrappers';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { getIsMobile, scrollToTopIfNeeded } from 'js/util/util';
import { useDataContext } from 'js/providers/DataContextProvider';

import Streak from 'img/profile/streak.svg';
import editPencilBlackSvg from 'img/settings/edit-pencil-black.svg';
import editPencilWhiteSvg from 'img/settings/edit-pencil-white.svg';
import { iconEndorsementsSvg, iconNewYearGoalsSvg } from 'img/profile-v2';

import { Communities } from './Communities';
import { ConversationStarters } from './ConversationStarters';
import { Hero } from './Hero';
import { ProfileInterests } from './ProfileInterests';
import { ProfileEndorsements } from './ProfileEndorsements';
import { Profile } from './Profile';
import { LineBreak } from './shared';
import { ConversationState } from './constants';
import { ProfileMobile, ProfileBackground, ProfileAvatarImage } from './ProfileMobile';
import { ProfileEditorMobile } from './ProfileEditorMobile';
import { ProfileNewYearGoals } from './ProfileNewYearGoals';

const pluralize = (count: number, noun: string, suffix = 's') =>
  `${noun}${count !== 1 ? suffix : ''}`;

export const ProfilePage = ({ passedUser }: { passedUser?: ProductUser }) => {
  const [editingTop, setEditingTop] = useState(false);
  const [editingBottom, setEditingBottom] = useState(false);
  const [navbarHeaderHeight, setNavbarHeaderHeight] = useState(0);

  const { updateUser } = useUserContextProvider();

  const {
    navbarIconClicked,
    setNavbarIconClicked,
    endorsements: myEndorsements,
  } = useDataContext();
  const { profile_id } = useUser();
  const isMe =
    passedUser === undefined ||
    ('profile_id' in passedUser && profile_id === passedUser.profile_id);
  const endorsements = isMe
    ? myEndorsements
    : passedUser !== undefined && 'endorsements' in passedUser
    ? passedUser.endorsements
    : [];

  useEffect(() => {
    scrollToTopIfNeeded(navbarIconClicked, 'profile', null, setNavbarIconClicked);
  }, [navbarIconClicked]);

  const user = useUser();
  const {
    streak,
    num_meetings: numMeetings,
    ask_me_about: askMeAbout,
    like_to_learn: likeToLearn,
    just_learned: justLearned,
    top_of_mind: topOfMind,
    side_hustle: sideHustle,
  } = passedUser || user;

  const [updatedImage, setUpdatedImage] = useState('');
  const [conversationState, setConversationState] = useState<ConversationState>({
    askMeAbout,
    likeToLearn,
    justLearned,
    topOfMind,
    sideHustle,
  });
  const [newYearGoals, setNewYearGoals] = useState<string[]>(user.new_year_goals);

  useEffect(() => {
    setConversationState({ askMeAbout, likeToLearn, justLearned, topOfMind, sideHustle });
    setNewYearGoals(passedUser?.new_year_goals || user.new_year_goals);
  }, [passedUser]);

  const saveConversations = () =>
    updateUser({
      ...conversationState,
      newYearGoals,
    });

  if (getIsMobile()) {
    return editingTop ? (
      <div style={{ background: colors.greyLight, minHeight: '100%' }}>
        <ProfileBackground style={{ minHeight: 100, marginBottom: 60 }}>
          <RoundBackButtonContainer>
            <RoundBackButton onClick={() => setEditingTop(false)} />
          </RoundBackButtonContainer>
          <ProfilePictureUploader onDidUpload={setUpdatedImage}>
            {(uploading, onPickFile) => (
              <>
                <ProfileAvatarImage
                  src={updatedImage || user.image}
                  onClick={onPickFile}
                  avatarId={user.profile_id}
                />
                <ProfileAvatarEditButton onClick={onPickFile}>
                  {uploading ? <SVGLoader /> : <img src={editPencilBlackSvg} alt="edit" />}
                </ProfileAvatarEditButton>
              </>
            )}
          </ProfilePictureUploader>
        </ProfileBackground>
        <ProfileEditorMobile onDone={() => setEditingTop(false)} />
      </div>
    ) : (
      <GreyPage
        fixedFullHeight
        style={{ padding: 0, background: colors.whiteMain }}
        setNavbarHeaderHeight={setNavbarHeaderHeight}
      >
        <ProfileBackground style={{ minHeight: 100 }}>
          <ProfileAvatarImage alt="profile" src={user.image} avatarId={user.profile_id} />
        </ProfileBackground>
        <ProfileMobile profile={user} endorsements={endorsements} />
        <div style={{ height: 54 }} />
        <EditProfileButton
          style={{ display: 'flex', gap: margins.size2, alignItems: 'center' }}
          onClick={() => setEditingTop(true)}
          navbarHeaderHeight={navbarHeaderHeight}
        >
          <img src={editPencilWhiteSvg} alt="edit" />
          Edit Profile
        </EditProfileButton>
      </GreyPage>
    );
  }

  const profileCard = (
    <ProfileCard>
      {!editingBottom && (
        <>
          <Header />

          <OuterContainer>
            <ProfilePadding>
              <Hero passedUser={passedUser} editing={editingTop} showSocials showChatButton />
              <FlexRow alignItems="start">
                <ProfileContent>
                  <Profile
                    passedUser={passedUser}
                    editing={editingTop}
                    save={() => setEditingTop(false)}
                    cancel={() => setEditingTop(false)}
                  />
                </ProfileContent>
                <FlexColumn>
                  <div style={{ height: '24px' }}>
                    {!passedUser && !editingTop && (
                      <Button
                        style={{ margin: 0 }}
                        secondary
                        small
                        onClick={() => setEditingTop(true)}
                      >
                        edit
                      </Button>
                    )}
                  </div>
                </FlexColumn>
              </FlexRow>
            </ProfilePadding>
          </OuterContainer>
        </>
      )}
      {!editingTop && (
        <OuterContainer noTop={editingBottom}>
          {!passedUser && !editingBottom && <LineBreak />}

          <ProfilePadding>
            {!editingBottom && (
              <FlexRow justifyContent="space-between" style={{ marginTop: margins.size3 }}>
                <FlexRow>
                  {numMeetings !== undefined && (
                    <FlexRow>
                      <Heading3>{numMeetings}</Heading3>
                      <MeetingsText>{pluralize(numMeetings, 'Meeting')}</MeetingsText>
                    </FlexRow>
                  )}
                  {streak > 0 && (
                    <FlexRow style={{ marginLeft: margins.size2 }}>
                      <img src={Streak} alt="Streak Icon" />
                      <Heading3 style={{ marginLeft: margins.size1 }}>{streak}</Heading3>
                    </FlexRow>
                  )}
                </FlexRow>
                {!passedUser && (
                  <Button
                    style={{ margin: 0 }}
                    secondary
                    small
                    onClick={() => setEditingBottom(true)}
                  >
                    edit
                  </Button>
                )}
              </FlexRow>
            )}

            <ProfileContent>
              <ProfileInterests passedUser={passedUser} editing={editingBottom} />
              <ConversationStarters
                passedUser={passedUser}
                editing={editingBottom}
                conversationState={conversationState}
                setConversationState={setConversationState}
              />
              {(newYearGoals.length > 0 || editingBottom) && (
                <>
                  {editingBottom && <LineBreak />}
                  <div style={{ marginBlockEnd: margins.size4 }}>
                    <FlexRow width="100%" style={{ marginBottom: margins.size2 }}>
                      <img
                        style={{ marginRight: margins.size3 }}
                        src={iconNewYearGoalsSvg}
                        alt="New Year Goals icon"
                      />
                      <Heading3>Goals this year</Heading3>
                    </FlexRow>
                    <div style={editingBottom ? {} : { marginLeft: '40px' }}>
                      <ProfileNewYearGoals
                        newYearGoals={newYearGoals}
                        setNewYearGoals={setNewYearGoals}
                        editing={editingBottom}
                      />
                    </div>
                  </div>
                </>
              )}
              {endorsements.length > 0 && (
                <div style={{ marginBlockEnd: margins.size4 }}>
                  <FlexRow width="100%" style={{ marginBottom: margins.size2 }}>
                    <img
                      style={{ marginRight: margins.size3 }}
                      src={iconEndorsementsSvg}
                      alt="Endorsements icon"
                    />
                    <Heading3>Endorsed for</Heading3>
                  </FlexRow>
                  <div style={{ marginLeft: '40px' }}>
                    <ProfileEndorsements endorsements={endorsements} />
                  </div>
                </div>
              )}
              <Communities passedUser={passedUser} editing={editingBottom} />
              {editingBottom && (
                <Button
                  large
                  onClick={() => {
                    saveConversations();
                    setEditingBottom(false);
                  }}
                  style={{ marginBottom: margins.size5 }}
                >
                  Done
                </Button>
              )}
            </ProfileContent>
          </ProfilePadding>
        </OuterContainer>
      )}
    </ProfileCard>
  );
  if (passedUser) return profileCard;
  return <GreyPage>{profileCard}</GreyPage>;
};

const RoundBackButtonContainer = styled.div`
  position: fixed;
  top: calc(env(safe-area-inset-top) + ${margins.size3});
  left: ${margins.size3};
  z-index: 2;
`;

const ProfileCard = styled(Card)`
  max-width: 600px;
  min-width: 600px;
  ${media.mobile} {
    max-width: 100vw;
    min-width: unset;
  }
`;

const MeetingsText = styled(Heading3)`
  margin-left: ${margins.size1};
  font-family: ${fonts.regular};
`;

const Header = styled.div`
  width: 100%;
  height: 120px;
  background-color: ${colors.greyMain};
`;

type OuterContainerTypes = { noTop?: boolean };
const OuterContainer = styled.div<OuterContainerTypes>`
  position: relative;
  top: ${p => (p.noTop ? '0px' : ' -62px')};
`;

const ProfilePadding = styled.div`
  padding: 0px ${margins.size4};
`;

const ProfileContent = styled.div`
  width: 100%;
  text-align: left;
  position: relative;
  top: 24px;
`;

const EditProfileButton = styled(Button)<{ navbarHeaderHeight: number }>`
  position: fixed;
  bottom: calc(env(safe-area-inset-bottom) + ${margins.size2} + ${p => p.navbarHeaderHeight}px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.12);
  right: 15px;
`;

const ProfileAvatarEditButton = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: ${colors.whiteMain};
  transform: translate(19px, 47px);
  position: absolute;
  left: 50%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.12);
`;
