import React from 'react';
import styled from 'styled-components/macro';

import {
  borders,
  lineHeights,
  fontSizes,
  fonts,
  colors,
  margins,
  FlexRow,
  Heading3,
  Clickable,
  globalTransitionSettings,
} from 'css/css';

import TagAdd from 'img/profile/tag-add-filled.svg';
import TagDelete from 'img/profile/tag-delete.svg';

export const LeftAlign = styled.div`
  text-align: left;
`;

const Icon = styled.button`
  cursor: pointer;
  padding: 0px;
  flex-direction: right;
  background-color: ${colors.greyLight};
  border-radius: 0px 5px 5px 0px;
  border: none;
`;

type InterestButtonTypes = { deleting: boolean };
export const InterestButton = styled.p<InterestButtonTypes>`
  font-family: ${fonts.regular};
  font-size: ${fontSizes.size2};
  line-height: 0.5;

  color: ${colors.secondaryMid};
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: ${margins.size1};
  margin-right: ${margins.size1};
  cursor: default;

  padding: ${p => (p.deleting ? '0px 8px 0px 0px' : '14px 8px')};
  background-color: ${colors.secondaryLight};
  border-radius: 3px;
  height: 36px;

  display: flex;
  align-items: center;
`;

export const AddInterestButton = () => (
  <Icon>
    <img src={TagAdd} style={{ margin: 4 }} alt="Add Interest" />
  </Icon>
);

export const TagDeleteButton = ({ tagName, updateInterests }: any) => (
  <Clickable onClick={updateInterests}>
    <img style={{ width: 36, height: 36 }} src={TagDelete} alt={`Delete tag ${tagName}`} />
  </Clickable>
);
export const InputContainer = styled.form`
  display: flex;
  width: 100%;
  padding-top: ${margins.size4};
`;
export const InputField = styled.input`
  width: 100%;
  padding: 10px;
  outline: none;
  background-color: ${colors.greyLight};
  border: none;
  border-radius: 5px 0px 0px 5px;
  font-family: ${fonts.regular};
  font-size: ${fontSizes.size2};
  line-height: ${lineHeights.body};
  color: ${colors.blackMid};
`;

type SelectableInterestButtonTypes = { selected: boolean };
export const SelectableInterestButton = styled.button<SelectableInterestButtonTypes>`
  color: ${p => (p.selected ? colors.whiteMain : colors.secondaryMid)};
  background-color: ${p => (p.selected ? colors.secondaryMid : colors.whiteMain)};
  border: ${borders.secondary};
  border-radius: 3px;
  padding: ${margins.size2};
  font-family: ${fonts.regular};
  font-size: ${fontSizes.size2};
  line-height: ${lineHeights.body};
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: ${margins.size1};
  margin-right: ${margins.size1};
  cursor: pointer;
  transition: ${globalTransitionSettings};
  :focus {
    outline: none;
  }
`;

export const CategoryHeading = styled(Heading3)`
  margin-top: ${margins.size4};
  margin-bottom: ${margins.size3};
  color: ${colors.blackLight};
`;

export const WrappingRow = styled(FlexRow)`
  margin-top: ${margins.size1};
  flex-wrap: wrap;
`;

export const SaveButtonBox = styled.div`
  margin-top: ${margins.size6};
  margin-bottom: ${margins.size6};
  text-align: center;
`;
