import React from 'react';
import styled from 'styled-components/macro';

import { Card, Heading1, Heading2, Text, margins, colors } from 'css/css';

import { GreyPage } from 'js/components/shared/page-wrappers';

export const Privacy = () => (
  <GreyPage>
    <Card maxWidth="900px" padding={margins.size6}>
      <Heading1 style={{ marginBottom: margins.size4 }}>Privacy Policy</Heading1>

      <div style={{ textAlign: 'left', maxWidth: '600px', margin: 'auto' }}>
        <SubHeading>Effective date: January 1, 2017.</SubHeading>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          Welcome to Lunchclub, a software platform that enables users to meet with others around
          them that share interests and goals. The platform is managed by Elliot Technologies, Inc.
          dba Lunchclub (&#34;Elliot,&#34; &#34;we,&#34; &#34;us&#34; or &#34;our&#34;), with
          physical mailing location is 4100 NE 2nd Ave, Suite 302, Miami, FL, 33137, a software
          company that uses machine learning to create meaningful connections.
        </Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          This Privacy Policy (&#34;Our Policy&#34;, or the &#34;Policy&#34;) explains how we
          collect, use, share and protect information in relation to our website (
          <a style={{ color: colors.primaryMain }} href="lunchclub.com">
            lunchclub.com
          </a>
          ), and any software provided on or in connection with our services (collectively, the
          &#34;Service&#34;), and your choices about the collection and use of your information.
        </Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          Our Policy applies to all visitors, users, and others who access the Service
          (&#34;Users&#34;).
        </Text>

        <SubHeading>Information we collect</SubHeading>

        <Text bold>Registration Information</Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          We may collect information needed to register and sign into the Service, such as your
          e-mail address and phone number (if applicable).
        </Text>

        <Text bold>Profile Information</Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          We may collect personally identifiable information such as your name, picture, country,
          city, and school name (if applicable).
        </Text>

        <Text bold>Location Information</Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          We may collect location data while you are actively using the Service on a mobile device.
          This information is not shared externally and is used by the Service to determine
          connections within your vicinity who are also actively using the Service.
        </Text>

        <Text bold>Google Permissions</Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          We may ask for some Google permissions allowing us to register and authenticate you on the
          Service using Google. By default, we retrieve certain information such as your Google id,
          email address, contacts, and calendar. This information is used to help us easily connect
          you to the Service and to help us find new connections for you to meet efficiently.
        </Text>

        <Text bold>Contacts List and Friends</Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          In addition to your contacts made available to us via Google, we may ask for additional
          contacts, either from your device, email, or other third-party social media site.
        </Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          If you provide us with permission to use your contacts list, the Service will access your
          contacts list to expand the social network of friends using Elliot. You understand and
          agree that you are responsible for any charges that apply to communications sent from your
          device.
        </Text>

        <Text bold>Logging Information</Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          We log information reported by our servers or your device each time you use the Service.
          This information includes your Internet Protocol (&#34;IP&#34;) address, browser type,
          device platform (iOS, Android), and user id. This information is used for diagnostics and
          to improve our Service.
        </Text>

        <SubHeading>How we use your information</SubHeading>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          In addition to some of the specific uses of information we describe in this Privacy
          Policy, we may use information that we receive to:
        </Text>

        <ul style={{ color: colors.blackMid }}>
          <li>Help you efficiently log in and authenticate with the Service</li>

          <li>Provide personalized suggestions and information to you and others</li>

          <li>Provide, improve, test, and monitor the effectiveness of our Service</li>

          <li>Develop and test new products and features</li>

          <li>For technical support and detecting bugs and/or crashes in our Service</li>

          <li>
            Monitor metrics such as total number of visitors, traffic, and geographic patterns
          </li>
        </ul>

        <SubHeading>Sharing of your information</SubHeading>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          We will not rent or sell your information to third parties without your consent, except as
          noted in this Policy.
        </Text>

        <Text bold>Assignment and/or Change of Control</Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          If we incorporate, sell, or otherwise transfer part or the whole of the Service, User
          Content and any other information collected through the Service may be assigned and/or
          transfered to the new entity. The buyer, transferee, or new entity will have to honor the
          commitments we have made in this Privacy Policy.
        </Text>

        <Text bold>Instances where we may be required to share your information</Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          Elliot will disclose your information where required to do so by law or by judicial
          process or if we reasonably believe that such action is necessary to:
        </Text>

        <ul style={{ color: colors.blackMid }}>
          <li>Comply with the law and the reasonable requests of law enforcement</li>

          <li>Enforce our Terms of Use</li>

          <li>Protect the security or integrity of our Service</li>

          <li>
            Exercise or protect the rights, property, or personal safety of Elliot, our Users or
            others
          </li>
        </ul>

        <SubHeading>How we store your information</SubHeading>

        <Text bold>Storage and Processing</Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          Information collected through the Service may be stored and processed in the United
          States, or any other country in which the Service is made available. The information will
          be stored in tools, machines, databases and/or facilities provided and maintained by
          Elliot or Service Providers.
        </Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          Anonymized user data may be accessible to certain types of persons involved with the
          operation of the Service (administration, sales, marketing, legal, system administration,
          developers, and/or designers) or Service Providers (such as third party technical service
          providers, mail carriers, hosting providers, IT companies, communications agencies)
          appointed, if necessary to transfer, back-up, or store the data.
        </Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          We use commercially reasonable safeguards to help keep the information collected through
          the Service secure and take reasonable steps (such as using the OAuth Protocol) to verify
          your identity before granting you access to your account. However, Elliot cannot ensure
          the security of any information you transmit to Elliot or guarantee that information on
          the Service may not be accessed, disclosed, altered, or destroyed.
        </Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          Your privacy settings may also be affected by changes made by other social media services.
          We are not responsible for the functionality, privacy, or security measures of any other
          organization.
        </Text>

        <SubHeading>Your choices about your information</SubHeading>

        <Text bold>Your account information and profile/privacy settings</Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          You may update your account, including privacy and permissions settings, at any time by
          accessing the Service and changing your profile settings.
        </Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          You may unsubscribe from any email communications or promotions that are not
          Service-related (e.g., account verification, purchase and billing confirmations and
          reminders, changes/updates to features of the Service, technical and security notices are
          all Service-related).
        </Text>

        <Text bold>How long we keep your User Content</Text>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          Following termination or deactivation of your account, Elliot may retain information
          (including your profile information) and User Content for a commercially reasonable time
          for backup, archival, and/or audit purposes.
        </Text>

        <SubHeading>How to contact us</SubHeading>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          If you have any questions about this Privacy Policy or the Service,{' '}
          <a style={{ color: colors.primaryMain }} href="mailto:support@lunchclub.ai">
            you may email us.
          </a>
        </Text>

        <SubHeading>Changes to our privacy policy</SubHeading>

        <Text style={{ marginTop: margins.size2, marginBottom: margins.size2 }}>
          Elliot may modify or update this Privacy Policy from time to time, so please review it
          periodically. We may provide you additional forms of notice of modifications or updates as
          appropriate under the circumstances. Your continued use of Elliot or the Service after any
          modification to this Privacy Policy will constitute your acceptance of such modification.
        </Text>
      </div>
    </Card>
  </GreyPage>
);

const SubHeading = styled(Heading2)`
  color: ${colors.primaryMain};
  margin-bottom: ${margins.size4};
  margin-top: ${margins.size5};
`;
