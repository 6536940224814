import moment from 'moment';

export const SETTINGS_PAGES: any = {
  ACCOUNT: {
    id: 0,
    text: 'Account',
  },
  AVAILABILITIES: {
    id: 1,
    text: 'Availabilities',
  },
  GOALS: {
    id: 2,
    text: 'Goals',
  },
  COMMUNITIES: {
    id: 3,
    text: 'Communities',
  },
  NOTIFICATIONS: {
    id: 4,
    text: 'Notifications',
  },
};

export const PauseSteps = {
  DURATION: {
    header: 'Pause introductions until...',
    buttonText: 'Pause',
  },
  REASON: {
    header: 'Why would you like to pause meetings?',
    buttonText: 'Send',
  },
};

export const PauseUntilValues: Record<string, string> = {
  1: 'A week from now',
  2: 'Two weeks from now',
  3: 'Three weeks from now',
  4: 'A month from now',
  5: 'I turn them back on',
};

export const SectionsTitles = {
  CONNECTED_ACCOUNTS: 'Connected accounts',
  MEETINGS_FREQUENCY: 'Meetings frequency',
  HOME_CITY: 'Home city',
  PRIMARY_EMAIL: 'Primary email',
};

export const PauseReasons = {
  1: 'My schedule is currently unpredictable',
  2: 'I will be busy with other things',
  3: 'I’m unhappy with the product, or changes in it',
  4: 'I’m taking a break from making new connections',
  5: 'I’m satisfying my networking needs on another platform',
};

export const SettingsSectionsTitles = {
  PAUSE_MEETINGS: 'Pause recurring meetings',
  TARGET_LOCALE: 'Meet local matches only',
  COACHING_PLAN: 'Curated Matches',
  AUTOMATIC_INTROS: 'Automatic intros',
  OUTREACH: 'Lunchclub Daily Picks outreach',
  RECONNECTS: 'Smart reconnects',
  PAUSE_ACCOUNT: 'Pause Account',
};

export const getPauseMeetingsSubtitle = ({ pausedUntil }: { pausedUntil: string | null }) => {
  return pausedUntil === 'Punished'
    ? 'Your meetings have been paused due to inactivity.'
    : pausedUntil === 'Infinity'
    ? 'Your meetings have been paused indefinitely.'
    : pausedUntil
    ? `Paused until ${moment(pausedUntil).format('MMM Do')}`
    : 'Take a break from meeting new people on Lunchclub. You will still be able to chat with your past matches.';
};

export const getTargetLocaleSubtitle = ({ pausedUntil }: { pausedUntil: string | null }) => {
  return pausedUntil ? 'Paused' : 'Only get introduced to matches from your area.';
};
