import React, { useEffect, Suspense } from 'react';
import styled from 'styled-components/macro';
import { CookiesProvider } from 'react-cookie';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import * as navImages from 'img/navbar-v2';
import * as weeklyImages from 'img/weekly-v2';
import * as profileImages from 'img/profile-v2';

import { RootProvider } from './providers/RootProvider';
import { Authenticate } from './components/shared/Authenticate';
import { CallPage } from './components/call';
import { Chat } from './components/chat';
import { Clubpoints } from './components/clubpoints';
import { Guidelines } from './components/guidelines';
import { Faq } from './components/faq';
import { HomePage } from './components/homepage';
import { Feedback } from './components/feedback';
import { GooglePermissions } from './components/google-permissions';
import { Invite } from './components/invite';
import { InviteTree } from './components/invite-tree';
import { LandingPage } from './components/landing-page';
import { SVGLoader } from './components/shared/loaders/SVGLoader';
import { Meetings } from './components/meetings';
import { NotFound } from './components/not-found';
import { Playground } from './components/playground';
import { Privacy } from './components/privacy';
import { ProfilePage } from './components/profile';
import { PublicProfile } from './components/member/index';
import { RedirectDiscover } from './components/redirect-discover';
import Registration from './components/registration';
import { Settings } from './components/settings';
import { SuperInviters } from './components/super-inviters';
import { TermsOfService } from './components/terms';
import { Unsubscribe } from './components/unsubscribe';
import { Verify } from './components/verify';
import { Weekly } from './components/weekly';
import { Dashboard } from './components/dashboard';
import { getIsMobile, isProduction } from './util/util';
import { DetermineRedirect } from './util/DetermineRedirect';
import { NotifProvider } from './util/notif-context';
import { postActivity } from './util/api';
import { AssetPrefetcher } from './components/shared/AssetPrefetcher';
import { MobileRoot } from './components/mobile-root';
import { PromptForNotifications } from './components/mobile-root/PromptForNotifications';
import { PingServer } from './util/ping-server';
import { isAndroidChrome, isIOSMobile, isSafari } from './components/callv2/utils/browser';
import { BrowserRouterWithReloader } from './components/shared/BrowserRouterWithReloader';
import { BridgeEventHandler } from './components/shared/BridgeEventHandler';
import { BadgeCountSetter } from './components/shared/BadgeCountSetter';
import { LogWithLogRocket } from './util/LogWithLogRocket';
import { PromptForFeedback } from './components/mobile-root/PromptForFeedback';
import { Autopilot } from './components/autopilot';

const PageScrollReset: React.FC = () => {
  const location = useLocation();
  const exceptionURLS = ['/chat'];

  useEffect(() => {
    if (!exceptionURLS.some(x => x === location.pathname)) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return <span />;
};

const App = () => (
  <>
    <PingServer />

    <CookiesProvider>
      <NotifProvider>
        <FullHeightDiv>
          <BrowserRouterWithReloader>
            <LastLocationProvider>
              <RootProvider>
                <LogWithLogRocket />
                <RouterAnalyticsTracking />
                <PageScrollReset />
                {getIsMobile() && (
                  <>
                    <AssetPrefetcher images={{ ...weeklyImages, ...navImages, ...profileImages }} />
                    <BadgeCountSetter />
                    <BridgeEventHandler />
                  </>
                )}
                <Suspense fallback={<SVGLoader />}>
                  <Switch>
                    {!isProduction() && <Route exact path="/playground" component={Playground} />}

                    <Route exact path="/" component={LandingPage} />

                    <Route exact path="/verify" component={Verify} />

                    <Redirect from="/stayhungry" to="/?invite_code=stayhungry" />

                    <Route exact path="/community-guidelines" component={Guidelines} />

                    <Route exact path="/privacy" component={Privacy} />

                    <Redirect from="/year-in-review-20" to="/?invite_code=yearinreview20" />

                    <Route exact path="/terms-of-service" component={TermsOfService} />

                    <Route exact path="/unsubscribe" component={Unsubscribe} />

                    <Route path="/registration" component={Registration} />

                    <Route exact path="/login">
                      <Authenticate>
                        <DetermineRedirect />
                      </Authenticate>
                    </Route>

                    <Route exact path="/home">
                      <Authenticate>
                        <HomePage />
                      </Authenticate>
                    </Route>

                    <Route exact path="/discover">
                      <Authenticate>
                        <RedirectDiscover />
                      </Authenticate>
                    </Route>

                    <Route exact path="/feedback">
                      <Authenticate>
                        <Feedback />
                      </Authenticate>
                    </Route>

                    <Route exact path="/profile">
                      <Authenticate>
                        <ProfilePage />
                      </Authenticate>
                    </Route>

                    <Route exact path="/settings">
                      <Authenticate>
                        <Settings />
                      </Authenticate>
                    </Route>

                    <Route exact path="/meetings">
                      <Authenticate>
                        <Meetings />
                      </Authenticate>
                    </Route>

                    <Route exact path="/weekly">
                      <Authenticate>
                        <Weekly />
                      </Authenticate>
                    </Route>

                    <Route path="/invite">
                      <Authenticate>
                        <Invite />
                      </Authenticate>
                    </Route>

                    <Route path="/member/:id">
                      <Authenticate>
                        <PublicProfile />
                      </Authenticate>
                    </Route>

                    <Route path={['/call/:id', '/call']}>
                      <Authenticate>
                        <CallPage />
                      </Authenticate>
                    </Route>

                    <Route path={['/callv2/:id', '/callv2']}>
                      <Authenticate>
                        <CallPage />
                      </Authenticate>
                    </Route>

                    <Route exact path="/clubpoints">
                      <Authenticate>
                        <Clubpoints />
                      </Authenticate>
                    </Route>

                    <Route exact path="/faq">
                      <Faq />
                    </Route>

                    <Route exact path="/google">
                      <Authenticate>
                        <GooglePermissions />
                      </Authenticate>
                    </Route>
                    {/*
                    <Route exact path="/connections">
                      <Authenticate>
                        <Connections />
                      </Authenticate>
                    </Route> */}

                    <Route path={['/chat/:id', '/chat']}>
                      <Authenticate>
                        <Chat />
                      </Authenticate>
                    </Route>

                    <Route exact path="/invite-tree">
                      <Authenticate>
                        <InviteTree />
                      </Authenticate>
                    </Route>

                    <Route exact path="/superinviters">
                      <Authenticate>
                        <SuperInviters />
                      </Authenticate>
                    </Route>

                    <Route exact path="/dashboard">
                      <Authenticate>
                        <Dashboard />
                      </Authenticate>
                    </Route>

                    <Route exact path="/autopilot">
                      <Authenticate>
                        <Autopilot />
                      </Authenticate>
                    </Route>

                    <Route exact path="/mobile-root" component={MobileRoot} />
                    <Route
                      exact
                      path="/mobile-root/notifications"
                      component={PromptForNotifications}
                    />
                    <Route exact path="/mobile-root/feedback" component={PromptForFeedback} />

                    <Redirect path="/validate" to="/" />

                    {/* Firesides are currently deactivated */}
                    <Redirect from="/fireside" to="/" />
                    <Redirect from="/fireside-*" to="/" />
                    <Redirect from="/firesides" to="/" />

                    {/* There is no waitlist and we support worldwide locales */}
                    <Redirect from="/waitlist" to="/registration" />

                    {/* Miami event was a 1 day event in summer 2021 */}
                    <Redirect from="/miami" to="/" />

                    {/* Always ensure that this is the last route listed. */}
                    <Route exact path="/404" component={NotFound} />
                    <Redirect to="/404" />
                  </Switch>
                </Suspense>
              </RootProvider>
            </LastLocationProvider>
          </BrowserRouterWithReloader>
        </FullHeightDiv>
      </NotifProvider>
    </CookiesProvider>
  </>
);

// Note: This patch subtracts the height of the Chrome and Safari nav bars, which are
// included in "100vh" so the page always scrolls up and down by ~60px. The implementation
// of 100vh is very unfortunate.
// https://dev.to/peiche/100vh-behavior-on-chrome-2hm8
//
const isChromeDevtools = getIsMobile() && window.navigator.platform.includes('Mac');
export const isSafariIos15 = () => {
  const agent = window.navigator.userAgent;
  const start = agent.indexOf('OS ');
  if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
    return window.Number(agent.substr(start + 3, 3).replace('_', '.')) === 15 && isSafari();
  }
  return false;
};

const FullHeightDiv = styled.div`
  position: relative;
  ${!getIsMobile()
    ? // Desktop
      'height: 100vh;'
    : window.bridge
    ? // iOS App: no toolbars!
      'height: 100vh;'
    : isAndroidChrome() && !isChromeDevtools
    ? // Chrome Android: toolbars need explicit adjustment but are consistent
      'height: calc(100vh - 56px)'
    : isSafariIos15()
    ? // iOS 15
      'height: 100vh;'
    : isIOSMobile() && !isChromeDevtools
    ? // Safari iOS: top toolbar and SOMETIMES bottom toolbar (on 12 Pro but not 8 Plus) are included in 100vh,
      // so it's not clear how much should be subtracted. Thankfully window.innerHeight is correct.
      `height: ${window.innerHeight}px;`
    : 'height: 100vh;'}
`;

const RouterAnalyticsTracking: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    postActivity(location.pathname, location.search);
  }, [location]);

  return null;
};

export default App;
