import React, { createContext, useContext, useState, useEffect } from 'react';

import { useWindowSize } from 'js/util/custom-hooks';
import { getIsMobile } from 'js/util/util';
import { useParticipantsContext } from 'js/components/callv2/contexts/ParticipantsContext';

export type SettingsView = 'devices' | 'quality' | null;

export type SidebarView = 'profile' | 'chat' | null;

interface ContextValue {
  // setSettingsView(mode: SettingsView): void;
  setShowDeviceInUseModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDeviceNotFoundModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowUnblockModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSidebarView: React.Dispatch<React.SetStateAction<SidebarView>>;
  setIsBannerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFeedbackModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSettingsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCallExitModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowScreenSharePermissionModal: React.Dispatch<React.SetStateAction<boolean>>;
  toggleControlsVisibility: () => void;

  // settingsView: SettingsView;
  showDeviceInUseModal: boolean;
  showDeviceNotFoundModal: boolean;
  showUnblockModal: boolean;
  sidebarView: SidebarView;
  isBannerVisible: boolean;
  areControlsVisible: boolean;
  showFeedbackModal: boolean;
  showSettingsModal: boolean;
  showCallExitModal: boolean;
  showScreenSharePermissionModal: boolean;
  fullScreenWidth: number;
  fullScreenHeight: number;
}

// eslint-disable-next-line no-console
const noop = () => console.error('No parent UIStateContextProvider found!');

export const UIStateContext = createContext<ContextValue>({
  // setSettingsView: noop,
  setShowDeviceInUseModal: noop,
  setShowDeviceNotFoundModal: noop,
  setShowUnblockModal: noop,
  setSidebarView: noop,
  setIsBannerVisible: noop,
  setShowFeedbackModal: noop,
  setShowSettingsModal: noop,
  setShowCallExitModal: noop,
  setShowScreenSharePermissionModal: noop,
  toggleControlsVisibility: noop,

  // settingsView: null,
  showDeviceInUseModal: false,
  showDeviceNotFoundModal: false,
  showUnblockModal: false,
  sidebarView: null,
  isBannerVisible: false,
  showFeedbackModal: false,
  showSettingsModal: false,
  showCallExitModal: false,
  showScreenSharePermissionModal: false,
  areControlsVisible: false,
  fullScreenWidth: 0,
  fullScreenHeight: 0,
});

export const UIStateContextProvider: React.FC = ({ children }) => {
  // const [settingsView, setSettingsView] = useState<SettingsView>(null);
  const [showDeviceNotFoundModal, setShowDeviceNotFoundModal] = useState(false);
  const [showDeviceInUseModal, setShowDeviceInUseModal] = useState(false);
  const [showUnblockModal, setShowUnblockModal] = useState(false);
  const [sidebarView, setSidebarView] = useState<SidebarView>(null);
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showCallExitModal, setShowCallExitModal] = useState(false);
  const [showScreenSharePermissionModal, setShowScreenSharePermissionModal] = useState(false);
  const [areMobileControlsVisible, setAreMobileControlsVisible] = useState(true);
  const [allowExitModal, setAllowExitModal] = useState(false);
  const [fullScreenWidth, setFullScreenWidth] = useState(0);
  const [fullScreenHeight, setFullScreenHeight] = useState(0);

  const isMobile = getIsMobile();
  const [windowWidth, windowHeight] = useWindowSize();
  const { screen, doubleJoin } = useParticipantsContext();

  useEffect(() => {
    const id = setTimeout(() => {
      setAllowExitModal(true);
    }, 60 * 1000);
    return () => clearTimeout(id);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      const width = windowWidth - 16 - 16 - (sidebarView ? 420 + 16 : 0);
      const height = windowHeight - (screen ? 88 + 24 : 0) - (isBannerVisible ? 56 : 0);
      setFullScreenWidth(width);
      setFullScreenHeight(height);
    } else {
      setFullScreenWidth(windowWidth);
      setFullScreenHeight(windowHeight);
    }
  }, [windowWidth, windowHeight, isMobile, isBannerVisible, sidebarView, screen?.isScreenshare]);

  // Hide controls on mobile 5s after app mounts
  useEffect(() => {
    if (isMobile && areMobileControlsVisible) {
      const id = setTimeout(() => {
        setAreMobileControlsVisible(false);
      }, 5000);
      return () => clearTimeout(id);
    }
    return () => undefined;
  }, [isMobile, areMobileControlsVisible]);

  const toggleControlsVisibility = () => {
    if (isMobile) setAreMobileControlsVisible(prev => !prev);
  };

  useEffect(() => {
    if (doubleJoin !== undefined) {
      if (allowExitModal || doubleJoin === false) {
        setShowCallExitModal(doubleJoin);
      }
    }
  }, [allowExitModal, doubleJoin]);

  return (
    <UIStateContext.Provider
      value={{
        setShowDeviceInUseModal,
        setShowDeviceNotFoundModal,
        setShowUnblockModal,
        setSidebarView,
        setIsBannerVisible,
        setShowSettingsModal,
        setShowFeedbackModal,
        setShowCallExitModal,
        setShowScreenSharePermissionModal,
        toggleControlsVisibility,

        showDeviceInUseModal,
        showDeviceNotFoundModal,
        showUnblockModal,
        sidebarView,
        isBannerVisible,
        showFeedbackModal,
        showSettingsModal,
        showCallExitModal,
        showScreenSharePermissionModal,
        areControlsVisible: !isMobile || areMobileControlsVisible,
        fullScreenWidth,
        fullScreenHeight,
      }}
    >
      {children}
    </UIStateContext.Provider>
  );
};

export const useUIStateContext = () => useContext(UIStateContext);
