import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

import { colors, globalTransitionSettings, margins, media, Text } from 'css/css';

import { LOGIN_FAILED_MESSAGE } from 'js/components/landing-page/components/RegisterEmail';
import { GoogleSignIn } from 'js/components/shared/GoogleSignIn';
import { useUserContextProvider } from 'js/providers/UserProvider';
import { postTestActivity } from 'js/util/api';
import { COMMUNITY_CODE_TO_ID, getParameterByName } from 'js/util/util';
import { ORGANIZATION, ORG_INVITE_CODE } from 'js/util/organizations';
import { useNotifContext } from 'js/util/notif-context';

import googleIcon from 'img/settings/google.svg';

interface Props {
  urlParams?: string;
  isInvalid?: boolean;
}

export const RegisterGoogle: React.FC<Props> = ({ urlParams, isInvalid }) => {
  const { fetchUser, updateUserAvailability } = useUserContextProvider();
  const history = useHistory();
  const { showNotif } = useNotifContext();

  const orgId = Number(getParameterByName('orgId')) || ORGANIZATION.LUNCHCLUB;
  const inviteCode = getParameterByName('invite_code');
  const commName: string | null =
    getParameterByName('commId') || (inviteCode === 'trends' ? 'trends' : null);

  const onGoogleSubmit = (res: {
    status: number;
    getJson: { skip: boolean; auth_token: string; is_verified?: boolean };
  }) => {
    if (res.status === 200 && res.getJson.is_verified) {
      Promise.all([fetchUser(), updateUserAvailability()]).then(() => history.push('/login'));
    } else if (res.status === 200) {
      postTestActivity('landing-tests-google', urlParams || '');
      const selectedOrg: number = commName ? COMMUNITY_CODE_TO_ID[commName] : orgId;
      const orgCode = ORG_INVITE_CODE[selectedOrg];
      const inviteCodeStr = inviteCode || orgCode ? `&invite_code=${inviteCode || orgCode}` : '';
      history.push(
        `registration/hello?orgId=${selectedOrg}${inviteCodeStr}&skip_verification=true&ref=email-signup`,
      );
    } else if (res.status === 401)
      showNotif({
        message: LOGIN_FAILED_MESSAGE,
        level: 'error',
      });
  };

  if (isInvalid) {
    return (
      <SigninButton isInvalid={isInvalid}>
        <GoogleSigninIcon src={googleIcon} alt="Sign up with Google" />
        <Text color={colors.primaryMain}>Sign up with Google</Text>
      </SigninButton>
    );
  }

  return (
    <GoogleSignIn
      isNewUser
      isRegistering
      onLogIn={onGoogleSubmit}
      customButton={
        <SigninButton isInvalid={isInvalid}>
          <GoogleSigninIcon src={googleIcon} alt="Sign up with Google" />
          <Text color={colors.primaryMain}>Sign up with Google</Text>
        </SigninButton>
      }
    />
  );
};

const GoogleSigninIcon = styled.img`
  margin: 0px ${margins.size3} 0px 0px;
  height: 100%;
  width: 18px;
  height: 18px;
`;

const SigninButton = styled.button<{ isInvalid?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px ${margins.size3};
  height: 46px;
  border: 1px solid ${colors.primaryMain};
  border-radius: 5px;
  cursor: ${p => (p.isInvalid ? 'default' : 'pointer')};
  transition: ${globalTransitionSettings};
  background-color: transparent;
  &:hover {
    background-color: ${colors.greyLight};
  }

  ${media.mobile} {
    width: 100%;
    justify-content: center;
  }
`;
