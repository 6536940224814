import {
  iconAskAboutSvg,
  iconLearnSvg,
  iconLikeToLearnSvg,
  iconSideHustleSvg,
  iconTopOfMindSvg,
} from 'img/profile-v2';
import AskMe from 'img/profile/conversation-starters/ask.svg';
import JustLearned from 'img/profile/conversation-starters/just-learned.svg';
import NewProject from 'img/profile/conversation-starters/new-project.svg';
import TopOfMind from 'img/profile/conversation-starters/mind.svg';
import WantToLearn from 'img/profile/conversation-starters/learn.svg';

const CONVERSATIONS = {
  ASKMEABOUT: 0,
  LIKETOLEARN: 1,
  JUSTLEARNED: 2,
  TOPOFMIND: 3,
  SIDEHUSTLE: 4,
};

type ConversationVariableNames =
  | 'askMeAbout'
  | 'likeToLearn'
  | 'justLearned'
  | 'topOfMind'
  | 'sideHustle';

export interface Conversation {
  title: string;
  value: string;
  icon: string;
  variableName: ConversationVariableNames;
}

export interface ConversationDict {
  [key: number]: Conversation;
}

export interface ConversationState {
  askMeAbout: string;
  likeToLearn: string;
  justLearned: string;
  topOfMind: string;
  sideHustle: string;
}

export const createConversationObj = ({
  askMeAbout,
  likeToLearn,
  justLearned,
  topOfMind,
  sideHustle,
}: ConversationState): ConversationDict => ({
  [CONVERSATIONS.ASKMEABOUT]: {
    title: 'Ask me about',
    value: askMeAbout,
    icon: AskMe,
    variableName: 'askMeAbout',
  },
  [CONVERSATIONS.LIKETOLEARN]: {
    title: 'I’d like to learn about',
    value: likeToLearn,
    icon: WantToLearn,
    variableName: 'likeToLearn',
  },
  [CONVERSATIONS.JUSTLEARNED]: {
    title: 'Something I just learned',
    value: justLearned,
    icon: JustLearned,
    variableName: 'justLearned',
  },
  [CONVERSATIONS.TOPOFMIND]: {
    title: 'Top of mind for me',
    value: topOfMind,
    icon: TopOfMind,
    variableName: 'topOfMind',
  },
  [CONVERSATIONS.SIDEHUSTLE]: {
    title: 'Working on a side project',
    value: sideHustle,
    icon: NewProject,
    variableName: 'sideHustle',
  },
});

export const ConversationStarterConfigMobile = [
  {
    key: 'ask_me_about',
    title: 'Ask me about',
    titleThirdParty: 'Ask NAME about',
    icon: iconAskAboutSvg,
  },
  {
    key: 'like_to_learn',
    title: "I'd like to learn about",
    titleThirdParty: 'Likes to learn about',
    icon: iconLikeToLearnSvg,
  },
  {
    key: 'top_of_mind',
    title: 'Top of mind for me',
    titleThirdParty: 'Top of mind',
    icon: iconTopOfMindSvg,
  },
  {
    key: 'just_learned',
    title: 'Something I just learned',
    titleThirdParty: 'Just learned',
    icon: iconLearnSvg,
  },
  {
    key: 'side_hustle',
    title: 'Working on a side project',
    titleThirdParty: 'Side hustle',
    icon: iconSideHustleSvg,
  },
] as const;

export const NEW_YEAR_GOALS_LIMIT = 10;
