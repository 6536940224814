import React from 'react';
import SVG from 'react-inlinesvg';
import moment from 'moment';
import styled from 'styled-components/macro';

import { margins, FlexColumn, media, Text, colors, FlexRow, Heading3 } from 'css/css';

import { consolidateTimeslots, getIsMobile } from 'js/util/util';

import clock from 'img/registration-v2/clock.svg';
import repeatArrow from 'img/registration-v2/repeat-arrow.svg';
import calendarCheck from 'img/registration-v2/date-check.svg';
import calendarCheckYellow from 'img/weekly/calendar-check-yellow.svg';

import { CadenceType } from '../constants';

interface AvailabilitiesBoxProps {
  selectedTimeslots: string[];
  selectedCadence?: CadenceType;
  is24Hour: boolean;
}

export const SelectedAvailabilitiesBox: React.FC<AvailabilitiesBoxProps> = ({
  selectedTimeslots,
  selectedCadence,
  is24Hour,
}) => {
  const consolidatedTimeslots = consolidateTimeslots(selectedTimeslots);
  const isMobile = getIsMobile();
  return (
    <AvailabilityContainer $extraPadding={selectedCadence !== undefined}>
      {Object.keys(consolidatedTimeslots).map(day => {
        return (
          <TimesRow key={day}>
            <FlexRow style={{ flex: '1 1 15%', alignSelf: 'flex-start' }}>
              {selectedCadence !== undefined && (
                <img src={isMobile ? calendarCheck : calendarCheckYellow} alt="Check" />
              )}
              <Heading3
                color={isMobile ? colors.whiteMain : colors.blackMid}
                key={day}
                style={{ marginLeft: margins.size2 }}
              >
                {moment(consolidatedTimeslots[day][0]).format('dddd')}
              </Heading3>
            </FlexRow>
            <FlexRow alignItems="flex-start" style={{ flex: 2 }}>
              <SVG
                color={colors.blackLight}
                src={clock}
                alt="Clock"
                style={{ marginRight: margins.size1, marginTop: '2px' }}
              />
              <TimesGrid>
                {consolidatedTimeslots[day].map((t: string) => (
                  <Text
                    color={isMobile ? colors.whiteMain : colors.blackMid}
                    style={{ textAlign: 'left' }}
                  >
                    {moment(t).format(is24Hour ? 'H:mm' : 'h:mm a')}
                  </Text>
                ))}
              </TimesGrid>
            </FlexRow>
          </TimesRow>
        );
      })}
      {selectedCadence !== undefined && (
        <FlexRow style={{ marginTop: margins.size4 }}>
          <SVG
            src={repeatArrow}
            alt="Repeat"
            color={isMobile ? colors.primaryFaded : colors.tertiary3Main}
          />
          <Heading3 color={isMobile ? colors.primaryFaded : colors.tertiary3Main}>
            {selectedCadence.title}
          </Heading3>
        </FlexRow>
      )}
    </AvailabilityContainer>
  );
};

const AvailabilityContainer = styled(FlexColumn)<{ $extraPadding: boolean }>`
  width: 100%;
  padding: ${p => (p.$extraPadding ? `${margins.size5} ${margins.size3}` : margins.size3)};
  text-align: center;
  background-color: ${colors.blackMid};
  border-radius: 10px;

  ${media.desktop} {
    background-color: ${colors.greyLight};
  }
`;

const TimesRow = styled(FlexRow)`
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: ${margins.size2};
  }
`;

const TimesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;
  margin-right: -${margins.size2};
`;
