import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { margins, FlexColumn, FlexRow, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { ProfilePage } from 'js/components/profile';
import { getDashboardData } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';

export const Dashboard = () => {
  const [data, setData] = useState<any>([]);
  const [index, setIndex] = useState<number>(0);

  const loadData = async () => {
    const res = await getDashboardData();
    if (res.status === 200) {
      setData((prev: any) => [...prev, ...res.getJson.data]);
    }
  };

  useMountEffect(() => {
    loadData();
  });

  if (data.length === 0) return <SVGLoader />;
  const matchData = data[index];

  return (
    <Container>
      <CenterColumn>
        <Text>
          Viewing {index + 1} out of {data.length}
        </Text>
        <Text>Match ID: {matchData.match_id} </Text>
        <Text>{matchData.time_slot}</Text>
        <Text>Type: {matchData.match_type}</Text>
        <Text>Call Length: {Math.round(matchData.call_length / 60)} min</Text>
        <FlexRow>
          <StyledButton onClick={() => setIndex(x => Math.max(0, x - 1))}>Previous</StyledButton>
          <StyledButton onClick={loadData}>Load more</StyledButton>
          <StyledButton onClick={() => setIndex(x => Math.min(data.length - 1, x + 1))}>
            Next
          </StyledButton>
        </FlexRow>
      </CenterColumn>
      <ProfilesContainer>
        <ProfileColumn>
          <Text style={{ marginBottom: margins.size2 }}>
            exp: {matchData.user_exp}, tech: {matchData.user_tech}, notes: {matchData.user_notes},
            feedback: {matchData.user_feedback}
          </Text>
          <ProfilePage passedUser={matchData.user} />
        </ProfileColumn>
        <ProfileColumn>
          <Text style={{ marginBottom: margins.size2 }}>
            exp: {matchData.match_user_exp}, tech: {matchData.match_user_tech}, notes:{' '}
            {matchData.match_user_notes}, feedback: {matchData.match_user_feedback}
          </Text>
          <ProfilePage passedUser={matchData.match_user} />
        </ProfileColumn>
      </ProfilesContainer>
    </Container>
  );
};

const Container = styled(FlexColumn)`
  justify-content: center;
  align-items: center;
  padding: ${margins.size3} 32px;
`;

const CenterColumn = styled(FlexColumn)`
  justify-content: flex-start;
  height: 100%;
  margin: ${margins.size3};
`;

const ProfilesContainer = styled(FlexRow)`
  align-items: flex-start;
`;

const ProfileColumn = styled(FlexColumn)`
  justify-content: flex-start;
  height: 100%;
  margin: ${margins.size3};
  flex: 1;
  max-width: 600px;
`;

const StyledButton = styled(Button)`
  margin: ${margins.size2};
`;
