import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import 'intersection-observer';
import smoothscroll from 'smoothscroll-polyfill';
import 'es7-object-polyfill';

import './css/bootstrap.css';
import './css/fonts.css';

import App from './js/app';
import { isProduction } from './js/util/util';

// Polyfill invocation
smoothscroll.polyfill();

if (isProduction()) {
  Bugsnag.start({
    apiKey: '287ff9a156df4b6e2b237e8d055ff0cc',
    plugins: [new BugsnagPluginReact()],
  });

  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
  ReactDOM.render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>,
    document.getElementById('root'),
  );
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
}
