import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';

import { FlexColumn, Heading1, margins, media, Heading2, colors } from 'css/css';

import { isAndroidChrome, isIOSMobile, isSafari } from 'js/components/callv2/utils/browser';
import { Modal } from 'js/components/shared/NewModal';
import { Button } from 'js/components/shared/Button';

import videoOffIcon from 'img/call/video-off.svg';
import audioOffIcon from 'img/call/microphone-off.svg';

import { useBrowser } from '../utils/useBrowser';

import {
  MobileChromeUnblockMessage,
  SafariOrIOSMobileUnblockMessage,
  GenericUnblockMessage,
} from './UnblockMessages';

type Props = {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
};

export const UnblockPermissionsModal: React.FC<Props> = ({ showModal, setShowModal }) => {
  const { name: browserName, icon: browserIcon } = useBrowser();

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleGrantAccessClick = () => {
    window.location.reload();
  };

  return (
    <Modal showModal={showModal} handleClose={handleModalClose} hideScrollbar>
      <FlexColumn>
        <div style={{ display: 'flex' }}>
          <StyledIcon alt="" aria-hidden="true" src={videoOffIcon} />
          <div style={{ margin: `0 ${margins.size2}` }} />
          <StyledIcon alt="" aria-hidden="true" src={audioOffIcon} />
        </div>
        <Heading1 style={{ margin: `${margins.size4} 0`, textAlign: 'center' }}>
          Your camera or microphone is blocked
        </Heading1>
        <Heading2 style={{ marginBottom: margins.size4 }}>
          Lunchclub needs access to both your camera and microphone
        </Heading2>
        {isSafari() || isIOSMobile() ? (
          <SafariOrIOSMobileUnblockMessage />
        ) : isAndroidChrome(browserName) ? (
          <MobileChromeUnblockMessage browserIcon={browserIcon} />
        ) : (
          <GenericUnblockMessage browserName={browserName} browserIcon={browserIcon} />
        )}
        <div style={{ margin: `${margins.size2} 0` }} />
        {isSafari() || isIOSMobile() ? (
          <div style={{ display: 'flex' }}>
            <Button secondary onClick={handleModalClose}>
              Close
            </Button>
            <div style={{ margin: `0 ${margins.size2}` }} />
            <Button onClick={handleGrantAccessClick}>Grant Access</Button>
          </div>
        ) : (
          <Button onClick={handleModalClose}>Dismiss</Button>
        )}
      </FlexColumn>
    </Modal>
  );
};

const StyledIcon = styled(SVG)`
  width: ${margins.size6};
  height: ${margins.size6};
  margin: ${margins.size4} 0;
  color: ${colors.blackMain};

  ${media.mobile} {
    width: ${margins.size5};
    height: ${margins.size5};
    margin: ${margins.size2} 0;
  }
`;
