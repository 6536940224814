import React, { memo } from 'react';

import { Participant } from 'js/components/callv2/contexts/ParticipantsContext/types';
import { NetworkState } from 'js/components/callv2/hooks/useNetworkState';
import { Avatar } from 'js/components/shared/Avatar';

import { TileInfo } from './TileInfo';
import { TileVideo } from './TileVideo';

export interface TileContentProps {
  network?: NetworkState;
  participant: Participant;
  avatarImg?: string;
  isLocal: boolean;
  radius?: boolean;
}

export const TileContent = memo(
  ({ participant, network, avatarImg, isLocal, radius }: TileContentProps) => (
    <>
      <TileVideo
        participant={participant}
        isLocal={isLocal}
        disabled={participant.isCamMuted || network === 'very-low'}
        radius={radius}
      />
      {participant.isCamMuted && (
        <div>
          <Avatar src={avatarImg} size4 />
        </div>
      )}
      <TileInfo muted={!!participant.isMicMuted} />
    </>
  ),
);
