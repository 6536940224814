import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { Heading2, Text, margins, colors, globalTransitionSettings } from 'css/css';

import { postCallFeedback } from 'js/util/api';
import { Button } from 'js/components/shared/Button';
import { TextInput } from 'js/components/shared/TextInput';
import { useCallV2Context } from 'js/components/callv2/contexts/CallContext';
import { useNotifContext } from 'js/util/notif-context';
import { useUIStateContext } from 'js/components/callv2/contexts/UIStateContext';

const OTHER_OPTION_INDEX = 3;

export const FeedbackModal = () => {
  const { showNotif } = useNotifContext();
  const { setShowFeedbackModal, showFeedbackModal } = useUIStateContext();

  const [options, setOptions] = useState([
    { description: 'My video is not connecting', isSelected: false },
    { description: 'My audio is having problems', isSelected: false },
    { description: 'I cannot connect with my match', isSelected: false },
    { description: 'Other', isSelected: false },
  ]);
  const [userInput, setUserInput] = useState('');
  const { matchCode } = useCallV2Context();

  const selectOption = (i: number) =>
    setOptions(prev => prev.map((o, j) => (j === i ? { ...o, isSelected: !o.isSelected } : o)));

  const handleClose = () => setShowFeedbackModal(false);
  const handleSubmit = async () => {
    const res = await postCallFeedback({
      matchCode,
      videoProblem: options[0].isSelected,
      audioProblem: options[1].isSelected,
      matchProblem: options[2].isSelected,
      problemExplanation: userInput,
    });
    if (res.ok) {
      showNotif({
        message:
          'Your feedback has been submitted. If issues persist, we suggest you email your match to find an alternative.',
        level: 'success',
      });
    }
    setOptions(prev => prev.map(o => ({ ...o, isSelected: false })));
    setUserInput('');
    handleClose();
  };

  return (
    <Backdrop isVisible={showFeedbackModal} onClick={handleClose}>
      <Content isVisible={showFeedbackModal} onClick={e => e.stopPropagation()}>
        <Heading2 style={{ marginBottom: margins.size3 }}>Having problems? Tell us more!</Heading2>
        <Options>
          {options.map((o, i) => (
            <Option key={o.description} isSelected={o.isSelected} onClick={() => selectOption(i)}>
              {o.description}
            </Option>
          ))}
          {options[OTHER_OPTION_INDEX].isSelected && (
            <TextInput
              value={userInput}
              onChange={setUserInput}
              placeholder="Please describe your problem"
              maxWidth="100%"
            />
          )}
        </Options>
        <Text style={{ marginBottom: margins.size3 }}>
          Note: Video calls are not being recorded and we rely on your feedback as a way to improve
          this platform.
        </Text>
        <Button
          onClick={handleSubmit}
          invalid={
            !options[0].isSelected &&
            !options[1].isSelected &&
            !options[2].isSelected &&
            userInput.length === 0
          }
          invalidMessage="Please choose an option or describe your problem in the other section."
          style={{ marginRight: margins.size1 }}
        >
          Submit feedback
        </Button>
        <Button onClick={handleClose} secondary>
          Cancel
        </Button>
      </Content>
    </Backdrop>
  );
};

const Backdrop = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: ${globalTransitionSettings};
  opacity: ${p => (p.isVisible ? 1 : 0)};
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
`;

const Content = styled.div<{ isVisible: boolean }>`
  width: 600px;
  background-color: ${colors.whiteMain};
  padding: ${margins.size4};
  border-radius: 5px;
  text-align: start;

  transition: ${globalTransitionSettings};
  transform: translateY(${p => (p.isVisible ? 0 : '-50px')});
`;

const Option = styled(Text)<{ isSelected: boolean }>`
  padding: ${margins.size2};
  border-bottom: 1px solid ${colors.greyMain};
  color: ${p => p.isSelected && colors.blackMain};
  background-color: ${p => (p.isSelected ? colors.greyMain : colors.whiteMain)};
  transition: ${globalTransitionSettings};

  &:hover {
    cursor: pointer;
    background-color: ${colors.greyMain};
  }
`;

const Options = styled.div`
  margin: ${margins.size3} 0;
  border: 1px solid ${colors.greyMain};
  border-radius: 5px;

  ${Option}:last-child {
    border-bottom: none;
  }
`;
