import React, { useState } from 'react';
import styled from 'styled-components/macro';

import {
  colors,
  margins,
  FlexRow,
  Heading1,
  Text,
  Heading3,
  EditTextArea,
  Link,
  globalTransitionSettings,
  FlexColumn,
} from 'css/css';
import { underlineAnimation } from 'css/animations';

import { useCallV2Context } from 'js/components/callv2/contexts/CallContext';
import { SaveAndCancel } from 'js/components/shared/selectors/SaveAndCancel';
import { TextInput } from 'js/components/shared/TextInput';
import { StringLengthIndicator } from 'js/components/shared/StringLengthIndicator';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { useNotifContext } from 'js/util/notif-context';
import {
  addHttpsToURL,
  MIN_HEADLINE_LENGTH,
  MAX_HEADLINE_LENGTH,
  validateHeadlineLength,
  validateSocial,
  addParameterToURL,
} from 'js/util/util';

import Envelope from 'img/profile/envelope.svg';
import userSvg from 'img/profile/user.svg';
import Location from 'img/profile/location.svg';
import Connections from 'img/profile/connections.svg';

import { Socials } from './Socials';
import { LineBreak } from './shared';

interface Props {
  editing?: boolean;
  passedUser: any;
  save?: () => void;
  cancel?: () => void;
}
export const Profile: React.FC<Props> = ({ editing, passedUser, save, cancel }) => {
  const { updateUser } = useUserContextProvider();
  const { showNotif } = useNotifContext();

  const user = useUser();
  const selectedUser = passedUser || user;
  const {
    first_name: firstName,
    last_name: lastName,
    city,
    title,
    company,
    headline,
    connections,
    inviter,
  } = selectedUser;
  const [editedUser, setEditedUser] = useState(selectedUser);
  const [savingUser, setSavingUser] = useState(false);
  const { hasContext } = useCallV2Context();

  if (editing) {
    const updateEditingUser = (field: any, value: any) => {
      const currentEdit = { ...editedUser };
      currentEdit[field] = value;
      setEditedUser(currentEdit);
    };

    const cancelUpdates = () => {
      if (cancel) {
        setEditedUser(selectedUser);
        cancel();
      }
    };

    const sendNotif = (text: any, success = true) =>
      showNotif({ message: text, level: success ? 'success' : 'error' });

    const validSocials = () => {
      if (editedUser.twitter_url && !validateSocial(editedUser.twitter_url, 'twitter')) {
        sendNotif(`Invalid link for Twitter`, false);
        return 0;
      }
      if (editedUser.linkedin_url && !validateSocial(editedUser.linkedin_url, 'linkedin')) {
        sendNotif(`Invalid link for LinkedIn`, false);
        return 0;
      }
      if (editedUser.angellist_url && !validateSocial(editedUser.angellist_url, 'angel')) {
        sendNotif(`Invalid link for Angel List`, false);
        return 0;
      }
      if (editedUser.github_url && !validateSocial(editedUser.github_url, 'github')) {
        sendNotif(`Invalid link for GitHub`, false);
        return 0;
      }
      if (editedUser.instagram_url && !validateSocial(editedUser.instagram_url, 'instagram')) {
        sendNotif(`Invalid link for Instagram`, false);
        return 0;
      }
      return 1;
    };

    const createSocials = () => {
      const newSocials: { [key: string]: string } = {};
      [
        'twitter_url',
        'linkedin_url',
        'angellist_url',
        'github_url',
        'instagram_url',
        'personal_url',
      ].forEach(key => {
        if (editedUser[key]) newSocials[key] = addHttpsToURL(editedUser[key]);
      });

      return newSocials;
    };

    const saveUpdates = () => {
      if (!save) return;
      if (savingUser) return;
      if (!editedUser.first_name.trim().length || !editedUser.last_name.trim().length) {
        sendNotif(`Please enter a valid first and last name`, false);
        return;
      }
      if (!validateHeadlineLength(editedUser.headline.length, 0)) {
        sendNotif(`Please make sure your introduction has an appropriate length`, false);
        return;
      }

      if (!validSocials()) return;

      setSavingUser(true);
      updateUser({
        firstName: editedUser.first_name,
        lastName: editedUser.last_name,
        title: editedUser.title,
        company: editedUser.company,
        headline: editedUser.headline,
        profileUrls: createSocials(),
      }).then(() => {
        setSavingUser(false);
        save();
      });
    };

    return (
      <>
        <div style={{ marginBottom: margins.size4 }}>
          <FlexRow justifyContent="space-between" style={{ marginBottom: margins.size3 }}>
            <ProfileRow
              title="First name"
              value={editedUser.first_name}
              editValue={(e: any) => updateEditingUser('first_name', e)}
            />

            <ProfileRow
              title="Last name"
              value={editedUser.last_name}
              editValue={(e: any) => updateEditingUser('last_name', e)}
              leftMargin
            />
          </FlexRow>

          <FlexRow justifyContent="space-between">
            <ProfileRow
              title="Role"
              value={editedUser.title}
              editValue={(e: any) => updateEditingUser('title', e)}
            />

            <ProfileRow
              title="Company"
              value={editedUser.company}
              editValue={(e: any) => updateEditingUser('company', e)}
              leftMargin
            />
          </FlexRow>
        </div>

        <FlexRow justifyContent="space-between" style={{ marginBottom: margins.size1 }}>
          <Heading3>Introduction</Heading3>
        </FlexRow>

        <EditTextArea
          style={{ backgroundColor: colors.greyLight, border: 'none' }}
          value={editedUser.headline}
          onChange={(e: any) => updateEditingUser('headline', e.target.value)}
        />
        <StringLengthIndicator
          minLength={MIN_HEADLINE_LENGTH}
          maxLength={MAX_HEADLINE_LENGTH}
          string={editedUser.headline}
        />

        <Heading3>Links</Heading3>

        <Socials
          editing
          profiles={{
            twitter: editedUser.twitter_url,
            linkedin: editedUser.linkedin_url,
            github: editedUser.github_url,
            angellist: editedUser.angellist_url,
            instagram: editedUser.instagram_url,
            personal: editedUser.personal_url,
          }}
          editField={updateEditingUser}
        />

        <LineBreak />

        <FlexRow justifyContent="space-between" style={{ marginBottom: margins.size4 }}>
          <FlexRow>
            <ProfileIcon src={Connections} />

            <Heading3 color={colors.primaryMain}>{connections} connections</Heading3>
          </FlexRow>
        </FlexRow>

        <SaveAndCancel
          clickCancel={cancelUpdates}
          clickSave={saveUpdates}
          saveLoading={savingUser}
        />
      </>
    );
  }
  return (
    <>
      <Heading1 style={{ marginBottom: margins.size2 }}>
        {firstName} {lastName}
      </Heading1>

      <Text style={{ marginBottom: margins.size2 }}>{headline}</Text>
      <FlexColumn alignItems="flex-start" style={{ marginBottom: margins.size4 }}>
        <FlexRow $wrap>
          {title && company && (
            <RightMarginRow>
              <ProfileIcon src={userSvg} />
              <Text>
                {title} @{company}
              </Text>
            </RightMarginRow>
          )}

          {city && (
            <RightMarginRow>
              <ProfileIcon src={Location} />
              <Text>{city}</Text>
            </RightMarginRow>
          )}

          {connections > 0 && (
            <RightMarginRow>
              <ProfileIcon src={Connections} />
              <Text color={colors.primaryMain}>{connections} connections</Text>
            </RightMarginRow>
          )}
        </FlexRow>

        {!!inviter && (
          <FlexRow>
            <img src={Envelope} alt="envelope" style={{ marginRight: margins.size2 }} />
            <Text style={{ marginRight: margins.size1 }}>Invited by</Text>
            <Heading3>
              <ProfileLink
                to={addParameterToURL('ref', 'profile', `/member/${inviter.public_id}`)}
                disabled={!inviter.is_active}
                target={hasContext ? '_blank' : '_self'}
                rel={hasContext ? 'noopener noreferrer' : ''}
              >
                {inviter.first_name} {inviter.last_name}
              </ProfileLink>
            </Heading3>
          </FlexRow>
        )}
      </FlexColumn>
      {(!headline || !headline.length) && !passedUser && (
        <Heading3 color={colors.secondaryMid} style={{ marginBottom: margins.size3 }}>
          Click edit to add an introduction, and some links.
        </Heading3>
      )}
    </>
  );
};

const ProfileRow = ({ title, value, editValue, leftMargin }: any) => (
  <div style={{ width: '50%', marginLeft: leftMargin ? margins.size2 : '0px' }}>
    <Heading3 style={{ marginBottom: margins.size1 }}>{title}</Heading3>

    <TextInput height="40px" value={value} onChange={editValue} placeholder={title} />
  </div>
);

const RightMarginRow = styled(FlexRow)`
  margin-right: ${margins.size3};
`;

const ProfileIcon = styled.img`
  height: 12px;
  min-height: 12px;
  margin-right: ${margins.size2};
`;

export const ProfileLink = styled(Link)<{ disabled?: boolean }>`
  color: ${p => (p.disabled ? colors.blackMain : colors.primaryMain)};
  transition: ${globalTransitionSettings};
  pointer-events: ${p => p.disabled && 'none'};
  &:focus {
    color: ${p => (p.disabled ? colors.blackMain : colors.primaryMain)};
  }
  &:not(disabled) {
    ${underlineAnimation}
  }
`;
