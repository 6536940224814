import React from 'react';
import styled from 'styled-components/macro';

import {
  Heading3,
  FlexRow,
  margins,
  borders,
  globalTransitionSettings,
  FlexColumn,
  Text,
  colors,
} from 'css/css';

import { LocaleTimeslotsByDay } from 'js/components/weekly/types';

import { getNumMaxMeetings } from '../constants';

interface Props {
  timeslots: LocaleTimeslotsByDay[];
  selectedTimeslots: string[];
  onClick: (timeslot: string) => void;
  selectedCadence: number;
}

export const TimeslotPickerDesktop: React.FC<Props> = ({
  timeslots,
  selectedTimeslots,
  onClick,
  selectedCadence,
}) => {
  const numMaxMeetings = getNumMaxMeetings(selectedCadence);
  const hasReachedLimit = selectedTimeslots.length >= numMaxMeetings;
  const allTimes: string[] = [];
  timeslots.map(day => day.options.map(option => allTimes.push(option.label)));

  // Array of unique time options sorted by time
  const uniqueAllTimes = allTimes
    .filter((time, i, times) => times.indexOf(time) === i)
    .sort(
      (time1, time2) =>
        Date.parse(`1970/01/01 ${time1.slice(0, -2)} ${time1.slice(-2)}`) -
        Date.parse(`1970/01/01 ${time2.slice(0, -2)} ${time2.slice(-2)}`),
    );

  const searchTime = (time: string, data: LocaleTimeslotsByDay) => {
    return data.options.filter(option => option.label === time)[0];
  };

  return (
    <>
      <Container>
        {timeslots.map(day => {
          return (
            <FlexColumn key={day.id}>
              <TextBox>
                <Heading3>{day.header.slice(0, 3)}</Heading3>
              </TextBox>
              <Tray>
                {uniqueAllTimes.map(time => {
                  const option = searchTime(time, day);
                  if (option) {
                    return (
                      <WeeklyButton
                        role="checkbox"
                        aria-checked={selectedTimeslots.includes(option.option)}
                        key={option.option}
                        $isSelected={selectedTimeslots.includes(option.option)}
                        onClick={() => onClick(option.option)}
                        $isInvalid={hasReachedLimit}
                      >
                        <Text>{option.label}</Text>
                      </WeeklyButton>
                    );
                  }
                  return <FillerWeeklySlot key={time} />;
                })}
              </Tray>
            </FlexColumn>
          );
        })}
      </Container>
    </>
  );
};

const Container = styled(FlexRow)`
  margin: ${margins.size3} 0;
  padding: ${margins.size4} ${margins.size3};
  border: ${borders.standard};
  border-radius: 10px;
  max-width: 800px;
  justify-content: space-between;
  transition: ${globalTransitionSettings};
  gap: ${margins.size3};
`;

const TextBox = styled(FlexColumn)`
  margin-bottom: ${margins.size2};
  width: 100%;
  justify-content: center;
`;

const Tray = styled(FlexColumn)`
  transition: ${globalTransitionSettings};
  width: 100%;
`;

type WeeklyButtonTypes = {
  $isSelected: boolean;
  $isInvalid: boolean;
};
const WeeklyButton = styled.button<WeeklyButtonTypes>`
  transition: ${globalTransitionSettings};
  width: 86px;
  height: 44px;
  border: none;
  margin-bottom: ${margins.size1};
  border-radius: 10px;
  background-color: ${p => (p.$isSelected ? colors.blackMain : colors.whiteMain)};
  cursor: ${p => (p.$isSelected ? 'pointer' : p.$isInvalid ? 'default' : 'pointer')};
  padding: 1px ${margins.size2};
  :focus {
    outline: none;
  }

  ${Text} {
    transition: ${globalTransitionSettings};
    color: ${p =>
      p.$isSelected ? colors.whiteMain : p.$isInvalid ? colors.blackLight : colors.blackMain};
  }

  &:hover {
    ${p => (!p.$isInvalid || p.$isSelected) && 'cursor:pointer'};
    background-color: ${p =>
      p.$isSelected ? colors.blackMain : p.$isInvalid ? colors.whiteMain : `${colors.greyLight}`};
    ${Text} {
      color: ${p =>
        p.$isSelected ? colors.whiteMain : p.$isInvalid ? colors.blackLight : colors.blackMain};
    }
  }
`;

const FillerWeeklySlot = styled.div`
  width: 100%;
  height: 44px;
  border: none;
  margin-bottom: ${margins.size1};
`;
