import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';

import { colors, FlexRow } from 'css/css';

import { useTestBuckets } from 'js/providers/UserProvider';
import {
  getNuxAutopilotHeaderSteps,
  NUX_HEADER_STEPS,
} from 'js/components/registration/mobile/shared';

export enum ProgressBarStyle {
  LIGHT,
  DARK,
}

interface ProgressBarProps {
  currentStepIndex: number;
  style?: ProgressBarStyle;
}

const ProgressBar: React.FunctionComponent<ProgressBarProps> = ({ style, currentStepIndex }) => {
  const barStyle = style ?? ProgressBarStyle.DARK;
  const { cohesiveAutopilotTest } = useTestBuckets();
  const nuxHeaderSteps = cohesiveAutopilotTest ? getNuxAutopilotHeaderSteps() : NUX_HEADER_STEPS;

  return (
    <Container>
      <StepsContainer>
        {Object.values(nuxHeaderSteps).map(({ iconActive, iconInactive }, index) => {
          const active = index === currentStepIndex;
          const color = iconColor(active, barStyle);
          return (
            <div style={{ width: '24px' }}>
              <SVG src={active ? iconActive : iconInactive} color={color} />
            </div>
          );
        })}
      </StepsContainer>
    </Container>
  );
};

const Container = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  flex: 1;
`;

const StepsContainer = styled(FlexRow)`
  justify-content: space-evenly;
  align-items: center;
  height: 50px;
  aspect-ratio: 1;
  flex: 1;
`;

const iconColor = (active: boolean, style: ProgressBarStyle) => {
  if (style === ProgressBarStyle.LIGHT) {
    return active ? colors.whiteMain : colors.blackLight;
  }

  return active ? colors.blackMain : colors.blackLight;
};

export default ProgressBar;
