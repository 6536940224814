import React from 'react';
import styled from 'styled-components/macro';

import { margins, FlexRow, colors, Heading3 } from 'css/css';

import { Button } from 'js/components/shared/Button';

import { ThumbsDownEmoji, ThumbsUpEmoji } from 'img/homepage/feed';

import { QuestionContainer, QuestionHeading } from './Question';

const options = [
  { id: 0, text: 'no', response: false, svgSrc: ThumbsDownEmoji, svgAlt: 'thumbs down' },
  { id: 1, text: 'yes', response: true, svgSrc: ThumbsUpEmoji, svgAlt: 'thumbs up' },
];

export const MeetQuestion = ({
  matchFirstName,
  response,
  setResponse,
}: {
  matchFirstName: string;
  response: boolean | null;
  setResponse: React.Dispatch<React.SetStateAction<boolean | null>>;
}) => (
  <QuestionContainer>
    <QuestionHeading style={{ marginBottom: margins.size2 }}>
      Would you like to meet more people like {matchFirstName}?
    </QuestionHeading>

    <FlexRow style={{ width: '100%', gap: margins.size1 }} justifyContent="center">
      {options.map(option => (
        <MeetQuestionButton
          key={option.id}
          onClick={() => {
            setResponse(option.response);
          }}
          isSelected={response === option.response}
        >
          <img
            src={option.svgSrc}
            alt={option.svgAlt}
            style={{ height: 24, width: 24, display: 'inline-block' }}
          />
          <Heading3
            style={{ display: 'inline-block', marginLeft: margins.size2 }}
            color={colors.primaryMain}
          >
            {option.text}
          </Heading3>
        </MeetQuestionButton>
      ))}
    </FlexRow>
  </QuestionContainer>
);

const MeetQuestionButton = styled(Button)<{ isSelected: boolean }>`
  background-color: ${p => (p.isSelected ? colors.greyMain : colors.greyLight)};
  margin: 0 0;
  width: 93px;
  &:hover {
    background-color: ${p => (p.isSelected ? colors.greyMain : colors.greyLight)};
  }
`;
