import React from 'react';
import styled from 'styled-components/macro';

import { colors, margins, media, FlexColumn, SubText, FlexRow } from 'css/css';

import { Button } from 'js/components/shared/Button';

import { lightbulbIcon } from 'img/homepage/feed';

import { DECK_CARD_CONTAINER_WIDTH } from './styles';

interface Props {
  numCompleted: number;
  total: number;
  isDisplayed: boolean;
  openHeader?: (continuation?: () => void) => void;
  closeHeader?: (continuation?: () => void) => void;
}

export const FeedProgressBar: React.FC<Props> = ({
  numCompleted,
  total,
  isDisplayed,
  openHeader,
  closeHeader,
}) => {
  const progress = numCompleted / total;
  const progressPercent = progress * 100;

  return (
    <>
      <ProgressSection
        onClick={() => {
          if (closeHeader) {
            closeHeader();
          }
        }}
      >
        <ProgressFillContainer>
          <ProgressFill progress={progressPercent} isDisplayed={isDisplayed} />
        </ProgressFillContainer>
        {isDisplayed && (
          <ProgressContainer>
            <SubText>Daily Goal</SubText>
            {openHeader !== undefined && (
              <ExplanationButton
                onClick={(event: React.MouseEvent) => {
                  if (openHeader) {
                    openHeader();
                  }
                  event.stopPropagation();
                }}
              >
                <img src={lightbulbIcon} alt="Open explanation header" />
              </ExplanationButton>
            )}
          </ProgressContainer>
        )}
      </ProgressSection>
    </>
  );
};

const ProgressContainer = styled(FlexRow)`
  padding-left: ${margins.size4};
  padding-right: ${margins.size4};
  justify-content: space-between;
  width: 100%;
`;

const ExplanationButton = styled(Button)`
  margin: 0;
  background: none;
  &:hover {
    background: none;
  }
`;

const ProgressSection = styled(FlexColumn)`
  align-items: center;
  width: ${DECK_CARD_CONTAINER_WIDTH}px;
  background-color: ${colors.whiteMain};
  margin: 0 auto;
  border-radius: 10px 10px 0px 0px;
  ${media.mobile} {
    width: 100%;
  }
`;

const ProgressFillContainer = styled.div`
  height: 8px;
  width: 100%;

  margin-bottom: ${margins.size2};
  ${media.mobile} {
    width: 100%;
    height: 8px;
    margin-top: calc(env(safe-area-inset-top) + ${margins.size2});
    margin-bottom: ${margins.size2};
    padding: 0px ${margins.size4};
    border-radius: 5px;
  }
`;

const ProgressFill = styled.div<{ isDisplayed: boolean; progress: number }>`
  height: 8px;
  width: calc(max(${p => p.progress || 1}%, 17px));
  background-color: ${p => (p.isDisplayed ? colors.secondaryMain : colors.whiteMain)};
  border-radius: ${p => (p.progress < 100 ? '10px 0px 0px 0px' : '10px 10px 0px 0px')};
  transition: width 1s ease-in-out, background-color 0.2s ease-in-out 1s;
  ${media.mobile} {
    border-radius: ${p => (p.progress < 100 ? '10px 0px 0px 10px' : '10px 10px 10px 10px')};
    width: calc(max(${p => p.progress || 1}%, 9px));
  }
`;
