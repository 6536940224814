import React from 'react';

export const AssetPrefetcher: React.FC<{ images: { [imageName: string]: string } }> = ({
  images,
}) => (
  <>
    {Object.entries(images).map(([name, src]) => (
      <link rel="preload" as="image" key={name} href={src} />
    ))}
  </>
);
