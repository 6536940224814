import React from 'react';

import { GroupedInterests } from 'types/interests';

import { PostUserInfoPayload } from 'js/util/api';
import { validateSocial, validateHeadlineLength, addHttpsToURL } from 'js/util/util';
import { onboarding } from 'js/util/strings';
import { ConversationStarters } from 'js/components/shared/conversations-starters/conversation-starters';
import { InterestsPicker } from 'js/components/shared/interests';
import { Headline } from 'js/components/shared/Headline';

import convoStarters from 'img/registration/profile/convo-starters.svg';
import interestsIcon from 'img/registration/profile/interests.svg';
import introductionIcon from 'img/registration/profile/introduction.svg';
import socialIcon from 'img/registration/profile/social.svg';

import { IconCarousel } from './IconCarousel';
import { OnboardingHeader } from './OnboardingHeader';
import { ProfileData } from './ProfileData';
import { RegistrationFields } from './use-registration-fields';

const { profile } = onboarding;

interface StepProfileProps {
  onSubmit: () => void;
  onPrev: () => void;
  buttonLoading: boolean;
  requireLinkedIn: boolean;
  values: RegistrationFields;
  setValues: (values: RegistrationFields) => void;
  interests: GroupedInterests;
  submitUserInfo: (info: PostUserInfoPayload, opts?: { next?: () => void }) => void;
}

export const StepProfile = ({
  values,
  setValues,
  onSubmit,
  onPrev,
  submitUserInfo,
  buttonLoading,
  requireLinkedIn,
  interests,
}: StepProfileProps) => {
  let pages = [
    {
      name: 'interests',
      icon: interestsIcon,
      component: (
        <InterestsPicker
          allInterests={interests}
          chosenInterests={values.selectedInterests}
          updateSelection={(v: any) => setValues({ ...values, selectedInterests: v })}
        />
      ),
      submit: async () => {
        await submitUserInfo({
          interests: values.selectedInterests.map(interest => interest.name),
        });
      },
      validate: () => Boolean(values.selectedInterests.length),
      invalidMessage: profile.requiredErrorInterests,
    },
    {
      name: 'profileData',
      icon: socialIcon,
      component: (
        <ProfileData
          twitterLink={values.twitterLink}
          setTwitterLink={twitterLink => setValues({ ...values, twitterLink })}
          linkedInLink={values.linkedinLink}
          setLinkedInLink={linkedinLink => setValues({ ...values, linkedinLink })}
          profilePicture={values.image}
          setProfilePicture={image => setValues({ ...values, image })}
          requireLinkedIn={requireLinkedIn}
        />
      ),
      submit: async () => {
        await submitUserInfo({
          profileUrls: {
            linkedin_url: addHttpsToURL(values.linkedinLink),
            twitter_url: addHttpsToURL(values.twitterLink),
          },
        });
      },
      validate: () => {
        if (values.twitterLink.length && !validateSocial(values.twitterLink, 'twitter'))
          return false;
        if (values.linkedinLink.length && !validateSocial(values.linkedinLink, 'linkedin'))
          return false;
        if (requireLinkedIn && !values.linkedinLink.length) return false;
        return true;
      },
      invalidMessage: `${profile.requiredErrorSocials}${
        requireLinkedIn ? ' LinkedIn is required.' : ''
      }`,
    },
    {
      name: 'headline',
      icon: introductionIcon,
      component: (
        <Headline
          firstName={values.firstName}
          headline={values.headline}
          setHeadline={(s: string) => setValues({ ...values, headline: s })}
        />
      ),
      submit: async () => {
        await submitUserInfo({ headline: `${values.firstName} is ${values.headline}` });
      },
      validate: () =>
        validateHeadlineLength(values.headline.length) && !values.headline.includes('http'),
      invalidMessage: profile.requiredErrorHeadline,
    },
    {
      name: 'convoStarters',
      icon: convoStarters,
      component: (
        <ConversationStarters
          conversations={values}
          onChangeConversation={(key: string, value: string) =>
            setValues({ ...values, [key]: value })
          }
        />
      ),
      submit: async () => {
        await submitUserInfo({
          likeToLearn: values.likeToLearn.trim(),
          askMeAbout: values.askMeAbout.trim(),
          topOfMind: values.topOfMind.trim(),
          justLearned: values.justLearned.trim(),
          sideHustle: values.sideHustle.trim(),
        });
      },
    },
  ];

  // Adds header text to pages
  pages = pages.map(page => ({
    ...page,
    component: (
      <>
        <OnboardingHeader
          // @ts-ignore
          title={profile.headers[page.name]}
          // @ts-ignore
          description={profile.descriptions[page.name]}
          noTopMargin
        />
        {page.component}
      </>
    ),
  }));

  return (
    <IconCarousel
      pages={pages}
      onFinish={() => onSubmit()}
      onBack={onPrev}
      buttonLoading={buttonLoading}
    />
  );
};
