import React from 'react';

import { Call } from './Call';
import { CallV2ContextProvider } from './contexts/CallContext';
import { UIStateContextProvider } from './contexts/UIStateContext';
import { ParticipantsContextProvider } from './contexts/ParticipantsContext';
import { TracksContextProvider } from './contexts/TracksContext';
import { MediaDevicesContextProvider } from './contexts/MediaDevicesContext';
import { Modals } from './components/Modals';

export const CallV2Page: React.FC = () => (
  <CallV2ContextProvider>
    <ParticipantsContextProvider>
      <UIStateContextProvider>
        <TracksContextProvider>
          <MediaDevicesContextProvider>
            <Modals />
            <Call />
          </MediaDevicesContextProvider>
        </TracksContextProvider>
      </UIStateContextProvider>
    </ParticipantsContextProvider>
  </CallV2ContextProvider>
);
