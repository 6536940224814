import moment from 'moment';
import React from 'react';

import { Preference } from 'js/components/shared/Preference';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';

import snoozeSvg from 'img/settings/pause.svg';

const MONTH_DAY = 'MMM Do';

export const SnoozeNotificationsSelect: React.FC = () => {
  const { snooze_until } = useUser();
  const { updateUser } = useUserContextProvider();

  const snoozeOptions = [0, 1, 2, 3, 4].map(i => {
    const targetTime = moment.utc().add(7 * i, 'days');
    return {
      id: i,
      value: targetTime,
      name: i === 0 ? 'Unpause' : targetTime.format(MONTH_DAY),
    };
  });

  const updateSnoozeTime = async (snoozeId: number) => {
    updateUser({
      snoozeUntil: snoozeOptions[snoozeId].value.format('YYYY-MM-DD hh:mm:ss'),
    });
  };

  return (
    <Preference
      imageSrc={snoozeSvg}
      imageAlt="Pause emails"
      questionText={
        snooze_until
          ? `Paused until ${moment.utc(snooze_until).format(MONTH_DAY)}`
          : 'Pause Lunchclub emails until...'
      }
      isSelected={!!snooze_until}
      options={snooze_until ? snoozeOptions : snoozeOptions.slice(1)}
      sortObjects={false}
      selectOption={updateSnoozeTime}
      shouldResetOnClick={false}
      shouldOutlineSelected={false}
    />
  );
};
