import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

import globe from 'img/shared/globe.svg';

export const SpinningGlobe = () => {
  return (
    <SpinningGlobeContainer>
      <SpinningGlobeImage src={globe} />;
    </SpinningGlobeContainer>
  );
};

const rotate = keyframes`
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
`;

const SpinningGlobeContainer = styled.div`
  overflow: hidden;
  max-height: 285px;
  max-width: 100vw;
  position: absolute;
  text-align: center;
  align-self: center;
  bottom: 0;
  left: 2px;

  @media screen and (max-height: 750px) {
    max-height: 217px;
  }
`;

const SpinningGlobeImage = styled.img`
  position: relative;
  animation: ${rotate} 30s linear infinite;
  margin-left: -50%;
  margin-right: -50%;
`;
