import React, { useEffect, useState } from 'react';

const RETRY_TIMOUT = 30;

type RetryCountdownProps = {
  setShowRetryCountdown: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RetryCountdown: React.FC<RetryCountdownProps> = ({ setShowRetryCountdown }) => {
  const [count, setCount] = useState(RETRY_TIMOUT);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (count > 0) {
        setCount(prev => prev - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (count === 0) {
      setShowRetryCountdown(false);
    }
  }, [count]);

  return <span>{count}s</span>;
};
