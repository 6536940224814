import React, { createContext, useContext, useEffect, useState } from 'react';

import { Endorsement } from 'types/endorsements';

import { Locale, Timeslot } from 'js/components/weekly/types';
import { useResource } from 'js/util/use-resource';
import { getEndorsement } from 'js/util/api';

import { useUser } from './UserProvider';

interface ConciergeTimeslotsResponse {
  [localeId: string]: {
    in_person_time_slots: string[];
    is_next_week: boolean;
    time_slots: string[];
    time_slots_with_popularity: [string, boolean][];
    warn_deadline: boolean;
  };
}

// eslint-disable-next-line no-console
const noop = () => console.error('No parent ChatContextProvider found!');

const DataContext = createContext<{
  allTimeslots: Timeslot[];
  allLocales: Locale[];
  allActiveLocales: Locale[];
  navbarIconClicked: string;
  setNavbarIconClicked: React.Dispatch<React.SetStateAction<string>>;
  endorsements: Endorsement[];
  setShouldFetchEndorsements: React.Dispatch<React.SetStateAction<boolean>>;
  wasEndorsementModalImmediatelyClosed: boolean;
  setWasEndorsementModalImmediatelyClosed: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  allTimeslots: [],
  allLocales: [],
  allActiveLocales: [],
  navbarIconClicked: '',
  setNavbarIconClicked: noop,
  endorsements: [],
  setShouldFetchEndorsements: noop,
  wasEndorsementModalImmediatelyClosed: false,
  setWasEndorsementModalImmediatelyClosed: noop,
});

export const useDataContext = () => useContext(DataContext);

export const DataContextProvider: React.FC = ({ children }) => {
  const user = useUser();
  const [resTimeslots, timeslotsOps] = useResource<ConciergeTimeslotsResponse>(
    'discover/user/concierge_timeslots',
  );
  const [endorsements, setEndorsements] = useState<Endorsement[]>([]);
  const [shouldFetchEndorsements, setShouldFetchEndorsements] = useState(true);
  const [allTimeslots, setAllTimeslots] = useState<Timeslot[]>([]);
  const [navbarIconClicked, setNavbarIconClicked] = useState<string>('');
  const [wasEndorsementModalImmediatelyClosed, setWasEndorsementModalImmediatelyClosed] = useState(
    false,
  );

  useEffect(() => {
    (async () => {
      if (shouldFetchEndorsements) {
        const res = await getEndorsement();
        if (res.ok) {
          setShouldFetchEndorsements(false);
          setEndorsements(res.getJson.endorsements);
        }
      }
    })();
  }, [shouldFetchEndorsements]);

  useEffect(() => {
    timeslotsOps.refresh();
    const intervalId = setInterval(() => {
      timeslotsOps.refresh();
    }, 1000 * 60 * 60);
    return () => clearInterval(intervalId);
  }, [user?.profile_id]);

  useEffect(() => {
    if (resTimeslots) {
      const processedTimeslots = Object.keys(resTimeslots).map(localeId => ({
        id: parseInt(localeId, 10),
        timeslots: resTimeslots[localeId].time_slots?.sort() || [],
        inPersonTimeslots: resTimeslots[localeId].in_person_time_slots?.sort() || [],
        isNextWeek: resTimeslots[localeId].is_next_week,
        warnDeadline: resTimeslots[localeId].warn_deadline,
      }));
      setAllTimeslots(processedTimeslots);
    }
  }, [resTimeslots]);

  // {
  //   "id": 0,
  //   "is_active": 1,
  //   "is_featured": 1,
  //   "is_timezone": 0,
  //   "large": 1,
  //   "name": "San Francisco Bay",
  //   "neighborhoods": [{ "id": 0, "is_active": true, "name": "SoMa" }],
  //   "timezone": "America/Los_Angeles",
  //   "video_neighborhood_id": 99
  // }

  interface LunchclubNeighborhoodResponse {
    id: number;
    name: string;
    is_active: boolean;
  }

  interface LunchclubLocalesResponse {
    id: number;
    is_active: boolean;
    is_featured: boolean;
    is_timezone: boolean;
    large: boolean;
    name: string;
    neighborhoods: LunchclubNeighborhoodResponse[];
    timezone: string;
    video_neighborhood_id: number;
  }

  const [resLocales] = useResource<LunchclubLocalesResponse[]>('discover/locales', {
    include_tz_locales: true,
  });
  const [allLocales, setAllLocales] = useState<Locale[]>([]);
  const [allActiveLocales, setAllActiveLocales] = useState<Locale[]>([]);
  useEffect(() => {
    if (resLocales && resLocales?.length) {
      const processedLocales = resLocales.map(
        locale =>
          ({
            id: locale.id,
            name: locale.name,
            active: locale.is_active,
            timezone: locale.timezone,
            large: locale.large,
            videoNeighborhood: locale.video_neighborhood_id,
            neighborhoods: locale.neighborhoods.map(neighborhood => ({
              id: neighborhood.id,
              name: neighborhood.name,
              active: neighborhood.is_active,
            })),
          } as Locale),
      );
      setAllLocales(processedLocales);
      setAllActiveLocales(processedLocales.filter(locale => locale.active));
    }
  }, [resLocales]);

  const dataContextValue = {
    allTimeslots,
    allLocales,
    allActiveLocales,
    navbarIconClicked,
    setNavbarIconClicked,
    endorsements,
    setShouldFetchEndorsements,
    wasEndorsementModalImmediatelyClosed,
    setWasEndorsementModalImmediatelyClosed,
  };

  return <DataContext.Provider value={dataContextValue}>{children}</DataContext.Provider>;
};
