import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

import { media, fontSizes, colors, FlexRow, margins } from 'css/css';

import { copyElementToClipboard } from 'js/util/util';

import { Button } from './Button';

export const LinkShare = ({ linkToCopy, buttonText }: any) => {
  const [copiedTipOpen, setCopiedTipOpen] = useState(false);

  const codeInput = useRef<HTMLInputElement>(null);

  const handleLinkClick = () => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    codeInput.current.select();
    copyElementToClipboard('linktocopy');
    setCopiedTipOpen(true);
    setTimeout(() => setCopiedTipOpen(false), 3000);
  };

  return (
    <Tooltip title="Copied to clipboard" position="top" arrow theme="light" open={copiedTipOpen}>
      <Container>
        <StyledLink ref={codeInput} id="linktocopy" value={linkToCopy} onSelect={handleLinkClick} />

        <Button large onClick={handleLinkClick}>
          {buttonText}
        </Button>
      </Container>
    </Tooltip>
  );
};

const StyledLink = styled.input`
  border-radius: 5px;
  padding: ${margins.size2} ${margins.size3};
  background-color: ${colors.greyLight};
  width: 100%;
  height: 44px;
  margin: 2px auto;
  margin-right: ${margins.size1};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: none;
  font-size: ${fontSizes.size2};
  max-width: 310px;
  min-width: 310px;
  color: ${colors.secondaryMid};
  ${media.mobile} {
    max-width: 500px;
    min-width: 100%;
  }
`;

const Container = styled(FlexRow)`
  ${media.mobile} {
    display: block;
  }
`;
