import React, { useEffect, useState } from 'react';

import { margins, FlexColumn, FlexRow, Heading1, Heading2, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { Modal } from 'js/components/shared/NewModal';
import { useUser } from 'js/providers/UserProvider';
import { describeWeeklyTimeslots, getIsMobile, INTERNATIONAL_TIME_LOCALES } from 'js/util/util';
import { postTestActivity } from 'js/util/api';

import autopilotSvg from 'img/weekly/autopilot.svg';

interface Props {
  showModal: boolean;
  timeslots: string[];
  accept: () => void;
  decline: () => void;
}

export const UpdateAutopilotModal: React.FC<Props> = ({
  showModal,
  timeslots,
  accept,
  decline,
}) => {
  const user = useUser();

  const [modalOpen, setModalOpen] = useState(showModal);

  useEffect(() => {
    setModalOpen(showModal);
  }, [showModal]);

  const show24hourTime = INTERNATIONAL_TIME_LOCALES.includes(user.locale);

  return (
    <Modal showModal={modalOpen} handleClose={decline}>
      <FlexColumn style={{ textAlign: 'center' }}>
        <Heading1 style={{ margin: `${margins.size2} 0px` }}>
          {timeslots.length > 1 ? 'Make these your new times?' : 'Make this your new time?'}
        </Heading1>
        <img src={autopilotSvg} alt="" />
        <Heading2 style={{ marginBottom: margins.size2 }}>Update Autopilot?</Heading2>
        <Text style={{ marginBottom: margins.size2 }}>
          We can update your autopilot to receive matches every week on{' '}
          {describeWeeklyTimeslots(timeslots, show24hourTime)}.
        </Text>

        <FlexRow style={{ textAlign: 'center' }} width="100%">
          <Button
            large
            onClick={() => {
              sendTestUpdate(true);
              accept();
            }}
            style={{ marginTop: margins.size3, marginRight: margins.size2 }}
          >
            Update autopilot
          </Button>
          <Button
            large
            onClick={() => {
              sendTestUpdate(false);
              decline();
            }}
            style={{ marginTop: margins.size3, marginLeft: margins.size2 }}
          >
            Not right now
          </Button>
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
};

const sendTestUpdate = (autopilotSave: boolean) => {
  const platform = window?.bridge ? 'ios' : getIsMobile() ? 'mobile' : 'desktop';
  if (autopilotSave) {
    postTestActivity('autopilot-time-reset', `${platform}-accept`);
  } else {
    postTestActivity('autopilot-time-reset', `${platform}-reject`);
  }
};
