import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { underlineAnimation } from 'css/animations';
import { margins, Heading3, colors, Clickable } from 'css/css';

import {
  getUnsubscribeTypes,
  getUserUnsubscriptions,
  postUserResubscribe,
  postUserUnsubscribe,
  UnsubscribeTypes,
} from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';

import { Title } from '../styles';
import { SnoozeNotificationsSelect } from '../components/SnoozeNotificationsSelect';
import { ToggleSection } from '../components/shared-components/ToggleSection';

const UNSUB_ALL = 0;

export const Notifications = () => {
  const [unsubscribeTypes, setUnsubscribeTypes] = useState<UnsubscribeTypes>({});
  const [userUnsubIds, setUserUnsubIds] = useState<any[]>([]);
  const unsubscribedAll = userUnsubIds.includes(UNSUB_ALL);

  const loadUserUnsubscriptions = async () => {
    const res = await getUserUnsubscriptions();
    if (res.ok) setUserUnsubIds(res.getJson);
  };

  useMountEffect(() => {
    const loadUnsubscribeTypes = async () => {
      const res = await getUnsubscribeTypes();
      if (res.ok) setUnsubscribeTypes(res.getJson);
    };
    loadUnsubscribeTypes();
    loadUserUnsubscriptions();
  });

  const toggleSubscriptionType = async (typeId: string) => {
    const intTypeId = parseInt(typeId, 10);
    if (userUnsubIds.includes(intTypeId) || unsubscribedAll) {
      await postUserResubscribe({ typeId: intTypeId });
    } else {
      await postUserUnsubscribe({ typeId: intTypeId });
    }
    loadUserUnsubscriptions();
  };

  const toggleAllSubscriptions = async () => {
    if (unsubscribedAll) await postUserResubscribe({});
    else await postUserUnsubscribe({});
    loadUserUnsubscriptions();
  };

  return (
    <>
      <Title style={{ marginBottom: margins.size3 }}>Email notifications</Title>
      <SnoozeNotificationsSelect />
      {Object.keys(unsubscribeTypes)
        .filter(unsubscribeType => unsubscribeType !== '0')
        .sort((a, b) => {
          const textA = unsubscribeTypes[a].title;
          const textB = unsubscribeTypes[b].title;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map(unsubscribeType => {
          const { title, subtitle } = unsubscribeTypes[unsubscribeType];
          return (
            <ToggleSection
              key={title}
              headingTitle={title}
              subtitle={subtitle}
              isOn={!(userUnsubIds.includes(parseInt(unsubscribeType, 10)) || unsubscribedAll)}
              onClick={() => toggleSubscriptionType(unsubscribeType)}
              hasBorder
            />
          );
        })}
      <Clickable
        onClick={toggleAllSubscriptions}
        style={{ width: 'fit-content', marginTop: margins.size1 }}
      >
        <SubscribeAllText>
          {`${unsubscribedAll ? 'Re' : 'Un'}subscribe ${
            unsubscribedAll ? 'to' : 'from'
          } all optional emails`}
        </SubscribeAllText>
      </Clickable>
    </>
  );
};

export const SubscribeAllText = styled(Heading3)`
  color: ${colors.primaryMain};
  ${underlineAnimation};
`;
