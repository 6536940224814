import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';

import {
  colors,
  FlexRow,
  Heading3,
  Clickable,
  FlexColumn,
  SubText,
  margins,
  Text,
  BUTTON_HEIGHT,
} from 'css/css';

import { Button } from 'js/components/shared/Button';
import { useChatContext } from 'js/providers/ChatContextProvider';

import close from 'img/shared/close-small.svg';

interface Props {
  openScheduler?: () => void;
  clearDefaultInputValue: () => void;
  closeTimeslotsConfirmation?: () => void;
  isTimeslotsConfirmationVisible: boolean;
  userTimezone?: string;
}

export const sortTimeslots = (timeslotsArray: string[]) => {
  return timeslotsArray.sort(
    (a, b) => Number(moment(a).format('YYYYMMDD')) - Number(moment(b).format('YYYYMMDD')),
  );
};

export const TimeslotsConfirmationInput: React.FC<Props> = ({
  openScheduler,
  clearDefaultInputValue,
  closeTimeslotsConfirmation,
  isTimeslotsConfirmationVisible,
  userTimezone,
}) => {
  const { consolidatedTimeslots } = useChatContext();

  const handleEditClick = () => {
    if (openScheduler) {
      openScheduler();
    }
    clearDefaultInputValue();
    if (closeTimeslotsConfirmation) {
      closeTimeslotsConfirmation();
    }
  };

  const handleClose = () => {
    if (closeTimeslotsConfirmation) {
      closeTimeslotsConfirmation();
    }
    clearDefaultInputValue();
  };

  if (!Object.keys(consolidatedTimeslots).length || !isTimeslotsConfirmationVisible) {
    return null;
  }

  return (
    <ConfirmationContainer>
      <FlexRow style={{ width: '100%' }} justifyContent="space-between">
        <Heading3>Your available times</Heading3>
        <Clickable onClick={handleClose}>
          <img src={close} alt="close" style={{ color: colors.blackLight }} />
        </Clickable>
      </FlexRow>
      <SubText>(times are in {userTimezone})</SubText>
      <TimeslotsContainer>
        {sortTimeslots(Object.keys(consolidatedTimeslots)).map(t => (
          <TimeslotContainer key={t}>
            <Heading3 color={colors.primaryMain}>{t}</Heading3>
            <div style={{ borderRight: `1px solid ${colors.whiteMain}`, height: BUTTON_HEIGHT }} />
            <Text color={colors.primaryMain}>
              {consolidatedTimeslots[t].length} timeslot{consolidatedTimeslots[t].length > 1 && 's'}
            </Text>
          </TimeslotContainer>
        ))}
      </TimeslotsContainer>
      <Button secondary style={{ margin: '0' }} onClick={handleEditClick}>
        Edit times
      </Button>
    </ConfirmationContainer>
  );
};

const ConfirmationContainer = styled(FlexColumn)`
  background-color: ${colors.whiteMain};
  border-radius: 5px;
  width: 100%;
  margin: ${margins.size2} 0;
  padding: ${margins.size3};
  align-items: flex-start;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

const TimeslotsContainer = styled(FlexRow)`
  flex-wrap: wrap;
`;

const TimeslotContainer = styled(FlexRow)`
  justify-content: space-evenly;
  background-color: ${colors.primaryLight};
  border-radius: 5px;
  padding: ${margins.size3} ${margins.size1};
  margin: ${margins.size2} ${margins.size1} ${margins.size2} 0;
  width: 200px;
  height: ${BUTTON_HEIGHT};
`;
