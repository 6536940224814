import React from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import { colors, globalTransitionSettings } from 'css/css';

import { useCallV2Context } from 'js/components/callv2/contexts/CallContext';

import endIcon from 'img/call/end.svg';

export const LeaveButton: React.FC = () => {
  const { leaveCall } = useCallV2Context();

  return (
    <Container role="button" id="end-call" title="Leave call" onClick={() => leaveCall()}>
      <SVG src={endIcon} alt-text="End call icon" />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  transition: ${globalTransitionSettings};
  background-color: ${colors.tertiary2Main};

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;
