import React from 'react';

import { colors, Heading3, Text } from 'css/css';

import { SuperMessage } from 'types/channel';

import { Avatar } from 'js/components/shared/Avatar';

import { MessageBubble } from './BaseMessage';

export const MorphFeedbackMessage = ({ m }: { m: SuperMessage }) => {
  const { image, first_name, last_name } = (m.attributes as any).feedbackMatch;

  return (
    <MessageBubble $isMyMessage>
      <Heading3 color={colors.whiteMain}>
        Thoughts on <Avatar size0 src={image} style={{ marginBottom: '2px' }} /> {first_name}{' '}
        {last_name}
      </Heading3>
      <Text color={colors.whiteMain}>{m.body}</Text>
    </MessageBubble>
  );
};
