import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';

import { colors, margins, Heading2, Text, FlexColumn } from 'css/css';

import { DeckCardContainer, DECK_CARD_CONTAINER_WIDTH } from 'js/components/homepage/styles';
import {
  ENDORSEMENTS_END_TEXT,
  ENDORSEMENT_MODAL_DESKTOP_BODY_HEIGHT,
} from 'js/components/chat/endorsement-modal/constants';
import { getIsMobile } from 'js/util/util';

import { regularSessionEndIcon, endorsementSessionEndIcon } from 'img/homepage/feed';

export const DeckEndCard = ({
  feedSession,
}: {
  feedSession: 'Regular' | 'Endorsement' | 'DiscoverBooking';
}) => {
  const isMobile = getIsMobile();
  const descText =
    feedSession === 'Endorsement'
      ? ENDORSEMENTS_END_TEXT
      : 'Come back tomorrow to see your new Daily Picks!';

  return (
    <DeckCardContainer
      isEndCard
      style={{
        width: isMobile ? '100%' : DECK_CARD_CONTAINER_WIDTH,
        ...(!isMobile && feedSession === 'Endorsement'
          ? { height: ENDORSEMENT_MODAL_DESKTOP_BODY_HEIGHT }
          : {}),
      }}
      feedSession={feedSession}
    >
      <EndCardContainer>
        <EndCardIcon
          src={feedSession === 'Endorsement' ? endorsementSessionEndIcon : regularSessionEndIcon}
        />
        <Heading2
          color={colors.blackMain}
          style={{ marginBottom: margins.size1, textAlign: 'center' }}
        >
          You’re done{feedSession === 'Endorsement' ? '' : ' for today'}!
        </Heading2>
        <Text style={{ textAlign: 'center' }}>{descText}</Text>
      </EndCardContainer>
    </DeckCardContainer>
  );
};

const EndCardIcon = styled(SVG).attrs(({ src }) => ({ src, alt: 'Feed complete icon' }))`
  min-width: 64px;
  min-height: 64px;
  margin-bottom: ${margins.size4};
`;

const EndCardContainer = styled(FlexColumn)`
  padding: ${margins.size5} ${margins.size3};
  align-items: center;
  margin: auto;
`;
