import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { margins, colors, Text, Heading3, FlexColumn } from 'css/css';

import { ProductUser, UserMe } from 'types/user';
import { Interests } from 'types/interests';
import { Endorsement } from 'types/endorsements';
import { NetworkUser } from 'types/matches';

import { Avatar } from 'js/components/shared/Avatar';
import { postTestActivity } from 'js/util/api';
import { addParameterToURL, extractProfiles, getIsMobile } from 'js/util/util';

import {
  angellistIcon,
  githubIcon,
  instagramIcon,
  linkedinIcon,
  personalIcon,
  twitterIcon,
} from 'img/shared/social-icons';
import {
  iconAboutSvg,
  iconInterestsSvg,
  coverImageSvg,
  iconEndorsementsSvg,
  iconNewYearGoalsSvg,
} from 'img/profile-v2';
import { mobileWorkSvg, mobileLocationSvg, mobileInviteSvg } from 'img/profile';

import { ProfileEndorsements } from './ProfileEndorsements';
import { ProfileNewYearGoals } from './ProfileNewYearGoals';
import { ConversationStarterConfigMobile } from './constants';

export const ProfileMobile: React.FC<{
  profile: UserMe | ProductUser | NetworkUser;
  note?: React.ReactNode;
  endorsements: Endorsement[];
}> = ({ profile, note, endorsements }) => {
  const { city: cityName } = profile;
  const inviter = 'inviter' in profile ? profile.inviter : undefined;
  const newYearGoals = 'new_year_goals' in profile ? profile.new_year_goals : undefined;

  return (
    <div
      style={{
        padding: margins.size3,
        width: '100%',
        maxWidth: '100vw',
      }}
    >
      {note}
      <FlexColumn>
        <Heading3 style={{ marginBottom: margins.size3 }}>
          {profile.first_name} {profile.last_name}
        </Heading3>
        <Text style={{ textAlign: 'center' }} color={colors.blackMain}>
          {profile.headline}
        </Text>
      </FlexColumn>
      <ProfileSocialIcons profile={profile} />
      <ProfileInfoBox>
        <ProfileLineItem
          icon={iconAboutSvg}
          title="Some Basics"
          content={
            <>
              {cityName ? (
                <LightGreyButton style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    style={{ marginRight: margins.size2 }}
                    src={mobileLocationSvg}
                    alt="location"
                  />
                  <Text>{cityName}</Text>
                </LightGreyButton>
              ) : (
                undefined
              )}
              {profile.title && profile.company ? (
                <LightGreyButton style={{ display: 'flex', alignItems: 'center' }}>
                  <img style={{ marginRight: margins.size2 }} src={mobileWorkSvg} alt="Role" />
                  <Text>
                    {profile.title} @{profile.company}
                  </Text>
                </LightGreyButton>
              ) : (
                undefined
              )}
              {inviter ? (
                <LightGreyButton style={{ display: 'flex', alignItems: 'center' }}>
                  <img style={{ marginRight: margins.size2 }} src={mobileInviteSvg} alt="Inviter" />
                  <Text>Invited by </Text>
                  {inviter.is_active ? (
                    <Link to={addParameterToURL('ref', 'profile', `/member/${inviter.public_id}`)}>
                      <Heading3 color={colors.primaryMain}>
                        {inviter.first_name} {inviter.last_name}
                      </Heading3>
                    </Link>
                  ) : (
                    <Text>
                      {inviter.first_name} {inviter.last_name}
                    </Text>
                  )}
                </LightGreyButton>
              ) : (
                undefined
              )}
            </>
          }
        />
        {ConversationStarterConfigMobile.filter(c => !!profile[c.key]).map(starter => (
          <ProfileLineItem
            key={starter.key}
            icon={starter.icon}
            title={starter.titleThirdParty.replace('NAME', profile.first_name)}
            content={profile[starter.key]}
          />
        ))}
        {newYearGoals !== undefined && newYearGoals.length > 0 && (
          <ProfileLineItem
            title="Goals this year"
            icon={iconNewYearGoalsSvg}
            content={<ProfileNewYearGoals newYearGoals={newYearGoals} editing={false} />}
          />
        )}
        {endorsements.length > 0 && (
          <ProfileLineItem
            title="Endorsed for"
            icon={iconEndorsementsSvg}
            content={<ProfileEndorsements endorsements={endorsements} />}
          />
        )}
        {'interests' in profile && <ProfileInterests interests={profile.interests} />}
      </ProfileInfoBox>
    </div>
  );
};

const ProfileInfoBox = styled.div`
  padding: ${margins.size3};
  border: 1px solid ${colors.greyMain};
  border-radius: 12px;
  text-align: left;
  margin-top: ${margins.size3};
  flex-shrink: 0;
  background-color: ${colors.whiteMain};
`;

const ProfileSocialIcons: React.FC<{ profile: UserMe | ProductUser | NetworkUser }> = ({
  profile,
}) => {
  const links = extractProfiles(profile);
  return (
    <div style={{ display: 'flex', justifyContent: 'center', margin: `${margins.size2} 0` }}>
      {[
        [links.angellist, angellistIcon],
        [links.twitter, twitterIcon],
        [links.github, githubIcon],
        [links.instagram, instagramIcon],
        [links.linkedin, linkedinIcon],
        [links.personal, personalIcon],
      ].map(
        ([link, icon]) =>
          link && (
            <a
              key={link}
              href={link}
              target="_blank"
              rel="nofollow noreferrer"
              onClick={() => postTestActivity('mobile_profile_link', `${link}`)}
            >
              <img src={icon} alt="social link" style={{ padding: '9px 5px' }} />
            </a>
          ),
      )}
    </div>
  );
};

const ProfileLineItem: React.FC<{
  content: React.ReactNode | null;
  icon: string;
  title: string;
}> = ({ content, icon, title }) =>
  content ? (
    <div style={{ marginBottom: margins.size2 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={icon} alt="icon" style={{ marginRight: margins.size3 }} />
        <Text color={colors.blackMain}>{title}</Text>
      </div>
      <Text
        style={{
          color: colors.blackMain,
          whiteSpace: 'pre-wrap',
          padding: `${margins.size2} 0`,
        }}
      >
        {content}
      </Text>
    </div>
  ) : null;

const ProfileInterests: React.FC<{ interests: Interests[] }> = ({ interests }) => {
  return (
    <ProfileLineItem
      title="Interested in"
      icon={iconInterestsSvg}
      content={
        <InterestsScroller>
          {interests.map(interest => (
            <LightGreyButton key={interest.id}>{interest.name}</LightGreyButton>
          ))}
        </InterestsScroller>
      }
    />
  );
};

export const InterestsScroller = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin: 0 -${margins.size3};
  padding: 0 ${margins.size3};
`;

export const LightGreyButton = styled.div`
  whitespace: nowrap;
  flex-shrink: 0;
  background: ${colors.greyLight};
  border-radius: 12px;
  padding: ${margins.size2} ${margins.size3};
  width: fit-content;
  margin-bottom: ${margins.size1};
`;

export const ProfileBackground = styled.div<{ noCover?: boolean }>`
  width: 100%;
  height: 12vh;
  background: ${p => (p.noCover ? 'transparent' : `url(${coverImageSvg})`)};
  background-size: cover;
  margin-bottom: 50px;
  position: relative;
  text-align: center;
  flex-shrink: 0;
  ${!getIsMobile() && `border-top-left-radius: 5px; border-top-right-radius: 5px;`}
`;

export const ProfileAvatarImage = styled(Avatar)`
  width: 96px;
  height: 96px;
  border: 4px solid #fff;
  border-radius: 48px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`;

export const RoundShuffleButton = styled.div`
  background-color: ${colors.whiteMain};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  position: fixed;
  top: calc(max(env(safe-area-inset-top), ${margins.size3}));
  right: ${margins.size3};
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  z-index: 2;
`;
