import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import { FlexColumn, media } from 'css/css';

import { ConversationContainer } from 'js/components/chat/ConversationContainer';
import { useChatContext } from 'js/providers/ChatContextProvider';

interface Props {
  matchProfileId?: string;
  setHasUnreadMessages: React.Dispatch<React.SetStateAction<boolean>>;
  isActive: boolean;
}

export const ChatCard: React.FC<Props> = ({ matchProfileId, setHasUnreadMessages, isActive }) => {
  const { setSelectedProfileId, channels } = useChatContext();
  const selectedChannel = channels.find(c => c.profileId === matchProfileId);

  useEffect(() => {
    if (matchProfileId) {
      setSelectedProfileId(matchProfileId);
    }
  }, [matchProfileId]);

  return (
    <ChatCardContainer>
      {!!selectedChannel && (
        <ConversationContainer
          selectedChannel={selectedChannel}
          setHasUnreadMessages={setHasUnreadMessages}
          isActive={isActive}
          inCall
          isOpen
        />
      )}
    </ChatCardContainer>
  );
};

const ChatCardContainer = styled(FlexColumn)`
  width: 100%;
  height: 100%;

  ${media.mobile} {
    position: relative;
  }
`;
