import React from 'react';

import { margins } from 'css/css';

import { SuperMessage } from 'types/channel';

import { MessageBubble } from './BaseMessage';

interface Props {
  message: SuperMessage;
}

export const IntroMessage: React.FC<Props> = ({ message }) => {
  const messages = message.body
    .split('\n\n')
    .map(t => t.trim())
    .filter(t => !!t);
  return (
    <>
      {messages.map(m => {
        return (
          <>
            <MessageBubble $isMyMessage={false} style={{ marginBottom: margins.size1 }}>
              {m}
            </MessageBubble>
          </>
        );
      })}
    </>
  );
};
