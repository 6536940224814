import React from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import {
  borders,
  colors,
  fonts,
  media,
  margins,
  Link,
  FlexRow,
  Text,
  BUTTON_HEIGHT,
} from 'css/css';

import { Partnership, PartnershipTheme } from 'types/partnership-whitelabel';

import lunchclubLogo from 'img/shared/logo2.svg';
import { divider } from 'img/landing-page';

interface Props {
  partnership?: Partnership | undefined;
}
export const LandingPageNav: React.FC<Props> = ({ partnership }) => (
  <Container>
    <FlexRow>
      <Logo src={partnership?.lunchclubLogo || lunchclubLogo} alt="Lunchclub logo" />
      {partnership?.partnerLogo && (
        <>
          <Divider fillColor={partnership.theme.logoDividerColor} src={divider} alt="" />
          <Logo src={partnership?.partnerLogo} alt={partnership?.name} />
        </>
      )}
    </FlexRow>
    <LoginButton theme={partnership?.theme} to="/login">
      <LoginText theme={partnership?.theme}>Log in</LoginText>
    </LoginButton>
  </Container>
);

const Container = styled(FlexRow)`
  justify-content: space-between;
  ${media.mobile} {
    padding: ${margins.size3};
  }
`;

const Logo = styled.img`
  height: 50px;
  ${media.mobile} {
    height: 25px;
  }
`;

const Divider = styled(SVG)`
  margin: 0px ${margins.size4};
  ${media.mobile} {
    margin: 0px ${margins.size3};
    height: 35px;
  }

  & > path {
    stroke: ${p => p.fillColor};
  }
`;

const LoginButton = styled(Link)<{ theme: PartnershipTheme }>`
  display: flex;
  align-items: center;
  border: ${p =>
    p?.theme.loginButtonBorder ? `1px solid ${p?.theme.loginButtonBorder}` : borders.primary};
  border-radius: 5px;
  height: 38px;
  padding: 0px 12px;
  background-color: ${p =>
    p?.theme.loginButtonBorder ? 'transparent' : p?.theme.logInButtonColor || 'transparent'};
  ${media.mobile} {
    height: ${BUTTON_HEIGHT};
  }
`;

const LoginText = styled(Text)<{ theme: PartnershipTheme }>`
  color: ${p => p?.theme.descriptionColor || colors.primaryMain};
  font-family: ${fonts.semiBold};
`;
