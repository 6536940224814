import React, { useState } from 'react';

import { postProfileResponse } from 'js/util/api';
import { apiFailure } from 'js/util/strings';
import { useNotifContext } from 'js/util/notif-context';

import { CardQuestion, DeckCardContainer, HeightContainer } from '../styles';
import { UserDescriptionBoxes } from '../shared/UserDescriptionBoxes';
import { yesNoButtons } from '../shared/DeckCardButtons';
import { AllCardTypes, PersonCard } from '../types';
import { CARD_TRANSITION_LEAVE_DURATION } from '../constants';

export const PersonCardYesNo = ({
  card,
  completeCard,
}: {
  card: PersonCard;
  completeCard: (cardId: string) => void;
}) => {
  const { showNotif } = useNotifContext();

  const [cardQuestionHeight, setCardQuestionHeight] = useState<number>();
  const [deckCardContentHeight, setDeckCardContentHeight] = useState<number>();
  const [isAccepted, setIsAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRejected, setIsRejected] = useState(false);

  const { card_type: cardType, card_id: cardId } = card;

  const getPersonCardYesNoInfo = () => {
    switch (cardType) {
      case AllCardTypes.Similar: {
        return {
          postResponse: (accepted: boolean) =>
            postProfileResponse({
              profileId: card.data.similar_user.profile,
              source: cardId,
              reject: !accepted,
            }),

          user: card.data.similar_user,
        };
      }
      default: {
        return {
          postResponse: (accepted: boolean) =>
            postProfileResponse({
              profileId: card.data.user.profile,
              source: cardId,
              reject: !accepted,
            }),
          user: card.data.user,
        };
      }
    }
  };

  const { postResponse, user } = getPersonCardYesNoInfo();

  const submitPersonYesNo = async (accept: boolean) => {
    if (loading) return;
    setLoading(true);
    const res = await postResponse(accept);
    if (res.ok) {
      if (accept) setIsAccepted(true);
      else setIsRejected(true);
      setTimeout(() => completeCard(cardId), CARD_TRANSITION_LEAVE_DURATION);
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
    setLoading(false);
  };

  const { image, name, headline, profile: profileId } = user;

  return (
    <DeckCardContainer
      deckCardId={card.id}
      isLoading={loading}
      isSelected={isRejected || isAccepted}
      buttons={{
        kind: 'emoji',
        values: yesNoButtons(submitPersonYesNo),
      }}
      setContentHeight={setDeckCardContentHeight}
      feedSession="Regular"
    >
      <HeightContainer setHeight={setCardQuestionHeight}>
        <CardQuestion>Would you like to meet someone like {name}?</CardQuestion>
      </HeightContainer>

      {deckCardContentHeight !== undefined && cardQuestionHeight !== undefined && (
        <UserDescriptionBoxes
          deckCardId={card.id}
          profiles={[
            {
              id: profileId,
              imageSrc: image,
              description: headline,
            },
          ]}
          isIntro={false}
          maxHeight={deckCardContentHeight - cardQuestionHeight}
        />
      )}
    </DeckCardContainer>
  );
};
