import React from 'react';
import styled from 'styled-components/macro';

import { colors, Text, FlexRow, FlexColumn, margins, Clickable, EditTextArea } from 'css/css';

import DeleteGoal from 'img/profile/cross.svg';

import { NEW_YEAR_GOALS_LIMIT } from './constants';

export const ProfileNewYearGoals: React.FC<{
  newYearGoals: string[];
  setNewYearGoals?: (val: string[]) => void;
  editing: boolean;
}> = ({ newYearGoals, setNewYearGoals, editing }) => {
  if (newYearGoals.length === 0 && !editing) return null;

  if (editing) {
    const goalsList = [
      ...newYearGoals,
      ...Array(Math.max(NEW_YEAR_GOALS_LIMIT - newYearGoals.length, 0)).fill(''),
    ];
    const updateGoalsList = (index: any, value: any) => {
      if (setNewYearGoals) {
        const updatedGoalsList = getUpdatedGoalsList(goalsList, index, value);
        setNewYearGoals(updatedGoalsList);
      }
    };

    const goalsInputs = goalsList.map(
      (value, index) =>
        (index <= 1 || !!goalsList[index - 1]) && (
          <GoalEditContainer>
            <EditTextArea
              style={{
                backgroundColor: colors.greyLight,
                border: 'none',
                height: '40px',
                padding: margins.size2,
                resize: 'none',
              }}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                updateGoalsList(index, e.target.value)
              }
              value={value || ''}
              placeholder={`My goal #${index + 1}`}
            />

            <DeleteButton
              onClick={() => {
                updateGoalsList(index, '');
              }}
            >
              <img src={DeleteGoal} alt="delete" />
            </DeleteButton>
          </GoalEditContainer>
        ),
    );

    return <ProfileNewYearGoalContainer>{goalsInputs}</ProfileNewYearGoalContainer>;
  }
  return (
    <ProfileNewYearGoalContainer>
      <NewYearGoalUnorderedList>
        {newYearGoals.map(
          newYearGoal =>
            newYearGoal && (
              <NewYearGoalLineContainer>
                <Text>{newYearGoal}</Text>
              </NewYearGoalLineContainer>
            ),
        )}
      </NewYearGoalUnorderedList>
    </ProfileNewYearGoalContainer>
  );
};

const ProfileNewYearGoalContainer = styled(FlexColumn)`
  border: 1px solid ${colors.greyLight};
  border-radius: 10px;
  gap: ${margins.size2};
  align-items: flex-start;
`;

const GoalEditContainer = styled(FlexRow)`
  border-radius: 5px;
  width: 100%;
  resize: none;
`;

const NewYearGoalUnorderedList = styled(FlexColumn)`
  color: ${colors.blackMid};
  padding: ${margins.size2};
  margin: 0px;
`;

const NewYearGoalLineContainer = styled.div`
  align-items: flex-start;
  width: 100%;
  padding: ${margins.size2};
`;

const DeleteButton = styled(Clickable)`
  position: absolute;
  right: 0px;
  width: 40px;
  height: 40px;
`;

export const getUpdatedGoalsList = (
  goalsList: string[],
  index: number,
  value: string,
): string[] => {
  if (index < 0 || index >= NEW_YEAR_GOALS_LIMIT) {
    return goalsList;
  }
  const currentGoalsList = [...goalsList];
  currentGoalsList[index] = value;
  const filteredCurrentGoalsList = currentGoalsList.filter(Boolean);
  return filteredCurrentGoalsList;
};
