import React from 'react';
import styled from 'styled-components/macro';
import { partition } from 'lodash';

import { margins, fonts, Heading2 } from 'css/css';

import { BackendLocale } from '../weekly/types';

import { City } from './cities/City';
import { CitySelect } from './cities/CitySelect';

type StepCitiesProps = {
  locales: BackendLocale[];
  onSubmit: (hub: { locale: number } | { googlePlaceId: string }) => void;
};

export const StepCities: React.FC<StepCitiesProps> = ({ locales, onSubmit }) => {
  const [featuredLocales] = partition(locales || [], locale => locale.is_featured);

  return (
    <CitySelectWrapper>
      <CitySelect onSubmitCustom={googlePlaceId => onSubmit({ googlePlaceId })} />
      <FeaturedLocationsHeader>Some of our major cities...</FeaturedLocationsHeader>

      <div style={{ marginTop: margins.size2 }}>
        {featuredLocales.map((locale: any) => (
          <City locale={locale} onClick={() => onSubmit({ locale })} />
        ))}
      </div>
    </CitySelectWrapper>
  );
};

const CitySelectWrapper = styled.div`
  max-width: 600px;
  margin: ${margins.size5} auto 0;
`;

const FeaturedLocationsHeader = styled(Heading2)`
  font-family: ${fonts.regular};
  text-align: left;
  margin-top: ${margins.size4};
`;
