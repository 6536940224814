import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';

import { margins, FlexRow } from 'css/css';

import { postTestActivity } from 'js/util/api';
import { Button } from 'js/components/shared/Button';

interface Props {
  selectedProfileId: string;
  metDate?: string;
  sendMessage: (message: string) => void;
  style?: Record<string, any>;
  inputHeight: number;
}

export const ConversationBubble: React.FC<Props> = ({
  selectedProfileId,
  metDate,
  sendMessage,
  style,
  inputHeight,
}) => {
  const isUpcoming = moment(metDate).isAfter(moment());
  let bubbleMessages = [];
  if (isUpcoming) {
    bubbleMessages = ['Looking forward to speaking with you!', 'Speak with you soon!', 'Hey!'];
  } else {
    bubbleMessages = ['Great chatting with you!', "Let's catch up", 'How have you been?', 'Hey!'];
  }

  return (
    <MessageBubbleContainer style={style} $inputHeight={inputHeight}>
      {bubbleMessages.map(messageText => (
        <ConfirmMessageBubble
          secondary
          key={messageText}
          onClick={() => {
            postTestActivity('chat-bubble', `${messageText}&${selectedProfileId}`);
            sendMessage(messageText);
          }}
        >
          {messageText}
        </ConfirmMessageBubble>
      ))}
    </MessageBubbleContainer>
  );
};

const MessageBubbleContainer = styled(FlexRow)<{ $inputHeight: number }>`
  margin-left: ${margins.size2};
  margin-bottom: calc(
    ${p => p.$inputHeight}px + calc(max(${margins.size3}, env(safe-area-inset-bottom)))
  );
  padding: ${margins.size3} ${margins.size2} 0px;
  width: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: all 300ms ease-out;
`;

const ConfirmMessageBubble = styled(Button)`
  border-radius: 5px;
  height: 34px;
  margin-right: ${margins.size2};
  margin-left: 0;
  width: max-content;
  white-space: nowrap;
`;
