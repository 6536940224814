import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { Card, margins, Heading3, FlexColumn } from 'css/css';

import { Invitee } from 'types/invites';

import { Shuffle } from 'js/components/shared/Shuffle';
import { InviteCardRow } from 'js/components/shared/InviteCardRow';
import { useUserContextProvider } from 'js/providers/UserProvider';
import { getInvitees, postInvitees } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';
import { useNotifContext } from 'js/util/notif-context';
import { INVITE_SOURCES } from 'js/util/util';

const NUM_DISPLAYED_INVITEES = 5;

export const PondSuggestions: React.FC = () => {
  const { showNotif } = useNotifContext();
  const { fetchUser } = useUserContextProvider();

  const [loading, setLoading] = useState(false);
  const [inviteSuggestions, setInviteSuggestions] = useState<Invitee[]>([]);

  const loadInviteSuggestions = async () => {
    if (loading) return;
    setLoading(true);
    const res = await getInvitees({
      inviteeNum: NUM_DISPLAYED_INVITEES,
    });
    if (res.status === 200) {
      setInviteSuggestions(res.getJson);
    }
    setLoading(false);
  };

  useMountEffect(() => {
    loadInviteSuggestions();
  });

  if (inviteSuggestions.length === 0 && !loading) {
    return null;
  }

  const removeFromInvitees = (email: string) => {
    const filteredArray = inviteSuggestions.filter(u => u.email !== email);
    if (filteredArray.length === 0) {
      loadInviteSuggestions();
    }
    setInviteSuggestions(filteredArray);
  };

  const sendInvite = async (email: string) => {
    const res = await postInvitees({
      emails: [email],
      source: INVITE_SOURCES.WEEKLY,
    });
    if (res.status === 200) {
      showNotif({
        message: `Sent invite to ${email}.`,
        level: 'success',
      });
      fetchUser();
      removeFromInvitees(email);
    } else {
      showNotif({
        message: `There was an issue sending this invite, please try again. If issues persist, contact us at support@lunchclub.com.`,
        level: 'error',
      });
    }
  };

  return (
    <ContainerCard>
      <Heading3 style={{ textAlign: 'left' }}>Invite some friends for more Clubpoints?</Heading3>

      <FlexColumn style={{ marginBottom: margins.size2 }}>
        {inviteSuggestions.map(invitee => (
          <InviteCardRow
            key={invitee.email}
            user={invitee}
            sendInvite={() => sendInvite(invitee.email)}
          />
        ))}
      </FlexColumn>

      <Shuffle onClick={loadInviteSuggestions} loading={loading} />
    </ContainerCard>
  );
};

const ContainerCard = styled(Card)`
  position: relative;
  width: 100%;
  padding: ${margins.size4};
  margin-bottom: ${margins.size3};
`;
