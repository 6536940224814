import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

import { colors, FlexColumn, Heading1, Heading3, margins, SubText, Text } from 'css/css';

import {
  CoachingPlanEligibilityResult,
  CoachingPlanMatchMeta,
  postAcceptCoachingPlan,
} from 'js/api/coaching-plan';
import { TimesSelector } from 'js/components/autopilot/components/TimesSelector';
import { Confirmation } from 'js/components/autopilot/Confirmation';
import { AutopilotSteps, DEFAULT_CADENCE, getHeader } from 'js/components/autopilot/constants';
import { CoachingPlanAvatars } from 'js/components/coaching_plan/CoachingPlanAvatars';
import { CoachingPlanOptIn } from 'js/components/coaching_plan/CoachingPlanOptIn';
import { CoachingPlanOptInMobile } from 'js/components/coaching_plan/CoachingPlanOptInMobile';
import { CoachingPlanSplash } from 'js/components/coaching_plan/CoachingPlanSplash';
import { Button } from 'js/components/shared/Button';
import { Checkbox } from 'js/components/shared/Checkbox';
import {
  DarkCardBackground,
  WhiteCard,
  WhiteCardHeader,
  WhitePrompt,
} from 'js/components/shared/MobileModal';
import { Modal } from 'js/components/shared/NewModal';
import { GreyPage } from 'js/components/shared/page-wrappers';
import { useDataContext } from 'js/providers/DataContextProvider';
import { useUser, useUserAvailability, useUserContextProvider } from 'js/providers/UserProvider';
import { postAvailability, postTestActivity } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';
import { useNotifContext } from 'js/util/notif-context';
import { apiFailure } from 'js/util/strings';
import { useResource } from 'js/util/use-resource';
import { addParameterToURL, getIsMobile, getTimezoneAbbr } from 'js/util/util';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';

import autopilotSvg from 'img/weekly/autopilot.svg';

export const AutopilotForced = () => {
  const [currentStep, setCurrentStep] = useState(AutopilotSteps.CHECK_COACHING_PLAN_ELIGIBILITY);
  const [selectedTimeslots, setSelectedTimeslots] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocaleTargeted, setIsLocaleTargeted] = useState(false);
  const [decidedCoachingPlan, setDecidedCoachingPlan] = useState(false);
  const [signedUpCoachingPlanType, setSignedUpCoachingPlanType] = useState<number | undefined>();
  const [showCoachingPlanOptIn, setShowCoachingPlanOptIn] = useState(false);
  const [showCoachingPlanSplash, setShowCoachingPlanSplash] = useState(false);
  const [coachingPlanEligibility] = useResource<CoachingPlanEligibilityResult>(
    'discover/coaching_plan/eligible',
  );

  const { fetchUser, updateUserAvailability, updateUser } = useUserContextProvider();
  const { allLocales, allActiveLocales } = useDataContext();
  const history = useHistory();
  const user = useUser();
  const isMobile = getIsMobile();
  const { showNotif } = useNotifContext();
  const userAvailability = useUserAvailability();

  const userTimezone = getTimezoneAbbr(user.locale_info.timezone);
  const platform = window?.bridge ? 'ios' : isMobile ? 'mobile' : 'desktop';
  const targetableLocaleIds = allActiveLocales.filter(l => l.large).map(l => l.id);

  useMountEffect(() => {
    window.hj('trigger', 'cohesive');
  });

  useEffect(() => {
    if (currentStep !== AutopilotSteps.CHECK_COACHING_PLAN_ELIGIBILITY) {
      return;
    }
    if (coachingPlanEligibility?.eligible) {
      setCurrentStep(AutopilotSteps.COACHING_PLAN);
    }
    if (coachingPlanEligibility && !coachingPlanEligibility.eligible) {
      setCurrentStep(AutopilotSteps.TIMESLOTS);
    }
  }, [currentStep, coachingPlanEligibility]);

  useEffect(() => {
    setShowCoachingPlanOptIn(!decidedCoachingPlan && currentStep === AutopilotSteps.COACHING_PLAN);
  }, [currentStep]);

  const clickAcceptCoachingPlan = () => {
    setDecidedCoachingPlan(true);
    setSignedUpCoachingPlanType(coachingPlanEligibility?.type);
    setShowCoachingPlanSplash(true);
    setTimeout(() => {
      setShowCoachingPlanSplash(false);
      setCurrentStep(AutopilotSteps.TIMESLOTS);
    }, 3000);
  };

  const clickDeclineCoachingPlan = () => {
    setDecidedCoachingPlan(true);
    setCurrentStep(AutopilotSteps.TIMESLOTS);
  };

  const submitAutopilot = async () => {
    if (!selectedTimeslots.length) {
      return;
    }
    setIsLoading(true);
    postTestActivity('new-user-autopilot', `${platform}-accept`);
    const videoNeighborhood = allLocales.find(n => n.id === user.locale)?.videoNeighborhood;
    const res = await postAvailability({
      locale: user.locale,
      timeslots: selectedTimeslots,
      neighborhoods: [videoNeighborhood || 0],
      numberOfMeetings: selectedTimeslots.length,
      autopilotCadence: DEFAULT_CADENCE.value,
      autopilot: true,
      targetLocale: isLocaleTargeted ? user.locale : null,
    });
    if (res.ok) {
      if (signedUpCoachingPlanType) {
        await postAcceptCoachingPlan(signedUpCoachingPlanType);
      }
      await Promise.all([fetchUser(), updateUserAvailability()]);

      updateUser({
        visualSettings: {
          has_completed_autopilot_flow: true,
        },
      });
      setCurrentStep(AutopilotSteps.CONFIRMATION);
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
  };

  if (currentStep === AutopilotSteps.CONFIRMATION || currentStep === AutopilotSteps.SETTINGS) {
    return (
      <Confirmation
        selectedTimeslots={selectedTimeslots}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        buttonText="Show me where"
        buttonOnClick={() => history.push(addParameterToURL('tab', 'availabilities', '/settings'))}
      />
    );
  }

  if (!coachingPlanEligibility) {
    return <SVGLoader />;
  }
  const LocaleCheckbox = () => (
    <>
      {!userAvailability?.coaching_plan_details?.on_coaching_plan &&
        !signedUpCoachingPlanType &&
        targetableLocaleIds.includes(user.locale) && (
          <Checkbox
            desc={`Introduce me to matches in ${user.locale_info.name}`}
            checked={isLocaleTargeted}
            fullWidth
            containerStyle={{ padding: '24px 0 0 0', alignItems: 'center' }}
            checkboxStyle={{ width: '24px', height: '24px' }}
            setCheckValue={setIsLocaleTargeted}
          />
        )}
    </>
  );

  if (!isMobile) {
    return (
      <GreyPage>
        {coachingPlanEligibility?.details && (
          <>
            <CoachingPlanOptIn
              showCoachingPlan={coachingPlanEligibility?.eligible ?? false}
              accept={clickAcceptCoachingPlan}
              decline={clickDeclineCoachingPlan}
              details={coachingPlanEligibility.details}
              planType={coachingPlanEligibility.type || 0}
            />
            <CoachingPlanSplashModal
              shouldShow={showCoachingPlanSplash}
              handleClose={() => setShowCoachingPlanSplash(false)}
              matches={coachingPlanEligibility.details.matches}
            />
          </>
        )}

        <ModalContainer>
          {!signedUpCoachingPlanType && (
            <>
              <Heading1 style={{ marginTop: margins.size5 }}>Set it and forget it!</Heading1>
              <Text>Let’s put meeting new people on Autopilot!</Text>
              <Text>(You can always adjust this in settings)</Text>
              <img style={{ margin: margins.size4 }} src={autopilotSvg} alt="Calendar" />
            </>
          )}

          {currentStep === AutopilotSteps.TIMESLOTS && (
            <>
              {!signedUpCoachingPlanType && (
                <>
                  <Heading3 style={{ textAlign: 'center' }}>{getHeader(currentStep)}</Heading3>
                  <SubText style={{ textAlign: 'center', paddingBottom: margins.size2 }}>
                    Times are in {userTimezone}
                  </SubText>
                </>
              )}
              {signedUpCoachingPlanType && coachingPlanEligibility?.details && (
                <>
                  <CoachingPlanAvatars matches={coachingPlanEligibility.details.matches} />
                  <Heading1 style={{ textAlign: 'center', marginTop: margins.size4 }}>
                    When would you like to meet your matches each week?
                  </Heading1>
                  <Text style={{ textAlign: 'center', paddingBottom: margins.size2 }}>
                    You can update this at any time!
                    <br /> The times are in {userTimezone}. Each meeting is 45 minutes long.
                  </Text>
                </>
              )}
              <TimesSelector
                selectedTimeslots={selectedTimeslots}
                setSelectedTimeslots={setSelectedTimeslots}
                selectedCadence={DEFAULT_CADENCE.value}
                isModal
              />
              <LocaleCheckbox />
              <Button
                style={{ marginTop: margins.size4 }}
                onClick={submitAutopilot}
                large
                invalid={!selectedTimeslots.length}
                loading={isLoading}
              >
                {signedUpCoachingPlanType ? 'Save my meeting time' : 'Turn on Autopilot'}
              </Button>
            </>
          )}
        </ModalContainer>
      </GreyPage>
    );
  }

  // Mobile view
  if (
    coachingPlanEligibility &&
    coachingPlanEligibility.details &&
    (showCoachingPlanOptIn || showCoachingPlanSplash)
  ) {
    return (
      <CoachingPlanOptInMobile
        accept={clickAcceptCoachingPlan}
        decline={clickDeclineCoachingPlan}
        details={coachingPlanEligibility.details}
        planType={coachingPlanEligibility.type || 0}
      />
    );
  }

  return (
    <DarkCardBackground>
      <FlexColumn alignItems="flex-start" style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        {currentStep === AutopilotSteps.TIMESLOTS &&
          signedUpCoachingPlanType &&
          coachingPlanEligibility?.details?.matches && (
            <>
              <WhitePrompt style={{ width: '100%' }}>
                <CoachingPlanAvatars matches={coachingPlanEligibility.details.matches} />
                <div style={{ marginTop: margins.size2 }}>
                  When would you like to meet your matches each week?
                </div>
              </WhitePrompt>
              <Text color={colors.whiteMain} style={{ padding: margins.size3, paddingTop: 0 }}>
                You’ll still be able to adjust this in settings!
              </Text>
            </>
          )}
        <WhiteCard style={{ width: '100%' }}>
          <WhiteCardHeader title="" onClose={() => history.push('/home')} />
          {currentStep === AutopilotSteps.TIMESLOTS && (
            <div style={{ width: '100%' }}>
              <Heading3 style={{ textAlign: 'center' }}>{getHeader(currentStep)}</Heading3>
              <SubText style={{ textAlign: 'center', paddingBottom: margins.size2 }}>
                Times are in {userTimezone}
              </SubText>

              <TimesSelector
                selectedTimeslots={selectedTimeslots}
                setSelectedTimeslots={setSelectedTimeslots}
                selectedCadence={DEFAULT_CADENCE.value}
              />
              <LocaleCheckbox />
              <Button
                style={{ marginTop: margins.size3 }}
                onClick={submitAutopilot}
                large
                invalid={!selectedTimeslots.length}
                loading={isLoading}
              >
                {signedUpCoachingPlanType ? 'Save my meeting time' : 'Turn on Autopilot'}
              </Button>
            </div>
          )}
        </WhiteCard>
      </FlexColumn>
    </DarkCardBackground>
  );
};

const CoachingPlanSplashModal: React.FC<{
  shouldShow: boolean;
  handleClose: () => void;
  matches: CoachingPlanMatchMeta[];
}> = ({ shouldShow, handleClose, matches }) => (
  <Modal hideScrollbar showModal={shouldShow} handleClose={handleClose}>
    <CoachingPlanSplash matches={matches} />
  </Modal>
);

export const ModalContainer = styled(FlexColumn)`
  margin: auto;
  background-color: ${colors.whiteMain};
  border-radius: 10px;
  padding: ${margins.size4};
`;
