import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components/macro';

import { margins, media, Card, FlexColumn, Text } from 'css/css';

import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { GreyPage } from 'js/components/shared/page-wrappers';
import { SaveAndCancel } from 'js/components/shared/selectors/SaveAndCancel';
import { getCutoffDay } from 'js/components/weekly';
import { useDataContext } from 'js/providers/DataContextProvider';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { getMatchFeedback, postAvailability, postTestActivity } from 'js/util/api';
import { useDidUpdateEffect } from 'js/util/custom-hooks';
import { getParameterByName, INTERNATIONAL_TIME_LOCALES } from 'js/util/util';

import { QuestionHeading } from './Question';

export const AskSignup: React.FC<{
  setShouldAskSignup: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setShouldAskSignup }) => {
  const user = useUser();
  const { locale } = user;
  const { updateUserAvailability, userAvailability } = useUserContextProvider();
  const { allTimeslots, allActiveLocales } = useDataContext();

  const [loading, setLoading] = useState(true);
  const [locked, setLock] = useState(false);
  const [timeslotToShow, setTimeslotToShow] = useState<Moment | undefined>();
  const [timeslots, setTimeslots] = useState<string[]>([]);

  const videoNeighborhood = allActiveLocales.find(obj => obj.id === locale)?.videoNeighborhood || 0;
  const matchFeedbackCode = getParameterByName('feedback_code');
  const ref = getParameterByName('ref');

  // We only want to show the signup if the following conditions are met:
  // 1. The user is directly coming from the call.
  // 2. The user does not have an autopilot signup.
  // 3. The user did not already signup for the timeslot
  // 4. Neighborhood does not have any conflicts with the existing signup
  // 5. Time slot can be inserted to the existing signup without spending any clubpoints
  // 6. The match with passed feedback code corresponds to an available timeslot 1 week in advance.
  // 7. The user is part of the test cohort. (This component will not render if not)
  useEffect(() => {
    const findTimeslotToShow = async () => {
      if (ref !== 'call') return; // 1.
      if (userAvailability) {
        if (userAvailability.autopilot) return; // 2.
        const existingNeighborhoods = new Set(userAvailability.neighborhoods);
        if (
          existingNeighborhoods.size &&
          (existingNeighborhoods.size > 1 || !existingNeighborhoods.has(videoNeighborhood))
        )
          return; // 4
        if (
          userAvailability.target_locale != null ||
          userAvailability.target_role !== null ||
          userAvailability.max_matches > 2
        )
          return; // 5
      }
      const res = await getMatchFeedback();
      if (res.ok) {
        const selectedMatch = res.getJson.find(match => match.feedback_code === matchFeedbackCode);
        const localeTimeslot = allTimeslots.find(a => a.id === locale);
        if (selectedMatch && localeTimeslot) {
          const [latestCurrentTimeslot] = localeTimeslot.timeslots.sort().slice(-1);
          const lastMoment = moment(latestCurrentTimeslot);
          const [startWindow, endWindow] = getCutoffDay({
            homeIsNextWeek: localeTimeslot?.isNextWeek,
            userTimezone: user.locale_info?.timezone || '',
          });

          let testTime = moment(selectedMatch.time_slot_with_exact_time);
          while (testTime <= lastMoment) {
            const formattedTestTime = testTime.format('YYYY-MM-DD HH:mm:ss');
            if (
              localeTimeslot.timeslots.includes(formattedTestTime) &&
              formattedTestTime >= startWindow &&
              formattedTestTime <= endWindow
            ) {
              const existingTimeslots = userAvailability?.time_slots ?? [];
              if (existingTimeslots.includes(testTime.format('YYYY-MM-DD HH:mm:ss'))) break; // 3
              const sortedNewTimeslots = [
                ...existingTimeslots,
                testTime.format('YYYY-MM-DD HH:mm:ss'),
              ].sort((a, b) => moment(a).diff(b));
              setTimeslots(sortedNewTimeslots);
              setTimeslotToShow(testTime); // 3.
              break;
            }
            testTime = testTime.add(7, 'days');
          }
        }
      }
    };

    if (userAvailability && allTimeslots.length && !locked) {
      setLock(true);
      findTimeslotToShow().then(() => setLoading(false));
    }
  }, [userAvailability, allTimeslots]);

  useDidUpdateEffect(() => {
    if (!loading && !timeslotToShow) {
      setShouldAskSignup(false);
    } else {
      postTestActivity('feedback-aggressive-signup', 'seen', matchFeedbackCode || undefined);
    }
  }, [loading]);

  const postWeekly = async () => {
    const targetInterest = userAvailability?.target_interest || undefined;
    const objective = userAvailability?.weekly_objective?.id || undefined;
    const preferTheseCommunities = userAvailability?.organization_ids || [];
    if (locale >= 0 && timeslots.length) {
      const res = await postAvailability({
        timeslots,
        locale,
        neighborhoods: [videoNeighborhood],
        numberOfMeetings: timeslots.length,
        targetInterest,
        objective,
        preferTheseCommunities: preferTheseCommunities.length ? preferTheseCommunities : null,
      });
      if (res.ok) {
        postTestActivity('feedback-aggressive-signup', 'accepted', matchFeedbackCode || undefined);
        updateUserAvailability();
        setShouldAskSignup(false);
      }
    }
  };

  const skipQuestion = () => {
    postTestActivity('feedback-aggressive-signup', 'passed', matchFeedbackCode || undefined);
    setShouldAskSignup(false);
  };

  if (loading || !timeslotToShow)
    return (
      <GreyPage>
        <SVGLoader />
      </GreyPage>
    );

  const shownTime = INTERNATIONAL_TIME_LOCALES.includes(user.locale)
    ? timeslotToShow.format('dddd, MMMM Do, k:mm')
    : timeslotToShow.format('dddd, MMMM Do, h:mma');

  return (
    <GreyPage>
      <Card maxWidth="900px">
        <Container>
          <QuestionHeading style={{ marginBottom: margins.size3 }}>
            Sign up for the same time next week?
          </QuestionHeading>
          <Text style={{ marginBottom: margins.size4 }}>
            Interested in making it a habit?
            <br />
            We can sign you up for the same time next week. You can change this at any time.
          </Text>
          <Text style={{ marginBottom: margins.size3 }}>Sign up for a match on {shownTime}?</Text>
          <SaveAndCancel
            clickCancel={skipQuestion}
            clickSave={postWeekly}
            cancelLabel="Skip"
            saveLabel="Signup"
          />
        </Container>
      </Card>
    </GreyPage>
  );
};

const Container = styled(FlexColumn)`
  padding: ${margins.size7} ${margins.size5};
  max-width: 600px;
  margin: auto;
  ${media.mobile} {
    padding: ${margins.size5} ${margins.size4};
    width: 100%;
  }
`;
