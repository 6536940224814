import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { Transition } from 'react-transition-group';

import {
  MobileScrollFade,
  FlexRow,
  FlexColumn,
  Heading2,
  margins,
  borders,
  media,
  colors,
} from 'css/css';

import { getIsMobile, scrollToTopIfNeeded } from 'js/util/util';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { SearchBar } from 'js/components/shared/SearchBar';
import { useDataContext } from 'js/providers/DataContextProvider';

import { MobilePageHeader } from '../shared/page-wrappers';

import { ConversationPreview } from './ConversationPreview';
import { TRANSITION_DURATION } from './ConversationContainer';

import { ChatTabs } from '.';

export const ConversationList: React.FC<{
  isOpen: boolean;
  setOpenTab: React.Dispatch<React.SetStateAction<ChatTabs>>;
}> = ({ isOpen, setOpenTab }) => {
  const { channels } = useChatContext();
  const [filteredChannels, setFilteredChannels] = useState(channels);
  const { navbarIconClicked, setNavbarIconClicked } = useDataContext();
  const isMobile = getIsMobile();
  const listRef = useRef<HTMLDivElement>(null);

  const transitionStyles: Record<string, Record<string, any>> = {
    entering: { transform: 'translateX(0)', opacity: 1 },
    entered: { transform: 'translateX(0)', opacity: 1 },
    exiting: { transform: 'translateX(-100%)', opacity: 1 },
    exited: { transform: 'translateX(-100%)', opacity: 0 },
  };

  useEffect(() => {
    setFilteredChannels(channels);
  }, [channels]);

  useEffect(() => {
    scrollToTopIfNeeded(navbarIconClicked, 'chat', listRef, setNavbarIconClicked);
  }, [navbarIconClicked]);

  const doesChannelMatch = (name: string, keyword: string) => {
    if (!keyword.length) {
      return true;
    }
    return name.toLowerCase().includes(keyword.toLowerCase());
  };

  return !channels ? null : (
    <Transition in={isOpen} exit timeout={TRANSITION_DURATION}>
      {state => (
        <Container style={transitionStyles[state]}>
          <HeaderContainer>
            {isMobile ? <MobilePageHeader>Messages</MobilePageHeader> : <Header>Messages</Header>}
          </HeaderContainer>
          {!isMobile && (
            <div style={{ margin: `${margins.size2} 0px`, width: '100%' }}>
              <SearchBar
                onChange={(newUserInput: string) => {
                  const newFilteredChannels = channels.filter(channel =>
                    doesChannelMatch(channel.name, newUserInput),
                  );
                  setFilteredChannels(newFilteredChannels);
                }}
                onBlur={() => setFilteredChannels(channels)}
                clearOnBlur
              />
            </div>
          )}
          <List ref={listRef}>
            {isMobile && <MobileScrollFade />}
            {isMobile && (
              <div style={{ margin: `${margins.size2} 0px`, width: '100%' }}>
                <SearchBar
                  onChange={(newUserInput: string) => {
                    const newFilteredChannels = channels.filter(channel =>
                      doesChannelMatch(channel.name, newUserInput),
                    );
                    setFilteredChannels(newFilteredChannels);
                  }}
                  onBlur={() => setFilteredChannels(channels)}
                  clearOnBlur
                />
              </div>
            )}
            {filteredChannels.map(channel => (
              <ConversationPreview
                key={channel.profileId}
                previewChannel={channel}
                filteredChannels={filteredChannels}
                setOpenTab={setOpenTab}
              />
            ))}
          </List>
        </Container>
      )}
    </Transition>
  );
};

const Container = styled(FlexColumn)`
  width: 360px;
  height: 100%;
  align-items: start;
  border-right: ${borders.standard};

  ${media.mobile} {
    width: 100%;
    padding-top: env(safe-area-inset-top);
    border-right: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 300ms ease-out;
  }
`;

const HeaderContainer = styled(FlexRow)<{ $removeBorder?: boolean }>`
  width: 100%;
  border-bottom: ${p => (p.$removeBorder ? 'none' : borders.standard)};
  background-color: ${colors.whiteMain};
  min-height: 60px;
  ${media.mobile} {
    border-bottom: none;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: env(safe-area-inset-top);
  }
  ${MobilePageHeader} {
    padding-left: ${margins.size3};
    margin-bottom: 0;
  }
`;

const Header = styled(Heading2)`
  padding: ${margins.size3};
  text-align: left;
  margin-bottom: 0;
  font-size: 22px;
`;

const List = styled(FlexColumn)`
  width: 100%;
  padding: ${margins.size2};
  padding-bottom: ${margins.size3};
  overflow-y: scroll;
  user-select: none;
  ${media.mobile} {
    padding-top: 0px;
    overflow-y: auto;
    margin-top: ${margins.size6};
  }
`;
