import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { FlexColumn, margins, media } from 'css/css';

import { useMountEffect, useDidUpdateEffect } from 'js/util/custom-hooks';
import { DeckEndCard } from 'js/components/homepage/fixed-cards/DeckEndCard';
import { PersonalityResultCard } from 'js/components/homepage/fixed-cards/PersonalityResultCard';
import { ImpressionTracker } from 'js/components/shared/ImpressionTracker';
import { useFeedContextProvider } from 'js/providers/FeedProvider';
import { useNotifContext } from 'js/util/notif-context';
import { apiFailure } from 'js/util/strings';
import { postFeedAction } from 'js/util/api';
import { getIsMobile } from 'js/util/util';

import { IntroCard, QuestionCard, PersonCardYesNo, FriendFinderCard, ContentCard } from './cards';
import { FeedProgressBar } from './ProgressBar';
import { HeaderCard } from './HeaderCard';
import { CARD_TRANSITION_LEAVE_DURATION, explanationHeaders } from './constants';
import {
  AllCardTypes,
  EndorsementFeed,
  PersonalityFeed,
  RegularFeed,
  DiscoverBookingFeed,
  Card,
  FeedActionType,
} from './types';
import { EndorsementCard } from './cards/EndorsementCard';
import { DiscoverBookingCard } from './cards/DiscoverBookingCard';
import { useDiscoverBookingModal } from './DiscoverBookingModal';

interface FeedDeck {
  feed: EndorsementFeed | PersonalityFeed | RegularFeed | DiscoverBookingFeed;
  completedCardIds: Set<string>;
  completeCard: (cardId: string) => void;
  deckCompletedCallback?: () => void;
  openHeader?: (continuation?: () => void) => void;
  setOpenHeader: React.Dispatch<
    React.SetStateAction<((continuation?: (() => void) | undefined) => void) | undefined>
  >;
  closeHeader?: (continuation?: () => void) => void;
  setCloseHeader: React.Dispatch<
    React.SetStateAction<((continuation?: (() => void) | undefined) => void) | undefined>
  >;
}

export const FeedDeck: React.FC<FeedDeck> = ({
  feed,
  completedCardIds,
  completeCard,
  deckCompletedCallback,
  openHeader,
  setOpenHeader,
  closeHeader,
  setCloseHeader,
}) => {
  const {
    initialFeedLength,
    shouldShowDeckNotif,
    setShouldShowDeckNotif,
  } = useFeedContextProvider();
  const { showNotif } = useNotifContext();

  const nonCompletedCards: typeof feed.cards = (feed.cards as any).filter(
    (card: Card) => !completedCardIds.has(card.card_id),
  );
  const [hasCompletedSomeCards, setHasCompletedSomeCards] = useState(false);
  const [wasHeaderOpened, setWasHeaderOpened] = useState(false);
  const [currentCard, setCurrentCard] = useState(nonCompletedCards[0]);
  const [shouldHideProgressBar, setShouldHideProgressBar] = useState(false);

  const [openDiscoverBookingModal, discoverBookingModal] = useDiscoverBookingModal();

  const cardNum = completedCardIds.size;
  const hasHitDailyGoal = feed.session === 'Regular' && cardNum >= initialFeedLength;
  const isCompleted = currentCard === undefined;
  const cardToShow = !isCompleted ? (
    getCardElements(
      currentCard,
      completeCard,
      feed.session === 'Endorsement',
      setShouldHideProgressBar,
      closeHeader,
      feed.session === 'DiscoverBooking' ? feed.fetchSuggestion : undefined,
    )
  ) : feed.session !== 'Personality' ? (
    <DeckEndCard feedSession={feed.session} />
  ) : null;
  const hasProgressBar = feed.session !== 'Endorsement' && feed.session !== 'DiscoverBooking';
  const hasExplanationHeader =
    hasProgressBar && !isCompleted && currentCard.card_type !== AllCardTypes.DiscoverBooking;
  const isOnHomePage =
    feed.session === 'DiscoverBooking' ||
    feed.session === 'Personality' ||
    feed.session === 'Regular';

  const boundIfRegularSession = (n: number) =>
    feed.session === 'Regular' ? Math.min(n, initialFeedLength) : n;

  const turnOffNotif = () => {
    if (shouldShowDeckNotif) {
      setShouldShowDeckNotif(false);
    }
  };

  const openHeaderWithAction = () => {
    if (openHeader) {
      openHeader();
    }
    if ('id' in currentCard) {
      postFeedAction(currentCard.id, { type: FeedActionType.GetExplanation });
    }
  };

  useMountEffect(() => {
    if (feed.cards === undefined) {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }

    if (feed.session === 'DiscoverBooking' && feed.isFirstCard) {
      openDiscoverBookingModal();
    }

    return turnOffNotif;
  });

  useDidUpdateEffect(() => {
    const newCurrentCard = nonCompletedCards[0];
    setCurrentCard(newCurrentCard);
    if (newCurrentCard !== undefined && 'id' in newCurrentCard) {
      postFeedAction(newCurrentCard.id, { type: FeedActionType.Load });
    }
    setHasCompletedSomeCards(true);
  }, [nonCompletedCards.length]);

  useDidUpdateEffect(() => {
    if (deckCompletedCallback) {
      setTimeout(deckCompletedCallback, CARD_TRANSITION_LEAVE_DURATION);
    }
  }, [isCompleted]);

  useEffect(() => {
    if (!wasHeaderOpened && openHeader !== undefined && !isCompleted) {
      setWasHeaderOpened(true);
      setTimeout(() => openHeader(), 400);
    }
  }, [openHeader, isCompleted]);

  const progressBar =
    hasProgressBar && !shouldHideProgressBar ? (
      <FeedProgressBar
        key="deckCard"
        numCompleted={boundIfRegularSession(cardNum)}
        total={boundIfRegularSession(feed.cards.length)}
        openHeader={hasExplanationHeader ? openHeaderWithAction : undefined}
        closeHeader={closeHeader}
        isDisplayed={!isCompleted}
      />
    ) : null;

  const headerCard = hasExplanationHeader ? (
    <HeaderCard
      heading={
        hasHitDailyGoal
          ? 'You hit your daily goal!'
          : explanationHeaders[currentCard.card_type].heading
      }
      desc={
        hasHitDailyGoal
          ? 'Come back tomorrow to keep your streak up.'
          : currentCard.card_type === AllCardTypes.FriendFinder
          ? explanationHeaders[currentCard.card_type].desc(currentCard.data.user.first_name)
          : explanationHeaders[currentCard.card_type].desc
      }
      openHeader={openHeader}
      setOpenHeader={setOpenHeader}
      closeHeader={closeHeader}
      setCloseHeader={setCloseHeader}
    />
  ) : null;

  const feedDeck =
    feed.session === 'Personality' && isCompleted ? (
      <PersonalityResultCard isFirstTime={hasCompletedSomeCards} />
    ) : (
      <>
        {progressBar}
        {headerCard}
        {cardToShow}
        {feed.session !== 'Endorsement' && feed.session !== 'Personality' && discoverBookingModal}
      </>
    );

  return isOnHomePage ? <HomeFeedDeckContainer>{feedDeck}</HomeFeedDeckContainer> : feedDeck;
};

const HomeFeedDeckContainer = styled(FlexColumn)`
  height: 100%;
  ${media.desktop} {
    padding: ${margins.size4} 0px;
    overflow-y: auto;
  }
`;

const getCardElements = (
  card: Card,
  completeCard: (cardId: string) => void,
  isEndorsementFeed: boolean,
  setShouldHideProgressBar: React.Dispatch<React.SetStateAction<boolean>>,
  closeHeader?: (continuation?: () => void) => void,
  fetchSuggestion?: () => Promise<void>,
) => {
  const isMobile = getIsMobile();

  let trackedCard = null;

  switch (card.card_type) {
    case AllCardTypes.Similar:
    case AllCardTypes.Discover:
      trackedCard = <PersonCardYesNo card={card} completeCard={completeCard} />;

      break;
    case AllCardTypes.EndorsementSuggestion:
      trackedCard = (
        <EndorsementCard
          deckCardId={'id' in card ? card.id : undefined}
          cardId={card.card_id}
          data={card.data}
          completeCard={completeCard}
          feedSession={isEndorsementFeed ? 'Endorsement' : 'Regular'}
        />
      );
      break;
    case AllCardTypes.IntroSuggestion:
      trackedCard = (
        <IntroCard
          deckCardId={card.id}
          cardId={card.card_id}
          data={card.data}
          completeCard={completeCard}
        />
      );
      break;
    case AllCardTypes.Personality:
    case AllCardTypes.Survey:
      trackedCard = <QuestionCard card={card} completeCard={completeCard} />;
      break;
    case AllCardTypes.FriendFinder:
      trackedCard = (
        <FriendFinderCard
          deckCardId={card.id}
          cardId={card.card_id}
          data={card.data}
          completeCard={completeCard}
        />
      );
      break;
    case AllCardTypes.DiscoverBooking:
      trackedCard = (
        <DiscoverBookingCard
          deckCardId={card.id}
          cardId={card.card_id}
          data={card.data}
          completeCard={completeCard}
          fetchSuggestion={fetchSuggestion}
        />
      );
      break;
    case AllCardTypes.Content:
      trackedCard = (
        <ContentCard
          deckCardId={card.id}
          cardId={card.card_id}
          data={card.data}
          completeCard={completeCard}
        />
      );
      break;
    default:
  }
  return (
    <ImpressionTracker
      key={card.card_id}
      impressionId={card.card_id}
      style={{
        display: 'inline',
        flex: 1,
        width: '100%',
        ...(isMobile ? { overflow: 'hidden' } : {}),
      }}
      handleClick={() => {
        if (closeHeader) {
          closeHeader();
        }
        if ('id' in card) {
          postFeedAction(card.id, { type: FeedActionType.Click });
        }
      }}
    >
      {trackedCard}
    </ImpressionTracker>
  );
};
