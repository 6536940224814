import { useState, useRef, MouseEvent, TouchEvent, UIEvent } from 'react';
import { CSSProperties } from 'styled-components/macro';

const EXPAND_DRAG_PX = 60;

export function useFullscreenOnScroll() {
  const [activeInitialPos, setActiveInitialPos] = useState<{
    rect: DOMRect;
    scrollHeight: number;
  } | null>(null);
  const [scrolled, setScrolled] = useState<number | null>(null);
  const [activePct, setActivePct] = useState(0);
  const held = useRef(false);
  const touchY = useRef(0);

  const momentumScrollTimerRef = useRef<NodeJS.Timeout>();

  const onSettleActive = (e: EventTarget) => {
    const el = e as HTMLDivElement;
    if (held.current) {
      return;
    }
    if (!el || el.scrollTop === 0 || el.scrollTop >= EXPAND_DRAG_PX) {
      return;
    }
    const target = el.scrollTop > EXPAND_DRAG_PX / 2 ? EXPAND_DRAG_PX : 0;
    const step =
      Math.sign(target - el.scrollTop) * Math.abs(Math.max(1, (target - el.scrollTop) / 20));
    const last = { value: el.scrollTop };

    const interval = setInterval(() => {
      if (Math.abs(el.scrollTop - last.value) > 1 || el.scrollTop === target) {
        clearInterval(interval);
        return;
      }
      const next = Math.floor(Math.max(0, Math.min(EXPAND_DRAG_PX, el.scrollTop + step)));
      el.scrollTop = next;
      last.value = next;
    }, 1);
  };

  const containerStyles: CSSProperties =
    activeInitialPos && activePct > 0
      ? {
          position: 'absolute',
          top: activeInitialPos.rect.y * (1 - activePct) + 0 * activePct,
          left: activeInitialPos.rect.x * (1 - activePct) + 0 * activePct,
          width: activeInitialPos.rect.width * (1 - activePct) + window.innerWidth * activePct,
          height: activeInitialPos.rect.height * (1 - activePct) + window.innerHeight * activePct,
          boxShadow: `0 ${activePct * 4}px ${activePct * 30}px rgba(0,0,0,0.4)`,
          paddingBottom: `calc(max(${window.innerHeight -
            activeInitialPos.scrollHeight +
            (scrolled !== null ? EXPAND_DRAG_PX - scrolled : 0)}px, env(safe-area-inset-bottom)))`,
          paddingTop: scrolled !== null ? scrolled : EXPAND_DRAG_PX * activePct,
          WebkitTransform: 'translate3d(0,0,0)',
          zIndex: 4,
        }
      : { WebkitTransform: 'translate3d(0,0,0)' };

  const containerProps = {
    onTouchStart: (e: TouchEvent) => {
      if ((e.currentTarget as HTMLElement).scrollTop === 0) {
        touchY.current = e.touches[0].screenY;
      }
      held.current = true;
    },
    onTouchMove: (e: TouchEvent) => {
      const d = touchY.current - e.touches[0].screenY;
      const el = e.currentTarget as HTMLElement;
      if (el.scrollTop === 0 && d < 0) {
        el.style.overflowY = 'hidden';
      }
    },
    onTouchEnd: (e: TouchEvent) => {
      (e.currentTarget as HTMLElement).style.overflowY = 'scroll';
    },
    onMouseUpCapture: (e: MouseEvent) => {
      held.current = false;
      if (e.currentTarget) onSettleActive(e.currentTarget);
    },
    onTouchEndCapture: (e: TouchEvent) => {
      held.current = false;
      if (e.currentTarget) onSettleActive(e.currentTarget);
    },
    onScroll: (e: UIEvent) => {
      const el = e.currentTarget;
      if (activePct === 0) {
        setActiveInitialPos({ rect: el.getBoundingClientRect(), scrollHeight: el.scrollHeight });
      }
      setActivePct(Math.min(1, Math.max(0, el.scrollTop / EXPAND_DRAG_PX)));

      const scrollPercentage = el.scrollTop / (el.scrollHeight - el.clientHeight);
      if (scrollPercentage < 0.95) {
        setScrolled(null);
      } else {
        if (activePct !== 1 || scrolled !== null) {
          setScrolled(el.scrollTop);
        }
        setActivePct(1);
      }

      if (momentumScrollTimerRef.current) {
        clearTimeout(momentumScrollTimerRef.current);
      }
      momentumScrollTimerRef.current = setTimeout(() => onSettleActive(el), 400);
    },
  };
  return {
    containerStyles,
    containerProps,
  };
}
