import { Cookies } from 'react-cookie';
import React from 'react';
import imageCompression from 'browser-image-compression';
import moment, { Moment } from 'moment';

import { MEDIA_SIZE } from 'css/css';

import { ProductUser, UserMe } from 'types/user';
import { NetworkUser } from 'types/matches';

import brainstorm from 'img/registration/objectives/brainstorm-with-peers.svg';
import busDev from 'img/registration/objectives/business-development.svg';
import newProjects from 'img/registration/objectives/explore-new-projects.svg';
import findJob from 'img/registration/objectives/find-job.svg';
import cofounder from 'img/registration/objectives/find-a-cofounder-or-partner.svg';
import growTeam from 'img/registration/objectives/grow-your-team.svg';
import invest from 'img/registration/objectives/invest.svg';
import mentor from 'img/registration/objectives/mentor-others.svg';
import oragnizeEvents from 'img/registration/objectives/organize-events.svg';
import raiseFunding from 'img/registration/objectives/raise-funding.svg';
import startCompany from 'img/registration/objectives/start-a-company.svg';
import meetPeople from 'img/registration/objectives/meet-interesting-people.svg';
import newCity from 'img/registration/objectives/new-to-the-city.svg';
import newPerspectives from 'img/registration/objectives/explore-new-perspectives.svg';
import sanFrancisco from 'img/registration/cities/sf-bay.svg';
import toronto from 'img/registration/cities/toronto.svg';
import losAngeles from 'img/registration/cities/greater-la.svg';
import boston from 'img/registration/cities/boston.svg';
import austin from 'img/registration/cities/austin.svg';
import newYork from 'img/registration/cities/new-york-city.svg';
import seattle from 'img/registration/cities/seattle.svg';
import london from 'img/registration/cities/london.svg';
import chicago from 'img/registration/cities/chicago.svg';
import denver from 'img/registration/cities/denver.svg';
import telAviv from 'img/registration/cities/tel-aviv.svg';
import washingtonDC from 'img/registration/cities/washington-dc.svg';
import bangalore from 'img/registration/cities/bangalore.svg';
import ffc from 'img/homepage/ffc.svg';
import httti from 'img/homepage/httti.svg';
import ppde from 'img/homepage/ppde.svg';
import isi from 'img/homepage/isi.svg';
import yposf from 'img/homepage/yposf.svg';
import producttanksf from 'img/homepage/producttanksf.svg';
import dreamers from 'img/homepage/dreamers.svg';
import systers from 'img/homepage/systers.svg';
import harvard from 'img/homepage/harvard.svg';
import google from 'img/homepage/google.svg';
import yc from 'img/homepage/yc.svg';
import upenn from 'img/homepage/upenn.svg';
import nyu from 'img/homepage/nyu.svg';
import princeton from 'img/homepage/princeton.svg';
import lse from 'img/homepage/lse.svg';
import ucla from 'img/homepage/ucla.svg';
import stanford from 'img/homepage/stanford.svg';
import oxford from 'img/homepage/oxford.svg';
import lbs from 'img/homepage/lbs.svg';
import mit from 'img/homepage/mit.svg';
import carnegie from 'img/homepage/carnegie.svg';
import cornell from 'img/homepage/cornell.svg';
import uchicago from 'img/homepage/uchicago.svg';
import cambridge from 'img/homepage/cambridge.svg';
import microsoft from 'img/homepage/microsoft.svg';
import wesleyan from 'img/homepage/wesleyan.svg';
import columbia from 'img/homepage/columbia.svg';
import yale from 'img/homepage/yale.svg';
import umichigan from 'img/homepage/umichigan.svg';
import brown from 'img/homepage/brown.svg';
import schwarzman from 'img/homepage/schwarzman.svg';
import bunch from 'img/homepage/bunch.svg';
import ucberkeley from 'img/homepage/ucberkeley.svg';
import hbs from 'img/homepage/hbs.svg';
import mckinsey from 'img/homepage/mckinsey.svg';
import cmute from 'img/homepage/cmute.svg';
import kellogg from 'img/homepage/kellogg.svg';
import bcg from 'img/homepage/bcg.svg';
import whartonexecs from 'img/homepage/whartonexecs.svg';
import wip from 'img/homepage/wip.svg';
import ucl from 'img/homepage/ucl.svg';
import hbs2020 from 'img/homepage/hbs2020.svg';
import techstars from 'img/homepage/techstars.svg';
import utaustin from 'img/homepage/utaustin.svg';
import dartmouth from 'img/homepage/dartmouth.svg';
import duke from 'img/homepage/duke.svg';
import northwestern from 'img/homepage/northwestern.svg';
import wisconsin from 'img/homepage/wisconsin.svg';
import biomimicry from 'img/homepage/biomimicry.svg';
import startingbloc from 'img/homepage/startingbloc.svg';
import m13 from 'img/homepage/m13.svg';
import toptier from 'img/homepage/toptier.svg';
import minerva from 'img/homepage/minerva.svg';
import cryptocurry from 'img/homepage/cryptocurry.svg';
import usc from 'img/homepage/usc.svg';
import designclub from 'img/homepage/designclub.svg';
import marketingclub from 'img/homepage/marketingclub.svg';
import diversityinclusionclub from 'img/homepage/diversityinclusionclub.svg';
import lifesciencesclub from 'img/homepage/lifesciencesclub.svg';
import cryptoblockchainclub from 'img/homepage/cryptoblockchainclub.svg';
import reengage from 'img/homepage/reengage.svg';
import apx from 'img/homepage/apx.svg';
import futureisfemale from 'img/homepage/futureisfemale.svg';
import serialmarketers from 'img/homepage/serialmarketers.svg';
import iima from 'img/homepage/iima.svg';
import isb from 'img/homepage/isb.svg';
import advancedmaterials from 'img/homepage/advancedmaterials.svg';
import socialimpactclub from 'img/homepage/socialimpactclub.svg';
import tks from 'img/homepage/tks.svg';
import leaninnyc from 'img/homepage/leaninnyc.svg';
import trends from 'img/homepage/trends.svg';
import defaultAvatar1 from 'img/shared/default-picture1.svg';
import defaultAvatar2 from 'img/shared/default-picture2.svg';
import defaultAvatar3 from 'img/shared/default-picture3.svg';
import defaultAvatar4 from 'img/shared/default-picture4.svg';
import defaultAvatar5 from 'img/shared/default-picture5.svg';

import { urls } from 'config/urls';

export const MIN_HEADLINE_LENGTH = 15;
export const MAX_HEADLINE_LENGTH = 300;
export const PASSED_TOKEN_NAME = 'token';
export const PASSED_REFRESH_TOKEN_NAME = 'r_token';
export const TOKEN_COOKIE_NAME = 'elliotToken';

export const isProduction = () => process.env.NODE_ENV === 'production';

export const GOOGLE_CLIENT_ID =
  '245808737532-k2hpl4mmrov6257fc36rp74040r21hhc.apps.googleusercontent.com';

export const getParameterByName = (name: any, url = window.location.href) => {
  const paramName = name.replace(/[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${paramName}(=([^&#]*)|&|#|$)`);

  const results = regex.exec(url);
  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const URL = (() => {
  if (isProduction()) return urls.production;

  // Manual overrides
  const passedServer = getParameterByName('server');
  if (passedServer && urls[passedServer]) return urls[passedServer];

  // Automatic fallbacks
  // 1- Test servers, including staging. Specifically do not allow production.
  const subdomain = window.location.host.split('.')[0];
  if (subdomain && subdomain !== 'production' && urls[subdomain]) return urls[subdomain];

  // 2- Netlify deploy previews
  if (process.env.NODE_ENV === 'staging') return urls.staging;

  // 3- Local development
  return urls.development;
})();

export const getElliotToken = () => {
  let lsToken: string | null = null;
  try {
    lsToken = ('localStorage' in window && window.localStorage.getItem(TOKEN_COOKIE_NAME)) || null;
  } catch (err) {
    // localstorage is present but blocked by user's browser settings
  }
  const cookieToken = new Cookies().get(TOKEN_COOKIE_NAME) || null;
  const passedToken = getParameterByName('token');
  if (passedToken) {
    setElliotToken(passedToken);
  }
  return passedToken || lsToken || cookieToken;
};

export const setElliotToken = (value: string | null) => {
  try {
    if (value) {
      window.localStorage.setItem(TOKEN_COOKIE_NAME, value);
      if (window.bridge) window.bridge.setAuthToken(value);
    } else {
      window.localStorage.removeItem(TOKEN_COOKIE_NAME);
      if (window.bridge) window.bridge.removeAuthToken();
    }
    new Cookies().remove(TOKEN_COOKIE_NAME);
  } catch (err) {
    if (value) {
      new Cookies().set(TOKEN_COOKIE_NAME, value);
    }
  }
};

export const getTokenURLParameter = () => getParameterByName(PASSED_TOKEN_NAME);

export const addParameterToURL = (key: any, value: any, url = '') => {
  let newUrl = '';
  if (!key || value === null || value === '') {
    return url;
  }
  if (!url) newUrl = window.location.href;
  else newUrl = url;
  newUrl += `${newUrl.split('?')[1] ? '&' : '?'}${key}=${value}`;
  return newUrl;
};

export const lunchclubTypes = {
  verified: 2,
  unverified: 3,
  waitlisted: 4,
};

/*
 *  Workaround for copying text to clipboard on iOS browsers.
 *  Source: https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
 * */
export const copyElementToClipboard = (elementId: any) => {
  const el = document.getElementById(elementId);
  const range = document.createRange();
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  el.contentEditable = true;
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  el.readOnly = false;
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'HTMLElement | null' is not assig... Remove this comment to see the full error message
  range.selectNodeContents(el);
  const s = window.getSelection();
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  s.removeAllRanges();
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  s.addRange(range);
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
  document.execCommand('copy');
};

export const copyStringToClipboard = (str: any) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('id', 'toBeCopied');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  // handle iOS as a special case
  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    copyElementToClipboard('toBeCopied');
    document.body.removeChild(el);
  } else {
    const selected =
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      document.getSelection().removeAllRanges();
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      document.getSelection().addRange(selected);
    }
  }
};

export const changeSlashDelimitedDateToDot = (dateString: any) =>
  dateString ? dateString.split('/').join('.') : dateString;

export const isLetter = (letter: any) => {
  return letter.toLowerCase() !== letter.toUpperCase();
};

export const runAndScroll = async (fn: any) => {
  await fn();
  window.scrollTo(0, 0);
};

export const cleanNames = (name: any) => {
  /*
    Removes all non-alpha characters except those in validChars, removes all trailing and prefixed
    whitespace, and capitalizes the first character in each name while lowercasing others
    (if the name had > 2 uppercase chars)
  */
  if (name) {
    const validChars = ['-', ' ', ',', '.'];
    const filteredName = name
      .split('')
      .filter((letter: any) => isLetter(letter) || validChars.includes(letter))
      .join('')
      .trim();

    const nameArray = filteredName.split(' ');

    const capitalizedNames = nameArray
      .map((curName: any) => {
        if (!curName) return '';
        const firstLetter = curName[0].toUpperCase();
        const restOfLetters =
          curName.replace(/[^A-Z]/g, '').length > 2
            ? curName.substring(1).toLowerCase()
            : curName.substring(1);
        return firstLetter + restOfLetters;
      })
      .join(' ');
    return capitalizedNames;
  }

  return name;
};

// From: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
export const validateEmail = (email: any) => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const addHttpsToURL = (url: any) => {
  if (url) {
    const cleaned = url.trim().toLowerCase();
    if (cleaned) {
      if (cleaned.substring(0, 8) === 'https://' || cleaned.substring(0, 7) === 'http://')
        return cleaned;
      return `https://${cleaned}`;
    }
  }
  return url;
};

export const validateHeadlineLength = (headlineLength: any, toBeRemoved = 0) => {
  return (
    headlineLength - toBeRemoved >= MIN_HEADLINE_LENGTH &&
    headlineLength - toBeRemoved <= MAX_HEADLINE_LENGTH
  );
};

export const validateSocial = (link: any, platform: any) => {
  const lowerLink = link.toLowerCase();
  const platformSuffix =
    platform === 'angel' ? '.co/' : platform === 'linkedin' ? '.com/in/' : '.com/';

  return (
    lowerLink.includes(`${platform}${platformSuffix}`) &&
    lowerLink.indexOf(platformSuffix) < lowerLink.length - platformSuffix.length
  );
};

export const onEnterKey = (e: React.KeyboardEvent, cb: () => any) => {
  if (e.key === 'Enter' && cb) {
    cb();
  }
};

// Use function for redirects if back button should ignore prev page
export const replaceHistory = (history: any, url: string) => {
  history.replace(url);
};

export const compressImage = (file: any) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  return imageCompression(file, options).catch(() => false);
};

export const objectiveIcons = [
  { imgSrc: brainstorm, id: 0 },
  { imgSrc: growTeam, id: 1 },
  { imgSrc: startCompany, id: 2 },
  { imgSrc: findJob, id: 15 },
  { imgSrc: busDev, id: 5 },
  { imgSrc: invest, id: 6 },
  { imgSrc: newProjects, id: 7 },
  { imgSrc: mentor, id: 8 },
  { imgSrc: oragnizeEvents, id: 9 },
  { imgSrc: raiseFunding, id: 10 },
  { imgSrc: cofounder, id: 11 },
  { imgSrc: meetPeople, id: 12 },
  { imgSrc: newCity, id: 13 },
  { imgSrc: newPerspectives, id: 14 },
];

export const extractProfiles = (user: UserMe | ProductUser | NetworkUser) => {
  const profiles = {
    twitter: '',
    linkedin: '',
    angellist: '',
    github: '',
    instagram: '',
    personal: '',
  };
  if (!user || isEmptyObject(user)) return profiles;

  if ('twitter_url' in user && user.twitter_url) {
    profiles.twitter = user.twitter_url;
  }

  if ('linkedin_url' in user && user.linkedin_url) {
    profiles.linkedin = user.linkedin_url;
  }

  if ('angellist_url' in user && user.angellist_url) {
    profiles.angellist = user.angellist_url;
  }

  if ('github_url' in user && user.github_url) {
    profiles.github = user.github_url;
  }

  if ('instagram_url' in user && user.instagram_url) {
    profiles.instagram = user.instagram_url;
  }

  if ('personal_url' in user && user.personal_url) {
    profiles.personal = user.personal_url;
  }

  return profiles;
};

export const extractTwitterHandle = (user: UserMe | ProductUser) => {
  const twitterLink = extractProfiles(user).twitter;
  if (twitterLink) {
    const twitterArray = twitterLink.split('/');
    return '@'.concat(twitterArray[twitterArray.length - 1]);
  }
  return null;
};

export const hubArtIcons = [
  { imgSrc: sanFrancisco, id: 0 },
  { imgSrc: losAngeles, id: 1 },
  { imgSrc: toronto, id: 2 },
  { imgSrc: newYork, id: 6 },
  { imgSrc: austin, id: 4 },
  { imgSrc: boston, id: 7 },
  { imgSrc: london, id: 8 },
  { imgSrc: washingtonDC, id: 9 },
  { imgSrc: seattle, id: 10 },
  { imgSrc: chicago, id: 11 },
  { imgSrc: denver, id: 12 },
  { imgSrc: telAviv, id: 30 },
  { imgSrc: bangalore, id: 19 },
];

export const INVITE_SOURCES = {
  MANUAL: 0,
  POND: 1,
  PROFILE_MUTUAL: 2,
  PROFILE_POND: 3,
  PROFILE_MANUAL: 4,
  POND_ALL: 5,
  FEED: 6,
  FEED_COMMUNITY_POND: 7,
  WEEKLY: 8,
  FEED_INTRO_SUGGESTION: 9,
  FEED_DECK_ALL: 10,
  CALL_WAITROOM: 11,
  MUTUAL_CONTACT_DO_NOTIF: 12,
  FEED_DECK_BINARY: 13,
  FEED_DECK_NOTES: 14,
  NETWORK_SCORE_GUESS: 15,
  RECONNECT: 16,
  RECONNECT_HIDDEN: 17,
};

export const INTRO_RESPONSES = {
  NONE: 0,
  ACCEPT: 1,
  REJECT: 2,
};

export const COMMUNITY_CODE_TO_ID: any = {
  femalefounders: 1,
  htttinvestors: 2,
  ppdsf: 3,
  inclusivespeaker: 4,
  yposf: 5,
  producttank: 6,
  dreamers: 7,
  systers: 8,
  ycombinator: 9,
  harvard: 10,
  googler: 11,
  upenn: 12,
  nyu: 13,
  princeton: 14,
  lse: 15,
  ucla: 16,
  stanford: 17,
  oxford: 18,
  lbs: 19,
  mit: 20,
  carnegie: 21,
  cornell: 22,
  uchicago: 23,
  cambridge: 24,
  microsoft: 25,
  wesleyan: 26,
  columbia: 27,
  yale: 28,
  umichigan: 29,
  brown: 30,
  schwarzman: 31,
  bunch: 32,
  ucberkeley: 33,
  hbs: 34,
  mckinsey: 35,
  cmute: 36,
  kellogg: 37,
  bcg: 38,
  whartonexecs: 39,
  wip: 40,
  ucl: 41,
  hbs2020: 42,
  techstars: 43,
  utaustin: 44,
  dartmouth: 45,
  duke: 46,
  northwestern: 47,
  wisconsin: 48,
  biomimicry: 49,
  startingbloc: 50,
  m13: 51,
  toptier: 52,
  minerva: 53,
  cryptocurry: 54,
  usc: 55,
  designclub: 56,
  marketingclub: 57,
  diversityinclusionclub: 58,
  lifesciencesclub: 59,
  cryptoblockchainclub: 60,
  reengage: 61,
  apx: 62,
  futureisfemale: 63,
  serialmarketers: 64,
  iima: 65,
  isb: 66,
  advancedmaterials: 67,
  socialimpactclub: 68,
  tks: 69,
  leaninnyc: 70,
  trends: 71,
};

export const COMMUNITIES = {
  [COMMUNITY_CODE_TO_ID.femalefounders]: {
    id: COMMUNITY_CODE_TO_ID.femalefounders,
    name: 'Female Founders',
    img: ffc,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.htttinvestors]: {
    id: COMMUNITY_CODE_TO_ID.htttinvestors,
    name: 'How To Talk To Investors',
    img: httti,
  },
  [COMMUNITY_CODE_TO_ID.ppdsf]: {
    id: COMMUNITY_CODE_TO_ID.ppdsf,
    name: 'Personal & Professional Development Events SF',
    img: ppde,
  },
  [COMMUNITY_CODE_TO_ID.inclusivespeaker]: {
    id: COMMUNITY_CODE_TO_ID.inclusivespeaker,
    name: 'Inclusivity Speaker Initiative',
    img: isi,
  },
  [COMMUNITY_CODE_TO_ID.yposf]: {
    id: COMMUNITY_CODE_TO_ID.yposf,
    name: 'Young Professionals of SF (YPOSF)',
    img: yposf,
  },
  [COMMUNITY_CODE_TO_ID.producttank]: {
    id: COMMUNITY_CODE_TO_ID.producttank,
    name: 'ProductTank',
    img: producttanksf,
  },
  [COMMUNITY_CODE_TO_ID.dreamers]: {
    id: COMMUNITY_CODE_TO_ID.dreamers,
    name: 'Dreamers // Doers',
    img: dreamers,
  },
  [COMMUNITY_CODE_TO_ID.systers]: {
    id: COMMUNITY_CODE_TO_ID.systers,
    name: 'Systers',
    img: systers,
  },
  [COMMUNITY_CODE_TO_ID.ycombinator]: {
    id: COMMUNITY_CODE_TO_ID.ycombinator,
    name: 'Y Combinator',
    img: yc,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.harvard]: {
    id: COMMUNITY_CODE_TO_ID.harvard,
    name: 'Harvard',
    img: harvard,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.googler]: {
    id: COMMUNITY_CODE_TO_ID.googler,
    name: 'Googler/Xoogler',
    img: google,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.upenn]: {
    id: COMMUNITY_CODE_TO_ID.upenn,
    name: 'UPenn',
    img: upenn,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.nyu]: {
    id: COMMUNITY_CODE_TO_ID.nyu,
    name: 'NYU',
    img: nyu,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.princeton]: {
    id: COMMUNITY_CODE_TO_ID.princeton,
    name: 'Princeton',
    img: princeton,
  },
  [COMMUNITY_CODE_TO_ID.lse]: {
    id: COMMUNITY_CODE_TO_ID.lse,
    name: 'LSE',
    img: lse,
  },
  [COMMUNITY_CODE_TO_ID.ucla]: {
    id: COMMUNITY_CODE_TO_ID.ucla,
    name: 'UCLA',
    img: ucla,
  },
  [COMMUNITY_CODE_TO_ID.stanford]: {
    id: COMMUNITY_CODE_TO_ID.stanford,
    name: 'Stanford',
    img: stanford,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.oxford]: {
    id: COMMUNITY_CODE_TO_ID.oxford,
    name: 'Oxford',
    img: oxford,
  },
  [COMMUNITY_CODE_TO_ID.lbs]: {
    id: COMMUNITY_CODE_TO_ID.lbs,
    name: 'LBS',
    img: lbs,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.mit]: {
    id: COMMUNITY_CODE_TO_ID.mit,
    name: 'MIT',
    img: mit,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.carnegie]: {
    id: COMMUNITY_CODE_TO_ID.carnegie,
    name: 'Carnegie Mellon',
    img: carnegie,
  },
  [COMMUNITY_CODE_TO_ID.cornell]: {
    id: COMMUNITY_CODE_TO_ID.cornell,
    name: 'Cornell',
    img: cornell,
  },
  [COMMUNITY_CODE_TO_ID.uchicago]: {
    id: COMMUNITY_CODE_TO_ID.uchicago,
    name: 'UChicago',
    img: uchicago,
  },
  [COMMUNITY_CODE_TO_ID.cambridge]: {
    id: COMMUNITY_CODE_TO_ID.cambridge,
    name: 'Cambridge',
    img: cambridge,
  },
  [COMMUNITY_CODE_TO_ID.microsoft]: {
    id: COMMUNITY_CODE_TO_ID.microsoft,
    name: 'Microsoft',
    img: microsoft,
  },
  [COMMUNITY_CODE_TO_ID.wesleyan]: {
    id: COMMUNITY_CODE_TO_ID.wesleyan,
    name: 'Wesleyan',
    img: wesleyan,
  },
  [COMMUNITY_CODE_TO_ID.columbia]: {
    id: COMMUNITY_CODE_TO_ID.columbia,
    name: 'Columbia',
    img: columbia,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.yale]: {
    id: COMMUNITY_CODE_TO_ID.yale,
    name: 'Yale',
    img: yale,
  },
  [COMMUNITY_CODE_TO_ID.umichigan]: {
    id: COMMUNITY_CODE_TO_ID.umichigan,
    name: 'Michigan',
    img: umichigan,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.brown]: {
    id: COMMUNITY_CODE_TO_ID.brown,
    name: 'Brown',
    img: brown,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.schwarzman]: {
    id: COMMUNITY_CODE_TO_ID.schwarzman,
    name: 'Schwarzman Scholars',
    img: schwarzman,
  },
  [COMMUNITY_CODE_TO_ID.bunch]: {
    id: COMMUNITY_CODE_TO_ID.bunch,
    name: 'Bunch of Founders',
    img: bunch,
  },
  [COMMUNITY_CODE_TO_ID.ucberkeley]: {
    id: COMMUNITY_CODE_TO_ID.ucberkeley,
    name: 'UC Berkeley',
    img: ucberkeley,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.hbs]: {
    id: COMMUNITY_CODE_TO_ID.hbs,
    name: 'Harvard Business School',
    img: hbs,
  },
  [COMMUNITY_CODE_TO_ID.mckinsey]: {
    id: COMMUNITY_CODE_TO_ID.mckinsey,
    name: 'Bunch of Founders',
    img: mckinsey,
  },
  [COMMUNITY_CODE_TO_ID.cmute]: {
    id: COMMUNITY_CODE_TO_ID.cmute,
    name: 'CMU TE',
    img: cmute,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.kellogg]: {
    id: COMMUNITY_CODE_TO_ID.kellogg,
    name: 'Kellogg',
    img: kellogg,
  },
  [COMMUNITY_CODE_TO_ID.bcg]: {
    id: COMMUNITY_CODE_TO_ID.bcg,
    name: 'BCG',
    img: bcg,
  },
  [COMMUNITY_CODE_TO_ID.whartonexecs]: {
    id: COMMUNITY_CODE_TO_ID.whartonexecs,
    name: 'Wharton Executive Education',
    img: whartonexecs,
  },
  [COMMUNITY_CODE_TO_ID.wip]: {
    id: COMMUNITY_CODE_TO_ID.wip,
    name: 'Women in Product',
    img: wip,
  },
  [COMMUNITY_CODE_TO_ID.ucl]: {
    id: COMMUNITY_CODE_TO_ID.ucl,
    name: 'UCL',
    img: ucl,
  },
  [COMMUNITY_CODE_TO_ID.hbs2020]: {
    id: COMMUNITY_CODE_TO_ID.hbs2020,
    name: 'HBS 2020',
    img: hbs2020,
  },
  [COMMUNITY_CODE_TO_ID.techstars]: {
    id: COMMUNITY_CODE_TO_ID.techstars,
    name: 'Techstars',
    img: techstars,
  },
  [COMMUNITY_CODE_TO_ID.utaustin]: {
    id: COMMUNITY_CODE_TO_ID.utaustin,
    name: 'UT Austin',
    img: utaustin,
  },
  [COMMUNITY_CODE_TO_ID.dartmouth]: {
    id: COMMUNITY_CODE_TO_ID.dartmouth,
    name: 'Dartmouth',
    img: dartmouth,
  },
  [COMMUNITY_CODE_TO_ID.duke]: {
    id: COMMUNITY_CODE_TO_ID.duke,
    name: 'Duke',
    img: duke,
  },
  [COMMUNITY_CODE_TO_ID.northwestern]: {
    id: COMMUNITY_CODE_TO_ID.northwestern,
    name: 'Northwestern',
    img: northwestern,
  },
  [COMMUNITY_CODE_TO_ID.wisconsin]: {
    id: COMMUNITY_CODE_TO_ID.wisconsin,
    name: 'University of Wisconsin',
    img: wisconsin,
  },
  [COMMUNITY_CODE_TO_ID.biomimicry]: {
    id: COMMUNITY_CODE_TO_ID.biomimicry,
    name: 'Biomimicry Institute',
    img: biomimicry,
  },
  [COMMUNITY_CODE_TO_ID.startingbloc]: {
    id: COMMUNITY_CODE_TO_ID.startingbloc,
    name: 'StartingBloc',
    img: startingbloc,
  },
  [COMMUNITY_CODE_TO_ID.m13]: {
    id: COMMUNITY_CODE_TO_ID.m13,
    name: 'M13',
    img: m13,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.toptier]: {
    id: COMMUNITY_CODE_TO_ID.toptier,
    name: 'Top Tier Impact',
    img: toptier,
  },
  [COMMUNITY_CODE_TO_ID.minerva]: {
    id: COMMUNITY_CODE_TO_ID.minerva,
    name: 'Minerva School',
    img: minerva,
  },
  [COMMUNITY_CODE_TO_ID.cryptocurry]: {
    id: COMMUNITY_CODE_TO_ID.cryptocurry,
    name: 'Crypto Curry',
    img: cryptocurry,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.usc]: {
    id: COMMUNITY_CODE_TO_ID.usc,
    name: 'USC',
    img: usc,
  },
  [COMMUNITY_CODE_TO_ID.designclub]: {
    id: COMMUNITY_CODE_TO_ID.designclub,
    name: 'Design Club',
    img: designclub,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.marketingclub]: {
    id: COMMUNITY_CODE_TO_ID.marketingclub,
    name: 'Marketing Club',
    img: marketingclub,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.diversityinclusionclub]: {
    id: COMMUNITY_CODE_TO_ID.diversityinclusionclub,
    name: 'Diversity & Inclusion Club',
    img: diversityinclusionclub,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.lifesciencesclub]: {
    id: COMMUNITY_CODE_TO_ID.lifesciencesclub,
    name: 'Life Sciences Club',
    img: lifesciencesclub,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.cryptoblockchainclub]: {
    id: COMMUNITY_CODE_TO_ID.cryptoblockchainclub,
    name: 'Crypto & Blockchain Club',
    img: cryptoblockchainclub,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.reengage]: {
    id: COMMUNITY_CODE_TO_ID.reengage,
    name: 'ReEngage 2021',
    img: reengage,
  },
  [COMMUNITY_CODE_TO_ID.apx]: {
    id: COMMUNITY_CODE_TO_ID.apx,
    name: 'APX',
    img: apx,
  },
  [COMMUNITY_CODE_TO_ID.futureisfemale]: {
    id: COMMUNITY_CODE_TO_ID.futureisfemale,
    name: 'Future is Female',
    img: futureisfemale,
  },
  [COMMUNITY_CODE_TO_ID.serialmarketers]: {
    id: COMMUNITY_CODE_TO_ID.serialmarketers,
    name: 'Serial Marketers',
    img: serialmarketers,
  },
  [COMMUNITY_CODE_TO_ID.iima]: {
    id: COMMUNITY_CODE_TO_ID.iima,
    name: 'IIM Ahmedabad',
    img: iima,
  },
  [COMMUNITY_CODE_TO_ID.isb]: {
    id: COMMUNITY_CODE_TO_ID.isb,
    name: 'ISB',
    img: isb,
  },
  [COMMUNITY_CODE_TO_ID.advancedmaterials]: {
    id: COMMUNITY_CODE_TO_ID.advancedmaterials,
    name: 'Innovation Network for Advanced Materials',
    img: advancedmaterials,
  },
  [COMMUNITY_CODE_TO_ID.socialimpactclub]: {
    id: COMMUNITY_CODE_TO_ID.socialimpactclub,
    name: 'Social Impact Club',
    img: socialimpactclub,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.tks]: {
    id: COMMUNITY_CODE_TO_ID.tks,
    name: 'TKS',
    img: tks,
  },
  [COMMUNITY_CODE_TO_ID.leaninnyc]: {
    id: COMMUNITY_CODE_TO_ID.leaninnyc,
    name: 'Lean In NYC',
    img: leaninnyc,
    largeCommunity: true,
  },
  [COMMUNITY_CODE_TO_ID.trends]: {
    id: COMMUNITY_CODE_TO_ID.trends,
    name: 'Trends',
    img: trends,
    largeCommunity: false,
  },
};

export const isEmptyObject = (obj: any) =>
  Object.entries(obj).length === 0 && obj.constructor === Object;

export const INTERNATIONAL_TIME_LOCALES = [
  14,
  27,
  28,
  29,
  30,
  31,
  34,
  35,
  36,
  37,
  38,
  39,
  41,
  49,
  51,
  52,
  53,
  54,
  59,
  60,
  62,
  63,
  64,
  65,
  67,
  68,
  70,
  73,
  74,
  75,
  77,
];

export const createSelectOptions = (option: any) => ({
  label: option.name,
  value: option.id,
});

export const sortSelectObjects = (a: any, b: any) =>
  a.label > b.label ? 1 : a.label < b.label ? -1 : 0;

const DEFAULT_AVATARS = [
  defaultAvatar1,
  defaultAvatar2,
  defaultAvatar3,
  defaultAvatar4,
  defaultAvatar5,
];

export const pickDefaultAvatar = (index: number | undefined | null = null) => {
  if ((index || index === 0) && index > -1) {
    return DEFAULT_AVATARS[index % DEFAULT_AVATARS.length];
  }
  return DEFAULT_AVATARS[Math.floor(Math.random() * DEFAULT_AVATARS.length)];
};

export const simpleStringHash = (s: any) => {
  if (!s || s.length === 0) {
    return 0;
  }
  const modPrime = 972041737;
  const basePrime = 514229;
  let result = 0;
  for (let i = 0; i < s.length; i += 1) {
    result = result * basePrime + s.charCodeAt(i) + 1;
    result %= modPrime;
  }
  result = (result + modPrime) % modPrime;
  return result;
};

export const pluralize = (n: number, text: string) => `${n} ${text}${n !== 1 ? 's' : ''}`;

export const TEST_BUCKETS = {
  SHORTENED_ONBOARDING_CONTROL: 0,
  SHORTENED_ONBOARDING_EXPERIMENT: 1,
  POPULAR_TIMESLOTS: 2,
  AVAILABLE_TIMESLOTS: 3,
  DYNAMIC_MATCHES: 4,
  TOPIC_TEST: 5,
  TWEET_TEST: 6,
  FOLLOW_TEST: 7,
  INVITE_REFRESH: 8,
  INVITE_ALL_NOTE: 9,
  INTEREST_PREFERENCE_TEST: 10,
  WEEKLY_TARGET_UID: 11,
  DECK_TEST: 12,
  DECK_CONTROL: 13,
  WEEKLY_SAT_SESSION: 14,
  SIMPLIFY_MOBILE_INVITE_TEST: 15,
  SIMPLIFY_MOBILE_INVITE_CONTROL: 16,
  WEEKLY_STREAKS_ANIMATION: 17,
  MEETINGS_AUTOPILOT: 18,
  GATE_TEST: 19,
  INVITE_PAGE_COPY_TEST: 20,
  TEN_STAR_RATING_TEST: 22,
  CONVERSATION_RATING_TEST: 23,
  INSTANT_FEEDBACK_CONTROL: 24,
  CASUAL_PROFESSIONAL_TOGGLE_TEST: 25,
  BINARY_INVITE_TEST: 26,
  HIDE_NAVBAR_IN_WEEKLY_TEST: 27,
  NOTES_PREFERENCE_INVITE_TEST: 28,
  FEED_DECK_CLUBPOINTS_REWARD: 29,
  DISABLE_POND_ALL: 30,
  DECK_RECONNECT_TEST: 31,
  DECK_RECONNECT_HIDDEN_TEST: 32,
  INVITE_SECTION_HOLDOUT_TEST: 33,
  INVITE_CARD_SKIP_TEST: 34,
  TARGET_TOPIC_TEST: 35,
  WEEKLY_FREEFORM_REQUEST_TEST: 36,
  TARGET_TOPIC_WITH_SPECIFY_TEST: 37,
  CHAT_INVITE_TEST: 38,
  SLANT_OPT_IN_TEST: 39,
  AGGRESSIVE_FEEDBACK_SIGNUP: 40,
  NUX_MATCH_INVITE_TEST: 41,
  NEW_USER_FORCED_AUTOPILOT: 42,
  NUX_MATCH_TEST: 43,
  MORPHEUS_TEST: 44,
  ENDORSEMENT_FEED_TEST: 45,
  AUTOPILOT_CADENCE_TEST: 46,
  DISCOVER_BOOKING_TEST: 47,
  COHESIVE_AUTOPILOT_TEST: 48,
  NUX_TOUR_TEST: -1,
  SIGN_UP_NEXT_WEEK_TEST: -2,
  WEEKLY_TESTIMONIALS: -3,
  SECOND_MATCH_MESSAGE_TEST_1: -5,
  SECOND_MATCH_MESSAGE_TEST_2: -6,
  IRL_MODAL: -7,
  IRL_MODAL_SWAPPED: -8,
  E2E_VIDEO_V2: -9,
};

export const getIsMobile = () =>
  window.matchMedia(`screen and (max-width: ${MEDIA_SIZE}px)`).matches;

export const getIsIOSApp = () => !!window.bridge;

export const countUp = (countTo: number, setCount: (x: number) => void, duration = 2000) => {
  const easeOutCirc = (x: number): number => Math.sqrt(1 - (x - 1) ** 2);
  const frameDuration = 1000 / 25;

  let frame = 0;
  const totalFrames = Math.round(duration / frameDuration);
  const counter = setInterval(() => {
    frame += 1;
    const progress = easeOutCirc(frame / totalFrames);
    setCount(Math.ceil(countTo * progress));

    if (frame === totalFrames) {
      clearInterval(counter);
    }
  }, frameDuration);
};

export const getDateString = (eventDate: string): string => {
  const eventMoment = moment(eventDate, 'M/D/YY');
  if (eventMoment.isSame(moment(), 'year')) return eventMoment.format('MMMM D');
  return eventMoment.format('MMMM D, YYYY');
};

export const decidePastFutureText = (eventDate: string, past: string, future: string) =>
  moment(eventDate, 'M/D/YY') > moment() ? future : past;

// decorator for making Linkify links open in a new tab
export const linkifyDecorator = (href: string, text: string, key: number) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

export const getTimezoneAbbr = (timezone: string) => {
  const timezoneAbbr = moment.tz(timezone).zoneAbbr();
  return timezoneAbbr.includes('+') ? `UTC${timezoneAbbr}` : timezoneAbbr;
};

export enum NETWORK_TYPES {
  INVITER = 0,
  INVITEE = 1,
  MATCH = 2,
  CONTACT = 3,
  LC_BOT = 4,
}

export const scrollToTopIfNeeded = (
  navbarIconClicked: string,
  checkString: string,
  scrollableItemRef: any,
  setNavbarIconClicked: (x: string) => void,
) => {
  if (navbarIconClicked === checkString) {
    if (scrollableItemRef?.current) scrollableItemRef.current.scrollTo(0, 0);
    window.scrollTo(0, 0);
  }
  setNavbarIconClicked('');
};

export const EVENT_STATUS = {
  pending: 0,
  confirmed: 1,
  declined: 2,
};

export const generateTimeslots = ({
  startWeek,
  startTime,
  endTime,
  numDays,
  userTimezone,
  bufferHours,
  cutOffTime,
  incrementsInMinutes = 60,
  selectedDay,
}: {
  startWeek: number;
  startTime: string;
  endTime: string;
  numDays: number;
  userTimezone: string;
  bufferHours: number;
  cutOffTime?: string;
  incrementsInMinutes?: number;
  selectedDay?: Moment | null;
}) => {
  const currentTimeWithBuffer = moment
    .utc()
    .tz(userTimezone)
    .add(bufferHours, 'hours')
    .format('YYYY-MM-DD HH:mm:ss');
  const cutOffMoment = moment(
    `${(selectedDay?.clone() || moment()).format('YYYY-MM-DD')} ${cutOffTime}`,
  );
  const shouldAddADay = cutOffTime && moment(currentTimeWithBuffer).isAfter(cutOffMoment);
  const startDay =
    selectedDay?.clone() || moment().add(startWeek * numDays + (shouldAddADay ? 1 : 0), 'day');
  const currTimeslots = [];
  let dayWithTimeslots: string[] = [];
  const cutOffDay = moment(startDay).add(numDays, 'days');
  let currDay = startDay.clone();
  let dayEndTime = moment(`${currDay.format('YYYY-MM-DD')} ${endTime}`, 'YYYY/MM/DD HH:mm:ss');
  let currentTime = moment(`${currDay.format('YYYY-MM-DD')} ${startTime}`, 'YYYY/MM/DD HH:mm:ss');

  while (currDay.isBefore(cutOffDay)) {
    while (currentTime.isBefore(dayEndTime)) {
      dayWithTimeslots.push(currentTime.format('YYYY-MM-DD HH:mm:ss'));
      currentTime.add(incrementsInMinutes, 'minutes');
    }
    currTimeslots.push(dayWithTimeslots);
    dayWithTimeslots = [];
    currDay = currDay.add(1, 'day');
    dayEndTime = dayEndTime.add(1, 'day');
    currentTime = moment(`${currDay.format('YYYY-MM-DD')} ${startTime}`, 'YYYY/MM/DD HH:mm:ss');
  }
  return { startDay, currTimeslots, currentTimeWithBuffer };
};

export const generateDays = ({ startDate, numDays }: { startDate: Moment; numDays: number }) => {
  const days = [];
  let i = 0;
  while (i < numDays) {
    days.push(moment(startDate).add(i, 'day'));
    i += 1;
  }
  return days;
};

export const LC_BOT_PUBLIC_ID = 'b402e1aef63e';
export const APP_STORE_LINK = 'https://apps.apple.com/app/lunchclub/id1538817081';

export const isSameSet = (listA?: string[], listB?: string[]) => {
  if (listA === undefined || listB === undefined) {
    return false;
  }
  const setA = new Set(listA);
  const setB = new Set(listB);

  if (setA.size !== setB.size) {
    return false;
  }

  return [...setA].filter(x => !setB.has(x)).length === 0;
};

export const describeWeeklyTimeslots = (timeslots: string[], show24hourTime = false) => {
  if (!timeslots.length) {
    return '';
  }
  const allEqual = (days: number[]) => days.every(x => x === days[0]);
  const prettyJoin = (prettyTimeslots: string[]) => {
    if (prettyTimeslots.length === 1) {
      return prettyTimeslots[0];
    }
    const commaSeperated = prettyTimeslots.slice(0, -1).join(', ');
    const last = prettyTimeslots[prettyTimeslots.length - 1];
    return `${commaSeperated} and ${last}`;
  };
  const prettyDay = (timeslotMoment: Moment) => {
    return timeslotMoment.format('dddd');
  };
  const prettyHour = (timeslotMoment: Moment) => {
    return show24hourTime ? timeslotMoment.format('HH:mm') : timeslotMoment.format('h:mma');
  };
  const prettyTimeslot = (timeslotMoment: Moment) => {
    return `${prettyDay(timeslotMoment)} at ${prettyHour(timeslotMoment)}`;
  };

  const timeslotMoments = [...timeslots]
    .map(x => moment(x))
    .sort((momentA: Moment, momentB: Moment) => {
      if (momentA.isoWeekday() !== momentB.isoWeekday()) {
        return momentA.isoWeekday() - momentB.isoWeekday();
      }
      return momentA.hour() - momentB.hour();
    });

  if (allEqual(timeslotMoments.map(x => x.weekday()))) {
    const day = prettyDay(timeslotMoments[0]);
    return `${day} at ${prettyJoin(timeslotMoments.map(x => prettyHour(x)))}`;
  }
  return prettyJoin(timeslotMoments.map(x => prettyTimeslot(x)));
};

export const getPlatform = () => {
  return window?.bridge ? 'ios' : getIsMobile() ? 'mobile' : 'desktop';
};

export const capitaliseFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const assertNever = (x: never) => undefined;

export const consolidateTimeslots = (passedTimeslots: string[]) =>
  passedTimeslots
    ? passedTimeslots.reduce((timeslots: Record<string, any>, t) => {
        const timeslotString = moment(t).format('ddd MMM D');
        /* eslint-disable no-param-reassign */
        const updatedTimeslots = timeslots[timeslotString]
          ? [...timeslots[timeslotString], t]
          : [t];
        timeslots[timeslotString] = updatedTimeslots.sort();
        return timeslots;
      }, {})
    : [];
