import React from 'react';
import styled from 'styled-components/macro';

import { FlexRow, margins, colors } from 'css/css';

export const DialogBox: React.FC<{ vertical?: boolean }> = ({ children, vertical }) => (
  <FlexRow style={{ position: 'relative', marginLeft: margins.size3 }}>
    <Diamond />

    <TextBox vertical={vertical}>{children}</TextBox>
  </FlexRow>
);

const Diamond = styled.div`
  border-right: none;
  border-top: none;
  position: absolute;
  top: ${margins.size3};
  left: -10px;
  background-color: ${colors.whiteMain};
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
`;

const TextBox = styled(FlexRow)<{ vertical?: boolean }>`
  justify-content: center;
  ${p => p.vertical && 'flex-direction: column;'}
  ${p => p.vertical && `padding: ${margins.size2};`}
  ${p => p.vertical && 'align-items: flex-start;'}
  min-height: 40px;
  background-color: ${colors.whiteMain};
  border-radius: 5px;
  margin-left: -5px;
  padding-right: ${margins.size2};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
`;
