import React from 'react';

import { FlexRow, Text, colors, margins } from 'css/css';

import indicator from 'img/weekly/indicator.svg';

export const AvailibilityExplanation = ({
  shouldDisplay,
  contentStyle,
}: {
  shouldDisplay: boolean;
  contentStyle?: React.CSSProperties;
}) => {
  return (
    <FlexRow style={{ ...contentStyle, alignSelf: 'flex-start', minHeight: 23 }}>
      {shouldDisplay && (
        <>
          <img src={indicator} alt="Availability indicator" style={{ margin: margins.size2 }} />
          <Text color={colors.blackLight}>Best fit with your calendar</Text>
        </>
      )}
    </FlexRow>
  );
};
