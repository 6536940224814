export const ORGANIZATION = {
  LUNCHCLUB: 0,
  FEMALEFOUNDERS: 1,
  HTTTINVESTORS: 2,
  PPDSF: 3,
  INCLUSIVESPEAKER: 4,
  YPOSF: 5,
  PRODUCTTANKSV: 6,
  DREAMERS: 7,
  SYSTERS: 8,
};

export const ORG_INVITE_CODE = {
  [ORGANIZATION.FEMALEFOUNDERS]: 'femalefounders',
  [ORGANIZATION.HTTTINVESTORS]: 'htttinvestors',
  [ORGANIZATION.PPDSF]: 'ppdsf',
  [ORGANIZATION.INCLUSIVESPEAKER]: 'inclusivespeaker',
  [ORGANIZATION.YPOSF]: 'yposf',
  [ORGANIZATION.PRODUCTTANKSV]: 'producttanksv',
  [ORGANIZATION.DREAMERS]: 'dreamers',
  [ORGANIZATION.SYSTERS]: 'systers',
};
