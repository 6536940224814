import React, { useState, useEffect, CSSProperties } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';

import {
  colors,
  fonts,
  fontSizes,
  globalTransitionSettings,
  margins,
  SubText,
  Text,
} from 'css/css';

import xSvg from 'img/weekly/x.svg';

import { CheckmarkAnimated } from '../registration/mobile/shared';

import { LargeArrow } from './LargeArrow';

export const WhiteCardListItem: React.FC<{
  icon: string;
  title: string;
  subtitle?: string;
  selected?: boolean;
  onClick: () => void;
}> = ({ icon, title, subtitle, onClick, selected }) => {
  return (
    <WhiteCardListItemContainer onClick={onClick} isSelected={selected}>
      <img src={icon} style={{ flexShrink: 0 }} alt="icon" />
      <div style={{ marginLeft: 15, flex: 1 }}>
        <div className="title">{title}</div>
        {subtitle && <div className="subtitle">{subtitle}</div>}
      </div>
      {selected && <CheckmarkAnimated />}
    </WhiteCardListItemContainer>
  );
};

const WhiteCardListItemContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  background-color: ${p => p.isSelected && colors.greyLight};
  transition: ${globalTransitionSettings};

  .title {
    font-family: ${fonts.extraBold};
    font-size: ${fontSizes.size2};
  }
  .subtitle {
    font-family: ${fonts.regular};
    font-size: ${fontSizes.size1};
    opacity: 0.6;
  }
`;

export const WhiteCardListSeparator = styled.div`
  font-family: ${fonts.regular};
  font-size: ${fontSizes.size2};
  margin-top: 8px;
`;

export const WhitePrompt = styled.div`
  font-family: ${fonts.extraBold};
  padding: ${margins.size3};
  padding-bottom: 20px;
  font-size: 22px;
  color: white;
  max-width: 85%;
  user-select: none;
  line-height: 26px;
`;

export const WhiteSubprompt = styled.div`
  font-family: ${fonts.regular};
  padding: ${margins.size3};
  margin-top: -10px;
  padding-top: 0;
  padding-bottom: 25px;
  font-size: ${fontSizes.size2};
  color: ${colors.greyLight};
  user-select: none;
  line-height: 26px;
`;

export const WhiteCard = styled.div`
  background: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: ${margins.size3};
  padding-bottom: calc(max(${margins.size3}, env(safe-area-inset-bottom)));
  max-height: calc(100vh - 100px);
  color: ${colors.blackMain};

  input {
    background: ${colors.greyLight};
  }
`;

export const WhiteCardAction: React.FC<{
  disabled?: boolean;
  hide?: boolean;
  title: React.ReactNode;
  onClick: () => Promise<void> | void;
  flashCompleteTitle?: React.ReactNode;
  onFlashComplete?: () => void;
}> = ({ title, onClick, hide, onFlashComplete, flashCompleteTitle, disabled }) => {
  const [state, setState] = React.useState<'none' | 'saving' | 'complete'>('none');
  return (
    <WhiteCardActionWrap
      hide={hide}
      disabled={disabled}
      state={state}
      onClick={async e => {
        if (disabled || hide) {
          return;
        }
        e.stopPropagation();
        setState('saving');
        try {
          await onClick();
        } catch (err) {
          setState('none');
          return;
        }
        if (flashCompleteTitle) {
          setState('complete');
          if (onFlashComplete) setTimeout(onFlashComplete, 1000);
        } else {
          setState('none');
        }
      }}
    >
      <div className="title">{state === 'complete' ? flashCompleteTitle : title}</div>
    </WhiteCardActionWrap>
  );
};

const WhiteCardActionWrap = styled.div<{
  state: 'none' | 'saving' | 'complete';
  disabled?: boolean;
  hide?: boolean;
}>`
  padding: 18px;
  color: white;
  background: ${p =>
    p.hide
      ? 'white'
      : p.disabled
      ? colors.blackLight
      : p.state === 'complete'
      ? colors.secondaryMain
      : colors.primaryMain};
  transition: background 150ms ease-in-out;
  text-align: center;
  font-family: ${fonts.extraBold};
  padding-bottom: calc(max(18px, env(safe-area-inset-bottom) + 10px));

  & .title {
    opacity: ${p => (p.state === 'saving' ? '0' : '1')};
    transform: translate(0, ${p => (p.state === 'saving' ? '50px' : '0')});
    transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
  }
`;

export const WhiteCardHeader: React.FC<{
  onPrev?: () => void;
  onClose?: () => void;
  title: string;
  subtitle?: string;
  localeSelector?: boolean;
  subtext?: string;
}> = ({ onPrev, onClose, title, subtitle, localeSelector, subtext }) => (
  <WhiteCardHeaderWrap
    style={{
      marginRight: localeSelector ? '16px' : undefined,
      marginBottom: localeSelector ? margins.size1 : undefined,
    }}
  >
    {onPrev && (
      <div
        tabIndex={0}
        role="button"
        onClick={onPrev}
        style={{ padding: 10, position: 'absolute', left: -10, top: -6, outline: 'none' }}
      >
        <LargeArrow direction="left" color={colors.blackMain} />
      </div>
    )}
    <div style={{ flex: 1 }}>
      {title}
      {subtitle && <Text>{subtitle}</Text>}
      {subtext && <SubText>{subtext}</SubText>}
    </div>
    {onClose && (
      <div
        tabIndex={0}
        role="button"
        onClick={onClose}
        style={{ padding: 10, position: 'absolute', right: -10, top: -6, outline: 'none' }}
      >
        <img alt="close" src={xSvg} />
      </div>
    )}
  </WhiteCardHeaderWrap>
);

const WhiteCardHeaderWrap = styled.div`
  text-align: center;
  position: relative;
  font-family: ${fonts.bold};
  line-height: 22px;
  height: 36px;
  margin-bottom: ${margins.size3};
  user-select: none;
  display: flex;
  align-items: center;
  .subtitle {
    font-family: ${fonts.regular};
    opacity: 0.85;
  }
`;

const fadebg = keyframes`
0% {
  background: ${colors.blackMain}00;
}
60% {
  background: ${colors.blackMain}FF;
}
100% {
  background: ${colors.blackMain}FF;
}
`;
const fadebgblurred = keyframes`
0% {
  background: ${colors.blackMain}00;
}
60% {
  background: ${colors.blackMain}99;
}
100% {
  background: ${colors.blackMain}99;
}
`;

const whitePromptAppear = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;
const whiteCardAppear = keyframes`
0% {
  transform: translate(0, 100%);
}
100% {
  transform: translate(0, 0);
}
`;
const whiteCardDisappear = keyframes`
0% {
  transform: translate(0, 0);
}
100% {
  transform: translate(0, 100%);
}
`;
export const DarkCardBackground = styled.div`
  background: ${colors.blackMain}FF;
  flex-direction: column;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  text-align: left;
  color: black;
  animation: ${fadebg} 300ms ease-out;
  ${WhitePrompt} {
    opacity: 1;
    animation: ${whitePromptAppear} 300ms ease-out;
  }
  ${WhiteCard} {
    animation: ${whiteCardAppear} 300ms ease-out;
  }
`;

export const Backdrop = styled.div<{
  isOpen: boolean;
  transitionDuration?: number;
  blur?: number;
  $isAboveNavbar?: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 100vh;
  z-index: ${props => (props.$isAboveNavbar ? 4 : 2)};
  display: flex;
  flex-direction: column;
  transition: background-color ${({ transitionDuration = 300 }) => transitionDuration}ms ease-in-out;
  pointer-events: ${({ isOpen }) => (isOpen ? 'initial' : 'none')};
  -webkit-backdrop-filter: ${({ isOpen, blur = 5 }) => (isOpen ? `blur(${blur}px)` : 'blur(0px)')};
  background-color: ${({ isOpen }) => (isOpen ? `${colors.blackMain}99` : `${colors.blackMain}00`)};
  ${({ isOpen, transitionDuration = 300 }) =>
    isOpen
      ? css`
          animation: ${fadebgblurred} ${transitionDuration}ms ease-in-out;
        `
      : ''};

  ${WhitePrompt} {
    opacity: 1;
    animation: ${whitePromptAppear} ${({ transitionDuration = 300 }) => transitionDuration}ms
      ease-out;
    animation-direction: ${({ isOpen }) => (isOpen ? 'normal' : 'reverse')};
    animation-fill-mode: forwards;
  }
  ${WhiteCard} {
    animation: ${whiteCardAppear} ${({ transitionDuration = 300 }) => transitionDuration}ms ease-out;
    animation-fill-mode: forwards;
    &.closing {
      animation: ${whiteCardDisappear} ${({ transitionDuration = 300 }) => transitionDuration}ms
        ease-out;
    }
  }
`;

export const SimpleModalWrap: React.FC<{
  state: 'open' | 'closing' | false;
  closeModal: () => void;
  actions?: React.ReactNode;
  cardStyle?: CSSProperties;
  isAboveNavbar?: boolean;
  noAction?: boolean;
}> = ({ state, closeModal, actions, children, cardStyle, noAction, isAboveNavbar }) => {
  if (state === false) {
    return null;
  }
  return (
    <Backdrop
      $isAboveNavbar={isAboveNavbar}
      isOpen={state === 'open'}
      onClick={e => {
        closeModal();
        e.stopPropagation();
      }}
    >
      <div style={{ flex: 1 }} />
      <WhiteCard onClick={e => e.stopPropagation()} style={cardStyle} className={state}>
        {children}
      </WhiteCard>
      {!noAction && (actions || <WhiteCardAction title="Done" onClick={closeModal} />)}
    </Backdrop>
  );
};

export const SimpleFullHeightModalWrap: React.FC<{
  state: 'open' | 'closing' | false;
  closeModal: () => void;
  actions?: React.ReactNode;
}> = ({ state, closeModal, actions, children }) => {
  useEffect(() => {
    if (window.bridge) {
      window.bridge.setShrinkViewportForKeyboard(state === 'open');
    }
  }, [state]);

  if (state === false) {
    return null;
  }

  return (
    <Backdrop isOpen={state === 'open'} onClick={closeModal}>
      <WhiteCard
        key={state}
        style={{
          display: 'flex',
          maxHeight: 'initial',
          flexDirection: 'column',
          paddingTop: 'calc(max(10px, env(safe-area-inset-top)))',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          flex: 1,
        }}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </WhiteCard>
      {actions || <WhiteCardAction title="Done" onClick={closeModal} />}
    </Backdrop>
  );
};

export function useSimpleModal() {
  const [state, setState] = useState<'open' | 'closing' | false>(false);

  const closeModal = React.useCallback(() => {
    setState('closing');
    setTimeout(() => setState(false), 300);
  }, [setState]);

  const openModal = React.useCallback(() => {
    if (state === 'closing') return;
    setState('open');
  }, [state, setState]);

  return {
    openModal,
    closeModal,
    wrapperProps: {
      state,
      closeModal,
    },
  };
}
