import React from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import { colors, fonts, margins, FlexRow, Heading3, HideMobile, ShowMobile, media } from 'css/css';

import ClubpointIcon from 'img/clubpoints/clubpoints.svg';
import SpecifyMatch from 'img/clubpoints/specify.svg';
import MultipleMatch from 'img/clubpoints/multiple.svg';
import LocationMatch from 'img/clubpoints/location.svg';

import { ExplanationText } from './style';

const SPEND_OPTIONS = [
  {
    title: 'Specify your match for the week.',
    explanation: 'If you would like a match with a specific specialty, each pick is ',
    cost: '30 Clubpoints',
    icon: SpecifyMatch,
  },
  {
    title: 'Make multiple matches for the week.',
    explanation:
      'If you would like to take more than three matches in a week, each additional match is ',
    cost: '10 Clubpoints',
    icon: MultipleMatch,
  },
  {
    title: 'Specify where your match is from.',
    explanation: 'If you would like to meet someone in a specific city, each match is ',
    cost: '5 Clubpoints',
    icon: LocationMatch,
  },
];

const EARN_OPTIONS = [
  { action: 'Take a meeting', reward: '+ 2 ' },
  { action: 'Invite a friend who joins Lunchclub', reward: '+ 5 ' },
  { action: 'Invited friend takes first meeting', reward: '+ 5 ' },
  { action: 'Send a suggested invite', reward: '+ 1 ' },
];

export const SpendOptions = () => (
  <>
    {SPEND_OPTIONS.map(option => (
      <SpendOptionsContainer key={option.title}>
        <div>
          <Icon src={option.icon} alt={option.title} />
        </div>
        <div style={{ paddingLeft: margins.size3 }}>
          <Heading3>{option.title}</Heading3>

          <ExplanationText style={{ marginTop: margins.size2 }}>
            {option.explanation}
            <HighlightSpend>{option.cost}</HighlightSpend>.
          </ExplanationText>
        </div>
      </SpendOptionsContainer>
    ))}
  </>
);

export const EarnOptions = () => (
  <EarnOptionsContainer>
    {EARN_OPTIONS.map(option => (
      <EarnRow key={option.action}>
        <ActionHeading>{option.action}</ActionHeading>

        <FlexRow style={{ height: 19, marginBottom: 'auto' }}>
          <Heading3 color={colors.tertiary3Main} style={{ marginRight: margins.size1 }}>
            {option.reward}
          </Heading3>

          <HideMobile>
            <Heading3 color={colors.tertiary3Main}>Clubpoints</Heading3>
          </HideMobile>

          <ShowMobile style={{ height: 24 }}>
            <ClubpointsIcon />
          </ShowMobile>
        </FlexRow>
      </EarnRow>
    ))}
  </EarnOptionsContainer>
);

const ActionHeading = styled(Heading3)`
  ${media.mobile} {
    width: 180px;
  }
`;

const Icon = styled.img`
  width: 48px;
  height: 48px;
`;

const HighlightSpend = styled.span`
  color: ${colors.tertiary2Main};
  font-family: ${fonts.bold};
`;

const SpendOptionsContainer = styled(FlexRow)`
  margin: ${margins.size4} auto;
  align-items: left;
`;

const EarnRow = styled(FlexRow)`
  justify-content: space-between;
  margin-bottom: ${margins.size2};
`;

const EarnOptionsContainer = styled.div`
  margin-top: ${margins.size3};
`;

const ClubpointsIcon = styled(SVG).attrs({ src: ClubpointIcon, alt: 'Clubpoints Icon' })`
  & > circle {
    stroke: ${colors.tertiary3Main};
    fill: ${colors.tertiary3Main};
  }
  & > path {
    stroke: ${colors.tertiary3Main};
    fill: ${colors.tertiary3Main};
  }
  margin-top: auto;
  margin-bottom: 1px;
`;
