import React from 'react';
import styled from 'styled-components/macro';

import { margins, media, FlexColumn, FlexRow, Heading1, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { Logo } from 'js/components/shared/Logo';
import { useTestBuckets } from 'js/providers/UserProvider';
import { educationPage } from 'js/util/strings';

import { greyArrow, weekly, intro, videoMeet } from 'img/verify';

const Images: { [key: string]: string } = {
  weekly,
  intro,
  meet: videoMeet,
};

export const StartGuide: React.FC<{ onSubmit: () => void }> = ({ onSubmit }) => {
  const { cohesiveAutopilotTest } = useTestBuckets();

  if (cohesiveAutopilotTest) {
    educationPage.sections[0].subtitle = 'Select your preferred availability';
  }

  return (
    <div style={{ padding: `${margins.size5} 0px` }}>
      <Logo logoWidth="240px" style={{ marginBottom: margins.size5 }} />
      <Heading1>{educationPage.mainTitle}</Heading1>
      <FlexRow justifyContent="space-between" style={{ marginBottom: margins.size5 }}>
        {educationPage.sections.map((section, idx) => (
          <React.Fragment key={section.key}>
            <FlexColumn style={{ margin: 'auto', maxWidth: '230px' }}>
              <ModalImage src={Images[section.key]} alt={section.subtitle} />
              <Text>{section.subtitle}</Text>
            </FlexColumn>
            {idx < educationPage.sections.length - 1 && <Arrow src={greyArrow} alt="arrow" />}
          </React.Fragment>
        ))}
      </FlexRow>
      <Button onClick={onSubmit} maxWidth="240px">
        Get started
      </Button>
    </div>
  );
};

const Arrow = styled.img`
  height: 22px;
  ${media.mobile} {
    display: none;
  }
`;

const ModalImage = styled.img`
  height: 192px;
  ${media.mobile} {
    height: 15vh;
  }
`;
