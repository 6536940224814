import React, { useState } from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';
import moment from 'moment';

import {
  FlexRow,
  FlexColumn,
  margins,
  Text,
  colors,
  media,
  borders,
  globalTransitionSettings,
} from 'css/css';

import { useUser } from 'js/providers/UserProvider';
import { getIsMobile, INTERNATIONAL_TIME_LOCALES } from 'js/util/util';
import {
  DayButton,
  DayButtonText,
} from 'js/components/chat/scheduler/timeslots-modal/SuggestedTimes';
import { TimeslotButtonText } from 'js/components/chat/scheduler/timeslots-modal/TimeslotButton';
import { useDataContext } from 'js/providers/DataContextProvider';
import { useNotifContext } from 'js/util/notif-context';
import { apiFailure } from 'js/util/strings';
import { Locale } from 'js/components/weekly/types';
import { getCutoffDay } from 'js/components/weekly';

import close from 'img/shared/close-small.svg';

import { getNumMaxMeetings } from '../constants';

import { TimeslotPickerDesktop } from './TimeslotPickerDesktop';

interface Props {
  selectedTimeslots: string[];
  setSelectedTimeslots: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCadence: number;
  isForNextWeek?: boolean;
  isModal?: boolean;
}

export const TimesSelector: React.FC<Props> = ({
  selectedTimeslots,
  setSelectedTimeslots,
  selectedCadence,
  isForNextWeek = false,
  isModal = false,
}) => {
  const [selectedDay, setSelectedDay] = useState(0);
  const { allTimeslots, allLocales } = useDataContext();
  const user = useUser();
  const isMobile = getIsMobile();
  const { showNotif } = useNotifContext();
  const userLocaleId = user.locale_info.id;
  const numMaxMeetings = getNumMaxMeetings(isForNextWeek ? 1 : selectedCadence);

  const hasReachedLimit = selectedTimeslots.length >= numMaxMeetings;
  const localeTimeslotObject = allTimeslots.find(t => t.id === userLocaleId);
  const isNextWeekHome = allTimeslots.find(t => t.id === userLocaleId)?.isNextWeek || false;

  const collectIntoCalendarDays = (args: {
    timeslots?: string[];
    window: [string, string]; // YYYY-MM-DD 00:00:00
    isDefault: boolean;
  }) => {
    const { timeslots, isDefault } = args;
    const userLocale = allLocales.find(rawLocale => rawLocale.id === user.locale) as Locale;
    const is24Hour = userLocale?.id && INTERNATIONAL_TIME_LOCALES.includes(userLocale.id);
    const homeTimezone = userLocale?.timezone || '';
    const [startWindow, endWindow] = args.window;

    const passedTimeslots = timeslots ? [...timeslots] : [];
    const localAdjustedTimeslots = passedTimeslots
      .map(timeslot =>
        moment
          .tz(timeslot, homeTimezone)
          .clone()
          .format('YYYY-MM-DD HH:mm:ss'),
      )
      .filter(timeslot => startWindow <= timeslot && timeslot <= endWindow);

    const days = [];
    while (localAdjustedTimeslots.length) {
      const targetDayFormat = moment(localAdjustedTimeslots[0]).format('YYYY-MM-DD');
      const daySlots = localAdjustedTimeslots.filter(
        timeslot => moment(timeslot).format('YYYY-MM-DD') === targetDayFormat,
      );
      days.push(daySlots);
      localAdjustedTimeslots.splice(0, daySlots.length);
    }

    if (isDefault) {
      days.sort((a, b) => moment(a[0]).day() - moment(b[0]).day());
    }
    const resTimeslots = days.map((day, i) => ({
      id: i,
      header: moment(day[0]).format('dd MMM D'),
      options: day.map((timeslot, j) => {
        return {
          id: j,
          option: timeslot,
          label: is24Hour ? moment(timeslot).format('HH:mm') : moment(timeslot).format('h:mm a'),
        };
      }),
    }));
    return resTimeslots;
  };

  if (!localeTimeslotObject) {
    return null;
  }

  const localeTimeslotsByDay = collectIntoCalendarDays({
    timeslots: localeTimeslotObject?.timeslots,
    window: getCutoffDay({
      homeIsNextWeek: isNextWeekHome,
      userTimezone: user.locale_info?.timezone || '',
    }),
    isDefault: !isForNextWeek,
  });
  const startDay = moment(localeTimeslotsByDay[0].options[0].option);
  const endDay = moment(localeTimeslotsByDay[localeTimeslotsByDay.length - 1].options[0].option);
  const shouldDisplayNextMonth = !startDay.isSame(endDay, 'month');

  const clickTimeslot = (passedTimeslot: string) => {
    if (!hasReachedLimit) {
      setSelectedTimeslots(prev =>
        prev
          ? prev.includes(passedTimeslot)
            ? prev.filter(t => t !== passedTimeslot)
            : [...prev, passedTimeslot]
          : [passedTimeslot],
      );
    } else if (selectedTimeslots.includes(passedTimeslot)) {
      setSelectedTimeslots(prev =>
        prev.includes(passedTimeslot) ? prev.filter(t => t !== passedTimeslot) : [...prev],
      );
    } else {
      showNotif({
        message: `You can't sign up for more than ${numMaxMeetings} meeting${
          numMaxMeetings !== 1 ? 's' : ''
        } in a given week${
          numMaxMeetings === 1
            ? '. If you wish to change that, you’ll need to adjust your settings to a weekly cadence.'
            : ''
        }`,
        level: 'error',
      });
    }
  };

  if (!localeTimeslotsByDay) {
    showNotif({
      message: apiFailure.message,
      level: 'error',
    });
    return null;
  }

  if (!isMobile && !isModal) {
    return (
      <TimeslotPickerDesktop
        timeslots={localeTimeslotsByDay}
        selectedTimeslots={selectedTimeslots}
        onClick={clickTimeslot}
        selectedCadence={selectedCadence}
      />
    );
  }

  return (
    <FlexColumn style={{ width: '100%', margin: `${margins.size3} 0` }}>
      {' '}
      {isForNextWeek && (
        <Text color={colors.blackMain} style={{ marginBottom: margins.size2 }}>
          {startDay.format('MMMM')} {shouldDisplayNextMonth && `- ${endDay.format('MMMM')}`}
        </Text>
      )}
      <FlexRow style={{ width: '100%', justifyContent: 'space-between' }}>
        {localeTimeslotsByDay.map(({ id, header, options }, idx) => {
          const [dayOfWeek, , date] = header.split(' ');
          return (
            <FlexColumn style={{ marginBottom: margins.size2 }} key={id}>
              {isForNextWeek && <Text color={colors.blackLight}>{dayOfWeek}</Text>}
              <DayButton
                style={{ height: '44px', width: '44px' }}
                key={id}
                $isSelected={selectedDay === idx}
                onClick={() => {
                  setSelectedDay(idx);
                }}
                $hasSelectedTimes={
                  !!options.filter(o => selectedTimeslots.includes(o.option)).length
                }
              >
                <DayButtonText
                  $isSelected={selectedDay === idx}
                  $hasSelectedTimes={
                    !!options.filter(o => selectedTimeslots.includes(o.option)).length
                  }
                >
                  {isForNextWeek ? date : dayOfWeek}
                </DayButtonText>
              </DayButton>
            </FlexColumn>
          );
        })}
      </FlexRow>
      <TimeslotsContainer>
        {localeTimeslotsByDay[selectedDay].options.map(timeslot => (
          <StyledTimeslotButton
            $isSelected={selectedTimeslots.includes(timeslot.option)}
            $isInvalid={hasReachedLimit}
            onClick={() => clickTimeslot(timeslot.option)}
            key={timeslot.option}
          >
            {selectedTimeslots.includes(timeslot.option) && <DeleteTimeslot />}
            <TimeslotButtonText
              $isSelected={selectedTimeslots.includes(timeslot.option)}
              $isInvalid={hasReachedLimit}
            >
              {timeslot.label}
            </TimeslotButtonText>
          </StyledTimeslotButton>
        ))}
      </TimeslotsContainer>
    </FlexColumn>
  );
};

const TimeslotsContainer = styled(FlexRow)`
  align-items: flex-start;
  width: 100%;
  gap: ${margins.size1};
  flex-wrap: wrap;
`;

const StyledTimeslotButton = styled(FlexRow)<{
  $isSelected: boolean;
  $isInvalid: boolean;
}>`
  padding: ${margins.size2} ${margins.size3};
  border-radius: 12px;
  background-color: ${p => (p.$isSelected ? colors.blackMain : colors.whiteMain)};
  transition: ${globalTransitionSettings};
  height: 50px;
  border: ${p => (p.$isSelected ? 'none' : borders.variation)};
  ${p => p.$isInvalid && 'cursor: default;'}

  &:hover {
    ${p => (!p.$isInvalid || p.$isSelected) && 'cursor:pointer'};
    background-color: ${p =>
      p.$isSelected ? colors.blackMain : p.$isInvalid ? colors.whiteMain : `${colors.greyLight}`};
    ${TimeslotButtonText} {
      color: ${p =>
        p.$isSelected ? colors.whiteMain : p.$isInvalid ? colors.blackLight : colors.blackMain};
    }

    ${media.mobile} {
      background-color: ${p => (p.$isSelected ? colors.blackMain : 'transparent')};
      ${TimeslotButtonText} {
        color: ${p =>
          p.$isSelected ? colors.whiteMain : p.$isInvalid ? colors.blackLight : colors.blackMain};
      }
    }
  }

  &:focus {
    outline: none;
  }
`;

const DeleteTimeslot = styled(SVG).attrs(() => ({ src: close }))`
  > path {
    stroke: ${colors.whiteMain};
  }
`;
