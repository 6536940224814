import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { useMountEffect } from 'js/util/custom-hooks';
import { getCurrentMatch } from 'js/util/api';

import { Text, Button } from '../styles';

interface Props {
  setHasCurrentMatch: (hasCurrentMatch: boolean) => void;
}
export const CurrentMatch: React.FC<Props> = ({ setHasCurrentMatch }) => {
  const history = useHistory();
  const location = useLocation();

  const [matchCode, setMatchCode] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [isE2e, setIsE2e] = useState(false);

  const checkCurrentMatches = async () => {
    const res = await getCurrentMatch();
    if (!res.ok) return;

    const { match_code, first_name, is_e2e } = res.getJson;

    setMatchCode(match_code);
    setFirstName(first_name);
    setIsE2e(is_e2e);
    setHasCurrentMatch(!!match_code);
  };

  useMountEffect(() => {
    if (!location.pathname.includes('/call')) {
      checkCurrentMatches();
      const id = setInterval(checkCurrentMatches, 60 * 1000);
      return () => clearInterval(id);
    }
    return undefined;
  });

  const handleJoin = () => history.push(`/call/${matchCode}?fromBanner=true`);

  return !matchCode ? null : (
    <>
      <Text>
        Your call with {firstName} is ready! {!isE2e ? 'Join from the link in your email' : ''}
      </Text>
      {isE2e && <Button onClick={handleJoin}>Join Call</Button>}
    </>
  );
};
