import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { colors, fonts, lineHeights, margins } from 'css/css';

import Channel, { SuperMessage } from 'types/channel';

import { MessageBubble } from './BaseMessage';
import { MessageContent } from './MessageContent';

interface Props {
  m: SuperMessage;
  messages: string[];
  matchChannel?: Channel;
  isMyMessage: boolean;
  isFirstTimeScheduling: boolean;
  selectedChannel: Channel;
  userTimezone: string;
  ref: React.RefObject<HTMLDivElement>;
  lastSchedulerMessage?: SuperMessage;
  openEndorsementModal?: () => void;
  openScheduler?: ({
    isReconnecting,
    isEditingTimes,
  }: {
    isReconnecting: boolean;
    isEditingTimes: boolean;
  }) => void;
  currentMatchChannel?: Channel;
  replaceNameWithLink: (message: string, matchChannel?: Channel) => React.ReactNode;
  isUpcoming: boolean;
}

export const LCBotMultipleMessages: React.FC<Props> = ({
  m,
  messages,
  matchChannel,
  isMyMessage,
  isFirstTimeScheduling,
  selectedChannel,
  userTimezone,
  ref,
  lastSchedulerMessage,
  openEndorsementModal,
  openScheduler,
  currentMatchChannel,
  replaceNameWithLink,
  isUpcoming,
}) => {
  return (
    <>
      {messages.map(message => (
        <MessageBubble $isMyMessage={false} style={{ marginBottom: margins.size1 }}>
          {replaceNameWithLink(message, matchChannel)}
        </MessageBubble>
      ))}
      <MessageContent
        m={m}
        isMyMessage={isMyMessage}
        isFirstTimeScheduling={isFirstTimeScheduling}
        selectedChannel={selectedChannel}
        userTimezone={userTimezone}
        ref={ref}
        lastSchedulerMessage={lastSchedulerMessage}
        openEndorsementModal={openEndorsementModal}
        openScheduler={openScheduler}
        currentMatchChannel={currentMatchChannel}
        replaceNameWithLink={replaceNameWithLink}
        noBody
        isUpcoming={isUpcoming}
      />
    </>
  );
};

export const TypingMessageBubble = () => (
  <TypingMessageContainer>
    {new Array(3).fill(null).map((el, idx) => {
      return <TypingDot i={idx} />;
    })}
  </TypingMessageContainer>
);

const TypingMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding: ${margins.size2};
  margin-top: ${margins.size1};
  margin-bottom: ${margins.size1};
  border-radius: 20px;
  margin-right: ${margins.size5};
  color: ${colors.blackMid};
  background-color: ${colors.greyLight};
  text-align: left;
  word-break: break-word;
  max-width: 530px;
  line-height: ${lineHeights.header};
  font-family: ${fonts.regular};
`;

const blink = () => keyframes`
0% {
    background-color: ${colors.greyMain}
}
50% {
    background-color: ${colors.blackLight}
}
100% {
    background-color: ${colors.greyMain}
}
`;

const TypingDot = styled.div<{ i: number }>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${colors.greyMain};
  animation: ${blink()} 1.5s ease-in-out ${p => p.i * 200 + 800}ms;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
`;
