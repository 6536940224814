/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { apiFetch } from 'js/util/api';

export const BridgeEventHandler: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    if (!window.bridge) {
      return;
    }

    window.bridge.setNotificationTokenHandler(async (platform, tokenHexValue) => {
      console.log(`[Bridge]: Received push token ${tokenHexValue}`);
      const resp = await apiFetch<unknown>('POST', 'discover/device_token', {
        device_token: tokenHexValue,
        platform,
      });
      console.log(`[Bridge]: Submitted token: ${resp.status}`);
    });

    window.bridge.setPushActionHandler(async notifInfo => {
      apiFetch<unknown>('POST', 'discover/push_notif/open', {
        notif_id: notifInfo.notif_id,
      });
      history.push(`/${notifInfo.path_name}?${notifInfo.path_params}`);
    });

    window.bridge.callEnded = feedbackCode => {
      if (feedbackCode) history.push(`/feedback?feedback_code=${feedbackCode}&ref=call`);
      else history.push('/home');
    };
  }, []);

  return null;
};
