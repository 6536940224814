import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { margins, Text, ClickableText, colors, Heading3 } from 'css/css';

import { setElliotToken } from 'js/util/util';
import { useNotifContext } from 'js/util/notif-context';
import { apiFailure, onboarding } from 'js/util/strings';
import { createOrActivateToken, postUserLogin } from 'js/util/api';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { CodeInput } from 'js/components/shared/CodeInput';
import { Button } from 'js/components/shared/Button';
import { KeyEmoji } from 'js/components/shared/Login/KeyEmoji';

import { verifySvgv2 } from 'img/registration-v2';

interface StepVerifyProps {
  tempTokenDefault: string;
  handleLogin: (token: string) => void;
}
export const StepVerifyEmail: React.FunctionComponent<StepVerifyProps> = ({
  tempTokenDefault,
  handleLogin,
}) => {
  const { showNotif } = useNotifContext();
  const { fetchUser, updateUserAvailability } = useUserContextProvider();
  const user = useUser();

  const [sendingEmail, setSendingEmail] = useState(false);

  const [tempToken, setTempToken] = useState(tempTokenDefault || '');

  const [code, setCode] = useState('');
  const [validatingCode, setValidatingCode] = useState(false);

  const { verification } = onboarding;

  const loadUserAndHandleLogin = async (token: string) => {
    await Promise.all([fetchUser(), updateUserAvailability()]);
    if (handleLogin) handleLogin(token);
  };

  const sendLoginEmail = async () => {
    if (sendingEmail) return;
    if (!user.email) return;
    setSendingEmail(true);

    const res = await postUserLogin({
      email: user.email,
      isFromApp: !!window.bridge,
    });
    if (res.ok) {
      // Temporarily invalid refresh token, verified by code later.
      setTempToken(res.getJson.token);
      showNotif({
        message: 'We sent a login code to your email!',
        level: 'success',
      });
    } else
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });

    setSendingEmail(false);
  };

  const validateCode = async () => {
    if (!code) return;
    if (code.length !== 6) return;
    if (validatingCode) return;
    setValidatingCode(true);
    const res = await createOrActivateToken(tempToken, !!window.bridge, code);
    if (res.ok) {
      const { auth_token } = res.getJson;
      setElliotToken(auth_token);
      loadUserAndHandleLogin(auth_token);
    } else {
      showNotif({ message: 'Invalid code', level: 'error' });
    }
    setValidatingCode(false);
  };

  return (
    <Container>
      <MailImage src={verifySvgv2} alt="Verify" />

      <Heading3 style={{ marginBottom: margins.size3, marginTop: margins.size3 }}>
        {verification.title}
      </Heading3>
      <CodeInput setCode={setCode} onSubmit={validateCode} />
      <Text
        color={colors.blackLight}
        style={{ marginTop: margins.size2, marginBottom: margins.size6 }}
      >
        {verification.subDescription[0]}
        <b>{verification.subDescription[1]}</b>
        {verification.subDescription[2]}
      </Text>

      <ClickableText big onClick={sendLoginEmail} style={{ maxWidth: 250 }}>
        {verification.buttonText}
      </ClickableText>

      <Button
        onClick={validateCode}
        invalid={code.length !== 6}
        loading={validatingCode}
        maxWidth="80%"
        style={{ marginTop: margins.size3 }}
      >
        Continue
      </Button>
      <Text style={{ marginTop: margins.size1 }}>
        <KeyEmoji /> We just sent you a login code.
      </Text>
      <Text bold>Check your inbox for the code!</Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30;
  margin-bottom: 30;
  text-align: center;
  color: ${colors.primaryMain};
  align-items: center;
`;

const MailImage = styled.img`
  margin-bottom: ${margins.size4};
  margin-top: ${margins.size6};
  width: 107px;
`;
