import { useCallback, useEffect, useRef } from 'react';

type IsMuted = () => boolean;

const defaultNotMuted = () => false;

export const useSound = (src: string, isMuted: IsMuted = defaultNotMuted) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const tag: HTMLAudioElement | null = document.querySelector(`audio[src="${src}"]`);

    if (tag) {
      audioRef.current = tag;
    } else {
      const t = document.createElement('audio');
      t.src = src;
      t.setAttribute('playsinline', '');
      document.body.appendChild(t);
      audioRef.current = t;
    }
  }, [src]);

  const load = useCallback(() => {
    if (!audioRef.current) return;
    audioRef.current.load();
  }, [audioRef]);

  const play = useCallback(async () => {
    if (!audioRef.current || isMuted()) return;
    try {
      audioRef.current.currentTime = 0;
      await audioRef.current.play();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [audioRef, isMuted]);

  return { load, play };
};
