import { useState } from 'react';

import { useMountEffect } from '../custom-hooks';

export default function useRotating<T>(list: T[], delay: number): [T, number] {
  const [index, setIndex] = useState(0);

  useMountEffect(() => {
    const interval = setInterval(() => {
      setIndex(previousIndex => (previousIndex + 1) % list.length);
    }, delay);
    return () => clearInterval(interval);
  });

  return [list[index], index];
}
