import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { colors, FlexRow, globalTransitionSettings, margins, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { countUp } from 'js/util/util';

import streak from 'img/weekly/streak.svg';
import buttonCheck from 'img/weekly/button-check.svg';

const poppingKeyframe = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.8);
  }
`;

interface Props {
  streakLength: number;
  onFinish: () => void;
  buttonText: string;
  hasSubmitted: boolean;
  buttonLoading: boolean;
  duration: number;
  numMeetings: number;
  invalid: boolean;
}
export const StreakFinishButton: React.FC<Props> = ({
  streakLength,
  onFinish,
  buttonText,
  hasSubmitted,
  buttonLoading,
  duration,
  numMeetings,
  invalid,
}) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (!hasSubmitted) return;
    countUp(streakLength, setCounter, duration);
  }, [hasSubmitted]);

  return (
    <Container>
      <Button
        style={{ margin: '0px' }}
        onClick={onFinish}
        loading={buttonLoading && !hasSubmitted}
        invalid={hasSubmitted || invalid}
      >
        {hasSubmitted ? (
          <FlexRow style={{ gap: margins.size1 }}>
            <img src={buttonCheck} alt="" />
            <Text bold color={colors.primaryMain}>
              {numMeetings > 0
                ? `Signed up for ${numMeetings} ${numMeetings > 1 ? 'matches' : 'match'}!`
                : `Passed for the week.`}
            </Text>
          </FlexRow>
        ) : (
          buttonText
        )}
      </Button>
      {hasSubmitted && numMeetings > 0 && (
        <StreaksContainer>
          <StreaksIcon hasSubmitted={hasSubmitted} src={streak} alt="" />
          <Text bold>{counter} week streak!</Text>
        </StreaksContainer>
      )}
    </Container>
  );
};

const Container = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  margin-top: ${margins.size4};
`;

const StreaksContainer = styled(FlexRow)`
  min-width: 130px;
  justify-content: space-between;

  transition: ${globalTransitionSettings};
`;

const StreaksIcon = styled.img<{ hasSubmitted: boolean }>`
  animation-duration: 0.4s;
  animation-name: ${poppingKeyframe};
  animation-iteration-count: 2;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  margin-right: ${margins.size1};
`;
