/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

import { colors, margins, FlexRow, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { postAuth } from 'js/util/api';
import { useNotifContext } from 'js/util/notif-context';
import { signin } from 'js/util/strings';
import { getParameterByName, GOOGLE_CLIENT_ID, setElliotToken } from 'js/util/util';

import { google } from 'img/verify';

const FULL_SCOPE =
  'profile email https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly';

interface Props {
  isRegistering?: boolean;
  isNewUser?: boolean;
  onLogIn?: (res: any) => void;
  buttonWidth?: string;
  buttonText?: string;
  confirmingLogin?: boolean;
  customButton?: JSX.Element;
  promptPermissions?: boolean;
  tag?: string;
  email?: string;
}

export const GoogleSignIn: React.FC<Props> = ({
  isRegistering,
  isNewUser,
  onLogIn,
  buttonWidth,
  buttonText: passedButtonText,
  confirmingLogin,
  customButton,
  promptPermissions,
  tag: passedTag,
  email: passedEmail,
}) => {
  const history = useHistory();
  const { showNotif } = useNotifContext();

  const [loading, setLoading] = useState(false);

  const getAuthErrorMessage = (apiResponse: any) => {
    if (apiResponse.status === 200) {
      return null;
    }
    const json = apiResponse.getJson;
    if (json.error === 'Not ready to be activated.') {
      return signin.errors.incomplete;
    }
    if (json.error === 'Not a Concierge user.') {
      return signin.errors.notFound;
    }
    if (json.error === 'Another matching user exists.') {
      return signin.errors.conflict;
    }
    return signin.errors.other;
  };

  const success = (
    googleResponse: GoogleLoginResponse | GoogleLoginResponseOffline | { serverAuthCode: string },
  ) => {
    const payload =
      'code' in googleResponse
        ? { gauthCode: googleResponse.code, isRegistering, isNewUser }
        : 'serverAuthCode' in googleResponse
        ? { gauthCode: googleResponse.serverAuthCode, isRegistering, isNewUser, isMobile: true }
        : {}; // we should never get a GoogleLoginResponse because we ask for Offline access

    postAuth(payload)
      .then(response => {
        if (response.status === 200) {
          setElliotToken(response.getJson.auth_token);
          if (onLogIn) onLogIn(response);
        } else {
          const errorMessage = getAuthErrorMessage(response);
          if (response.status === 302) {
            history.push(`/google?message=${errorMessage}`);
          }
          if (errorMessage) showError(errorMessage);
        }
        setLoading(false);
      })
      .catch(() => {
        showError(signin.errors.other);
        setLoading(false);
      });
  };

  const showError = (message: string) => {
    showNotif({
      message,
      level: 'error',
      autoDismiss: 10,
    });
  };

  let buttonText = 'Connect your Google account';
  if (passedButtonText) {
    buttonText = passedButtonText;
  }
  let tag = 'button';
  if (passedTag) {
    tag = passedTag;
  }

  const email = getParameterByName('email') || undefined;

  const defaultButton = (
    <Button style={{ margin: 0, width: buttonWidth || 'fit-content' }} secondary>
      <FlexRow justifyContent="center">
        <GoogleLogo src={google} alt="Google logo" />
        <Text color={colors.primaryMain}>{buttonText}</Text>
      </FlexRow>
    </Button>
  );

  if ((loading || confirmingLogin) && !customButton) {
    return <SVGLoader center={false} />;
  }
  if (window.bridge) {
    return React.cloneElement(customButton || defaultButton, {
      disabled: loading,
      onClick: () => {
        if (!window.bridge) return;
        setLoading(true);
        window.bridge.connectGoogleAccount(serverAuthCode => {
          setLoading(false);
          success({ serverAuthCode });
        });
      },
    });
  }

  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      scope={FULL_SCOPE}
      onSuccess={success}
      onRequest={() => setLoading(true)}
      onFailure={() => setLoading(false)}
      responseType="code"
      prompt={promptPermissions ? 'select_account consent' : 'select_account'}
      tag={tag}
      loginHint={passedEmail || email}
      accessType="offline"
      render={renderProps =>
        React.cloneElement(customButton || defaultButton, {
          onClick: renderProps.onClick,
          disabled: renderProps.disabled,
        })
      }
    />
  );
};

const GoogleLogo = styled.img`
  height: 18px;
  width: 18px;
  min-height: 18px;
  min-width: 18px;
  margin-right: ${margins.size2};
`;
