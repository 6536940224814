import React from 'react';

import { useUser } from 'js/providers/UserProvider';

import MobileCallPage from '../callv2/components/mobile';

import { CallVersionSelector } from './CallVersionSelector';
import { SharedCallContextProvider } from './SharedCallContext';

export const CallPage: React.FC = () => {
  const user = useUser();

  return user ? (
    <SharedCallContextProvider>
      {window.bridge?.showCall ? <MobileCallPage /> : <CallVersionSelector />}
    </SharedCallContextProvider>
  ) : null;
};
