/* eslint-disable no-alert */
import React, { useRef } from 'react';

import { Button } from 'js/components/shared/Button';
import { getCityFromLatLong, postTestActivity } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';

const LOG_TYPE = 'location_auth';

export const CurrentLocationButton: React.FC<{
  onGotGooglePlaceId: (googlePlaceId: string) => void;
}> = ({ onGotGooglePlaceId }) => {
  const [loading, setLoading] = React.useState(false);
  const [resolved, setResolved] = React.useState(``);
  const didPostLogRef = useRef(false);

  useMountEffect(() => {
    if (!!window.bridge && !didPostLogRef.current) {
      didPostLogRef.current = true;
      postTestActivity(LOG_TYPE, 'view');
    }
  });

  if (!window.bridge) {
    return <span />;
  }

  return (
    <Button
      style={{ width: '100%', marginTop: 10 }}
      loading={loading}
      onClick={() => {
        if (!window.bridge || loading) return;
        setLoading(true);
        postTestActivity(LOG_TYPE, 'accept');
        window.bridge.retrieveCurrentLocation(true, async result => {
          if ('error' in result) {
            postTestActivity(LOG_TYPE, 'native-reject');
            if (result.error === 'Not authorized') {
              alert('Sorry, you must allow access to Location in iOS Settings');
            } else {
              alert('Sorry, we were unable to determine your location.');
            }
            // We could show the error, but most likely the user just declined the prompt
            setLoading(false);
            return;
          }

          postTestActivity(LOG_TYPE, 'native-accept');

          const res = await getCityFromLatLong(result.lat, result.lon);
          setLoading(false);

          if (res.ok && res.getJson.length && res.getJson[0]) {
            const place = res.getJson[0];
            setResolved(place.formatted_address);
            onGotGooglePlaceId(place.place_id);
          }
        });
      }}
    >
      {resolved.length ? resolved : `Use Current Location`}
    </Button>
  );
};
