import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Clickable, colors, FlexRow, Heading3, margins } from 'css/css';

import Channel from 'types/channel';

import { Avatar } from 'js/components/shared/Avatar';

export const MatchChannelLink = ({ matchChannel }: { matchChannel: Channel }) => {
  const history = useHistory();
  return (
    <Clickable
      onClick={e => {
        e.stopPropagation();
        history.push(`/chat?chat_id=${matchChannel.profileId}`);
      }}
    >
      <FlexRow alignItems="flex-start">
        <StyledAvatar size0 src={matchChannel.avatar} />
        <Heading3 color={colors.primaryMain}>{matchChannel.firstName} </Heading3>
      </FlexRow>
    </Clickable>
  );
};

const StyledAvatar = styled(Avatar)`
  margin: 0 ${margins.size1};
  position: relative;
  top: 2px;
`;
