import * as React from 'react';

import { SharedCallContextProvider } from 'js/components/call/SharedCallContext';

import Call from './Call';

const MobileCallPage: React.FC = () => {
  return (
    <SharedCallContextProvider>
      <Call />
    </SharedCallContextProvider>
  );
};

export default MobileCallPage;
