import React from 'react';
import styled from 'styled-components/macro';

import { margins, Heading3, FlexRow, Text, FlexColumn } from 'css/css';

import { Avatar } from 'js/components/shared/Avatar';
import { Button } from 'js/components/shared/Button';

const getEnglishSentence = (names: any) => {
  const outputNames = [...names];
  const { length } = outputNames;
  if (length > 1) outputNames[length - 1] = `and ${outputNames[length - 1]}`;
  if (length === 2) return outputNames.join(' ');
  return outputNames.join(', ');
};

export const FeedbackCard = ({ matchesToReview, startSubmittingFeedback }: any) => {
  const header = `et us know how your meeting${matchesToReview.length > 1 ? 's' : ''} went`;
  const matchNames = matchesToReview.map((match: any) => match.match_user.first_name);
  const matchSentence = `Before signing up, l${header} with ${getEnglishSentence(matchNames)}.`;

  return (
    <FlexColumn>
      <FlexRow>
        {matchesToReview.map((match: any, i: any) => (
          <Avatar
            src={match.match_user.image}
            avatarId={match.match_user.public_id}
            style={{ marginLeft: i > 0 ? -14 : 0 }}
          />
        ))}
      </FlexRow>

      <Heading3 style={{ marginTop: margins.size4 }}>{`L${header}!`}</Heading3>

      <TextBox>
        <Text style={{ marginTop: margins.size2 }}>{matchSentence}</Text>
      </TextBox>

      <Button onClick={() => startSubmittingFeedback(true)} style={{ marginTop: margins.size4 }}>
        Give Feedback
      </Button>
    </FlexColumn>
  );
};

const TextBox = styled.div`
  max-width: 400px;
  text-align: center;
`;
