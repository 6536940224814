import React from 'react';
import styled from 'styled-components/macro';

import { colors, globalTransitionSettings, Link } from 'css/css';

import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { useUser } from 'js/providers/UserProvider';

import clubpointsSvg from 'img/navbar/clubpoints.svg';

interface Props {
  clubpoints?: number;
}

export const Clubpoints: React.FC<Props> = ({ clubpoints }) => {
  const { clubpoints: userClubpoints } = useUser();
  const displayedClubpoints = typeof clubpoints !== 'undefined' ? clubpoints : userClubpoints;
  return (
    <PointsWhiteBoxLink to="/clubpoints" onClick={e => e.stopPropagation()}>
      {displayedClubpoints >= 0 ? displayedClubpoints : <SVGLoader center={false} small />}
      <img alt="clubpoint" src={clubpointsSvg} />
    </PointsWhiteBoxLink>
  );
};

const PointsWhiteBoxLink = styled(Link)`
  background: ${colors.whiteMain};
  display: flex;
  align-items: center;
  border-radius: 12px;
  line-height: 30px;
  padding: 0 7px;
  color: ${colors.tertiary3Main};
  text-decoration: none;
  transition: ${globalTransitionSettings};
  min-height: 30px;

  img {
    width: 18px;
    height: 18px;
    margin-left: 5px;
  }

  &:hover {
    background-color: ${colors.greyMain};
    text-decoration: none;
  }
`;
