import React from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import { colors, margins, globalTransitionSettings } from 'css/css';

import HubIcon from 'img/registration/progress bar/hub.svg';
import ObjectivesIcon from 'img/registration/progress bar/objectives.svg';
import ProfileIcon from 'img/registration/progress bar/profile.svg';
import FinishIcon from 'img/registration/progress bar/finish.svg';

const ProgressBarIcons = {
  HUB: HubIcon,
  OBJECTIVES: ObjectivesIcon,
  PROFILE: ProfileIcon,
  FINISH: FinishIcon,
};

const ProgressCenter = ({ isColored, title }: any) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const icon = ProgressBarIcons[title];

  return <ProgressImage src={icon} alt={title} isColored={isColored} />;
};

const ProgressBox = ({
  title,
  isColored,
  isCenter,
  leftLine,
  rightLine,
  leftLineActive,
  rightLineActive,
}: any) => (
  <Box>
    <BoxTop>
      <Line isShown={leftLine} isColored={leftLineActive} />

      <ProgressCenter isCenter={isCenter} isColored={isColored} title={title} />

      <Line isShown={rightLine} isColored={rightLineActive} />
    </BoxTop>
  </Box>
);

export const OnboardingProgressBar = ({
  titles,
  activeIndex,
}: {
  titles: string[];
  activeIndex: number;
}) => (
  <ProgressBar>
    {titles.map((title, index) => (
      <React.Fragment key={title}>
        {(index === 0 || title !== titles[index - 1]) && (
          <ProgressBox
            title={title}
            isColored={index <= activeIndex}
            leftLine={index > 0}
            rightLine={index < titles.length - 1}
            leftLineActive={activeIndex >= index}
            rightLineActive={activeIndex > index}
          />
        )}
      </React.Fragment>
    ))}
  </ProgressBar>
);

const ProgressBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors.whiteMain};
  max-width: 486px;
  margin: 0 auto;
  margin-top: ${margins.size4};
`;

const BoxTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Box = styled.div`
  width: 200px;
  text-align: center;
`;

type LineTypes = {
  isShown: boolean;
  isColored: boolean;
};
const Line = styled.div<LineTypes>`
  flex-grow: 1;
  max-width: 50%;
  height: 1px;
  opacity: ${p => (p.isShown ? 1 : 0)};
  background-color: ${p => (p.isColored ? colors.primaryMain : colors.primaryLight)};
  transition: ${globalTransitionSettings};
`;

type ProgressImageTypes = { isColored: boolean };
const ProgressImage = styled(SVG)<ProgressImageTypes>`
  & > path {
    transition: ${globalTransitionSettings};
    fill: ${p => (p.isColored ? colors.primaryMain : colors.whiteMain)};
    stroke: ${p => (p.isColored ? colors.primaryMain : colors.primaryLight)};
  }
`;
