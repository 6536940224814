import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { margins, FlexRow, Card } from 'css/css';

import { useMountEffect } from 'js/util/custom-hooks';
import { APP_STORE_LINK } from 'js/util/util';

import downloadAppSvg from 'img/homepage/download-app.svg';
import qrCode from 'img/homepage/lunchclub-appstore-qr.svg';

export const AppLinks: React.FC = () => {
  const [isDownloadAppOpen, setIsDownloadAppOpen] = useState(false);

  useMountEffect(() => {
    const id = setTimeout(() => setIsDownloadAppOpen(true), 50);
    return () => clearTimeout(id);
  });

  return (
    <Container>
      <a href={APP_STORE_LINK} target="_blank" rel="noreferrer">
        <QRCodeContainer>
          <img src={downloadAppSvg} alt="Download App" />
          <QRImage
            src={qrCode}
            alt="Lunchclub App Store Link QR Code"
            $isOpen={isDownloadAppOpen}
          />
        </QRCodeContainer>
      </a>
    </Container>
  );
};

const Container = styled(FlexRow)`
  justify-content: space-between;
  margin-top: ${margins.size4};
  align-items: flex-start;
  width: 100%;
`;

const QRCodeContainer = styled(Card)`
  border: none;
  border-radius: 8px;
  background-color: #000;
  margin: 0px;
  box-shadow: 0px 0px 23px rgba(38, 45, 68, 0.1);
`;

const QRImage = styled.img<{ $isOpen: boolean }>`
  max-height: ${p => (p.$isOpen ? '120px' : '0px')};
  margin: ${margins.size2};
  margin-top: 0px;
  width: 120px;
  opacity: ${p => (p.$isOpen ? 1 : 0)};
  transition: all 0.5s ease-in-out;
`;
