import React from 'react';
import styled from 'styled-components/macro';

import { FlexColumn, fontSizes, Heading2, margins, media } from 'css/css';

import { CoachingPlanMatchMeta } from 'js/api/coaching-plan';
import { CoachingPlanAvatars } from 'js/components/coaching_plan/CoachingPlanAvatars';

interface Props {
  matches: CoachingPlanMatchMeta[];
}
export const CoachingPlanSplash: React.FC<Props> = ({ matches }) => {
  return (
    <SplashFlex>
      <CoachingPlanAvatars matches={matches} />
      <ResponsiveHeading>
        Great! Let’s select the times for us to send you these matches.
      </ResponsiveHeading>
    </SplashFlex>
  );
};

const SplashFlex = styled(FlexColumn)`
  text-align: center;
  height: 400px;
  justify-content: center;
`;

const ResponsiveHeading = styled(Heading2)`
  padding: ${margins.size1};
  ${media.tablet} {
    font-size: ${fontSizes.size5};
  }
`;
