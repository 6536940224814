import React, { useState, useEffect } from 'react';

import { FlexColumn, margins } from 'css/css';

import { useNotifContext } from 'js/util/notif-context';
import { onboarding } from 'js/util/strings';
import { postInviteCode } from 'js/util/api';
import { cleanNames, COMMUNITIES } from 'js/util/util';
import { BigLogo as Logo } from 'js/components/shared/BigLogo';
import { TextInput } from 'js/components/shared/TextInput';
import { Button } from 'js/components/shared/Button';

import citiesGraphic from 'img/registration/cities-graphic.svg';

import { RegistrationFields } from './use-registration-fields';

interface StepNameAndCodeProps {
  values: RegistrationFields;
  prefillInviteCode: string | null;
  onSubmitCode: (firstName: string, lastName: string, inviteCode: string) => void;
  buttonLoading: boolean;
}

export const StepNameAndCode: React.FunctionComponent<StepNameAndCodeProps> = ({
  values,
  prefillInviteCode,
  onSubmitCode,
  buttonLoading,
}) => {
  const { showNotif } = useNotifContext();
  const [invalidName, setInvalidName] = useState(true);

  const [firstName, setFirstName] = useState(values.firstName);
  const [lastName, setLastName] = useState(values.lastName);

  useEffect(() => {
    if (
      firstName.replace(/\s/g, '') &&
      lastName.replace(/\s/g, '') &&
      Number.isNaN(Number(firstName)) &&
      Number.isNaN(Number(lastName))
    ) {
      setInvalidName(false);
    } else {
      setInvalidName(true);
    }
  }, [firstName, lastName]);

  const handleSubmit = async () => {
    if (invalidName) {
      showNotif({
        message: 'Please enter a valid name.',
        level: 'error',
      });
      return;
    }
    const cleanFirstName = cleanNames(firstName);
    const cleanLastName = cleanNames(lastName);

    if (prefillInviteCode) {
      const res = await postInviteCode(prefillInviteCode);
      if (res.ok) {
        onSubmitCode(cleanFirstName, cleanLastName, prefillInviteCode);
      } else {
        await postInviteCode('fullskip');
        onSubmitCode(cleanFirstName, cleanLastName, 'fullskip');
      }
    } else {
      await postInviteCode('fullskip');
      onSubmitCode(cleanFirstName, cleanLastName, 'fullskip');
    }
  };

  const { nameAndCode } = onboarding;

  const community = COMMUNITIES[values.organizations[0]];

  return (
    <FlexColumn padding={`${margins.size7} 10px`}>
      <FlexColumn>
        <Logo />
        {community && (
          <img
            style={{ marginTop: margins.size3, marginBottom: `-${margins.size6}` }}
            src={community.img}
            alt={community.name}
          />
        )}
      </FlexColumn>

      <img
        src={citiesGraphic}
        alt="cities"
        style={{ margin: `${margins.size6} auto`, width: '90%' }}
      />

      <TextInput
        value={firstName}
        onChange={(val: any) => setFirstName(val)}
        placeholder={nameAndCode.firstNamePlaceholder}
        onEnter={handleSubmit}
      />

      <TextInput
        value={lastName}
        onChange={(val: any) => setLastName(val)}
        placeholder={nameAndCode.lastNamePlaceholder}
        onEnter={handleSubmit}
        style={{ marginBottom: margins.size4 }}
      />

      <Button
        loading={buttonLoading}
        invalid={invalidName}
        onClick={handleSubmit}
        invalidMessage="Please enter a valid name."
        maxWidth="350px"
      >
        {nameAndCode.buttonText}
      </Button>
    </FlexColumn>
  );
};
