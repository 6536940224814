import { useEffect, useState } from 'react';
import { OptionTypeBase } from 'react-select';

import { getAutoCompletedCities } from 'js/util/api';

import { useDebounce } from './custom-hooks';

const turkishCharacters = [
  'ı',
  'İ',
  'i',
  'I',
  'ş',
  'Ş',
  's',
  'S',
  'ç',
  'Ç',
  'c',
  'C',
  'ğ',
  'Ğ',
  'g',
  'G',
  'ö',
  'Ö',
  'o',
  'O',
  'ü',
  'Ü',
  'u',
  'U',
];

const turkishCharRegex = (keyword: string) =>
  keyword
    .replace(/[ıİiI]/g, '[ıİiI]')
    .replace(/[şŞsS]/g, '[şŞsS]')
    .replace(/[çÇcC]/g, '[çÇcC]')
    .replace(/[ğĞgG]/g, '[ğĞgG]')
    .replace(/[öÖoO]/g, '[öÖoO]')
    .replace(/[üÜuU]/g, '[üÜuU]');

export const filterOptions = (option: OptionTypeBase, searchText: string) => {
  const containsTurkishChars = turkishCharacters.includes(option.label);

  return containsTurkishChars
    ? turkishCharRegex(option.label)
        .toLowerCase()
        .includes(turkishCharRegex(searchText).toLowerCase())
    : true;
};

interface ResultItem {
  place_id: string;
  description: string;
  reference: string;
  terms: any[];
  types: string[];
}
export const useAutoCompleteSearch = () => {
  const [query, setQuery] = useState('');
  const [prevQuery, setPrevQuery] = useState('');
  const [error, setError] = useState(false);
  const [searchResults, setSearchResults] = useState<ResultItem[]>([]);

  const [isDebouncing, setDebouncing] = useState(false);

  const debouncedSearchTerm = useDebounce(query, 450);

  const setSearchQuery = (val: string) => {
    setDebouncing(true);
    setQuery(val);
  };

  useEffect(() => {
    (async () => {
      if (debouncedSearchTerm !== prevQuery) {
        setPrevQuery(debouncedSearchTerm);
        const res = await getAutoCompletedCities(debouncedSearchTerm);
        if (res.ok) {
          setSearchResults(res.getJson);
          setError(false);
        } else {
          setSearchResults([]);
          setError(true);
        }
        setDebouncing(false);
      }
    })();
  }, [debouncedSearchTerm]);

  return { query, setSearchQuery, searchResults, isDebouncing, error };
};
