import { colors } from 'css/css';

import { PartnershipDict, DefaultPartnership } from 'types/partnership-whitelabel';

import { connection, conversation, tell } from 'img/landing-page';
import {
  cowrksLogo,
  execthreadHero,
  execthreadLogo,
  lunchclubLogoMain,
  lunchclubLogoNegative,
  theWingLogo,
  thubLogo,
  weworkLogo,
  myxLogo,
} from 'img/landing-page/partnerships';

export const ABOUT_CONTENT = [
  {
    id: 0,
    text: "Tell us your background, goals, and what you're excited about",
    img: tell,
    alt: "Tell us your background, goals, and what you're excited about",
  },
  {
    id: 1,
    text: 'Our AI matches you with just the\nright person for a 1:1 conversation',
    img: conversation,
    alt: 'Our AI matches you with just the\nright person for a 1:1 conversation',
  },
  {
    id: 2,
    text: 'Your feedback improves our matches for you each week',
    img: connection,
    alt: 'Your feedback improves our matches for you each week',
  },
];

export const WHITELABEL_PARTNERS: PartnershipDict = {
  ExecThread: {
    name: 'ExecThread',
    inviteCode: 'ExecThread',
    lunchclubLogo: lunchclubLogoNegative,
    partnerLogo: execthreadLogo,
    hero: execthreadHero,
    theme: {
      logoDividerColor: colors.whiteMain,
      backgroundColor: colors.blackMain,
      headerColor: colors.whiteMain,
      descriptionColor: colors.whiteMain,
      logInButtonColor: colors.primaryMain,
      loginButtonBorder: colors.primaryMain,
    },
    copy: {
      header: 'Connect with fellow executives.',
      subHeader: 'Meet other professionals with relevant executive backgrounds, powered by AI.',
    },
  },
  THub: {
    name: 'THub',
    inviteCode: 'THub',
    lunchclubLogo: lunchclubLogoNegative,
    partnerLogo: thubLogo,
    hero: execthreadHero,
    theme: {
      logoDividerColor: colors.whiteMain,
      backgroundColor: colors.blackMain,
      headerColor: colors.whiteMain,
      descriptionColor: colors.whiteMain,
      logInButtonColor: colors.primaryMain,
      loginButtonBorder: colors.primaryMain,
    },
    copy: {
      header: 'Your network is waiting for you.',
      subHeader:
        'We facilitate casual conversations that lead to not-so-casual professional impact. Powered by AI. Join now and earn an extra 25 Clubpoints.',
    },
  },
  WomenOfWeWork: {
    name: 'WomenOfWeWork',
    inviteCode: 'WomenOfWeWork',
    lunchclubLogo: lunchclubLogoNegative,
    partnerLogo: weworkLogo,
    hero: execthreadHero,
    theme: {
      logoDividerColor: colors.whiteMain,
      backgroundColor: colors.blackMain,
      headerColor: colors.whiteMain,
      descriptionColor: colors.whiteMain,
      logInButtonColor: colors.primaryMain,
      loginButtonBorder: colors.primaryMain,
    },
    copy: {
      header: 'Your network is waiting for you.',
      subHeader:
        'We facilitate casual conversations that lead to not-so-casual professional impact. Powered by AI.',
    },
  },
  CoWrks: {
    name: 'CoWrks',
    inviteCode: 'CoWrks',
    lunchclubLogo: lunchclubLogoMain,
    partnerLogo: cowrksLogo,
    hero: execthreadHero,
    theme: {
      logoDividerColor: colors.blackMain,
      backgroundColor: colors.whiteMain,
      headerColor: colors.blackMain,
      descriptionColor: colors.blackMain,
      logInButtonColor: colors.primaryMain,
      loginButtonBorder: colors.primaryMain,
    },
    copy: {
      header: 'Your network is waiting for you.',
      subHeader:
        'We facilitate casual conversations that lead to not-so-casual professional impact. Powered by AI.',
    },
  },
  TheWing: {
    name: 'The Wing',
    inviteCode: 'TheWing',
    lunchclubLogo: lunchclubLogoMain,
    partnerLogo: theWingLogo,
    hero: execthreadHero,
    theme: {
      logoDividerColor: colors.blackMain,
      backgroundColor: colors.whiteMain,
      headerColor: colors.blackMain,
      descriptionColor: colors.blackMain,
      logInButtonColor: colors.primaryMain,
      loginButtonBorder: colors.primaryMain,
    },
    copy: {
      header: 'Your network is waiting for you.',
      subHeader:
        'We facilitate casual conversations that lead to not-so-casual professional impact. Powered by AI.',
    },
  },
  MYX: {
    name: 'MYX',
    inviteCode: 'themyx',
    lunchclubLogo: lunchclubLogoMain,
    partnerLogo: myxLogo,
    hero: execthreadHero,
    theme: {
      logoDividerColor: colors.blackMain,
      backgroundColor: colors.whiteMain,
      headerColor: colors.blackMain,
      descriptionColor: colors.blackMain,
      logInButtonColor: colors.primaryMain,
      loginButtonBorder: colors.primaryMain,
    },
    copy: {
      header: 'Your network is waiting for you.',
      subHeader:
        'We facilitate casual conversations that lead to not-so-casual professional impact. Powered by AI.',
    },
  },
};

export const DEFAULT_DARK_PARTNERSHIP: DefaultPartnership = {
  name: 'DEFAULT',
  inviteCode: undefined,
  lunchclubLogo: lunchclubLogoMain,
  partnerLogo: undefined,
  hero: execthreadHero,
  theme: {
    logoDividerColor: colors.whiteMain,
    backgroundColor: colors.blackMain,
    headerColor: colors.whiteMain,
    descriptionColor: colors.whiteMain,
    logInButtonColor: colors.primaryMain,
    loginButtonBorder: colors.primaryMain,
  },
  copy: {
    header: 'Your network is waiting for you.',
    subHeader:
      'We facilitate casual conversations that lead to not-so-casual professional impact. Powered by AI.',
  },
};

export const DEFAULT_LIGHT_PARTNERSHIP: DefaultPartnership = {
  name: 'DEFAULT',
  inviteCode: undefined,
  lunchclubLogo: lunchclubLogoMain,
  partnerLogo: undefined,
  hero: execthreadHero,
  theme: {
    logoDividerColor: colors.blackMain,
    backgroundColor: colors.whiteMain,
    headerColor: colors.blackMain,
    descriptionColor: colors.blackMain,
    logInButtonColor: colors.primaryMain,
    loginButtonBorder: colors.primaryMain,
  },
  copy: {
    header: 'Your network is waiting for you.',
    subHeader:
      'We facilitate casual conversations that lead to not-so-casual professional impact. Powered by AI.',
  },
};
