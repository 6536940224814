import React from 'react';
import styled from 'styled-components/macro';

import { FlexColumn, margins, Text } from 'css/css';

import {
  DarkCardBackground,
  WhiteCard,
  WhiteCardAction,
  WhiteCardHeader,
  WhitePrompt,
  WhiteSubprompt,
} from 'js/components/shared/MobileModal';
import { apiFetch, postTestActivity } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';

import promptContactsSvg from 'img/mobile-root/prompt-contacts.svg';

const HEIGHT = `calc(min(500px, 100vh - 180px))`;
const LOG_TYPE = 'weekly_contact_auth';

export const WeeklyContacts = ({ onFinish }: any) => {
  const onFinishWithLogger = (log: string) => {
    const newOnFinish = () => {
      postTestActivity(LOG_TYPE, log);
      onFinish();
    };
    return newOnFinish;
  };

  useMountEffect(() => {
    postTestActivity(LOG_TYPE, 'view');
  });

  return (
    <DarkCardBackground>
      <Step active>
        <WhitePrompt>Get better matches!</WhitePrompt>
        <WhiteCard style={{ height: HEIGHT, display: 'flex', flexDirection: 'column' }}>
          <WhiteCardHeader onClose={onFinishWithLogger('reject')} title="" />
          <FlexColumn style={{ flexGrow: 1, justifyContent: 'center', gap: margins.size4 }}>
            <img src={promptContactsSvg} alt="3 people" width="305px" />
            <Text style={{ textAlign: 'center' }}>
              Connect to your contacts so we can find you better matches!
            </Text>
          </FlexColumn>
        </WhiteCard>
        <WhiteCardAction
          title="Connect to contacts"
          onClick={() => {
            postTestActivity(LOG_TYPE, 'accept');
            if (window.bridge) {
              window.bridge.retrieveContacts(true, contacts => {
                // eslint-disable-next-line no-alert
                apiFetch('POST', `discover/mobile/contacts`, { contacts });
                onFinishWithLogger('native-accept')();
              });
            } else {
              // This really shouldn't happen except for when we're testing the flow
              // in Chrome instead of in the app. Web users should never see this screen.
              onFinish();
            }
          }}
        />
      </Step>
    </DarkCardBackground>
  );
};

const Step = styled.div<{ active: boolean }>`
  transition: opacity 150ms linear;
  opacity: ${p => (p.active ? 1 : 0)};
  pointer-events: ${p => (p.active ? 'inherit' : 'none')};
  bottom: 0;
  position: absolute;
  transition-delay: ${p => (p.active ? '0' : '150ms')};
  width: 100%;

  ${WhitePrompt}, ${WhiteSubprompt} {
    opacity: ${p => (p.active ? 1 : 0)};
    transition: opacity 150ms linear;
    transition-delay: ${p => (p.active ? '150ms' : '0')};
  }
`;
