import React from 'react';
import styled from 'styled-components/macro';

import { FlexColumn, MainHeading, Text, margins, media } from 'css/css';

import { Logo } from 'js/components/shared/Logo';
import { page404 } from 'js/util/strings';

export const NotFound = () => (
  <Wrapper>
    <LogoBox>
      <Logo logoWidth="150px" />
    </LogoBox>

    <Title>{page404.title}</Title>

    <Text>
      {page404.message} <a href="/">{page404.linkMessage}</a>
    </Text>
  </Wrapper>
);

const LogoBox = styled.div`
  margin-top: ${margins.size5};
`;

const Title = styled(MainHeading)`
  margin: ${margins.size7} 0px ${margins.size7} 0px;
`;

const Wrapper = styled(FlexColumn)`
  padding: ${margins.size5};
  ${media.mobile} {
    padding: ${margins.size4};
  }
`;
