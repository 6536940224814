import styled from 'styled-components/macro';

import { margins, fonts, colors } from 'css/css';
import { underlineAnimation } from 'css/animations';

export const LinkText = styled.p`
  margin-top: ${margins.size3};
  margin-bottom: 0px;
  font-family: ${fonts.bold};
  font-size: 16px;
  text-align: left;
  color: ${colors.primaryMain};
  ${underlineAnimation}
`;
