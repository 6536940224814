import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';

import { colors, FlexColumn, FlexRow, margins, Text } from 'css/css';

import { SettingsSection } from 'js/components/settings/components/shared-components/EditableSection';
import { Button } from 'js/components/shared/Button';
import { useOutsideAlerter } from 'js/util/custom-hooks';
import {
  consolidateTimeslots,
  getIsMobile,
  getTimezoneAbbr,
  INTERNATIONAL_TIME_LOCALES,
} from 'js/util/util';
import { SimpleModalWrap, useSimpleModal, WhiteCardHeader } from 'js/components/shared/MobileModal';
import { TimesSelector } from 'js/components/autopilot/components/TimesSelector';
import { useUser, useUserAvailability, useUserContextProvider } from 'js/providers/UserProvider';
import { useDataContext } from 'js/providers/DataContextProvider';
import { postAvailability } from 'js/util/api';
import { apiFailure } from 'js/util/strings';
import { useNotifContext } from 'js/util/notif-context';
import { SmallArrow } from 'js/components/shared/SmallArrow';

import { Container, SelectMenu } from './MeetingFrequency';

enum Steps {
  closed,
  selection,
  changeThisWeek,
  changeDefault,
}

interface TimeslotSelectOptionType {
  title: string;
  onClick: () => void;
  mobileOnClick: () => void;
}

export const TimesEditor = () => {
  const [step, setStep] = useState(Steps.closed);
  const [selectedTimeslots, setSelectedTimeslots] = useState<string[]>([]);
  const [defaultTimeslots, setDefaultTimeslots] = useState<string[]>([]);

  const isMobile = getIsMobile();

  const userAvailability = useUserAvailability();
  const selectedCadence = userAvailability?.autopilot_cadence;
  const user = useUser();
  const { allLocales } = useDataContext();
  const { fetchUser, updateUserAvailability } = useUserContextProvider();
  const { showNotif } = useNotifContext();

  const userLocale = allLocales.find(rawLocale => rawLocale.id === user.locale);
  const is24Hour = !!userLocale?.id && INTERNATIONAL_TIME_LOCALES.includes(userLocale.id);
  const isEditButtonVisible =
    !!userAvailability?.availability_autopilot?.autopilot &&
    !user.autopilot_paused_until &&
    (isMobile || step === Steps.closed);

  const consolidatedTimeslots = consolidateTimeslots(userAvailability?.time_slots || []);

  const { openModal, closeModal, wrapperProps } = useSimpleModal();
  const userTimezone = getTimezoneAbbr(user.locale_info.timezone);

  const containerRef = useRef<HTMLInputElement>(null);
  useOutsideAlerter(containerRef, () => setStep(Steps.closed));

  useEffect(() => {
    if (userAvailability?.time_slots) {
      setSelectedTimeslots(userAvailability?.time_slots);
    }

    if (userAvailability?.availability_autopilot?.time_slots) {
      setDefaultTimeslots(userAvailability?.availability_autopilot?.time_slots);
    }
  }, [userAvailability, step]);

  useEffect(() => {
    if (userAvailability?.time_slots) {
      setSelectedTimeslots(userAvailability?.time_slots);
    }

    if (userAvailability?.availability_autopilot?.time_slots) {
      setDefaultTimeslots(userAvailability?.availability_autopilot?.time_slots);
    }
  }, [userAvailability, step]);

  const handleEditClick = () => {
    if (!isEditButtonVisible) return;
    setStep(Steps.selection);
    if (isMobile) {
      openModal();
    }
  };

  const handleBackClick = () => {
    setStep(Steps.selection);
  };

  const handleCloseModal = () => {
    setStep(Steps.selection);
    closeModal();
  };

  const handleChangeThisWeekClick = () => {
    setStep(Steps.changeThisWeek);
  };

  const handleChangeDefaultClick = () => {
    setStep(Steps.changeDefault);
  };

  const getSubmitButtonText = () => {
    if (step === Steps.changeThisWeek) {
      if (selectedTimeslots.length > 0) {
        return 'Save for this week';
      }
      return 'Pass for a week';
    }
    return 'Update default times';
  };

  const getTimeslotsText = () => {
    if (user.autopilot_paused_until) {
      return 'Paused';
    }

    if (userAvailability?.time_slots.length === 0) {
      return 'Passed for the week';
    }

    return null;
  };

  const selectedOptions =
    getTimeslotsText() ||
    Object.keys(consolidatedTimeslots).map(
      day =>
        `${moment(consolidatedTimeslots[day][0]).format('ddd')}  
        ${consolidatedTimeslots[day]
          .map((t: string) => moment(t).format(is24Hour ? 'H:mm' : 'ha'))
          .join(', ')}`,
    );

  const submitTimeslots = async () => {
    const isChangingDefault = step === Steps.changeDefault;
    if (isChangingDefault && defaultTimeslots.length === 0) {
      return;
    }
    const videoNeighborhood = allLocales.find(n => n.id === user.locale)?.videoNeighborhood;
    const timeslots = isChangingDefault ? defaultTimeslots : selectedTimeslots;
    const numberOfMeetings = isChangingDefault ? defaultTimeslots.length : selectedTimeslots.length;

    const res = await postAvailability({
      locale: user.locale,
      timeslots,
      neighborhoods: [videoNeighborhood || 0],
      numberOfMeetings,
      autopilotCadence: selectedCadence,
      autopilot: isChangingDefault,
      targetLocale:
        userAvailability?.availability_autopilot?.target_locale !== null ? user.locale : null,
    });
    if (res.ok) {
      await Promise.all([fetchUser(), updateUserAvailability()]);
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }

    if (isMobile) {
      handleCloseModal();
    } else {
      setStep(Steps.closed);
    }
  };

  const TimeslotSelectOptions: Record<string, TimeslotSelectOptionType> = {
    JustThisWeek: {
      title: 'Change for just this week',
      onClick: () => setStep(Steps.changeThisWeek),
      mobileOnClick: () => handleChangeThisWeekClick(),
    },
    Default: {
      title: 'Update default times',
      onClick: () => setStep(Steps.changeDefault),
      mobileOnClick: () => handleChangeDefaultClick(),
    },
  };

  return (
    <Container ref={containerRef}>
      <SettingsSection
        onClick={handleEditClick}
        headingTitle={`Upcoming meetings (${userTimezone})`}
        isVisible={isEditButtonVisible}
        selectedOptions={selectedOptions}
      />
      {step === Steps.selection &&
        (!isMobile ? (
          <SelectMenu role="listbox">
            {Object.values(TimeslotSelectOptions).map(option => (
              <SelectOption onClick={option.onClick} role="option">
                <Text style={{ color: colors.black }}>{option.title}</Text>
                <SmallArrow
                  direction="right"
                  color={colors.blackMain}
                  style={{ marginLeft: margins.size2, marginBottom: '-2px' }}
                />
              </SelectOption>
            ))}
          </SelectMenu>
        ) : (
          <SimpleModalWrap {...wrapperProps} noAction isAboveNavbar>
            <WhiteCardHeader title="Edit meeting times" onClose={handleCloseModal} />
            <FlexColumn>
              {Object.values(TimeslotSelectOptions).map(option => (
                <FlexRow
                  width="100%"
                  justifyContent="space-between"
                  style={{ padding: `${margins.size3} 0`, cursor: 'pointer' }}
                  onClick={option.mobileOnClick}
                >
                  <Text style={{ color: colors.black }}>{option.title}</Text>
                  <SmallArrow direction="right" color={colors.blackMain} />
                </FlexRow>
              ))}
            </FlexColumn>
          </SimpleModalWrap>
        ))}
      {selectedCadence &&
        (step === Steps.changeDefault || step === Steps.changeThisWeek) &&
        (!isMobile ? (
          <SelectMenu role="listbox" $shouldAddPadding>
            <FlexRow style={{ width: '100%' }}>
              <LeftBox onClick={() => setStep(Steps.selection)}>
                <SmallArrow color={colors.blackMain} direction="left" />
              </LeftBox>
            </FlexRow>
            <TimesSelector
              selectedTimeslots={
                step === Steps.changeThisWeek ? selectedTimeslots : defaultTimeslots
              }
              setSelectedTimeslots={
                step === Steps.changeThisWeek ? setSelectedTimeslots : setDefaultTimeslots
              }
              isForNextWeek={step === Steps.changeThisWeek}
              isModal
              selectedCadence={selectedCadence}
            />
            <Button
              onClick={() => submitTimeslots()}
              large
              invalid={step === Steps.changeDefault && defaultTimeslots.length === 0}
            >
              {getSubmitButtonText()}
            </Button>
          </SelectMenu>
        ) : (
          <SimpleModalWrap {...wrapperProps} noAction isAboveNavbar>
            <WhiteCardHeader
              title="Edit meeting times"
              subtext={`Times in ${userTimezone}`}
              onClose={handleCloseModal}
              onPrev={handleBackClick}
            />
            <TimesSelector
              selectedTimeslots={
                step === Steps.changeThisWeek ? selectedTimeslots : defaultTimeslots
              }
              setSelectedTimeslots={
                step === Steps.changeThisWeek ? setSelectedTimeslots : setDefaultTimeslots
              }
              isForNextWeek={step === Steps.changeThisWeek}
              selectedCadence={selectedCadence}
            />
            <Button
              onClick={() => submitTimeslots()}
              large
              invalid={step === Steps.changeDefault && defaultTimeslots.length === 0}
            >
              {getSubmitButtonText()}
            </Button>
          </SimpleModalWrap>
        ))}
    </Container>
  );
};

const LeftBox = styled.div`
  margin-right: auto;
  margin-left: 0;
  cursor: pointer;
`;

export const SelectOption = styled(FlexRow)`
  width: 100%;
  padding: ${margins.size3};
  text-align: left;

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &:hover {
    background-color: ${colors.greyLight};
    cursor: pointer;
  }
`;
