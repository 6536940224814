import React, { useEffect, createRef } from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { Card, margins, media, FlexColumn, Heading2, colors, Clickable } from 'css/css';

import { getIsMobile } from 'js/util/util';

import { DeckCardButtons, Buttons } from './shared/DeckCardButtons';
import { CARD_TRANSITION_ENTER_DURATION, CARD_TRANSITION_LEAVE_DURATION } from './constants';

export const DECK_CARD_CONTAINER_CONTENT_PADDING = parseInt(margins.size5, 10);
const DECK_CARD_CONTAINER_CONTENT_PADDING_MOBILE = parseInt(margins.size4, 10);
export const DECK_CARD_CONTAINER_WIDTH = 600;

interface DeckCardContainerType {
  feedSession: 'Personality' | 'DiscoverBooking' | 'Endorsement' | 'Regular';
  deckCardId?: number;
  isHidden?: boolean;
  style?: any;
  className?: string;
  isLoading?: boolean;
  buttons?: Buttons;
  isSelected?: boolean;
  minDesktopHeight?: string;
  contentStyle?: React.CSSProperties;
  setContentHeight?: React.Dispatch<React.SetStateAction<number | undefined>>;
  isEndCard?: boolean;
  areItemsWrapped?: boolean;
}

export const CardQuestionWithHeightContainer: React.FC<{
  setHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
}> = ({ setHeight, children }) => {
  return (
    <HeightContainer setHeight={setHeight}>
      <CardQuestion>{children}</CardQuestion>
    </HeightContainer>
  );
};
export const CardQuestion = styled(Heading2)`
  color: ${colors.blackMain};
  text-align: center;
  margin-bottom: ${margins.size4};
`;

export const DeckCardItem = styled.div`
  width: 100%;
  text-align: center;
  &:first-child {
    margin-top: auto;
  }
  &:last-child {
    margin-bottom: auto;
  }
`;

export const DeckCardContainer: React.FC<DeckCardContainerType> = ({
  feedSession,
  deckCardId,
  isHidden,
  children,
  style,
  className,
  isLoading,
  buttons,
  isSelected,
  minDesktopHeight,
  contentStyle,
  setContentHeight,
  isEndCard,
  areItemsWrapped = true,
}) => {
  const isMobile = getIsMobile();

  return (
    <StyledCardContainer
      isHidden={isHidden}
      style={style}
      className={className}
      minDesktopHeight={minDesktopHeight}
    >
      <HeightContainer
        setHeight={(height: number) => {
          if (setContentHeight !== undefined) {
            setContentHeight(
              height -
                2 *
                  (isMobile
                    ? DECK_CARD_CONTAINER_CONTENT_PADDING_MOBILE
                    : DECK_CARD_CONTAINER_CONTENT_PADDING),
            );
          }
        }}
      >
        <QuestionContainer
          $hasBottomBorderRadius={!!isEndCard && !isMobile}
          $hasAllBorderRadius={feedSession === 'DiscoverBooking'}
        >
          <StyledFlexColumn
            minDesktopHeight={minDesktopHeight}
            style={contentStyle}
            isSelected={isSelected}
          >
            {areItemsWrapped
              ? React.Children.map(children, child => <DeckCardItem>{child}</DeckCardItem>)
              : children}
          </StyledFlexColumn>
        </QuestionContainer>
      </HeightContainer>
      {isLoading !== undefined && isSelected !== undefined && buttons !== undefined && (
        <DeckCardButtons deckCardId={deckCardId} buttons={buttons} />
      )}
    </StyledCardContainer>
  );
};

const QuestionContainer = styled.div<{
  $hasBottomBorderRadius: boolean;
  $hasAllBorderRadius: boolean;
}>`
  background-color: ${colors.whiteMain};
  ${p => p.$hasBottomBorderRadius && !p.$hasAllBorderRadius && 'border-radius: 0px 0px 10px 10px;'}
  ${p => p.$hasAllBorderRadius && 'border-radius: 10px;'}
  height: 100%;
`;

type StyledCardContainerTypes = {
  isHidden?: boolean;
  minDesktopHeight?: string;
};

const StyledCardContainer = styled(Card)<StyledCardContainerTypes>`
  display: ${p => (p.isHidden ? 'none' : 'default')};
  width: ${DECK_CARD_CONTAINER_WIDTH}px;
  text-align: left;
  border: none;
  background-color: transparent;
  height: 100%;
  ${media.desktop} {
    ${p => (p.minDesktopHeight ? `min-height: ${p.minDesktopHeight}; ` : '')};
  }
  ${media.mobile} {
    border-radius: 0;
    border: none;
    display: flex;
    width: 100%;
  }
`;

const appear = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledFlexColumn = styled(FlexColumn)<{
  isSelected?: boolean;
  minDesktopHeight?: string;
}>`
  width: 100%;
  height: 100%;
  padding: ${DECK_CARD_CONTAINER_CONTENT_PADDING}px;
  ${p => (p.minDesktopHeight ? 'height: 100%' : '')};
  align-items: center;
  ${media.desktop} {
    transition: all ${CARD_TRANSITION_LEAVE_DURATION}ms ease-in-out;
    ${p => p.isSelected && 'opacity: 0;'}
    animation: ${appear} ${CARD_TRANSITION_ENTER_DURATION}ms ease-in-out;
  }
  ${media.mobile} {
    padding: ${DECK_CARD_CONTAINER_CONTENT_PADDING_MOBILE}px;
    overflow: auto;
    transition: all ${CARD_TRANSITION_LEAVE_DURATION}ms ease-in;
    animation: slideIn ${CARD_TRANSITION_ENTER_DURATION}ms ease-out;
    ${p => p.isSelected && 'transform: translateX(-100%);'}
    ${p => p.isSelected && 'opacity: 0;'}
  }
`;

interface HeightContainerProps {
  setHeight: (height: number) => void;
  isFullHeight?: boolean;
  isFullWidth?: boolean;
}
export const HeightContainer: React.FC<HeightContainerProps> = ({
  children,
  setHeight,
  isFullHeight = true,
  isFullWidth = false,
}) => {
  const divRef = createRef<HTMLDivElement>();
  useEffect(() => {
    if (divRef.current?.clientHeight !== undefined) {
      setHeight(divRef.current?.clientHeight);
    }
  });

  return (
    <div
      ref={divRef}
      style={
        isFullHeight ? { height: '100%', overflow: 'hidden' } : isFullWidth ? { width: '100%' } : {}
      }
    >
      {children}
    </div>
  );
};

export const ColoredClickable = styled(Clickable)`
  &:hover > * {
    color: ${colors.primaryMain};
  }
`;
