import { useDeepCompareMemo } from 'use-deep-compare';

import { isLocalId, isScreenId } from '../ParticipantsContext/utils';

import { useTracksContext } from '.';

export const useVideoTrack = (id: string): MediaStreamTrack | null => {
  const { videoTracks } = useTracksContext();

  const videoTrack = useDeepCompareMemo(() => videoTracks?.[id], [id, videoTracks]);

  /**
   * MediaStreamTrack's are difficult to compare.
   * Changes to a video track's id will likely need to be reflected in the UI / DOM.
   * This usually happens on P2P / SFU switches.
   */
  return useDeepCompareMemo((): MediaStreamTrack | null => {
    if (
      videoTrack?.state === 'off' ||
      videoTrack?.state === 'blocked' ||
      (!videoTrack?.subscribed && !isLocalId(id) && !isScreenId(id))
    )
      return null;
    // persistentTrack is not a field for type DailyTrackState
    // @ts-ignore
    return videoTrack?.persistentTrack;
    // persistentTrack is not a field for type DailyTrackState
    // @ts-ignore
  }, [id, videoTrack, videoTrack?.persistentTrack?.id]);
};
