export const authenticated = {
  banned:
    'Your Lunchclub account has been deactivated. If you believe this is an error, please contact support@lunchclub.ai',
  unsubscribed: 'Your account is unsubscribed from Lunchclub. Click below to resubscribe 👇',
};

export const onboarding = {
  progressTitles: ['HUB', 'OBJECTIVES', 'OBJECTIVES', 'PROFILE', 'FINISH'],
  nameAndCode: {
    firstNamePlaceholder: 'First name',
    lastNamePlaceholder: 'Last name',
    buttonText: "Let's get started",
    apiFailure:
      'There is a technical issue, please try again. If issues persist, contact support@lunchclub.ai.',
    invalidName: 'Please enter a valid first and last name',
  },
  city: {
    title: 'Where are you based?',
    neighborhoodTitle: 'Which neighborhoods can you meet in?',
    description:
      'Lunchclub can find you a match, wherever you are! Let us know where you call home.',
    hubs: ['Bay Area, CA', 'Los Angeles, CA', 'Toronto, Canada'],
  },
  profile: {
    headers: {
      headline: "How would you like to be intro'd?",
      interests: 'What are you interested in?',
      profileData: 'Your profile',
      convoStarters: "Let's get the conversation rolling!",
    },
    descriptions: {
      headline: "Tell us whatever you'd like to share with your matches.",
      interests: 'Select from the list and add your own interests.',
      profileData:
        "This basic information will be shown to your matches every week. Tell us what you'd like to show!",
      convoStarters:
        'You can optionally pick some conversation starters from the list below. This will help you strike up conversation with your new match.',
    },
    title: 'Tell us a little bit about yourself',
    description: ``,
    headline: 'Please meet {0} {1}! {0} is ',
    headlinePlaceholder:
      'a licensed doctor. Practicing medicine does not prevent them from assisting with crime-fighting cases, which they later record and publish.',
    requiredErrorHeadline:
      'Please make sure your introduction has an appropriate length and does not contain links.',
    requiredErrorSocials: 'Please enter valid social media links, or leave blank.',
    requiredErrorInterests: 'Please select at least 1 interest.',
    emailSubject: 'Your match for this week!',
    emailFrom: 'Lunchclub',
    emailTo: '{0}, Your Match',
  },
  objectives: {
    title: 'What are your objectives?',
    description: `Select up to 3 objectives. These will be kept private from other users but help us find relevant matches.`,
    selectedTitle: 'YOUR OBJECTIVES',
    disclaimer:
      'Select up to 3 objectives. These will be kept private from other users but help our AI algorithm to find relevant matches.',
    upperThresholdError: 'Please select up to 3 objectives.',
    lowerThresholdError: 'Please select at least 1 objective.',
  },
  objectiveDetails: {
    title: 'Tell us more about your objectives',
    description: `Give us more details on what you're looking for so we can find even more relevant matches.`,
    selectedTitle: 'YOUR OBJECTIVES',
  },
  interests: {
    title: 'What are you interested in?',
    description: 'Select from the list and add your own interests.',
    selectedTitle: 'YOUR INTERESTS',
    disclaimer: 'These will be surfaced to your matches once you are introduced to break the ice.',
  },
  headline: {
    title: "How would you like to be intro'd?",
    description: "Tell us whatever you'd like to be shared with your matches.",
    selectedTitle: 'YOUR HEADLINE',
  },
  verification: {
    title: 'Enter the code from your email to verify your account!',
    description: `Thank you for registering to join Lunchclub!`,
    subDescription: [
      'We use email from ',
      'hello@lunchclub.ai',
      " to communicate with you. Please make sure we're not filtered out",
    ],
    buttonText: 'Did not receive an email? Click here to resend.',
  },
};

export const feedbackPage = {
  privacyStatement: 'Your feedback is private and will only be used to improve future matches',
  didMeet:
    'Thanks for letting us know you communicated with your match! You will be redirected shortly.',
  guidelines: 'Please read our community guideline for a reminder of the Lunchclub Code of Conduct',
  successStoryShare: 'Share my match and story with the Lunchclub community.',
  expandedSuccessStoryShare:
    'Your match and story may be featured on our social media channels! We will only use first names.',
};

export const invitePage = {
  header: 'Build the Lunchclub community!',
  inviteButtonText: `Copy invite link`,
  link: `https://lunchclub.com/?invite_code={0}`,
  cta: 'Share your invite link',
  autocompleteCta: 'Search by name or email',
  autocompleteCtaNoContacts: 'Type an email',
  headerWithMeeting: 'Tell your friends about your meeting with',
  headerWithoutMeeting: 'Tell your friends about joining Lunchclub!',
};

export const page404 = {
  title: '404 page not found',
  message: 'We are sorry but the page you are looking for does not exist.',
  linkMessage: 'Click here to return to the home page.',
};

export const signin = {
  loginButton: 'Log in',
  loginHereButton: 'Already have an account? Log in here',
  continueButton: 'Continue!',
  joinClub: 'JOIN THE CLUB',
  errors: {
    incomplete: 'Please complete registration and email verification first.',
    notFound: 'User not found, please register first.',
    conflict: 'This Google account matches a different user, please use a different account.',
    other:
      'We had an issue authenticating your account, please try again. If issues persist contact support@lunchclub.ai with error code E104.',
  },
};

export const apiFailure = {
  message: `There is a technical issue, please try refreshing. If issues persist, contact support@lunchclub.ai`,
};

export const educationPage = {
  mainTitle: 'How it works',
  sections: [
    {
      key: 'weekly',
      subtitle: 'Sign up each week for a match',
    },
    { key: 'intro', subtitle: 'We make a personalized introduction' },
    { key: 'meet', subtitle: 'Connect with your matches virtually' },
  ],
};

export const finalizeRegistration = {
  signInError:
    'Invalid email, please validate the correct email listed above. If you continue to have issues, contact Lunchclub support.',
  finishSignIn: 'Finish sign up with Google',
  gmailExplainTitle: 'Why connect my Google account?',
  gmailExplainBody: `Your contact and calendar data helps us schedule meetings and ensure that your matches are relevant (not matching you with people you already know, for instance).`,
  skipMessage: `Still don't want to connect your Google account?`,
  skipButton: 'Skip',
  wereSerious:
    "We're serious about your privacy and will never share your private data with other users or third parties without your consent.",
};
