import styled from 'styled-components/macro';

import { colors, media, Text } from 'css/css';

export const MainHeading = styled.p`
  font-family: Faktum Bold;
  font-size: 66px;
  line-height: 0.9;
  letter-spacing: -1px;
  color: ${colors.blackMain};

  ${media.mobile} {
    font-size: 42px;
  }
`;

export const Heading = styled.p`
  font-family: Faktum SemiBold;
  font-size: 44px;
  line-height: 1;
  letter-spacing: -1px;
  color: ${colors.blackMain};

  ${media.mobile} {
    font-size: 30px;
  }
`;

export const Subheading = styled(Text)`
  color: #31365a;
  opacity: 80%;
  letter-spacing: 1px;
  font-size: 12px;
`;
