import * as React from 'react';
import styled from 'styled-components/macro';

import { colors } from 'css/css';

import { LargeArrow } from '../LargeArrow';

interface RoundBackButtonProps {
  size?: number;
  color?: string;
  arrowColor?: string;
  onClick: () => void;
}

export const RoundBackButton: React.FC<RoundBackButtonProps> = ({
  size,
  color,
  arrowColor,
  onClick,
}) => {
  return (
    <RoundButton size={size} color={color} onClick={onClick}>
      <LargeArrow direction="left" color={arrowColor ?? colors.blackMain} />
    </RoundButton>
  );
};

export const RoundButton = styled.div<{ color?: string; size?: number }>`
  background-color: ${p => p.color ?? colors.whiteMain};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size = 44 }) => size}px;
  aspect-ratio: 1;
  z-index: 2;
`;
