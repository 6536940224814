import React, { memo, useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';

import { globalTransitionSettings, colors } from 'css/css';

import { useCallV2Context } from 'js/components/callv2/contexts/CallContext';
import { useMediaDevicesContext } from 'js/components/callv2/contexts/MediaDevicesContext';
import { useUIStateContext } from 'js/components/callv2/contexts/UIStateContext';
import { useParticipantsContext } from 'js/components/callv2/contexts/ParticipantsContext';
import { isMacOS } from 'js/components/callv2/utils/browser';

import microphoneMuted from 'img/call/microphone-off.svg';
import microphoneUnmuted from 'img/call/microphone-on.svg';

export const AudioControls: React.FC = memo(() => {
  const { setShowDeviceInUseModal, setShowDeviceNotFoundModal } = useUIStateContext();
  const { micError, promptForAccess, areDevicesBlocked } = useMediaDevicesContext();
  const { localParticipant } = useParticipantsContext();
  const { daily } = useCallV2Context();

  // Mute mic if localParticipant is undefined
  const muted = localParticipant?.isMicMuted ?? true;

  const toggleMic = useCallback(() => {
    if (areDevicesBlocked) {
      promptForAccess();
      return;
    }
    switch (micError) {
      case 'in-use':
        setShowDeviceInUseModal(true);
        break;
      case 'not-found':
        setShowDeviceNotFoundModal(true);
        break;
      default:
        if (daily) daily.setLocalAudio(muted);
        break;
    }
  }, [
    daily,
    areDevicesBlocked,
    micError,
    muted,
    promptForAccess,
    setShowDeviceInUseModal,
    setShowDeviceNotFoundModal,
  ]);

  const toggleKeys = `${isMacOS() ? 'command' : 'ctrl'}+d`;

  useHotkeys(
    toggleKeys,
    e => {
      e.preventDefault();
      toggleMic();
    },
    {},
    [toggleMic, toggleKeys],
  );

  const AudioIcon = muted ? AudioMuted : AudioUnmuted;
  const ariaLabel = muted ? `Turn on audio (${toggleKeys})` : `Turn off audio (${toggleKeys})`;

  return (
    <AudioButton
      role="button"
      id="audio-toggle"
      isActive={!muted}
      title={ariaLabel}
      onClick={toggleMic}
    >
      <AudioIcon isActive={!muted} aria-label={ariaLabel} />
    </AudioButton>
  );
});

const AudioButton = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  transition: ${globalTransitionSettings};
  background-color: ${p => (p.isActive ? colors.blackMain : colors.whiteMain)};

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

interface Icon {
  isActive: boolean;
}

const AudioMuted = styled(SVG).attrs({
  src: microphoneMuted,
  'alt-text': 'Audio icon',
})<Icon>`
  color: ${colors.blackMain};
`;
const AudioUnmuted = styled(SVG).attrs({ src: microphoneUnmuted, 'alt-text': 'Audio icon' })<
  Icon
>``;
