import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { margins, Heading2, Heading3, FlexRow, FlexColumn, borders, colors, Text } from 'css/css';

import { ToggleSwitch } from 'js/components/shared/ToggleSwitch';
import { useDebounce } from 'js/util/custom-hooks';

interface SlantMatchToggle {
  toggleState: boolean;
  clickToggle: (isToggleOn: boolean) => void;
  text: string;
  heading: string;
  desc: string;
}
export const SlantMatchToggle: React.FC<SlantMatchToggle> = ({
  toggleState,
  clickToggle,
  text,
  heading,
  desc,
}) => {
  const [tempToggleState, setTempToggleState] = useState(toggleState);

  const debouncedToggleState = useDebounce(tempToggleState, 500);

  useEffect(() => {
    clickToggle(debouncedToggleState);
  }, [debouncedToggleState]);

  return (
    <FlexColumn style={{ width: '100%', marginTop: margins.size3 }} alignItems="flex-start">
      <Heading2 style={{ marginBottom: margins.size3 }}>{heading}</Heading2>
      <Container>
        <FlexColumn alignItems="left" style={{ marginRight: margins.size2 }}>
          <Heading3 color={colors.primaryMain} style={{ textAlign: 'left' }}>
            {text}
          </Heading3>
        </FlexColumn>
        <ToggleSwitch
          styles={{ minWidth: '30px' }}
          isOn={toggleState}
          onClick={() => setTempToggleState(prev => !prev)}
        />
      </Container>
      <Text color={colors.blackLight} style={{ textAlign: 'left' }}>
        {desc}
      </Text>
    </FlexColumn>
  );
};

const Container = styled(FlexRow)`
  padding: 10px ${margins.size4};
  justify-content: space-between;
  border: ${borders.standard};
  border-radius: 5px;
  width: 100%;
  margin-bottom: ${margins.size1};
`;
