import React, { createRef, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';

import { colors, FlexColumn, Heading3, margins } from 'css/css';

import Channel, { SuperMessage } from 'types/channel';

import { useUser } from 'js/providers/UserProvider';
import { Button } from 'js/components/shared/Button';

import questionSvg from 'img/chat/question.svg';

import { BaseMessage } from './BaseMessage';

interface Props {
  question: string;
  responses: SuperMessage[] | undefined;
  selectedChannel: Channel;
  lastMessage: SuperMessage | undefined;
  hasUserResponded: boolean | undefined;
  userTimezone: string;
  isRespondingToIcebreaker: boolean;
  setIsRespondingToIcebreaker: React.Dispatch<React.SetStateAction<boolean>>;
}

export const IcebreakerQuestion: React.FC<Props> = ({
  question,
  responses,
  selectedChannel,
  lastMessage,
  hasUserResponded,
  userTimezone,
  isRespondingToIcebreaker,
  setIsRespondingToIcebreaker,
}) => {
  const [messageRefs, setMessageRefs] = useState([]);

  const user = useUser();

  useEffect(() => {
    setMessageRefs(prevState =>
      Array(responses?.length)
        .fill(null)
        .map((__, i) => prevState[i] || createRef()),
    );
  }, [responses?.length]);

  return (
    <IcebreakerContainer>
      <img alt="question" src={questionSvg} />
      <Heading3 style={{ marginBottom: margins.size3 }}>{question}</Heading3>
      <FlexColumn style={{ width: '100%', flexDirection: 'column-reverse' }}>
        {responses !== undefined &&
          responses
            .sort((a, b) => moment(b.dateUpdated).diff(a.dateUpdated))
            .map((m, i) => (
              <BaseMessage
                m={m}
                index={i}
                isMyMessage={m.author === user.profile_id}
                isFirstTimeScheduling={false}
                selectedChannel={selectedChannel}
                userTimezone={userTimezone}
                lastMessage={lastMessage}
                ref={messageRefs[i]}
                clickedMessageIdx={null}
                shouldDisplayAvatar={() => true}
                shouldInsertTimeDiv={() => false}
                shouldInsertGap={() => false}
                isUpcoming={false}
                isRespondingToIcebreaker={isRespondingToIcebreaker}
                setIsRespondingToIcebreaker={setIsRespondingToIcebreaker}
              />
            ))}
      </FlexColumn>
      {!isRespondingToIcebreaker && !hasUserResponded && (
        <Button
          secondary
          style={{
            marginBottom: `-${margins.size3}`,
            backgroundColor: 'transparent',
          }}
          onClick={e => {
            e.stopPropagation();
            setIsRespondingToIcebreaker(true);
          }}
        >
          + add your answer
        </Button>
      )}
    </IcebreakerContainer>
  );
};

const IcebreakerContainer = styled(FlexColumn)`
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${colors.greyLight};
  padding: ${margins.size3};
`;
