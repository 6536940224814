import React from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';
import format from 'string-format';

import {
  borders,
  Heading3,
  Text,
  FlexRow,
  colors,
  FlexColumn,
  margins,
  globalTransitionSettings,
  media,
} from 'css/css';

import { StringLengthIndicator } from 'js/components/shared/StringLengthIndicator';
import { getIsMobile } from 'js/util/util';
import { TextInput } from 'js/components/shared/TextInput';

import veryGoodEmojiMobile from 'img/feedback/very-good-v2.svg';
import alrightEmojiMobile from 'img/feedback/alright-v2.svg';
import notGoodEmojiMobile from 'img/feedback/not-good-v2.svg';
import goodEmojiMobile from 'img/feedback/good-v2.svg';
import notMotivatedEmoji from 'img/feedback/not-motivated.svg';
import motivatedEmoji from 'img/feedback/motivated.svg';
import veryMotivatedEmoji from 'img/feedback/very-motivated.svg';
import notGoodEmoji from 'img/feedback/not-good.svg';
import alrightEmoji from 'img/feedback/alright.svg';
import veryGoodEmoji from 'img/feedback/very-good.svg';

import { AutoselectableOption } from '../shared/AutoselectableOption';

const notMotivatedEmojiMobile = alrightEmojiMobile;
const motivatedEmojiMobile = goodEmojiMobile;
const veryMotivatedEmojiMobile = veryGoodEmojiMobile;

const emojis = getIsMobile()
  ? [notGoodEmojiMobile, alrightEmojiMobile, veryGoodEmojiMobile]
  : [notGoodEmoji, alrightEmoji, veryGoodEmoji];
const emojis2 = getIsMobile()
  ? [notMotivatedEmojiMobile, motivatedEmojiMobile, veryMotivatedEmojiMobile]
  : [notMotivatedEmoji, motivatedEmoji, veryMotivatedEmoji];

const OptionInputText = ({ display, value, onChange, maxLength }: any) => (
  <CollapsableInputTile display={display}>
    <StyledInput
      placeholder="Tell us more..."
      value={value}
      onChange={onChange}
      disabled={!display}
      error={value?.length > maxLength}
    />

    <StringLengthIndicator
      minLength={0}
      maxLength={maxLength}
      string={value}
      noDisplay={!(value && value.length > maxLength)}
    />
  </CollapsableInputTile>
);

const EmojiOption = ({ text, onClick, selected, emoji, answered }: any) => (
  <EmojiTile
    role="radio"
    aria-checked={selected}
    onClick={onClick}
    selected={selected}
    answered={answered}
  >
    <Emoji src={emoji} selected={selected} answered={answered} />
    <Text>{text}</Text>
  </EmojiTile>
);

export const Question: React.FC<any> = ({
  question,
  onClickAnswer,
  responses,
  matchFirstName,
  emoji,
  setDontScroll,
  maxLength,
  onOptionInputChange,
  optionInputValue,
}) => {
  return (
    <QuestionContainer>
      <QuestionHeading>{format(question.text, matchFirstName)}</QuestionHeading>
      {emoji ? (
        <FlexRow
          style={{ minHeight: '120px' }}
          alignItems="flex-start"
          width="100%"
          justifyContent="space-around"
          role="radiogroup"
        >
          {question.options.map((option: any, idx: any) => (
            <EmojiOption
              key={option.id}
              text={option.text}
              onClick={() => {
                setDontScroll(false);
                onClickAnswer(question.id, option.id);
              }}
              selected={responses.some(
                (response: any) => response[0] === question.id && response[1] === option.id,
              )}
              answered={responses.some((response: any) => response[0] === question.id)}
              emoji={question.text.includes('motivated') ? emojis2[idx] : emojis[idx]}
            />
          ))}
        </FlexRow>
      ) : (
        <FlexColumn style={{ width: '100%' }} role="radiogroup">
          {question.options.map(
            (option: any) =>
              option.text && (
                <>
                  <AutoselectableOption
                    key={option.id}
                    text={format(option.text, matchFirstName)}
                    onClick={() => {
                      setDontScroll(option.text === 'Other');
                      onClickAnswer(question.id, option.id);
                    }}
                    selected={responses.some(
                      (response: any) => response[0] === question.id && response[1] === option.id,
                    )}
                  />
                  {option.text === 'Other' && (
                    <OptionInputText
                      key={option.id + 1}
                      value={optionInputValue}
                      onChange={(value: any) => onOptionInputChange(question.id, option.id, value)}
                      display={responses.some(
                        (response: any) => response[0] === question.id && response[1] === option.id,
                      )}
                      maxLength={maxLength}
                    />
                  )}
                </>
              ),
          )}
        </FlexColumn>
      )}
    </QuestionContainer>
  );
};

const Emoji = styled(SVG)`
  margin-bottom: ${margins.size3};
  & > path {
    fill: ${p => !p.selected && colors.whiteMain};
  }
  transition: ${globalTransitionSettings};
  height: ${p => (p.answered && !p.selected ? '60px' : '72px')};
  width: ${p => (p.answered && !p.selected ? '60px' : '72px')};
  ${media.mobile} {
    margin-bottom: 0;
    height: 56px;
    width: 56px;
    & > path {
      fill: ${p => (p.selected ? colors.tertiary3Main : colors.whiteMain)};
    }
  }
`;

export const QuestionContainer = styled(FlexColumn)`
  width: 100%;
  margin-top: ${margins.size7};
  ${media.mobile} {
    margin-top: 0;
  }
`;

export const QuestionHeading = styled(Heading3)`
  margin-bottom: ${margins.size5};
  ${media.mobile} {
    margin-bottom: ${margins.size3};
    text-align: center;
  }
`;

type CollapsableInputTileTypes = {
  display?: boolean;
};
const CollapsableInputTile = styled.div<CollapsableInputTileTypes>`
  max-width: 350px;
  width: 90%;
  margin: 2px auto;
  background-color: ${colors.greyLight};
  min-height: ${p => (p.display ? '40px' : '0px')};
  max-height: ${p => (p.display ? '100px' : '0px')};
  border-radius: 3px;
  text-align: left;
  transition: ${globalTransitionSettings};
  overflow: hidden;
  ${media.mobile} {
    width: 100%;
    max-width: initial;
  }
`;

type StyledInputTypes = {
  error?: boolean;
};
const StyledInput = styled(TextInput)<StyledInputTypes>`
  width: 100%;
  max-width: 350px;
  margin: 2px auto;
  padding: ${margins.size3};
  background-color: ${colors.greyLight};
  height: 55px;
  color: ${colors.blackMid};
  border-radius: 5px;
  border: ${p => (p.error ? borders.error : 'none')};
  cursor: text;
  &::placeholder {
    color: ${colors.blackLight};
  }
  &:focus {
    outline: none;
  }
  ${media.mobile} {
    max-width: 350px;
  }
`;

type EmojiTileTypes = {
  answered: boolean;
  selected: boolean;
};
const EmojiTile = styled.div<EmojiTileTypes>`
  opacity: ${p => (p.answered && !p.selected ? 0.2 : 1)};
  min-height: 90px;
  flex: 1;
  text-align: center;
  cursor: pointer;
  transition: ${globalTransitionSettings};
  ${media.mobile} {
    opacity: 1;
  }
`;
