import React from 'react';
import styled from 'styled-components/macro';

import { BUTTON_HEIGHT, colors, FlexColumn, Heading1, margins, media } from 'css/css';

import { getIsMobile, INTERNATIONAL_TIME_LOCALES } from 'js/util/util';
import { useUser } from 'js/providers/UserProvider';
import { useDataContext } from 'js/providers/DataContextProvider';
import { useMountEffect } from 'js/util/custom-hooks';
import { Locale } from 'js/components/weekly/types';
import AnimatedEntry from 'js/components/shared/AnimatedEntry';
import { ModalContainer } from 'js/components/weekly/AutopilotForced';
import { Button } from 'js/components/shared/Button';
import { GreyPage } from 'js/components/shared/page-wrappers';

import autopilotSvg from 'img/weekly/autopilot.svg';
import calendar from 'img/registration-v2/calendar.svg';

import { SelectedAvailabilitiesBox } from './components/SelectedAvailabilitiesBox';
import { AutopilotSteps, CONFIRMATION_PAGE_DELAY, DEFAULT_CADENCE } from './constants';

interface Props {
  selectedTimeslots: string[];
  buttonOnClick: () => void;
  buttonText: string;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

export const Confirmation: React.FC<Props> = ({
  selectedTimeslots,
  buttonOnClick,
  buttonText,
  currentStep,
  setCurrentStep,
}) => {
  const user = useUser();
  const isMobile = getIsMobile();
  const { allLocales } = useDataContext();

  const userLocale = allLocales.find(rawLocale => rawLocale.id === user.locale) as Locale;
  const is24Hour = !!userLocale?.id && INTERNATIONAL_TIME_LOCALES.includes(userLocale.id);

  useMountEffect(() => {
    const settingsTimeout = setTimeout(() => {
      setCurrentStep(AutopilotSteps.SETTINGS);
    }, CONFIRMATION_PAGE_DELAY);
    return () => clearTimeout(settingsTimeout);
  });

  if (!isMobile) {
    return (
      <GreyPage>
        <ModalContainer>
          <img style={{ margin: margins.size4 }} src={autopilotSvg} alt="Calendar" />
          <Step $isActive={currentStep === AutopilotSteps.CONFIRMATION}>
            <Heading1>You&apos;re all set!</Heading1>
          </Step>
          <Step $isActive={currentStep === AutopilotSteps.SETTINGS}>
            <Heading1>Want to see where to adjust your meeting times?</Heading1>
          </Step>
          <SelectedAvailabilitiesBox
            selectedCadence={DEFAULT_CADENCE}
            selectedTimeslots={selectedTimeslots}
            is24Hour={is24Hour}
          />
          <Step $isActive={currentStep === AutopilotSteps.SETTINGS}>
            <StyledButton onClick={buttonOnClick}>{buttonText}</StyledButton>
          </Step>
        </ModalContainer>
      </GreyPage>
    );
  }

  return (
    <ConfirmationPageContainer>
      <AnimatedEntry>
        <Step $isActive={currentStep === AutopilotSteps.CONFIRMATION}>
          <img src={calendar} alt="Calendar" />
        </Step>
        <Step $isActive={currentStep === AutopilotSteps.CONFIRMATION}>
          <Heading1 color={colors.primaryLight} style={{ marginTop: margins.size4 }}>
            You&apos;re all set!
          </Heading1>
        </Step>
        <Step $isActive={currentStep === AutopilotSteps.SETTINGS}>
          <Heading1 color={colors.primaryLight} style={{ marginTop: margins.size4 }}>
            Want to see where to adjust your meeting times?
          </Heading1>
        </Step>
        <SelectedAvailabilitiesBox
          selectedCadence={DEFAULT_CADENCE}
          selectedTimeslots={selectedTimeslots}
          is24Hour={is24Hour}
        />
        <Step $isActive={currentStep === AutopilotSteps.SETTINGS}>
          <StyledButton secondary onClick={buttonOnClick}>
            {buttonText}
          </StyledButton>
        </Step>
      </AnimatedEntry>
    </ConfirmationPageContainer>
  );
};

const ConfirmationPageContainer = styled(FlexColumn)`
  background-color: ${colors.blackMain};
  padding: ${margins.size3};
  padding-bottom: calc(${margins.size4} * 2 + ${BUTTON_HEIGHT});
  height: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const Step = styled.div<{ $isActive: boolean }>`
  transition: opacity 150ms linear;
  opacity: ${p => (p.$isActive ? 1 : 0)};
  pointer-events: ${p => (p.$isActive ? 'inherit' : 'none')};
  transition-delay: ${p => (p.$isActive ? '0' : '150ms')};
  width: 100%;
  display: contents;
  ${p => !p.$isActive && 'height: 0;'}
  > * {
    ${p => !p.$isActive && 'height: 0;'}
    opacity: ${p => (p.$isActive ? 1 : 0)};
    transition: opacity 150ms linear;
    transition-delay: ${p => (p.$isActive ? '150ms' : '0')};
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${margins.size4};
  ${media.mobile} {
    position: fixed;
    bottom: ${margins.size4};
    left: ${margins.size3};
    right: ${margins.size3};
    width: auto;
  }
`;
