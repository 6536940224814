import { Message } from '@twilio/conversations/lib/message';
import { Conversation } from '@twilio/conversations/lib/data/conversations';
import { Paginator } from '@twilio/conversations/lib/interfaces/paginator';

import { NETWORK_TYPES } from 'js/util/util';

export interface MorphFeedbackMatch {
  public_id: string;
  image: string;
  first_name: string;
  last_name: string;
}

export type MessageAttributes = {
  inCall: boolean;
  schedulerTimeslots?: string[];
  confirmedTimeslot?: string;
  isReconnecting?: boolean;
  canceledSchedule?: boolean;
  isScheduling?: boolean;
  replyIndex?: number;
  messageType?: number | null;
  morpheusType?: number | null;
  feedbackMatch?: MorphFeedbackMatch;
};

export type ConversationAttributes = {
  isScheduling?: boolean;
  isRescheduling?: boolean;
  isReconnecting?: boolean;
  schedulerAuthor?: string;
  hasPrevScheduled?: boolean;
  metDate?: string;
  reconnectDate?: string;
};

export enum LCMessageType {
  // Sent from Lunchclub
  INTRO = 100,
  REINTRODUCE = 101,
  ENDORSEMENT = 102,
  ENDORSEMENT_ACCEPTED = 103, // Deprecated
  NUX_MATCH_OPT_IN = 104,
  PRESCHEDULED_RECONNECT = 105,
  ENDORSEMENT_ACCEPTED_BY_THEM = 106,
  ENDORSEMENT_ACCEPTED_BY_ME = 107,
  SUGGESTED_TIME = 108,
  FEEDBACK_REQUEST = 109,
  LIGHTER = 110,
  MORPHEUS = 200,
  COACHING_PLAN = 300,
}

export enum MorpheusMessageType {
  INITIAL_GREET = 1,
  PROMPT_VIDEO_CALL = 2,
  PROMPT_GHOST_REASON = 3,
  PROMPT_GHOST_REASON_ACK = 4,
  ANSWER_FEED_QUESTIONS = 5,
  FOUND_FAST_MATCH = 6,
  ASK_BUNCHED_FEEDBACK = 7,
  FEEDBACK_RESPONSE = 8,
  ICEBREAKER_RESPONSE = 9,
}
export interface BackendMessage {
  author: 'Lunchclub';
  body: string;
  dateUpdated: string;
  attributes: Record<string, any>;
  sid: string;
}

export interface LocalMessage {
  message_type?: string;
  author: string;
  body: string;
  dateUpdated: string;
  attributes: MessageAttributes;
  sid: string;
  conversationAttributes?: ConversationAttributes;
}

export type SuperMessage = BackendMessage | LocalMessage | Message;

export default interface Channel {
  profileId: string;
  avatar: string;
  name: string;
  firstName: string;
  metDate: string;
  created: string;
  matchCode: string | null;
  userEventStatus: number | null;
  matchUserEventStatus: number | null;
  uniqueName?: string;
  conversation?: Conversation;
  messages: Message[];
  messagePaginator?: Paginator<Message>;
  pageIndex: number;
  messageHandler?: (message: Message) => void;
  lcMessages: BackendMessage[];
  localMessages: LocalMessage[];
  isBlocked: boolean;
  canReschedule: boolean;
  canReconnect: boolean;
  isInPersonMatch: boolean;
  isSlantMatch: boolean;
  isMorpheusMatch?: boolean;
  isMatchPreviouslyRescheduled: boolean;
  reconnectHeaderSource: number | null;
  networkType: NETWORK_TYPES;
  prescheduledReconnectDate?: string;
}
