import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';

import { FlexColumn, Heading1, Text, margins } from 'css/css';

import { Modal } from 'js/components/shared/NewModal';
import { Button } from 'js/components/shared/Button';
import { getIsIOSApp, getIsMobile } from 'js/util/util';

import screenShareIcon from 'img/call/screen-share-big.svg';

import { useBrowser } from '../utils/useBrowser';

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ScreenSharePermissionModal: React.FC<Props> = ({ showModal, setShowModal }) => {
  const { name: browserName } = useBrowser();
  const handleModalClose = () => {
    setShowModal(false);
  };

  if (getIsMobile() || getIsIOSApp()) {
    return null;
  }

  return (
    <Modal hideScrollbar showModal={showModal}>
      <FlexColumn alignItems="center">
        <Heading1 style={{ margin: `${margins.size4} 0`, textAlign: 'center' }}>
          We are unable to share your screen
        </Heading1>
        <ScreenShareIcon />
        <Text style={{ marginBottom: margins.size4 }}>
          {`${browserName} might not have screen-recording permissions on your device. Please grant access to be able to share your screen.`}
        </Text>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: `0 ${margins.size2}` }} />
          <Button onClick={handleModalClose}>Continue the call</Button>
        </div>
      </FlexColumn>
    </Modal>
  );
};

const ScreenShareIcon = styled(SVG).attrs({
  src: screenShareIcon,
  'alt-text': 'Screen Share icon',
})`
  filter: invert();
`;
