import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Interests } from 'types/interests';
import { Objective } from 'types/objectives';
import { UserMe } from 'types/user';

import { useMountEffect } from 'js/util/custom-hooks';
import {
  getParameterByName,
  COMMUNITIES,
  getTokenURLParameter,
  getElliotToken,
  lunchclubTypes,
  COMMUNITY_CODE_TO_ID,
} from 'js/util/util';
import { useResource } from 'js/util/use-resource';
import { getUserInfo, postUserInfo } from 'js/util/api';

export interface RegistrationFields {
  firstName: string;
  lastName: string;
  headline: string;
  title: string;
  likeToLearn: string;
  askMeAbout: string;
  topOfMind: string;
  justLearned: string;
  sideHustle: string;
  image: string;
  twitterLink: string;
  linkedinLink: string;
  selectedObjectives: Objective[];
  selectedInterests: Interests[];
  organizations: number[];
  requireLinkedin: boolean;
}

export function useRegistrationFields() {
  const [initialUserInfo] = useResource<UserMe>('discover/user/me');

  const [values, setValues] = useState<RegistrationFields>({
    firstName: '',
    lastName: '',
    title: '',
    headline: '',
    image: '',
    likeToLearn: '',
    sideHustle: '',
    askMeAbout: '',
    topOfMind: '',
    justLearned: '',
    twitterLink: '',
    linkedinLink: '',
    selectedInterests: [],
    selectedObjectives: [],
    organizations: [],
    requireLinkedin: false,
  });

  const history = useHistory();
  const [prefillInviteCode] = useState(getParameterByName('invite_code'));
  const [skipVerification] = useState(!!getParameterByName('skip_verification'));

  const handleStepParam = () => {
    const stepParam = getParameterByName('step');
    if (stepParam === 'objectives') {
      history.push(`/registration/objectives${history.location.search}`);
    }
  };

  const verifyAndLoadData = async () => {
    const res = await getUserInfo();
    // Redirect to homepage, probably token doesn't exist.
    if (res.status !== 200) {
      history.push('/');
      return;
    }

    // Redirect to discover if user is verified already.
    const userType = res.getJson.user_type;
    if (userType === lunchclubTypes.verified) {
      history.push('/home');
    }
  };

  useEffect(() => {
    if (!initialUserInfo) {
      return;
    }

    setValues({
      ...values,
      requireLinkedin: true,
      firstName: initialUserInfo.first_name || '',
      lastName: initialUserInfo.last_name || '',
      headline: initialUserInfo.headline || '',
      image: initialUserInfo.image || '',
    });
  }, [initialUserInfo]);

  useMountEffect(() => {
    let orgId = getParameterByName('orgId') || '';
    if (COMMUNITY_CODE_TO_ID[orgId]) {
      orgId = COMMUNITY_CODE_TO_ID[orgId];
    }
    if (parseInt(orgId, 10)) {
      const integerId = parseInt(orgId, 10);
      setValues({ ...values, organizations: [integerId] });
      if (COMMUNITIES[integerId]) {
        postUserInfo({ organizations: [integerId] });
      }
    }

    if (getTokenURLParameter() || getElliotToken()) {
      handleStepParam();
    } else {
      verifyAndLoadData();
    }
  });

  return {
    prefillInviteCode,
    skipVerification,
    values,
    valuesReady: !!initialUserInfo,
    setValues,
  };
}
