import React, { useState } from 'react';

import { FlexRow, HideMobile, margins } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { postDeleteGoogleAccount, postReactivateAutopilot } from 'js/util/api';
import { useDebounce, useDidUpdateEffect, useMountEffect } from 'js/util/custom-hooks';
import { getParameterByName } from 'js/util/util';
import { useNotifContext } from 'js/util/notif-context';

import { DeletionModal } from '../components/DeletionModal';
import { ConnectedAccountEditor } from '../components/ConnectedAccountEditor';
import { HomeCityEditor } from '../components/HomeCityEditor';
import { PrimaryEmailEditor } from '../components/PrimaryEmailEditor';
import { ToggleSection } from '../components/shared-components/ToggleSection';
import { SettingsSectionsTitles } from '../constants';

function useToggleSetting(initial: boolean, settingKey: string) {
  const { updateUser } = useUserContextProvider();
  const [isUpdatingSetting, setIsUpdatingSetting] = useState(false);
  const [value, setValue] = useState(initial);
  const debouncedValue = useDebounce(value, 500);

  useDidUpdateEffect(() => {
    if (isUpdatingSetting) return;
    setIsUpdatingSetting(true);
    updateUser({
      [settingKey]: debouncedValue,
    }).then(() => setIsUpdatingSetting(false));
  }, [debouncedValue]);

  return [value, setValue] as const;
}

const useInvoluntaryInviteDisabled = (initial: boolean) =>
  useToggleSetting(initial, 'isInvoluntaryInvitesDisabled');
const useReconnectPreschedulerDisabled = (initial: boolean) =>
  useToggleSetting(initial, 'isReconnectPreschedulerDisabled');

export const Account: React.FC = () => {
  const user = useUser();
  const { showNotif } = useNotifContext();
  const { fetchUser, updateUserAvailability } = useUserContextProvider();

  const [deletingUser, setDeletingUser] = useState(false);
  const [
    isInvoluntaryInvitesDisabled,
    setIsInvoluntaryInviteDisabled,
  ] = useInvoluntaryInviteDisabled(user.is_involuntary_invites_disabled);
  const [
    isReconnectPreschedulerDisabled,
    setIsReconnectPreschedulerDisabled,
  ] = useReconnectPreschedulerDisabled(user.is_reconnect_prescheduler_disabled);

  const allGoogleAccounts = user.primary_google_email
    ? [user.primary_google_email, ...user.secondary_google_emails]
    : user.secondary_google_emails;

  const onAddedAccount = () => fetchUser();

  const onRemoveGoogleAccount = async (email: string) => {
    const res = await postDeleteGoogleAccount({ email });
    // 400 status indicates token was previously revoked.
    if (res.status === 200 || res.status === 400) fetchUser();
  };

  useMountEffect(() => {
    const autopilotCode = getParameterByName('enable_autopilot');
    if (autopilotCode) {
      const reactivateAutopilot = async () => {
        const res = await postReactivateAutopilot(autopilotCode);
        if (res.ok) {
          updateUserAvailability();
          showNotif({
            message: 'Successfully reactivated autopilot!',
            level: 'success',
          });
        } else {
          showNotif({
            message: 'Failed to reactivate autopilot.',
            level: 'error',
          });
        }
      };
      reactivateAutopilot();
    }
  });

  return (
    <>
      <HomeCityEditor />

      <PrimaryEmailEditor />

      <ConnectedAccountEditor
        accounts={allGoogleAccounts}
        onRemoveAccount={onRemoveGoogleAccount}
        onAddedAccount={onAddedAccount}
      />
      <ToggleSection
        hasBorder
        headingTitle={SettingsSectionsTitles.OUTREACH}
        onClick={() => setIsInvoluntaryInviteDisabled(prev => !prev)}
        isOn={!isInvoluntaryInvitesDisabled}
        containerStyle={{ marginTop: margins.size4 }}
      />
      <ToggleSection
        hasBorder
        headingTitle={SettingsSectionsTitles.RECONNECTS}
        onClick={() => setIsReconnectPreschedulerDisabled(prev => !prev)}
        isOn={!isReconnectPreschedulerDisabled}
        containerStyle={{ marginTop: margins.size4 }}
      />
      <HideMobile>
        <FlexRow adapting alignItems="left" style={{ marginTop: margins.size4 }}>
          <Button onClick={() => setDeletingUser(true)} tertiary style={{ margin: 0 }}>
            Delete my account
          </Button>
        </FlexRow>
        <DeletionModal showModal={deletingUser} handleClose={() => setDeletingUser(false)} />
      </HideMobile>
    </>
  );
};
