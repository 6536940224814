import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { colors, margins, Heading3, Text, FlexColumn } from 'css/css';

import { getUserInfo } from 'js/util/api';
import {
  cleanNames,
  getElliotToken,
  getTokenURLParameter,
  lunchclubTypes,
  validateHeadlineLength,
} from 'js/util/util';
import { useNotifContext } from 'js/util/notif-context';
import { DefaultSampleHeadlines } from 'js/components/shared/Headline';
import { useUserContextProvider } from 'js/providers/UserProvider';
import { Avatar } from 'js/components/shared/Avatar';

import orangeAvatar from 'img/registration/profile/orange-avatar.svg';
import redAvatar from 'img/registration/profile/red-avatar.svg';
import greenAvatar from 'img/registration/profile/green-avatar.svg';
import { emptyProfileSvg } from 'img/registration-v2';

import { RegistrationStepProps } from '../MobileRegistrationContent';

import { OnboardingFlowPage } from './OnboardingFlowPage';
import {
  StepMobileContainer,
  Seperator,
  WhiteInputGroupBox,
  InputPrompt,
  InputRow,
  OnboardingSteps,
  NUX_HEADER_STEPS,
} from './shared';
import { HeadlineMobileInput, splitHeadlineAndPrefix } from './HeadlineMobileInput';
import { ProgressBar } from './ProgressBar';

export const StepHeadlineMobile: React.FC<RegistrationStepProps & {
  skipVerification: boolean;
}> = ({ values, setValues, submitUserInfo, skipVerification }) => {
  const history = useHistory();
  const { showNotif } = useNotifContext();
  const { fetchUser } = useUserContextProvider();

  const initialValue = `${cleanNames(values.firstName)} is `;
  const displayedValue = `${initialValue}${values.headline}`;
  const isEmpty = displayedValue.trim() === initialValue.trim();
  const isHeadlineValid =
    validateHeadlineLength(values.headline.length) && !values.headline.includes('http');

  // removes hardcoded 'FirstName is'
  const headlineChanged = (event: any) => {
    const incomingHeadline = event.target.value;
    const writtenPart = incomingHeadline.substring(initialValue.length);
    setValues({ ...values, headline: writtenPart });
  };

  const onNext = async () => {
    await submitUserInfo(
      {
        done: true,
        skipVerification,
        headline: displayedValue,
        organizations: values.organizations,
        justLearned: '',
        askMeAbout: '',
        sideHustle: '',
        topOfMind: '',
        likeToLearn: '',
        isFromApp: !!window.bridge,
      },
      async () => {
        const res = await getUserInfo();
        if (res.status !== 200) {
          throw new Error('getUserMe request failed');
        }
        const userType = res.getJson.user_type;

        if (userType === lunchclubTypes.unverified && !skipVerification) {
          history.push('/registration/verification');
        } else {
          fetchUser().then(() =>
            history.push(`/verify?token=${getTokenURLParameter() || getElliotToken()}`),
          );
        }
      },
    );
    window.qp('track', 'CompleteRegistration');
  };

  return (
    <OnboardingFlowPage
      step={OnboardingSteps.Profile}
      onNext={onNext}
      onPrev={() => history.push(`/registration/linkedin${history.location.search}`)}
      title={NUX_HEADER_STEPS.Profile.title}
      subtitle={NUX_HEADER_STEPS.Profile.subtitle}
      nextDisabled={isEmpty}
      nextInvalid={!isHeadlineValid}
      invalidOnNext={() =>
        showNotif({ message: 'Your headline has an invalid length', level: 'error' })
      }
    >
      <StepMobileContainer style={{ alignItems: 'center', maxHeight: '55vh' }}>
        <ProgressBar total={4} numCompleted={3} />
        <Avatar
          src={values.image || emptyProfileSvg}
          alt={values.image ? 'profile' : 'placeholder'}
          size5
        />
        <Text style={{ marginTop: margins.size2 }}>
          {values.firstName} {values.lastName}
        </Text>
        <Seperator />
        <Heading3 style={{ marginBottom: margins.size2 }}>How should we introduce you?</Heading3>
        <WhiteInputGroupBox
          style={{
            flex: 1,
            display: 'flex',
            maxHeight: values.headline.length ? 'unset' : '50px',
          }}
        >
          <InputRow
            htmlFor="headline"
            style={{
              flexDirection: 'column',
              alignItems: 'stretch',
              flex: 1,
            }}
          >
            {values.headline.length === 0 && <InputPrompt>Write your introduction</InputPrompt>}
            <HeadlineMobileInput
              value={displayedValue}
              resetValue={headline => setValues({ ...values, headline })}
              onChange={headlineChanged}
              transparent
              buttonOnClick={onNext}
              isButtonInvalid={!isHeadlineValid}
              isEmpty={isEmpty}
              initialValue={initialValue}
            />
          </InputRow>
        </WhiteInputGroupBox>
      </StepMobileContainer>
      <Text
        style={{
          alignSelf: 'center',
          padding: margins.size1,
          margin: 'auto',
        }}
      >
        Example Introductions
      </Text>
      <SampleCarousel>
        {DefaultSampleHeadlines.map((sampleHeadline, idx) => {
          const [prefix, headline] = splitHeadlineAndPrefix(sampleHeadline);

          return (
            <FlexColumn>
              <StyledAvatar src={[greenAvatar, redAvatar, orangeAvatar][idx]} alt="profile" size2 />
              <SampleCarouselItem key={sampleHeadline}>
                {prefix} {headline}
              </SampleCarouselItem>
            </FlexColumn>
          );
        })}
        <div style={{ minWidth: '20vw' }} />
      </SampleCarousel>
    </OnboardingFlowPage>
  );
};

const SampleCarousel = styled.div`
  scroll-snap-type: x mandatory;
  scroll-padding: ${margins.size3};
  overflow-x: scroll;
  display: flex;
  justify-content: stretch;
  align-self: stretch;
  scrollbar-color: transparent;
  scrollbar-width: none;
  min-height: 184px;
  margin-bottom: calc(env(safe-area-inset-bottom) + ${margins.size4} + 50px);
  ::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar:horizontal {
    height: 0;
  }
`;
const SampleCarouselItem = styled.div`
  margin-top: -28px;
  min-width: 80vw;
  background: ${colors.greyMain};
  border-radius: 10px;
  padding: ${margins.size3};
  padding-top: calc(${margins.size3} + 10px);
  margin-left: ${margins.size3};
  scroll-snap-align: start;
  overflow-y: scroll;
  height: 100%;
`;

const StyledAvatar = styled(Avatar)`
  border: 4px solid ${colors.greyMain};
  position: relative;
  margin-left: ${margins.size3};
`;
