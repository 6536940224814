import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { Heading3, ShowMobile, HideMobile, Clickable, margins, colors, media } from 'css/css';

import { useOutsideAlerter } from 'js/util/custom-hooks';

import CloseIcon from 'img/close.svg';
import BackArrow from 'img/back-long-arrow.svg';
import Hamburger from 'img/hamburger-menu.svg';

import { DropdownMenu } from './DropdownMenu';

export const OldModal = ({
  isOpen,
  onClose,
  width,
  canClose = true,
  children,
  modalTitle,
  options,
}: any) => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  useEffect(() => setHamburgerOpen(false), [isOpen]);

  const modalContentRef = useRef<HTMLInputElement>(null);
  useOutsideAlerter(modalContentRef, () => {
    if (canClose) onClose();
  });

  if (isOpen) {
    return (
      <BackDrop>
        <ContentContainer width={width} ref={modalContentRef}>
          {canClose && (
            <>
              <ShowMobile>
                {modalTitle && (
                  <>
                    <HeaderRow>
                      <Clickable onClick={() => onClose()}>
                        <img src={BackArrow} alt="Head back" />
                      </Clickable>

                      <div
                        style={{
                          textAlign: 'center',
                          width: '100%',
                          paddingTop: margins.size1,
                        }}
                      >
                        <Heading3>{modalTitle}</Heading3>
                      </div>
                      {options ? (
                        <>
                          <Clickable
                            onClick={() => {
                              const newState = !hamburgerOpen;
                              setHamburgerOpen(newState);
                            }}
                          >
                            <img src={Hamburger} alt="More options" />
                          </Clickable>
                          {hamburgerOpen && (
                            <DropdownMenu top="64px" position="absolute" menuItems={options} />
                          )}
                        </>
                      ) : (
                        <div style={{ width: 24 }} />
                      )}
                    </HeaderRow>

                    <hr style={{ margin: 0, padding: 0 }} />
                  </>
                )}
              </ShowMobile>

              <HideMobile>
                <RightBox>
                  <CloseButton src={CloseIcon} onClick={onClose} alt="Close button" />
                </RightBox>
              </HideMobile>
            </>
          )}
          {children}
        </ContentContainer>
      </BackDrop>
    );
  }
  return null;
};

const HeaderRow = styled.div`
  padding: ${margins.size3};
  display: flex;
  align-items: center;
`;

const BackDrop = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 4;
`;

type ContentContainerTypes = {
  width: string;
};
const ContentContainer = styled.div<ContentContainerTypes>`
  position: fixed;
  min-width: 500px;
  max-width: ${p => (p.width ? p.width : '70%')};
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.whiteMain};
  z-index: 2;
  max-height: 90%;
  border-radius: 5px;
  overflow-y: auto;
  text-align: center;

  ${media.mobile} {
    transform: none;
    border-radius: 0px;
    min-width: 100%;
    top: calc(env(safe-area-inset-top) + 0%);
    left: 0%;
    width: 100%;
    padding-bottom: 67px;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
  }
`;

const RightBox = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const CloseButton = styled.img`
  color: ${colors.blackLight};
  cursor: pointer;
`;
