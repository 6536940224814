import React from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import {
  SubText,
  margins,
  FlexRow,
  FlexColumn,
  Clickable,
  Heading3,
  colors,
  Text,
  BUTTON_HEIGHT,
  fontSizes,
} from 'css/css';

import { Invitee, MobileContactInvitee } from 'types/invites';

import { Avatar } from 'js/components/shared/Avatar';

import cross from 'img/close.svg';
import crossBlackSvg from 'img/close-black.svg';
import inviteButton from 'img/invite/invite-button.svg';
import inviteButtonMobile from 'img/invite/invite-button-mobile.svg';

const textOverflowEllipsis = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'left',
  minWidth: 0,
  width: '100%',
} as React.CSSProperties;

interface Props {
  user: Invitee | MobileContactInvitee;
  sendInvite: () => void;
  skipInvite?: () => void;
}

export const InviteCardRow: React.FC<Props> = ({ user, sendInvite, skipInvite }) => (
  <FlexRow style={{ width: '100%', paddingTop: margins.size3 }}>
    {skipInvite && (
      <Clickable onClick={skipInvite}>
        <img src={cross} alt="close" style={{ color: colors.blackLight }} />
      </Clickable>
    )}
    <AvatarContainer>
      <Avatar
        avatarId={user.mode === 'contact' ? user.phone_number : user.email}
        size2
        src={user.mode === 'email' ? user.image : null}
        style={{ marginLeft: margins.size2 }}
      />
    </AvatarContainer>
    <FlexColumn alignItems="flex-start" style={{ marginLeft: margins.size2 }}>
      <Heading3 style={textOverflowEllipsis}>
        {user.mode === 'contact' ? user.name : `${user.first_name} ${user.last_name}`}
      </Heading3>
      {user.num_lc_contacts > 1 && (
        <SubText
          style={textOverflowEllipsis}
        >{`${user.num_lc_contacts} contacts on Lunchclub`}</SubText>
      )}
    </FlexColumn>

    <InviteButtonContainer onClick={sendInvite}>
      <InviteButtonSvg src={inviteButton} alt="Invite friend" />
    </InviteButtonContainer>
  </FlexRow>
);

// Mobile design is just /slightly/ different font sizes, colors, assets

export const InviteCardRowMobile: React.FC<Props> = ({ user, sendInvite, skipInvite }) => (
  <FlexRow style={{ width: '100%', paddingTop: margins.size3 }}>
    {skipInvite && (
      <Clickable onClick={skipInvite}>
        <img
          src={crossBlackSvg}
          alt="close"
          style={{ padding: margins.size2, marginLeft: `-${margins.size2}` }}
        />
      </Clickable>
    )}
    <AvatarContainer>
      <Avatar
        avatarId={user.mode === 'contact' ? user.phone_number : user.email}
        size3
        src={user.mode === 'email' ? user.image : null}
        style={{ marginLeft: margins.size2 }}
      />
    </AvatarContainer>
    <FlexColumn alignItems="flex-start" style={{ marginLeft: margins.size3, gap: 4, minWidth: 0 }}>
      <Heading3 style={textOverflowEllipsis}>
        {user.mode === 'contact' ? user.name : `${user.first_name} ${user.last_name}`}
      </Heading3>{' '}
      {user.num_lc_contacts > 1 && (
        <Text
          color={colors.blackLight}
          style={{ ...textOverflowEllipsis, fontSize: fontSizes.size1 }}
        >{`${user.num_lc_contacts} contacts on Lunchclub`}</Text>
      )}
    </FlexColumn>

    <InviteButtonContainer onClick={sendInvite}>
      <img src={inviteButtonMobile} alt="Invite friend" />
    </InviteButtonContainer>
  </FlexRow>
);

const InviteButtonContainer = styled(Clickable)`
  margin: 0;
  margin-left: auto;
  height: ${BUTTON_HEIGHT};
  padding: ${margins.size1} 0px;
`;

const InviteButtonSvg = styled(SVG)`
  > path {
    fill: ${colors.whiteMain};
  }
  > line {
    stroke: ${colors.whiteMain};
  }
  > rect {
    fill: ${colors.primaryMain};
  }
  &:hover {
    cursor: pointer;
  }
  &:hover > rect {
    fill: ${colors.primaryMain};
  }
`;

const AvatarContainer = styled.div`
  @media screen and (max-width: 320px) {
    display: none;
  }
`;
