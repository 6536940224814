import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import {
  FlexRow,
  FlexColumn,
  margins,
  colors,
  globalTransitionSettings,
  media,
  Heading2,
} from 'css/css';

import { ProductUser, UserMe } from 'types/user';

import { useUser } from 'js/providers/UserProvider';
import { useOutsideAlerter } from 'js/util/custom-hooks';
import { Avatar } from 'js/components/shared/Avatar';
import {
  ProfileBackground,
  ProfileAvatarImage,
  ProfileMobile,
} from 'js/components/profile/ProfileMobile';
import { useCallV2Context } from 'js/components/callv2/contexts/CallContext';
import { useUIStateContext, SidebarView } from 'js/components/callv2/contexts/UIStateContext';

import chatIcon from 'img/call/chat.svg';
import openChatIcon from 'img/call/open-chat.svg';
import closeIcon from 'img/call/exit.svg';

import { ChatCard } from './ChatCard';

interface Props {
  className?: string;
  localAvatar?: string;
  partnerAvatar?: string;
}
export const Sidebar: React.FC<Props> = ({ className, localAvatar, partnerAvatar }) => {
  const [selectedUser, setSelectedUser] = useState<UserMe | ProductUser | undefined>(undefined);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const sidebarRef = useRef(null);
  const { match } = useCallV2Context();
  const { sidebarView, setSidebarView } = useUIStateContext();
  const user = useUser();

  useEffect(() => {
    setSelectedUser(match);
  }, [match]);

  const ProfileSwitcher = () => (
    <FlexRow style={{ gap: margins.size2 }}>
      <AvatarControl
        src={partnerAvatar}
        alt="Partner's profile"
        onClick={() => setSelectedUser(match)}
        isSelected={selectedUser === match}
        avatarId={user.profile_id}
      />
      <AvatarControl
        src={localAvatar}
        alt="My profile"
        onClick={() => setSelectedUser(user)}
        isSelected={selectedUser === user}
      />
    </FlexRow>
  );

  useOutsideAlerter(sidebarRef, () => setSidebarView(null));

  const toggleTab = (tab: SidebarView) => () => setSidebarView(sidebarView === tab ? null : tab);

  return (
    <div ref={sidebarRef}>
      <SidebarContainer isOpen={!!sidebarView}>
        <SidebarHeader>
          {sidebarView === 'profile' ? <ProfileSwitcher /> : <Heading2>Messages</Heading2>}
          <div style={{ flex: 1 }} />
          <Close onClick={() => setSidebarView(null)} />
        </SidebarHeader>
        <SidebarContent>
          <SidebarContentContainer isVisible={sidebarView === 'profile'}>
            {selectedUser && (
              <>
                <ProfileBackground style={{ minHeight: 100 }}>
                  <ProfileAvatarImage
                    alt="profile"
                    src={selectedUser.image}
                    avatarId={user.profile_id}
                  />
                </ProfileBackground>
                <ProfileMobile profile={selectedUser} endorsements={selectedUser.endorsements} />
              </>
            )}
          </SidebarContentContainer>
          <SidebarContentContainer isVisible={sidebarView === 'chat'}>
            <ChatCard
              isActive={sidebarView === 'chat'}
              matchProfileId={match?.profile_id}
              setHasUnreadMessages={setHasUnreadMessages}
            />
          </SidebarContentContainer>
        </SidebarContent>
      </SidebarContainer>
      <SidebarControls isOpen={!!sidebarView} className={className}>
        <Control onClick={toggleTab('chat')}>
          {hasUnreadMessages && <NotificationBadge />}
          {!!sidebarView && sidebarView === 'chat' ? <OpenChatIcon /> : <ChatIcon />}
        </Control>
        <AvatarControl
          src={partnerAvatar}
          alt="Toggle profile"
          onClick={toggleTab('profile')}
          isSelected
          avatarId={user.profile_id}
        />
      </SidebarControls>
    </div>
  );
};

const SidebarContainer = styled(FlexColumn)<{ isOpen: boolean }>`
  position: absolute;
  top: ${margins.size3};
  right: ${margins.size3};
  bottom: 80px;
  width: 420px;
  border-radius: 10px;
  background-color: ${colors.whiteMain};
  transition: ${globalTransitionSettings};
  transform: translateX(${p => (p.isOpen ? 0 : '436px')});

  ${media.mobile} {
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    transform: translateX(${p => (p.isOpen ? 0 : '100%')});
    padding-top: env(safe-area-inset-top);
  }
`;

const SidebarHeader = styled(FlexRow)`
  align-items: center;
  padding: ${margins.size2} ${margins.size3};
  width: 100%;
  height: 48px;
`;

const Close = styled(SVG).attrs(() => ({ src: closeIcon }))`
  padding: 0;
  color: ${colors.blackLight};
  z-index: 2;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

const SidebarContent = styled.div`
  flex: 1;
  width: 100%;
  margin-bottom: ${margins.size3};
  overflow-y: auto;
`;

const SidebarContentContainer = styled.div<{ isVisible: boolean }>`
  display: ${p => (p.isVisible ? 'inherit' : 'none')};
  height: 100%;
`;

const SidebarControls = styled(FlexRow)<{ isOpen: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: ${margins.size2};
  gap: ${margins.size3};
  border-radius: 10px 10px 0 0;
  background-color: ${colors.blackMain};

  ${media.mobile} {
    display: ${p => p.isOpen && 'none'};
    background-color: transparent;
  }
`;

const Control = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: ${globalTransitionSettings};
  margin: ${margins.size2};
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

const AvatarControl = styled(Avatar)<{ isSelected: boolean }>`
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
  filter: ${p => !p.isSelected && 'grayscale(1)'};
  transition: ${globalTransitionSettings};

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

const NotificationBadge = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.8em;
  height: 0.8em;
  top: -2px;
  right: -2px;
  border-radius: 1em;
  background-color: ${colors.tertiary2Main};
`;

const ChatIcon = styled(SVG).attrs(() => ({ src: chatIcon }))`
  transition: ${globalTransitionSettings};
  & > * {
    stroke: ${colors.whiteMain};
  }
`;

const OpenChatIcon = styled(SVG).attrs(() => ({ src: openChatIcon }))`
  transition: ${globalTransitionSettings};
  & > * {
    stroke: ${colors.blackMain};
  }
`;
