import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';

import {
  borders,
  globalTransitionSettings,
  colors,
  margins,
  Clickable,
  Text,
  media,
} from 'css/css';

import indicator from 'img/weekly/indicator.svg';

interface Props {
  selectedSuggestedTimeslots: string[];
  timeslot: string;
  currentTimeWithBuffer: string;
  setSelectedSuggestedTimeslots: React.Dispatch<React.SetStateAction<string[]>>;
  busyTimeslots: string[];
  hasReachedLimit: boolean;
  timeslotsSuggestedByMatch: string[];
}

export const TimeslotButton: React.FC<Props> = ({
  selectedSuggestedTimeslots,
  timeslot,
  currentTimeWithBuffer,
  setSelectedSuggestedTimeslots,
  busyTimeslots = [],
  hasReachedLimit,
  timeslotsSuggestedByMatch,
}) => {
  const hasTimePassed = moment(timeslot).isBefore(moment(currentTimeWithBuffer));

  const clickTimeslot = (passedTimeslot: string) => {
    if (!hasReachedLimit && !hasTimePassed) {
      setSelectedSuggestedTimeslots(prev =>
        prev
          ? prev.includes(passedTimeslot)
            ? prev.filter(t => t !== passedTimeslot)
            : [...prev, passedTimeslot]
          : [passedTimeslot],
      );
    } else {
      setSelectedSuggestedTimeslots(prev =>
        prev.includes(passedTimeslot) ? prev.filter(t => t !== passedTimeslot) : [...prev],
      );
    }
  };

  const getAnnotedTimeLabel = (passedTimeslot: string) => {
    if (busyTimeslots.length) {
      if (busyTimeslots.includes(passedTimeslot)) {
        return moment(passedTimeslot).format('h a');
      }
      return (
        <>
          <img
            src={indicator}
            style={{ marginLeft: -11, paddingRight: 6, marginBottom: '2px' }}
            alt=""
          />
          {moment(passedTimeslot).format('h a')}
        </>
      );
    }
    return moment(passedTimeslot).format('h a');
  };

  return (
    <StyledTimeslotButton
      $isSelected={selectedSuggestedTimeslots?.includes(timeslot)}
      $isInvalid={hasReachedLimit || hasTimePassed || timeslotsSuggestedByMatch.includes(timeslot)}
      onClick={() => clickTimeslot(timeslot)}
      key={timeslot}
    >
      <TimeslotButtonText
        $isSelected={selectedSuggestedTimeslots?.includes(timeslot)}
        $isInvalid={
          hasReachedLimit || hasTimePassed || timeslotsSuggestedByMatch.includes(timeslot)
        }
      >
        {getAnnotedTimeLabel(timeslot)}{' '}
      </TimeslotButtonText>
    </StyledTimeslotButton>
  );
};

export const TimeslotButtonText = styled(Text)<{
  $isSelected: boolean;
  $isInvalid: boolean;
}>`
  color: ${p =>
    p.$isSelected ? colors.whiteMain : p.$isInvalid ? colors.blackLight : colors.blackMain};
  transition: ${globalTransitionSettings};
  &:hover {
    color: ${p =>
      p.$isSelected ? colors.whiteMain : p.$isInvalid ? colors.blackLight : colors.blackMain};
  }
`;

const StyledTimeslotButton = styled(Clickable)<{
  $isSelected: boolean;
  $isInvalid: boolean;
}>`
  padding: ${margins.size2};
  border-radius: 12px;
  background-color: ${p => (p.$isSelected ? colors.blackMain : colors.whiteMain)};
  transition: ${globalTransitionSettings};
  height: 50px;
  min-width: 100px;
  border: ${p => (p.$isSelected ? 'none' : borders.variation)};
  ${p => p.$isInvalid && 'cursor: default;'}

  &:hover {
    background-color: ${p =>
      p.$isSelected ? colors.blackMain : p.$isInvalid ? colors.whiteMain : `${colors.greyLight}`};
    ${TimeslotButtonText} {
      color: ${p =>
        p.$isSelected ? colors.whiteMain : p.$isInvalid ? colors.blackLight : colors.blackMain};
    }

    ${media.mobile} {
      background-color: ${p => (p.$isSelected ? colors.blackMain : 'transparent')};
      ${TimeslotButtonText} {
        color: ${p =>
          p.$isSelected ? colors.whiteMain : p.$isInvalid ? colors.blackLight : colors.blackMain};
      }
    }
  }

  &:focus {
    outline: none;
  }

  ${media.mobile} {
    min-width: 73px;
  }
`;
