import React from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import {
  FlexRow,
  margins,
  colors,
  FlexColumn,
  SubText,
  media,
  Text,
  UppercaseHeading3,
} from 'css/css';

import { SuperMessage } from 'types/channel';

import { Button } from 'js/components/shared/Button';
import { Avatar } from 'js/components/shared/Avatar';

import lockIcon from 'img/shared/lock.svg';

import { MessageBubble } from './BaseMessage';

interface Props {
  message?: SuperMessage;
  headerText?: string;
  subHeaderText?: string;
  onButtonClick?: (e: React.MouseEvent) => void;
  buttonText?: string;
  wasButtonClicked?: boolean;
  icon?: string;
  avatar?: { id: string; src: string; iconTop: number; iconLeft: number };
  isLoading?: boolean;
  isPrivate?: boolean;
  isMyMessage?: boolean;
}

export const LunchclubBotMessage: React.FC<Props> = ({
  message,
  onButtonClick,
  buttonText,
  wasButtonClicked,
  icon,
  avatar,
  headerText,
  subHeaderText,
  isPrivate = false,
  isLoading,
  isMyMessage,
}) => {
  const hasHeader = !!icon && !!headerText;
  const hasButton = !!buttonText && onButtonClick;

  return (
    <FlexColumn style={{ width: '100%' }} justifyContent="space-between">
      <Container $isMyMessage={!!isMyMessage}>
        <FlexColumn>
          <MessageContainer $withSmallerPadding={!hasButton && !hasHeader}>
            {hasHeader && (
              <MessageHeader
                headerText={headerText}
                subHeaderText={subHeaderText}
                icon={icon}
                avatar={avatar}
              />
            )}
            {!!message && message.body && (
              <MessageBubble $isMyMessage={false} style={{ padding: 0, margin: 0 }}>
                {message.body}
              </MessageBubble>
            )}
            {hasButton && (
              <StyledButton
                large
                onClick={onButtonClick}
                invalid={wasButtonClicked}
                loading={isLoading}
                $wasButtonClicked={!!wasButtonClicked}
              >
                {buttonText}
              </StyledButton>
            )}
          </MessageContainer>
          {isPrivate && (
            <FlexRow
              style={{ alignSelf: 'flex-start', marginTop: margins.size1 }}
              justifyContent="center"
            >
              <LockGray alt="lock" />
              <SubText>Only you can see this</SubText>
            </FlexRow>
          )}
        </FlexColumn>
      </Container>
    </FlexColumn>
  );
};

const Container = styled(FlexRow)<{ $isMyMessage: boolean }>`
  align-self: flex-start;
  margin-top: ${margins.size2};
  align-items: flex-end;
  width: 100%;
  ${p => p.$isMyMessage && 'justify-content: flex-end'}
`;

const LockGray = styled(SVG).attrs(() => ({ src: lockIcon }))`
  color: ${colors.blackMid};
  margin-right: ${margins.size1};
`;

const MessageContainer = styled(FlexColumn)<{ $withSmallerPadding: boolean }>`
  background-color: ${colors.greyLight};
  border-radius: 20px;
  padding: ${p => (p.$withSmallerPadding ? `${margins.size2} ${margins.size3}` : margins.size3)};
  align-items: flex-start;
  margin-top: ${margins.size1};
  gap: ${margins.size2};
  max-width: 342px;
  text-align: left;
`;

interface MessageHeaderProps {
  headerText: string;
  icon: string;
  subHeaderText?: string;
  avatar?: { id: string; src: string; iconTop: number; iconLeft: number };
}

const MessageHeader: React.FC<MessageHeaderProps> = ({
  headerText,
  icon,
  subHeaderText,
  avatar,
}) => (
  <FlexRow style={{ gap: margins.size3 }}>
    <div style={{ position: 'relative' }}>
      {avatar !== undefined && (
        <Avatar src={avatar.src} avatarId={avatar.id} size2 style={{ position: 'relative' }} />
      )}
      <img
        src={icon}
        alt={headerText}
        style={
          avatar !== undefined
            ? { position: 'absolute', top: avatar.iconTop, left: avatar.iconLeft }
            : {}
        }
      />
    </div>
    <FlexColumn alignItems="flex-start">
      <UppercaseHeading3>{headerText}</UppercaseHeading3>
      {!!subHeaderText && <Text>{subHeaderText}</Text>}
    </FlexColumn>
  </FlexRow>
);

const getButtonBackgroundColor = (wasButtonClicked: boolean) =>
  wasButtonClicked ? colors.whiteMain : colors.primaryMain;

const StyledButton = styled(Button)<{ $wasButtonClicked: boolean }>`
  background-color: ${p => getButtonBackgroundColor(p.$wasButtonClicked)};
  color: ${p => (p.$wasButtonClicked ? colors.blackLight : colors.whiteMain)};
  ${media.mobile} {
    &:hover {
      background-color: ${p => getButtonBackgroundColor(p.$wasButtonClicked)};
    }
  }
`;
