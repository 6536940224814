import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

import {
  margins,
  media,
  FlexColumn,
  FlexRow,
  Heading1,
  Heading3,
  Link,
  Text,
  colors,
} from 'css/css';

import { Button } from 'js/components/shared/Button';
import { Checkbox } from 'js/components/shared/Checkbox';
import { Modal } from 'js/components/shared/NewModal';
import { DeletionReason, getDeleteReasons, postAvailability, postDeleteUser } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';
import { setElliotToken } from 'js/util/util';

import warningSvg from 'img/settings/warning.svg';

import { ReasonSelector } from './ReasonSelector';

export const DeletionModal = ({
  showModal,
  handleClose,
}: {
  showModal: boolean;
  handleClose: () => void;
}) => {
  const history = useHistory();

  const [productReasons, setProductReasons] = useState<DeletionReason[]>([]);
  const [meetingReasons, setMeetingReasons] = useState<DeletionReason[]>([]);

  const [selectedReasons, setSelectedReasons] = useState<number[]>([]);
  const [notifyFixed, setNotifyFixed] = useState(false);

  useMountEffect(() => {
    const fetchDeleteReasons = async () => {
      const res = await getDeleteReasons();
      if (res.ok) {
        setProductReasons(res.getJson.product_reasons);
        setMeetingReasons(res.getJson.meeting_reasons);
      }
    };

    fetchDeleteReasons();
  });

  const clickReason = (id: number) => {
    let reasonSet = selectedReasons;
    if (selectedReasons.includes(id)) {
      reasonSet = reasonSet.filter(reasonId => reasonId !== id);
    } else {
      reasonSet = [...selectedReasons, id];
    }
    setSelectedReasons(reasonSet);
  };

  const deleteUserAccount = async () => {
    Promise.all([postAvailability({}), postDeleteUser({ selectedReasons, notifyFixed })]).then(
      () => {
        setElliotToken(null);
        history.push('/');
        window.location.reload();
      },
    );
  };

  return (
    <Modal showModal={showModal} handleClose={handleClose}>
      <WarningContainer>
        <FlexRow alignItems="flex-start">
          <img src={warningSvg} alt="Warning" />
          <FlexColumn style={{ marginLeft: margins.size3 }}>
            <Heading3 style={{ marginBottom: margins.size2 }}>
              If you don&apos;t like how many emails we send, you can manage your email
              notifications in{' '}
              <Link onClick={handleClose} to="/settings?tab=notifications">
                settings.
              </Link>
            </Heading3>
          </FlexColumn>
        </FlexRow>
      </WarningContainer>
      <ContentContainer>
        <Heading1>We’re sad to see you go!</Heading1>
        <Text>Tell us more about how we could have improved your experience.</Text>
        <ReasonSelector
          title="What problems did you have with the product itself?"
          reasons={productReasons}
          selectedReasons={selectedReasons.filter(reasonId =>
            productReasons.map(reason => reason.id).includes(reasonId),
          )}
          selectReason={(id: number) => clickReason(id)}
        />
        {!!meetingReasons.length && (
          <ReasonSelector
            title="What problems did you have with the meetings?"
            reasons={meetingReasons}
            selectedReasons={selectedReasons.filter(reasonId =>
              meetingReasons.map(reason => reason.id).includes(reasonId),
            )}
            selectReason={(id: number) => clickReason(id)}
          />
        )}
        <div style={{ marginTop: margins.size2, width: '100%' }}>
          <Checkbox
            desc="Let me know when these problems are fixed"
            checked={notifyFixed}
            setCheckValue={setNotifyFixed}
            fullWidth
            borderColor={colors.blackMid}
          />
          <FlexRow style={{ marginTop: margins.size3 }} width="100%">
            <Button large onClick={deleteUserAccount} style={{ marginRight: margins.size2 }}>
              Delete
            </Button>
            <Button large onClick={handleClose} secondary>
              Cancel
            </Button>
          </FlexRow>
        </div>
      </ContentContainer>
    </Modal>
  );
};

const WarningContainer = styled.div`
  margin-top: ${margins.size4};
  margin-bottom: ${margins.size4};

  ${media.mobile} {
    padding-bottom: ${margins.size3};
  }
`;

const ContentContainer = styled(FlexColumn)`
  align-items: flex-start;
  padding: 0px 0px 0px 40px;
  ${media.mobile} {
    margin-bottom: ${margins.size4};
    padding: 0px;
  }
`;
