import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { DailyParticipant, DailyEvent } from '@daily-co/daily-js';
import { useHotkeys } from 'react-hotkeys-hook';

import { FlexColumn, FlexRow, margins, colors, media, globalTransitionSettings } from 'css/css';

import { useWindowSize } from 'js/util/custom-hooks';
import { getIsMobile } from 'js/util/util';
import { Banner } from 'js/components/navbar/banner';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { useUser } from 'js/providers/UserProvider';
import { UnblockPermissionsModal } from 'js/components/call/UnblockPermissionsModal';
import { ScreenSharePermissionModal } from 'js/components/call/ScreenSharePermissionModal';

import { useCallContext } from './CallContext';
import { Video } from './Video';
import { ScreenShare } from './Screenshare';
import { SmallControls as BaseSmallControls } from './SmallControls';
import { Sidebar } from './Sidebar';
import { useCallPermissionsContext } from './CallPermissionsContext';
import { CallFeedbackModal } from './CallFeedbackModal';
import { CallExitModal } from './CallExitModal';
import { Controls } from './Controls';

interface Props {
  showSettings: () => void;
  showFeedback: () => void;
}

export const Call: React.FC<Props> = ({ showSettings, showFeedback }) => {
  const [localParticipant, setLocalParticipant] = useState<DailyParticipant>();
  const [partnerParticipant, setPartnerParticipant] = useState<DailyParticipant>();
  const [sharedScreen, setSharedScreen] = useState<DailyParticipant>();
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [joinedMeeting, setJoinedMeeting] = useState(false);
  const user = useUser();

  const {
    call,
    match,
    allowScreenShare,
    areControlsVisible,
    setAreControlsVisible,
    sidebarView,
  } = useCallContext();
  const {
    isAudioOn,
    isVideoOn,
    isScreenShareOn,
    setIsAudioOn,
    setIsVideoOn,
    setIsScreenShareOn,
    setAudioOn,
    setVideoOn,
    setScreenShareOn,
    showUnblockModal,
    setShowUnblockModal,
    showScreenSharePermissionModal,
    setShowScreenSharePermissionModal,
    endCall,
  } = useCallPermissionsContext();

  const isMobile = getIsMobile();
  const localAvatar = user?.image;
  const partnerAvatar = match?.image;

  const [doubleJoin, setDoubleJoin] = useState(false);
  const [allowExitModal, setAllowExitModal] = useState(false);

  const [fullScreenWidth, setFullScreenWidth] = useState(0);
  const [fullScreenHeight, setFullScreenHeight] = useState(0);
  const [windowWidth, windowHeight] = useWindowSize();

  useEffect(() => {
    const id = setTimeout(() => {
      setAllowExitModal(true);
    }, 60 * 1000);
    return () => clearTimeout(id);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      const width = windowWidth - 16 - 16 - (sidebarView ? 420 + 16 : 0);
      const height = windowHeight - (sharedScreen ? 88 + 24 : 0) - (isBannerVisible ? 56 : 0);
      setFullScreenWidth(width);
      setFullScreenHeight(height);
    } else {
      setFullScreenWidth(windowWidth);
      setFullScreenHeight(windowHeight);
    }
  }, [windowWidth, windowHeight, isMobile, isBannerVisible, sidebarView, sharedScreen]);

  useEffect(() => {
    if (!call) {
      return undefined;
    }

    const events: DailyEvent[] = ['participant-joined', 'participant-updated', 'participant-left'];
    const handleParticipantChange = () => {
      const participants = call.participants();
      setIsVideoOn(!!participants?.local.video);
      setIsAudioOn(!!participants?.local.audio);
      setIsScreenShareOn(!!participants?.local.screen);
      if (!participants) {
        return;
      }

      const getParticipant = (fn: (id: string) => boolean) =>
        participants[Object.keys(participants).find(fn) || ''];

      const screenShareParticipant = Object.values(participants).find(
        participant => !!participant.screen,
      );
      const isDoubleJoin = Object.values(participants).some(
        participant => user && participant.user_name === user.profile_id && !participant.local,
      );
      setSharedScreen(screenShareParticipant);
      setDoubleJoin(isDoubleJoin);
      setLocalParticipant(getParticipant(id => id === 'local'));
      setPartnerParticipant(getParticipant(id => id !== 'local'));
    };

    const handleLocalParticipantJoined = () => {
      setJoinedMeeting(true);
    };

    events.forEach(e => call.on(e, handleParticipantChange));
    call.on('joined-meeting', handleLocalParticipantJoined);

    return () => {
      events.forEach(e => call.off(e, handleParticipantChange));
      call.off('joined-meeting', handleLocalParticipantJoined);
    };
  }, [call]);

  useEffect(() => {
    if (!areControlsVisible) {
      return undefined;
    }

    const timeout = isMobile ? 5000 : 10000;

    const id = setTimeout(() => {
      setAreControlsVisible(false);
    }, timeout);
    return () => clearTimeout(id);
  }, [areControlsVisible]);

  const handlePartnerClick = () => {
    if (isMobile) {
      setAreControlsVisible(prev => !prev);
    }
  };

  useHotkeys(
    'ctrl+d',
    e => {
      e.preventDefault();
      setAudioOn(!isAudioOn);
    },
    {},
    [isAudioOn],
  );
  useHotkeys(
    'ctrl+e',
    e => {
      e.preventDefault();
      setVideoOn(!isVideoOn);
    },
    {},
    [isVideoOn],
  );

  return (
    <>
      <UnblockPermissionsModal showModal={showUnblockModal} setShowModal={setShowUnblockModal} />
      <ScreenSharePermissionModal
        showModal={showScreenSharePermissionModal}
        setShowModal={setShowScreenSharePermissionModal}
      />
      <CallFeedbackModal />
      {allowExitModal && doubleJoin && (
        <CallExitModal
          handleModalClose={() => {
            endCall(undefined, undefined);
          }}
        />
      )}
      <Banner setIsBannerVisible={setIsBannerVisible} />
      {joinedMeeting ? (
        isMobile ? (
          <Fullscreen isBannerVisible={isBannerVisible}>
            <Video
              participant={partnerParticipant}
              avatar={partnerAvatar}
              onClick={handlePartnerClick}
              fullScreenWidth={fullScreenWidth}
              fullScreenHeight={fullScreenHeight}
            />
            <Video
              isLocal
              participant={localParticipant}
              avatar={localAvatar}
              fullScreenWidth={fullScreenWidth}
              fullScreenHeight={fullScreenHeight}
            />
            <SmallControls
              isVisible={areControlsVisible}
              showSettings={showSettings}
              showFeedback={showFeedback}
            />
            <Controls isVisible={areControlsVisible} />
            <Sidebar localAvatar={localAvatar} partnerAvatar={partnerAvatar} />
          </Fullscreen>
        ) : (
          <Fullscreen
            isBannerVisible={isBannerVisible}
            isScreenShareOn={!isMobile && !!sharedScreen}
            isSidebarOn={!!sidebarView}
          >
            <DesktopVideosContainer sharedScreen={!!sharedScreen}>
              {!!sharedScreen && (
                <ScreenShare
                  participant={sharedScreen}
                  onClick={() => isScreenShareOn && setScreenShareOn(false)}
                  fullScreenWidth={fullScreenWidth}
                  fullScreenHeight={fullScreenHeight}
                />
              )}
              {!!sharedScreen && !sidebarView && <div style={{ marginRight: margins.size2 }} />}
              <FlexColumn
                style={sharedScreen && sidebarView ? { position: 'absolute', right: 0 } : {}}
              >
                <Video
                  participant={partnerParticipant}
                  avatar={partnerAvatar}
                  onClick={handlePartnerClick}
                  isScreenShareOn={!!sharedScreen}
                  fullScreenWidth={fullScreenWidth}
                  fullScreenHeight={fullScreenHeight}
                />
                <Video
                  isLocal
                  participant={localParticipant}
                  avatar={localAvatar}
                  isScreenShareOn={!!sharedScreen}
                  fullScreenWidth={fullScreenWidth}
                  fullScreenHeight={fullScreenHeight}
                />
              </FlexColumn>
            </DesktopVideosContainer>
            <SmallControls
              isVisible={areControlsVisible}
              showSettings={showSettings}
              showFeedback={showFeedback}
            />
            <Controls
              isVisible={areControlsVisible}
              allowScreenShare={isScreenShareOn || (allowScreenShare && !sharedScreen)}
            />
            <Sidebar localAvatar={localAvatar} partnerAvatar={partnerAvatar} />
          </Fullscreen>
        )
      ) : (
        <Fullscreen isBannerVisible={isBannerVisible}>
          <SVGLoader />
          <Sidebar localAvatar={localAvatar} partnerAvatar={partnerAvatar} />
        </Fullscreen>
      )}
    </>
  );
};

// TODO: deal with this 56px hardcoded banner height
const Fullscreen = styled(FlexColumn)<{
  isBannerVisible: boolean;
  isScreenShareOn?: boolean;
  isSidebarOn?: boolean;
}>`
  position: relative;
  height: calc(100% - ${p => (p.isBannerVisible ? '56px' : '0px')});
  justify-content: center;
  background-color: ${colors.black};
  max-width: 100vw;
  overflow: hidden;
  transition: ${globalTransitionSettings};
  padding-left: ${margins.size3};
  padding-right: ${margins.size3};
  ${p =>
    p.isScreenShareOn &&
    `
    padding-bottom: 88px;
    padding-top: ${margins.size4};
  `}

  ${p => p.isSidebarOn && `padding-right: 452px;`}

  ${media.mobile} {
      padding: 0;
  }
`;

const DesktopVideosContainer = styled(FlexRow)<{ sharedScreen?: boolean }>`
  position: relative;
`;

const SmallControls = styled(BaseSmallControls)<{ isVisible: boolean }>`
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
  position: absolute;
  bottom: ${margins.size4};
  left: ${margins.size4};
`;
