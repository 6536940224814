import React, { useState } from 'react';
import styled from 'styled-components/macro';

import {
  FlexColumn,
  Heading2,
  Link,
  colors,
  globalTransitionSettings,
  margins,
  Text,
} from 'css/css';

import { addParameterToURL } from 'js/util/util';

export const NewUserChat: React.FC<{
  hasSignedUp: boolean;
}> = ({ hasSignedUp }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <FlexColumn style={{ textAlign: 'center', padding: margins.size6 }}>
      <Heading2>
        <Text>Chat with users you meet on Lunchclub!</Text>

        {hasSignedUp ? (
          <Text>You&apos;ll be able to start messaging once you get matched!</Text>
        ) : (
          <Link
            to={addParameterToURL('ref', 'chat', `/weekly`)}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <AnimatedHeading isHovering={isHovering}>Sign up for a meeting! </AnimatedHeading>
          </Link>
        )}
      </Heading2>
    </FlexColumn>
  );
};

export const AnimatedHeading = styled.span<{ isHovering: boolean }>`
  color: ${colors.primaryMain};
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${colors.primaryMain};
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: ${globalTransitionSettings};
    ${p => p.isHovering && 'transform-origin: bottom left; transform: scaleX(1);'}
  }
`;
