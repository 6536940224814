import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

import { Card, Heading1, Heading2, Text, margins, colors, media } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { GreyPage } from 'js/components/shared/page-wrappers';
import { useUser } from 'js/providers/UserProvider';

export const Guidelines = () => {
  const history = useHistory();
  const user = useUser();
  return (
    <GreyPage>
      <WrapperCard>
        <Heading1 style={{ marginBottom: margins.size5 }}>
          Community Guidelines and Code of Conduct
        </Heading1>

        <div style={{ textAlign: 'left', maxWidth: '600px', margin: 'auto' }}>
          <SubHeading>
            Treat Lunchclub introductions like you would treat introductions from a friend or
            trusted colleague.
          </SubHeading>

          <Text>
            Respond in a timely manner to introduction emails for the week, and communicate in a
            friendly and professional way.
          </Text>

          <BottomText bold>
            Users who consistently fail to reply to introductions will be removed from the platform.
          </BottomText>

          <SubHeading>Rescheduling is discouraged.</SubHeading>

          <Text>
            Emergencies happen — in those cases, let your weekly match know if you can&apos;t make a
            meeting ASAP. “Ghosting”, or not showing up to a scheduled meeting without notifying
            your match, is not tolerated.
          </Text>

          <BottomText bold>
            Members who show a pattern of rescheduling consistently or of ghosting other users will
            be removed from the platform.
          </BottomText>

          <SubHeading>Lunchclub isn&apos;t about transactional benefits.</SubHeading>

          <Text>
            Members expect friendly and open-ended conversations, and often offer to make helpful
            introductions if they feel like there&apos;s a good fit. Take the time to build rapport
            with your match, and you&apos;ll reap the rewards.
          </Text>

          <BottomText>
            When your match spends their precious and valuable time to give guidance, advice,
            introductions, or other help, take the time to thank them and pay it forward when you
            can!
          </BottomText>

          <SubHeading>Lunchclub is a professional community.</SubHeading>

          <BottomText>
            It should never be used to solicit a date or romantic partnership. Reports of such
            behavior will result in removal from the platform. Solicitation is not welcome and
            attempts to sell a product or service prior to or during a meeting will result in
            removal from the platform.
          </BottomText>

          <SubHeading>Lunchclub is not an recruitment funnel to Direct Sales or MLM.</SubHeading>

          <BottomText>
            Any recruitment activity to any company whos strategy is to encourage existing
            distributors to recruit new distributors (commonly known as Multi-level marketing, or
            Direct Sales) is strictly prohibited. Members are encouraged to report this behavior to
            our support team and offenders will be promptly removed without warning.
          </BottomText>

          <SubHeading>
            Each of our members plays a role in establishing and promoting a positive, inclusive,
            and encouraging community.
          </SubHeading>

          <Text>
            Discussions, posts, actions, or comments that promote hatred of any kind are not
            acceptable through communication on Lunchclub or during meetings.
          </Text>

          <BottomText bold>
            Members who engage in this kind of behavior will be removed from the platform.
          </BottomText>

          <SubHeading>
            We take every report of hatred, violence, and harassment seriously.
          </SubHeading>

          <Text>
            We prioritize working with those affected to determine what the next steps should be -
            the safety of our members is of utmost importance. If you have been subject to any of
            these behaviors please let Lunchclub know.
          </Text>

          <BottomText bold>
            Members found to have broken the Lunchclub code of conduct will be removed from the
            platform.
          </BottomText>
        </div>

        <Button onClick={() => history.push(user?.email ? '/weekly' : '/')}>Finish</Button>
      </WrapperCard>
    </GreyPage>
  );
};

const SubHeading = styled(Heading2)`
  color: ${colors.primaryMain};
  margin-bottom: ${margins.size4};
`;

const BottomText = styled(Text)`
  margin-bottom: ${margins.size5};
`;

const WrapperCard = styled(Card)`
  max-width: 900px;
  padding: ${margins.size6};
  ${media.mobile} {
    padding: ${margins.size4};
  }
`;
