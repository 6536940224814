import React from 'react';

import { Clickable, colors, FlexRow, Heading3, margins } from 'css/css';

import { Autocomplete } from 'js/components/shared/Autocomplete';
import { LinkShare } from 'js/components/shared/LinkShare';

import shareInvite from 'img/invite/share-invite.svg';

interface Props {
  inviteText: string;
  inviteLink: string;
  sendInvite: (email: string) => void;
  sendMobileInvite: (phoneNumber: string) => void;
  isMobile?: boolean;
  dontShowAutoComplete?: boolean;
  isEmailTab: boolean;
}

export const InviteGadget: React.FC<Props> = ({
  sendInvite,
  sendMobileInvite,
  inviteText,
  inviteLink,
  isMobile = false,
  dontShowAutoComplete = false,
  isEmailTab,
}) => {
  const ShareInvite = () => {
    if (!(window.bridge || navigator.share)) return null;
    return (
      <Clickable
        onClick={() => {
          if (window.bridge) {
            return window.bridge?.presentShareSheet({ url: inviteLink });
          }
          return navigator.share({
            text: `${inviteText} ${inviteLink}`,
          });
        }}
        style={{ marginLeft: margins.size2, marginBottom: margins.size3, width: '100%' }}
      >
        <FlexRow>
          <img src={shareInvite} alt="Share invite" />
          <Heading3 color={colors.primaryMain} style={{ marginLeft: margins.size2 }}>
            Share invite
          </Heading3>
        </FlexRow>
      </Clickable>
    );
  };

  if (isMobile) {
    return (
      <div
        style={{
          width: '100%',
          backgroundColor: colors.whiteMain,
          padding: `${margins.size3} ${margins.size3} 0 ${margins.size3}`,
        }}
      >
        {!dontShowAutoComplete && (
          <Autocomplete
            buttonOnClick={sendInvite}
            mobileContactOnClick={sendMobileInvite}
            buttonText="Send invite"
            noUsers
            isMobile
            isEmailTab={isEmailTab}
          />
        )}
        <ShareInvite />
      </div>
    );
  }
  return (
    <>
      {!dontShowAutoComplete && (
        <Autocomplete
          buttonOnClick={sendInvite}
          mobileContactOnClick={sendMobileInvite}
          buttonText="Send invite"
          noUsers
          isEmailTab={isEmailTab}
        />
      )}
      <LinkShare linkToCopy={inviteLink} buttonText="Copy invite link" />
    </>
  );
};
