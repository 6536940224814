import React from 'react';

import { colors, Heading1, HideMobile, margins, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { OldModal } from 'js/components/shared/OldModal';
import { Objectives } from 'js/components/shared/objectives/ObjectiveSelector';

import { ModalContainer } from '../styles';

export const GoalModal = ({
  isOpen,
  closeModals,
  goals,
  userGoals,
  selectedGoal,
  setSelectedGoal,
  nextModal,
}: any) => {
  const userGoalIds = userGoals.map((goal: any) => goal.id);
  const remainingGoals = goals.filter((goal: any) => !userGoalIds.includes(goal.id));
  return (
    <OldModal width="902px" isOpen={isOpen} modalTitle="Add new goal" onClose={closeModals}>
      <ModalContainer>
        <Heading1 style={{ marginBottom: margins.size3, color: colors.blackMain }}>
          Add a new goal
        </Heading1>

        <HideMobile>
          <Text style={{ marginBottom: margins.size2 }}>
            Select a new goal to add to your settings.
          </Text>

          <Text style={{ marginBottom: margins.size6 }}>
            This will help our AI algorithm to find relevant matches.
          </Text>
        </HideMobile>

        <Objectives
          objectives={remainingGoals}
          selectedObjectives={selectedGoal ? [selectedGoal] : []}
          setSelectedObjectives={setSelectedGoal}
          profile
        />

        <Button style={{ marginTop: margins.size6 }} maxWidth="350px" onClick={nextModal}>
          Next
        </Button>
      </ModalContainer>
    </OldModal>
  );
};
