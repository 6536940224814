import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import { media, margins, FlexRow, Heading4, Heading3 } from 'css/css';

import { Invitee } from 'types/invites';

import { Button } from 'js/components/shared/Button';
import { Shuffle } from 'js/components/shared/Shuffle';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { ImpressionTracker } from 'js/components/shared/ImpressionTracker';
import { useTestBuckets, useUserContextProvider } from 'js/providers/UserProvider';

import clubpointsIcon from 'img/clubpoints/clubpoints.svg';

import { InviteCard } from './InviteCard';

interface Props {
  loading: boolean;
  inviteSuggestions: Invitee[];
  sendInvite: (email: string) => void;
  inviteAll: (inviteeList: Invitee[], note?: string) => void;
  skipInvite: (email: string) => void;
  shuffleInviteSuggestions: () => void;
  inviteAllLoading: boolean;
  confirmedInviteAll: boolean;
  setConfirmedInviteAll: (isConfirmed: boolean) => void;
  MAX_DISPLAYED_INVITE_SUGGESTIONS: number;
  MAX_LOADED_INVITE_SUGGESTIONS: number;
}

export const InviteSuggestions: React.FC<Props> = ({
  loading,
  inviteSuggestions,
  sendInvite,
  inviteAll,
  skipInvite,
  shuffleInviteSuggestions,
  inviteAllLoading,
  confirmedInviteAll,
  setConfirmedInviteAll,
  MAX_DISPLAYED_INVITE_SUGGESTIONS,
  MAX_LOADED_INVITE_SUGGESTIONS,
}) => {
  const { updateUser } = useUserContextProvider();

  const [initialLoad, setInitialLoad] = useState(true);
  const [clickedInviteAll, setClickedInviteAll] = useState(false);
  const { disableInviteAllTest } = useTestBuckets();

  const numInvitees =
    inviteSuggestions.length >= MAX_DISPLAYED_INVITE_SUGGESTIONS
      ? MAX_DISPLAYED_INVITE_SUGGESTIONS
      : inviteSuggestions.length;

  useEffect(() => {
    if (loading === false) {
      setInitialLoad(false);
    }
  }, [loading]);

  const dispatchUpdateUser = () =>
    updateUser({
      visualSettings: { confirmed_invite_all: true },
    });

  const getNumClubpointsToDisplay = () =>
    numInvitees === MAX_DISPLAYED_INVITE_SUGGESTIONS
      ? MAX_DISPLAYED_INVITE_SUGGESTIONS + 4
      : numInvitees;

  const getInviteAllConfirmedButton = () => (
    <Button
      onClick={() => {
        setClickedInviteAll(true);
        if (confirmedInviteAll) inviteAll(inviteSuggestions.slice(0, numInvitees));
      }}
      loading={inviteAllLoading}
      large
    >
      Invite all {numInvitees} ( +{getNumClubpointsToDisplay()} <SVG src={clubpointsIcon} alt="" />)
    </Button>
  );

  const getConfirmButton = () => (
    <Button
      style={{ margin: 0, marginLeft: margins.size2 }}
      onClick={() => {
        dispatchUpdateUser().then(() => setConfirmedInviteAll(true));
        inviteAll(inviteSuggestions.slice(0, numInvitees));
      }}
    >
      Invite +{getNumClubpointsToDisplay()} <SVG src={clubpointsIcon} alt="" />
    </Button>
  );

  const getCancelButton = () => (
    <Button secondary style={{ margin: 0 }} onClick={() => setClickedInviteAll(false)}>
      Cancel
    </Button>
  );

  const getInviteAllButton = () => {
    if (!confirmedInviteAll && clickedInviteAll)
      return (
        <FlexRow adapting justifyContent="center">
          <Heading3 style={{ margin: margins.size2 }}>Invite all {numInvitees} contacts?</Heading3>

          <FlexRow>
            {getCancelButton()}
            {getConfirmButton()}
          </FlexRow>
        </FlexRow>
      );
    return getInviteAllConfirmedButton();
  };

  if (initialLoad && loading) return <SVGLoader />;

  return (
    <InviteeContainer>
      <FullSpan>
        <FlexRow
          justifyContent="space-between"
          style={{ paddingTop: margins.size3, minHeight: '40px' }}
        >
          <Heading4>Suggested Invites</Heading4>
          {inviteSuggestions.length === MAX_LOADED_INVITE_SUGGESTIONS && (
            <Shuffle onClick={shuffleInviteSuggestions} loading={loading} />
          )}
        </FlexRow>
      </FullSpan>
      {inviteSuggestions.slice(0, numInvitees).map(invitee => (
        <ImpressionTracker key={invitee.email} impressionId={`invite,${invitee.email}`}>
          <InviteCard
            user={invitee}
            sendInvite={() => sendInvite(invitee.email)}
            skipInvite={() => skipInvite(invitee.email)}
          />
        </ImpressionTracker>
      ))}

      {inviteSuggestions.length > 1 && !disableInviteAllTest && (
        <FullSpan bottom>{getInviteAllButton()}</FullSpan>
      )}
    </InviteeContainer>
  );
};

const InviteeContainer = styled.div`
  max-width: 872px;
  margin: 0 auto;
  margin-bottom: ${margins.size3};
  display: grid;
  grid-template-columns: repeat(auto-fill, 215px);
  grid-gap: ${margins.size1};
  justify-content: center;
  ${media.mobile} {
    grid-template-columns: repeat(auto-fill, 100%);
  }
`;

type FullSpanTypes = {
  bottom?: boolean;
};
const FullSpan = styled.div<FullSpanTypes>`
  grid-column: 1 / -1;
  margin-top: ${p => (p.bottom ? margins.size1 : '0px')};
`;
