import { useRef } from 'react';

import { postPingServer } from './api';
import { useMountEffect, useTimeout, useWindowActive } from './custom-hooks';

const PING_INTERVAL = 20; // seconds

export const PingServer = () => {
  const windowActiveRef = useRef(true);
  windowActiveRef.current = useWindowActive();

  const pingIfWindowActive = () => windowActiveRef.current && postPingServer();
  useTimeout(pingIfWindowActive, 1000);

  useMountEffect(() => {
    const intervalId = setInterval(pingIfWindowActive, PING_INTERVAL * 1000);
    return () => clearInterval(intervalId);
  });

  return null;
};
