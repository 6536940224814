import React from 'react';
import styled from 'styled-components/macro';

import { margins, media, FlexRow } from 'css/css';

import { Button } from '../Button';

export const SaveAndCancel = ({
  clickCancel,
  clickSave,
  saveLoading,
  style,
  editing = true,
  cancelLabel = 'Cancel',
  saveLabel = 'Save',
}: any) =>
  editing ? (
    <Container style={style}>
      <SaveButtonContainer>
        <Button large onClick={clickSave} loading={saveLoading}>
          {saveLabel}
        </Button>
      </SaveButtonContainer>

      <Button secondary large onClick={clickCancel}>
        {cancelLabel}
      </Button>
    </Container>
  ) : null;

const Container = styled(FlexRow)`
  ${media.mobile} {
    flex-direction: column;
  }
`;

const SaveButtonContainer = styled.div`
  margin-right: ${margins.size2};
  width: 100%;
  ${media.mobile} {
    margin-right: 0px;
    margin-bottom: ${margins.size1};
  }
`;
