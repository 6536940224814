import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { Heading3, margins, Text, colors } from 'css/css';

import { postPushPermissionChange, postTestActivity } from 'js/util/api';

import close from 'img/shared/close-small.svg';

import { Button } from '../shared/Button';

let dismissedOnce = false;
const LOG_TYPE = 'chat_notif_auth';

export const ConversationNotificationsPrompt: React.FC<{
  firstName: string;
  headerHeight: number;
}> = ({ firstName, headerHeight }) => {
  const [authorized, setAuthorized] = useState(true);
  const [dismissed, setDismissed] = useState(dismissedOnce);
  const didPostLogRef = useRef(false);

  const onClose = () => {
    setDismissed(true);
    dismissedOnce = true;
    postTestActivity(LOG_TYPE, 'reject');
  };

  useEffect(() => {
    if (!window.bridge || !window.bridge.checkForNotifications) {
      return;
    }

    window.bridge.checkForNotifications(resp => {
      setAuthorized(resp);
    });
  }, []);

  useEffect(() => {
    if (!authorized && !didPostLogRef.current) {
      didPostLogRef.current = true;
      postTestActivity(LOG_TYPE, 'view');
    }
  }, [authorized]);

  if (authorized || dismissed) {
    return null;
  }

  return (
    <Banner $headerHeight={headerHeight}>
      <div
        tabIndex={0}
        role="button"
        onClick={onClose}
        style={{ padding: margins.size2, position: 'absolute', right: 0, top: 0, outline: 'none' }}
      >
        <img alt="close" src={close} />
      </div>

      <Heading3 style={{ marginTop: margins.size3 }}>See when {firstName} messages you!</Heading3>
      <Text style={{ margin: `${margins.size2} 0` }}>
        Turn on your notifications so you know right away when {firstName} messages you!
      </Text>
      <Button
        large
        onClick={() => {
          postTestActivity(LOG_TYPE, 'accept');
          if (window.bridge) {
            window.bridge.promptForNotifications('', '', authed => {
              setAuthorized(authed);
              postPushPermissionChange(authed);
              postTestActivity(LOG_TYPE, 'native-accept');
            });
          }
        }}
      >
        Turn on notifications
      </Button>
    </Banner>
  );
};

const Banner = styled.div<{ $headerHeight: number }>`
  position: absolute;
  z-index: 1;
  top: ${p => p.$headerHeight}px;
  border-bottom: 1px solid ${colors.greyMain};
  padding: ${margins.size4};
  background-color: ${colors.whiteMain};
`;
