import { useEffect, useRef } from 'react';

import { useCallV2Context } from 'js/components/callv2/contexts/CallContext';

import { useSoundLoader } from './useSoundLoader';

/**
 * Convenience hook to play `join.mp3` when local participant joins OR when subsequent participants join
 */
export const useJoinSound = () => {
  const { daily } = useCallV2Context();
  const { joinSound } = useSoundLoader();
  const playJoinSoundRef = useRef(false);

  useEffect(() => {
    if (!daily) return;
    /**
     * Case 1: Plays join sound when local participant joins (i.e. when <Call /> is rendered)
     */
    joinSound.play();

    /**
     * Note: Only allow join sound to play 3s after initial render. We need to do this because other participants
     *    in the call will always be loaded after the local participant, regardless of whether they were already
     *    in the room before. Hence we will assume that any participant that joins within 3s are participants
     *    that were in the room before the local participant.
     */
    setTimeout(() => {
      playJoinSoundRef.current = true;
    }, 3000);
  }, [daily]);

  useEffect(() => {
    if (!daily) return () => undefined;

    /**
     * Case 2: Plays join sound when the match participant joins
     */
    const handleParticipantJoined = () => {
      if (playJoinSoundRef.current) joinSound.play();
    };

    daily.on('participant-joined', handleParticipantJoined);
    return () => {
      daily.off('participant-joined', handleParticipantJoined);
    };
  }, [daily, joinSound]);
};
