import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';

import { FlexRow, globalTransitionSettings, colors, margins } from 'css/css';

import { getIsMobile } from 'js/util/util';
import { useOutsideAlerter } from 'js/util/custom-hooks';
import { useCallV2Context } from 'js/components/callv2/contexts/CallContext';
import { useMediaDevicesContext } from 'js/components/callv2/contexts/MediaDevicesContext';
import { useUIStateContext } from 'js/components/callv2/contexts/UIStateContext';
import { isMacOS } from 'js/components/callv2/utils/browser';
import { SmallArrow } from 'js/components/shared/SmallArrow';

import cameraOff from 'img/call/camera-off.svg';
import cameraOn from 'img/call/camera-on.svg';

import { useParticipantsContext } from '../../contexts/ParticipantsContext';

export const VideoControls: React.FC = memo(() => {
  const { daily, allowVideoProcessing } = useCallV2Context();
  const { camError, areDevicesBlocked, promptForAccess } = useMediaDevicesContext();
  const { localParticipant } = useParticipantsContext();
  const { setShowDeviceInUseModal, setShowDeviceNotFoundModal } = useUIStateContext();
  // Mute cam if localParticipant is undefined
  const isCamMuted = localParticipant?.isCamMuted ?? true;

  const isMobile = getIsMobile();

  const blurControlRef = useRef(null);

  /**
   * Local muted state for optimistic updates when toggling the camera.
   * Enabling camera can take ~1 second until the participant state is updated.
   * Adding an optimistic local state update adds the expected UI feedback.
   */
  const [muted, setMuted] = useState(isCamMuted);
  const [blurControl, setBlurControl] = useState(false);
  const [backgroundBlur, setBackgroundBlur] = useState(false);

  useEffect(() => {
    /**
     * Add a small delay before actually updating the local state.
     * When toggling the camera fast, participant-update events might be emitted subsequently.
     */
    const timeout = setTimeout(() => {
      setMuted(isCamMuted);
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [isCamMuted]);

  const toggleCamera = useCallback(() => {
    if (areDevicesBlocked) {
      promptForAccess();
      return;
    }
    switch (camError) {
      case 'in-use':
        setShowDeviceInUseModal(true);
        break;
      case 'not-found':
        setShowDeviceNotFoundModal(true);
        break;
      default:
        if (daily) daily.setLocalVideo(muted);
        setMuted(!muted);
        break;
    }
  }, [
    daily,
    areDevicesBlocked,
    camError,
    muted,
    promptForAccess,
    setShowDeviceInUseModal,
    setShowDeviceNotFoundModal,
  ]);

  const toggleBlurControl = useCallback(() => {
    setBlurControl(!blurControl);
  }, [blurControl, setBlurControl]);

  const toggleBackgroundBlur = useCallback(() => {
    if (isCamMuted || !daily || !allowVideoProcessing) return;
    if (!backgroundBlur) {
      daily.updateInputSettings({
        video: {
          processor: {
            type: 'background-blur',
            config: { strength: 1 },
          },
        },
      });
    } else {
      daily.updateInputSettings({
        video: {
          processor: {
            type: 'none',
            config: {},
          },
        },
      });
    }
    setBackgroundBlur(!backgroundBlur);
    setBlurControl(false);
  }, [isCamMuted, daily, backgroundBlur, setBackgroundBlur]);

  const toggleKeys = `${isMacOS() ? 'command' : 'ctrl'}+e`;

  useHotkeys(
    toggleKeys,
    e => {
      e.preventDefault();
      toggleCamera();
    },
    {},
    [toggleCamera],
  );

  const VideoIcon = muted ? CameraOffIcon : CameraOnIcon;
  const ariaLabel = muted ? `Turn on video (${toggleKeys})` : `Turn off video (${toggleKeys})`;
  const showBlur = !isMobile && allowVideoProcessing && !muted;

  useOutsideAlerter(blurControlRef, () => setBlurControl(false));

  return !showBlur ? (
    <VideoButton
      role="button"
      id="video-toggle"
      isActive={!muted}
      title={muted ? `Turn on video (${toggleKeys})` : `Turn off video (${toggleKeys})`}
      onClick={toggleCamera}
    >
      <VideoIcon isActive={!muted} aria-label={ariaLabel} />
    </VideoButton>
  ) : (
    <div ref={blurControlRef}>
      <ExpandedVideoButton>
        <VideoButton
          role="button"
          id="video-toggle"
          style={{ paddingLeft: margins.size1 }}
          title={muted ? `Turn on video (${toggleKeys})` : `Turn off video (${toggleKeys})`}
          onClick={toggleCamera}
          static
        >
          <VideoIcon isActive={!muted} aria-label={ariaLabel} />
        </VideoButton>
        <Seperator />
        <VideoButton
          role="button"
          id="video-settings"
          style={{
            paddingRight: '12px',
            backgroundColor: blurControl ? colors.blackMid : undefined,
            borderRadius: '0px 31px 31px 0px',
          }}
          onClick={toggleBlurControl}
          static
        >
          <TrayArrow direction="up" color={colors.whiteMain} />
        </VideoButton>
      </ExpandedVideoButton>
      {blurControl && (
        <BlurControl isActive onClick={toggleBackgroundBlur}>
          {backgroundBlur ? 'Disable Background Blur' : 'Enable Background Blur'}
        </BlurControl>
      )}
    </div>
  );
});

const VideoButton = styled.div<{ isActive?: boolean; static?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  transition: ${globalTransitionSettings};
  ${p =>
    !p.static &&
    `background-color: ${p.isActive ? colors.blackMain : colors.whiteMain};
    border-radius: 50%;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  `}
`;

const ExpandedVideoButton = styled(FlexRow)`
  width: 88px;
  height: 48px;
  border-radius: 31px;
  transition: ${globalTransitionSettings};
  background-color: ${colors.blackMain};
`;

const Seperator = styled.div`
  position: static;
  width: 1px;
  height: 32px;
  left: 0px;
  top: 0px;
  border: 1px solid ${colors.blackMid};
`;

const TrayArrow = styled(SmallArrow)`
  transition: ${globalTransitionSettings};
  width: 16px;
  height: 16px;
  margin-left: ${margins.size2};
`;

const BlurControl = styled.div<{ isActive: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.blackMain};
  color: ${colors.whiteMain};
  left: 64px;
  bottom: 56px;
  height: 48px;
  border-radius: 10px;
  padding: 8px;
  width: 100%;
  transition: ${globalTransitionSettings};
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

interface Icon {
  isActive: boolean;
}

const CameraOffIcon = styled(SVG).attrs(() => ({ src: cameraOff, 'alt-text': 'Video icon' }))<
  Icon
>``;
const CameraOnIcon = styled(SVG).attrs(() => ({ src: cameraOn, 'alt-text': 'Video icon' }))<Icon>``;
