/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
import qs from 'querystring';

import { useEffect, useState } from 'react';

import { apiFetch } from './api';

const message = {
  success: (msg: string) => {
    console.log(msg);
  },
  error: (msg: string) => {
    console.error(msg);
  },
};

// Collection data provider

export async function makeRequest<T>(
  path: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
  itemPayload: any = null,
): Promise<T> {
  const resp = await apiFetch<T>(method, path, itemPayload);
  return resp.getJson;
}

interface ResourceConfig {
  silent?: boolean;
  GETRequestMethod?: 'GET' | 'POST';
}

export interface ResourceOps<T, U = Partial<T>> {
  post: (v: U) => Promise<void>;
  put: (v: U) => Promise<void>;
  putItem: (item: { id: string | number } & U) => Promise<void>;
  delete: () => Promise<void>;
  deleteItem: (item: string | number | { id: string | number }) => Promise<void>;
  applyLocalUpdates: (v: T) => void;
  refresh: () => Promise<T>;
}

export function useResource<T, U = Partial<T>>(
  path: string,
  query?: { [key: string]: any },
  config?: ResourceConfig,
) {
  const GETRequestMethod = config?.GETRequestMethod || 'GET';
  const [state, setState] = useState<{ value: T; url: string } | undefined>(undefined);
  const url = `${path}${path.includes('?') ? '&' : '?'}${
    GETRequestMethod === 'GET' && query ? qs.stringify(query) : ''
  }`;

  useEffect(() => {
    const fetch = async () => {
      setState(undefined);
      setState({
        url,
        value: await makeRequest<T>(
          url,
          GETRequestMethod,
          GETRequestMethod === 'POST' ? query : undefined,
        ),
      });
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, GETRequestMethod]);

  const ops: ResourceOps<T, U> = {
    post: async (v: U) => {
      try {
        const resp = await makeRequest<U>(path, 'POST', v);
        if ('id' in resp && state && state.value instanceof Array) {
          setState({ ...state, value: [...state.value, resp] as any });
        } else {
          console.warn(
            'useResource: POST state update skipped, response contains no id or state.value is not an array',
          );
        }
        if (!config?.silent) message.success('Item created');
      } catch (err) {
        if (!config?.silent) message.error('Failed to save, try again.');
        throw err;
      }
    },
    put: async (v: U) => {
      try {
        const resp = await makeRequest<T>(path, 'PUT', v);
        if (state) {
          if ('id' in resp) {
            setState({ ...state, value: { ...state.value, ...resp } });
          } else if (state.value instanceof Array && resp instanceof Array) {
            setState({ ...state, value: resp });
          } else {
            console.warn(
              'useResource: PUT state update skipped, response does not look like object or array item',
            );
          }
        }
        if (!config?.silent) message.success('Changes saved');
      } catch (err) {
        if (!config?.silent) message.error('Failed to save, try again.');
        throw err;
      }
    },
    putItem: async (item: { id: string | number } & U) => {
      const resp = await makeRequest<any>(`${path}/${item.id}`, 'PUT', item);
      if (resp && 'id' in resp && state && state.value instanceof Array) {
        const nextValue: any = [];
        for (const a of state.value) {
          nextValue.push(a.id === resp.id ? resp : a);
        }
        setState({ ...state, value: nextValue });
      } else {
        console.warn(
          'useResource: PUT state update skipped, response does not look like array item',
        );
      }
      if (!config?.silent) message.success('Updated successfully');
    },
    delete: async () => {
      await makeRequest<T>(path, 'DELETE');
      if (!config?.silent) message.success('Deleted successfully');
    },
    deleteItem: async (item: string | number | { id: string | number }) => {
      const itemId = typeof item === 'object' && 'id' in item ? item.id : item;
      await makeRequest<T>(`${path}/${itemId}`, 'DELETE');
      if (!config?.silent) message.success('Deleted successfully');
      if (state && state.value instanceof Array) {
        setState({ ...state, value: state.value.filter(i => i.id !== itemId) as any });
      } else {
        console.warn(
          'useResource: DELETE state update skipped, local state is not an array of items',
        );
      }
    },
    applyLocalUpdates: (v: T) => {
      setState({ url, value: v });
    },
    refresh: async () => {
      const v = await makeRequest<T>(
        url,
        GETRequestMethod,
        GETRequestMethod === 'POST' ? query : undefined,
      );
      setState({ url, value: v });
      return v;
    },
  };

  // Explicitly tell TS this is a tuple of two distinct types, not an array of (T | typeof Ops)
  if (state?.url === url) {
    return [state.value, ops] as [T | undefined, ResourceOps<T, U>];
  }
  return [undefined, ops] as [T | undefined, ResourceOps<T, U>];
}
