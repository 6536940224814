import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router';

import { APIFailure } from 'js/components/shared/APIFailure';
import { GreyPage } from 'js/components/shared/page-wrappers';
import { postUserInfo, PostUserInfoPayload } from 'js/util/api';

import * as images from 'img/registration-v2';

import { AssetPrefetcher } from '../shared/AssetPrefetcher';

import { StepCitiesMobile } from './mobile/StepCitiesMobile';
import { StepObjectivesMobile } from './mobile/StepObjectivesMobile';
import { RegistrationFields, useRegistrationFields } from './use-registration-fields';
import { StepInterestsMobile } from './mobile/StepInterestsMobile';
import { StepProfilePictureMobile } from './mobile/StepProfilePictureMobile';
import { StepHeadlineMobile } from './mobile/StepHeadlineMobile';
import { StepNameMobile } from './mobile/StepNameMobile';
import { StepVerifyMobile } from './mobile/StepVerifyMobile';
import { StepLinkedinMobile } from './mobile/StepLinkedinMobile';

export interface RegistrationStepProps {
  values: RegistrationFields;
  setValues: React.Dispatch<React.SetStateAction<RegistrationFields>>;
  submitUserInfo: (payload: PostUserInfoPayload, next: () => void | Promise<void>) => void;
  prefillInviteCode: string | null;
}

export const MobileRegistrationContent = () => {
  const location = useLocation();
  const [apiFailure, setApiFailure] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { skipVerification, values, setValues, prefillInviteCode } = useRegistrationFields();
  const [tempToken, setTempToken] = useState<string>('');

  const submitUserInfo = async (info: PostUserInfoPayload, next?: () => void) => {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    let res = null;
    try {
      res = await postUserInfo(info);
    } catch (err) {
      setApiFailure(true);
    }
    if (res && res.ok) {
      if (res.getJson.token) {
        setTempToken(res.getJson.token);
      }
      if (next) {
        await next();
      }
    } else {
      setApiFailure(true);
    }
    setSubmitting(false);
  };

  if (apiFailure) {
    return (
      <GreyPage>
        <APIFailure />
      </GreyPage>
    );
  }

  const stepProps: RegistrationStepProps = {
    values,
    setValues,
    submitUserInfo,
    prefillInviteCode,
  };

  return (
    <>
      <AssetPrefetcher images={images} />
      <Switch>
        <Route path="/registration/city" render={() => <StepCitiesMobile {...stepProps} />} />
        <Route
          path="/registration/objectives"
          render={() => <StepObjectivesMobile {...stepProps} />}
        />
        <Route
          path="/registration/interests"
          render={() => <StepInterestsMobile {...stepProps} />}
        />
        <Route
          path="/registration/profile-picture"
          render={() => <StepProfilePictureMobile {...stepProps} />}
        />
        <Route path="/registration/name" render={() => <StepNameMobile {...stepProps} />} />
        <Route path="/registration/linkedin" render={() => <StepLinkedinMobile {...stepProps} />} />
        <Route
          path="/registration/headline"
          render={() => <StepHeadlineMobile {...stepProps} skipVerification={skipVerification} />}
        />
        <Route
          path="/registration/verification"
          render={() => <StepVerifyMobile tempTokenDefault={tempToken} />}
        />
        <Redirect to={`/registration/city${location.search}`} />
      </Switch>
    </>
  );
};
