import React from 'react';
import styled from 'styled-components/macro';

import { margins, FlexColumn, Heading1, Text, Link } from 'css/css';

import { LinkText } from '../style';

export const PassedWeekCard = () => (
  <CardContainer>
    <Heading1>You passed for the week!</Heading1>

    <Text>Change your mind?</Text>

    <Link to="/weekly">
      <LinkText>Sign up for the week</LinkText>
    </Link>
  </CardContainer>
);

const CardContainer = styled(FlexColumn)`
  padding: ${margins.size4};
`;
