import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { colors, margins, FlexColumn, FlexRow, Heading1, Heading3, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { Checkbox } from 'js/components/shared/Checkbox';
import { Modal } from 'js/components/shared/NewModal';
import { useUser } from 'js/providers/UserProvider';

import inPersonLocation from 'img/weekly/location-irl.svg';
import inPersonCalendar from 'img/weekly/calendar-irl.svg';
import inPerson from 'img/weekly/in-person.svg';

interface Props {
  showModal: boolean;
  neighborhood: string;
  timeslot: string;
  join: () => void;
  decline: () => void;
}

export const InPersonInviteModal: React.FC<Props> = ({
  showModal,
  neighborhood,
  timeslot,
  join,
  decline,
}) => {
  const user = useUser();
  const [modalOpen, setModalOpen] = useState(showModal);
  const [boxChecked, setBoxChecked] = useState(false);

  useEffect(() => {
    setModalOpen(showModal);
  }, [showModal]);

  const handleClose = () => {
    decline();
    setModalOpen(false);
  };

  return (
    <Modal showModal={modalOpen} handleClose={handleClose}>
      <FlexColumn>
        <Heading1 style={{ margin: `${margins.size3} 0px`, textAlign: 'center' }}>
          You&apos;re invited to Lunch!
        </Heading1>
        <Text style={{ textAlign: 'center' }}>
          We&apos;re running an exclusive beta for select Lunchclub members in{' '}
          {user.locale_info.name} this week. Sign up for a 1:1 in-person meeting!
        </Text>
        <img style={{ margin: `${margins.size4} 0px` }} src={inPerson} alt="" />
        <FlexRow style={{ marginBottom: margins.size2 }}>
          <img style={{ marginRight: margins.size2 }} src={inPersonLocation} alt="Location" />
          <Heading3 color={colors.blackMid}>
            {neighborhood}, {user.locale_info.name}
          </Heading3>
        </FlexRow>
        <FlexRow style={{ marginBottom: margins.size4 }}>
          <img style={{ marginRight: margins.size2 }} src={inPersonCalendar} alt="Location" />
          <Heading3 color={colors.blackMid}>
            {moment(timeslot).format('dddd, MMMM Do, ha')}
          </Heading3>
        </FlexRow>
        <Checkbox
          desc="I am fully vaccinated and understand that this meeting is in person."
          checked={boxChecked}
          setCheckValue={setBoxChecked}
          borderColor={colors.blackMid}
        />
        <FlexColumn
          style={{
            width: 250,
            maxWidth: '90%',
          }}
        >
          <Button large onClick={join} style={{ marginTop: margins.size2 }} invalid={!boxChecked}>
            I’m in!
          </Button>
          <Button large onClick={decline} secondary style={{ marginTop: margins.size1 }}>
            Not this week
          </Button>
        </FlexColumn>
      </FlexColumn>
    </Modal>
  );
};
