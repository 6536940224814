import React from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import { colors, globalTransitionSettings } from 'css/css';

import feedbackIcon from 'img/call/feedback.svg';
import settingsIcon from 'img/call/settings.svg';

type Type = 'settings' | 'feedback';

interface Props {
  type: Type;
  action: () => void;
}
export const SmallControl: React.FC<Props> = ({ type, action }) => {
  const Icon = {
    settings: SettingsIcon,
    feedback: FeedbackIcon,
  }[type];

  return (
    <ControlContainer type={type} onClick={action}>
      <Icon />
    </ControlContainer>
  );
};

const SettingsIcon = styled(SVG).attrs(() => ({ src: settingsIcon }))`
  transition: all 0.5s ease;
`;

const FeedbackIcon = styled(SVG).attrs(() => ({ src: feedbackIcon }))`
  transition: all 1s ease;
`;

const ControlContainer = styled.div<{ type: Type }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: ${globalTransitionSettings};
  background-color: ${colors.blackMid};

  &:hover {
    cursor: pointer;
    transform: scale(1.2);

    ${SettingsIcon} {
      transform: rotate(90deg);
    }
  }
`;
