import React from 'react';
import styled from 'styled-components/macro';

import { margins, Text, Heading1, Card, media } from 'css/css';

import { GoogleSignIn } from 'js/components/shared/GoogleSignIn';
import { Button } from 'js/components/shared/Button';
import { useUserContextProvider } from 'js/providers/UserProvider';
import { useNotifContext } from 'js/util/notif-context';

export const WeeklyGauth = ({ onFinish }: any) => {
  const { showNotif } = useNotifContext();
  const { updateUser } = useUserContextProvider();

  return (
    <Container>
      <div style={{ maxWidth: '640px', margin: 'auto', marginBottom: margins.size4 }}>
        <Heading1>Get more relevant matches!</Heading1>
        <Text>
          Users who connect their Lunchclub accounts with Google get 65% more relevant matches!
          Connect with Google and supercharge your match:
        </Text>
      </div>

      <GoogleSignIn
        buttonText="Connect with Google"
        isRegistering
        onLogIn={() => {
          showNotif({
            message: 'Successfully connected your Google account!',
            level: 'success',
          });
          onFinish();
        }}
      />

      <Button
        secondary
        maxWidth="200px"
        style={{ marginTop: margins.size1 }}
        onClick={() => {
          updateUser({
            visualSettings: { hide_weekly_gauth: true },
          }).then(() => onFinish());
        }}
      >
        Skip
      </Button>
    </Container>
  );
};

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${margins.size5};
  ${media.mobile} {
    padding: ${margins.size4};
  }
`;
