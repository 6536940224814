import React, { useEffect, useState, useMemo } from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components/macro';
import _ from 'lodash';

import { FlexColumn, globalTransitionSettings, media } from 'css/css';

import Channel, { ConversationAttributes, MessageAttributes, SuperMessage } from 'types/channel';
import { LunchclubMatch } from 'types/matches';

import { useUser } from 'js/providers/UserProvider';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { getIsMobile, getTimezoneAbbr, NETWORK_TYPES, simpleStringHash } from 'js/util/util';
import { useMountEffect } from 'js/util/custom-hooks';
import { convertTimeslots, postChatCreateConversation } from 'js/util/api';

import { ConversationHeader } from './conversation-header';
import { ConversationBody } from './ConversationBody';
import { ConversationInput } from './ConversationInput';
import { ConversationNotificationsPrompt } from './ConversationNotificationsPrompt';
import { ConversationPopup } from './ConversationPopup';
import { useEndorsementModal } from './endorsement-modal';

import { ChatTabs } from '.';

export const updateReadStatus = async (profileId: string, currChannel?: Channel) => {
  if (currChannel) {
    if (
      currChannel.conversation?.attributes &&
      !(currChannel.conversation.attributes as any)[profileId]?.hasRead
    ) {
      await currChannel.conversation.updateAttributes({
        ...currChannel.conversation.attributes,
        [profileId]: { hasRead: true },
      });
    }
  }
};

export type PendingMessage = {
  profileId: string;
  message: string;
  messageAttributes: MessageAttributes;
  conversationAttributes?: ConversationAttributes;
};

export const TRANSITION_DURATION = 250;

export const transitionSettings: Record<string, any> = {
  styles: {
    entering: { transform: 'translateX(0)', opacity: 1 },
    entered: { transform: 'translateX(0)', opacity: 1 },
    exiting: { transform: 'translateX(100%)', opacity: 1 },
    exited: { transform: 'translateX(100%)', opacity: 0 },
  },
  timeout: TRANSITION_DURATION,
};

interface Props {
  setHasUnreadMessages?: React.Dispatch<React.SetStateAction<boolean>>;
  isActive?: boolean;
  inCall: boolean;
  pastMatches?: LunchclubMatch[];
  selectedChannel: Channel;
  isOpen: boolean;
  setOpenTab?: React.Dispatch<React.SetStateAction<ChatTabs>>;
}

export const ConversationContainer: React.FC<Props> = ({
  setHasUnreadMessages,
  isActive,
  inCall,
  pastMatches,
  selectedChannel,
  isOpen,
  setOpenTab,
}) => {
  const [isMobileKeyboardOpen, setIsMobileKeyboardOpen] = useState(false);
  const [isSchedulerOpen, setIsSchedulerOpen] = useState(false);
  const [isReconnect, setIsReconnect] = useState(false);
  const [isTimeslotsConfirmationVisible, setIsTimeslotsConfirmationVisible] = useState(false);
  const [isChangingTimes, setIsChangingTimes] = useState(false);
  const [pendingMessage, setPendingMessage] = useState<PendingMessage>();
  const [isChangingScheduleTimes, setIsChangingScheduleTimes] = useState(false);
  const [inputHeight, setInputHeight] = useState(0);
  const [lastSchedulerMessage, setLastSchedulerMessage] = useState<SuperMessage>();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [timeslotsSuggestedByMatch, setTimeslotsSuggestedByMatch] = useState<string[]>([]);
  const [shouldDisplayInputInBotChannel, setShouldDisplayInputInBotChannel] = useState(false);
  const [areBubblesVisible, setAreBubblesVisible] = useState(false);
  const [isRespondingToIcebreaker, setIsRespondingToIcebreaker] = useState(false);
  const [openEndorsementModal, endorsementModal] = useEndorsementModal();

  const user = useUser();
  const myProfileId = user.profile_id;
  const userTimezone = getTimezoneAbbr(user.locale_info.timezone);
  const { isPopupOpen, setIsPopupOpen, setSavedTimeslots } = useChatContext();

  const {
    matchCode,
    isBlocked,
    conversation,
    canReconnect,
    profileId,
    isSlantMatch,
    prescheduledReconnectDate,
    isMatchPreviouslyRescheduled,
    userEventStatus,
    matchUserEventStatus,
    isMorpheusMatch,
  } = selectedChannel;

  const utcToUserTime = (date?: string) => {
    if (date === undefined) {
      return undefined;
    }
    return moment
      .utc(date)
      .clone()
      .tz(user.locale_info.timezone)
      .format('YYYY-MM-DD HH:mm:ss');
  };

  const isMobile = getIsMobile();
  const metDateFromConversation = (conversation?.attributes as any)?.metDate;
  const metDate = metDateFromConversation
    ? utcToUserTime(metDateFromConversation)
    : selectedChannel.metDate;

  const isFirstTimeScheduling =
    isSlantMatch &&
    !isMatchPreviouslyRescheduled &&
    !(conversation?.attributes as any)?.hasPrevScheduled;

  const isUserScheduleInitiator = useMemo(() => lastSchedulerMessage?.author === user.profile_id, [
    lastSchedulerMessage,
  ]);

  const schedulerTimeslots = useMemo(
    () => (lastSchedulerMessage?.attributes as any)?.schedulerTimeslots || [],
    [lastSchedulerMessage],
  );
  const isScheduling = (conversation?.attributes as any)?.isScheduling;

  const isUpcoming = isFirstTimeScheduling || moment(metDate).isAfter(moment());

  const reconnectDate =
    utcToUserTime((conversation?.attributes as any)?.reconnectDate) || prescheduledReconnectDate;
  const isReconnectUpcoming = moment(reconnectDate).isAfter(moment());

  const isInBotChannel = selectedChannel.networkType === NETWORK_TYPES.LC_BOT;

  let popupContentHash = '';

  const hasMeetingPassed =
    !isFirstTimeScheduling &&
    moment(metDate)
      .add(1, 'days')
      .isBefore(moment());

  const shouldDisplayReconnect =
    canReconnect && !isUpcoming && !isScheduling && !!selectedChannel.matchCode && !isBlocked;

  const shouldShowCallHeader =
    matchCode && (!hasMeetingPassed || isReconnectUpcoming) && !isScheduling && !isBlocked;

  const isInCallMobile = !!window.bridge?.exitChat;

  const matchPrivateNote = pastMatches?.find(m => m.match_user.public_id === profileId)
    ?.private_note;

  const shouldShowCalendarIcon =
    isReconnectUpcoming || shouldShowCallHeader || shouldDisplayReconnect || isScheduling;

  const shouldHideInput =
    selectedChannel.isBlocked || (isInBotChannel && !shouldDisplayInputInBotChannel);

  useEffect(() => {
    if (isActive) {
      updateReadStatus(myProfileId, selectedChannel);
    }
  }, [isActive, myProfileId, selectedChannel]);

  useEffect(() => {
    if (isSchedulerOpen) {
      window.hj('trigger', 'reschedule');
    }
  }, [isSchedulerOpen]);

  useEffect(() => {
    if (!selectedChannel || !setHasUnreadMessages) return;
    const myAttributes = (selectedChannel.conversation?.attributes as any)?.[myProfileId];
    setHasUnreadMessages(myAttributes && !myAttributes?.hasRead);
  }, [selectedChannel, setHasUnreadMessages]);

  popupContentHash = simpleStringHash(
    [
      schedulerTimeslots.toString(),
      isScheduling,
      isFirstTimeScheduling,
      shouldDisplayReconnect,
      reconnectDate,
      userEventStatus,
      matchUserEventStatus,
      isMatchPreviouslyRescheduled,
    ].toString(),
  ).toString();

  useEffect(() => {
    const isPopupNew = user?.visual_settings?.[selectedChannel.profileId] !== popupContentHash;

    const shouldDisplayPopup =
      !isInCallMobile &&
      !inCall &&
      (isReconnectUpcoming ||
        shouldShowCallHeader ||
        shouldDisplayReconnect ||
        (isScheduling && !isChangingTimes)) &&
      (!isMobile || isOpen) &&
      (!isMorpheusMatch || isScheduling || isMatchPreviouslyRescheduled) &&
      isPopupNew;

    setIsPopupOpen(shouldDisplayPopup);
  }, [selectedChannel.profileId, popupContentHash]);

  useMountEffect(() => {
    const searchInputElement = document.getElementById('search_input');
    if (searchInputElement) {
      searchInputElement.blur();
    }
  });

  useEffect(() => {
    const convertTimeslotsToUserTimezone = async (passedTimeslots: string[]) => {
      const res = await convertTimeslots({
        timeslots: passedTimeslots,
        profileId,
      });
      if (!res.ok) {
        return;
      }
      setTimeslotsSuggestedByMatch(res.getJson.timeslots);
    };
    if (!isUserScheduleInitiator && schedulerTimeslots.length) {
      convertTimeslotsToUserTimezone(schedulerTimeslots);
    }
  }, [schedulerTimeslots, isUserScheduleInitiator]);
  useEffect(() => {
    resetSavedTimeslots();
  }, [isScheduling, lastSchedulerMessage, isUserScheduleInitiator]);

  const resetSavedTimeslots = () => {
    if (isScheduling && schedulerTimeslots && isUserScheduleInitiator) {
      const isTimeslotSuggested = (t: Moment) => {
        const startTime = moment(`${t?.clone().format('YYYY-MM-DD')} 07:00:00`);
        const endTime = moment(`${t?.clone().format('YYYY-MM-DD')} 18:00:00`);
        return (
          t.isAfter(startTime, 'hour') && t.isBefore(endTime, 'hour') && t.format('mm') === '00'
        );
      };
      const [suggestedTimes, customTimes] = _.partition(
        schedulerTimeslots.filter((t: string) =>
          moment(t).isAfter(
            moment
              .utc()
              .tz(user.locale_info.timezone)
              .format('YYYY-MM-DD HH:mm'),
          ),
        ),
        t => isTimeslotSuggested(moment(t)),
      );
      setSavedTimeslots(prev => ({
        ...prev,
        [profileId]: {
          ...prev[profileId],
          timeslots: { suggested: suggestedTimes, custom: customTimes },
        },
      }));
    } else {
      setSavedTimeslots(prev => ({
        ...prev,
        [profileId]: {
          ...prev[profileId],
          timeslots: { suggested: [], custom: [] },
        },
      }));
    }
  };

  const openScheduler = ({
    isReconnecting,
    isEditingTimes,
  }: {
    isReconnecting: boolean;
    isEditingTimes: boolean;
  }) => {
    setIsSchedulerOpen(true);
    if (isEditingTimes) {
      setIsChangingScheduleTimes(true);
    }
    if (isReconnecting) {
      setIsReconnect(true);
    }
    if (!conversation) {
      postChatCreateConversation(profileId);
    }
  };

  return (
    <Container $isInCall={inCall} $isOpen={isOpen}>
      {!inCall && (
        <ConversationHeader
          selectedChannel={selectedChannel}
          setIsSchedulerOpen={setIsSchedulerOpen}
          isFirstTimeScheduling={isFirstTimeScheduling}
          metDate={metDate}
          setIsReconnect={setIsReconnect}
          setOpenTab={setOpenTab}
          isOpen={isOpen}
          setHeaderHeight={setHeaderHeight}
        />
      )}
      {isMobile && (
        <ConversationNotificationsPrompt
          firstName={selectedChannel.firstName}
          headerHeight={headerHeight}
        />
      )}
      <ConversationBody
        lcMessages={selectedChannel.lcMessages}
        localMessages={selectedChannel.localMessages}
        messages={selectedChannel.messages}
        updateReadStatus={updateReadStatus}
        setIsSchedulerOpen={setIsSchedulerOpen}
        setIsTimeslotsConfirmationVisible={setIsTimeslotsConfirmationVisible}
        isSchedulerOpen={isSchedulerOpen}
        isFirstTimeScheduling={isFirstTimeScheduling}
        selectedChannel={selectedChannel}
        userTimezone={userTimezone}
        inputHeight={inputHeight}
        isOpen={isOpen}
        setLastSchedulerMessage={setLastSchedulerMessage}
        inCall={inCall}
        openScheduler={openScheduler}
        openEndorsementModal={!inCall ? openEndorsementModal : () => undefined}
        timeslotsSuggestedByMatch={timeslotsSuggestedByMatch}
        isUserScheduleInitiator={isUserScheduleInitiator}
        setShouldDisplayInputInBotChannel={setShouldDisplayInputInBotChannel}
        areBubblesVisible={areBubblesVisible}
        isUpcoming={isUpcoming}
        isRespondingToIcebreaker={isRespondingToIcebreaker}
        setIsRespondingToIcebreaker={setIsRespondingToIcebreaker}
      />
      {isPopupOpen && !isInBotChannel && (
        <ConversationPopup
          shouldDisplayReconnect={shouldDisplayReconnect}
          selectedChannel={selectedChannel}
          matchPrivateNote={matchPrivateNote}
          setIsSchedulerOpen={setIsSchedulerOpen}
          setIsReconnect={setIsReconnect}
          isFirstTimeScheduling={isFirstTimeScheduling}
          metDate={metDate}
          userTimezone={userTimezone}
          inputHeight={inputHeight}
          isScheduling={isScheduling}
          setIsChangingTimes={setIsChangingTimes}
          setPendingMessage={setPendingMessage}
          openScheduler={openScheduler}
          inCall={inCall}
          lastSchedulerMessage={lastSchedulerMessage}
          isUpcoming={isUpcoming}
          isMobileKeyboardOpen={isMobileKeyboardOpen}
          popupContentHash={popupContentHash}
          reconnectDate={reconnectDate}
          isUserScheduleInitiator={isUserScheduleInitiator}
        />
      )}
      {!shouldHideInput && (
        <ConversationInput
          inCall={isInCallMobile}
          openScheduler={() => setIsSchedulerOpen(true)}
          isTimeslotsConfirmationVisible={isTimeslotsConfirmationVisible}
          closeTimeslotsConfirmation={() => setIsTimeslotsConfirmationVisible(false)}
          isChangingTimes={isChangingTimes}
          setIsChangingTimes={setIsChangingTimes}
          pendingMessage={pendingMessage}
          setPendingMessage={setPendingMessage}
          isFirstTimeScheduling={isFirstTimeScheduling}
          isReconnect={isReconnect}
          metDate={metDate}
          selectedChannel={selectedChannel}
          isChangingScheduleTimes={isChangingScheduleTimes}
          resetIsChangingScheduleTimes={() => setIsChangingScheduleTimes(false)}
          userTimezone={userTimezone}
          isMobileKeyboardOpen={isMobileKeyboardOpen}
          setIsMobileKeyboardOpen={setIsMobileKeyboardOpen}
          inputHeight={inputHeight}
          setInputHeight={setInputHeight}
          isOpen={isOpen}
          shouldShowCalendarIcon={shouldShowCalendarIcon}
          isUserScheduleInitiator={isUserScheduleInitiator}
          setAreBubblesVisible={setAreBubblesVisible}
          isRespondingToIcebreaker={isRespondingToIcebreaker}
          setIsRespondingToIcebreaker={setIsRespondingToIcebreaker}
        />
      )}
      {!inCall && endorsementModal}
    </Container>
  );
};

const Container = styled(FlexColumn)<{
  $isInCall: boolean;
  $isOpen: boolean;
}>`
  height: 100%;
  align-items: flex-start;
  overflow: hidden;
  flex-grow: 1;
  ${p => p.$isInCall && 'width: 100%;'}
  position: relative;

  ${media.mobile} {
    ${p => !p.$isOpen && 'display: none;'}
    width: 100%;
    align-items: initial;
    padding-top: env(safe-area-inset-top);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    ${p => p.$isInCall && 'height: unset;'}
    transition: ${globalTransitionSettings};
  }
`;
