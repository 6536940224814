import React, { ComponentPropsWithoutRef, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import {
  globalTransitionSettings,
  fontSizes,
  colors,
  borders,
  margins,
  media,
  Heading1,
} from 'css/css';

import { getIsIOSApp, getIsMobile } from 'js/util/util';
import { useCallV2Context } from 'js/components/callv2/contexts/CallContext';
import { useUIStateContext } from 'js/components/callv2/contexts/UIStateContext';
import { useParticipantsContext } from 'js/components/callv2/contexts/ParticipantsContext';
import { Participant } from 'js/components/callv2/contexts/ParticipantsContext/types';

import { TileContent, TileContentProps } from './TileContent';
import { LocalScreenShare } from './ScreenContent';

const DEFAULT_ASPECT_RATIO = 9 / 16;

interface Props
  extends ComponentPropsWithoutRef<'div'>,
    Omit<TileContentProps, 'participant' | 'isLocal'> {
  participant?: Participant;
  isLocal?: boolean;
  isScreen?: boolean;
}

export const Tile: React.FC<Props> = ({
  participant,
  isLocal = false,
  isScreen = false,
  network,
  avatarImg,
  ...props
}) => {
  const { match, daily } = useCallV2Context();
  const {
    toggleControlsVisibility,
    sidebarView,
    fullScreenWidth,
    fullScreenHeight,
  } = useUIStateContext();
  const { screen } = useParticipantsContext();

  const [size, setSize] = useState(0);
  const isMobile = getIsMobile();

  useEffect(() => {
    if (!isMobile && !!screen) {
      if (isScreen) {
        const maxScreenShareWidth = Math.floor(fullScreenHeight / DEFAULT_ASPECT_RATIO);
        const offset = !sidebarView ? 168 + 16 : 0;
        const screenShareWidth = Math.min(fullScreenWidth - offset, maxScreenShareWidth);
        setSize(screenShareWidth);
      } else {
        const videoWidth = 168;
        setSize(videoWidth);
      }
    } else {
      const newHeight = Math.floor(fullScreenHeight / 2);
      const newWidth = Math.floor(newHeight / DEFAULT_ASPECT_RATIO);
      setSize(isMobile || fullScreenWidth <= newWidth ? fullScreenWidth : newWidth);
    }
  }, [fullScreenWidth, fullScreenHeight, sidebarView, screen]);

  const setScreenShareOff = useCallback(() => {
    if (daily) {
      daily.stopScreenShare();
    }
  }, [daily]);

  return (
    <Container
      id={participant?.id}
      size={size}
      isLocal={isLocal}
      hide={!participant && isScreen}
      onClick={() => {
        if (!isLocal) toggleControlsVisibility();
      }}
      minimized={!isScreen && screen ? 1 : undefined}
      radius={!!screen}
      border={!isScreen && !!screen && !!sidebarView}
      {...props}
    >
      {participant ? (
        participant.isScreenshare && participant.isLocal ? (
          <LocalScreenShare onClick={() => !!screen && setScreenShareOff()} />
        ) : (
          <TileContent
            participant={participant}
            isLocal={isLocal}
            network={network}
            avatarImg={avatarImg}
            radius={!!screen}
          />
        )
      ) : !isLocal && !isScreen ? (
        <VideoOffContainer minimized={!isScreen && screen ? 1 : undefined}>
          <Unselectable>
            <Heading1
              color={colors.whiteMain}
              style={size === 168 ? { fontSize: fontSizes.size1, margin: margins.size1 } : {}}
            >
              Waiting for {match?.first_name || 'your match'} to join...
            </Heading1>
          </Unselectable>
        </VideoOffContainer>
      ) : null}
    </Container>
  );
};

const Container = styled.div<{
  size: number;
  isLocal?: boolean;
  hide?: boolean;
  minimized?: number;
  radius?: boolean;
  border?: boolean;
}>`
  position: relative;
  display: ${p => (p.hide ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  width: ${p => p.size}px;
  height: ${p => `calc(${p.size}px * ${p.minimized ? 1 : DEFAULT_ASPECT_RATIO})`};
  margin-bottom: ${p => p.minimized && margins.size2};
  transition: ${globalTransitionSettings};
  background-color: ${colors.blackMid};
  border: ${p => p.border && `1px solid ${colors.blackMain}`};
  border-radius: ${p => (p.radius ? '10px' : '0')};

  ${media.mobile} {
    width: ${p => (p.isLocal ? `calc(${p.size}px * 0.33)` : `${p.size}px`)};
    height: ${p => (p.isLocal ? `calc(${p.size}px * 0.33 * 1.2)` : '100%')};
    position: absolute;
    top: ${p => p.isLocal && margins.size3};
    right: ${p => p.isLocal && margins.size3};
    border: ${p => p.isLocal && borders.standard};
    margin-bottom: 0;
    margin-top: ${p => p.isLocal && getIsIOSApp() && margins.size4};
  }
`;

const VideoOffContainer = styled.div<{ minimized?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${colors.blackMid};
  border-radius: ${p => (p.minimized ? '10px' : '0')};
`;

const Unselectable = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
