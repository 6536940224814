import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { FlexColumn } from 'css/css';

import { Partnership } from 'types/partnership-whitelabel';

import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { getPartnershipInfo } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';
import {
  getElliotToken,
  getParameterByName,
  getTokenURLParameter,
  APP_STORE_LINK,
} from 'js/util/util';
import shouldRedirectToWeekly from 'js/util/weekly-redirect';

import {
  DEFAULT_DARK_PARTNERSHIP,
  DEFAULT_LIGHT_PARTNERSHIP,
  WHITELABEL_PARTNERS,
} from './constants';
import { Hero, Footer, SinglePageHero } from './pages';

export const LandingPage: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [copy, setCopy] = useState({
    header: 'Your network is waiting for you.',
    subHeader:
      'We facilitate casual conversations that lead to not-so-casual professional impact. Powered by AI.',
  });
  const [partnership, setPartnership] = useState<Partnership>();

  const passedToken = getTokenURLParameter();
  const hasToken = !!getElliotToken();
  const inviteCode = getParameterByName('invite_code');
  const shouldForwardToApp = getParameterByName('app_forward');
  const commId = getParameterByName('commId') || (inviteCode === 'trends' ? 'trends' : null);
  const getRedirectURL = async () => {
    const shouldWeekly = await shouldRedirectToWeekly();
    return shouldWeekly ? '/weekly' : '/home';
  };

  useMountEffect(() => {
    (async () => {
      // Forward to app store listing
      if (shouldForwardToApp === '1') {
        window.location.href = APP_STORE_LINK;
        return;
      }

      const getPartnershipDict = async (inviteCodeParam: string) => {
        if (inviteCodeParam) {
          const res = await getPartnershipInfo(inviteCodeParam);
          if (res.ok) {
            const { logo, use_dark_landing_page } = res.getJson;
            if (logo) {
              return {
                ...(use_dark_landing_page ? DEFAULT_DARK_PARTNERSHIP : DEFAULT_LIGHT_PARTNERSHIP),
                inviteCode: inviteCodeParam,
                partnerLogo: logo,
              };
            }
          }
        }
        return undefined;
      };

      // Handle custom branded landing pages
      let passedPartnership: Partnership | undefined;
      switch (inviteCode?.toLocaleLowerCase()) {
        case WHITELABEL_PARTNERS.ExecThread?.inviteCode.toLocaleLowerCase():
          passedPartnership = WHITELABEL_PARTNERS.ExecThread;
          break;
        case WHITELABEL_PARTNERS.THub?.inviteCode.toLocaleLowerCase():
          passedPartnership = WHITELABEL_PARTNERS.THub;
          break;
        case WHITELABEL_PARTNERS.WomenOfWeWork?.inviteCode.toLocaleLowerCase():
          passedPartnership = WHITELABEL_PARTNERS.WomenOfWeWork;
          break;
        case WHITELABEL_PARTNERS.CoWrks?.inviteCode.toLocaleLowerCase():
          passedPartnership = WHITELABEL_PARTNERS.CoWrks;
          break;
        case WHITELABEL_PARTNERS.TheWing?.inviteCode.toLocaleLowerCase():
          passedPartnership = WHITELABEL_PARTNERS.TheWing;
          break;
        case WHITELABEL_PARTNERS.MYX?.inviteCode.toLocaleLowerCase():
          passedPartnership = WHITELABEL_PARTNERS.MYX;
          break;
        default:
          if (inviteCode) passedPartnership = await getPartnershipDict(inviteCode);
          break;
      }

      if (passedToken) history.push(`/verify?token=${passedToken}`);
      else if (hasToken && commId) history.push(`/home?community=${commId}`);
      else if (hasToken) getRedirectURL().then(url => history.push(url));
      if (passedPartnership?.copy) setCopy(passedPartnership.copy);

      setPartnership(passedPartnership);
      setLoading(false);
    })();
  });

  if (loading || passedToken || hasToken) return <SVGLoader />;

  return (
    <FlexColumn style={{ height: '100vh', alignItems: 'stretch', justifyContent: 'stretch' }}>
      {partnership ? (
        <Hero copy={copy} partnership={partnership} />
      ) : (
        <SinglePageHero copy={copy} />
      )}
      <Footer />
    </FlexColumn>
  );
};
