import cloneDeep from 'lodash/cloneDeep';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import format from 'string-format';

import { colors } from 'css/css';

import { GroupedInterests } from 'types/interests';
import { Objective } from 'types/objectives';

import { APIFailure } from 'js/components/shared/APIFailure';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { ObjectiveDetails } from 'js/components/shared/objectives/ObjectiveDetails';
import { Objectives } from 'js/components/shared/objectives/ObjectiveSelector';
import { BasicPage, GreyPage } from 'js/components/shared/page-wrappers';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { postUserInfo, PostUserInfoPayload } from 'js/util/api';
import { onboarding } from 'js/util/strings';
import { useResource } from 'js/util/use-resource';
import { getElliotToken, getIsMobile, getTokenURLParameter, lunchclubTypes } from 'js/util/util';
import { useMountEffect } from 'js/util/custom-hooks';

import { StepCities } from './StepCities';
import { StepNameAndCode } from './StepNameAndCode';
import { OnboardingHeader } from './OnboardingHeader';
import { StepProfile } from './Profile';
import { useRegistrationFields } from './use-registration-fields';
import { MobileRegistrationContent } from './MobileRegistrationContent';
import { OnboardingProgressBar } from './OnboardingProgressBar';
import { StepVerifyEmail } from './StepVerifyEmail';

type PagePath = 'hello' | 'city' | 'objectives' | 'objective-details' | 'profile' | 'verification';

interface PageConfig {
  path: PagePath;
  title: string;
  description: string;
  backgroundColor?: string;
}
const PAGES: PageConfig[] = [
  {
    path: 'hello',
    title: '',
    description: '',
  },
  {
    path: 'city',
    title: onboarding.city.title,
    description: onboarding.city.description,
    backgroundColor: colors.blackMain,
  },
  {
    path: 'objectives',
    title: onboarding.objectives.title,
    description: onboarding.objectives.description,
  },
  {
    path: 'objective-details',
    title: onboarding.objectiveDetails.title,
    description: onboarding.objectiveDetails.description,
  },
  {
    path: 'profile',
    title: onboarding.profile.title,
    description: onboarding.profile.description,
  },
];

const RegistrationContent = () => {
  const { pagePath } = useParams<{ pagePath: PagePath }>();
  const pageIndex = PAGES.findIndex(p => p.path === pagePath);

  const { fetchUser } = useUserContextProvider();

  const history = useHistory();
  const [apiFailure, setApiFailure] = useState(false);
  const user = useUser();

  const [selectedObjectiveDetails, setSelectedObjectiveDetails] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [objectives] = useResource<Objective[]>('discover/objectives');
  const [interests] = useResource<GroupedInterests>('discover/interests');
  const [locales] = useResource<any[]>('discover/locales', { include_tz_locales: false });

  const [tempToken, setTempToken] = useState<string>('');

  const requireLinkedIn = true;

  const {
    prefillInviteCode,
    skipVerification,
    values,
    valuesReady,
    setValues,
  } = useRegistrationFields();

  useMountEffect(() => {
    if (user && user.user_type === lunchclubTypes.verified) {
      history.push('/home');
    }
  });

  const submitUserInfo = async (
    info: PostUserInfoPayload,
    opts: { blockRepeatedSubmission?: boolean; next?: () => void } = {},
  ) => {
    if (opts.blockRepeatedSubmission && submitting) {
      return;
    }
    setSubmitting(true);
    setButtonLoading(true);

    let res = null;
    try {
      res = await postUserInfo(info);
    } catch (err) {
      setApiFailure(true);
    }
    if (res && res.ok) {
      if (res.getJson.token) {
        setTempToken(res.getJson.token);
      }
      if (opts.next) {
        opts.next();
      }
    } else {
      setApiFailure(true);
    }
    setButtonLoading(false);
    setSubmitting(false);
  };

  const submitNameAndCode = async (firstName: string, lastName: string, inviteCodeArg: string) => {
    let orgId = 0;
    if (inviteCodeArg === 'dinner_yc') {
      orgId = 9;
    }

    if (prefillInviteCode === 'trends') {
      orgId = 71;
    }

    setValues({ ...values, firstName, lastName });
    await submitUserInfo(
      { firstName, lastName, organizations: orgId ? [orgId] : null },
      {
        next: () => {
          history.push(`/registration/city${history.location.search}`);
        },
      },
    );
  };

  const submitHub = async (hub: { locale: any } | { googlePlaceId: string }) => {
    await submitUserInfo(
      'locale' in hub
        ? { locale: hub.locale.id, done: null }
        : { googlePlaceId: hub.googlePlaceId, autoLocale: true },
      {
        blockRepeatedSubmission: true,
        next: () => history.push(`/registration/objectives${history.location.search}`),
      },
    );
  };

  const submitObjectives = async (showDetails: any) => {
    await submitUserInfo(
      { objectiveIds: values.selectedObjectives.map(obj => obj.id) },
      {
        next: () => {
          setValues({
            ...values,
            selectedObjectives: cloneDeep(values.selectedObjectives).sort(
              (a: any, b: any) => a.id - b.id,
            ),
          });
          history.push(
            showDetails
              ? `/registration/objective-details${history.location.search}`
              : `/registration/profile${history.location.search}`,
          );
        },
      },
    );
  };

  const submitObjectiveDetails = async () => {
    await submitUserInfo(
      { objectiveInfo: selectedObjectiveDetails },
      {
        next: () => {
          history.push(`/registration/profile${history.location.search}`);
        },
      },
    );
  };

  const decideFinalStep = async () => {
    const token = getTokenURLParameter() || getElliotToken();
    setButtonLoading(true);

    const res = await fetchUser();
    const userType = res.user_type;
    if (userType === lunchclubTypes.unverified) {
      if (skipVerification) {
        history.push(format('/?token={0}', token));
      } else {
        history.push('/registration/verification');
      }
    } else if (userType === lunchclubTypes.verified) {
      history.push(format('/?token={0}', token));
    } else {
      setApiFailure(true);
    }
    setButtonLoading(false);
  };

  const submitDone = async () => {
    await submitUserInfo(
      { done: true, skipVerification },
      { blockRepeatedSubmission: true, next: () => decideFinalStep() },
    );
    window.qp('track', 'CompleteRegistration');
  };

  const handlePrev = () => {
    let prev = PAGES[pageIndex - 1].path || 'hello';
    if (prev === 'objective-details') {
      prev = 'objectives';
    }
    history.push(`/registration/${prev}${history.location.search}`);
  };

  if (apiFailure) {
    return (
      <GreyPage>
        <APIFailure />
      </GreyPage>
    );
  }

  const shouldRenderHeader = pageIndex > 0;
  const page = PAGES[pageIndex];

  return (
    <BasicPage showHeader={false}>
      {shouldRenderHeader && (
        <>
          <OnboardingProgressBar titles={onboarding.progressTitles} activeIndex={pageIndex - 1} />
          <OnboardingHeader title={page.title} description={page.description} />
        </>
      )}
      {!valuesReady || !objectives || !locales || !interests ? (
        <SVGLoader />
      ) : pagePath === 'hello' ? (
        <StepNameAndCode
          values={values}
          prefillInviteCode={prefillInviteCode}
          onSubmitCode={submitNameAndCode}
          buttonLoading={buttonLoading}
        />
      ) : pagePath === 'city' ? (
        <StepCities locales={locales} onSubmit={submitHub} />
      ) : pagePath === 'objectives' ? (
        <Objectives
          objectives={objectives}
          selectedObjectives={values.selectedObjectives}
          setSelectedObjectives={obj => setValues({ ...values, selectedObjectives: obj })}
          onSubmit={submitObjectives}
          onPrev={handlePrev}
          buttonLoading={buttonLoading}
        />
      ) : pagePath === 'objective-details' ? (
        <ObjectiveDetails
          objectives={values.selectedObjectives}
          selectedObjectiveDetails={selectedObjectiveDetails}
          setSelectedObjectiveDetails={setSelectedObjectiveDetails}
          onSubmit={submitObjectiveDetails}
          onPrev={handlePrev}
          buttonLoading={buttonLoading}
        />
      ) : pagePath === 'profile' ? (
        <StepProfile
          values={values}
          setValues={setValues}
          interests={interests}
          submitUserInfo={submitUserInfo}
          onSubmit={submitDone}
          onPrev={handlePrev}
          buttonLoading={buttonLoading}
          requireLinkedIn={requireLinkedIn}
        />
      ) : pagePath === 'verification' ? (
        <StepVerifyEmail
          tempTokenDefault={tempToken}
          handleLogin={(token: string) => history.replace(`/verify?token=${token}`)}
        />
      ) : (
        <span />
      )}
    </BasicPage>
  );
};

export default () => {
  const { fetchTestBuckets } = useUserContextProvider();
  const isMobile = getIsMobile();

  useMountEffect(() => {
    fetchTestBuckets();
  });

  return (
    <Switch>
      <Route
        exact
        path="/registration"
        render={(props: any) => <Redirect to={`/registration/hello${props.location.search}`} />}
      />

      <Route
        path="/registration/:pagePath"
        component={isMobile ? MobileRegistrationContent : RegistrationContent}
      />
    </Switch>
  );
};
