import React from 'react';
import { useHistory } from 'react-router-dom';

import { getIsMobile, setElliotToken } from 'js/util/util';
import { Avatar } from 'js/components/shared/Avatar';
import { useDataContext } from 'js/providers/DataContextProvider';

import { settingsSvg, feedbackSvg, FAQSvg, logOutSvg } from 'img/hamburger-menu-v2';

import { BaseMenu } from './BaseMenu';

export interface ProfileMenuItem {
  type?: string;
  icon?: string;
  label: string;
  onClick: () => void;
}

interface Props {
  user: any;
  isSelected: (route: string) => boolean;
}

export const ProfileMenu: React.FC<Props> = ({ user, isSelected }) => {
  const history = useHistory();
  const isMobile = getIsMobile();
  const { setNavbarIconClicked } = useDataContext();

  const menuItems: ProfileMenuItem[] = [
    {
      type: 'profile',
      label: 'Profile',
      onClick: () => {
        if (isSelected('/profile')) {
          setNavbarIconClicked('profile');
        } else {
          history.push('/profile');
        }
      },
    },
    {
      icon: settingsSvg,
      label: 'Settings',
      onClick: () => !isSelected('/settings') && history.push('/settings'),
    },
    {
      icon: feedbackSvg,
      label: 'Give feedback',
      onClick: () => {
        window.location.href = 'mailto:support+product@lunchclub.ai';
      },
    },
    {
      icon: FAQSvg,
      label: 'FAQ',
      onClick: () => !isSelected('/faq') && history.push('/faq'),
    },
    {
      icon: logOutSvg,
      label: 'Log out',
      onClick: () => {
        setElliotToken(null);
        history.push(window.bridge ? '/mobile-root' : '/');
        window.location.reload();
      },
    },
  ];

  return (
    <BaseMenu title="Profile" menuItems={menuItems} user={user} isExpandable>
      <Avatar
        size1={!isMobile}
        ownProfile
        src={user?.image}
        alt={`${user?.first_name}'s profile picture`}
        style={{
          margin: '0',
          border: '2px solid white',
          minWidth: 32,
          maxWidth: 32,
          minHeight: 32,
          maxHeight: 32,
        }}
      />
    </BaseMenu>
  );
};
