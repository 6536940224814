import { useCallback, useEffect, useState } from 'react';
import { DailyCall, DailyEventObjectNetworkQualityEvent } from '@daily-co/daily-js';

import { useCallV2Context, VideoQuality } from '../contexts/CallContext';

export type NetworkState = 'good' | 'low' | 'very-low';

const STANDARD_HIGH_BITRATE_CAP = 980;
const STANDARD_LOW_BITRATE_CAP = 300;

type Props = {
  co: DailyCall | null;
  quality: VideoQuality;
};

export type TUseNetworkState = NetworkState;

export const useNetworkState = ({ co = null, quality = 'high' }: Props): TUseNetworkState => {
  const [threshold, setThreshold] = useState<NetworkState>('good');

  const { daily } = useCallV2Context();

  const callObject = co ?? daily;

  const setQuality = useCallback(
    (q: VideoQuality) => {
      if (!callObject || typeof rtcpeers === 'undefined') return;
      const peers = Object.keys(callObject.participants()).length - 1;
      // @ts-ignore
      const isSFU = rtcpeers?.currentlyPreferred?.typeName?.() === 'sfu';
      const lowKbs = isSFU
        ? STANDARD_LOW_BITRATE_CAP
        : STANDARD_LOW_BITRATE_CAP / Math.max(1, peers);

      switch (q) {
        case 'high':
          callObject.setBandwidth({ kbs: STANDARD_HIGH_BITRATE_CAP });
          break;
        case 'low':
          callObject.setBandwidth({
            kbs: lowKbs,
          });
          break;
        case 'bandwidth-saver':
          callObject.setLocalVideo(false);
          callObject.setBandwidth({
            kbs: lowKbs,
          });
          break;
        default:
          break;
      }
    },
    [callObject],
  );

  const handleNetworkQualityChange = useCallback(
    (event?: DailyEventObjectNetworkQualityEvent) => {
      if (event?.threshold === threshold) return;

      switch (event?.threshold) {
        case 'very-low':
          setQuality('bandwidth-saver');
          setThreshold('very-low');
          break;
        case 'low':
          setQuality(quality === 'bandwidth-saver' ? quality : 'low');
          setThreshold('low');
          break;
        case 'good':
          setQuality(['bandwidth-saver', 'low'].includes(quality) ? quality : 'high');
          setThreshold('good');
          break;
        default:
          break;
      }
    },
    [callObject, threshold, quality],
  );

  useEffect(() => {
    if (!callObject) return () => undefined;
    callObject.on('network-quality-change', handleNetworkQualityChange);
    return () => {
      callObject.off('network-quality-change', handleNetworkQualityChange);
    };
  }, [callObject, handleNetworkQualityChange]);

  useEffect(() => {
    setQuality(quality);
  }, [quality, setQuality]);

  return threshold;
};
