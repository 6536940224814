import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { Card, Heading3, SubText, colors, margins, globalTransitionSettings, media } from 'css/css';

type HeaderState = 'Hidden' | 'Displayed' | 'Exiting';

export const HeaderCard = ({
  heading,
  desc,
  autoCloseTime = 10000,
  openHeader,
  setOpenHeader,
  closeHeader,
  setCloseHeader,
}: {
  heading: string;
  desc: string;
  autoCloseTime?: number;
  openHeader?: (continuation?: () => void) => void;
  setOpenHeader: React.Dispatch<
    React.SetStateAction<((continutation?: () => void) => void) | undefined>
  >;
  closeHeader?: (continuation?: () => void) => void;
  setCloseHeader: React.Dispatch<
    React.SetStateAction<((continutation?: () => void) => void) | undefined>
  >;
}) => {
  const [state, setState] = useState<HeaderState>('Hidden');
  const [closeHeaderTimeoutId, setCloseHeaderTimeoutId] = useState<NodeJS.Timer>();
  const [currentHeading, setCurrentHeading] = useState(heading);
  const [currentDesc, setCurrentDesc] = useState(desc);

  useEffect(() => {
    if (openHeader) {
      if (heading !== currentHeading) {
        openHeader(() => {
          setCurrentHeading(heading);
          setCurrentDesc(desc);
        });
      } else {
        setCurrentDesc(desc);
      }
    }
  }, [heading, desc]);

  useEffect(() => {
    const currentCloseHeader = (continuation?: () => void) => {
      if (closeHeaderTimeoutId !== undefined) {
        clearTimeout(closeHeaderTimeoutId);
      }
      setState('Exiting');
      setTimeout(() => {
        setState('Hidden');
        if (continuation) {
          continuation();
        }
      }, 200);
    };
    setOpenHeader(() => (continuation?: () => void) => {
      const displayHeader = () => {
        setState('Displayed');
        setCloseHeaderTimeoutId(setTimeout(() => currentCloseHeader(), autoCloseTime));
        if (continuation) {
          continuation();
        }
      };

      if (state === 'Displayed') {
        currentCloseHeader(() => setTimeout(displayHeader, 200));
      } else {
        if (closeHeaderTimeoutId !== undefined) {
          clearTimeout(closeHeaderTimeoutId);
        }
        displayHeader();
      }
    });
    setCloseHeader(() => currentCloseHeader);
  }, [state, closeHeaderTimeoutId]);

  return (
    <HeaderCardContainer
      onClick={() => {
        if (closeHeader) {
          closeHeader();
        }
      }}
      state={state}
    >
      <Heading3
        color={colors.blackMain}
        style={{ marginBottom: margins.size1, userSelect: 'none' }}
      >
        {currentHeading}
      </Heading3>
      <SubText
        color={colors.blackMid}
        style={{ userSelect: 'none' }}
        dangerouslySetInnerHTML={{ __html: currentDesc }}
      />
    </HeaderCardContainer>
  );
};

const MAX_HEADER_CARD_WIDTH = 504; // This is the width of UserDescriptionBox on desktop
const HEADER_MARGIN = parseInt(margins.size2, 10);

const HeaderCardContainer = styled(Card)<{ state: HeaderState }>`
  position: absolute;
  text-align: left;
  background: ${colors.whiteMain};
  padding: ${margins.size3};
  border-radius: 10px;
  border: 1px solid ${colors.greyLight};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  transition: ${globalTransitionSettings};
  transform: ${p => (p.state === 'Hidden' ? 'translateY(-150%)' : 'translateY(0)')};
  opacity: ${p => (p.state === 'Displayed' ? 1 : 0)};
  z-index: 2;
  ${media.mobile} {
    top: calc(env(safe-area-inset-top) + ${HEADER_MARGIN}px);
    left: 0;
    width: calc(100% - ${HEADER_MARGIN * 2}px);
    margin: 0px ${HEADER_MARGIN}px;
  }
  ${media.desktop} {
    top: calc(env(safe-area-inset-top) + ${HEADER_MARGIN}px + ${margins.size6});
    left: calc(50% - ${MAX_HEADER_CARD_WIDTH / 2}px);
    width: ${MAX_HEADER_CARD_WIDTH}px;
    margin: 0px;
    cursor: pointer;
  }
`;
