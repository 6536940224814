import { backOff } from 'exponential-backoff';

import { FeedbackQuestionType, QuestionIds, PrevFeedback } from 'types/feedback';
import { ProductUser, UserMe } from 'types/user';
import { availability } from 'types/availability';
import { Contact, Invitee, MobileContactInvitee, MobileInvite } from 'types/invites';
import { LunchclubMatch, NetworkUser } from 'types/matches';
import { BackendMessage } from 'types/channel';
import { Endorsement } from 'types/endorsements';

import { TopInviterInfo } from 'js/components/super-inviters';
import { InviteTreeUser } from 'js/components/invite-tree';
import { Connection } from 'js/components/connections/types';
import {
  DiscoverBookingCard,
  FeedAction,
  MinimalEndorsementCard,
  MinimalUserDict,
} from 'js/components/homepage/types';

import { URL, getTokenURLParameter, getElliotToken, NETWORK_TYPES, getIsIOSApp } from './util';

type EmptyObj = Record<string, never>;

export const apiFetch = async <T>(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  path: string,
  data: any,
  token: string | null = null,
): Promise<{
  ok: boolean;
  isClientError: boolean;
  status: number;
  getJson: { error?: string } & T; // BG: This shold really be `{error: string} | T`
}> => {
  try {
    const response = await backOff(
      async () => {
        const res = await fetch(`${URL}/${path}`, {
          method,
          headers: {
            'Content-Type': 'application/json',
            'auth-token': token || getElliotToken(),
          },
          body: data ? JSON.stringify(data) : undefined,
        });

        // Retry 5xx errors
        if (!res || res.status.toString()[0] === '5') throw Error(`${res?.status}`);
        else return res;
      },
      {
        numOfAttempts: 3,
      },
    );

    return {
      ok: response.ok,
      isClientError: response.status >= 400 && response.status <= 499,
      status: response.status,
      getJson: await response.json(),
    };
  } catch (e) {
    // @ts-ignore
    return e;
  }
};

export const apiGet = <T>(path: string, token: string | null = null) =>
  apiFetch<T>('GET', path, null, token);

export const apiPost = <T>(path: string, data: any, token: string | null = null) =>
  apiFetch<T>('POST', path, { ...data, is_ios_app: getIsIOSApp() }, token);

export const getAvailability = () => apiGet<availability>(`discover/user/concierge_availability`);

interface PostAvailabilityType {
  timeslots?: any;
  locale?: number | null;
  neighborhoods?: number[];
  numberOfMeetings?: number;
  objective?: number | null;
  pickMatchRole?: number | null;
  canCrossCity?: boolean;
  preferSecondDegree?: boolean;
  preferTheseCommunities?: any;
  targetLocale?: number | null;
  meetingLength?: number | null;
  targetInterest?: number | null;
  targetUser?: string | null;
  satSession?: boolean;
  slantOptIn?: boolean | null;
  autopilot?: boolean;
  autopilotCadence?: number;
}

export const postAvailability = ({
  timeslots = [],
  locale = null,
  neighborhoods = [],
  numberOfMeetings = 1,
  objective = null,
  pickMatchRole = null,
  canCrossCity = true,
  preferTheseCommunities = null,
  targetLocale = null,
  meetingLength = null,
  targetInterest = null,
  targetUser = null,
  satSession = false,
  slantOptIn = null,
  autopilot = false,
  autopilotCadence = 1,
}: PostAvailabilityType) => {
  return apiPost<any>(`discover/user/concierge_availability`, {
    time_slots: timeslots,
    locale,
    neighborhoods,
    max_matches: numberOfMeetings,
    objective,
    target_role: pickMatchRole,
    can_cross_city: canCrossCity ? 1 : 0,
    organization_ids:
      preferTheseCommunities && preferTheseCommunities.length ? preferTheseCommunities : null,
    target_locale: targetLocale,
    meeting_length: meetingLength,
    target_interest: targetInterest,
    target_user_public_id: targetUser,
    session_signup: satSession,
    slant_opt_in: slantOptIn,
    autopilot,
    autopilot_cadence: autopilotCadence,
  });
};

export interface PostAutopilotCadenceType {
  cadence: number;
}

export const postAutopilotCadence = ({ cadence = 1 }: PostAutopilotCadenceType) => {
  return apiPost<any>(`discover/user/autopilot/cadence`, {
    cadence,
  });
};

export const postInviteCode = (code: any) =>
  apiPost<any>('discover/user/invite_code', {
    invite_code: code,
  });

export const getUserInfo = () => apiGet<UserMe>('discover/user/me');

export const getInterests = () => apiGet<any>('discover/interests');

export const getObjectives = () => apiGet<any>('discover/objectives');

interface FeedbackV2 {
  question_ids: QuestionIds;
  questions: FeedbackQuestionType[];
  prev_feedback: PrevFeedback;
  is_in_person: boolean;
  has_want_to_meet_similar: boolean;
  version: number;
}

export const getFeedbackV2 = (feedbackCode: string) =>
  apiGet<FeedbackV2>(`discover/feedback/v2?feedback_code=${feedbackCode}`);

export const getAutoCompletedCities = (query: string) =>
  apiGet<any>(`discover/places/city_autocomplete?query=${query}`);

export const getCityFromLatLong = (lat: number, lng: number) =>
  apiGet<any>(`discover/places/city?lat=${lat}&lng=${lng}`);

export const getCityInfo = (googlePlaceId: string) =>
  apiGet<any>(`discover/places/city_info?google_place_id=${googlePlaceId}`);

export interface PostProfileFields {
  firstName: string;
  lastName: string;
  headline: string;
  title: string;
  company: string;
  likeToLearn: string;
  askMeAbout: string;
  topOfMind: string;
  justLearned: string;
  sideHustle: string;
  primaryEmail: string;
  secondaryEmail: string;
  publicConversations: boolean;
  publicConnections: boolean | number;
  newYearGoals: string[];
}

export type PostUserInfoPayload = Partial<PostProfileFields> &
  Partial<{
    locale: number;
    neighborhoodIds: string;
    googlePlaceId: string;
    autoLocale: boolean;
    customLocale: string;
    objectiveIds: number[];
    objectiveInfo: any[];
    interests: string[];
    profileUrls: {
      [key: string]: string;
    };
    isAmbassador: boolean;
    organizations: number[] | null;
    done: null | true;
    showCurrencyNux: boolean;
    skipVerification: boolean;
    removeImage: boolean;
    snoozeUntil: string;
    isInvoluntaryInvitesDisabled: boolean;
    isReconnectPreschedulerDisabled: boolean;
    visualSettings: any;
    isFromApp: boolean;
    pauseAutopilotUntil: string | null;
  }>;

export const postUserInfo = (payload: PostUserInfoPayload) =>
  apiPost<any>('discover/user/me', {
    profile: {
      first_name: payload.firstName,
      last_name: payload.lastName,
      headline: payload.headline,
      title: payload.title,
      company: payload.company,
      ask_me_about: payload.askMeAbout,
      just_learned: payload.justLearned,
      like_to_learn: payload.likeToLearn,
      side_hustle: payload.sideHustle,
      top_of_mind: payload.topOfMind,
      primary_email: payload.primaryEmail,
      secondary_email: payload.secondaryEmail,
      public_conversations: payload.publicConversations,
      public_connections: payload.publicConnections,
    },
    locale: payload.locale,
    neighborhood_ids: payload.neighborhoodIds,
    google_place_id: payload.googlePlaceId,
    auto_locale: payload.autoLocale,
    custom_locale: payload.customLocale,
    objective_ids: payload.objectiveIds,
    objective_info: payload.objectiveInfo,
    interests: payload.interests,
    new_year_goals: payload.newYearGoals,
    profile_urls: payload.profileUrls,
    is_ambassador: payload.isAmbassador,
    organizations: payload.organizations,
    done: payload.done,
    show_currency_nux: payload.showCurrencyNux,
    skip_verification: payload.skipVerification,
    remove_image: payload.removeImage,
    snooze_until: payload.snoozeUntil,
    visual_settings: payload.visualSettings,
    is_involuntary_invites_disabled: payload.isInvoluntaryInvitesDisabled,
    is_reconnect_prescheduler_disabled: payload.isReconnectPreschedulerDisabled,
    is_from_app: payload.isFromApp,
    autopilot_paused_until: payload.pauseAutopilotUntil,
  });

export const postDeleteUser = ({
  selectedReasons,
  notifyFixed,
}: {
  selectedReasons: number[];
  notifyFixed: boolean;
}) =>
  apiPost('discover/user/me', {
    delete_user: true,
    delete_reason_ids: selectedReasons,
    notify_when_fixed: notifyFixed,
  });
interface MatchInfo {
  match: NetworkUser;
  user: NetworkUser;
}
export const getMatchInfo = (feedbackCode: any) =>
  apiGet<MatchInfo>(`discover/match_feedback/info?feedback_code=${feedbackCode}`);

export const getMatchFeedback = () => apiGet<LunchclubMatch[]>('discover/user/match_feedback');

export const postMatchFeedback = ({
  feedbackCode,
  hadMeeting,
  starRating,
  objectivesFeedback,
  textFeedback,
  npsScore,
  didCommunicate,
  rescheduled,
  userReachedOut,
  matchReachedOut,
  responses,
  privateNote,
  successStory,
  shareStory,
  isStarClickPost,
  wantToMeetSimilar,
}: any) =>
  apiPost<any>('discover/user/match_feedback', {
    had_meeting: hadMeeting,
    star_rating: starRating,
    want_to_meet_similar: wantToMeetSimilar,
    objectives_feedback: objectivesFeedback,
    text_feedback: textFeedback,
    private_note: privateNote,
    want_to_invite: npsScore,
    did_communicate: didCommunicate,
    rescheduled,
    user_reached_out: userReachedOut,
    match_reached_out: matchReachedOut,
    feedback_details: responses,
    success_story: successStory,
    share_story: shareStory,
    feedback_code: feedbackCode,
    is_star_click_post: isStarClickPost,
  });

export const postEditMatchFeedback = ({ feedbackCode, key, value }: any) =>
  apiPost<any>('discover/match_feedback/edit', {
    feedback_code: feedbackCode,
    key,
    value,
  });

export const postActivity = (screen_name: string, screen_params: string) => {
  const tempToken = getTokenURLParameter();
  const token = getElliotToken();
  return apiPost(
    'discover/activity',
    {
      screens: [
        {
          screen_name,
          screen_params,
          app_platform: window.bridge?.platform,
          app_version: window.bridge?.version,
        },
      ],
    },
    token || tempToken,
  );
};

export const postPushPermissionChange = async (authorized: boolean) => {
  const tempToken = await getTokenURLParameter();
  const token = getElliotToken();
  return apiPost('discover/push_notif/permission', { authorized }, token || tempToken);
};

export const postTestActivity = async (
  name: string,
  params: string,
  feedbackCode: string | undefined = undefined,
) => {
  const tempToken = getTokenURLParameter();
  const token = getElliotToken();
  return apiPost(
    'discover/test_activity',
    {
      logs: [{ name, params }],
      feedback_code: feedbackCode,
    },
    token || tempToken,
  );
};

export const postPingServer = () => {
  const tempToken = getTokenURLParameter();
  const token = getElliotToken();
  if (tempToken || token)
    apiPost(
      'discover/ping',
      { app_platform: window.bridge?.platform, app_version: window.bridge?.version },
      token || tempToken,
    );
};

export const postMobileFeedback = (response: number, feedback?: string) =>
  apiFetch('POST', `discover/mobile/feedback`, { response, feedback });

export const getMatchFeedbackCode = () => apiGet<any>('discover/match_feedback/code');

interface GetInviteesParams {
  inviteeNum: number;
  excludeEmails?: string[];
  isShuffling?: boolean;
}

export const getInvitees = ({
  inviteeNum,
  excludeEmails = [],
  isShuffling = false,
}: GetInviteesParams) => {
  return apiPost<Invitee[]>('discover/invite_suggestions', {
    num_invitees: inviteeNum,
    exclude_emails: excludeEmails,
    is_shuffling: isShuffling,
  });
};

export const getMobileContactsInvitees = (
  numSuggestions: number,
  excludePhoneNumbers: string[] = [],
) => {
  return apiPost<MobileContactInvitee[]>('discover/mobile_contacts/invite_suggestions', {
    num_suggestions: numSuggestions,
    exclude_phone_numbers: excludePhoneNumbers,
  });
};

export const updateMobileContactsInvitee = (phoneNumber: string, response: number) => {
  return apiPost<MobileInvite>('discover/mobile_contacts/update_invite', {
    phone_number: phoneNumber,
    response,
  });
};

export const getPastInvitees = () => apiGet<any>('discover/user/invitees');

export const postInvitees = ({
  emails,
  source,
  reminder = false,
  token = null,
  publicProfileId = null,
  note = null,
  communityId = null,
  introSuggestionId = null,
}: any) =>
  apiPost<any>(
    'discover/invitees',
    {
      emails,
      is_reminder: reminder,
      source,
      profile_public_id: publicProfileId,
      note,
      community_id: communityId,
      intro_suggestion_id: introSuggestionId,
    },
    token,
  );

export const postDeclineInvitees = (emails: any, token = null) =>
  apiPost<any>(
    'discover/invitees/decline',
    {
      emails,
    },
    token,
  );

interface PostUserType {
  email: string;
  specialCode: string | null;
}
export const postUser = ({ email, specialCode = null }: PostUserType) =>
  apiPost<any>('discover/concierge/user', {
    email,
    special_code: specialCode,
  });

export const postAuth = ({
  gauthCode,
  isRegistering = false,
  isNewUser = false,
  isMobile = false,
}: any) =>
  apiPost<any>('discover/concierge/auth', {
    gauth_code: gauthCode,
    is_registering: Boolean(isRegistering),
    is_new_user: Boolean(isNewUser),
    is_mobile: Boolean(isMobile),
  });

interface VerifyUser {
  email: string | null;
  allow_skip: boolean | null;
}
export const verifyUser = (token: string | null = null) =>
  apiPost<VerifyUser>('discover/verify', {}, token);

type UnsubscribeTypeIds = 1 | 2;
export const getUserUnsubscriptions = () =>
  apiGet<UnsubscribeTypeIds[]>(`discover/user/unsubscribe`);

export const getUserUnsubscriptionsMobile = () =>
  apiGet<Record<string, number[]>>(`discover/unsubscribe/schema`);

export const postUserUnsubscriptionsMobile = (unsubscribeIds: Record<string, number[]>) =>
  apiPost(`discover/unsubscribe/schema`, unsubscribeIds);

interface UnsubscribeUserParams {
  token?: string | null;
  typeId?: number; // If not provided backend defaults to full unsubscribe (0).
}
export const postUserUnsubscribe = ({ typeId = 0, token = null }: UnsubscribeUserParams) =>
  apiPost(
    'discover/user/unsubscribe',
    {
      type: typeId,
    },
    token,
  );

interface ResubscribeUserParams {
  typeId?: number; // If not provided backend defaults to full resubscribe.
}
export const postUserResubscribe = ({ typeId }: ResubscribeUserParams) =>
  apiPost('discover/user/resubscribe', { type: typeId });

export interface UnsubscribeTypes {
  [key: string]: {
    title: string;
    subtitle: string;
  };
}
export const getUnsubscribeTypes = () => apiGet<UnsubscribeTypes>(`discover/unsubscribe_types`);

export const postUserImage = async (data: any) =>
  fetch(`${URL}/discover/user/image`, {
    method: 'POST',
    headers: {
      'auth-token': getElliotToken(),
    },
    body: data,
  }).then(async response => ({
    ok: response.ok,
    status: response.status,
    getJson: await response.json(),
  }));

export const getUserContacts = (match?: string) =>
  apiGet<Contact[]>('discover/user/contacts', match);

export const getWeeklyQuestion = () => apiGet<any>('/discover/user/weekly_question');

export const postWeeklyQuestion = ({ questionId, responseId }: any) =>
  apiPost<any>('/discover/user/weekly_question', {
    question_id: questionId,
    response_id: responseId,
  });

interface PostClubpointsAvailability {
  maxMatches: number | null;
  targetRole: number | null;
  targetLocale?: number | null;
  targetUser?: string | null;
  notEnoughPoints: number | null;
}

export const postClubpointsAvailability = ({
  maxMatches = null,
  targetRole = null,
  targetLocale = null,
  targetUser = null,
  notEnoughPoints = null,
}: PostClubpointsAvailability) =>
  apiPost<any>('discover/premier_availability', {
    target_role: targetRole,
    max_matches: maxMatches,
    target_locale: targetLocale,
    target_user_public_id: targetUser,
    not_enough_points: notEnoughPoints,
  });

export const postUserLogin = ({ email, isFromApp }: { email: string; isFromApp: boolean }) =>
  apiPost<{ token: string }>('discover/login', { email, is_from_app: isFromApp });

export const getUserProfile = (profileId: string) =>
  apiGet<ProductUser>(`/discover/user/profile?profile_id=${profileId}`);

export const getDashboardData = () => apiGet<any>('/discover/dashboard');

interface postProfileResponseParams {
  profileId: string;
  source: string;
  reject?: boolean;
}
export const postProfileResponse = ({ profileId, source, reject }: any) =>
  apiPost<postProfileResponseParams>('/discover/profile_response', {
    public_id: profileId,
    rating: reject ? 2 : 4,
    source,
  });

export const postContentCardResponse = ({
  contentId,
  response,
}: {
  contentId: number;
  response: number;
}) =>
  apiPost<any>('/discover/content_card_response', {
    content_id: contentId,
    response,
  });

type PostEndorsementSuggestion = {
  suggestionId: number;
  isAccepted: boolean;
  source: string;
};
interface PostEndorsementSuggestionParams extends PostEndorsementParams {
  source: string;
}

export const postEndorsementSuggestion = ({
  suggestionId,
  isAccepted,
  source,
}: PostEndorsementSuggestion) =>
  apiPost<PostEndorsementSuggestionParams>('/discover/endorsement_suggestion', {
    id: suggestionId,
    accepted: isAccepted,
    source,
  });

type PostEndorsement = {
  endorsementId: number;
  isAccepted: boolean;
};

type PostEndorsementParams = {
  id: number;
  accepted: boolean;
};

export const getEndorsement = () => apiGet<{ endorsements: Endorsement[] }>('discover/endorsement');

export const postEndorsement = ({ endorsementId, isAccepted }: PostEndorsement) =>
  apiPost<PostEndorsementParams>('/discover/endorsement', {
    id: endorsementId,
    accepted: isAccepted,
  });

export type AllowedEndorsements = {
  user: MinimalUserDict;
  categories: { num: number; name: string }[];
};

export const getAllowedEndorsements = () =>
  apiGet<AllowedEndorsements[]>('discover/manual_endorsement');

type SendEndorsement = {
  publicId: string;
  categoryNum: number;
};

type SendEndorsementParams = {
  profile_id: string;
  category: number;
};

export const sendEndorsement = ({ publicId, categoryNum }: SendEndorsement) =>
  apiPost<SendEndorsementParams>('discover/manual_endorsement', {
    profile_id: publicId,
    category: categoryNum,
  });

export const postAcceptPreschedule = ({
  prescheduleId,
  matchCode,
}: {
  prescheduleId: number;
  matchCode: string;
}) =>
  apiPost<any>('/discover/prescheduled_reconnect/confirm', {
    match_code: matchCode,
    preschedule_id: prescheduleId,
  });

export const getSpecialInvite = (code: any) => apiGet<any>(`/discover/special_invite?code=${code}`);

export const getUserProfileShuffle = (profileId: any) =>
  apiGet<any>(`discover/user/profile/shuffle?profile_id=${profileId}`);

export const getFeed = (version?: number) =>
  apiGet<any>(`/discover/homepage/feed?version=${version !== undefined ? version : 2}`);

export const getEndorsementFeed = (limit: number, isRegularFeed: boolean) =>
  apiGet<{ cards: MinimalEndorsementCard[] }>(
    `discover/endorsement_suggestion?limit=${limit}&is_feed=${isRegularFeed}`,
  );

export const postIntroResponse = ({
  suggestionId,
  responseId,
}: {
  suggestionId: number;
  responseId: number;
}) =>
  apiPost<EmptyObj>('/discover/intro_suggestion_response', {
    suggestion_id: suggestionId,
    response_id: responseId,
  });

type DiscoverBookingSuggestion =
  | { status: 'NOT_FOUND' }
  | { status: 'NOT_ENOUGH_CARDS' }
  | { status: 'FOUND'; card: DiscoverBookingCard; is_first_card: boolean };

type DiscoverBookingConfirmation =
  | { status: 'ALREADY_RESPONDED' }
  | { status: 'CONFIRMED_MATCH'; match_code: string };

type DiscoverBookingSkip = { status: 'ALREADY_RESPONDED' } | { status: 'SKIPPED' };

type DiscoverBookingReservationRenewal = { status: 'ALREADY_RESPONDED' } | { status: 'RENEWED' };

export const getDiscoverBookingSuggestion = () =>
  apiGet<DiscoverBookingSuggestion>(`discover/discover_booking/get_suggestion`);

export const confirmDiscoverBookingMatch = ({
  suggestionId,
  timeSlot,
}: {
  suggestionId: number;
  timeSlot: string;
}) =>
  apiPost<DiscoverBookingConfirmation>('discover/discover_booking/confirm_match', {
    suggestion_id: suggestionId,
    time_slot: timeSlot,
  });

export const renewDiscoverBookingReservation = ({ suggestionId }: { suggestionId: number }) =>
  apiPost<DiscoverBookingReservationRenewal>('discover/discover_booking/renew_reservation', {
    suggestion_id: suggestionId,
  });

export const skipDiscoverBookingSuggestion = ({ suggestionId }: { suggestionId: number }) =>
  apiPost<DiscoverBookingSkip>('discover/discover_booking/skip_suggestion', {
    suggestion_id: suggestionId,
  });

export const postFriendFinderResponse = ({
  publicId = null,
  email = null,
  response,
}: {
  publicId?: string | null;
  email?: string | null;
  response: number;
}) =>
  apiPost<any>('discover/friend_finder_response', {
    public_id: publicId,
    email,
    response,
  });

export const postImpressions = ({ impressions }: any) =>
  apiPost<any>('discover/impression_activity', {
    impressions,
  });

export const postFeedAction = (deckCardId: number, action: FeedAction) => {
  const { type: actionType, ...actionDetails } = action;
  apiPost<any>(`/discover/homepage/feed/action`, {
    deck_card_id: deckCardId,
    action_type: actionType,
    action_details: actionDetails,
  });
};

export const postLikeEvent = ({ cardId, liked }: any) =>
  apiPost<any>('discover/homepage/feed/like', {
    card_id: cardId,
    liked,
  });

export const postDeleteGoogleAccount = ({ email }: any) =>
  apiPost<any>('discover/google/revoke', {
    email,
  });

interface postDiscoverOutreachResponseParams {
  candidateId: number;
  source: string;
  accepted: boolean;
}
export const postDiscoverOutreachResponse = ({
  candidateId,
  source,
  accepted,
}: postDiscoverOutreachResponseParams) =>
  apiPost<any>('discover/discover_outreach_response', {
    candidate_id: candidateId,
    source,
    accepted,
  });

export const postSearchUsers = ({ query }: { query: string }) =>
  apiPost<any>('discover/search_users', {
    query,
  });

interface CallInfo {
  match: ProductUser;
  room_name: string;
  feedback_code: string;
  time_slot: string;
  is_v2: boolean;
  error?: 'Invalid match id' | 'Room has expired' | 'Not allowed to create room for given match id';
}
export const getCallInfo = (matchId: string) =>
  // Split hotfix because sometimes emails add weird MIME formatting
  apiGet<CallInfo>(`discover/call_info?match_code=${matchId.substring(0, 10)}`);

interface CurrentMatch {
  first_name: string;
  match_code: string;
  is_e2e: boolean;
}
export const getCurrentMatch = () => apiGet<CurrentMatch>(`discover/current_match`);

export const getBusyTimeslots = () => apiGet<string[]>(`discover/user/busy_concierge_timeslots`);

interface postFeedDeckAnswerParams {
  cardId: string;
  status: boolean;
}
export const postFeedDeckAnswer = ({ cardId, status }: postFeedDeckAnswerParams) =>
  apiPost<any>('discover/homepage/feed/deck_answer', {
    card_id: cardId,
    status,
  });

interface Connections {
  leaderboard: Connection[];
  num_meetings: number;
}
export const getConnections = () => apiGet<Connections>('/discover/connections');

export const getChatToken = () => apiGet<{ token: string }>('discover/chat/token');

interface ConversationResponse {
  name: string;
  first_name: string;
  last_name: string;
  met_date: string;
  match_code: string | null;
  image: string;
  role: string | null;
  company: string | null;
  locale: string;
  headline: string;
  profile: string;
  last_updated: string;
  conversation_name?: string;
  preview?: string;
  is_unread?: boolean;
  lc_messages: BackendMessage[];
  blocked: boolean;
  can_reschedule_match: boolean;
  user_event_status: number;
  match_user_event_status: number;
  should_show_reconnect_header: boolean;
  is_in_person_match: boolean;
  is_slant_match: boolean;
  is_morpheus_match?: boolean;
  is_match_previously_rescheduled: boolean;
  reconnect_header_source: number | null;
  network_type: NETWORK_TYPES;
  prescheduled_reconnect_date?: string;
}

export const getChannels = () =>
  apiGet<{
    conversations: ConversationResponse[];
  }>('discover/chat/conversations');

export const postChatCreateConversation = (partnerId: string) =>
  apiPost<{ conversation_name: string }>('discover/chat/create_conversation', {
    other_profile_id: partnerId,
  });

interface InviteTree {
  total_meetings: number;
  total_invited?: number;
  invite_tree?: InviteTreeUser;
}
export const getInviteTree = (statsOnly = false) =>
  apiGet<InviteTree>(`discover/user/invite_tree?stats_only=${statsOnly}`);

interface TopInviters {
  locale: string;
  leaderboard: TopInviterInfo[];
  user_info: TopInviterInfo;
}
export const getTopInviters = () => apiGet<TopInviters>(`discover/top_inviters`);

interface PostCallFeedbackParams {
  matchCode: string;
  videoProblem: boolean;
  audioProblem: boolean;
  matchProblem: boolean;
  problemExplanation: string;
}
export const postCallFeedback = ({
  matchCode,
  videoProblem,
  audioProblem,
  matchProblem,
  problemExplanation,
}: PostCallFeedbackParams) =>
  apiPost('/discover/call/feedback', {
    match_code: matchCode,
    video_problem: videoProblem,
    audio_problem: audioProblem,
    match_problem: matchProblem,
    problem_explanation: problemExplanation,
  });

export interface DeletionReason {
  id: number;
  name: string;
}
export const getDeleteReasons = () =>
  apiGet<{ product_reasons: DeletionReason[]; meeting_reasons: DeletionReason[] }>(
    'discover/delete_reasons',
  );

export const confirmRescheduleMeeting = ({
  matchId,
  timeslot,
}: {
  matchId: string;
  timeslot: string;
}) =>
  apiPost('discover/chat/confirm_reschedule', {
    match_code: matchId,
    rescheduled_timeslot: timeslot,
  });

export const convertTimeslots = ({
  timeslots,
  profileId,
}: {
  timeslots: string[];
  profileId?: string;
}) =>
  apiPost<{ timeslots: string[] }>('discover/chat/convert_timeslots', {
    timeslots,
    profile_id: profileId,
  });

export const getSchedulerBusyTimeslots = () =>
  apiGet<string[]>('discover/chat/reschedule/busy_timeslots');

export const confirmReconnectMeeting = ({
  matchId,
  timeslot,
  source,
}: {
  matchId: string;
  timeslot: string;
  source: number | null;
}) =>
  apiPost('discover/reconnect/confirm', {
    match_code: matchId,
    timeslot,
    source,
  });

export const createOrActivateToken = async (
  refreshToken: string,
  isFromApp: boolean,
  accessCode: string,
) =>
  apiPost<{ auth_token: string }>('discover/user/access_token', {
    refresh_token: refreshToken,
    is_from_app: isFromApp,
    access_code: accessCode,
  });

interface postQuestionResponseParams {
  questionId: number;
  optionId: number | null;
}

const postQuestionResponse = (path: string) => ({
  questionId,
  optionId,
}: postQuestionResponseParams) =>
  apiPost<any>(path, {
    question_id: questionId,
    option_id: optionId,
  });

export const postPersonalityQuestionResponse = postQuestionResponse(
  'discover/personality_question_response',
);

export const postSurveyQuestionResponse = postQuestionResponse('discover/survey_question_response');

export type PersonalityType = 'Drive' | 'Influence' | 'Support' | 'Clarity';

export interface PersonalityTestResults {
  scores: { [key: string]: number };
  personality_type: PersonalityType;
  same_personality_type: number;
  mostly_met?: string;
  mostly_met_letter?: string;
  mostly_met_percent?: number;
}

export const getPersonalityTestResults = () =>
  apiGet<PersonalityTestResults>('discover/personality_test_results');

export interface DefaultCallDevices {
  video_device_id: string | null;
  speaker_device_id: string | null;
  microphone_device_id: string | null;
}

export const getDefaultCallDevices = (isMobile: boolean) =>
  apiGet<DefaultCallDevices>(`discover/user/call_devices?is_mobile=${isMobile}`);

export interface PostDefaultCallDevicesParams {
  videoDeviceId: string | null;
  speakerDeviceId: string | null;
  microphoneDeviceId: string | null;
  isMobile: boolean;
}

export const postDefaultCallDevices = ({
  videoDeviceId,
  speakerDeviceId,
  microphoneDeviceId,
  isMobile,
}: PostDefaultCallDevicesParams) =>
  apiPost('discover/user/call_devices', {
    video_device_id: videoDeviceId,
    speaker_device_id: speakerDeviceId,
    microphone_device_id: microphoneDeviceId,
    is_mobile: isMobile,
  });

export interface PostMatchEventStatusParams {
  matchCode: string;
  status: number;
}

export const postMatchEventStatus = ({ matchCode, status }: PostMatchEventStatusParams) =>
  apiPost('discover/user/match_event_status', {
    match_code: matchCode,
    status,
  });

export const optInToGreet = (optIn: boolean) =>
  apiPost('discover/nux_match/greet_opt_in', {
    opt_in: optIn,
  });

export const optInToRecruitingPilot = (optIn: boolean) =>
  apiPost('discover/recruiting_pilot/opt_in', {
    opt_in: optIn,
  });

export interface postBlockProfileParams {
  profileId: string;
  unblock?: boolean;
}

export const postBlockProfile = ({ profileId, unblock }: postBlockProfileParams) =>
  apiPost('discover/user/block_user', {
    block_profile_id: profileId,
    unblock,
  });

interface AutopilotResponse {
  autopilot: boolean;
  type: 1 | 2 | 3;
}

export const postNewUserAutopilotAvailable = () =>
  apiPost<AutopilotResponse>(`discover/user/is_autopilot_allowed`, {
    new_user_test: true,
  });

export const postAutopilotAvailable = ({
  timeslots = [],
  locale = null,
  neighborhoods = [],
  numberOfMeetings = 1,
  pickMatchRole = null,
  targetLocale = null,
  slantOptIn = null,
}: PostAvailabilityType) =>
  apiPost<AutopilotResponse>(`discover/user/is_autopilot_allowed`, {
    slant_opt_in: slantOptIn,
    target_locale: targetLocale,
    target_role: pickMatchRole,
    locale,
    time_slots: timeslots,
    neighborhoods,
    max_matches: numberOfMeetings,
  });

export const deleteAutopilot = () => apiPost('discover/user/delete_autopilot', {});

export const postReactivateAutopilot = (autopilotCode: string) =>
  apiPost(`discover/user/autopilot/reactivate`, {
    autopilot_code: autopilotCode,
  });

export const postNewYearGoals = (goalsList: string[]) =>
  apiPost('discover/new_year_goals', {
    goals_list: goalsList,
  });

interface PartnershipInfo {
  logo: string;
  use_dark_landing_page?: boolean;
}

export const getPartnershipInfo = (inviteCode: string) =>
  apiGet<PartnershipInfo>(`discover/partnership_info?invite_code=${inviteCode}`);

interface SpeakerData {
  matchCode: string;
  localDuration: number;
  remoteDuration: number;
  totalDuration: number;
}

export const postSpeakerData = ({
  matchCode,
  localDuration,
  remoteDuration,
  totalDuration,
}: SpeakerData) => {
  apiPost(`discover/call/active_speaker_log`, {
    match_code: matchCode,
    user_duration: localDuration,
    match_user_duration: remoteDuration,
    total_duration: totalDuration,
  });
};

export const pauseAutopilotUntil = (pauseUntil: string | null) =>
  apiPost('discover/user/autopilot/pause', {
    pause_until: pauseUntil,
  });

export const autopilotTargetUserLocale = (targetUserLocale: boolean) =>
  apiPost('discover/user/autopilot/target_user_locale', {
    target_user_locale: targetUserLocale,
  });
