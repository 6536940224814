import moment from 'moment';
import React, { useState } from 'react';
import { partition } from 'lodash';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';

import { Clickable, colors, fonts, HideMobile, margins, SubText, Text } from 'css/css';
import { UnderlineAnimationProps, underlineAnimation } from 'css/animations';

import {
  SimpleFullHeightModalWrap,
  useSimpleModal,
  WhiteCardHeader,
} from 'js/components/shared/MobileModal';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { getIsMobile } from 'js/util/util';
import { CitySelectMobile } from 'js/components/registration/cities/CitySelectMobile';
import { BackendLocale } from 'js/components/weekly/types';
import { useResource } from 'js/util/use-resource';
import { SettingsSection } from 'js/components/settings/components/shared-components/EditableSection';

import { SectionsTitles } from '../constants';

import { CitySelect } from './CitySelect';
import { SettingsOption } from './SettingsOption';

export const HomeCityEditor: React.FC = () => {
  const user = useUser();
  const { openModal, closeModal, wrapperProps } = useSimpleModal();
  const { updateUser } = useUserContextProvider();
  const history = useHistory();

  const [focused, setFocused] = useState(false);
  const [locales] = useResource<BackendLocale[]>('discover/locales', { include_tz_locales: false });
  const [featuredLocales] = partition(locales || [], l => l.is_featured);

  const localeBlocked = user.locale_info.locale_block_expire
    ? moment.utc(user.locale_info.locale_block_expire) > moment.utc()
    : false;

  if (getIsMobile()) {
    return (
      <div>
        <SimpleFullHeightModalWrap {...wrapperProps} actions={localeBlocked ? undefined : <span />}>
          <WhiteCardHeader title="Change home city" onClose={closeModal} />
          {localeBlocked ? (
            <LocaleBlockNotice />
          ) : (
            <CitySelectMobile
              focused={focused}
              setFocused={setFocused}
              featuredLocales={featuredLocales}
              onSubmit={async value => {
                if (!value) return;
                await updateUser(
                  'locale' in value
                    ? { locale: value.locale.id }
                    : { googlePlaceId: value.googlePlaceId, autoLocale: true },
                );
                closeModal();
              }}
            />
          )}
          <div style={{ flex: 1 }} />
        </SimpleFullHeightModalWrap>
        <SettingsSection
          onClick={openModal}
          headingTitle={SectionsTitles.HOME_CITY}
          selectedOptions={user.city}
        />
      </div>
    );
  }
  return (
    <SettingsOption
      title="Home city"
      bodyComponent={
        localeBlocked ? (
          <LocaleBlockNotice />
        ) : (
          <HideMobile>
            <Text style={{ marginBottom: margins.size1 }}>
              Please select your physical location from the list below. You can update your location
              every six months. You can still receive global matches regardless of your physical
              location.
            </Text>
            <SubText style={{ marginBottom: margins.size2 }}>
              *Please note that if you change your location you will need to select your
              availabilities again. You can do that{' '}
              <Clickable
                onClick={() => {
                  history.push('/weekly');
                }}
              >
                <AnimatedLink>here</AnimatedLink>
              </Clickable>
              .
            </SubText>
          </HideMobile>
        )
      }
      selectComponent={<CitySelect localeBlocked={localeBlocked} />}
      style={{ marginBottom: margins.size4 }}
    />
  );
};

const LocaleBlockNotice = () => (
  <>
    <Text style={{ marginBottom: margins.size2 }}>
      You have either changed your location too recently or our support team has updated your
      location for you.{' '}
    </Text>
    <Text style={{ marginBottom: margins.size3 }}>
      If you are not living at the location below, change your location by contacting{' '}
      <a
        href="mailto:support@lunchclub.ai"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          fontFamily: fonts.semiBold,
          color: colors.primaryMain,
        }}
      >
        our support team (support@lunchclub.ai).
      </a>
    </Text>
  </>
);

const AnimatedLink = styled.span<UnderlineAnimationProps>`
  color: ${colors.primaryMain};
  ${underlineAnimation}
`;
