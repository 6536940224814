import React from 'react';
import styled from 'styled-components/macro';

import { ClickableCard, margins, CardText, media, ClickableText } from 'css/css';

import { Objective } from 'types/objectives';

import { Nav } from 'js/components/registration/Nav';
import { onboarding } from 'js/util/strings';
import { runAndScroll, objectiveIcons } from 'js/util/util';
import { useNotifContext } from 'js/util/notif-context';

const ObjectiveCard = ({
  obj,
  selected,
  onClick,
}: {
  obj: Objective;
  selected: boolean;
  onClick: (obj: Objective) => void;
}) => {
  // @ts-ignore
  const graphic = objectiveIcons.find((o: any) => o.id === obj.id).imgSrc;
  return (
    <ClickableCard
      style={{
        padding: margins.size4,
        height: 160,
        width: 160,
        marginRight: margins.size1,
        marginBottom: margins.size1,
      }}
      flexDirection="column"
      selected={selected}
      onClick={() => onClick(obj)}
    >
      <img src={graphic} alt={obj.name} />

      <CardText>{obj.name}</CardText>
    </ClickableCard>
  );
};

interface ObjectivesProps {
  objectives: Objective[];
  selectedObjectives: Objective[];
  setSelectedObjectives: (objs: Objective[]) => void;

  onSubmit?: (showDetails: boolean) => void;
  onPrev?: () => void;
  profile?: boolean;
  buttonLoading?: boolean;
}
export const Objectives = ({
  objectives,
  onSubmit,
  onPrev,
  selectedObjectives,
  setSelectedObjectives,
  profile,
  buttonLoading,
}: ObjectivesProps) => {
  const { showNotif } = useNotifContext();

  const handleClick = (obj: any) => {
    if (selectedObjectives.some((o: any) => o.id === obj.id)) {
      setSelectedObjectives(selectedObjectives.filter((o: any) => o.id !== obj.id));
    } else if (selectedObjectives.length >= 3) {
      showNotif({
        message: onboarding.objectives.upperThresholdError,
        level: 'error',
      });
    } else setSelectedObjectives([...selectedObjectives, obj]);
  };
  const submitObjectives = async (showDetails = false) => {
    if (!selectedObjectives.length) {
      showNotif({
        message: onboarding.objectives.lowerThresholdError,
        level: 'error',
      });
    } else {
      await onSubmit?.(showDetails);
    }
  };
  return (
    <>
      <CardContainer profile>
        {objectives.map((obj: any) => (
          <ObjectiveCard
            key={obj.id}
            selected={!!selectedObjectives.find((o: any) => o.id === obj.id)}
            obj={obj}
            onClick={handleClick}
          />
        ))}
      </CardContainer>
      {!profile && (
        <>
          <ClickableText
            style={{ marginTop: margins.size2 }}
            invalid={!selectedObjectives.length}
            onClick={() => runAndScroll(() => submitObjectives(true))}
          >
            Tell us more
            <span style={{ position: 'relative', top: '2px', fontWeight: 'bold' }}>&nbsp;→</span>
          </ClickableText>

          <Nav
            onNext={() => submitObjectives()}
            nextInvalid={!selectedObjectives.length}
            onBack={onPrev}
            loading={buttonLoading}
            invalidMessage={onboarding.objectives.lowerThresholdError}
          />
        </>
      )}
    </>
  );
};

type CardContainerTypes = { profile: boolean };

const CardContainer = styled.div<CardContainerTypes>`
  display: grid;
  grid-template-columns: repeat(auto-fill, 168px);
  justify-content: center;
  margin-bottom: ${margins.size4};
  width: 80%;
  margin: 0 auto;
  margin-bottom: ${p => (p.profile ? '0px' : margins.size4)};
  ${media.mobile} {
    width: 100%;
  }
`;
