import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';

import { colors } from 'css/css';

import loader from 'img/shared/button-loading.svg';

interface Props {
  color?: string;
  center?: boolean;
  small?: boolean;
  large?: boolean;
}
export const SVGLoader: React.FC<Props> = ({ color, center = true, small, large }) => (
  <LoadingImage
    src={loader}
    color={color}
    small={small}
    large={large}
    center={center}
    alt="loading"
  />
);

const LoadingImage = styled(SVG)<Props>`
  width: ${p => (p.small ? '20px' : p.large ? '50px' : '40px')};
  height: ${p => (p.small ? '20px' : p.large ? '50px' : '40px')};
  align-self: center;
  ${p =>
    p.center &&
    `position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);`}
  & > :first-child {
    fill: ${p => p.color || colors.primaryMain};
  }
`;
