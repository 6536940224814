import React from 'react';
import styled from 'styled-components/macro';

import { globalTransitionSettings } from 'css/css';

import { pickDefaultAvatar, simpleStringHash } from 'js/util/util';

import DefaultPicture from 'img/shared/default-picture.svg';

interface Props {
  className?: string;
  src?: string | null;
  alt?: string;
  style?: any;
  avatarId?: string | number;
  ownProfile?: boolean;
  grayOut?: boolean;
  size0?: boolean;
  size1?: boolean;
  size2?: boolean;
  size3?: boolean;
  size4?: boolean;
  size5?: boolean;
  size6?: boolean;
  size7?: boolean;
  onClick?: () => void;
}

export const Avatar: React.FC<Props> = ({
  className,
  src,
  alt,
  style,
  avatarId,
  ownProfile,
  grayOut,
  size0,
  size1,
  size2,
  size3,
  size4,
  size5,
  size6,
  size7,
  onClick,
}) => {
  const defaultAvatar = ownProfile
    ? DefaultPicture
    : pickDefaultAvatar(typeof avatarId === 'number' ? avatarId : simpleStringHash(avatarId));
  return (
    <AvatarImage
      className={className}
      style={style}
      size={
        size0
          ? '16px'
          : size1
          ? '24px'
          : size2
          ? '40px'
          : size3
          ? '48px'
          : size4
          ? '64px'
          : size5
          ? '96px'
          : size6
          ? '120px'
          : size7
          ? '160px'
          : '86px'
      }
      src={src || defaultAvatar}
      grayOut={grayOut}
      alt={alt || 'Profile Picture'}
      onClick={onClick}
    />
  );
};

interface AvatarImage {
  size: string;
  grayOut?: boolean;
}
const AvatarImage = styled.img<AvatarImage>`
  height: ${p => p.size};
  min-height: ${p => p.size};
  max-height: ${p => p.size};
  min-width: ${p => p.size};
  max-width: ${p => p.size};
  border-radius: 50%;
  object-fit: cover;
  transition: ${globalTransitionSettings};
  ${p => p.grayOut && 'filter: gray; '};
  ${p =>
    p.grayOut &&
    '-webkit-filter: grayscale(100%); -moz-filter: grayscale(100%); -o-filter: grayscale(100%);'};
`;
