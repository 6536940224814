// related to the a/b test on the number of endorsement suggestions shown in the endorsement modal
export const SMALL_NUM_OF_ENDORSEMENT_SUGGESTIONS = 5;
export const LARGE_NUM_OF_ENDORSEMENT_SUGGESTIONS = 31;

export const MODAL_SMALL_TRANSITION_DURATION = 300; // in milliseconds
export const MODAL_LARGE_TRANSITION_DURATION = 500; // in milliseconds
export const ENDORSEMENT_MODAL_DESKTOP_BODY_HEIGHT = 540; // in pixels

export const CATEGORY_MIN_THRESHOLD = 2;
export const NUMBER_OF_AVATARS = 3;

export const ENDORSEMENTS_END_TEXT = 'We’re all out of endorsement recommendations for now!';
