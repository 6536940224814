import React from 'react';

import { Heading3, Text, FlexColumn, colors, margins, FlexRow } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { SmallArrow } from 'js/components/shared/SmallArrow';

import privateNote from 'img/chat/private-note.svg';

interface Props {
  firstName: string;
  openReconnect: () => void;
  matchPrivateNote?: string;
}

export const ReconnectReminderPopup: React.FC<Props> = ({
  firstName,
  openReconnect,
  matchPrivateNote,
}) => (
  <>
    <Heading3>Reconnect with {firstName}!</Heading3>
    {matchPrivateNote && (
      <FlexRow
        style={{ marginTop: margins.size2, marginRight: margins.size4 }}
        alignItems="flex-end"
      >
        <img src={privateNote} alt="Private note" style={{ marginRight: margins.size2 }} />
        <FlexColumn alignItems="flex-start">
          <Text color={colors.blackMain}>Your private note:</Text>
          <Text>{matchPrivateNote}</Text>
        </FlexColumn>
      </FlexRow>
    )}
    <Button secondary style={{ marginTop: margins.size3, marginLeft: 0 }} onClick={openReconnect}>
      <FlexRow>
        <Heading3 color={colors.primaryMain}>Find times to reconnect</Heading3>{' '}
        <SmallArrow
          direction="right"
          color={colors.primaryMain}
          style={{ marginLeft: margins.size2 }}
        />
      </FlexRow>
    </Button>
  </>
);
