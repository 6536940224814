import { Interests } from 'types/interests';

type CardId = {
  card_id: string;
  id: number;
};

type CardLiked = { liked: boolean };

type CardUniqueMeta = {
  unique_meta?: {
    card_id: string;
    priority: number;
    group: number;
  };
};

type BaseCard = CardId & CardLiked & CardUniqueMeta;

export interface DiscoverCard extends BaseCard {
  card_type: AllCardTypes.Discover;
  data: DiscoverCardData;
}

export interface SimilarCard extends BaseCard {
  card_type: AllCardTypes.Similar;
  data: SimilarCardData;
}

export type MinimalEndorsementCard = {
  card_type: AllCardTypes.EndorsementSuggestion;
  data: EndorsementCardData;
  card_id: string;
};

export type EndorsementCard = MinimalEndorsementCard & CardLiked & CardUniqueMeta & { id: number };

export interface IntroCard extends BaseCard {
  card_type: AllCardTypes.IntroSuggestion;
  data: IntroCardData;
}

export interface PersonalityCard extends BaseCard {
  card_type: AllCardTypes.Personality;
  data: QuestionCardData;
}

export type SurveyCard = CardId &
  CardLiked & {
    card_type: AllCardTypes.Survey;
    data: SurveyCardData;
  };

export interface FriendFinderCard extends BaseCard {
  card_type: AllCardTypes.FriendFinder;
  data: FriendFinderCardData;
}
export interface DiscoverBookingCard extends CardId {
  card_type: AllCardTypes.DiscoverBooking;
  data: DiscoverBookingCardData;
}

export interface ContentCard extends CardId {
  card_type: AllCardTypes.Content;
  data: ContentCardData;
}

export type PersonCard = DiscoverCard | SimilarCard;

export type QuestionCards = PersonalityCard | SurveyCard;

export type RegularCard =
  | PersonCard
  | EndorsementCard
  | IntroCard
  | FriendFinderCard
  | MinimalEndorsementCard
  | SurveyCard
  | ContentCard;

export type Card = RegularCard | PersonalityCard | MinimalEndorsementCard | DiscoverBookingCard;

export type EndorsementFeed = {
  session: 'Endorsement';
  cards: MinimalEndorsementCard[];
};

export type PersonalityFeed = {
  session: 'Personality';
  cards: PersonalityCard[];
};

export type RegularFeed = {
  session: 'Regular';
  cards: RegularCard[];
};

export type DiscoverBookingFeed = {
  session: 'DiscoverBooking';
  cards: DiscoverBookingCard[];
  fetchSuggestion: () => Promise<void>;
  isFirstCard: boolean;
};

export type FeedSession = 'Endorsement' | 'Personality' | 'Regular' | 'DiscoverBooking';

export enum AllCardTypes {
  Similar = 0,
  Community = 1,
  Discover = 2,
  Network = 3,
  Invite = 4,
  IntroSuggestion = 5,
  Topic = 6,
  DiscoverOutreach = 7,
  Question = 8,
  MatchComparison = 9,
  DiscoverOutreachComparison = 10,
  NetworkMatch = 11,
  Personality = 12,
  FriendFinder = 13,
  EndorsementSuggestion = 14,
  NUX = 15,
  DiscoverBooking = 16,
  Survey = 17,
  DiscoverMorpheus = 18,
  Content = 19,
}

export type RegularCardType =
  | AllCardTypes.IntroSuggestion
  | AllCardTypes.Discover
  | AllCardTypes.Similar
  | AllCardTypes.FriendFinder
  | AllCardTypes.EndorsementSuggestion
  | AllCardTypes.Survey
  | AllCardTypes.Content;

export interface QuestionCardData {
  question: string;
  question_id: number;
  options: { [optionId: number]: [string, string] };
}

export interface SurveyCardData extends QuestionCardData {
  is_binary_options: boolean;
}

export interface SimilarCardData {
  similar_user: UserInfo;
  target_user: UserInfo;
}

export interface DiscoverCardData {
  user: UserInfo;
}

export interface EndorsementCardData {
  user: MinimalUserDict;
  category: string;
  id: number;
}
export interface DiscoverBookingCardData extends DiscoverCardData {
  time_slots: string[];
  suggestion_id: number;
}

export interface IntroCardData {
  friend_1: MinimalUserDict | PhantomDict;
  friend_2: MinimalUserDict | PhantomDict;
  id: number;
  two_step: boolean;
}

export interface FriendFinderCardData {
  user: MinimalUserDict | PhantomDict;
}

export interface DiscoverOutreachData {
  candidate_id: number;
  image: string;
  first_name: string;
  last_name: string;
  headline: string;
  sub_headline: string;
}

export interface QuestionData {
  question_id: string;
  question: string;
  options: {
    [key: string]: {
      text: string;
      response: string;
    };
  };
}

enum ContentType {
  Article = 0,
  Book = 1,
  Video = 2,
}
export interface ContentCardData {
  content_id: number;
  content_type: ContentType;
  headline: string;
  text: string;
  image: string;
  url: string;
}

export interface MinimalUserDict {
  company: null | string;
  first_name: string;
  headline: string;
  image: string;
  last_name: string;
  locale: string;
  name: string;
  city: string;
  profile: string;
  role: null | string;
}

export interface PhantomDict {
  email: string;
  headline: string;
  image: string;
  name: string;
  first_name: string;
  sub_headline: string;
}
export interface UserInfo extends MinimalUserDict {
  interests: Interests[];
  prev_matched?: boolean;
}

export enum FeedActionType {
  Load = 0,
  Respond = 1,
  SeeProfile = 2,
  OpenUrl = 3,
  ExpandHeadline = 4,
  Click = 5,
  GetExplanation = 6,
}

export type FeedAction =
  | {
      type: FeedActionType.Load | FeedActionType.Click | FeedActionType.GetExplanation;
    }
  | {
      type: FeedActionType.Respond;
      response: any;
    }
  | { type: FeedActionType.SeeProfile | FeedActionType.ExpandHeadline; publicId: string }
  | { type: FeedActionType.OpenUrl; url: string };
