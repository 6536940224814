import React from 'react';

import { UserInterest } from 'types/interests';

import { Preference } from 'js/components/shared/Preference';

import Interests from 'img/weekly/interests.svg';
import Communities from 'img/weekly/communities.svg';

import { Community } from '../types';

import { PreferenceHeading } from './styles';

interface Props {
  shortWeekly: boolean;
  communities: Community[];
  interests: UserInterest[];
  targetInterest?: number;
  setTargetInterest: (interest: number) => void;
  preferTheseCommunities: number[];
  setPreferTheseCommunities: (preferTheseCommunities: number[]) => void;
}

export const MeetingPreferenceQuestion: React.FC<Props> = ({
  communities,
  preferTheseCommunities,
  interests,
  targetInterest,
  setTargetInterest,
  setPreferTheseCommunities,
  shortWeekly,
}) => {
  const largeCommunities = communities.filter(community => community.largeCommunity);

  if (shortWeekly && communities.length)
    return (
      <>
        {!!largeCommunities.length && (
          <>
            <PreferenceHeading>Meet someone in your communities</PreferenceHeading>
            <Preference
              questionText="Pick a community"
              imageSrc={Communities}
              imageAlt="Prefer matches within your community"
              selectedOption={preferTheseCommunities[0]}
              selectOption={community =>
                community !== null
                  ? setPreferTheseCommunities([community])
                  : setPreferTheseCommunities([])
              }
              options={largeCommunities}
              customCopy="Meet someone in "
            />
          </>
        )}
      </>
    );
  return (
    <>
      {!!largeCommunities.length && (
        <Preference
          questionText="Pick a community"
          imageSrc={Communities}
          imageAlt="Prefer matches within your community"
          selectedOption={preferTheseCommunities[0]}
          selectOption={community =>
            community !== null
              ? setPreferTheseCommunities([community])
              : setPreferTheseCommunities([])
          }
          options={largeCommunities}
          customCopy="Meet someone in "
        />
      )}
      {!shortWeekly && !!interests.length && (
        <Preference
          questionText="Interested in"
          imageSrc={Interests}
          imageAlt="Interested in"
          selectedOption={targetInterest}
          selectOption={setTargetInterest}
          options={interests}
          marginBottom
          sortObjects={false}
          customCopy="Interested in "
        />
      )}
    </>
  );
};
