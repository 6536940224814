import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { Text, margins, FlexColumn, MobileScrollFade, colors } from 'css/css';

import { Invitee } from 'types/invites';

import { GoogleSignIn } from 'js/components/shared/GoogleSignIn';
import { InviteCardRowMobile } from 'js/components/shared/InviteCardRow';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { ImpressionTracker } from 'js/components/shared/ImpressionTracker';

interface Props {
  isLoading: boolean;
  isLoadingMoreInvites: boolean;
  inviteSuggestions: Invitee[];
  sendInvite: (email: string) => void;
  skipInvite: (email: string) => void;
  loadNInviteSuggestions: (n: number) => void;
  hasLoadedAllSuggestions: boolean;
}
export const InviteSuggestionsMobileEmail: React.FC<Props> = ({
  isLoading,
  isLoadingMoreInvites,
  inviteSuggestions,
  sendInvite,
  skipInvite,
  loadNInviteSuggestions,
  hasLoadedAllSuggestions,
}) => {
  const [initialLoad, setInitialLoad] = useState(true);

  const handleScroll: React.UIEventHandler<HTMLDivElement> = e => {
    if (hasLoadedAllSuggestions) {
      return;
    }
    const approachingBottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop < 700;

    if (approachingBottom) {
      loadNInviteSuggestions(30);
    }
  };

  useEffect(() => {
    if (isLoading === false) {
      setInitialLoad(false);
    }
  }, [isLoading]);

  if (initialLoad && isLoading) {
    return (
      <Container>
        <SVGLoader center={false} />
      </Container>
    );
  }
  return (
    <Container>
      {inviteSuggestions?.length ? (
        <div
          style={{ overflowY: 'scroll', overflowX: 'hidden', paddingBottom: margins.size3 }}
          onScroll={handleScroll}
        >
          <MobileScrollFade />
          {inviteSuggestions.map(invitee => (
            <ImpressionTracker key={invitee.email} impressionId={`invite,${invitee.email}`}>
              <InviteCardRowMobile
                user={invitee}
                sendInvite={() => sendInvite(invitee.email)}
                skipInvite={() => skipInvite(invitee.email)}
              />
            </ImpressionTracker>
          ))}
          {isLoadingMoreInvites && <SVGLoader center={false} small />}
        </div>
      ) : (
        <>
          <Text style={{ margin: `${margins.size4} 0px`, textAlign: 'left' }}>
            Connect with Google to unlock the full potential of Lunchclub - including automatic
            calendar holds and improved matching. We may also show you friends who could be
            interested in joining.
          </Text>
          <GoogleSignIn isRegistering />
        </>
      )}
    </Container>
  );
};

const Container = styled(FlexColumn)`
  align-items: stretch;
  overflow: scroll;
  background-color: ${colors.whiteMain};
  height: 100%;
  padding: 0px ${margins.size3};
`;
