import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { borders, colors, margins, FlexColumn, FlexRow, media } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { TextInput } from 'js/components/shared/TextInput';
import { getIsMobile } from 'js/util/util';

import { Title } from '../styles';

interface SettingsOptionProps {
  title: string;
  bodyComponent: React.ReactNode;
  icon?: string;
  loading?: boolean;
  style?: CSSProperties;
  selectComponent?: React.ReactNode;
  values?: string[];
  onChangeValue?: (value: string | null) => void;
  onRemoveValue?: (value: string) => void;
}
export const SettingsOption: React.FC<SettingsOptionProps> = ({
  title,
  bodyComponent,
  selectComponent,
  icon,
  values = [],
  onChangeValue,
  onRemoveValue,
  loading,
  style,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);

  const onToggleEditing = (nextValue: string) => {
    if (isEditing) {
      if (onChangeValue) onChangeValue(nextValue);
      setIsEditing(false);
    } else {
      setTempValue(nextValue);
      setIsEditing(true);
    }
  };

  useEffect(() => {
    const inputEl = containerRef.current && containerRef.current.querySelector('input');
    if (isEditing && inputEl) {
      inputEl.focus();
    }
  }, [isEditing]);

  return (
    <FlexColumn alignItems="left" style={style} ref={containerRef}>
      <Title>{title}</Title>
      {bodyComponent}
      {selectComponent ??
        values.map((passedValue, i) => (
          <Container icon={!!icon} marginBottom={i < values.length} key={passedValue}>
            <FlexRow width="100%">
              {icon && <Icon src={icon} />}
              <TextInput
                mobileFocusBehavior="always"
                value={isEditing ? tempValue : passedValue}
                onChange={newValue => setTempValue(newValue)}
                onEnter={() => onToggleEditing(tempValue || passedValue)}
                maxWidth="100%"
                placeholder={title}
                $backgroundColor={colors.whiteMain}
                disabled={!onChangeValue || (!isEditing && !getIsMobile())}
                style={{ margin: 0, marginRight: margins.size1 }}
                onBlur={() => isEditing && onToggleEditing(tempValue || passedValue)}
                height="40px"
              />
            </FlexRow>
            {isEditing ? (
              <>
                <Button
                  small
                  secondary
                  style={{ marginRight: margins.size1 }}
                  onClick={() => {
                    setTempValue(passedValue);
                    setIsEditing(false);
                  }}
                >
                  cancel
                </Button>

                <Button small onClick={() => onToggleEditing(tempValue || passedValue)}>
                  save
                </Button>
              </>
            ) : onRemoveValue ? (
              <Button small tertiary onClick={() => onRemoveValue(passedValue)}>
                remove{' '}
              </Button>
            ) : (
              <Button small secondary onClick={() => onToggleEditing(tempValue || passedValue)}>
                {loading ? 'saving...' : 'edit'}
              </Button>
            )}
          </Container>
        ))}
    </FlexColumn>
  );
};

type ContainerTypes = {
  icon: boolean;
  marginBottom?: boolean;
};
const Container = styled(FlexRow)<ContainerTypes>`
  padding-right: ${margins.size3};
  padding-left: ${p => (p.icon ? margins.size3 : '0px')};
  border: ${borders.variation};
  margin-bottom: ${p => (p.marginBottom ? margins.size1 : '0px')};
  border-radius: 5px;
  height: 42px;

  ${media.mobile} {
    padding: 0;
    border: 0;
    input {
      padding-left: 0;
    }
  }
`;

const Icon = styled.img`
  width: 15px;
  height: 15px;
  min-width: 15px;
  max-width: 15px;
  margin-right: ${margins.size3};
`;
