import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { FlexColumn, margins, MainHeading, Text, colors, media, Clickable } from 'css/css';

import { useUserContextProvider } from 'js/providers/UserProvider';
import { createOrActivateToken, postUserLogin } from 'js/util/api';
import { apiFailure } from 'js/util/strings';
import { useNotifContext } from 'js/util/notif-context';
import { setElliotToken } from 'js/util/util';

import citiesGraphic from 'img/registration/cities-graphic.svg';

import { Button } from '../Button';
import { Logo } from '../Logo';
import { GoogleSignIn } from '../GoogleSignIn';
import { TextInput } from '../TextInput';
import { CodeInput } from '../CodeInput';

import { KeyEmoji } from './KeyEmoji';
import { RetryCountdown } from './RetryCountdown';

interface Props {
  handleLogin?: () => void;
  tempTokenDefault?: string;
}
export const Login: React.FC<Props> = ({ handleLogin, tempTokenDefault = undefined }) => {
  const { showNotif } = useNotifContext();
  const { fetchUser, updateUserAvailability } = useUserContextProvider();

  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(!!tempTokenDefault);
  const [unknownEmail, setUnknownEmail] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);

  const [tempToken, setTempToken] = useState(tempTokenDefault || '');

  const [code, setCode] = useState('');
  const [validatingCode, setValidatingCode] = useState(false);
  const [showRetryCountdown, setShowRetryCountdown] = useState(false);

  const loadUserAndHandleLogin = async () => {
    await Promise.all([fetchUser(), updateUserAvailability()]);
    if (handleLogin) handleLogin();
  };

  const sendLoginEmail = async () => {
    if (sendingEmail) return;
    if (!email) return;
    setSendingEmail(true);

    const res = await postUserLogin({
      email,
      isFromApp: !!window.bridge,
    });
    if (res.ok) {
      setUnknownEmail(false);
      setEmailSent(true);

      // Temporarily invalid refresh token, verified by code later.
      setTempToken(res.getJson.token);
      setShowRetryCountdown(prev => !prev);
    } else if (res.status === 403) {
      setUnknownEmail(true);
    } else
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });

    setSendingEmail(false);
  };

  const validateCode = async () => {
    if (!code) return;
    if (code.length !== 6) return;
    if (validatingCode) return;
    setValidatingCode(true);
    const res = await createOrActivateToken(tempToken, !!window.bridge, code);
    if (res.ok) {
      setElliotToken(res.getJson.auth_token);
      loadUserAndHandleLogin();
    } else {
      showNotif({ message: 'Invalid code', level: 'error' });
    }
    setValidatingCode(false);
  };

  return (
    <FlexColumn>
      <LogoBox>
        <Logo logoWidth="150px" />
      </LogoBox>
      <MainHeading style={{ marginTop: margins.size5 }}>Log in</MainHeading>
      <Cities src={citiesGraphic} alt="cities" />
      <FlexColumn style={{ width: '90%', margin: '0 auto' }}>
        <GoogleSignIn
          onLogIn={loadUserAndHandleLogin}
          buttonText="Continue with Google"
          buttonWidth="350px"
        />
        <ColumnBreak />
        <div style={{ height: 40, marginBottom: margins.size3, width: '100%', maxWidth: 350 }}>
          {emailSent ? (
            <CodeInput setCode={setCode} onSubmit={validateCode} />
          ) : (
            <TextInput
              disabled={emailSent}
              value={email}
              onChange={(newEmail: string) => {
                setEmail(newEmail);
                setUnknownEmail(false);
              }}
              onEnter={sendLoginEmail}
              placeholder="Email"
            />
          )}
        </div>
        {!emailSent && (
          <Button onClick={sendLoginEmail} loading={sendingEmail} maxWidth="350px">
            Send me a login code
          </Button>
        )}
        {unknownEmail && (
          <Text style={{ marginTop: margins.size2 }}>
            No user found with that email - please verify the email address above, or create an
            account.
          </Text>
        )}

        {emailSent && (
          <>
            <Button
              onClick={validateCode}
              invalid={code.length !== 6}
              loading={validatingCode}
              maxWidth="350px"
            >
              Continue
            </Button>
            <Text style={{ marginTop: margins.size3 }}>
              <KeyEmoji /> We just sent you a login code.
            </Text>
            <Text bold>Check your inbox for the code!</Text>
            {showRetryCountdown ? (
              <Text color={colors.blackLight}>
                Haven&apos;t received an email? Retry in{' '}
                <RetryCountdown setShowRetryCountdown={setShowRetryCountdown} />
              </Text>
            ) : (
              <Clickable onClick={sendLoginEmail}>
                <Text color={colors.primaryMain}>
                  Haven&apos;t received an email? Click to resend
                </Text>
              </Clickable>
            )}
          </>
        )}
      </FlexColumn>
    </FlexColumn>
  );
};

const Cities = styled.img`
  margin: ${margins.size4} auto ${margins.size5} auto;
  width: 600px;
  ${media.mobile} {
    width: 90%;
  }
`;

const LogoBox = styled.div`
  margin-top: ${margins.size5};
`;

const ColumnBreak = styled.hr`
  height: 1px;
  color: ${colors.primaryLight};
  margin-top: ${margins.size4};
  margin-bottom: ${margins.size4};
  max-width: 350px;
  width: 100%;
`;
