import React, { useState } from 'react';
import styled from 'styled-components/macro';

import {
  colors,
  margins,
  Text,
  globalTransitionSettings,
  FlexColumn,
  media,
  fontSizes,
  lineHeights,
} from 'css/css';

import { useFontSizeFillingContainer } from 'js/util/use-font-size-filling-container';
import { Avatar } from 'js/components/shared/Avatar';
import { getIsMobile } from 'js/util/util';
import { postFeedAction } from 'js/util/api';
import { FeedActionType } from 'js/components/homepage/types';
import { SmallArrow } from 'js/components/shared/SmallArrow';

interface Profile {
  id: string | number;
  description?: string;
  subDescription?: string;
  imageSrc: string;
}

const AVATAR_HEIGHT = 40;
const CLOSED_HEIGHT_INTRO = 75;
const BOX_VERTICAL_PADDING = parseInt(margins.size4, 10);
const GAP_HEIGHT = parseInt(margins.size4, 10);

interface UserDesciptionBoxesProps {
  deckCardId: number;
  profiles: Profile[];
  isIntro: boolean;
  maxHeight: number;
}

export const UserDescriptionBoxes: React.FC<UserDesciptionBoxesProps> = ({
  deckCardId,
  profiles,
  isIntro,
  maxHeight,
}) => {
  const isSingle = profiles.length === 1;
  const isMobile = getIsMobile();

  const [selectedProfile, setSelectedProfile] = useState('');
  const [isCompletelyClosed, setIsCompletelyClosed] = useState(!isSingle);

  const handleClick = (newSelectedProfile: string) => {
    setIsCompletelyClosed(newSelectedProfile === selectedProfile);
    if (selectedProfile !== newSelectedProfile && !isSingle && isMobile) {
      postFeedAction(deckCardId, {
        type: FeedActionType.ExpandHeadline,
        publicId: newSelectedProfile,
      });
    }
    setSelectedProfile(newSelectedProfile === selectedProfile ? '' : newSelectedProfile);
  };

  return (
    <FlexColumn style={{ gap: GAP_HEIGHT }}>
      {profiles.map(profile => {
        const isOpen = selectedProfile === `${profile.id}`;
        return (
          <UserDescBox
            profile={profile}
            isOpen={isOpen || isSingle}
            handleClick={handleClick}
            isIntro={isIntro}
            isCompletelyClosed={isCompletelyClosed}
            key={profile.id}
            openHeight={
              maxHeight -
              profiles.length * (AVATAR_HEIGHT / 2) -
              (profiles.length - 1) * 2 * BOX_VERTICAL_PADDING -
              (profiles.length - 1) * GAP_HEIGHT
            }
          />
        );
      })}
    </FlexColumn>
  );
};

const UserDescBox = ({
  profile,
  isOpen,
  handleClick,
  isIntro,
  isCompletelyClosed,
  openHeight,
}: {
  profile: Profile;
  isOpen: boolean;
  isIntro: boolean;
  isCompletelyClosed: boolean;
  handleClick: (i: string) => void;
  openHeight: number;
}) => {
  const { id, description, subDescription, imageSrc } = profile;
  const wholeDescription = [description, subDescription].join(' ').trim();
  const { textboxRef, fontSize, remainingSpace } = useFontSizeFillingContainer(
    wholeDescription,
    parseInt(fontSizes.size2, 10),
    openHeight - 2 * BOX_VERTICAL_PADDING,
    lineHeights.header,
    10,
  );

  const isMobile = getIsMobile();

  return (
    <UserDescBoxContainer
      key={id}
      onClick={() => handleClick(`${id}`)}
      shouldDisplay={!isMobile || remainingSpace !== undefined}
    >
      <StyledAvatar avatarId={id} src={imageSrc} size2 />

      <UserCardContainer
        $isOpen={isOpen}
        $isIntro={isIntro}
        $openHeight={openHeight}
        $remainingSpace={remainingSpace}
        $isCompletelyClosed={isCompletelyClosed}
      >
        <ContentContainer isOpen={isOpen}>
          <DescText
            isOpen={isOpen}
            ref={textboxRef}
            style={isMobile && (!isIntro || !isCompletelyClosed) ? { fontSize } : {}}
          >
            {wholeDescription}
          </DescText>
        </ContentContainer>
        {isMobile && isIntro && (
          <SmallArrow
            color={colors.blackLight}
            direction={isOpen ? 'up' : 'down'}
            style={{ marginBottom: '-20px' }}
          />
        )}
      </UserCardContainer>
    </UserDescBoxContainer>
  );
};

const UserDescBoxContainer = styled(FlexColumn)<{ shouldDisplay: boolean }>`
  width: 100%;
`;

const StyledAvatar = styled(Avatar)`
  border: 4px solid ${colors.greyMain};
  position: relative;
`;

const getUserCardContainerHeight = ({
  isIntro,
  isOpen,
  openHeight,
  remainingSpace = 0,
  isCompletelyClosed,
}: {
  isIntro: boolean;
  isOpen: boolean;
  openHeight: number;
  remainingSpace?: number;
  isCompletelyClosed: boolean;
}) => {
  if (isIntro) {
    return `${
      isOpen ? openHeight - remainingSpace : isCompletelyClosed ? CLOSED_HEIGHT_INTRO : 0
    }px`;
  }
  return `${openHeight - remainingSpace}px`;
};

const UserCardContainer = styled(FlexColumn)<{
  $isOpen: boolean;
  $isIntro: boolean;
  $openHeight: number;
  $remainingSpace?: number;
  $isCompletelyClosed: boolean;
}>`
  border-radius: 5px;
  background: ${colors.greyMain};
  padding: ${margins.size4} ${margins.size3};
  margin-top: -20px;
  max-height: 1000px;
  width: 100%;
  transition: ${p => p.$isIntro && globalTransitionSettings};
  ${media.mobile} {
    padding: ${BOX_VERTICAL_PADDING}px ${margins.size3};
    overflow: hidden;
    border-radius: 10px;
    height: ${p =>
      getUserCardContainerHeight({
        isIntro: p.$isIntro,
        isOpen: p.$isOpen,
        openHeight: p.$openHeight,
        remainingSpace: p.$remainingSpace,
        isCompletelyClosed: p.$isCompletelyClosed,
      })};
  }
`;

const ContentContainer = styled(FlexColumn)<{ isOpen: boolean }>`
  transition: ${globalTransitionSettings};
  width: 100%;
  ${media.mobile} {
    overflow: hidden;
    overflow-x: hidden;
    height: 100%;
    border-radius: 10px;
  }
`;
const DescText = styled(Text)<{ isOpen: boolean }>`
  text-align: center;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  width: 100%;
  height: 100%;
`;

export const ExpandableHeadline = styled(Text)<{ $isExpanded: boolean; $maxHeight?: number }>`
  transition: ${globalTransitionSettings};
  opacity: ${p => (p.$isExpanded ? 1 : 0)};
  max-height: ${({ $isExpanded, $maxHeight = 1000 }) => ($isExpanded ? `${$maxHeight}px` : '0px')};
  overflow: hidden;
  white-space: pre-wrap;
`;

export const Headline = styled(Text)`
  white-space: pre-wrap;
`;
