import React, { useState } from 'react';

import { colors, margins, SubText } from 'css/css';

import { postFriendFinderResponse } from 'js/util/api';
import { apiFailure } from 'js/util/strings';
import { useNotifContext } from 'js/util/notif-context';

import { DeckCardContainer, CardQuestionWithHeightContainer, ColoredClickable } from '../styles';
import { UserDescriptionBoxes } from '../shared/UserDescriptionBoxes';
import { FriendFinderCardData } from '../types';
import { CARD_TRANSITION_LEAVE_DURATION } from '../constants';
import { feedActionWrapper } from '../shared/DeckCardButtons';

enum FriendFinderResponse {
  KnowWell = 0,
  KnowABit = 1,
  DontKnow = 2,
  DontShow = 3,
}

const DONT_SHOW_BUTTON_MARGIN_TOP = parseInt(margins.size2, 10);
const DONT_SHOW_BUTTON_HEIGHT = 21 + DONT_SHOW_BUTTON_MARGIN_TOP;

interface FriendFinderCardProps {
  cardId: string;
  deckCardId: number;
  data: FriendFinderCardData;
  completeCard: (cardId: string) => void;
}

export const FriendFinderCard: React.FC<FriendFinderCardProps> = ({
  data,
  deckCardId,
  cardId,
  completeCard,
}) => {
  const { showNotif } = useNotifContext();

  const [cardQuestionHeight, setCardQuestionHeight] = useState<number>();
  const [deckCardContentHeight, setDeckCardContentHeight] = useState<number>();
  const [selectedResponse, setSelectedResponse] = useState<FriendFinderResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = data;
  const isUserOffPlatform = 'email' in user;
  const answered = selectedResponse !== null;

  const postResponse = (response: FriendFinderResponse) =>
    postFriendFinderResponse(
      isUserOffPlatform ? { email: user.email, response } : { publicId: user.profile, response },
    );

  const respond = async (response: FriendFinderResponse) => {
    if (isLoading || answered) return;
    setIsLoading(true);
    const res = await postResponse(response);
    if (res.ok) {
      setSelectedResponse(response);
      setTimeout(() => completeCard(cardId), CARD_TRANSITION_LEAVE_DURATION);
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
    setIsLoading(false);
  };

  const dontShowText = "Don't show me this person";
  const dontShow = feedActionWrapper(
    () => respond(FriendFinderResponse.DontShow),
    dontShowText,
    deckCardId,
  );

  const { image, name, first_name: firstName, headline } = user;
  const profileId = isUserOffPlatform ? user.email : user.profile;

  return (
    <DeckCardContainer
      deckCardId={deckCardId}
      isLoading={isLoading}
      isSelected={answered}
      buttons={{
        kind: 'emoji',
        values: [
          {
            handleClick: () => respond(FriendFinderResponse.DontKnow),
            emoji: 'Meh',
            labelBelow: 'who?',
            labelAbove: `I don't know ${firstName} at all`,
            activeColor: colors.tertiary2Main,
          },
          {
            handleClick: () => respond(FriendFinderResponse.KnowABit),
            emoji: 'SlightSmile',
            labelBelow: 'a little',
            labelAbove: `I know ${firstName} a bit`,
            activeColor: colors.tertiary3Main,
          },
          {
            handleClick: () => respond(FriendFinderResponse.KnowWell),
            emoji: 'Happy',
            labelBelow: 'very well',
            labelAbove: `I know ${firstName} very well!`,
            activeColor: colors.tertiary1Main,
          },
        ],
      }}
      setContentHeight={setDeckCardContentHeight}
      feedSession="Regular"
    >
      <CardQuestionWithHeightContainer setHeight={setCardQuestionHeight}>
        How well do you know {name}?
      </CardQuestionWithHeightContainer>
      {deckCardContentHeight !== undefined && cardQuestionHeight !== undefined && (
        <UserDescriptionBoxes
          deckCardId={deckCardId}
          profiles={[
            {
              id: profileId,
              imageSrc: image,
              description: headline,
              subDescription: isUserOffPlatform ? user.sub_headline : undefined,
            },
          ]}
          isIntro={false}
          maxHeight={deckCardContentHeight - cardQuestionHeight - DONT_SHOW_BUTTON_HEIGHT}
        />
      )}
      <ColoredClickable onClick={dontShow} style={{ marginTop: DONT_SHOW_BUTTON_MARGIN_TOP }}>
        <SubText style={{ textDecoration: 'underline' }}>{dontShowText}</SubText>
      </ColoredClickable>
    </DeckCardContainer>
  );
};
