import React from 'react';
import styled from 'styled-components/macro';

import { FlexRow, margins } from 'css/css';

import { SmallControl } from './SmallControl';

interface Props {
  className?: string;
  showSettings: () => void;
  showFeedback: () => void;
}
export const SmallControls: React.FC<Props> = ({ className, showSettings, showFeedback }) => {
  return (
    <ControlsContainer className={className}>
      <SmallControl type="settings" action={showSettings} />
      <SmallControl type="feedback" action={showFeedback} />
    </ControlsContainer>
  );
};

const ControlsContainer = styled(FlexRow)`
  gap: ${margins.size2};
`;
