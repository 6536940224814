import React, { memo, useCallback, useState, useRef } from 'react';
import styled from 'styled-components/macro';

import { FlexRow, globalTransitionSettings, colors, margins } from 'css/css';

import { getIsMobile } from 'js/util/util';
import { useOutsideAlerter } from 'js/util/custom-hooks';

import { SmallArrow } from '../shared/SmallArrow';

import { useCallContext } from './CallContext';
import { useCallPermissionsContext } from './CallPermissionsContext';
import { Icons } from './Control';

export const VideoControls: React.FC = memo(() => {
  const { call, allowVideoProcessing } = useCallContext();
  const { isVideoOn, setVideoOn } = useCallPermissionsContext();

  const isMobile = getIsMobile();
  const blurControlRef = useRef(null);
  const [blurControl, setBlurControl] = useState(false);
  const [backgroundBlur, setBackgroundBlur] = useState(false);

  const toggleCamera = useCallback(() => {
    setVideoOn(!isVideoOn);
  }, [isVideoOn, setVideoOn]);

  const toggleBlurControl = useCallback(() => {
    setBlurControl(!blurControl);
  }, [blurControl, setBlurControl]);

  const toggleBackgroundBlur = useCallback(() => {
    if (!isVideoOn || !call || !allowVideoProcessing) return;
    if (!backgroundBlur) {
      call.updateInputSettings({
        video: {
          processor: {
            type: 'background-blur',
            config: { strength: 1 },
          },
        },
      });
    } else {
      call.updateInputSettings({
        video: {
          processor: {
            type: 'none',
            config: {},
          },
        },
      });
    }
    setBackgroundBlur(!backgroundBlur);
    setBlurControl(false);
  }, [isVideoOn, call, backgroundBlur, setBackgroundBlur]);

  const VideoIcon = isVideoOn ? Icons.video.componentOn : Icons.video.componentOff;
  const ariaLabel = isVideoOn ? Icons.video.enabledAriaLabel : Icons.video.disabledAriaLabel;

  const showBlur = !isMobile && allowVideoProcessing && isVideoOn;

  useOutsideAlerter(blurControlRef, () => setBlurControl(false));

  return !showBlur ? (
    <VideoButton
      role="button"
      id="video-toggle"
      isActive={isVideoOn}
      title={ariaLabel}
      onClick={toggleCamera}
      style={{ marginRight: margins.size3 }}
    >
      <VideoIcon isActive={isVideoOn} aria-label={ariaLabel} />
    </VideoButton>
  ) : (
    <div ref={blurControlRef}>
      <ExpandedVideoButton>
        <VideoButton
          role="button"
          id="video-toggle"
          title={ariaLabel}
          style={{ paddingLeft: margins.size1 }}
          onClick={toggleCamera}
          static
        >
          <VideoIcon isActive={isVideoOn} aria-label={ariaLabel} />
        </VideoButton>
        <Seperator />
        <VideoButton
          role="button"
          id="video-settings"
          style={{
            paddingRight: '12px',
            backgroundColor: blurControl ? colors.blackMid : undefined,
            borderRadius: '0px 31px 31px 0px',
          }}
          onClick={toggleBlurControl}
          static
        >
          <TrayArrow direction="up" color={colors.whiteMain} />
        </VideoButton>
      </ExpandedVideoButton>
      {blurControl && (
        <BlurControl isActive onClick={toggleBackgroundBlur}>
          {backgroundBlur ? 'Disable Background Blur' : 'Enable Background Blur'}
        </BlurControl>
      )}
    </div>
  );
});

const VideoButton = styled.div<{ isActive?: boolean; static?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  transition: ${globalTransitionSettings};
  ${p =>
    !p.static &&
    `background-color: ${p.isActive ? colors.blackMain : colors.whiteMain};
    border-radius: 50%;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  `}
`;

const ExpandedVideoButton = styled(FlexRow)`
  width: 88px;
  height: 48px;
  border-radius: 31px;
  transition: ${globalTransitionSettings};
  background-color: ${colors.blackMain};
  margin-right: ${margins.size3};
`;

const Seperator = styled.div`
  position: static;
  width: 1px;
  height: 32px;
  left: 0px;
  top: 0px;
  border: 1px solid ${colors.blackMid};
`;

const TrayArrow = styled(SmallArrow)`
  transition: ${globalTransitionSettings};
  width: 16px;
  height: 16px;
  margin-left: ${margins.size2};
`;

const BlurControl = styled.div<{ isActive: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.blackMain};
  color: ${colors.whiteMain};
  left: 64px;
  bottom: 56px;
  height: 48px;
  border-radius: 10px;
  padding: 8px;
  width: 100%;
  transition: ${globalTransitionSettings};
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
