import React from 'react';
import styled from 'styled-components/macro';

import { Card, margins, FlexColumn, Text, colors, FlexRow, media } from 'css/css';

import { Button } from './Button';
import { SmallArrow } from './SmallArrow';

export const DynamicForm = ({
  getComponentStack,
  skipButton = false,
  skipText = 'Save and skip',
  skipOnClick = null,
  finishButtonText = 'Finish',
  finishButtonOnClick = null,
  finishButtonInvalid = false,
  finishButtonLoading = false,
  lessPadded = false,
  preBlock = null,
}: any) => {
  const questionStack = getComponentStack();
  return (
    <Card maxWidth="900px">
      {preBlock}

      <Container lessPadded={lessPadded} preBlock={preBlock}>
        {questionStack.map((question: any) => question.component)}
        {questionStack[questionStack.length - 1].isLeaf && (
          <Button
            style={{ margin: `${margins.size6} auto 0px` }}
            loading={finishButtonLoading}
            invalid={finishButtonInvalid}
            onClick={finishButtonOnClick}
            large
          >
            {finishButtonText}
          </Button>
        )}
      </Container>

      <SkipButton onClick={skipOnClick} show={skipButton}>
        <Text color={colors.tertiary2Main}>{skipText}</Text>

        <SmallArrow
          direction="right"
          color={colors.tertiary2Main}
          style={{ marginLeft: margins.size2 }}
        />
      </SkipButton>
    </Card>
  );
};

type SkipButtonTypes = { show: boolean };
const SkipButton = styled(FlexRow)<SkipButtonTypes>`
  margin-left: auto;
  margin-right: ${margins.size4};
  margin-bottom: ${margins.size4};
  cursor: pointer;
  display: ${p => !p.show && 'none'};
  ${media.mobile} {
    display: ${p => !p.show && 'none'};
    margin-right: ${margins.size3};
    margin-bottom: ${margins.size3};
  }
`;

type ContainerTypes = {
  preBlock: boolean;
  lessPadded: boolean;
};
const Container = styled(FlexColumn)<ContainerTypes>`
  padding: ${p =>
    p.preBlock
      ? `0px ${margins.size5} ${margins.size6} ${margins.size5}`
      : p.lessPadded
      ? `${margins.size6} ${margins.size5}`
      : `${margins.size7} ${margins.size5}`};
  max-width: 600px;
  margin: auto;
  ${media.mobile} {
    padding: ${margins.size5} ${margins.size4};
    width: 100%;
  }
`;
