import React, { memo, useCallback } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';

import { globalTransitionSettings, colors, margins } from 'css/css';

import { getIsMobile } from 'js/util/util';
import { useCallV2Context } from 'js/components/callv2/contexts/CallContext';
import { useParticipantsContext } from 'js/components/callv2/contexts/ParticipantsContext';
import { useUIStateContext } from 'js/components/callv2/contexts/UIStateContext';

import screenShareOff from 'img/call/screen-share-off.svg';
import screenShareOn from 'img/call/screen-share-on.svg';

export const ScreenShareControls: React.FC = memo(() => {
  const { screen } = useParticipantsContext();
  const { daily, allowScreenShare } = useCallV2Context();
  const { setShowScreenSharePermissionModal } = useUIStateContext();

  const is_enabled = screen?.isLocal ?? false;
  const isMobile = getIsMobile();

  const toggleScreenShare = useCallback(async () => {
    if (daily && (!screen || screen.isLocal)) {
      if (is_enabled) daily.stopScreenShare();
      else {
        try {
          daily.startScreenShare({
            mediaStream: await navigator.mediaDevices.getDisplayMedia({ video: true }),
          });
        } catch (err) {
          if (
            err instanceof DOMException &&
            ((err.name === 'NotAllowedError' && err.message === 'Permission denied by system') ||
              err.code === err.NOT_FOUND_ERR)
          )
            setShowScreenSharePermissionModal(true);
        }
      }
    }
  }, [daily, screen, is_enabled]);

  return screen?.isLocal || (!screen && allowScreenShare && !isMobile) ? (
    <ScreenShareButton
      role="button"
      id="audio-toggle"
      isActive={is_enabled}
      title={is_enabled ? `Stop sharing screen` : `Start sharing screen`}
      onClick={toggleScreenShare}
    >
      {is_enabled ? <ScreenShareOnIcon /> : <ScreenShareOffIcon />}
    </ScreenShareButton>
  ) : null;
});

const ScreenShareButton = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  transition: ${globalTransitionSettings};
  background-color: ${p => (p.isActive ? colors.whiteMain : colors.blackMain)};
  margin-right: ${margins.size3};

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

const ScreenShareOffIcon = styled(SVG).attrs(() => ({
  src: screenShareOff,
  'alt-text': 'Screenshare icon',
}))`
  transition: ${globalTransitionSettings};
`;

const ScreenShareOnIcon = styled(SVG).attrs(() => ({
  src: screenShareOn,
  'alt-text': 'Screenshare icon',
}))`
  transition: ${globalTransitionSettings};
`;
