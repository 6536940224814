import moment, { Moment } from 'moment';
import React, { useState } from 'react';

import { FlexColumn, FlexRow, Text, margins, Clickable, Heading3, colors } from 'css/css';

import { generateDays } from 'js/util/util';
import { useMountEffect } from 'js/util/custom-hooks';
import { SmallArrow } from 'js/components/shared/SmallArrow';

import { DayButton, DayButtonText } from './SuggestedTimes';

interface Props {
  selectedDay: Moment | null;
  setSelectedDay: React.Dispatch<React.SetStateAction<Moment | null>>;
  openTimesSelector: () => void;
}

export const SchedulerCalendar: React.FC<Props> = ({
  selectedDay,
  setSelectedDay,
  openTimesSelector,
}) => {
  const [currentDate, setCurrentDate] = useState(moment());
  const weekDays = generateDays({ startDate: currentDate.clone().startOf('week'), numDays: 7 });
  const startOfMonth = currentDate.clone().startOf('month');
  const endOfMonth = currentDate.clone().endOf('month');
  const startDate = startOfMonth.clone().startOf('week');
  const endDate = endOfMonth.clone().startOf('week');
  const daysOfMonth = [];
  const day = startDate.clone();
  while (day <= endDate) {
    daysOfMonth.push(
      generateDays({
        startDate: day,
        numDays: 7,
      }),
    );
    day.add(7, 'days');
  }

  useMountEffect(() => setSelectedDay(null));

  const getIsDayInvalid = (d: Moment) => d.isBefore(moment(), 'day');

  return (
    <>
      <MonthHeading
        currentDate={currentDate}
        endOfMonth={endOfMonth}
        setCurrentDate={setCurrentDate}
      />
      <FlexRow style={{ width: '100%', marginTop: margins.size2 }} justifyContent="space-between">
        {weekDays.map(weekDay => (
          <Text style={{ width: '36px' }} key={weekDay.format('dd')}>
            {weekDay.format('dd')}
          </Text>
        ))}
      </FlexRow>
      <FlexColumn style={{ width: '100%' }}>
        {daysOfMonth.map(currWeek => (
          <FlexRow
            style={{ width: '100%' }}
            justifyContent="space-between"
            key={currWeek[0].format('YYYY-MM-DD')}
          >
            {currWeek.map(currDay => {
              return (
                <React.Fragment key={currDay.format('dd')}>
                  {currDay.isSame(currentDate, 'month') ? (
                    <DayButton
                      onClick={() => {
                        if (!getIsDayInvalid(currDay)) {
                          setSelectedDay(currDay);
                          openTimesSelector();
                        }
                      }}
                      $isSelected={!!selectedDay?.clone().isSame(currDay, 'day')}
                      $isInvalid={getIsDayInvalid(currDay)}
                    >
                      <DayButtonText
                        $isSelected={!!selectedDay?.isSame(currDay, 'day')}
                        $isInvalid={getIsDayInvalid(currDay)}
                      >
                        {currDay.format('D')}
                      </DayButtonText>
                    </DayButton>
                  ) : (
                    <div style={{ width: '36px', height: '36px' }} />
                  )}
                </React.Fragment>
              );
            })}
          </FlexRow>
        ))}
      </FlexColumn>
    </>
  );
};

interface MonthHeadingProps {
  currentDate: Moment;
  endOfMonth: Moment;
  setCurrentDate: React.Dispatch<React.SetStateAction<Moment>>;
}

const MonthHeading: React.FC<MonthHeadingProps> = ({ currentDate, endOfMonth, setCurrentDate }) => {
  const shouldShowNextMonth = endOfMonth.diff(moment(), 'days') < 7;
  const shouldShowBackArrow = !currentDate.isSame(moment(), 'month');

  return (
    <FlexRow justifyContent="space-between" style={{ width: '100%' }}>
      {shouldShowBackArrow ? (
        <Clickable onClick={() => setCurrentDate(currentDate.clone().subtract(1, 'months'))}>
          <SmallArrow direction="left" color={colors.primaryMain} />
        </Clickable>
      ) : (
        <div style={{ width: '14px' }} />
      )}
      <FlexColumn>
        <Heading3>{currentDate.format('MMMM')}</Heading3>
      </FlexColumn>
      {shouldShowNextMonth ? (
        <Clickable onClick={() => setCurrentDate(currentDate.clone().add(1, 'months'))}>
          <SmallArrow direction="right" color={colors.primaryMain} />
        </Clickable>
      ) : (
        <div style={{ width: '14px' }} />
      )}
    </FlexRow>
  );
};
