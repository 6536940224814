import React, { createRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { FlexColumn, margins, Heading3 } from 'css/css';

import { addHttpsToURL } from 'js/util/util';
import { postInviteCode } from 'js/util/api';
import { Avatar } from 'js/components/shared/Avatar';
import { useMountEffect } from 'js/util/custom-hooks';

import { emptyProfileSvg } from 'img/registration-v2';

import { RegistrationStepProps } from '../MobileRegistrationContent';

import { OnboardingFlowPage } from './OnboardingFlowPage';
import {
  InputRow,
  CheckmarkAnimated,
  StepMobileContainer,
  Seperator,
  WhiteInputGroupBox,
  OnboardingSteps,
  NUX_HEADER_STEPS,
} from './shared';
import { ProgressBar } from './ProgressBar';

export const StepNameMobile: React.FC<RegistrationStepProps> = ({
  values,
  setValues,
  submitUserInfo,
  prefillInviteCode,
}) => {
  const [isMobileKeyboardOpen, setIsMobileKeyboardOpen] = useState(false);

  const history = useHistory();

  useMountEffect(() => {
    if (window.bridge) {
      window.bridge.setShrinkViewportForKeyboard(true);
    }
    return () => {
      if (window.bridge) {
        window.bridge.setShrinkViewportForKeyboard(false);
      }
    };
  });

  const onNext = async () => {
    let orgId = 0;
    if (prefillInviteCode === 'dinner_yc') {
      orgId = 9;
    }

    if (prefillInviteCode === 'trends') {
      orgId = 71;
    }

    if (prefillInviteCode) {
      const res = await postInviteCode(prefillInviteCode);
      if (!res.ok) {
        await postInviteCode('fullskip');
      }
    } else {
      await postInviteCode('fullskip');
    }
    await submitUserInfo(
      {
        firstName: values.firstName,
        lastName: values.lastName,
        organizations: orgId ? [orgId] : null,
        profileUrls: {
          linkedin_url: addHttpsToURL(values.linkedinLink),
          twitter_url: '',
        },
      },
      () => {
        history.push(`/registration/linkedin${history.location.search}`);
      },
    );
  };

  const isFirstNameFilled = values.firstName.length > 0;
  const isLastNameFilled = values.lastName.length > 0;
  const isNextDisabled = !isFirstNameFilled || !isLastNameFilled;

  const lastNameEl = createRef<HTMLInputElement>();
  const linkedinEl = createRef<HTMLInputElement>();

  return (
    <OnboardingFlowPage
      step={OnboardingSteps.Profile}
      onNext={onNext}
      onPrev={() => history.push(`/registration/profile-picture${history.location.search}`)}
      nextDisabled={isNextDisabled}
      title={NUX_HEADER_STEPS.Profile.title}
      subtitle={NUX_HEADER_STEPS.Profile.subtitle}
      isMobileKeyboardOpen={isMobileKeyboardOpen}
    >
      <StepMobileContainer style={{ alignItems: 'center' }}>
        <ProgressBar total={4} numCompleted={1} />
        <Avatar
          src={values.image || emptyProfileSvg}
          alt={values.image ? 'profile' : 'placeholder'}
          size5
        />
        <Seperator />
        <InputContainer hide={false}>
          <Heading3>What&apos;s your first name?</Heading3>
          <WhiteInputGroupBox style={{ padding: '12px' }}>
            <InputRow htmlFor="firstName">
              <div style={{ flex: 1 }}>
                <input
                  type="text"
                  id="firstName"
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  spellCheck="false"
                  autoComplete="given-name"
                  enterKeyHint="next"
                  placeholder="Your first name"
                  value={values.firstName}
                  onFocus={() => setIsMobileKeyboardOpen(true)}
                  onChange={e => setValues({ ...values, firstName: e.target.value })}
                  onKeyUp={e => {
                    if ((e.key === 'Enter' || e.key === 'Return') && isFirstNameFilled) {
                      if (lastNameEl.current) {
                        lastNameEl.current.focus();
                      }
                    }
                  }}
                  style={{ backgroundColor: 'transparent', width: '100%' }}
                />
              </div>
              {isFirstNameFilled && <CheckmarkAnimated />}
            </InputRow>
          </WhiteInputGroupBox>
        </InputContainer>

        <InputContainer hide={!isFirstNameFilled && !isLastNameFilled}>
          <Heading3 style={{ marginTop: margins.size3 }}>What&apos;s your last name?</Heading3>
          <WhiteInputGroupBox style={{ padding: '12px' }}>
            <InputRow htmlFor="lastName">
              <div style={{ flex: 1 }}>
                <input
                  type="text"
                  id="lastName"
                  ref={lastNameEl}
                  spellCheck="false"
                  autoComplete="family-name"
                  placeholder="Your last name"
                  enterKeyHint="next"
                  value={values.lastName}
                  onChange={e => setValues({ ...values, lastName: e.target.value })}
                  onKeyUp={e => {
                    if ((e.key === 'Enter' || e.key === 'Return') && isLastNameFilled) {
                      if (linkedinEl.current) {
                        linkedinEl.current.focus();
                      } else {
                        onNext();
                      }
                    }
                  }}
                  style={{ backgroundColor: 'transparent', width: '100%' }}
                />
              </div>
              {isLastNameFilled && <CheckmarkAnimated />}
            </InputRow>
          </WhiteInputGroupBox>
        </InputContainer>
      </StepMobileContainer>
    </OnboardingFlowPage>
  );
};

const InputContainer = styled(FlexColumn)<{ hide: boolean }>`
  width: 100%;
  gap: ${margins.size2};
  ${p => p.hide && 'opacity: 0; height: 0;'}
`;
