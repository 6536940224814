import React, { useState } from 'react';
import styled from 'styled-components/macro';

import {
  FlexRow,
  FlexColumn,
  margins,
  Text,
  colors,
  UppercaseHeading3,
  Heading2,
  globalTransitionSettings,
  fontSizes,
  lineHeights,
} from 'css/css';

import { postEndorsementSuggestion, postFeedAction } from 'js/util/api';
import { apiFailure } from 'js/util/strings';
import { useNotifContext } from 'js/util/notif-context';
import { Avatar } from 'js/components/shared/Avatar';
import { useFontSizeFillingContainer } from 'js/util/use-font-size-filling-container';
import { getIsMobile } from 'js/util/util';
import { ENDORSEMENT_MODAL_DESKTOP_BODY_HEIGHT } from 'js/components/chat/endorsement-modal/constants';
import { useDidUpdateEffect } from 'js/util/custom-hooks';
import { SmallArrow } from 'js/components/shared/SmallArrow';

import { endorsementIcon, endorsementTick, thumbsUpRegular } from 'img/homepage/feed';

import { DeckCardContainer, HeightContainer } from '../styles';
import { EndorsementCardData, FeedActionType, MinimalUserDict } from '../types';
import { CARD_TRANSITION_LEAVE_DURATION } from '../constants';
import { ExpandableHeadline } from '../shared/UserDescriptionBoxes';

interface Props {
  deckCardId?: number;
  cardId: string;
  data: EndorsementCardData;
  completeCard: (cardId: string) => void;
  feedSession: 'Regular' | 'Endorsement';
}

export const EndorsementCard: React.FC<Props> = ({
  deckCardId,
  cardId,
  data,
  completeCard,
  feedSession,
}) => {
  const { showNotif } = useNotifContext();

  const [questionHeight, setQuestionHeight] = useState<number>();
  const [deckCardContentHeight, setDeckCardContentHeight] = useState<number>();
  const [wasAccepted, setWasAccepted] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = getIsMobile();
  const { user, id, category } = data;

  const endorseSkipButtons = [
    { handleClick: () => submitPersonYesNo(true), label: 'Endorse!', icon: thumbsUpRegular },
    { handleClick: () => submitPersonYesNo(false), label: 'Skip' },
  ];

  const postResponse = (accepted: boolean) =>
    postEndorsementSuggestion({
      suggestionId: id,
      source: cardId,
      isAccepted: accepted,
    });

  const submitPersonYesNo = async (isAccepted: boolean) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const res = await postResponse(isAccepted);
    if (res.ok) {
      setWasAccepted(isAccepted);
      setTimeout(() => completeCard(cardId), CARD_TRANSITION_LEAVE_DURATION);
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
    setIsLoading(false);
  };

  return (
    <DeckCardContainer
      deckCardId={deckCardId}
      isLoading={isLoading}
      isSelected={wasAccepted !== undefined}
      buttons={{
        kind: 'separate',
        values: endorseSkipButtons,
      }}
      setContentHeight={setDeckCardContentHeight}
      style={
        !isMobile && feedSession === 'Endorsement'
          ? { height: ENDORSEMENT_MODAL_DESKTOP_BODY_HEIGHT }
          : {}
      }
      feedSession={feedSession}
    >
      {deckCardContentHeight && questionHeight && (
        <EndorsementUser
          deckCardId={deckCardId}
          user={user}
          maxHeight={deckCardContentHeight - questionHeight}
        />
      )}
      <HeightContainer setHeight={setQuestionHeight}>
        <Text style={{ marginBottom: margins.size3 }}>
          Would you like to endorse {user.first_name} for {category}?
        </Text>
        <EndorsementCategory category={category} />
        <img src={endorsementIcon} alt="Endorsement icon" style={{ marginBottom: margins.size2 }} />
      </HeightContainer>
    </DeckCardContainer>
  );
};

const EndorsementCategory = ({ category }: { category: string }) => (
  <EndorsementCategoryContainer>
    <img src={endorsementTick} alt="Endorsement tick" />
    <UppercaseHeading3 style={{ color: colors.tertiary1Main }}>{category}</UppercaseHeading3>
  </EndorsementCategoryContainer>
);

const EndorsementCategoryContainer = styled(FlexRow)`
  background-color: ${colors.tertiary1Light};
  border-radius: 12px;
  width: fit-content;
  margin: 0px auto ${margins.size3} auto;
  margin-bottom: ${margins.size3};
  padding: ${margins.size2} ${margins.size3};
  gap: ${margins.size2};
`;

const AVATAR_MARGIN_BOTTOM = parseInt(margins.size2, 10);
const AVATAR_HEIGHT = parseInt(margins.size5, 10);
const ENDORSEMENT_USER_MARGIN_BOTTOM = parseInt(margins.size4, 10);

interface EndorsementUserProps {
  deckCardId?: number;
  user: MinimalUserDict;
  maxHeight: number;
}

const EndorsementUser: React.FC<EndorsementUserProps> = ({ deckCardId, user, maxHeight }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [nameHeaderHeight, setNameHeaderHeight] = useState<number>(0);

  const maxHeightOfHeadline =
    maxHeight -
    AVATAR_MARGIN_BOTTOM -
    AVATAR_HEIGHT -
    nameHeaderHeight -
    ENDORSEMENT_USER_MARGIN_BOTTOM;
  const { image, name, headline, profile: profileId } = user;
  const { textboxRef, fontSize, remainingSpace } = useFontSizeFillingContainer(
    headline,
    parseInt(fontSizes.size2, 10),
    maxHeightOfHeadline,
    lineHeights.header,
    10,
  );

  useDidUpdateEffect(() => {
    if (isExpanded && deckCardId !== undefined) {
      postFeedAction(deckCardId, { type: FeedActionType.ExpandHeadline, publicId: profileId });
    }
  }, [isExpanded]);

  return (
    <FlexColumn
      style={{
        marginBottom: ENDORSEMENT_USER_MARGIN_BOTTOM,
        transition: globalTransitionSettings,
        cursor: 'pointer',
      }}
      onClick={() => setIsExpanded(p => !p)}
    >
      <Avatar
        src={image}
        avatarId={profileId}
        size5={!isExpanded}
        size3={isExpanded}
        style={{ marginBottom: AVATAR_MARGIN_BOTTOM }}
      />
      <HeightContainer setHeight={setNameHeaderHeight}>
        <FlexRow style={{ marginBottom: margins.size2 }}>
          <Heading2 style={{ color: colors.blackMid, marginRight: margins.size1 }}>{name}</Heading2>
          <SmallArrow color={colors.blackLight} direction={isExpanded ? 'up' : 'down'} />
        </FlexRow>
      </HeightContainer>
      <ExpandableHeadline
        ref={textboxRef}
        $isExpanded={isExpanded}
        $maxHeight={maxHeightOfHeadline - (remainingSpace ?? 0)}
        style={{ fontSize }}
      >
        {headline}
      </ExpandableHeadline>
    </FlexColumn>
  );
};
