import React from 'react';
import styled from 'styled-components/macro';

import { colors, Heading3, FlexRow, margins } from 'css/css';

interface TabProps {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<'email' | 'contact'>>;
}

export const InviteMobileTab: React.FC<TabProps> = ({ selectedTab, setSelectedTab }) => {
  return (
    <TabWrapper>
      <Tab onClick={() => setSelectedTab('email')} $isSelected={selectedTab === 'email'}>
        <Heading3 color={selectedTab === 'email' ? colors.primaryMain : colors.blackMain}>
          Email
        </Heading3>
      </Tab>
      <Tab
        onClick={() => setSelectedTab('contact')}
        $isSelected={selectedTab === 'contact'}
        $isRightMostTab
      >
        <Heading3 color={selectedTab === 'contact' ? colors.primaryMain : colors.blackMain}>
          Contacts
        </Heading3>
      </Tab>
    </TabWrapper>
  );
};

const TabWrapper = styled(FlexRow)`
  width: 100%;
  justify-content: stretch;
  margin-left: 0px;
`;

const Tab = styled.div<{ $isSelected: boolean; $isRightMostTab?: boolean }>`
  width: 100%;
  background-color: ${p => (p.$isSelected ? colors.whiteMain : colors.greyMain)};
  color: ${p => (p.$isSelected ? colors.primaryMain : colors.blackMain)};
  border-radius: 5px 5px 0px 0px;
  padding: ${margins.size3};
  z-index: ${p => (p.$isSelected ? '1' : 'auto')};
  box-shadow: ${p =>
    p.$isSelected && `${p.$isRightMostTab ? '-' : ''}3px -5px 5px 0px rgba(0,0,0,0.035)`};
`;
