import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { colors, FlexColumn, FlexRow, margins, media, Text } from 'css/css';

import useRotating from 'js/util/hooks/useRotating';

import { stroke } from 'img/landing-page';

import { Heading, Subheading } from '../style';
import { ABOUT_CONTENT } from '../constants';

export const AnimatedAbout = () => {
  const [currentDescriptor, currentDescriptorIndex] = useRotating(ABOUT_CONTENT, 5000);

  return (
    <>
      <Container>
        <Subheading>HOW IT WORKS</Subheading>
        <AboutHeading>It&apos;s simple, really</AboutHeading>
        <Stroke src={stroke} alt="" />
        <InformationContainer>
          <AboutBox key={currentDescriptorIndex} {...currentDescriptor} />
        </InformationContainer>
      </Container>
    </>
  );
};

const Container = styled(FlexColumn)`
  padding: 50px 0px;
  width: 100%;
  ${media.mobile} {
    padding: ${margins.size3};
  }
`;

const AboutHeading = styled(Heading)`
  margin-top: ${margins.size4};
`;

const Stroke = styled.img`
  position: relative;
  bottom: 22px;
  left: 112px;
  width: 112px;
  ${media.mobile} {
    bottom: 20px;
    left: 75px;
    width: 75px;
  }
`;

const InformationContainer = styled(FlexRow)`
  justify-content: space-between;
  width: 80%;
  margin-top: ${margins.size5};

  ${media.mobile} {
    margin-top: 0px;
    flex-direction: row;
  }
`;

interface AboutProps {
  text: string;
  img: string;
  alt: string;
}
const AboutBox = ({ text, img, alt }: AboutProps) => (
  <AboutContainer>
    <AboutImage src={img} alt={alt} />
    <AboutText>{text}</AboutText>
  </AboutContainer>
);

const fadeInOut = keyframes`
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
      opacity: 0;
    }
`;

const AboutContainer = styled(FlexColumn)`
  animation: ${fadeInOut} 6s ease-in-out;
  ${media.mobile} {
    flex-direction: row;
    align-items: center;
  }
`;

const AboutImage = styled.img`
  height: 200px;
  ${media.mobile} {
    height: 110px;
  }
`;

const AboutText = styled(Text)`
  font-size: 21px;
  margin-top: ${margins.size5};
  width: 80%;
  text-align: center;
  color: ${colors.blackMain};
  opacity: 80%;
  ${media.mobile} {
    font-size: 16px;
    width: 60%;
    margin-top: 0px;
    margin-left: ${margins.size3};
    text-align: left;
  }
`;
