import React from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import { colors, margins, media, Heading3, Card, Clickable, SubText } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { Avatar } from 'js/components/shared/Avatar';

import crossSvg from 'img/close.svg';
import clubpointsSvg from 'img/clubpoints/clubpoints.svg';

export const InviteCard = ({ user, sendInvite, skipInvite }: any) => (
  <UserCard>
    <Clickable
      onClick={skipInvite}
      style={{ position: 'absolute', top: margins.size1, right: margins.size1 }}
    >
      <img src={crossSvg} alt="close" style={{ color: colors.blackLight }} />
    </Clickable>

    <ArangeMobile>
      <Avatar avatarId={user.email} size4 src={user.image} style={{ margin: margins.size4 }} />

      <NameButtonWrapper>
        <>
          <Name
            // @ts-expect-error ts-migrate(2365) FIXME: Operator '>' cannot be applied to types 'boolean' ... Remove this comment to see the full error message
            bottomMargin={!user.num_lc_contacts > 3}
          >{`${user.first_name} ${user.last_name}`}</Name>
          {user.num_lc_contacts > 1 && (
            <UnderName>{`${user.num_lc_contacts} connections on LC`}</UnderName>
          )}
        </>

        <Button style={{ margin: 0 }} onClick={sendInvite}>
          Invite +1 <SVG src={clubpointsSvg} alt="" />
        </Button>
      </NameButtonWrapper>
    </ArangeMobile>
  </UserCard>
);

const Name = styled(Heading3)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: ${margins.size3};
  ${media.mobile} {
    margin-bottom: ${margins.size2};
  }
`;

const UserCard = styled(Card)`
  min-width: 215px;
  height: 230px;
  position: relative;
  background-color: ${colors.whiteMain};
  ${media.mobile} {
    height: 120px;
    width: 100%;
  }
`;

const ArangeMobile = styled.div`
  ${media.mobile} {
    display: flex;
    align-items: center;
  }
`;

const NameButtonWrapper = styled.div`
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.desktop} {
    align-items: center;
  }
  ${media.mobile} {
    text-align: left;
  }
`;

const UnderName = styled(SubText)`
  margin-top: -${margins.size3};
  ${media.mobile} {
    margin-top: -${margins.size2};
  }
`;
