import React, { useState } from 'react';

import { GroupedInterests, Interests } from 'types/interests';

import { useNotifContext } from 'js/util/notif-context';

import { Button } from '../Button';

import {
  AddInterestButton,
  CategoryHeading,
  InputContainer,
  InputField,
  LeftAlign,
  SelectableInterestButton,
  WrappingRow,
  TagDeleteButton,
  InterestButton,
  SaveButtonBox,
} from './style';

const MAX_CUSTOM_LENGTH = 36; // "Ibbi is so great and I am interested"

interface InterestsProps {
  allInterests: GroupedInterests;
  chosenInterests: Interests[];
  updateSelection: (interests: Interests[]) => void;
  submitNewInterests?: (interests: Interests[]) => void;
}

export const InterestsPicker: React.FC<InterestsProps> = ({
  allInterests,
  chosenInterests,
  updateSelection,
  submitNewInterests,
}) => {
  const [customInterestFieldText, setCustomInterestFieldText] = useState('');
  const { showNotif } = useNotifContext();
  const noCategoryInterests =
    chosenInterests && chosenInterests.filter((interest: any) => interest.category === 'Other');

  const selected = (interest: any) => {
    const flattenedSelection =
      chosenInterests &&
      chosenInterests.map((currentInterest: any) => currentInterest.name.toLowerCase());
    return flattenedSelection && flattenedSelection.includes(interest.toLowerCase());
  };

  const addOrRemoveInterest = (clickedInterest: any, custom = false) => {
    if (selected(clickedInterest.name)) {
      if (custom) return;
      const newSelections = chosenInterests.filter(
        (selectedInterest: any) => selectedInterest.name !== clickedInterest.name,
      );
      updateSelection(newSelections);
    } else {
      updateSelection([...chosenInterests, clickedInterest]);
    }
  };

  const addCustomInterest = (e: any) => {
    e.preventDefault();
    if (customInterestFieldText.length) {
      addOrRemoveInterest({ category: 'Other', name: customInterestFieldText }, true);
      setCustomInterestFieldText('');
    }
  };

  const categories = Object.keys(allInterests);
  return (
    <>
      <LeftAlign>
        {categories.map(category => (
          <div key={category}>
            <CategoryHeading>{category}</CategoryHeading>

            <WrappingRow>
              {allInterests[category].map((interest: any) => (
                <SelectableInterestButton
                  key={interest.id}
                  selected={selected(interest.name)}
                  onClick={() => addOrRemoveInterest(interest)}
                >
                  {interest.name}
                </SelectableInterestButton>
              ))}
            </WrappingRow>
          </div>
        ))}

        <InputContainer onSubmit={addCustomInterest}>
          <InputField
            placeholder="Add your own"
            value={customInterestFieldText}
            onChange={(e: any) => {
              const newValue = e.target.value;
              if (newValue.length > MAX_CUSTOM_LENGTH) {
                showNotif({
                  message: 'Maximum character length, please shorten your interest.',
                  level: 'error',
                });
              } else {
                setCustomInterestFieldText(newValue);
              }
            }}
          />

          <AddInterestButton />
        </InputContainer>
        {noCategoryInterests && (
          <WrappingRow>
            {noCategoryInterests.map((customInterest: any) => (
              <InterestButton key={customInterest.name} deleting>
                <TagDeleteButton
                  tagName={customInterest.name}
                  updateInterests={() => addOrRemoveInterest(customInterest)}
                />
                {customInterest.name}
              </InterestButton>
            ))}
          </WrappingRow>
        )}
      </LeftAlign>
      {submitNewInterests && (
        <SaveButtonBox>
          <Button onClick={() => submitNewInterests(chosenInterests)}>Save</Button>
        </SaveButtonBox>
      )}
    </>
  );
};
