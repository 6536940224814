import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';

import { colors, Heading1, margins, Text } from 'css/css';

import { postPushPermissionChange, postTestActivity } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';

import promptNotificationSvg from 'img/mobile-root/prompt-notifications.svg';

import { Button } from '../shared/Button';

const TRANSITION_DURATION_MS = 280;
const LOG_TYPE = 'reg_start_notif_auth';

export const PromptForNotifications: React.FC<RouteComponentProps> = ({ history }) => {
  const [movingToOnboarding, setMovingToOnboarding] = useState(false);

  const onFinish = () => {
    setMovingToOnboarding(true);
    setTimeout(() => {
      history.push('/registration/city');
    }, TRANSITION_DURATION_MS + 20);
  };

  const onFinishWithLogger = (log: string) => {
    const newOnFinish = () => {
      postTestActivity(LOG_TYPE, log);
      onFinish();
    };
    return newOnFinish;
  };

  useMountEffect(() => {
    postTestActivity(LOG_TYPE, 'view');
  });

  return (
    <PromptForNotificationsContainer>
      <div>
        <img src={promptNotificationSvg} alt="bell" style={{ marginBottom: margins.size4 }} />
        <Heading1>
          Find out when you
          <br />
          get your matches!
        </Heading1>
        <Text style={{ marginBottom: '5vh' }}>
          Turn on notifications so you know
          <br />
          when you get matched!
        </Text>
        <Button
          onClick={() => {
            postTestActivity(LOG_TYPE, 'accept');
            if (window.bridge) {
              window.bridge.promptForNotifications('', '', authorized => {
                postPushPermissionChange(authorized);
                onFinishWithLogger('native-accept')();
              });
            } else {
              // This really shouldn't happen except for when we're testing the flow
              // in Chrome instead of in the app. Web users should never see this screen.
              onFinish();
            }
          }}
        >
          Turn on notifications
        </Button>
        <br />
        <Button
          secondary
          style={{ background: 'transparent' }}
          onClick={onFinishWithLogger('reject')}
        >
          Skip for now
        </Button>
      </div>
      <MobileFadeCover
        style={{
          background: movingToOnboarding ? colors.blackMain : 'transparent',
          transition: `background ${TRANSITION_DURATION_MS} linear`,
        }}
      />
    </PromptForNotificationsContainer>
  );
};

const PromptForNotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: ${margins.size4};
  background: ${colors.whiteMain};
  position: absolute;
  inset: 0;
`;

const MobileFadeCover = styled.div`
  position: fixed;
  inset: 0;
  pointer-events: none;
`;
