import React, { useEffect, useRef, useState, createRef, RefObject, useMemo } from 'react';
import styled from 'styled-components/macro';
import moment, { Moment } from 'moment';
import partition from 'lodash/partition';

import {
  FlexColumn,
  colors,
  margins,
  Clickable,
  FlexRow,
  fonts,
  fontSizes,
  globalTransitionSettings,
  Heading3,
} from 'css/css';

import { generateTimeslots, getIsMobile } from 'js/util/util';
import { useOutsideAlerter } from 'js/util/custom-hooks';
import { useUser } from 'js/providers/UserProvider';

import backArrow from 'img/connections/back-long-arrow.svg';

import { TimeslotButtonText } from './TimeslotButton';
import { CalendarHeaderButton } from './CustomTimes';

const MOBILE_TIMESLOT_HEIGHT = 40;

interface Props {
  hasReachedLimit: boolean;
  selectedDay: Moment | null;
  resetSelectedDay: () => void;
  setCalendarState: React.Dispatch<
    React.SetStateAction<'closed' | 'selecting day' | 'selecting times'>
  >;
  selectedCustomTimeslots: string[];
  setSelectedCustomTimeslots: React.Dispatch<React.SetStateAction<string[]>>;
  timeslotsSuggestedByMatch: string[];
}

export const CustomTimesSelector: React.FC<Props> = ({
  hasReachedLimit,
  selectedDay,
  resetSelectedDay,
  setCalendarState,
  selectedCustomTimeslots,
  setSelectedCustomTimeslots,
  timeslotsSuggestedByMatch,
}) => {
  const [amOrPm, setAmOrPm] = useState<'am' | 'pm'>('am');
  const [currentTimeslotIdx, setCurrentTimeslotIdx] = useState(0);
  const [timeslotRefs, setTimeslotRefs] = useState<any[]>([]);
  const [isScrolling, setIsScrolling] = useState(false);
  const [containerMiddlePosition, setContainerMiddlePosition] = useState<Record<string, number>>({
    top: 0,
    bottom: 0,
  });

  const user = useUser();

  const { currTimeslots, currentTimeWithBuffer } = generateTimeslots({
    startWeek: 0,
    startTime: '0:00',
    endTime: '24:00',
    numDays: 1,
    userTimezone: user.locale_info.timezone,
    bufferHours: 0,
    incrementsInMinutes: 15,
    selectedDay,
  });

  const [amTimeslots, pmTimeslots] = partition(
    currTimeslots[0],
    (timeslot: string) => moment(timeslot).format('HH:mm') < '12:00',
  );
  const amPmContainerElements = Array(6).fill(null);
  amPmContainerElements[2] = 'AM';
  amPmContainerElements[3] = 'PM';

  const isMobile = useMemo(() => getIsMobile(), []);

  const currentTimeslots = amOrPm === 'am' ? amTimeslots : pmTimeslots;
  const halfOfElementHeight = MOBILE_TIMESLOT_HEIGHT / 2;

  const mobileContainerRef = useRef<HTMLDivElement>(null);
  const amRef = useRef<HTMLDivElement>(null);
  const amPmContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef(null);
  useOutsideAlerter(containerRef, () => setCalendarState('selecting day'));

  useEffect(() => {
    if (currentTimeslots.length) {
      const timeslotsRefsArray = Array(currentTimeslots.length)
        .fill(null)
        .map(() => createRef<HTMLDivElement>());
      setTimeslotRefs(timeslotsRefsArray);
    }
  }, [currentTimeslots.length]);

  useEffect(() => {
    if (mobileContainerRef.current) {
      const containerOffsetTop = mobileContainerRef.current.offsetTop;
      const containerOffsetBottom = containerOffsetTop + mobileContainerRef.current.offsetHeight;
      const containerOffsetCenter =
        containerOffsetTop + (containerOffsetBottom - containerOffsetTop) / 2;
      const containerOffsetCenterTop =
        containerOffsetCenter - halfOfElementHeight - parseInt(margins.size3, 10);
      const containerOffsetCenterBottom =
        containerOffsetCenter + halfOfElementHeight - parseInt(margins.size3, 10);

      setContainerMiddlePosition(prev => ({
        ...prev,
        containerOffsetCenterTop,
        containerOffsetCenterBottom,
      }));
    }
  }, [mobileContainerRef.current?.getBoundingClientRect().top]);

  const getHasTimePassed = (time: string) => moment(time).isBefore(moment(currentTimeWithBuffer));

  const addTime = (timeslot: string) => {
    if (!getIsTimeslotInvalid(timeslot)) {
      setCalendarState('closed');
      setSelectedCustomTimeslots(prev => [...prev, timeslot]);
      resetSelectedDay();
    }
  };

  const getIsElementInCenter = (element: HTMLDivElement | null) => {
    if (element && element.getBoundingClientRect) {
      const { top, bottom } = element.getBoundingClientRect();
      const elementCenter = top + (bottom - top) / 2;
      return (
        elementCenter > containerMiddlePosition.containerCenterTopFromViewport &&
        elementCenter < containerMiddlePosition.containerCenterBottomFromViewport
      );
    }
    return false;
  };

  const resetContainerTop = () => {
    if (mobileContainerRef.current) {
      const {
        top: containerTop,
        bottom: containerBottom,
      } = mobileContainerRef.current.getBoundingClientRect();
      const containerCenter = containerTop + (containerBottom - containerTop) / 2;
      const containerCenterTop = containerCenter - halfOfElementHeight;
      const containerCenterBottom = containerCenter + halfOfElementHeight;
      setContainerMiddlePosition(prev => ({
        ...prev,
        containerCenterTopFromViewport: containerCenterTop,
        containerCenterBottomFromViewport: containerCenterBottom,
      }));
    }
  };

  const handleTimeslotsScroll = () => {
    resetContainerTop();
    setIsScrolling(true);
    setTimeout(() => {
      setIsScrolling(false);
    }, 1000);
    setCurrentTimeslotIdx(
      timeslotRefs.findIndex((ref: RefObject<HTMLDivElement>) => getIsElementInCenter(ref.current)),
    );
  };

  const handleAmPmScroll = () => {
    resetContainerTop();
    if (getIsElementInCenter(amRef.current)) {
      setAmOrPm('am');
    } else {
      setAmOrPm('pm');
    }
  };

  const getIsTimeslotInvalid = (timeslot: string) => {
    return (
      hasReachedLimit ||
      getHasTimePassed(timeslot) ||
      selectedCustomTimeslots.includes(timeslot) ||
      timeslotsSuggestedByMatch.includes(timeslot)
    );
  };

  if (isMobile) {
    return (
      <FlexColumn style={{ width: '100%' }}>
        <FlexRow style={{ width: '100%' }} justifyContent="space-between">
          <Clickable
            onClick={() => {
              setCalendarState('selecting day');
              resetSelectedDay();
            }}
          >
            <img src={backArrow} alt="Go back" style={{ marginBottom: '2px' }} />
          </Clickable>
          <Heading3 style={{ marginLeft: '8px', marginRight: 'auto' }}>
            {selectedDay?.format('ddd DD')}
          </Heading3>
          <Clickable
            onClick={() => addTime(currentTimeslots[currentTimeslotIdx])}
            noOutline={getIsTimeslotInvalid(currentTimeslots[currentTimeslotIdx])}
          >
            <CalendarHeaderButton
              $isInvalid={
                !isScrolling && getIsTimeslotInvalid(currentTimeslots[currentTimeslotIdx])
              }
            >
              Add time
            </CalendarHeaderButton>
          </Clickable>
        </FlexRow>
        <MobileTimesSelectorContainer ref={mobileContainerRef}>
          <MobileTimeslotsContainer onScroll={handleTimeslotsScroll}>
            {currentTimeslots.map((timeslot, i) => (
              <MobileTimeslotContainer
                ref={timeslotRefs[i]}
                $isInCenter={currentTimeslotIdx === i}
                key={timeslot}
                $isInvalid={getIsTimeslotInvalid(timeslot)}
              >
                <TimeslotButtonText $isSelected={false} $isInvalid={getIsTimeslotInvalid(timeslot)}>
                  {moment(timeslot).format('h')}
                </TimeslotButtonText>
                <TimeslotButtonText
                  $isSelected={false}
                  $isInvalid={
                    hasReachedLimit ||
                    getHasTimePassed(timeslot) ||
                    selectedCustomTimeslots.includes(timeslot)
                  }
                >
                  {moment(timeslot).format('mm')}
                </TimeslotButtonText>
              </MobileTimeslotContainer>
            ))}
          </MobileTimeslotsContainer>
          <AmPmContainer onScroll={handleAmPmScroll} ref={amPmContainerRef}>
            {amPmContainerElements.map((amPm, i) => (
              <MobileTimeslotContainer
                $isInvalid={false}
                ref={i === 2 ? amRef : null}
                $isInCenter={i === 2 ? amOrPm === 'am' : i === 3 && amOrPm === 'pm'}
              >
                <TimeslotButtonText $isInvalid={false} $isSelected={false}>
                  {amPm}
                </TimeslotButtonText>
              </MobileTimeslotContainer>
            ))}
          </AmPmContainer>
          <BreakLine $breakLineTop={containerMiddlePosition.containerOffsetCenterTop} />
          <BreakLine $breakLineTop={containerMiddlePosition.containerOffsetCenterBottom} />
        </MobileTimesSelectorContainer>
      </FlexColumn>
    );
  }

  return (
    <TimesSelectorContainer ref={containerRef}>
      {currTimeslots[0].map(timeslot => (
        <TimeslotContainer
          key={timeslot}
          $isInvalid={getIsTimeslotInvalid(timeslot)}
          onClick={() => addTime(timeslot)}
        >
          <TimeslotButtonText $isSelected={false} $isInvalid={getIsTimeslotInvalid(timeslot)}>
            {moment(timeslot).format('h:mm a')}
          </TimeslotButtonText>
        </TimeslotContainer>
      ))}
    </TimesSelectorContainer>
  );
};

const TimesSelectorContainer = styled(FlexColumn)`
  position: absolute;
  bottom: 294px;
  right: 24px;
  background-color: ${colors.whiteMain};
  border-radius: 12px;
  max-height: 300px;
  width: 120px;
  overflow-y: scroll;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
`;

const MobileTimesSelectorContainer = styled(FlexRow)`
  width: 100%;
  max-height: 200px;
  justify-content: center;
`;

const MobileTimeslotsContainer = styled(FlexColumn)`
  padding: 80px 0;
  height: 100%;
  max-height: 200px;
  overflow-y: scroll;

  scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;

  -ms-overflow-style: none;
  scrollbar-width: none;

  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2)),
    linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2));
  mask-size: 100% 50%;
  mask-repeat: no-repeat;
  mask-position: left top, left bottom;
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2)),
    linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2));
  -webkit-mask-size: 100% 50%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: left top, left bottom;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const TimeslotContainer = styled(Clickable)<{ $isInvalid: boolean }>`
  padding: 10px ${margins.size3};
  text-align: right;
  width: 100%;
  ${p => p.$isInvalid && 'cursor: default;'}

  &:hover {
    ${p => !p.$isInvalid && `background-color: ${colors.greyLight}`};
  }
`;

const MobileTimeslotContainer = styled(FlexRow)<{
  $isInvalid: boolean;
  $isInCenter?: boolean;
}>`
  padding: 10px ${margins.size3};
  text-align: left;
  width: 80px;
  min-height: ${MOBILE_TIMESLOT_HEIGHT}px;
  height: ${MOBILE_TIMESLOT_HEIGHT}px;
  gap: ${margins.size3};
  scroll-snap-align: center;

  > ${TimeslotButtonText} {
    transition: ${globalTransitionSettings};
    ${p => !p.$isInCenter && `color: ${colors.blackLight};`}
    ${p =>
      p.$isInCenter &&
      `font-family: ${fonts.bold};
       font-size: ${fontSizes.size2}; `}
  }
`;

const AmPmContainer = styled(MobileTimeslotsContainer)`
  padding: 0;
  width: 80px;
`;

const BreakLine = styled.hr<{ $breakLineTop: number }>`
  border: 1px solid ${colors.greyMain};
  position: absolute;
  top: ${p => p.$breakLineTop}px;
  width: 78%;
`;
