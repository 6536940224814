import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';

import { borders, colors, globalTransitionSettings, margins, media, Heading2 } from 'css/css';

type WeeklyHeadingType = {
  costBox?: boolean;
  noMobilePadding?: boolean;
  paddingTop?: string;
};
const WeeklyHeading = styled(Heading2)<WeeklyHeadingType>`
  padding-top: ${p => (p.costBox ? margins.size3 : margins.size5)};
  color: ${colors.blackMain};
  align-self: flex-start;
  ${media.mobile} {
    padding-top: ${p => (p.noMobilePadding ? '0px' : margins.size4)};
  }
`;

export const PreferenceHeading = styled(Heading2)<WeeklyHeadingType>`
  padding-top: ${p => p.paddingTop || margins.size4};
  margin-bottom: ${margins.size4};
  color: ${colors.blackMain};
  align-self: flex-start;
  ${media.mobile} {
    padding-top: ${margins.size4};
    padding-bottom: ${margins.size3};
    margin-bottom: 0;
  }
`;

export const WarningBox = styled.div`
  width: 100%;
  padding: ${margins.size4} 38px;
  border: ${borders.standard};
  border-radius: 3px;
  max-width: 488px;
  margin: ${margins.size4} auto;
`;

type PreferencesArrowType = {
  $isPreferencesOpen: boolean;
};
export const PreferencesArrow = styled(SVG)<PreferencesArrowType>`
  padding: 0px;
  margin: 0px ${margins.size2} ${margins.size1} 0px;
  transition: ${globalTransitionSettings};
  transform: ${p => (p.$isPreferencesOpen ? 'rotate(0deg)' : 'rotate(-90deg)')};
`;

export const SpecifyMatchesHeading = styled(WeeklyHeading)`
  cursor: pointer;
  align-self: flex-start;
  padding: ${margins.size4} 0px ${margins.size5} 0px;
  margin-bottom: 0;
  ${media.mobile} {
    padding-bottom: ${margins.size4};
  }
`;
