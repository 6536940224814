import { DailyParticipant } from '@daily-co/daily-js';

import { Participant } from './types';

export const getNewParticipant = (participant: DailyParticipant): Participant => {
  const id = getId(participant);

  const { local } = participant;
  const { audio, video } = participant.tracks;

  return {
    id,
    isActiveSpeaker: false,
    isCamMuted:
      video?.state === 'off' || video?.state === 'blocked' || video?.state === 'interrupted',
    isLoading: audio?.state === 'loading' || video?.state === 'loading',
    isLocal: local,
    isMicMuted:
      audio?.state === 'off' || audio?.state === 'blocked' || audio?.state === 'interrupted',
    isScreenshare: false,
    name: participant.user_name,
  };
};

export const getUpdatedParticipant = (
  participant: DailyParticipant,
  participants: Participant[],
): Participant => {
  const id = getId(participant);
  const prevItem = participants.find(p => p.id === id);

  // In case we haven't set up this participant, yet.
  if (!prevItem) return getNewParticipant(participant);

  const { local } = participant;
  const { audio, video } = participant.tracks;

  return {
    ...prevItem,
    id,
    isCamMuted:
      video?.state === 'off' || video?.state === 'blocked' || video?.state === 'interrupted',
    isLoading: audio?.state === 'loading' || video?.state === 'loading',
    isLocal: local,
    isMicMuted:
      audio?.state === 'off' || audio?.state === 'blocked' || audio?.state === 'interrupted',
    name: participant.user_name,
  };
};

export const getScreenItem = (participant: DailyParticipant): Participant => {
  const id = getId(participant);
  return {
    id: getScreenId(id),
    isLoading: false,
    isLocal: participant.local,
    isScreenshare: true,
    name: participant.user_name,
  };
};

// --- Derived data ---

export const getId = (participant: DailyParticipant): string =>
  participant.local ? 'local' : participant.session_id;

export const getScreenId = (id: string): string => `${id}-screen`;

export const isLocalId = (id: string): boolean => typeof id === 'string' && id === 'local';

export const isScreenId = (id: string): boolean => typeof id === 'string' && id.endsWith('-screen');
