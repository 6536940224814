import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import Select, { OptionTypeBase, components, InputActionMeta, ActionMeta } from 'react-select';
import SVG from 'react-inlinesvg';

import { margins, colors, borders, fontSizes } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { useUser, useUserAvailability, useUserContextProvider } from 'js/providers/UserProvider';
import { filterOptions, useAutoCompleteSearch } from 'js/util/react-select';

import locationIcon from 'img/settings/location.svg';

const searchResultsToOptions = (results: any) =>
  results.map((res: any) => ({ label: res.description, value: res.place_id }));

type Props = {
  localeBlocked: boolean;
};

type RequestBody = {
  googlePlaceId: any;
  autoLocale: boolean;
  visualSettings?: Record<string, any>;
};

export const CitySelect: React.FC<Props> = ({ localeBlocked }) => {
  const user = useUser();
  const { updateUser, updateUserAvailability, fetchUser } = useUserContextProvider();
  const userAvailability = useUserAvailability();

  const { query, setSearchQuery, searchResults, isDebouncing, error } = useAutoCompleteSearch();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const defaultQuery = user.city;
  const isDisabled = error || localeBlocked;
  const isLoading = isDebouncing && query !== defaultQuery;

  useEffect(() => {
    setSearchQuery(defaultQuery);
  }, [defaultQuery, error]);

  const onSubmit = useCallback(
    async (locale: OptionTypeBase, event: ActionMeta<OptionTypeBase>) => {
      if (event.action === 'select-option') {
        const googlePlaceId = locale.value;

        const reqBody: RequestBody = { googlePlaceId, autoLocale: true };

        if (userAvailability?.availability_autopilot?.autopilot) {
          reqBody.visualSettings = {
            has_completed_autopilot_flow: false,
          };
        }

        setIsSaving(true);
        await updateUser(reqBody);
        await Promise.all([fetchUser(), updateUserAvailability()]);
        setIsSaving(false);
      }
    },
    [],
  );

  const onInputChange = useCallback((input: string, event: InputActionMeta) => {
    if (event.action === 'input-change') {
      setSearchQuery(input);
    }
  }, []);

  const CustomIndicatorsContainer = (props: any) => (
    <>
      <components.IndicatorsContainer {...props} />
      <StyledButton
        small
        secondary
        invalid={isDisabled}
        onClick={() => setIsEditing(prevIsEditing => !prevIsEditing)}
      >
        {isSaving ? 'saving...' : isLoading ? 'loading...' : isEditing ? 'cancel' : 'edit'}
      </StyledButton>
    </>
  );

  /**
   * Forces <SingleValue /> to display the updated input
   *
   * Source: https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/Select.tsx#L1628-L1630
   */
  const CustomSingleValue = (props: any) => (
    <components.SingleValue {...props}>{query}</components.SingleValue>
  );

  return (
    <StyledSelect
      placeholder="Find your city!"
      styles={cityStyles}
      theme={(theme: any) => ({
        ...theme,

        colors: {
          ...theme.colors,
          primary: colors.blackLight,
          primary50: colors.blackLight,
        },
      })}
      blurInputOnSelect
      inputValue={query}
      options={searchResultsToOptions(searchResults)}
      onChange={onSubmit}
      onInputChange={onInputChange}
      isDisabled={isDisabled}
      onBlur={() => setIsEditing(false)}
      onFocus={() => setIsEditing(true)}
      filterOption={filterOptions}
      menuIsOpen={isEditing && searchResults.length > 0 && !isDebouncing}
      components={{
        Input: CustomInput,
        Option: CustomOption,
        SingleValue: CustomSingleValue,
        NoOptionsMessage: () => null, // NoOptionsMessage should never be displayed since the menu is closed when there are no search results
        IndicatorsContainer: CustomIndicatorsContainer,
      }}
    />
  );
};

/**
 * Prevents <Input /> from hiding the input value
 *
 * Sources:
 * - https://github.com/JedWatson/react-select/blob/a92c09a836450cbb849f3a90eade18e1320cb651/packages/react-select/src/components/Input.tsx#L54
 * - https://github.com/JedWatson/react-select/blob/a92c09a836450cbb849f3a90eade18e1320cb651/packages/react-select/src/components/Input.tsx#L46
 */
const CustomInput = (props: any) => {
  return (
    <>
      <StyledLocationIcon />
      <components.Input {...props} isHidden={false} isDisabled={false} />
    </>
  );
};

const CustomOption = (props: any) => {
  const { isFocused } = props;
  return (
    <div
      style={{
        padding: margins.size1,
        backgroundColor: isFocused ? colors.greyMain : '',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <components.Option {...props} />
      </div>
    </div>
  );
};

const StyledButton = styled(Button)`
  margin-right: ${margins.size3};
`;

const StyledLocationIcon = styled(SVG).attrs({ src: locationIcon, alt: 'Location Icon' })`
  width: 15px;
  height: 15px;
  min-width: 15px;
  max-width: 15px;
`;

const StyledSelect = styled(Select)`
  width: 250px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  color: ${colors.blackLight};
`;

const cityStyles = {
  input: (provided: any) => ({
    ...provided,
    color: colors.blackMid,
    marginLeft: margins.size3,
    fontSize: fontSizes.size2,
    display: 'flex',
    flexGrow: 1,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: colors.blackMid,
    textAlign: 'left',
    fontSize: fontSizes.size2,
    backgroundColor: state.isFocused ? colors.greyMain : '',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: colors.blackMid,
    fontSize: fontSizes.size2,
    marginLeft: '31px', // Location Icon width + Input's left margin
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: fontSizes.size2,
    marginLeft: '31px', // Location Icon width + Input's left margin
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  control: (provided: any) => ({
    ...provided,
    borderStyle: 'none',
    borderRadius: '5px',
    border: borders.variation,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: `calc(${margins.size1} + 2px) ${margins.size3}`,
  }),
};
