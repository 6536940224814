import React, { useState } from 'react';

import { FeedbackForm } from './FeedbackForm';
import { AskSignup } from './AskSignup';

export const Feedback: React.FC = () => {
  const [shouldAskSignup, setShouldAskSignup] = useState<boolean>(true);

  if (shouldAskSignup) return <AskSignup setShouldAskSignup={setShouldAskSignup} />;

  return <FeedbackForm />;
};
