import DailyIframe, {
  DailyCall,
  DailyCallOptions,
  DailyEvent,
  DailyEventObject,
} from '@daily-co/daily-js';
import { useEffect, useState } from 'react';

import { useUser } from 'js/providers/UserProvider';

// https://github.com/daily-co/daily-js/issues/154 Missing userName property in DailyCallOptions type definition
interface DailyCallOptionsWithUsername extends DailyCallOptions {
  userName: string;
}

export type CallMode = 'direct-link' | 'embedded';

export type CallState =
  | 'awaiting-args'
  | 'ready'
  | 'lobby'
  | 'joining'
  | 'joined'
  | 'ended'
  | 'error'
  | 'expired'
  | 'full'
  | 'nbf'
  | 'not-allowed'
  | 'not-found'
  | 'not-secure'
  | 'removed-from-call';

export type TUseCallMachine = {
  daily: DailyCall | null;
  state: CallState;
};

/**
 * This hook sets up the local call state machine and keeps track of the application state.
 * @param url – The call url
 */
export const useCallMachine = (url?: string): TUseCallMachine => {
  const user = useUser();
  const [daily, setDaily] = useState<TUseCallMachine['daily']>(null);
  const [state, setState] = useState<TUseCallMachine['state']>('ready');

  /**
   * Preauthenticates, so we know about the user's access state and the room's config.
   * Puts the machine into the next state, based on access state and room config.
   * @param co – The DailyCall object.
   */
  const preAuth = async (co: DailyCall) => {
    const { access } = await co.preAuth({
      subscribeToTracksAutomatically: false,
      url,
    });
    // @ts-ignore
    window.callObject = co;

    /**
     * Private room and no `token` was passed.
     */
    if (access === 'unknown' || access?.level === 'none') {
      return;
    }

    /**
     * Public room or private room with passed `token` and `enable_prejoin_ui` is `false`.
     */
    setState('joining');

    await co.join({
      subscribeToTracksAutomatically: false,
      url,
      userName: user.profile_id,
    } as DailyCallOptionsWithUsername);
    setState('joined');
  };

  /**
   * Set up the call object and preauthenticate.
   */
  useEffect(() => {
    if (daily || !url || state !== 'ready' || !user) return;

    const args: DailyCallOptions = {
      url,
      dailyConfig: {
        experimentalChromeVideoMuteLightOff: true,
        useDevicePreferenceCookies: true,
      },
    };
    const co = DailyIframe.createCallObject(args);

    setDaily(co);
    preAuth(co);
  }, [daily, url, state, user]);

  /**
   * Set up listeners for meeting state changes
   */
  useEffect(() => {
    if (!daily) return () => undefined;

    const events: DailyEvent[] = ['joined-meeting', 'joining-meeting', 'left-meeting', 'error'];

    const handleMeetingState = async <T extends DailyEvent>(event?: DailyEventObject<T>) => {
      const { access } = daily.accessState();
      switch (event?.action) {
        /**
         * Don't transition to 'joining' or 'joined' UI as long as access is not 'full'.
         * This means a request to join a private room is not granted, yet.
         * Technically in requesting for access, the participant is already known
         * to the room, but not joined, yet.
         */
        case 'joining-meeting':
          if (access === 'unknown' || access.level === 'none' || access.level === 'lobby') return;
          setState('joining');
          break;
        case 'joined-meeting':
          if (access === 'unknown' || access.level === 'none' || access.level === 'lobby') return;
          setState('joined');
          break;
        case 'left-meeting':
          daily.destroy();
          break;
        case 'error':
          switch (event?.error?.type) {
            case 'nbf-room':
            case 'nbf-token':
              daily.destroy();
              setState('nbf');
              break;
            case 'exp-room':
            case 'exp-token':
              daily.destroy();
              setState('expired');
              break;
            case 'ejected':
              daily.destroy();
              setState('removed-from-call');
              break;
            default:
              switch (event?.errorMsg) {
                case 'Meeting has ended':
                  /**
                   * Meeting has ended or participant was removed by an owner.
                   */
                  daily.destroy();
                  setState('ended');
                  break;
                case 'Meeting is full':
                  daily.destroy();
                  setState('full');
                  break;
                case "The meeting you're trying to join does not exist.":
                  daily.destroy();
                  setState('not-found');
                  break;
                case 'You are not allowed to join this meeting':
                  daily.destroy();
                  setState('not-allowed');
                  break;
                default:
                  daily.destroy();
                  setState('error');
                  break;
              }
              break;
          }
          break;
        default:
          break;
      }
    };

    // Listen for changes in state
    events.forEach(event => daily.on(event, handleMeetingState));

    // Stop listening for changes in state
    return () => {
      events.forEach(event => daily.off(event, handleMeetingState));
    };
  }, [daily, state, url]);

  return {
    daily,
    state,
  };
};
