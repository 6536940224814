import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { FlexColumn, colors, Heading2, Text, margins, media } from 'css/css';

import { getIsMobile } from 'js/util/util';

import { scheduleIconLight } from 'img/homepage/feed';

import { Backdrop } from '../shared/MobileModal';
import { Button } from '../shared/Button';
import { getModalTransitionDuration, ModalContainer } from '../chat/endorsement-modal';

type DiscoverBookingModalStates = 'Closed' | 'Open';

export const useDiscoverBookingModal = () => {
  const [state, setState] = useState<DiscoverBookingModalStates>('Closed');
  const [canBeClosed, setCanBeClosed] = useState(false);
  const modalContainerRef = useRef<HTMLDivElement>(null);

  const isOpen = state === 'Open';
  const isMobile = getIsMobile();
  const isFullScreen = !isMobile;
  const transitionDuration = getModalTransitionDuration(isFullScreen);

  const closeModal = () => {
    if (canBeClosed) {
      setState('Closed');
    }
  };
  const openModal = () => setState('Open');

  const modal = (
    <>
      <Backdrop
        isOpen={isOpen}
        transitionDuration={transitionDuration}
        blur={15}
        style={{ zIndex: 4 }}
      />
      <ModalContainer
        $isOpen={isOpen}
        $isFullScreen={isFullScreen}
        ref={modalContainerRef}
        onTransitionEnd={event => {
          if (modalContainerRef.current === event.target) {
            setCanBeClosed(b => !b);
          }
        }}
      >
        <DiscoverBookingModalBody handleClick={closeModal} />
      </ModalContainer>
    </>
  );

  return [openModal, modal] as const;
};

const DiscoverBookingModalBody = ({ handleClick }: { handleClick: () => void }) => {
  const isMobile = getIsMobile();

  return (
    <DiscoverBookingModalBodyContainer>
      <FlexColumn style={{ gap: margins.size2, ...(isMobile ? {} : { width: 376 }) }}>
        <img src={scheduleIconLight} alt="Book a meeting" />
        <Heading2>Book meetings with people you’d like to meet!</Heading2>
        <Text>
          Meet the people our AI found for you. Just book a meeting with their next available time!
        </Text>
      </FlexColumn>
      <Button large={isMobile} onClick={handleClick}>
        See profiles
      </Button>
    </DiscoverBookingModalBodyContainer>
  );
};

const DiscoverBookingModalBodyContainer = styled(FlexColumn)`
  width: 100%;
  justify-content: center;
  background-color: ${colors.whiteMain};
  gap: ${margins.size3};
  padding: ${margins.size4} ${margins.size3}
    calc(max(env(safe-area-inset-bottom), ${margins.size4})) ${margins.size3};
  ${media.desktop} {
    height: 600px;
    border-radius: 10px;
  }
`;
