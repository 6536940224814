import React, { useEffect } from 'react';

import { useUserAvailability } from 'js/providers/UserProvider';
import { useChatContext } from 'js/providers/ChatContextProvider';

export const BadgeCountSetter: React.FC = () => {
  const userAvailability = useUserAvailability();
  const { unreadCount } = useChatContext();

  useEffect(() => {
    if (!window.bridge?.setBadgeCount) {
      return;
    }
    const badgeCount =
      unreadCount +
      (userAvailability?.has_signed_up_weekly ||
      userAvailability?.has_signed_up_weekly === undefined
        ? 0
        : 1);
    window.bridge.setBadgeCount(badgeCount);
  }, [userAvailability, unreadCount]);

  return <></>;
};
