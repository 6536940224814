import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';
import styled from 'styled-components/macro';

import { Card, FlexColumn, margins, media } from 'css/css';

import { availability } from 'types/availability';
import { UserInterest } from 'types/interests';

import { CoachingPlanEligibilityResult, postAcceptCoachingPlan } from 'js/api/coaching-plan';
import {
  useTestBuckets,
  useUser,
  useUserAvailability,
  useUserContextProvider,
} from 'js/providers/UserProvider';
import { useDataContext } from 'js/providers/DataContextProvider';
import { GreyPage } from 'js/components/shared/page-wrappers';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import {
  getBusyTimeslots,
  getInterests,
  getMatchFeedbackCode,
  getObjectives,
  getWeeklyQuestion,
  postAutopilotAvailable,
  postAvailability,
  postClubpointsAvailability,
  postNewUserAutopilotAvailable,
  postTestActivity,
  postWeeklyQuestion,
} from 'js/util/api';
import { useNotifContext } from 'js/util/notif-context';
import {
  addParameterToURL,
  COMMUNITIES,
  getIsMobile,
  getParameterByName,
  getTimezoneAbbr,
  INTERNATIONAL_TIME_LOCALES,
  isSameSet,
} from 'js/util/util';
import { useDidUpdateEffect, useMountEffect } from 'js/util/custom-hooks';
import { CoachingPlanOptIn } from 'js/components/coaching_plan/CoachingPlanOptIn';
import { useResource } from 'js/util/use-resource';

import { TopBanner } from './TopBanner';
import { MissingHeadline } from './MIssingHeadline';
import { LocaleNotAvailable } from './LocaleNotAvailable';
import { Snowflaked } from './Snowflaked';
import { WeeklyGauth } from './WeeklyGauth';
import {
  MeetingPreferenceQuestion,
  ObjectiveQuestion,
  PickMeetingQuestion,
  PreferencesDropdown,
  SlantMatchToggle,
  TimeslotMeetingQuestion,
  WeeklyQuestion,
} from './questions';
import {
  InPersonInviteModal,
  MeetingFooter,
  ScheduleHeader,
  SecondMatchMessage,
  SlantMatchOptInModal,
} from './components';
import { PondSuggestions } from './PondSuggestions';
import { StreakFinishButton } from './components/StreakFinishButton';
import { Community, Locale, Objective, WeeklyQuestionType } from './types';
import { MobileMatchesModal } from './MobileMatchesModal';
import { MEETING_OPTIONS } from './pickers/options';
import { WeeklyContacts } from './WeeklyContacts';
import { WeeklyNotifs } from './WeeklyNotifs';
import { AutopilotOptInModal } from './components/AutopilotOptInModal';
import { NewUserForcedAutopilotModal } from './components/NewUserForcedAutopilotModal';
import { AutopilotHabitualModal } from './components/AutopilotHabitualModal';
import { UpdateAutopilotModal } from './components/UpdateAutopilotModal';
import { AutopilotForced } from './AutopilotForced';

const EXTRA_MATCH_COST = 10;
const PICK_MATCH_COST = 30;
const TARGET_LOCALE_COST = 5;
export const TIME_SELECTION_MIN = 1;
const STREAK_THRESHOLD = 8;
const ANIMATION_DURATION_SHORT = 1500;
const ANIMATION_DURATION_LONG = 2300;
export const MIDNIGHT_DAY_FORMAT = 'YYYY-MM-DD 00:00:00'; // Note: Technically this should be Tuesday, 20:00:00.

interface TestClubpoints {
  newSelectedNumberMeetings?: number;
  newSelectedPickMatch?: number;
  newTargetLocaleId?: number | null;
  message: (missingPoints: number) => string;
}

export const getCutoffDay = (args: {
  homeIsNextWeek: boolean;
  userTimezone: string;
}): [string, string] => {
  const dayINeed = 3; // for Wednesday
  const homeTimeNow = moment().tz(args.userTimezone);

  if (args.homeIsNextWeek) {
    return [
      homeTimeNow
        .clone()
        .add(1, 'weeks')
        .day(dayINeed)
        .format(MIDNIGHT_DAY_FORMAT),
      homeTimeNow
        .clone()
        .add(2, 'weeks')
        .day(dayINeed)
        .format(MIDNIGHT_DAY_FORMAT),
    ];
  }
  return [
    homeTimeNow
      .clone()
      .day(dayINeed)
      .format(MIDNIGHT_DAY_FORMAT),
    homeTimeNow
      .clone()
      .add(1, 'weeks')
      .day(dayINeed)
      .format(MIDNIGHT_DAY_FORMAT),
  ];
};

const getSpentClubpoints = (pastAvailability: availability | undefined): number => {
  if (!pastAvailability) return 0;
  let spentPoints = 0;
  const pastNumberMeetings = pastAvailability.max_matches;
  if (pastNumberMeetings > 3) {
    spentPoints += (pastNumberMeetings - 3) * EXTRA_MATCH_COST;
  }

  if (
    pastAvailability.target_locale !== null &&
    pastAvailability.target_locale !== pastAvailability.locale
  ) {
    spentPoints += pastNumberMeetings * TARGET_LOCALE_COST;
  }

  if (pastAvailability.target_role !== null) {
    spentPoints += pastNumberMeetings * PICK_MATCH_COST;
  }

  return spentPoints;
};

export const collectIntoCalendarDays = (args: {
  timeslots?: string[];
  selectedLocale: Locale | undefined;
  targetTimezone: string;
  window: [string, string]; // YYYY-MM-DD 00:00:00
}) => {
  const { timeslots, targetTimezone, selectedLocale } = args;
  const is24Hour = selectedLocale?.id && INTERNATIONAL_TIME_LOCALES.includes(selectedLocale.id);
  const homeTimezone = selectedLocale?.timezone || '';
  const [startWindow, endWindow] = args.window;

  const passedTimeslots = timeslots ? [...timeslots] : [];
  const localAdjustedTimeslots = passedTimeslots
    .map(timeslot =>
      moment
        .tz(timeslot, targetTimezone)
        .clone()
        .tz(homeTimezone)
        .format('YYYY-MM-DD HH:mm:ss'),
    )
    .filter(timeslot => startWindow <= timeslot && timeslot <= endWindow);

  const days = [];
  while (localAdjustedTimeslots.length) {
    const targetDayFormat = moment(localAdjustedTimeslots[0]).format('YYYY-MM-DD');
    const daySlots = localAdjustedTimeslots.filter(
      timeslot => moment(timeslot).format('YYYY-MM-DD') === targetDayFormat,
    );
    days.push(daySlots);
    localAdjustedTimeslots.splice(0, daySlots.length);
  }

  const resTimeslots = days.map((day, i) => ({
    id: i,
    header: moment(day[0]).format('ddd MMM D'),
    options: day.map((timeslot, j) => {
      return {
        id: j,
        option: timeslot,
        label: is24Hour ? moment(timeslot).format('HH:mm') : moment(timeslot).format('h:mma'),
      };
    }),
  }));
  return resTimeslots;
};

function useRedirectIfMatchFeedbackCode() {
  const history = useHistory();

  useMountEffect(() => {
    const loadFeedbackCode = async () => {
      const res = await getMatchFeedbackCode();
      if (res.status === 201) {
        const code = res.getJson.feedback_code[0];
        const maxSessionFeedback = getParameterByName('msf');
        history.push(
          addParameterToURL(
            'msf',
            maxSessionFeedback,
            addParameterToURL('ref', 'weekly', `/feedback?feedback_code=${code}`),
          ),
        );
      }
    };
    loadFeedbackCode();
  });
}

export const AUTOPILOT_TESTS = {
  AUTOPILOT_HABITUAL: 1,
  AUTOPILOT_FORCED_NUX: 2,
  AUTOPILOT_ALL: 3,
};

export const Weekly: React.FC = () => {
  const user = useUser();
  const userAvailability = useUserAvailability();
  const { fetchUser, updateUserAvailability, fetchTestBuckets } = useUserContextProvider();
  const { allTimeslots, allLocales, allActiveLocales } = useDataContext();
  const history = useHistory();
  const { showNotif } = useNotifContext();
  const { cohesiveAutopilotTest, slantOptInTest } = useTestBuckets();
  useRedirectIfMatchFeedbackCode();

  const [loading, setLoading] = useState(false);
  const [foldLoading, setFoldLoading] = useState(true);
  const [submittingWeekly, setSubmittingWeekly] = useState(false);
  const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);

  const [rawObjectives, setRawObjectives] = useState<Objective[]>([]);
  const [weeklyQuestion, setWeeklyQuestion] = useState<WeeklyQuestionType>();

  const [interests, setInterests] = useState<UserInterest[]>([]);

  const [selectedQuestionAnswer, setSelectedQuestionAnswer] = useState<number>();
  const [selectedLocaleId, setSelectedLocaleId] = useState<number>();
  const [preferTheseCommunities, setPreferTheseCommunities] = useState<number[]>([]);
  const [selectedTimeslots, setSelectedTimeslots] = useState<string[]>([]);
  const [targetLocaleId, setTargetLocaleId] = useState<number | null>(null);
  const [selectedNumberMeetings, setSelectedNumberMeetings] = useState<number>(0);
  const [selectedPickMatch, setSelectedPickMatch] = useState<number>(0);
  const [selectedObjective, setSelectedObjective] = useState<number>();
  const [missingUnderFivePoints, setMissingUnderFivePoints] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [shouldAskForNotifs, setShouldAskForNotifs] = useState(false);
  const [shouldFetchUserAgain, setShouldFetchUserAgain] = useState(false);
  const [showUpdateAutopilotModal, setShowUpdateAutopilotModal] = useState(false);

  const [targetInterest, setTargetInterest] = useState<number>();
  const [busyTimeslots, setBusyTimeslots] = useState<string[]>([]);

  const [promptInPerson, setPromptInPerson] = useState(false);

  const [inPersonTimeslots, setInPersonTimeslots] = useState<string[]>([]);

  const [slantOptIn, setSlantOptIn] = useState(false);
  const [promptSlantModal, setPromptSlantModal] = useState(false);

  const [showAutopilotModal, setShowAutopilotModal] = useState(false);
  const [isAutopilotSignup, setIsAutopilotSignup] = useState(false);
  const [autopilotTestVersion, setAutopilotTestVersion] = useState<number | undefined>();

  const [onCoachingPlan, setOnCoachingPlan] = useState(false);
  const [coachingPlanEligibility] = useResource<CoachingPlanEligibilityResult>(
    'discover/coaching_plan/eligible',
  );
  const [showCoachingPlan, setShowCoachingPlan] = useState<boolean>(false);
  const [coachingPlanSignupType, setCoachingPlanSignupType] = useState<number | undefined>();
  const [decidedCoachingPlan, setDecidedCoachingPlan] = useState(false);
  const [shouldRedirectToMeetings, setShouldRedirectToMeetings] = useState(false);
  const [shouldShowForcedAutopilot, setShouldShowForcedAutopilot] = useState(false);

  const userClubpoints = user?.clubpoints || 0;
  const newUser = !user.was_matched;
  const onAutopilot = userAvailability?.autopilot || false;
  const stillAutopilot =
    !!userAvailability?.availability_autopilot?.time_slots?.length &&
    isSameSet(selectedTimeslots, userAvailability?.availability_autopilot?.time_slots);

  // Coaching plan meetings should not cost clubpoints
  const maxMeetings =
    onCoachingPlan || coachingPlanSignupType ? 3 : newUser ? 2 : MEETING_OPTIONS.length - 1;

  const mergeInterests = (
    userInterestsArr: UserInterest[],
    allInterests: UserInterest[],
  ): UserInterest[] => {
    const userInterestsIds = user.interests ? user.interests.map(interest => interest.id) : [];
    const cleanedAllInterests = allInterests.filter(
      interest => !userInterestsIds.includes(interest.id),
    );
    return [...userInterestsArr, ...cleanedAllInterests];
  };

  const setCommunities = () => {
    if (user.organizations) {
      const orgs = user.organizations;
      const filteredOrgs = orgs.filter(org => COMMUNITIES[org]);
      return filteredOrgs.map(org => COMMUNITIES[org]);
    }
    return [];
  };
  const communities: Community[] = setCommunities();
  const platform = window?.bridge ? 'ios' : getIsMobile() ? 'mobile' : 'desktop';

  useMountEffect(() => {
    setShouldRedirectToMeetings(
      cohesiveAutopilotTest && !!user.visual_settings?.has_completed_autopilot_flow,
    );
    setShouldShowForcedAutopilot(cohesiveAutopilotTest && !userAvailability?.time_slots.length);
  });

  useEffect(() => {
    if (!decidedCoachingPlan && coachingPlanEligibility?.eligible) {
      setShowCoachingPlan(true);
    }
  }, [coachingPlanEligibility]);

  useEffect(() => {
    setOnCoachingPlan(userAvailability?.coaching_plan_details?.on_coaching_plan ?? false);
    setShouldRedirectToMeetings(
      cohesiveAutopilotTest &&
        (!!userAvailability?.availability_autopilot?.autopilot || !!user.autopilot_paused_until),
    );
  }, [userAvailability]);

  useMountEffect(() => {
    const fetchNewUserAutopilotTest = async () => {
      if (!user.was_matched) {
        const res = await postNewUserAutopilotAvailable();
        if (res.getJson.autopilot) {
          setAutopilotTestVersion(AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX);
          postTestActivity('new-user-autopilot', `${platform}-view`);
        }
      }
    };
    fetchNewUserAutopilotTest();
  });

  useEffect(() => {
    const loadObjectives = async () => {
      const res = await getObjectives();
      if (res.status === 200) {
        const cleanedObjectives = res.getJson.map((objective: Objective) => ({
          id: objective.id,
          name: objective.name,
        }));
        setRawObjectives(cleanedObjectives);
      }
    };

    const loadInterests = async () => {
      const res = await getInterests();
      if (res.status === 200) {
        const rawInterests: UserInterest[] = Object.values(res.getJson);
        const cleanedInterests = rawInterests
          .reduce((acc: UserInterest[], interest: UserInterest) => acc.concat(interest), [])
          .filter(interest => interest.is_topic);
        const cleanedUserInterests = user.interests?.length
          ? user.interests.filter(interest => interest.is_topic)
          : [];

        const sortedMergedInterests = mergeInterests(
          cleanedUserInterests,
          cleanedInterests,
        ).sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0,
        );

        setInterests(sortedMergedInterests);
      }
    };

    const loadWeeklyQuestion = async () => {
      const res = await getWeeklyQuestion();
      if (res.status === 200) setWeeklyQuestion(res.getJson);
    };

    const loadBusyTimes = async () => {
      if (user.has_google) {
        const res = await getBusyTimeslots();
        if (res.ok) setBusyTimeslots(res.getJson);
      }
    };

    if (
      !allTimeslots.length ||
      !allLocales.length ||
      !allActiveLocales.length ||
      userAvailability === undefined
    ) {
      setLoading(true);
      return;
    }

    setLoading(false);
    if (userAvailability.locale !== user.locale) {
      setSelectedLocaleId(user.locale);
      return;
    }

    const localeInPersonTimeslots: string[] =
      allTimeslots.find(timeslots => timeslots.id === userAvailability.locale)?.inPersonTimeslots ||
      [];
    if (localeInPersonTimeslots.length) {
      setInPersonTimeslots(localeInPersonTimeslots);
    }

    const userSelectedTimeslots = userAvailability.time_slots
      .filter(timeslot => !localeInPersonTimeslots.includes(timeslot))
      .sort();

    const passedForWeek = userAvailability?.passed;
    const userAutopilotSlots = userAvailability?.availability_autopilot?.time_slots;
    const displayIsAutopilot =
      !!userAvailability?.autopilot ||
      (!!userAvailability?.availability_autopilot?.time_slots.length &&
        isSameSet(
          userAvailability.time_slots,
          userAvailability?.availability_autopilot?.time_slots,
        )) ||
      (!!userAvailability?.availability_autopilot?.time_slots?.length &&
        !userAvailability.has_signed_up_weekly);

    setTargetLocaleId(userAvailability.target_locale);
    setSelectedLocaleId(userAvailability.locale);
    setSelectedNumberMeetings(userAvailability.max_matches);
    setSelectedTimeslots(
      passedForWeek
        ? []
        : userSelectedTimeslots.length
        ? userSelectedTimeslots
        : userAutopilotSlots?.length
        ? userAutopilotSlots
        : [],
    );
    setSelectedPickMatch(
      userAvailability.target_role !== null ? userAvailability.target_role + 1 : 0,
    );
    setTargetInterest(userAvailability.target_interest || undefined);
    setSelectedObjective(userAvailability.weekly_objective?.id || undefined);
    setPreferTheseCommunities(userAvailability.organization_ids || []);
    setSlantOptIn(userAvailability.slant_opt_in);
    setIsAutopilotSignup(displayIsAutopilot);

    Promise.all([loadObjectives(), loadInterests(), loadWeeklyQuestion()]).then(() =>
      setFoldLoading(false),
    );
    loadBusyTimes();
  }, [allTimeslots, allLocales, allActiveLocales, userAvailability, user.objective_ids]);

  useDidUpdateEffect(() => {
    if (userAvailability?.target_locale !== targetLocaleId) {
      setSelectedTimeslots([]);
    }
  }, [selectedLocaleId, targetLocaleId]);

  useEffect(() => {
    setSelectedNumberMeetings(selectedTimeslots.length + (slantOptIn ? 1 : 0));
  }, [selectedTimeslots, slantOptIn]);

  useEffect(() => {
    if (!window.bridge || !window.bridge.checkForNotifications) {
      return;
    }
    window.bridge.checkForNotifications(resp => {
      setShouldAskForNotifs(!resp);
    });
  }, []);

  useEffect(() => {
    if (showAutopilotModal) {
      if (autopilotTestVersion === AUTOPILOT_TESTS.AUTOPILOT_HABITUAL) {
        postTestActivity('autopilot-existing', `${platform}-view`);
      } else if (autopilotTestVersion === AUTOPILOT_TESTS.AUTOPILOT_ALL) {
        postTestActivity('autopilot-all', `${platform}-view`);
      }
    }
  }, [showAutopilotModal]);

  const clickTimeslotMeeting = (timeslot: string) => {
    let newSelectedTimeslots = [...selectedTimeslots];
    if (selectedTimeslots.includes(timeslot)) {
      if (userAvailability?.locked_time_slots?.includes(timeslot)) {
        showNotif({
          message: `Sorry, your match for this timeslot is already set in place. Please reschedule with them directly in chat.`,
          level: 'error',
        });
        return;
      }
      newSelectedTimeslots = newSelectedTimeslots.filter(
        selectedTimeslot => selectedTimeslot !== timeslot,
      );
    } else {
      newSelectedTimeslots = [...newSelectedTimeslots, timeslot];
    }
    if (newSelectedTimeslots.length + (slantOptIn ? 1 : 0) > maxMeetings) {
      return;
    }
    updateIfSufficientClubpoints({
      newSelectedNumberMeetings: newSelectedTimeslots.length + (slantOptIn ? 1 : 0),
      message: p => `To take more meetings, you need to earn ${p} more Clubpoints!`,
    }).then(enoughClubpoints => {
      if (enoughClubpoints) {
        setSelectedTimeslots(newSelectedTimeslots);
      }
    });
  };

  const clickSlantMatch = (newSlantOptIn: boolean) => {
    if (newSlantOptIn && selectedNumberMeetings >= maxMeetings) {
      showNotif({
        message: `You can sign up for a max of ${maxMeetings} meetings`,
        level: 'error',
      });
    } else {
      updateIfSufficientClubpoints({
        newSelectedNumberMeetings: selectedTimeslots.length + (newSlantOptIn ? 1 : 0),
        message: p => `To take more meetings, you need to earn ${p} more Clubpoints!`,
      }).then(enoughClubpoints => {
        if (enoughClubpoints) {
          setSlantOptIn(newSlantOptIn);
        }
      });
    }
  };

  const updateIfSufficientClubpoints = async ({
    newSelectedNumberMeetings = selectedNumberMeetings,
    newSelectedPickMatch = selectedPickMatch,
    newTargetLocaleId = targetLocaleId,
    message,
  }: TestClubpoints) => {
    // BG Note: this code should be moved into a helper because it's also calculated elsewhere
    const pickedMatch = newSelectedPickMatch > 0 ? newSelectedPickMatch - 1 : null;
    let requiredPoints = 0;
    if (newSelectedNumberMeetings > 3)
      requiredPoints += (newSelectedNumberMeetings - 3) * EXTRA_MATCH_COST;

    if ((newTargetLocaleId || newTargetLocaleId === 0) && newTargetLocaleId !== user.locale)
      requiredPoints += newSelectedNumberMeetings * TARGET_LOCALE_COST;

    if (pickedMatch !== null) requiredPoints += newSelectedNumberMeetings * PICK_MATCH_COST;

    const spentClubpoints = getSpentClubpoints(userAvailability);

    if (requiredPoints > (userClubpoints || 0) + spentClubpoints) {
      if (typeof userClubpoints === 'undefined') {
        showNotif({
          message: 'Clubpoints not yet loaded, please wait or try refreshing.',
          level: 'error',
        });
        return false;
      }
      const missingPoints = requiredPoints - userClubpoints - spentClubpoints;
      if (missingPoints < 6) {
        setMissingUnderFivePoints(true);
      }
      showNotif({
        message: message(missingPoints),
        level: 'error',
      });
      postClubpointsAvailability({
        notEnoughPoints: missingPoints,
        maxMatches: newSelectedNumberMeetings,
        targetRole: pickedMatch,
        targetLocale: newTargetLocaleId,
      });
      return false;
    }
    setSelectedNumberMeetings(newSelectedNumberMeetings);
    setSelectedPickMatch(newSelectedPickMatch);
    setTargetLocaleId(newTargetLocaleId);
    return true;
  };

  const clickPickMatch = (pickMatch: number) =>
    updateIfSufficientClubpoints({
      newSelectedPickMatch: pickMatch,
      message: missingPoints =>
        `To pick a more targeted match, you need to earn ${missingPoints} more Clubpoints!`,
    });

  const changeTargetLocaleId = (localeId: number | null) =>
    updateIfSufficientClubpoints({
      newSelectedNumberMeetings: 0,
      newTargetLocaleId: localeId,
      message: missingPoints =>
        `To select a different city, you need to earn ${missingPoints} more Clubpoints!`,
    });

  const redirectUser = (gAuthed?: string) => {
    history.push(
      addParameterToURL(typeof gAuthed === 'string' ? gAuthed : 'ref', 'weekly', '/invite'),
    );
  };

  const passWeekly = async () => {
    if (submittingWeekly) return;
    setSubmittingWeekly(true);
    const res = await postAvailability({});
    if (res.ok) {
      showNotif({
        message: `Thanks for letting us know you pass for a week. If you'll be out for a while, you can pause on the settings page.`,
        level: 'success',
      });
      await Promise.all([fetchUser(), updateUserAvailability()]);
      if (getIsMobile()) {
        // Note: tests below are pending mobile designs and setting their state doesn't do anything
        redirectUser();
      } else if (slantOptInTest) {
        setPromptSlantModal(true);
      } else if (isInPersonLocale) {
        setPromptInPerson(true);
        redirectUser();
      } else {
        setHasSubmitted(true);
      }
    } else {
      setSubmittingWeekly(false);
    }
  };

  const passWeeklyWithSlant = async () => {
    const res = await postAvailability({ slantOptIn: true, locale: user.locale });
    if (res.ok) {
      await Promise.all([
        postTestActivity(window.location.pathname, 'slant_match_modal=true'),
        fetchUser(),
        updateUserAvailability(),
      ]);
      redirectUser();
    }
  };

  const submitWeeklyWithIRL = async () => {
    const videoNeighborhood = allLocales.find(n => n.id === (selectedLocaleId || user.locale))
      ?.videoNeighborhood;

    if (
      videoNeighborhood !== undefined &&
      availableSingleNeighborhood !== undefined &&
      availableSingleTimeslot !== undefined
    ) {
      const resNeighborhoods: number[] = [
        ...Array(selectedTimeslots.length).fill(videoNeighborhood),
        availableSingleNeighborhood.id,
      ];
      const resTimeslots = [...selectedTimeslots, availableSingleTimeslot];

      // Making known tradeoff here to ignore user specifications for meetings if they
      // opt to in-person as specifications like targetLocale currently apply to all
      // signups, not just video signups. Backend must update to accommodate this.
      await postAvailability({
        timeslots: resTimeslots,
        locale: selectedLocaleId,
        neighborhoods: resNeighborhoods,
        numberOfMeetings: selectedNumberMeetings + 1,
        slantOptIn,
      });
      await updateUserAvailability();
    }

    setCurrentStep(4);
  };

  const submitWeekly = async () => {
    if (submittingWeekly) return;
    if (!selectedEnoughSlots) {
      passWeekly();
      return;
    }

    // TODO: Change how this works when neighborhoods are back.
    const videoNeighborhood = allLocales.find(n => n.id === (selectedLocaleId || user.locale))
      ?.videoNeighborhood;

    setSubmittingWeekly(true);
    if (selectedQuestionAnswer !== undefined)
      await postWeeklyQuestion({
        questionId: weeklyQuestion?.id,
        responseId: selectedQuestionAnswer,
      });

    const pickedMatch = selectedPickMatch > 0 ? selectedPickMatch - 1 : null;

    if (
      !onCoachingPlan &&
      !coachingPlanSignupType &&
      !showUpdateAutopilotModal &&
      userAvailability?.availability_autopilot?.time_slots[0] &&
      !isSameSet(userAvailability?.availability_autopilot?.time_slots, selectedTimeslots)
    ) {
      const autopilotRes = await postAutopilotAvailable({
        timeslots: selectedTimeslots,
        locale: selectedLocaleId,
        neighborhoods: [videoNeighborhood || 0],
        numberOfMeetings: selectedNumberMeetings,
        pickMatchRole: pickedMatch,
        targetLocale: targetLocaleId,
      });
      if (autopilotRes.ok) {
        if (autopilotRes.getJson.autopilot) {
          setShowUpdateAutopilotModal(true);
          setSubmittingWeekly(false);
          return;
        }
      }
    }

    if (onCoachingPlan) {
      // update the autopilot availability
      const res = await postAvailability({
        timeslots: selectedTimeslots,
        locale: selectedLocaleId,
        neighborhoods: [videoNeighborhood || 0],
        numberOfMeetings: selectedNumberMeetings,
        autopilot: true,
      });
      if (res.ok) {
        await Promise.all([fetchUser(), updateUserAvailability()]);
      }
      setSubmittingWeekly(false);
      return;
    }

    if (coachingPlanSignupType) {
      submitCoachingPlanWeekly();
      return;
    }

    const res = await postAvailability({
      timeslots: selectedTimeslots,
      locale: selectedLocaleId,
      neighborhoods: [videoNeighborhood || 0],
      numberOfMeetings: selectedNumberMeetings,
      pickMatchRole: pickedMatch,
      objective: selectedObjective,
      canCrossCity: !(targetLocaleId === user.locale),
      preferTheseCommunities: preferTheseCommunities.length ? preferTheseCommunities : null,
      targetLocale: targetLocaleId,
      meetingLength: 45,
      targetInterest,
      slantOptIn,
      autopilot: autopilotTestVersion === AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX,
    });

    if (res.ok) {
      await Promise.all([fetchUser(), updateUserAvailability(), fetchTestBuckets()]);
      if (!onAutopilot && !showAutopilotModal) {
        const autopilotRes = await postAutopilotAvailable({
          timeslots: selectedTimeslots,
          locale: selectedLocaleId,
          neighborhoods: [videoNeighborhood || 0],
          numberOfMeetings: selectedNumberMeetings,
          pickMatchRole: pickedMatch,
          targetLocale: targetLocaleId,
        });
        if (autopilotRes.ok) {
          if (autopilotRes.getJson.autopilot) {
            setAutopilotTestVersion(autopilotRes.getJson.type);
            setShowAutopilotModal(true);
            setSubmittingWeekly(false);
            return;
          }
        }
      } else if (!getIsMobile() && selectedNumberMeetings > 0) {
        showNotif({
          message: `Thanks for signing up! Stay tuned for an email introducing you to your match${
            selectedNumberMeetings > 1 ? 'es.' : '.'
          }`,
          level: 'success',
        });
      }

      setHasSubmitted(true);
    } else if (res.status === 400 && res.getJson.error === 'Invalid time slots') {
      showNotif({
        message: 'You just missed the deadline! Fetching updated times!',
        level: 'error',
      });
      window.location.reload();
      return;
    }

    setSubmittingWeekly(false);
  };

  const getVideoNeighbourhood = () => {
    return allLocales.find(n => n.id === (selectedLocaleId || user.locale))?.videoNeighborhood;
  };

  const submitAutopilotWeekly = async () => {
    if (selectedTimeslots.length) {
      switch (autopilotTestVersion) {
        case AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX:
          postTestActivity('new-user-autopilot', `${platform}-accept`);
          break;
        case AUTOPILOT_TESTS.AUTOPILOT_HABITUAL:
          postTestActivity('autopilot-existing', `${platform}-accept`);
          break;
        default:
          postTestActivity('autopilot-all', `${platform}-accept`);
      }
    } else if (autopilotTestVersion === AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX) {
      postTestActivity('new-user-autopilot', `${platform}-reset`);
    }

    const videoNeighborhood = getVideoNeighbourhood();

    const res = await postAvailability({
      timeslots: selectedTimeslots,
      locale: selectedLocaleId,
      neighborhoods: [videoNeighborhood || 0],
      numberOfMeetings: selectedNumberMeetings,
      autopilot: true,
    });
    if (res.ok) {
      await Promise.all([fetchUser(), updateUserAvailability()]);
      if (autopilotTestVersion !== AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX) {
        showNotif({
          message: `You're on autopilot! You can adjust these settings at anytime on the settings page.`,
          level: 'success',
        });
      }
    }

    if (autopilotTestVersion === AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX && selectedTimeslots.length) {
      setShowAutopilotModal(true);
      setSubmittingWeekly(false);
      return;
    }
    redirectUser();
  };

  const updateCoachingPlanWeekly = async () => {
    if (submittingWeekly) {
      return;
    }
    setSubmittingWeekly(true);
    if (selectedTimeslots.length === 0) {
      passWeekly();
      setSubmittingWeekly(false);
      return;
    }

    const videoNeighborhood = getVideoNeighbourhood();
    const res = await postAvailability({
      timeslots: selectedTimeslots,
      locale: selectedLocaleId,
      neighborhoods: [videoNeighborhood || 0],
      numberOfMeetings: selectedNumberMeetings,
      autopilot: true,
    });
    if (res.ok) {
      await Promise.all([fetchUser(), updateUserAvailability()]);
      showNotif({
        message: 'Your Curated Matches timeslots have been updated!',
        level: 'success',
      });
    }

    setSubmittingWeekly(false);
    redirectUser();
  };

  const submitCoachingPlanWeekly = async () => {
    if (submittingWeekly) {
      return;
    }
    setSubmittingWeekly(true);
    if (selectedTimeslots.length === 0) {
      showNotif({
        message: 'You have to choose at least one slot for Curated Matches.',
        level: 'error',
      });
      setSubmittingWeekly(false);
      return;
    }

    const videoNeighborhood = getVideoNeighbourhood();
    const res = await postAvailability({
      timeslots: selectedTimeslots,
      locale: selectedLocaleId,
      neighborhoods: [videoNeighborhood || 0],
      numberOfMeetings: selectedNumberMeetings,
      autopilot: true,
    });

    if (!res.ok) {
      showNotif({ message: `Sorry, something went wrong. Please try again`, level: 'error' });
      setSubmittingWeekly(false);
      return;
    }

    if (!coachingPlanSignupType) {
      showNotif({ message: `Sorry, something went wrong. Please try again`, level: 'error' });
      return;
    }

    const res2 = await postAcceptCoachingPlan(coachingPlanSignupType);
    if (res2.ok) {
      await Promise.all([fetchUser(), updateUserAvailability()]);
      showNotif({ message: `You're now signed up for Curated Matches!`, level: 'success' });
      setSubmittingWeekly(false);
      redirectUser();
    } else {
      showNotif({ message: `Sorry, something went wrong. Please try again`, level: 'error' });
      setSubmittingWeekly(false);
    }
  };

  const acceptCoachingPlan = async () => {
    setDecidedCoachingPlan(true);
    setShowCoachingPlan(false);
    setCoachingPlanSignupType(coachingPlanEligibility?.type);
  };

  const declineCoachingPlan = async () => {
    setDecidedCoachingPlan(true);
    setShowCoachingPlan(false);
    setCoachingPlanSignupType(undefined);
  };

  if (loading || !coachingPlanEligibility) {
    return (
      <GreyPage>
        <SVGLoader />
      </GreyPage>
    );
  }

  if (cohesiveAutopilotTest) {
    if (!shouldRedirectToMeetings && shouldShowForcedAutopilot) {
      return <AutopilotForced />;
    }
  }

  if (shouldRedirectToMeetings) {
    history.push('/meetings');
    return null;
  }

  if (userAvailability?.snowflake) {
    return <Snowflaked />;
  }

  if (!user.headline || user.headline.length === 0) return <MissingHeadline />;

  const relevantPageLocaleId =
    targetLocaleId !== undefined && targetLocaleId !== null ? targetLocaleId : selectedLocaleId;
  const currentSelectedLocale =
    allLocales.find(locale => locale.id === relevantPageLocaleId) || null;
  if (currentSelectedLocale === null || !currentSelectedLocale.active)
    return <LocaleNotAvailable locale={currentSelectedLocale} />;

  const localeTimeslotObject = allTimeslots.find(t => t.id === relevantPageLocaleId);
  const isNextWeekHome = allTimeslots.find(t => t.id === selectedLocaleId)?.isNextWeek || false;
  const localeTimeslotsByDay = collectIntoCalendarDays({
    selectedLocale: allLocales.find(rawLocale => rawLocale.id === selectedLocaleId) as Locale,
    targetTimezone: currentSelectedLocale.timezone,
    timeslots: localeTimeslotObject?.timeslots,
    window: getCutoffDay({
      homeIsNextWeek: isNextWeekHome,
      userTimezone: user.locale_info?.timezone || '',
    }),
  });

  const warnDeadline = localeTimeslotObject?.warnDeadline;
  const selectedEnoughSlots = selectedNumberMeetings >= TIME_SELECTION_MIN;
  const isInPersonLocale =
    !!localeTimeslotObject?.inPersonTimeslots.length && targetLocaleId === null;

  const availableSingleTimeslot = isInPersonLocale
    ? localeTimeslotObject?.inPersonTimeslots[0]
    : '';
  const availableSingleNeighborhood = isInPersonLocale
    ? allLocales.find(n => n.id === (selectedLocaleId || user.locale))?.neighborhoods[0]
    : undefined;

  const shortWeekly = newUser;
  const showSingleSignupModal = !!(
    promptInPerson &&
    availableSingleNeighborhood?.name &&
    inPersonTimeslots.length === 1 &&
    availableSingleTimeslot &&
    !selectedTimeslots.includes(availableSingleTimeslot)
  );

  const selectedLocaleTimezone =
    allLocales.find(rawLocale => rawLocale.id === selectedLocaleId)?.timezone || '';

  const homeTimezone = getTimezoneAbbr(selectedLocaleTimezone);
  const busyTimesAtHome = busyTimeslots.map(timeslot =>
    moment
      .tz(timeslot, 'UTC')
      .clone()
      .tz(selectedLocaleTimezone)
      .format('YYYY-MM-DD HH:mm:ss'),
  );

  const targetLocaleName = allLocales.find(locale => locale.id === targetLocaleId)?.name;

  const targetableLocales = shortWeekly
    ? []
    : isNextWeekHome
    ? allActiveLocales
    : allActiveLocales.filter(locale => !allTimeslots.find(t => t.id === locale.id)?.isNextWeek);

  const userStreak = user.streak + 1;
  const animationDuration =
    userStreak < STREAK_THRESHOLD ? ANIMATION_DURATION_SHORT : ANIMATION_DURATION_LONG;

  if (hasSubmitted) {
    if (currentStep <= 0 && !user.has_user_connected_contacts && !!window.bridge) {
      return (
        <GreyPage>
          <WeeklyContacts
            onFinish={() => {
              setShouldFetchUserAgain(true);
              setCurrentStep(1);
            }}
          />
        </GreyPage>
      );
    }
    if (currentStep <= 1 && shouldAskForNotifs && !!window.bridge) {
      return (
        <GreyPage>
          <WeeklyNotifs
            onFinish={() => {
              setCurrentStep(2);
            }}
          />
        </GreyPage>
      );
    }
    if (
      currentStep <= 2 &&
      (!user.visual_settings || !user.visual_settings.hide_weekly_gauth) &&
      !user.has_google
    ) {
      return (
        <GreyPage>
          <WeeklyGauth
            onFinish={() => {
              setShouldFetchUserAgain(true);
              setCurrentStep(3);
            }}
          />
        </GreyPage>
      );
    }
    if (currentStep <= 3 && isInPersonLocale && !getIsMobile()) {
      setPromptInPerson(true);
    } else if (currentStep <= 4) {
      if (shouldFetchUserAgain) {
        fetchUser();
      }
      setTimeout(() => redirectUser(), currentStep === 0 ? animationDuration : 0);
    }
  }

  if (getIsMobile()) {
    return (
      <GreyPage>
        <MobileMatchesModal
          shortWeekly={shortWeekly}
          localTimezone={homeTimezone}
          homeLocale={allActiveLocales.find(l => l.id === user.locale) || null}
          targetLocale={allActiveLocales.find(l => l.id === targetLocaleId) || null}
          changeTargetLocaleId={changeTargetLocaleId}
          currentSelectedLocale={currentSelectedLocale}
          localeTimeslotsByDay={localeTimeslotsByDay}
          activeLocales={targetableLocales}
          selectedTimeslots={selectedTimeslots}
          clickTimeslot={clickTimeslotMeeting}
          busyTimeslots={busyTimesAtHome}
          onSubmit={submitWeekly}
          slantOptInTest={slantOptInTest}
          slantOptInChecked={slantOptIn}
          onToggleSlantOptIn={clickSlantMatch}
          showAutopilot={showAutopilotModal}
          autopilotTimeslots={selectedTimeslots}
          submitWithAutopilot={submitAutopilotWeekly}
          autopilotVersion={autopilotTestVersion}
          onCoachingPlan={onCoachingPlan}
          coachingPlanSignupType={coachingPlanSignupType}
          showCoachingPlan={showCoachingPlan}
          acceptCoachingPlan={acceptCoachingPlan}
          declineCoachingPlan={declineCoachingPlan}
          submitWithCoachingPlan={updateCoachingPlanWeekly}
          coachingPlanEligibility={coachingPlanEligibility}
        />
      </GreyPage>
    );
  }

  const onFinish = onCoachingPlan
    ? updateCoachingPlanWeekly
    : coachingPlanSignupType
    ? submitCoachingPlanWeekly
    : stillAutopilot
    ? !onAutopilot
      ? () => {
          submitWeekly().then(() => redirectUser());
        }
      : redirectUser
    : autopilotTestVersion === AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX
    ? submitAutopilotWeekly
    : submitWeekly;

  const buttonText = coachingPlanSignupType
    ? 'Sign up for Curated Matches'
    : selectedNumberMeetings === 0
    ? autopilotTestVersion === AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX && !onAutopilot
      ? 'Sign up'
      : `Pass for a week`
    : onCoachingPlan
    ? `Update Curated Matches time${selectedNumberMeetings > 1 ? 's' : ''}`
    : stillAutopilot
    ? 'Continue on autopilot'
    : autopilotTestVersion === AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX
    ? 'Sign up for Lunchclub'
    : `Sign up for ${selectedNumberMeetings} match${selectedNumberMeetings > 1 ? 'es' : ''}`;

  return (
    <GreyPage>
      <TopBanner
        shortWeekly={shortWeekly}
        weekString={
          moment().endOf('week') >
          moment(localeTimeslotsByDay.length && localeTimeslotsByDay[0].options[0].option)
            ? 'this'
            : 'next'
        }
        autopilotVersion={autopilotTestVersion}
        onCoachingPlan={onCoachingPlan}
      />
      <BottomFittingCard>
        <Container>
          <ScheduleHeader
            onAutopilot={isAutopilotSignup}
            onCoachingPlan={onCoachingPlan}
            coachingPlanSignupType={coachingPlanSignupType}
            matches={coachingPlanEligibility.details?.matches ?? []}
          />
          <TimeslotMeetingQuestion
            localeTimeslotsByDay={localeTimeslotsByDay}
            selectedTimeslots={selectedTimeslots}
            clickTimeslot={clickTimeslotMeeting}
            warnDeadline={warnDeadline}
            newUser={newUser}
            maxMeetings={maxMeetings}
            selectedNumberMeetings={selectedNumberMeetings}
            selectedLocale={targetLocaleId}
            setSelectedLocale={changeTargetLocaleId}
            locales={targetableLocales}
            busyTimeslots={busyTimesAtHome}
            autopilotVersion={autopilotTestVersion}
            onCoachingPlan={!!coachingPlanSignupType || onCoachingPlan}
          />
          <MeetingFooter
            localTimezone={homeTimezone}
            targetLocale={targetLocaleName || user.locale_info.name}
            hasAvailableTimes={!!busyTimesAtHome}
            availableTimesLoaded={!!busyTimesAtHome?.length}
          />

          {slantOptInTest && (
            <SlantMatchToggle
              toggleState={slantOptIn}
              clickToggle={clickSlantMatch}
              text={`Send me the best match and I'll find the time!`}
              heading="Best match anytime?"
              desc=" Sign up for us to send you an additional match and you can schedule the time yourself!"
            />
          )}
          {shortWeekly ? (
            <MeetingPreferenceQuestion
              shortWeekly={shortWeekly}
              communities={communities}
              preferTheseCommunities={preferTheseCommunities}
              interests={[]}
              targetInterest={targetInterest}
              setTargetInterest={setTargetInterest}
              setPreferTheseCommunities={setPreferTheseCommunities}
            />
          ) : (
            !onCoachingPlan &&
            !coachingPlanSignupType && (
              <>
                <PreferencesDropdown
                  isPreferencesOpen={isPreferencesOpen}
                  setIsPreferencesOpen={setIsPreferencesOpen}
                  shortWeekly={shortWeekly}
                />
                {isPreferencesOpen && (
                  <>
                    {foldLoading && <SVGLoader small />}
                    <ObjectiveQuestion
                      rawObjectives={rawObjectives}
                      userObjectives={user.objective_ids}
                      selectedObjective={selectedObjective}
                      setSelectedObjective={setSelectedObjective}
                    />

                    <PickMeetingQuestion
                      selectedPickMatch={selectedPickMatch}
                      setSelectedPickMatch={clickPickMatch}
                      selectedNumberMeetings={selectedNumberMeetings}
                    />

                    <MeetingPreferenceQuestion
                      shortWeekly={shortWeekly}
                      communities={communities}
                      preferTheseCommunities={preferTheseCommunities}
                      interests={interests}
                      targetInterest={targetInterest}
                      setTargetInterest={setTargetInterest}
                      setPreferTheseCommunities={setPreferTheseCommunities}
                    />
                    {weeklyQuestion && (
                      <WeeklyQuestion
                        question={weeklyQuestion}
                        onClick={answer => setSelectedQuestionAnswer(answer)}
                        selected={selectedQuestionAnswer}
                      />
                    )}
                  </>
                )}
                {missingUnderFivePoints && <PondSuggestions />}
              </>
            )
          )}
          {selectedNumberMeetings === 1 &&
            !onAutopilot &&
            !(newUser && autopilotTestVersion === AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX) && (
              <SecondMatchMessage />
            )}
          <StreakFinishButton
            streakLength={userStreak}
            onFinish={onFinish}
            buttonText={buttonText}
            hasSubmitted={hasSubmitted}
            buttonLoading={submittingWeekly}
            duration={animationDuration}
            numMeetings={selectedNumberMeetings}
            invalid={
              selectedNumberMeetings === 0 &&
              ((!onAutopilot && autopilotTestVersion === AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX) ||
                !!coachingPlanSignupType)
            }
          />
        </Container>
      </BottomFittingCard>
      <InPersonInviteModal
        showModal={showSingleSignupModal}
        neighborhood={availableSingleNeighborhood?.name || ''}
        timeslot={availableSingleTimeslot || ''}
        join={submitWeeklyWithIRL}
        decline={redirectUser}
      />
      <SlantMatchOptInModal
        showModal={slantOptInTest && promptSlantModal}
        join={passWeeklyWithSlant}
        decline={redirectUser}
      />

      <UpdateAutopilotModal
        showModal={showUpdateAutopilotModal}
        timeslots={selectedTimeslots}
        accept={submitAutopilotWeekly}
        decline={() => {
          submitWeekly().then(() => redirectUser());
        }}
      />

      {coachingPlanEligibility?.details && (
        <CoachingPlanOptIn
          details={coachingPlanEligibility.details}
          planType={coachingPlanEligibility.type || 0}
          showCoachingPlan={showCoachingPlan}
          accept={acceptCoachingPlan}
          decline={declineCoachingPlan}
        />
      )}

      {autopilotTestVersion === AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX ? (
        <NewUserForcedAutopilotModal
          showModal={showAutopilotModal}
          timeslots={selectedTimeslots}
          accept={redirectUser}
        />
      ) : autopilotTestVersion === AUTOPILOT_TESTS.AUTOPILOT_HABITUAL ? (
        <AutopilotHabitualModal
          showModal={showAutopilotModal}
          timeslot={moment(selectedTimeslots[0])}
          accept={submitAutopilotWeekly}
          decline={redirectUser}
        />
      ) : !userAvailability?.availability_autopilot?.time_slots[0] ? (
        <AutopilotOptInModal
          showModal={showAutopilotModal}
          timeslots={selectedTimeslots}
          accept={submitAutopilotWeekly}
          decline={redirectUser}
        />
      ) : (
        <></>
      )}
    </GreyPage>
  );
};

const Container = styled(FlexColumn)`
  padding: ${margins.size6} 0 ${margins.size6} 0;
  max-width: 600px;
  margin: auto;
  ${media.mobile} {
    padding: ${margins.size4};
    width: 100%;
  }
`;

const BottomFittingCard = styled(Card)<{ hasTopCard?: boolean }>`
  max-width: 900px;
  ${p =>
    p.hasTopCard &&
    `
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`};
`;
