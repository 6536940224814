import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { colors, FlexRow, fonts, Heading3, margins, SubText } from 'css/css';

import ProgressBar, {
  ProgressBarStyle,
} from 'js/components/registration/new-onboarding/ProgressBar';
import { Button } from 'js/components/shared/Button';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { RoundBackButton } from 'js/components/shared/mobile/RoundBackButton';
import { SmallArrow } from 'js/components/shared/SmallArrow';

export const OnboardingFlowPage: React.FunctionComponent<{
  step: number;
  progressStyle?: ProgressBarStyle;
  title?: string;
  subtitle?: string;
  onPrev?: () => void;
  onNext?: () => void;
  nextLabel?: string;
  nextLoading?: boolean;
  nextDisabled?: boolean;
  nextInvalid?: boolean;
  background?: string;
  invalidOnNext?: () => void;
  isMobileKeyboardOpen?: boolean;
  isNextButtonSecondary?: boolean;
}> = ({
  title,
  subtitle,
  nextLabel,
  nextDisabled,
  nextInvalid,
  nextLoading,
  step,
  progressStyle,
  onPrev,
  onNext,
  background,
  children,
  invalidOnNext,
  isMobileKeyboardOpen,
  isNextButtonSecondary,
}) => (
  <OnboardingFlowPageWrap
    style={{
      background: background || colors.greyLight,
      ...(isMobileKeyboardOpen && { paddingBottom: '80px' }),
    }}
  >
    <OnboardingFlowNavWrap>
      <ProgressBar style={progressStyle} currentStepIndex={step} />
    </OnboardingFlowNavWrap>
    {title && (
      <OnboardingTitle>
        {title}
        <SubText color={colors.blackMid}>{subtitle}</SubText>
      </OnboardingTitle>
    )}
    {children}
    <BottomBar
      nextLabel={nextLabel}
      nextDisabled={nextDisabled}
      nextInvalid={nextInvalid}
      nextLoading={nextLoading}
      onPrev={onPrev}
      onNext={onNext}
      invalidOnNext={invalidOnNext}
      isNextButtonSecondary={isNextButtonSecondary}
    />
  </OnboardingFlowPageWrap>
);

interface BottomBarProps {
  onPrev?: () => void;
  onNext?: () => void;
  nextLabel?: string;
  nextLoading?: boolean;
  nextDisabled?: boolean;
  nextInvalid?: boolean;
  invalidOnNext?: () => void;
  isLight?: boolean;
  isNextButtonSecondary?: boolean;
}

export const BottomBar: React.FC<BottomBarProps> = ({
  nextLabel,
  nextDisabled,
  nextInvalid,
  nextLoading,
  onPrev,
  onNext,
  invalidOnNext,
  isLight,
  isNextButtonSecondary,
}) => {
  const [onClickInProgress, setOnClickInProgress] = useState(false);

  return (
    <BottomBarContainer>
      <BackButtonContainer>
        {onPrev && (
          <RoundBackButton
            size={50}
            color={isLight ? colors.whiteMain : colors.primaryMain}
            arrowColor={isLight ? colors.primaryMain : colors.whiteMain}
            onClick={onPrev}
          />
        )}
      </BackButtonContainer>
      {!nextDisabled && (
        <Button
          style={{
            marginRight: 0,
            boxShadow: isNextButtonSecondary ? 'none' : '0px 4px 15px rgba(0, 0, 0, 0.2)',
          }}
          onClick={async () => {
            document.body.focus();
            if (!onNext) {
              return;
            }
            setOnClickInProgress(true);
            await onNext();
            setOnClickInProgress(false);
          }}
          secondary={isLight || isNextButtonSecondary}
          invalid={nextInvalid}
          invalidCallback={invalidOnNext}
          active={isNextButtonSecondary}
        >
          {onClickInProgress || nextLoading ? (
            <SVGLoader center={false} small />
          ) : (
            <>
              {nextLabel || 'Next'}
              <SmallArrow
                direction="right"
                color={isLight || isNextButtonSecondary ? colors.primaryMain : colors.whiteMain}
                style={{ marginLeft: margins.size2, marginBottom: '2px' }}
              />
            </>
          )}
        </Button>
      )}
    </BottomBarContainer>
  );
};

const OnboardingTitle = styled(Heading3)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: ${margins.size3};
  padding: 0 20px;
  user-select: none;
`;

const OnboardingFlowPageWrap = styled.div`
  width: 100%;
  height: 100%;
  min-height: -webkit-fill-available;
  overflow-y: scroll;

  display: flex;
  font-family: ${fonts.regular};
  flex-direction: column;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-select: none;
`;

const OnboardingFlowNavWrap = styled.div`
  display: flex;
  align-items: center;
  padding: ${margins.size3};
  padding-bottom: 0;
  margin-top: env(safe-area-inset-top);
`;

const BottomBarContainer = styled(FlexRow)`
  justify-content: space-between;
  position: fixed;
  margin-bottom: 20px;
  right: 15px;
  left: 15px;
  bottom: env(safe-area-inset-bottom);
`;

const BackButtonContainer = styled.div`
  width: 50px;
  aspect-ratio: 1;
`;
