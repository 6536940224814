import React from 'react';

import { colors, fonts } from 'css/css';

import { MAX_HEADLINE_LENGTH, MIN_HEADLINE_LENGTH } from 'js/util/util';
import { MobileFocusingTextarea } from 'js/components/shared/MobileFocusingTextarea';
import { useFontSizeFillingContainer } from 'js/util/use-font-size-filling-container';

export function splitHeadlineAndPrefix(str: string) {
  const split = str.indexOf(' is ') + 4;
  if (split === 3) {
    return [str, ''];
  }
  return [str.substr(0, split), split > 0 ? str.substr(split) : ''];
}

export const HeadlineMobileInput: React.FC<{
  value: string;
  resetValue: (value: string) => void;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  bold?: boolean;
  color?: string;
  transparent?: boolean;
  buttonOnClick?: () => void;
  isButtonInvalid?: boolean;
  isEmpty: boolean;
  initialValue: string;
}> = ({
  value,
  resetValue,
  bold,
  onChange,
  color,
  transparent,
  buttonOnClick,
  isButtonInvalid,
  isEmpty,
  initialValue,
}) => {
  const { textboxRef, fontSize } = useFontSizeFillingContainer(value);

  return (
    <>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <MobileFocusingTextarea
          mobileFocusBehavior="always"
          mobileFocusLabel="How should we introduce you?"
          id="headline"
          ref={textboxRef}
          style={{
            padding: 0,
            lineHeight: '1.35em',
            fontWeight: bold ? 600 : 300,
            fontFamily: bold ? fonts.extraBold : fonts.regular,
            fontSize,
            flex: 1,
            color: color || colors.blackMain,
            opacity: !isEmpty ? 1 : 0.5,
            ...(transparent ? { background: 'transparent' } : {}),
          }}
          value={value}
          resetValue={resetValue}
          onChange={e => onChange(e)}
          buttonText="Save and finish"
          characterCount={{
            headline: value.substring(initialValue.length),
            min: MIN_HEADLINE_LENGTH,
            max: MAX_HEADLINE_LENGTH,
          }}
          buttonOnClick={buttonOnClick}
          isButtonInvalid={isButtonInvalid}
          isEmpty={isEmpty}
        />
      </div>
    </>
  );
};
