import { setImmediate } from 'timers';

import React, { useState } from 'react';
import styled from 'styled-components/macro';

import {
  BUTTON_HEIGHT,
  Clickable,
  colors,
  FlexColumn,
  FlexRow,
  globalTransitionSettings,
  Heading2,
  Heading3,
  margins,
  media,
  MobileScrollFade,
  SubText,
  Text,
  UppercaseHeading3,
} from 'css/css';

import { AllowedEndorsements, sendEndorsement } from 'js/util/api';
import { Avatar } from 'js/components/shared/Avatar';
import { MinimalUserDict } from 'js/components/homepage/types';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { Button } from 'js/components/shared/Button';
import { ContactsSearchTextInput } from 'js/components/shared/Autocomplete';
import { getIsMobile } from 'js/util/util';

import { endorsementSessionEndIcon } from 'img/homepage/feed';
import plus from 'img/chat/endorsement-modal/plus.svg';
import endorsementIcon from 'img/chat/endorsement.svg';
import cross from 'img/chat/endorsement-modal/x.svg';

import { Top } from './Top';
import { ENDORSEMENTS_END_TEXT, ENDORSEMENT_MODAL_DESKTOP_BODY_HEIGHT } from './constants';

interface EndorsementSenderProps {
  allowedEndorsements: AllowedEndorsements[];
  closeModal: () => void;
}

export const EndorsementSender: React.FC<EndorsementSenderProps> = ({
  allowedEndorsements,
  closeModal,
}) => {
  const [pickedUser, setPickedUser] = useState<AllowedEndorsements | null>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [sentEndorsements, setSentEndorsements] = useState<Record<string, number[]>>({});
  const [sendingEndorsements, setSendingEndorsements] = useState<Record<string, number[]>>({});

  const goBack = () => setPickedUser(null);

  const pickEndorsement = async (categoryNum: number, sentCallback: () => void) => {
    if (pickedUser === null) {
      return;
    }

    const pickedPublicId = pickedUser.user.profile;
    const appendEndorsement = (obj: Record<string, number[]>) => ({
      ...obj,
      [pickedPublicId]:
        obj[pickedPublicId] !== undefined ? [...obj[pickedPublicId], categoryNum] : [categoryNum],
    });
    const removeEndorsement = (obj: Record<string, number[]>) => ({
      ...obj,
      [pickedPublicId]:
        obj[pickedPublicId] !== undefined ? obj[pickedPublicId].filter(c => c !== categoryNum) : [],
    });

    setSendingEndorsements(appendEndorsement);

    const res = await sendEndorsement({ categoryNum, publicId: pickedUser.user.profile });
    if (res.ok) {
      setSentEndorsements(appendEndorsement);
      sentCallback();
    }

    setSendingEndorsements(removeEndorsement);
  };

  const sentPreviously = (categoryNum: number) => {
    if (pickedUser === null) {
      return false;
    }
    const { profile: pickedPublicId } = pickedUser.user;
    return (
      sentEndorsements[pickedPublicId] !== undefined &&
      sentEndorsements[pickedPublicId].includes(categoryNum)
    );
  };

  const getIsLoading = (publicId: string, categoryNum: number) =>
    sendingEndorsements[publicId] !== undefined &&
    sendingEndorsements[publicId].includes(categoryNum);

  const hasSomeEndorsementsLeft = (publicId: string, categories: { num: number; name: string }[]) =>
    categories.filter(
      category =>
        sentEndorsements[publicId] === undefined ||
        !sentEndorsements[publicId].includes(category.num),
    ).length > 0;

  const isMobile = getIsMobile();
  const shouldExpandList = isMobile && isInputFocused;

  const filteredEndorsements = allowedEndorsements.filter(({ user, categories }) =>
    hasSomeEndorsementsLeft(user.profile, categories),
  );
  const filteredUsers = filteredEndorsements.map(({ user }) => user);

  return (
    <EndorsementSenderContainer>
      {!shouldExpandList && (
        <Top
          closeModal={closeModal}
          button={
            pickedUser !== null
              ? {
                  kind: 'back',
                  handleClick: goBack,
                  ...(isMobile ? {} : { text: 'Endorse others', color: colors.primaryMain }),
                }
              : undefined
          }
          header={pickedUser !== null && isMobile ? 'Add an endorsement!' : ''}
        />
      )}
      {pickedUser === null ? (
        <UserPicker
          users={filteredUsers}
          pickRow={index => setPickedUser(filteredEndorsements[index])}
          shouldExpandList={shouldExpandList}
          setIsInputFocused={setIsInputFocused}
        />
      ) : (
        <EndorsementPicker
          user={pickedUser.user}
          categories={pickedUser.categories.filter(c => !sentPreviously(c.num))}
          shouldExpandList={shouldExpandList}
          setIsInputFocused={setIsInputFocused}
          pickEndorsement={pickEndorsement}
          getIsLoading={getIsLoading}
          goBack={goBack}
        />
      )}
    </EndorsementSenderContainer>
  );
};

const EndorsementSenderContainer = styled(FlexColumn)`
  width: 100%;
  background-color: ${colors.whiteMain};
  overflow: hidden;
  transition: ${globalTransitionSettings};

  ${media.desktop} {
    border-radius: 10px;
  }
  ${media.mobile} {
    border-radius: 10px 10px 0px 0px;
    flex-grow: 1;
    margin-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }
`;

interface PickerProps {
  shouldExpandList: boolean;
  setIsInputFocused: React.Dispatch<React.SetStateAction<boolean>>;
}

interface UserPickerProps extends PickerProps {
  users: MinimalUserDict[];
  pickRow: (index: number) => void;
}

const UserPicker: React.FC<UserPickerProps> = ({
  users,
  pickRow,
  shouldExpandList,
  setIsInputFocused,
}) => {
  const [userInput, setUserInput] = useState('');

  const isMobile = getIsMobile();
  const getRowContent = isMobile
    ? (user: MinimalUserDict) => (
        <>
          <Avatar
            src={user.image}
            avatarId={user.profile}
            size3
            style={{ marginRight: margins.size2 }}
          />
          <Heading3>
            {user.first_name} {user.last_name}
          </Heading3>
        </>
      )
    : (user: MinimalUserDict) => (
        <FlexColumn style={{ height: '100%', justifyContent: 'flex-start' }}>
          <Avatar
            src={user.image}
            avatarId={user.profile}
            size3
            style={{ marginBottom: margins.size2 }}
          />
          <SubText>{user.first_name}</SubText>
          <SubText>{user.last_name}</SubText>
        </FlexColumn>
      );

  const indexedUsers = users.map((user, index) => ({ index, user }));
  const filteredUsers = indexedUsers.filter(({ user }) =>
    user.name.toLowerCase().includes(userInput.toLowerCase()),
  );

  return (
    <PickerContainer>
      {!shouldExpandList && (
        <>
          <img src={endorsementSessionEndIcon} alt="Endorsement icon" />
          <UserPickerExplanation />
        </>
      )}
      <SearchInput
        userInput={userInput}
        setUserInput={setUserInput}
        setIsInputFocused={setIsInputFocused}
        placeholder="Search for a contact"
      />
      <List
        rows={filteredUsers.map(({ index, user }) => ({ index, data: user }))}
        getRowContent={getRowContent}
        pickRow={pickRow}
        isGrid={!isMobile}
      />
    </PickerContainer>
  );
};

const PICKER_HORIZONTAL_MARGIN_DESKTOP = parseInt(margins.size4, 10);
const PICKER_HORIZONTAL_MARGIN_MOBILE = parseInt(margins.size3, 10);

const PickerContainer = styled(FlexColumn)`
  overflow: hidden;
  width: 100%;
  transition: ${globalTransitionSettings};

  ${media.mobile} {
    flex-grow: 1;
    padding: ${margins.size4} ${PICKER_HORIZONTAL_MARGIN_MOBILE}px 0px
      ${PICKER_HORIZONTAL_MARGIN_MOBILE}px;
  }
  ${media.desktop} {
    height: ${ENDORSEMENT_MODAL_DESKTOP_BODY_HEIGHT}px;
    padding: ${PICKER_HORIZONTAL_MARGIN_DESKTOP}px;
  }
`;

const UserPickerExplanation = () => (
  <div style={{ marginTop: margins.size3 }}>
    <Heading2 style={{ marginBottom: margins.size2 }}>Anyone else come to mind?</Heading2>
    <Text>{ENDORSEMENTS_END_TEXT} Endorse more people?</Text>
  </div>
);

interface SearchInputProps {
  userInput: string;
  setUserInput: React.Dispatch<React.SetStateAction<string>>;
  setIsInputFocused: React.Dispatch<React.SetStateAction<boolean>>;
  placeholder: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  userInput,
  setUserInput,
  setIsInputFocused,
  placeholder,
}) => (
  <SearchInputContainer
    placeholder={placeholder}
    onChange={setUserInput}
    value={userInput}
    mobileFocusBehavior="never"
    onFocus={() => setIsInputFocused(true)}
    onBlur={() => setImmediate(() => setIsInputFocused(false))} // future TO-DO: find a less hacky way to do this
  />
);

const SearchInputContainer = styled(ContactsSearchTextInput)`
  margin: ${margins.size3} auto 0px auto;
  min-height: ${BUTTON_HEIGHT};

  ${media.desktop} {
    max-width: 600px;
  }
  ${media.mobile} {
    max-width: 500px;
  }
`;

interface ListProps<T> {
  rows: { index: number; data: T }[];
  getRowContent: (rowData: T) => JSX.Element;
  pickRow?: (index: number) => void;
  isGrid?: boolean;
}

const List = <T,>({ rows, getRowContent, pickRow, isGrid }: ListProps<T>) => {
  const isMobile = getIsMobile();
  const horizontalMargin =
    2 * (isMobile ? PICKER_HORIZONTAL_MARGIN_MOBILE : PICKER_HORIZONTAL_MARGIN_DESKTOP);

  const rowElements = isGrid
    ? rows.map(row => (
        <Clickable
          onClick={() => {
            if (pickRow) {
              pickRow(row.index);
            }
          }}
          style={{ margin: `${margins.size2} auto`, height: 97 }}
        >
          {getRowContent(row.data)}
        </Clickable>
      ))
    : rows.map(row => {
        const rowItem = (
          <FlexRow
            justifyContent="flex-start"
            style={{ width: '100%', marginTop: margins.size2, marginBottom: margins.size2 }}
          >
            {getRowContent(row.data)}
          </FlexRow>
        );

        return pickRow ? (
          <Clickable
            onClick={() => {
              pickRow(row.index);
            }}
            style={{ width: '100%' }}
          >
            {rowItem}
          </Clickable>
        ) : (
          rowItem
        );
      });

  return (
    <>
      <ListContainer isGrid={isGrid}>
        <MobileScrollFade width={`calc(100% - ${horizontalMargin}px)`} />
        {rowElements}
      </ListContainer>
    </>
  );
};

interface ListContainerProps {
  isGrid?: boolean;
}

const ListContainer: React.FC<ListContainerProps> = ({ isGrid, children }) =>
  isGrid ? (
    <ListGridContainer>{children}</ListGridContainer>
  ) : (
    <ListRegularContainer>{children}</ListRegularContainer>
  );

const LIST_CONTAINER_ATTRIBUTES = `
  width: 100%;
  overflow-y: scroll;
  flex-grow: 1;
  margin-top: ${margins.size3};
`;

const ListRegularContainer = styled(FlexColumn)`
  ${LIST_CONTAINER_ATTRIBUTES}
`;

const ListGridContainer = styled.div`
  ${LIST_CONTAINER_ATTRIBUTES}
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;

interface EndorsementPickerProps extends PickerProps {
  user: MinimalUserDict;
  categories: { num: number; name: string }[];
  pickEndorsement: (categoryNum: number, sentCallback: () => void) => Promise<void>;
  getIsLoading: (publicId: string, categoryNum: number) => boolean;
  goBack: () => void;
}

const EndorsementPicker: React.FC<EndorsementPickerProps> = ({
  user,
  categories,
  pickEndorsement,
  getIsLoading,
  goBack,
  shouldExpandList,
  setIsInputFocused,
}) => {
  const [userInput, setUserInput] = useState('');
  const [lastSentEndorsementName, setLastSentEndorsementName] = useState('');
  const [shouldDisplayEndorsementPopUp, setShouldDisplayEndorsementPopUp] = useState(false);

  const endorsementSentPopUp = (
    <EndorsementSentPopUp
      isDisplayed={shouldDisplayEndorsementPopUp}
      userName={user.first_name}
      endorsementName={lastSentEndorsementName}
      closePopUp={() => setShouldDisplayEndorsementPopUp(false)}
      goBack={goBack}
    />
  );

  const isMobile = getIsMobile();
  const indexedCategories = categories.map((category, index) => ({ index, category }));
  const filteredCategories = indexedCategories.filter(({ category }) =>
    category.name.toLowerCase().includes(userInput.toLowerCase()),
  );

  return (
    <PickerContainer>
      {!shouldExpandList && <AvatarWithName user={user} />}
      <SearchInput
        userInput={userInput}
        setUserInput={setUserInput}
        setIsInputFocused={setIsInputFocused}
        placeholder="Find an endorsement"
      />
      {!isMobile && endorsementSentPopUp}
      <List
        rows={filteredCategories.map(({ category, index }) => ({ index, data: category }))}
        getRowContent={category => (
          <>
            <UppercaseText>{category.name}</UppercaseText>
            <SendEndorsementButton
              handleClick={() => {
                setShouldDisplayEndorsementPopUp(false);
                pickEndorsement(category.num, () => {
                  setLastSentEndorsementName(category.name);
                  setShouldDisplayEndorsementPopUp(true);
                });
              }}
              isLoading={getIsLoading(user.profile, category.num)}
            />
          </>
        )}
      />
      {isMobile && endorsementSentPopUp}
    </PickerContainer>
  );
};

const AvatarWithName = ({ user }: { user: MinimalUserDict }) => (
  <div style={{ marginBottom: margins.size4 }}>
    <Avatar avatarId={user.profile} src={user.image} size5 />
    <Heading2 style={{ marginTop: margins.size2 }}>
      {user.first_name} {user.last_name}
    </Heading2>
  </div>
);

const UppercaseText = styled(Text)`
  &:first-letter {
    text-transform: uppercase;
  }
`;

const SendEndorsementButton = ({
  handleClick,
  isLoading,
}: {
  handleClick: () => void;
  isLoading: boolean;
}) => {
  const buttonContent = (
    <SendEndorsementButtonContainer $isLoading={isLoading}>
      {isLoading ? <SVGLoader small center={false} /> : <img src={plus} alt="Send endorsement" />}
    </SendEndorsementButtonContainer>
  );

  return isLoading ? (
    buttonContent
  ) : (
    <Clickable onClick={handleClick} style={{ marginLeft: 'auto' }}>
      {buttonContent}
    </Clickable>
  );
};

const SendEndorsementButtonContainer = styled.div<{ $isLoading: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 20px;
  background-color: ${colors.greyLight};
  display: flex;
  justify-content: center;
  align-items: center;
  ${p => p.$isLoading && 'margin-left: auto'};

  &:active {
    ${p => !p.$isLoading && `background-color: ${colors.greyMain}`};
  }
`;

interface EndorsementSentPopUpProps {
  isDisplayed: boolean;
  userName: string;
  endorsementName: string;
  closePopUp: () => void;
  goBack: () => void;
}

const ENDORSEMENT_SENT_POP_UP_MAX_HEIGHT = 100;

const EndorsementSentPopUp: React.FC<EndorsementSentPopUpProps> = ({
  isDisplayed,
  userName,
  endorsementName,
  closePopUp,
  goBack,
}) => {
  const isMobile = getIsMobile();
  const buttonText = 'Endorse other friends';

  return (
    <EndorsementSentPopUpContainer isDisplayed={isDisplayed}>
      <FlexColumn style={{ width: '100%', gap: margins.size3 }}>
        <FlexRow
          style={{ justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}
        >
          <EndorsementSentPopUpBody
            userName={userName}
            endorsementName={endorsementName}
            isDisplayed={isDisplayed}
          />
          {isMobile && (
            <Clickable onClick={closePopUp}>
              <img src={cross} alt="Close pop-up" />
            </Clickable>
          )}
          {!isMobile && (
            <Button
              onClick={goBack}
              style={{
                margin: 0,
                maxHeight: isDisplayed ? ENDORSEMENT_SENT_POP_UP_MAX_HEIGHT : 0,
              }}
              invalid={!isDisplayed}
            >
              {buttonText}
            </Button>
          )}
        </FlexRow>

        {isMobile && (
          <Button onClick={goBack} large invalid={!isDisplayed}>
            {buttonText}
          </Button>
        )}
      </FlexColumn>
    </EndorsementSentPopUpContainer>
  );
};

interface EndorsementSentPopUpBodyProps {
  userName: string;
  endorsementName: string;
  isDisplayed: boolean;
}

const EndorsementSentPopUpBody: React.FC<EndorsementSentPopUpBodyProps> = ({
  userName,
  endorsementName,
  isDisplayed,
}) => (
  <FlexRow
    style={{
      transition: globalTransitionSettings,
      maxHeight: isDisplayed ? ENDORSEMENT_SENT_POP_UP_MAX_HEIGHT : 0,
    }}
  >
    <img src={endorsementIcon} alt="Endorsement icon" style={{ marginRight: margins.size3 }} />
    <FlexColumn alignItems="flex-start">
      <UppercaseHeading3>{endorsementName}</UppercaseHeading3>
      <Text>Endorsement sent to {userName}</Text>
    </FlexColumn>
  </FlexRow>
);

const ENDORSEMENT_SENT_POP_UP_MARGIN = parseInt(margins.size2, 10);

const EndorsementSentPopUpContainer = styled.div<{ isDisplayed: boolean }>`
  background-color: ${colors.whiteMain};
  border-radius: 10px;
  border: 1px solid ${colors.greyLight};
  transition: ${globalTransitionSettings};
  opacity: ${({ isDisplayed }) => (isDisplayed ? 1 : 0)};

  ${media.mobile} {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0px ${ENDORSEMENT_SENT_POP_UP_MARGIN}px
      calc(${ENDORSEMENT_SENT_POP_UP_MARGIN}px + env(safe-area-inset-bottom))
      ${ENDORSEMENT_SENT_POP_UP_MARGIN}px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    width: calc(100% - ${ENDORSEMENT_SENT_POP_UP_MARGIN * 2}px);
    max-width: 400px;
    padding: ${margins.size3};
  }
  ${media.desktop} {
    width: 100%;
    max-height: ${p => (p.isDisplayed ? `${ENDORSEMENT_SENT_POP_UP_MAX_HEIGHT}px` : '0px')};
    padding: ${p => (p.isDisplayed ? `${margins.size2} ${margins.size3}` : '0px')};
    margin-top: ${p => (p.isDisplayed ? margins.size2 : '0px')};
  }
`;
