export enum AutopilotSteps {
  CHECK_COACHING_PLAN_ELIGIBILITY = 0,
  COACHING_PLAN = 1,
  COACHING_PLAN_SPLASH = 2,
  TIMESLOTS = 3,
  CONFIRMATION = 4,
  SETTINGS = 5,
}

const MAX_MATCHES_WEEKLY = 6;
const MAX_MATCHES_MORE_THAN_WEEKLY = 1;

export const getNumMaxMeetings = (selectedCadence: number) =>
  selectedCadence === CadenceTypes.Weekly.value ? MAX_MATCHES_WEEKLY : MAX_MATCHES_MORE_THAN_WEEKLY;

export const AutopilotHeaders = {
  Cadence: {
    title: 'How often would you like to meet new people?',
    subtext: 'You can update this at any time!',
  },
  TimeslotsWeeklyCadence: {
    title: 'When should we schedule your {} meetings?',
    subtext: `You can select up to ${MAX_MATCHES_WEEKLY} meetings in a given week!`,
  },
  TimeslotsOtherCadence: {
    title: 'When should we schedule your {} meetings?',
    subtext: `You can select ${MAX_MATCHES_MORE_THAN_WEEKLY} meeting a week!`,
  },
};

export interface CadenceType {
  title: 'Every week' | 'Every 2 weeks' | 'Every 4 weeks';
  timeslotsHeader: 'weekly' | 'bi-weekly' | 'monthly';
  value: 1 | 2 | 4;
}

export const CadenceTypes: Record<string, CadenceType> = {
  Weekly: {
    title: 'Every week',
    timeslotsHeader: 'weekly',
    value: 1,
  },
  Biweekly: {
    title: 'Every 2 weeks',
    timeslotsHeader: 'bi-weekly',
    value: 2,
  },
  Monthly: {
    title: 'Every 4 weeks',
    timeslotsHeader: 'monthly',
    value: 4,
  },
};

export const DEFAULT_CADENCE = CadenceTypes.Weekly;

export const getHeader = (step: number, selectedCadence: CadenceType = DEFAULT_CADENCE) => {
  if (step === AutopilotSteps.TIMESLOTS) {
    return AutopilotHeaders.TimeslotsWeeklyCadence.title.replace(
      '{}',
      selectedCadence.timeslotsHeader,
    );
  }
  return '';
};

export const getSubheader = (step: number, selectedCadence?: number) => {
  if (step === AutopilotSteps.TIMESLOTS && selectedCadence !== undefined) {
    return selectedCadence === CadenceTypes.Weekly.value
      ? `${AutopilotHeaders.TimeslotsWeeklyCadence.subtext}`
      : `${AutopilotHeaders.TimeslotsOtherCadence.subtext}`;
  }
  return '';
};

type AutopilotStateType = {
  title?: string;
  text?: string;
  buttonText?: string;
};

export enum AutopilotStatuses {
  on,
  paused,
  passedThisWeek,
  punished,
  notCadenceWeekBiweekly,
  notCadenceWeekMonthly,
}

export const AutopilotStates: Record<AutopilotStatuses, AutopilotStateType> = {
  [AutopilotStatuses.on]: {},
  [AutopilotStatuses.paused]: {
    title: 'You paused recurring meetings!',
    text: 'You told us you’d like a break from being introduced to new people.',
    buttonText: 'Resume meetings',
  },
  [AutopilotStatuses.passedThisWeek]: {
    title: 'You passed for the week',
    text: 'You’re taking a break from meeting new people this week.',
    buttonText: 'Sign up for meetings',
  },
  [AutopilotStatuses.punished]: {
    title: 'We paused your account because you’ve been inactive',
    text:
      'We noticed that you haven’t been responding to your matches so we paused introductions for you.',
    buttonText: 'Resume meetings',
  },
  [AutopilotStatuses.notCadenceWeekBiweekly]: {
    title: 'You don’t have any upcoming meetings!',
    text: 'You don’t have any meetings this week because you selected bi-weekly matches',
    buttonText: 'Change your cadence',
  },
  [AutopilotStatuses.notCadenceWeekMonthly]: {
    title: 'You don’t have any upcoming meetings!',
    text: 'You don’t have any meetings this week because you selected monthly matches',
    buttonText: 'Change your cadence',
  },
};

export const CONFIRMATION_PAGE_DELAY = 2000;
