import React from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import { colors, globalTransitionSettings, margins, media, FlexRow } from 'css/css';

import { TextInput } from 'js/components/shared/TextInput';
import { postTestActivity } from 'js/util/api';
import { getIsMobile } from 'js/util/util';

import {
  linkedinIcon,
  twitterIcon,
  githubIcon,
  angellistIcon,
  instagramIcon,
  personalIcon,
} from 'img/shared/social-icons';

interface Props {
  editing?: boolean;
  profiles: {
    linkedin?: string;
    twitter?: string;
    github?: string;
    angellist?: string;
    instagram?: string;
    personal?: string;
  };
  editField?: (field: string, eventString: string) => void;
}
export const Socials: React.FC<Props> = ({ editing, profiles, editField }) => {
  const socialLinksInfo = [
    {
      icon: linkedinIcon,
      fieldKey: 'linkedin_url',
      placeholder: 'LinkedIn url',
      link: profiles.linkedin,
    },
    {
      icon: twitterIcon,
      fieldKey: 'twitter_url',
      placeholder: 'Twitter url',
      link: profiles.twitter,
    },
    { icon: githubIcon, fieldKey: 'github_url', placeholder: 'GitHub url', link: profiles.github },
    {
      icon: angellistIcon,
      fieldKey: 'angellist_url',
      placeholder: 'AngelList url',
      link: profiles.angellist,
    },
    {
      icon: instagramIcon,
      fieldKey: 'instagram_url',
      placeholder: 'Instagram url',
      link: profiles.instagram,
      hideMobile: true,
    },
    {
      icon: personalIcon,
      fieldKey: 'personal_url',
      placeholder: 'Personal site',
      link: profiles.personal,
      hideMobile: true,
    },
  ];
  if (editing && editField) {
    return (
      <FlexRow $wrap adapting alignItems="left">
        {socialLinksInfo.map((website, idx) => (
          <AdaptingHalfRow
            key={website.fieldKey}
            style={{ marginTop: idx !== 0 ? margins.size1 : '0px' }}
          >
            <SocialIcon src={website.icon} alt={website.fieldKey} />

            <TextInput
              onChange={(e: string) => editField(website.fieldKey, e)}
              value={website.link || ''}
              placeholder={website.placeholder}
            />
          </AdaptingHalfRow>
        ))}
      </FlexRow>
    );
  }
  return (
    <FlexRow>
      {socialLinksInfo.map(website =>
        website.link && !(getIsMobile() && website.hideMobile) ? (
          <SocialLink
            key={website.fieldKey}
            href={website.link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => postTestActivity(`${website.fieldKey}`, `${website.link}`)}
          >
            <SocialIcon src={website.icon} alt={website.placeholder} />
          </SocialLink>
        ) : null,
      )}
    </FlexRow>
  );
};

const SocialIcon = styled(SVG)`
  margin-right: ${margins.size2};
  fill: ${colors.blackMain};
  transition: ${globalTransitionSettings};
  & > path {
    transition: ${globalTransitionSettings};
    fill: ${colors.blackMain};
  }
  &:hover {
    fill: ${colors.primaryMain};
    & > path {
      fill: ${colors.primaryMain};
    }
  }
  ${media.mobile} {
    height: 18px;
    width: 18px;
  }
`;

const SocialLink = styled.a`
  margin: 0 ${margins.size1};
  height: 24px;
  width: 24px;
  ${media.mobile} {
    height: 18px;
    width: 18px;
  }
`;

const AdaptingHalfRow = styled(FlexRow)`
  width: 50%;
  ${media.mobile} {
    width: 100%;
  }
`;
