/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';

import { colors, globalTransitionSettings, media } from 'css/css';

import star from 'img/feedback/star.svg';

export const Stars = ({
  fixed = false,
  updateStarCount,
  starSize,
  rating,
  containerStyle,
}: any) => {
  const [highlightedStars, setHighlightedStars] = useState(0);

  const hoverStars = (event: any) =>
    fixed ? null : setHighlightedStars(parseInt(event.target.id, 10) + 1);
  const stopHoveringStars = () => setHighlightedStars(0);
  const clickStar = (event: any) =>
    fixed ? null : updateStarCount(parseInt(event.target.id, 10) + 1);

  const selectedStars = highlightedStars || rating;

  return (
    <Container style={containerStyle} role="radiogroup">
      {[0, 1, 2, 3, 4].map(idx => (
        <Star
          id={idx}
          key={idx}
          src={star}
          role="radio"
          aria-checked={selectedStars > idx}
          filled={selectedStars > idx}
          title={`${idx + 1} stars`}
          starSize={starSize}
          onMouseOver={hoverStars}
          onMouseLeave={stopHoveringStars}
          onClick={clickStar}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Star = styled(SVG)`
  width: ${(props: any) => (props.starSize ? props.starSize : '24px')};
  height: ${(props: any) => (props.starSize ? props.starSize : '24px')};
  cursor: pointer;
  transition: ${globalTransitionSettings};
  * {
    pointer-events: none;
    fill: ${(props: any) => (props.filled ? colors.tertiary3Main : 'none')};
  }

  ${media.mobile} {
    * {
      pointer-events: none;
      fill: ${(props: any) => (props.filled ? colors.tertiary3Main : 'none')};
    }
  }
`;
