import React, { useRef, useMemo } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';

import {
  FlexRow,
  FlexColumn,
  Heading3,
  Text,
  margins,
  media,
  Clickable,
  colors,
  Link,
  fonts,
  fontSizes,
} from 'css/css';

import Channel, { SuperMessage, LCMessageType } from 'types/channel';

import { Button } from 'js/components/shared/Button';
import { postChatCreateConversation } from 'js/util/api';
import { useOutsideAlerter } from 'js/util/custom-hooks';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { EVENT_STATUS } from 'js/util/util';
import { useUserContextProvider } from 'js/providers/UserProvider';

import close from 'img/shared/close-small.svg';

import { CalendarStatus } from './conversation-header/CalendarStatus';
import { ReconnectReminderPopup } from './scheduler/ReconnectReminderPopup';
import { SchedulerRequest } from './scheduler/SchedulerRequest';
import { PendingMessage } from './ConversationContainer';

interface Props {
  shouldDisplayReconnect: boolean;
  selectedChannel: Channel;
  matchPrivateNote?: string;
  setIsSchedulerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsReconnect: React.Dispatch<React.SetStateAction<boolean>>;
  isFirstTimeScheduling: boolean;
  metDate?: string;
  userTimezone: string;
  inputHeight: number;
  isUpcoming: boolean;
  isScheduling: boolean;
  setIsChangingTimes: React.Dispatch<React.SetStateAction<boolean>>;
  setPendingMessage: React.Dispatch<React.SetStateAction<PendingMessage | undefined>>;
  openScheduler: ({
    isReconnecting,
    isEditingTimes,
  }: {
    isReconnecting: boolean;
    isEditingTimes: boolean;
  }) => void;
  inCall: boolean;
  lastSchedulerMessage?: SuperMessage;
  isMobileKeyboardOpen: boolean;
  popupContentHash: string;
  reconnectDate?: string;
  isUserScheduleInitiator: boolean;
}

export const ConversationPopup: React.FC<Props> = ({
  shouldDisplayReconnect,
  selectedChannel,
  matchPrivateNote,
  setIsSchedulerOpen,
  setIsReconnect,
  isFirstTimeScheduling,
  metDate,
  userTimezone,
  inputHeight,
  isUpcoming,
  isScheduling,
  setIsChangingTimes,
  setPendingMessage,
  openScheduler,
  inCall,
  lastSchedulerMessage,
  isMobileKeyboardOpen,
  popupContentHash,
  reconnectDate,
  isUserScheduleInitiator,
}) => {
  const { setIsPopupOpen } = useChatContext();

  // calendar status on chat is disabled for now
  const containerRef = useRef<HTMLDivElement>(null);
  const { updateUser } = useUserContextProvider();

  const schedulerTimeslots = useMemo(
    () => (lastSchedulerMessage?.attributes as any)?.schedulerTimeslots || [],
    [lastSchedulerMessage],
  );

  useOutsideAlerter(containerRef, () => setIsPopupOpen(false));

  if (isMobileKeyboardOpen) {
    return null;
  }

  return (
    <SchedulerContainer $inputHeight={inputHeight} ref={containerRef}>
      <CloseButton
        onClick={() => {
          if (selectedChannel.matchCode) {
            updateUser({
              visualSettings: {
                [selectedChannel.profileId]: popupContentHash,
              },
            });
          }
          setIsPopupOpen(false);
        }}
      >
        <img src={close} alt="close" style={{ verticalAlign: 'initial' }} />
      </CloseButton>
      <PopupContent
        shouldDisplayReconnect={shouldDisplayReconnect}
        selectedChannel={selectedChannel}
        matchPrivateNote={matchPrivateNote}
        setIsSchedulerOpen={setIsSchedulerOpen}
        setIsReconnect={setIsReconnect}
        isFirstTimeScheduling={isFirstTimeScheduling}
        metDate={metDate}
        userTimezone={userTimezone}
        isUpcoming={isUpcoming}
        isScheduling={isScheduling}
        setIsChangingTimes={setIsChangingTimes}
        setPendingMessage={setPendingMessage}
        openScheduler={openScheduler}
        inCall={inCall}
        schedulerTimeslots={schedulerTimeslots}
        isUserScheduleInitiator={isUserScheduleInitiator}
        reconnectDate={reconnectDate}
      />
      <PopupTriangle />
    </SchedulerContainer>
  );
};

interface PopupContentProps {
  shouldDisplayReconnect: boolean;
  selectedChannel: Channel;
  matchPrivateNote?: string;
  setIsSchedulerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsReconnect: React.Dispatch<React.SetStateAction<boolean>>;
  isFirstTimeScheduling: boolean;
  metDate?: string;
  userTimezone: string;
  isUpcoming: boolean;
  isScheduling: boolean;
  setIsChangingTimes: React.Dispatch<React.SetStateAction<boolean>>;
  setPendingMessage: React.Dispatch<React.SetStateAction<PendingMessage | undefined>>;
  openScheduler: ({
    isReconnecting,
    isEditingTimes,
  }: {
    isReconnecting: boolean;
    isEditingTimes: boolean;
  }) => void;
  inCall: boolean;
  schedulerTimeslots: string[];
  isUserScheduleInitiator: boolean;
  reconnectDate?: string;
}

const PopupContent: React.FC<PopupContentProps> = ({
  shouldDisplayReconnect,
  selectedChannel,
  matchPrivateNote,
  setIsSchedulerOpen,
  setIsReconnect,
  isFirstTimeScheduling,
  metDate,
  userTimezone,
  isUpcoming,
  isScheduling,
  setIsChangingTimes,
  setPendingMessage,
  openScheduler,
  inCall,
  schedulerTimeslots,
  isUserScheduleInitiator,
  reconnectDate,
}) => {
  const { confirmCall } = useChatContext();
  const {
    firstName,
    matchCode,
    isBlocked,
    conversation,
    prescheduledReconnectDate,
    profileId,
    messages,
    userEventStatus,
    canReschedule,
    isInPersonMatch,
    matchUserEventStatus,
    isMorpheusMatch,
  } = selectedChannel;
  const isUserConfirmed = userEventStatus === EVENT_STATUS.confirmed;
  const shouldShowRescheduler = canReschedule && !isBlocked;
  const shouldShowJoinMeeting = !isInPersonMatch && !isBlocked;
  const canShowCalendarStatus = userEventStatus !== undefined && matchUserEventStatus !== undefined;

  const prescheduleMessage = messages.find(
    m => (m?.attributes as any)?.messageType === LCMessageType.PRESCHEDULED_RECONNECT,
  );
  const prescheduleCreated = prescheduleMessage?.dateUpdated;
  const shouldShowPrescheduledReconnect =
    !!prescheduledReconnectDate &&
    canShowCalendarStatus &&
    !messages.some(
      m =>
        (m?.attributes as any)?.schedulerTimeslots?.length &&
        !!prescheduledReconnectDate &&
        moment(m.dateUpdated).isAfter(moment(prescheduleCreated)),
    );

  const isReconnecting = (conversation?.attributes as any)?.isReconnecting;
  const changeTimes = () => {
    if (isReconnecting) {
      setIsReconnect(true);
    }
    setIsSchedulerOpen(true);
    setIsChangingTimes(true);
  };

  if (isScheduling && schedulerTimeslots.length) {
    return (
      <SchedulerRequest
        isFirstTimeScheduling={isFirstTimeScheduling}
        isReconnect={isReconnecting}
        isUserScheduleInitiator={isUserScheduleInitiator}
        openScheduler={openScheduler}
        setPendingMessage={setPendingMessage}
        timeslots={schedulerTimeslots}
        changeTimes={changeTimes}
        inCall={inCall}
        selectedChannel={selectedChannel}
        userTimezone={userTimezone}
      />
    );
  }

  if (shouldShowPrescheduledReconnect) {
    return (
      <>
        <CalendarStatus
          selectedChannel={selectedChannel}
          metDate={reconnectDate as string}
          userTimezone={userTimezone}
        />
        <Link to={`/call/${matchCode}?ref=chat`} style={{ marginRight: margins.size1 }}>
          <Button>Join room</Button>
        </Link>
      </>
    );
  }
  if (shouldDisplayReconnect) {
    return (
      <>
        <ReconnectReminderPopup
          firstName={firstName}
          openReconnect={() => openScheduler({ isReconnecting: true, isEditingTimes: false })}
          matchPrivateNote={matchPrivateNote}
        />
      </>
    );
  }

  if (shouldShowRescheduler && isFirstTimeScheduling) {
    return (
      <>
        {isMorpheusMatch ? (
          <Text style={{ marginBottom: margins.size2, marginRight: margins.size4 }}>
            Schedule a time to meet with {firstName} over a video call!
          </Text>
        ) : (
          <Text style={{ marginBottom: margins.size2, marginRight: margins.size4 }}>
            We matched you to {firstName}, but{' '}
            <BoldTextSpan>you&apos;ll need to schedule a time to meet!</BoldTextSpan>
          </Text>
        )}
        <FlexRow>
          <Button
            style={{ marginRight: margins.size1 }}
            onClick={() => {
              setIsSchedulerOpen(true);
              if (!conversation) {
                postChatCreateConversation(profileId);
              }
            }}
          >
            Schedule
          </Button>
          {shouldShowJoinMeeting && (
            <Link to={`/call/${matchCode}?ref=chat`} style={{ width: '100%' }}>
              <Button secondary>Join meeting room</Button>
            </Link>
          )}
        </FlexRow>
      </>
    );
  }

  if (shouldShowRescheduler) {
    return (
      <>
        {!isUpcoming || !canShowCalendarStatus ? (
          <Text style={{ marginBottom: margins.size2, marginRight: margins.size5 }}>
            Your video call {isUpcoming ? 'is' : 'was'} scheduled {isUpcoming ? 'for' : 'on'}{' '}
            <BoldTextSpan>
              {moment(metDate).format('dddd MMM DD [at] h:mma')} {userTimezone}.
            </BoldTextSpan>
          </Text>
        ) : (
          <CalendarStatus
            selectedChannel={selectedChannel}
            metDate={metDate}
            userTimezone={userTimezone}
          />
        )}
        <FlexRow>
          {!isUserConfirmed && canShowCalendarStatus ? (
            <Button
              style={{ marginRight: margins.size1 }}
              onClick={() => {
                if (matchCode) {
                  confirmCall(profileId);
                }
              }}
            >
              Confirm call
            </Button>
          ) : (
            shouldShowJoinMeeting && (
              <Link to={`/call/${matchCode}?ref=chat`} style={{ marginRight: margins.size1 }}>
                <Button>Join room</Button>
              </Link>
            )
          )}
          <Button
            plain={!shouldShowJoinMeeting && !(!isUserConfirmed && canShowCalendarStatus)}
            secondary={(!isUserConfirmed && canShowCalendarStatus) || shouldShowJoinMeeting}
            onClick={() => {
              setIsSchedulerOpen(true);
              setIsReconnect(false);
              if (!conversation) {
                postChatCreateConversation(profileId);
              }
            }}
          >
            Reschedule
          </Button>
        </FlexRow>
      </>
    );
  }
  return (
    <>
      <Heading3 style={{ marginRight: margins.size4, marginBottom: margins.size3 }}>
        Join the call room for this meeting
      </Heading3>
      <Link to={`/call/${matchCode}?ref=chat`} style={{ marginRight: margins.size1 }}>
        <Button>Join room</Button>
      </Link>
    </>
  );
};

const SchedulerContainer = styled(FlexColumn)<{
  $inputHeight: number;
}>`
  align-items: flex-start;
  text-align: left;
  position: absolute;
  bottom: ${p => `calc(${p.$inputHeight}px + ${margins.size2})`};
  right: 0;
  background-color: ${colors.whiteMain};
  border-radius: 10px;
  max-width: 360px;
  min-width: 327px;
  z-index: 1;
  padding: ${margins.size3};
  margin: ${margins.size2};
  box-shadow: 0 4px 27px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  ${media.mobile} {
    max-width: unset;
    min-width: unset;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    left: 0;
  }
`;

const BoldTextSpan = styled.span`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.size2};
  color: ${colors.blackMain};
`;

const PopupTriangle = styled.div`
  width: 18px;
  height: 18px;
  transform: rotate(45deg);
  position: absolute;
  bottom: -9px;
  right: 32px;
  margin-top: -9px;
  background-color: ${colors.whiteMain};
`;

const CloseButton = styled(Clickable)`
  position: absolute;
  top: ${margins.size3};
  right: ${margins.size3};
  max-height: 18px;
`;
