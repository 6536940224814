import React from 'react';
import styled from 'styled-components/macro';

import { margins, media, FlexRow, globalTransitionSettings } from 'css/css';

import { useUIStateContext } from '../../contexts/UIStateContext';

import { AudioControls } from './AudioControls';
import { VideoControls } from './VideoControls';
import { ScreenShareControls } from './ScreenShareControls';
import { LeaveButton } from './LeaveButton';

export const Tray: React.FC = () => {
  const { areControlsVisible } = useUIStateContext();

  return (
    <Container id="tray" isVisible={areControlsVisible}>
      <AudioControls />
      <Spacer />
      <VideoControls />
      <Spacer />
      <ScreenShareControls />
      <LeaveButton />
    </Container>
  );
};

const Container = styled(FlexRow)<{ isVisible: boolean }>`
  width: fit-content;
  position: absolute;
  margin: 0 auto;
  bottom: ${margins.size4};
  opacity: ${p => (p.isVisible ? 100 : 0)};
  transition: ${globalTransitionSettings};

  ${media.mobile} {
    top: 85%;
    bottom: 15%;
  }
`;

const Spacer = styled.div`
  margin-right: ${margins.size3};
`;
