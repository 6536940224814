import React from 'react';

import { Browser, isAndroidChrome } from 'js/components/callv2/utils/browser';

import chromeIcon from 'img/call/browser/chrome.png';
import edgeIcon from 'img/call/browser/edge.png';
import firefoxNewIcon from 'img/call/browser/firefox-new.png';
import firefoxIcon from 'img/call/browser/firefox.png';
import androidChromeIcon from 'img/call/browser/android-chrome.png';
import operaIcon from 'img/call/browser/opera.png';

type BrowserIconProps = {
  browserName: string;
  browserVersion: string;
};

export const BrowserIcon: React.FC<BrowserIconProps> = ({ browserName, browserVersion }) => {
  const isFirefoxNew = browserName === Browser.FIREFOX && parseFloat(browserVersion) >= 89;
  const isEdge = browserName === Browser.EDGE;
  const isChrome = browserName === Browser.CHROME;
  const isFirefox = browserName === Browser.FIREFOX;
  const isOpera = browserName === Browser.OPERA;

  return isAndroidChrome() ? (
    <img
      alt="android-chrome-icon"
      src={androidChromeIcon}
      style={{ width: '14px', marginLeft: '2px', marginRight: '2px', verticalAlign: 'top' }}
    />
  ) : isFirefoxNew ? (
    <img alt="firefox-new-icon" src={firefoxNewIcon} style={{ width: '48px' }} />
  ) : isEdge ? (
    <img alt="edge-icon" src={edgeIcon} style={{ width: '18px', marginLeft: '2px' }} />
  ) : isChrome ? (
    <img alt="chrome-icon" src={chromeIcon} style={{ width: '24px' }} />
  ) : isFirefox ? (
    <img alt="firefox-icon" src={firefoxIcon} style={{ width: '48px', marginLeft: '2px' }} />
  ) : isOpera ? (
    <img alt="opera-icon" src={operaIcon} style={{ width: '16px', verticalAlign: 'top' }} />
  ) : null;
};
