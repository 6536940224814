import { isProduction } from 'js/util/util';

export const callUrl = (callId?: string): string | undefined => {
  if (callId === undefined) return undefined;

  const domain = isProduction()
    ? 'https://lunchclub.daily.co/'
    : 'https://elliot-test-box.daily.co/';

  return `${domain}${callId}`;
};
