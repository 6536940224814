import React, { useState } from 'react';

import { useUser, useUserAvailability, useUserContextProvider } from 'js/providers/UserProvider';
import { autopilotTargetUserLocale } from 'js/util/api';
import { apiFailure } from 'js/util/strings';
import { useNotifContext } from 'js/util/notif-context';
import { useDataContext } from 'js/providers/DataContextProvider';

import { ToggleSection } from '../shared-components/ToggleSection';
import { SettingsSectionsTitles, getTargetLocaleSubtitle } from '../../constants';

export const TargetLocale = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { autopilot_paused_until, locale } = useUser();
  const { fetchUser, updateUserAvailability } = useUserContextProvider();
  const userAvailability = useUserAvailability();
  const { showNotif } = useNotifContext();
  const { allActiveLocales } = useDataContext();
  const targetableLocaleIds = allActiveLocales.filter(l => l.large).map(l => l.id);

  if (
    !targetableLocaleIds.includes(locale) ||
    userAvailability?.coaching_plan_details?.on_coaching_plan
  ) {
    return null;
  }

  const handleToggleClick = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const res = await autopilotTargetUserLocale(
      userAvailability?.availability_autopilot?.target_locale === null,
    );
    if (res.ok) {
      await Promise.all([fetchUser(), updateUserAvailability()]);
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
    setIsLoading(false);
  };

  return (
    <ToggleSection
      headingTitle={SettingsSectionsTitles.TARGET_LOCALE}
      subtitle={getTargetLocaleSubtitle({ pausedUntil: autopilot_paused_until })}
      onClick={handleToggleClick}
      isOn={userAvailability?.availability_autopilot?.target_locale !== null}
    />
  );
};
