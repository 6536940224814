import React from 'react';
import styled from 'styled-components/macro';

import LogoImage from 'img/shared/logo2.svg';

export const BigLogo = ({ verticalMargin, clickable }: any) => (
  <BigLogoImg
    clickable={clickable}
    verticalMargin={verticalMargin}
    src={LogoImage}
    alt="Logo"
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'push' does not exist on type 'History'.
    onClick={() => clickable && window.history.push('/home')}
  />
);

type BigLogoTypes = {
  verticalMargin: string;
  clickable: boolean;
};
const BigLogoImg = styled.img<BigLogoTypes>`
  margin: ${p => p.verticalMargin || '0'} auto;
  cursor: ${p => (p.clickable ? 'pointer' : 'default')};
  width: 33vw;
  max-width: 220px;
  min-width: 150px;
`;
