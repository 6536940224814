import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useRouteMatch } from 'react-router';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import { margins, colors, Clickable, FlexColumn, FlexRow, media } from 'css/css';

import { ProductUser } from 'types/user';

import {
  ProfileAvatarImage,
  ProfileBackground,
  ProfileMobile,
} from 'js/components/profile/ProfileMobile';
import { Button } from 'js/components/shared/Button';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { GreyPage } from 'js/components/shared/page-wrappers';
import { MoreProfileOptions } from 'js/components/shared/MoreProfileOptions';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { useUser } from 'js/providers/UserProvider';
import { getUserProfile, getUserProfileShuffle } from 'js/util/api';
import { getIsMobile, getParameterByName, replaceHistory, LC_BOT_PUBLIC_ID } from 'js/util/util';

import sendMessageSvg from 'img/profile/send-message.svg';
import { shuffleSvg } from 'img/profile';
import seeMore from 'img/chat/more-button.svg';
import cross from 'img/weekly/x.svg';

import { LargeArrow } from '../shared/LargeArrow';

export const PublicProfile: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>('/member/:id');
  const profileId = match && match.params.id;

  const [memberObject, setMemberObject] = useState<ProductUser>();
  const [loadingShuffle, setLoadingShuffle] = useState(false);
  const [isSeeMoreDropdownOpen, setIsSeeMoreDropdownOpen] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  const didComeFromChat = getParameterByName('ref') === 'chat';
  const user = useUser();
  const isLoggedIn = !!user;

  const { channels } = useChatContext();
  const isConnectionInChat = !!channels.find(ch => ch.profileId === profileId);
  const [navbarHeaderHeight, setNavbarHeaderHeight] = useState(0);

  useEffect(() => {
    const loadMemberData = async () => {
      if (!profileId) {
        return;
      }
      const res = await getUserProfile(profileId);
      if (res.ok) {
        setMemberObject(res.getJson);
        setIsBlocked(res.getJson?.blocked || false);
      } else if (res.status === 303) {
        if (res.getJson.error === 'Viewing own profile') replaceHistory(history, '/profile');
      } else if (res.status === 404) {
        replaceHistory(history, '/404');
      }
    };

    window.scrollTo(0, 0);

    loadMemberData();
  }, [profileId]);

  const loadShuffle = async () => {
    if (loadingShuffle) return;
    setLoadingShuffle(true);
    const res = await getUserProfileShuffle(profileId);
    if (res.ok) {
      const { public_id: shuffleMember } = res.getJson;
      setLoadingShuffle(false);
      history.push(`/member/${shuffleMember}?ref=shuffle`);
    }
  };

  if (!memberObject)
    return (
      <GreyPage>
        <SVGLoader />
      </GreyPage>
    );

  const { first_name: userFirstName, profile_id: userProfileId } = memberObject;

  const TopNav: React.FC = () => {
    const shouldDisplaySeeMoreButton = userProfileId !== LC_BOT_PUBLIC_ID;
    return (
      <TopNavContainer>
        {didComeFromChat ? (
          <Link to={`/chat?chat_id=${profileId}`}>
            <CircularButton>
              <LargeArrow direction="left" color={colors.blackMain} />
            </CircularButton>
          </Link>
        ) : (
          <>
            <CircularButton
              onClick={() => {
                setIsSeeMoreDropdownOpen(prev => !prev);
              }}
              onMouseDown={e => e.stopPropagation()}
              style={{
                marginRight: margins.size3,
                ...(shouldDisplaySeeMoreButton ? {} : { visibility: 'hidden' }),
              }}
            >
              <SeeMoreButton src={seeMore} alt="See more" />
            </CircularButton>
            {isSeeMoreDropdownOpen && !getIsMobile() && (
              <MoreProfileOptions
                setIsSeeMoreDropdownOpen={setIsSeeMoreDropdownOpen}
                isBlocked={isBlocked}
                firstName={userFirstName}
                profileId={userProfileId}
                setIsBlocked={setIsBlocked}
              />
            )}
          </>
        )}
        {isLoggedIn && loadingShuffle ? (
          <CircularButton disabled style={{ marginLeft: margins.size3 }}>
            <SVGLoader center={false} small />
          </CircularButton>
        ) : (
          <CircularButton onClick={loadShuffle} style={{ marginLeft: margins.size3 }}>
            <img src={shuffleSvg} alt="shuffle" />
          </CircularButton>
        )}
      </TopNavContainer>
    );
  };

  return (
    <GreyPage
      style={{
        padding: '0px',
        paddingTop: getIsMobile() ? '0px' : margins.size3,
        background: colors.whiteMain,
      }}
      setNavbarHeaderHeight={setNavbarHeaderHeight}
    >
      <TopNav />

      <MemberProfileBody
        user={memberObject}
        hasBottomNav={isConnectionInChat}
        navbarHeaderHeight={getIsMobile() ? navbarHeaderHeight : 0}
      />
      {isConnectionInChat && getIsMobile() && <div style={{ height: 54 }} />}

      {isSeeMoreDropdownOpen && getIsMobile() && (
        <MoreProfileOptions
          setIsSeeMoreDropdownOpen={setIsSeeMoreDropdownOpen}
          isBlocked={isBlocked}
          firstName={userFirstName}
          profileId={userProfileId}
          setIsBlocked={setIsBlocked}
        />
      )}
    </GreyPage>
  );
};

export const MEMBER_PROFILE_MAX_WIDTH = 650;

interface MemberProfileBodyProps {
  user: ProductUser;
  hasBottomNav: boolean;
  navbarHeaderHeight?: number;
  handleClose?: () => void;
}

export const MemberProfileBody: React.FC<MemberProfileBodyProps> = ({
  user,
  hasBottomNav,
  navbarHeaderHeight,
  handleClose,
}) => {
  const BottomNav: React.FC = () =>
    getIsMobile() && navbarHeaderHeight !== undefined ? (
      <MobileBottomNavContainer navbarHeaderHeight={navbarHeaderHeight}>
        <Link to={`/chat?chat_id=${user.public_id}`}>
          <Button>
            <img src={sendMessageSvg} alt="Send message" style={{ marginRight: margins.size2 }} />
            Message {user.first_name}
          </Button>
        </Link>
      </MobileBottomNavContainer>
    ) : (
      <BottomNavContainer>
        <Link to={`/chat?chat_id=${user.public_id}`}>
          <Button>
            <img src={sendMessageSvg} alt="Send message" style={{ marginRight: margins.size2 }} />
            Message {user.first_name}
          </Button>
        </Link>
      </BottomNavContainer>
    );

  return (
    <>
      {handleClose !== undefined && (
        <CircularButton
          style={{
            position: 'fixed',
            top: `calc(env(safe-area-inset-top) + ${margins.size3}`,
            right: margins.size3,
            zIndex: 2,
          }}
          onClick={handleClose}
        >
          <img src={cross} alt="Close" />
        </CircularButton>
      )}
      <Container>
        <ProfileBackground style={{ minHeight: '100px' }}>
          <ProfileAvatarImage src={user.image} avatarId={user.public_id} />
        </ProfileBackground>
        <ProfileMobile profile={user} endorsements={user.endorsements} />
        {hasBottomNav && <BottomNav />}
      </Container>
    </>
  );
};
const Container = styled(FlexColumn)`
  max-width: ${MEMBER_PROFILE_MAX_WIDTH}px;
  margin: 0 auto;
  background-color: ${colors.whiteMain};
  border-radius: 5px;
`;

export const TopNavContainer = styled(FlexRow)`
  position: fixed;
  width: calc(${MEMBER_PROFILE_MAX_WIDTH}px + 44px + 44px + ${margins.size3} + ${margins.size3});
  max-width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  ${media.mobile} {
    top: calc(max(env(safe-area-inset-top), ${margins.size3}));
    padding: 0px ${margins.size3};
  }
`;

export const CircularButton = styled(Clickable)`
  background-color: ${colors.whiteMain};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;

  :hover {
    background-color: ${colors.greyLight};
  }
`;

const MobileBottomNavContainer = styled(FlexRow)<{ navbarHeaderHeight: number }>`
  position: fixed;
  bottom: calc(env(safe-area-inset-bottom) + ${p => p.navbarHeaderHeight}px);
  width: calc(100% - ${margins.size3} - ${margins.size3});
  max-width: calc(${MEMBER_PROFILE_MAX_WIDTH}px - ${margins.size3} - ${margins.size3});
  justify-content: space-between;
  margin: 0 auto;
  padding: ${margins.size3} 0px;
  flex-direction: row-reverse;
`;

const BottomNavContainer = styled(FlexRow)`
  width: calc(100% - ${margins.size3} - ${margins.size3});
  max-width: calc(${MEMBER_PROFILE_MAX_WIDTH}px - ${margins.size3} - ${margins.size3});
  justify-content: space-between;
  margin: 0 auto;
  padding: ${margins.size3} 0px;
  flex-direction: row-reverse;
`;

const SeeMoreButton = styled(SVG)`
  & > rect {
    fill: none;
  }

  & > circle {
    fill: ${colors.blackMain};
  }
`;
