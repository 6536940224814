import React from 'react';
import { useHistory } from 'react-router';
import format from 'string-format';

import { FlexColumn, Heading1, Text, margins, ClickableText } from 'css/css';

import { feedbackPage } from 'js/util/strings';

import ghostedSvg from 'img/feedback/ghost.svg';

interface Props {
  header: string;
  body: string;
  ghosted: boolean;
  guidelines: boolean;
  matchFirstName: string;
}

export const TextMessage: React.FC<Props> = ({
  header,
  body,
  ghosted,
  guidelines,
  matchFirstName,
}) => {
  const history = useHistory();
  return (
    <FlexColumn style={{ marginTop: margins.size7 }}>
      <Heading1 style={{ marginBottom: margins.size4 }}>{header}</Heading1>

      <Text>{format(body, matchFirstName)}</Text>
      {guidelines && (
        <ClickableText
          style={{ margin: `${margins.size4} auto` }}
          big
          onClick={() => history.push('/community-guidelines')}
        >
          {feedbackPage.guidelines}
        </ClickableText>
      )}

      {ghosted && <img src={ghostedSvg} alt="Boo, ghosted" />}
    </FlexColumn>
  );
};
