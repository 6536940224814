import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { media, colors } from 'css/css';

import { useTestBuckets, useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { getParameterByName, COMMUNITIES, COMMUNITY_CODE_TO_ID } from 'js/util/util';
import { useMountEffect } from 'js/util/custom-hooks';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { GreyPage } from 'js/components/shared/page-wrappers';
import { useCardInteractions, useFeedContextProvider } from 'js/providers/FeedProvider';
import { getDiscoverBookingSuggestion, optInToGreet, optInToRecruitingPilot } from 'js/util/api';
import { useNotifContext } from 'js/util/notif-context';
import { apiFailure } from 'js/util/strings';

import { FeedDeck } from './FeedDeck';
import { AllCardTypes, DiscoverBookingCard } from './types';
import { REGULAR_CARDS } from './constants';

export const HomePage: React.FC = () => {
  const { completeCard } = useCardInteractions();
  const { feedCards, completedCards, fetchFeedCardsIfNeeded } = useFeedContextProvider();
  const user = useUser();
  const { showNotif } = useNotifContext();
  const { updateUser } = useUserContextProvider();
  const { discoverBookingTest } = useTestBuckets();

  const [openHeader, setOpenHeader] = useState<(continuation?: () => void) => void>();
  const [closeHeader, setCloseHeader] = useState<(continuation?: () => void) => void>();

  const [isDiscoverBookingSession, setIsDiscoverBookingSession] = useState(discoverBookingTest);
  const [discoverBookingSuggestions, setDiscoverBookingSuggestions] = useState<
    DiscoverBookingCard[]
  >([]);
  const [shouldFetchDiscoverBooking, setShouldFetchDiscoverBooking] = useState(discoverBookingTest);
  const [wasDiscoverBookingFetched, setWasDiscoverBookingFetched] = useState(false);
  const [isFirstDiscoverBookingCard, setIsFirstDiscoverBookingCard] = useState(false);

  const fetchDiscoverBookingSuggestion = async () => {
    const res = await getDiscoverBookingSuggestion();
    if (res.ok) {
      if (res.getJson.status === 'FOUND') {
        const { card, is_first_card: isFirstCard } = res.getJson;
        setDiscoverBookingSuggestions(suggestions => [...suggestions, card]);
        setIsFirstDiscoverBookingCard(isFirstCard);
      } else if (res.getJson.status === 'NOT_ENOUGH_CARDS') {
        setIsDiscoverBookingSession(false);
      }
      setWasDiscoverBookingFetched(true);
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
  };

  useMountEffect(() => {
    if (!feedCards.length) fetchFeedCardsIfNeeded();
  });

  useMountEffect(() => {
    const communityParam = getParameterByName('community')?.toLowerCase();
    const orgs = user.organizations;
    if (communityParam) {
      const communityId = +(COMMUNITY_CODE_TO_ID[communityParam] || communityParam);
      if (COMMUNITIES[communityId]) {
        updateUser({
          organizations: [...new Set([...orgs, communityId])],
        });
      }
    }
  });

  useMountEffect(() => {
    const shouldNuxMatchOptIn = getParameterByName('nux_match_opt_in') === 'true';
    const shouldRecruitingOptIn = getParameterByName('recruiting_opt_in') === 'true';
    if (!shouldNuxMatchOptIn && !shouldRecruitingOptIn) return;

    const optIn = async () => {
      const res = shouldNuxMatchOptIn
        ? await optInToGreet(true)
        : await optInToRecruitingPilot(true);
      if (res.status === 200) {
        showNotif({
          message: `Great! Look out for an introduction from us soon!`,
          level: 'success',
        });
      } else {
        showNotif({
          message: `Something went wrong. Please contact support.`,
          level: 'error',
        });
      }
    };

    optIn();
  });

  useEffect(() => {
    if (shouldFetchDiscoverBooking) {
      fetchDiscoverBookingSuggestion();
      setShouldFetchDiscoverBooking(false);
    }
  }, [shouldFetchDiscoverBooking]);

  if (feedCards.length === 0 || (discoverBookingTest && !wasDiscoverBookingFetched)) {
    // to-do: change the condition here
    return (
      <GreyPage>
        <SVGLoader />
      </GreyPage>
    );
  }

  return (
    <StyledGreyPage fixedFullHeight>
      <FeedDeck
        feed={
          feedCards[0].card_type === AllCardTypes.Personality
            ? {
                cards: (feedCards as any).filter(
                  (card: any) => card.card_type === AllCardTypes.Personality,
                ), // future TO-DO: get rid of 'as any', and make it so that it infers the return type PersonalityCard[]
                session: 'Personality',
              }
            : isDiscoverBookingSession
            ? {
                cards: discoverBookingSuggestions,
                session: 'DiscoverBooking',
                fetchSuggestion: fetchDiscoverBookingSuggestion,
                isFirstCard: isFirstDiscoverBookingCard,
              }
            : {
                cards: (feedCards as any).filter((card: any) =>
                  REGULAR_CARDS.includes(card.card_type),
                ), // future TO-DO: get rid of 'as any', and make it so that it infers the return type RegularCard[]
                session: 'Regular',
              }
        }
        completedCardIds={completedCards}
        completeCard={completeCard}
        openHeader={openHeader}
        setOpenHeader={setOpenHeader}
        closeHeader={closeHeader}
        setCloseHeader={setCloseHeader}
      />
    </StyledGreyPage>
  );
};

const StyledGreyPage = styled(GreyPage)`
  ${media.mobile} {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    background-color: ${colors.whiteMain};
  }
`;
