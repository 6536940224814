import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import {
  FlexColumn,
  globalTransitionSettings,
  colors,
  margins,
  media,
  borders,
  FlexRow,
  Text,
  Heading3,
} from 'css/css';

import { Interests, UserInterest, GroupedInterests } from 'types/interests';

interface Props {
  allInterests: GroupedInterests;
  updateInterest: (interest: string) => void;
  userInterests: UserInterest[];
  dropdownOffset: number;
  filteredInterests: GroupedInterests;
}

export const InterestsDropdown: React.FC<Props> = ({
  allInterests,
  updateInterest,
  userInterests,
  dropdownOffset,
  filteredInterests,
}) => {
  const [interestsToDisplay, setInterestsToDisplay] = useState<GroupedInterests>({});

  useEffect(() => {
    const userInterestIds = userInterests.map((interest: UserInterest) => interest.id);
    const filteredAllInterests: { [key: string]: Interests[] } = {};
    Object.entries(allInterests).forEach(([category, interestsList]) => {
      filteredAllInterests[category] = interestsList.filter(
        interest => !userInterestIds.includes(interest.id) && interest.large,
      );
    });
    setInterestsToDisplay(
      Object.values(filteredInterests).length ? filteredInterests : filteredAllInterests,
    );
  }, [userInterests, allInterests, filteredInterests]);

  return (
    <DropdownContainer role="listbox" dropdownPosition={dropdownOffset}>
      {Object.entries(interestsToDisplay).map(([category, interests]) => (
        <>
          <SelectCategory key={category} shouldDisplay={!!interests.length}>
            <Heading3 color={colors.primaryMain}>{category}</Heading3>
          </SelectCategory>
          {interests.map(interest => (
            <SelectOption key={interest.id} onClick={() => updateInterest(interest.name)}>
              <Text>{interest.name}</Text>
            </SelectOption>
          ))}
        </>
      ))}
    </DropdownContainer>
  );
};

const DropdownContainer = styled(FlexColumn)<{ dropdownPosition: number }>`
  position: absolute;
  top: ${p => `${p.dropdownPosition}px`};
  width: 100%;
  background-color: ${colors.whiteMain};
  border-radius: 5px;
  border: ${borders.variation};
  max-height: 150px;
  overflow: scroll;
  ${media.mobile} {
    left: 16px;
    right: 16px;
  }
`;

const SelectOption = styled(FlexRow)`
  width: 100%;
  padding: ${margins.size2} ${margins.size3};
  text-align: left;
  transition: ${globalTransitionSettings};
  &:hover {
    cursor: pointer;
    background-color: ${colors.greyLight};
  }
`;

const SelectCategory = styled(SelectOption)<{ shouldDisplay: boolean }>`
  display: ${p => !p.shouldDisplay && 'none'};
  &:hover {
    cursor: default;
    background-color: transparent;
  }
`;
