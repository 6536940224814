import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { colors, HideMobile, margins, Text } from 'css/css';

import { CommunitySettings } from 'js/components/shared/CommunitySettings';
import { useUser } from 'js/providers/UserProvider';
import { useMountEffect } from 'js/util/custom-hooks';

import { Body, Title } from '../styles';

export const Communities = () => {
  const { organizations: communityIds } = useUser();
  const [hasCommunities, setHasCommunities] = useState(false);

  useMountEffect(() => {
    setHasCommunities(!!communityIds?.length);
  });

  return (
    <>
      <HideMobile>
        <Title>Your communities</Title>
      </HideMobile>
      <Body>
        Lunchclub communities are invite-only communities meant to bring people with similar
        experiences closer together.
      </Body>
      <Body>You can adjust which ones you’d like to be a part of here.</Body>
      {hasCommunities ? (
        <CommunitySettings editing />
      ) : (
        <BackgroundFiller>
          <Text>
            You’re not in any communities yet. Watch out for community invites from your
            connections!
          </Text>
        </BackgroundFiller>
      )}
    </>
  );
};

const BackgroundFiller = styled.div`
  width: 100%;
  padding: ${margins.size4};
  border-radius: 5px;
  background-color: ${colors.greyLight};
`;
