import React from 'react';
import styled from 'styled-components/macro';

import { FlexRow, Heading3, margins, media, Text } from 'css/css';

import editPencilSvg from 'img/settings/edit-pencil.svg';

export const SettingsSection = ({
  onClick,
  isVisible = true,
  headingTitle,
  selectedOptions,
}: {
  onClick: () => void;
  isVisible?: boolean;
  headingTitle: string;
  selectedOptions?: string[] | string;
}) => (
  <SettingsSectionContainer>
    <div style={{ flex: 1 }}>
      <Heading3 style={{ marginBottom: margins.size1 }}>{headingTitle}</Heading3>
      {selectedOptions !== undefined &&
        (typeof selectedOptions === 'string' ? (
          <Text>{selectedOptions}</Text>
        ) : (
          <Text>
            {selectedOptions.map(option => (
              <div key={option}>{option}</div>
            ))}
          </Text>
        ))}
    </div>
    <PencilContainer $isVisible={isVisible} role="button" tabIndex={-1} onClick={onClick}>
      {isVisible && <img style={{ paddingLeft: margins.size4 }} src={editPencilSvg} alt="edit" />}
    </PencilContainer>
  </SettingsSectionContainer>
);

const SettingsSectionContainer = styled(FlexRow)`
  width: 100%;
  align-items: flex-start;
  margin-bottom: ${margins.size3};
  margin-top: ${margins.size1};

  ${media.desktop} {
    padding: 0 ${margins.size3};
  }
`;

const PencilContainer = styled.div<{ $isVisible: boolean }>`
  width: 40px;
  height: 40px;
  cursor: ${p => (p.$isVisible ? 'pointer' : 'default')};
`;
