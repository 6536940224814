import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';

import { colors, FlexRow } from 'css/css';

interface Props {
  sections: number;
  activeIndex: number;
}

export default function Indicator({ sections, activeIndex }: Props): ReactElement {
  return (
    <Container>
      {Array.from({ length: sections }).map((_, index) => (
        <Dot active={index === activeIndex} />
      ))}
    </Container>
  );
}

const Container = styled(FlexRow)`
  padding: 4px;
`;

const Dot = styled.div<{ active: boolean }>`
  background-color: ${colors.whiteMain};
  opacity: ${div => (div.active ? 1.0 : 0.2)};
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: 0px 2px;
`;
