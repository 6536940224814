import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import { colors, margins, Text, Clickable } from 'css/css';
import { underlineAnimation, underlineHover } from 'css/animations';

import ShuffleIcon from 'img/invite/shuffle-icon.svg';

export const Shuffle = ({ onClick, loading }: any) => {
  return (
    <StyledShuffleContainer onClick={() => !loading && onClick()} noOutline>
      <ShuffleSvg spinning={loading} src={ShuffleIcon} alt="Shuffle Icon" />

      <AnimatedText bold color={colors.primaryMain}>
        Shuffle
      </AnimatedText>
    </StyledShuffleContainer>
  );
};

const shufflingKeyframe = keyframes`
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

const ShuffleSvg = styled(SVG)`
  margin-right: ${margins.size2};
  animation: ${(props: any) => props.spinning && shufflingKeyframe} 2s infinite linear;
`;

const StyledShuffleContainer = styled(Clickable)`
  color: ${colors.primaryMain};
  flex-direction: row;
  display: flex;
  align-items: baseline;

  &:hover {
    & > p {
      ${underlineHover}
    }
  }
`;

const AnimatedText = styled(Text)`
  ${underlineAnimation}
`;
