import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';

import { globalTransitionSettings } from 'css/css';

import smallArrow from 'img/shared/small-arrow.svg';

interface Props {
  color: string;
  direction: 'up' | 'down' | 'right' | 'left';
  style?: Record<string, any>;
  className?: string;
}

export const SmallArrow: React.FC<Props> = ({ color, direction, style, className }) => {
  return (
    <Arrow
      src={smallArrow}
      alt="Arrow"
      color={color}
      $direction={direction}
      style={style}
      className={className}
    />
  );
};

export const Arrow = styled(SVG)<{ $direction: string }>`
  transition: ${globalTransitionSettings};
  transform: rotate(
    ${props =>
      props.$direction === 'left'
        ? '90deg'
        : props.$direction === 'up'
        ? '180deg'
        : props.$direction === 'right'
        ? '-90deg'
        : '0deg'}
  );
`;
