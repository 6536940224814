import React from 'react';

import { margins } from 'css/css';

import { objectiveIcons } from 'js/util/util';
import { Preference } from 'js/components/shared/Preference';

import Goal from 'img/weekly/goal.svg';

import { Objective } from '../types';

import { PreferenceHeading } from './styles';

interface Props {
  rawObjectives: Objective[];
  userObjectives: Objective[];
  selectedObjective?: number;
  setSelectedObjective: (objective?: number) => void;
}

export const ObjectiveQuestion: React.FC<Props> = ({
  rawObjectives,
  userObjectives,
  selectedObjective,
  setSelectedObjective,
}) => {
  if (!rawObjectives || !userObjectives) {
    return null;
  }
  const filteredObjectives = rawObjectives.filter(
    objective => !userObjectives.map(obj => obj.id).includes(objective.id),
  );
  return (
    <>
      <PreferenceHeading style={{ marginTop: `-${margins.size4}` }} paddingTop="0" role="heading">
        Your main goal
      </PreferenceHeading>
      {userObjectives.map(objective => (
        <Preference
          key={objective.id}
          questionText={objective.name}
          imageSrc={objectiveIcons.find(icon => icon.id === objective.id)?.imgSrc}
          imageAlt={objective.name}
          isSelected={selectedObjective === objective.id}
          selectOption={setSelectedObjective}
          id={objective.id}
          marginBottom
        />
      ))}
      <Preference
        imageSrc={Goal}
        imageAlt="Specific objective"
        questionText="Add new objective"
        selectedOption={selectedObjective}
        selectOption={setSelectedObjective}
        options={filteredObjectives}
        isSelected={filteredObjectives.map(objective => objective.id).includes(selectedObjective)}
      />
    </>
  );
};
