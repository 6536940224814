import React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { useUser } from 'js/providers/UserProvider';
import { getIsMobile, lunchclubTypes } from 'js/util/util';

import { SVGLoader } from './loaders/SVGLoader';
import { Login } from './Login';
import { Banned } from './Banned';

const PAGES = {
  home: '/home',
  registration: '/registration',
};

export const Authenticate: React.ElementType = ({ children }) => {
  const history = useHistory();
  const location = useLocation<{ last: string }>();

  const user = useUser();

  if (user.email) {
    if (user.is_banned) return <Banned />;

    switch (user.user_type) {
      case lunchclubTypes.verified:
        if (location.state) {
          history.push(location.state.last || PAGES.home);
          return <SVGLoader />;
        }
        return children;

      case lunchclubTypes.unverified:
        break;
      case lunchclubTypes.waitlisted:
        history.push(PAGES.registration);
        break;

      default:
        break;
    }
  }

  if (getIsMobile() && window.bridge) {
    return <Redirect to="/mobile-root?session-invalid=true" />;
  }

  return <Login />;
};
