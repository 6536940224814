import React from 'react';

import { margins, MainHeading, Text } from 'css/css';

import { authenticated as copy } from 'js/util/strings';

import { BasicPage } from './page-wrappers';

export const Banned = () => (
  <BasicPage showHeader={false}>
    <MainHeading style={{ margin: `${margins.size7} 0` }}>Account deactivated</MainHeading>
    <Text>{copy.banned}</Text>
  </BasicPage>
);
