import React from 'react';
import styled from 'styled-components/macro';

import { colors, FlexRow, Link, margins, media, Text } from 'css/css';

import { logoIcon, instagram, twitter, linkedin } from 'img/landing-page';

import { Subheading } from '../style';

export const Footer = () => (
  <Container>
    <a
      href="//jobs.lever.co/lunchclub"
      rel="noreferrer"
      target="_blank"
      style={{ textDecoration: 'none' }}
    >
      <CareerContainer>
        <Text color={colors.primaryMain}>Careers</Text>
        <InnerContainer>
          <Subheading style={{ color: colors.whiteMain, opacity: 1, fontSize: 8 }}>
            WE&apos;RE HIRING
          </Subheading>
        </InnerContainer>
      </CareerContainer>
    </a>
    <FlexRow>
      <Icon url="//www.instagram.com/lunchclubai/" src={instagram} alt="Instagram" />
      <Icon url="//twitter.com/lunchclubai" src={twitter} alt="Twitter" />
      <Icon url="//www.linkedin.com/company/lunchclub" src={linkedin} alt="LinkedIn" />
    </FlexRow>
    <FlexRow>
      <Link style={{ color: colors.primaryMain }} to="/privacy">
        Privacy
      </Link>
      <LogoIcon src={logoIcon} alt="" />
    </FlexRow>
  </Container>
);

const Icon = ({ url, src, alt }: { url: string; src: string; alt: string }) => (
  <a href={url} target="_blank" rel="noreferrer">
    <img style={{ marginRight: margins.size2 }} src={src} alt={alt} />
  </a>
);

const Container = styled(FlexRow)`
  justify-content: space-between;
  padding: 0px ${margins.size5};
  ${media.mobile} {
    padding: ${margins.size3};
  }
`;

const CareerContainer = styled(FlexRow)`
  height: 40px;
  background-color: ${colors.greyLight};
  padding: 0px ${margins.size2};
  border-radius: 5px;
`;

const InnerContainer = styled(FlexRow)`
  height: 20px;
  background-color: ${colors.primaryMain};
  border-radius: 3px;
  padding: 0px ${margins.size2};
  margin-left: ${margins.size2};
`;

const LogoIcon = styled.img`
  height: 45px;
  margin: ${margins.size4};
  border-radius: 5px;

  ${media.mobile} {
    display: none;
  }
`;
