import React, { useState } from 'react';

import { FeedbackModal } from './FeedbackModal';
import { SettingsModal } from './SettingsModal';
import { Call } from './Call';
import { CallContextProvider } from './CallContext';
import { CallPermissionsContextProvider } from './CallPermissionsContext';

export const CallV1Page: React.FC = () => {
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);

  return (
    <CallContextProvider>
      <FeedbackModal
        isVisible={isFeedbackModalVisible}
        handleClose={() => setIsFeedbackModalVisible(false)}
      />
      <CallPermissionsContextProvider>
        <SettingsModal
          isVisible={isSettingsModalVisible}
          handleClose={() => setIsSettingsModalVisible(false)}
        />
        <Call
          showSettings={() => setIsSettingsModalVisible(true)}
          showFeedback={() => setIsFeedbackModalVisible(true)}
        />
      </CallPermissionsContextProvider>
    </CallContextProvider>
  );
};
