import React from 'react';
import styled from 'styled-components/macro';

import { Heading1, Heading3, margins, media, fonts } from 'css/css';

interface HeaderProps {
  title: string;
  description: string;
  noTopMargin?: boolean;
}

export const OnboardingHeader = ({ title, description, noTopMargin }: HeaderProps) => (
  <HeaderWrapper noTopMargin={noTopMargin}>
    <Heading1>{title}</Heading1>

    <RegularHeading3>{description}</RegularHeading3>
  </HeaderWrapper>
);

const HeaderWrapper = styled.div<{ noTopMargin?: boolean }>`
  margin-top: ${p => (p.noTopMargin ? 0 : margins.size6)};
  margin-bottom: ${margins.size4};
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  ${media.mobile} {
    margin-top: ${p => (p.noTopMargin ? 0 : margins.size4)};
    margin-bottom: ${margins.size4};
  }
`;

const RegularHeading3 = styled(Heading3)`
  font-family: ${fonts.regular};
`;
