import React from 'react';

import Hat from 'img/weekly/hat.svg';

import { PICK_OPTIONS } from '../pickers/options';
import { Preference } from '../../shared/Preference';

import { PreferenceHeading } from './styles';

interface Props {
  setSelectedPickMatch: (pickMatch: number) => void;
  selectedPickMatch: number;
  selectedNumberMeetings: number;
}

export const PickMeetingQuestion: React.FC<Props> = ({
  setSelectedPickMatch,
  selectedPickMatch,
  selectedNumberMeetings,
}) => {
  const lunchclubPick = PICK_OPTIONS[0];
  const paidOptions = PICK_OPTIONS.filter(option => ![lunchclubPick].includes(option));
  const cost = PICK_OPTIONS[selectedPickMatch]?.cost;
  const selectedMatch: string | undefined = PICK_OPTIONS.find(
    option => option.id === selectedPickMatch,
  )?.name;
  const getArticle = (s: string) => ('aeiou'.split('').includes(s[0].toLowerCase()) ? 'an' : 'a');
  return (
    <>
      <PreferenceHeading role="heading">Preferences</PreferenceHeading>
      <Preference
        imageSrc={Hat}
        imageAlt="Specific type of match"
        questionText="Pick your match"
        selectedOption={selectedPickMatch}
        selectOption={setSelectedPickMatch}
        options={paidOptions}
        isSelected={lunchclubPick.id !== selectedPickMatch && selectedPickMatch !== null}
        cost={cost}
        customCopy={selectedMatch && `Meet ${getArticle(selectedMatch)} `}
        selectedNumberMeetings={selectedNumberMeetings}
      />
    </>
  );
};
