import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { ProductUser } from 'types/user';

import { getCallInfo } from 'js/util/api';
import { useNotifContext } from 'js/util/notif-context';

import { callUrl } from '../callv2/utils/callUrl';

interface ContextValue {
  match?: ProductUser;
  matchCode: string;
  feedbackCode?: string;
  startTime?: string;
  hasContext: boolean;
  url?: string;
  isV2?: boolean;
}

const SharedCallContext = createContext<ContextValue>({
  matchCode: '',
  hasContext: false,
});

export const SharedCallContextProvider: React.FC = ({ children }) => {
  const routeMatch = useRouteMatch<{ id: string }>();

  const [match, setMatch] = useState<ProductUser>();
  const [url, setUrl] = useState<string>();
  const [feedbackCode, setFeedbackCode] = useState<string>();
  const [startTime, setStartTime] = useState<string>();
  const [isV2, setIsV2] = useState<boolean | undefined>();
  const history = useHistory();
  const { showNotif } = useNotifContext();

  const showError = (message: string) => {
    showNotif({ message, level: 'error' });
    history.push('/home');
  };

  const handleCall = async () => {
    if (routeMatch) {
      const res = await getCallInfo(routeMatch.params.id);
      if (res.ok) {
        const {
          match: currentMatch,
          room_name: callId,
          feedback_code,
          time_slot,
          is_v2,
        } = res.getJson;
        setMatch(currentMatch);
        setUrl(callUrl(callId));
        setFeedbackCode(feedback_code);
        setStartTime(time_slot);
        setIsV2(is_v2);
      } else if (res.isClientError) {
        const error = (() => {
          switch (res.getJson.error) {
            case 'Invalid match id':
              return 'Invalid match id. Please try pasting the link from your email to join.';
            case 'Room has expired':
              return 'The room has expired.';
            case 'Not allowed to create room for given match id':
              return "You don't have permissions to join this room. Are you sure you're logged into the right account?";
            default:
              return 'There was an error connecting to the room. Please try pasting the link from your email to join.';
          }
        })();
        showError(error);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await handleCall();
    })();
  }, []);

  return (
    <SharedCallContext.Provider
      value={{
        match,
        matchCode: routeMatch ? routeMatch.params.id : '',
        feedbackCode,
        startTime,
        url,
        isV2,
        hasContext: true,
      }}
    >
      {children}
    </SharedCallContext.Provider>
  );
};

export const useSharedCallContext = () => useContext(SharedCallContext);
