import { useEffect, useState } from 'react';

import { postSpeakerData } from 'js/util/api';
import { useCallV2Context } from 'js/components/callv2/contexts/CallContext';
import { useParticipantsContext } from 'js/components/callv2/contexts/ParticipantsContext';

interface SpeakerData {
  localDuration: number;
  remoteDuration: number;
  totalDuration: number;
  epoch?: number;
  isLocalActive?: boolean;
}

const SPEAKER_UPDATE_PERIOD = 120000;

export const useActiveSpeakerTracker = () => {
  const { matchCode } = useCallV2Context();
  const { localParticipant, matchParticipant, isLocalSpeaking } = useParticipantsContext();

  const [speakerData, setSpeakerData] = useState<SpeakerData>({
    localDuration: 0,
    remoteDuration: 0,
    totalDuration: 0,
  });
  const [shouldPostData, setShouldPostData] = useState<boolean>(false);

  const getUpdatedSpeakerData = () => {
    const newEpoch = Date.now();
    const { localDuration, remoteDuration, totalDuration, epoch, isLocalActive } = speakerData;
    const newIsLocalActive = !localParticipant || !matchParticipant ? undefined : isLocalSpeaking;
    return {
      localDuration: localDuration + (isLocalActive === true && epoch ? newEpoch - epoch : 0),
      remoteDuration: remoteDuration + (isLocalActive === false && epoch ? newEpoch - epoch : 0),
      totalDuration: totalDuration + (epoch ? newEpoch - epoch : 0),
      epoch: newEpoch,
      isLocalActive: newIsLocalActive,
    };
  };

  const postActiveSpekerData = async () => {
    const newSpeakerData = getUpdatedSpeakerData();
    const { localDuration, remoteDuration, totalDuration, epoch, isLocalActive } = newSpeakerData;
    await postSpeakerData({
      matchCode,
      localDuration,
      remoteDuration,
      totalDuration,
    });
    setSpeakerData({
      localDuration: 0,
      remoteDuration: 0,
      totalDuration: 0,
      isLocalActive,
      epoch,
    });
  };

  useEffect(() => {
    setSpeakerData(getUpdatedSpeakerData());
  }, [isLocalSpeaking, localParticipant, matchParticipant]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShouldPostData(true);
    }, SPEAKER_UPDATE_PERIOD);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (shouldPostData) {
      postActiveSpekerData();
      setShouldPostData(false);
    }
  }, [shouldPostData]);
};
