import React, { CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { Transition, TransitionStatus } from 'react-transition-group';

import { colors, FlexColumn, Heading2, margins, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { validateEmail } from 'js/util/util';
import { useMountEffect } from 'js/util/custom-hooks';

import bgDetailSVG from 'img/mobile-root/landing-page/bg-detail.svg';
import wordmarkSVG from 'img/mobile-root/landing-page/hero-wordmark.svg';

import { GrayEmailInput, MobileRootContainer, TRANSITION_DURATION, UserState } from '.';

const defaultButtonStyle = {
  marginTop: margins.size2,
  transition: `opacity 300ms ease-in-out`,
  opacity: 0,
};

const buttonTransitionStyles: Record<TransitionStatus, CSSProperties> = {
  unmounted: {},
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

interface EnterEmailProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  userState: UserState;
  setUserState: React.Dispatch<React.SetStateAction<UserState>>;
  isUserLoggingIn: boolean;
  onSubmit: () => void;
  transitionStyles: CSSProperties;
}

export const EnterEmail: React.FC<EnterEmailProps> = ({
  email,
  setEmail,
  userState,
  setUserState,
  isUserLoggingIn,
  onSubmit,
  transitionStyles,
}) => {
  useMountEffect(() => {
    if (window.bridge) {
      window.bridge.setShrinkViewportForKeyboard(true);
    }
    return () => {
      if (window.bridge) {
        window.bridge.setShrinkViewportForKeyboard(false);
      }
    };
  });

  const headingTitle = isUserLoggingIn ? 'Sign in with email' : 'Create an account';
  const keyHint = isUserLoggingIn ? 'send' : 'go';
  const buttonText = isUserLoggingIn ? 'Login' : 'Create account';
  const Toggle = () => (
    <Text color={colors.whiteMain}>
      {isUserLoggingIn ? 'Don’t have an account? ' : 'Already have an account? '}
      <ToggleText onClick={() => setUserState(isUserLoggingIn ? 'register-email' : 'login-email')}>
        {isUserLoggingIn ? 'Create account' : 'Login instead'}
      </ToggleText>
    </Text>
  );

  return (
    <MobileRootContainer style={transitionStyles} color={colors.blackMain}>
      <BackgroundArt src={bgDetailSVG} alt="" />
      <Container>
        <Logo src={wordmarkSVG} alt="Lunchclub logo" />
        <Heading2 color={colors.whiteMain}>{headingTitle}</Heading2>
        <GrayEmailInput
          enterKeyHint={keyHint}
          value={email}
          onChange={setEmail}
          onEnter={onSubmit}
          placeholder="enter your email"
          style={{ boxSizing: 'border-box', margin: `${margins.size3} 0px` }}
          maxWidthMobile="1000px"
          autoComplete="email"
        />
        <Toggle />
        <Transition in={!!email.length} timeout={TRANSITION_DURATION}>
          {state => (
            <Button
              onClick={onSubmit}
              invalid={!validateEmail(email)}
              loading={userState === 'email-loading'}
              style={{
                ...defaultButtonStyle,
                ...buttonTransitionStyles[state],
              }}
              large
            >
              {buttonText}
            </Button>
          )}
        </Transition>
      </Container>
    </MobileRootContainer>
  );
};

const Container = styled(FlexColumn)`
  height: 100vh;
  padding: ${margins.size3};
  justify-content: center;
  z-index: 1;
`;

const Logo = styled.img`
  width: 64px;
  height: 64px;
  margin-bottom: ${margins.size3};
`;

const BackgroundArt = styled.img`
  position: fixed;
  width: 100vw;
  top: 25px;
`;

const ToggleText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
