import React from 'react';

import { UserProvider } from './UserProvider';
import { FeedProvider } from './FeedProvider';
import { ChatContextProvider } from './ChatContextProvider';
import { DataContextProvider } from './DataContextProvider';

export const RootProvider: React.FC = ({ children }) => (
  <UserProvider>
    <FeedProvider>
      <ChatContextProvider>
        <DataContextProvider>{children}</DataContextProvider>
      </ChatContextProvider>
    </FeedProvider>
  </UserProvider>
);
