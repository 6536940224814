// import { motion } from 'framer-motion';
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import { colors, margins } from 'css/css';

import microphoneOff from 'img/call/microphone-off.svg';

interface Props {
  muted: boolean;
}

export const TileInfo = memo(({ muted }: Props) => {
  return (
    <div>
      {muted && (
        <MuteContainer>
          <MuteIcon />
        </MuteContainer>
      )}
    </div>
  );
});

const MuteContainer = styled.div`
  position: absolute;
  left: ${margins.size2};
  bottom: ${margins.size2};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${colors.tertiary2Main};
`;

const MuteIcon = styled(SVG).attrs(() => ({ src: microphoneOff }))`
  width: 16px;
  color: ${colors.whiteMain};
`;
