import moment from 'moment-timezone';

import { availability } from 'types/availability';
import { LunchclubMatch } from 'types/matches';

import { getMatchFeedback, getAvailability, getUserInfo } from 'js/util/api';

export default async (passedAvailability?: availability): Promise<boolean> => {
  if (passedAvailability) {
    const {
      time_slots: selectedTimes,
      passed,
      slant_opt_in: slantOptIn,
      availability_autopilot,
    } = passedAvailability;
    const hasAutopilot = Boolean(availability_autopilot?.autopilot);

    if (selectedTimes?.length > 0 || passed || slantOptIn || hasAutopilot) return false;
    return true;
  }
  let failedResp = false;
  let hasSignedUp = false;
  let hasUpcomingMatches = false;

  let futureMatches: LunchclubMatch[] = [];

  const [userInfoRes, matchFeedbackRes, availRes] = await Promise.all([
    getUserInfo(),
    getMatchFeedback(),
    getAvailability(),
  ]);

  if (userInfoRes.ok && matchFeedbackRes.ok && availRes.ok) {
    const {
      time_slots: selectedTimes,
      passed,
      slant_opt_in: slantOptIn,
      availability_autopilot,
    } = availRes.getJson;
    const hasAutopilot = !!availability_autopilot?.autopilot;

    if (selectedTimes?.length > 0 || passed || slantOptIn || hasAutopilot) {
      hasSignedUp = true;
    }
    futureMatches = matchFeedbackRes.getJson.filter(
      match =>
        moment
          .tz(match.time_slot_with_exact_time, userInfoRes.getJson.locale_info.timezone)
          .add(2, 'hours') >= moment.tz(userInfoRes.getJson.locale_info.timezone),
    );
    hasUpcomingMatches = !!futureMatches.length;
  } else {
    failedResp = true;
  }

  return !(failedResp || hasSignedUp || hasUpcomingMatches);
};
