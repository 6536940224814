import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { apiFetch } from 'js/util/api';
import { getIsMobile } from 'js/util/util';

const UPDATE_CHECK_INTERVAL = 10 * 60 * 1000;
const FORCE_RELOAD_AFTER = 60 * 60 * 1000;

const NOT_EXPECTED_ON_PAGE = ['runtime~main', 'service-worker', 'precache', 'hot-update'];

interface Manifest {
  files: { [key: string]: string };
}

export const BrowserRouterWithReloader: React.FC = ({ children }) => {
  const [newVersion, setNewVersion] = React.useState(false);
  const reloadTimer = React.useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    let lastWallTime = Date.now();

    const checkVersion = async () => {
      let manifest: Manifest;
      try {
        manifest = (await apiFetch<Manifest>('GET', `/asset-manifest.json`, {}, null)).getJson;
      } catch (err) {
        return;
      }
      if (!manifest || !manifest.files) {
        // eslint-disable-next-line no-console
        console.warn('Could not check for React app updates.');
        return;
      }
      const scripts = Array.from(document.scripts).map(o => o.getAttribute('src'));
      const changed = Object.keys(manifest.files).filter(
        key =>
          key.endsWith('.js') &&
          !scripts.includes(manifest.files[key]) &&
          !NOT_EXPECTED_ON_PAGE.some(term => manifest.files[key].includes(term)),
      );

      if (changed.length) {
        // eslint-disable-next-line no-console
        console.warn(
          `An update is available, asset-manifest.json specifies scripts not on this page.`,
          scripts,
          changed,
        );

        // Mark that an update is available and quietly make `<Link>` tags reload the page
        setNewVersion(true);

        // On mobile, force a page reload when:
        // - It's been more than an hour and they haven't clicked a link
        // - It was more than an hour between checkVersion calls (laptop just woke from sleep / network loss)
        if (getIsMobile()) {
          if (Date.now() - lastWallTime > FORCE_RELOAD_AFTER) {
            window.location.reload();
          } else {
            reloadTimer.current = setTimeout(() => window.location.reload(), FORCE_RELOAD_AFTER);
          }
        }
      }

      lastWallTime = Date.now();
    };

    const checkInterval = setInterval(checkVersion, UPDATE_CHECK_INTERVAL);
    return () => {
      clearInterval(checkInterval);
      if (reloadTimer.current) {
        clearTimeout(reloadTimer.current);
      }
    };
  }, []);

  return <BrowserRouter forceRefresh={newVersion}>{children}</BrowserRouter>;
};
