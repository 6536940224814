import { createRef, useState, useMemo, useEffect } from 'react';

const MIN_FONT_SIZE = 11;
const MAX_FONT_SIZE = 20;

export function useFontSizeFillingContainer(
  text: string,
  max_size = MAX_FONT_SIZE,
  fixedTargetHeight: number | undefined = undefined,
  lineHeight: number | undefined = undefined,
  min_size = MIN_FONT_SIZE,
) {
  const textboxRef = createRef<HTMLDivElement>();
  const [fontSize, setFontSize] = useState(0);
  const [remainingSpace, setRemainingSpace] = useState<number>();

  const sizingDiv = useMemo(() => document.createElement('div'), []);
  useEffect(() => {
    sizingDiv.style.position = 'absolute';
    sizingDiv.style.left = '-1000px';
    sizingDiv.style.top = '0px';
    document.body.appendChild(sizingDiv);
    return () => sizingDiv.remove();
  }, []);

  const computeIdealSize = () => {
    if (!textboxRef.current || !sizingDiv) {
      return;
    }

    const div = sizingDiv;
    const computed = getComputedStyle(textboxRef.current);
    div.style.padding = computed.padding;
    div.style.whiteSpace = computed.whiteSpace;
    div.style.textAlign = computed.textAlign;
    div.style.fontFamily = computed.fontFamily;
    div.style.fontFeatureSettings = computed.fontFeatureSettings;
    div.style.fontWeight = computed.fontWeight;
    div.style.overflowWrap = computed.overflowWrap;
    div.style.letterSpacing = computed.letterSpacing;

    div.style.width = `${textboxRef.current.clientWidth}px`;
    div.textContent = textboxRef.current.textContent;
    if (lineHeight !== undefined) {
      div.style.lineHeight = `${lineHeight}`;
    }

    const targetHeightPx =
      fixedTargetHeight !== undefined ? fixedTargetHeight : textboxRef.current.clientHeight - 5;
    const doesItFit = (sz: number) => {
      div.style.fontSize = `${sz}px`;
      return div.clientHeight <= targetHeightPx;
    };

    let size = min_size;
    div.style.fontSize = `${size}px`;
    setRemainingSpace(targetHeightPx - div.clientHeight);
    while (size + 0.25 <= max_size && doesItFit(size + 0.25)) {
      size += 0.25;
      setRemainingSpace(targetHeightPx - div.clientHeight);
    }

    setFontSize(size);
  };

  useEffect(() => {
    let observer: any;
    const el = textboxRef.current;

    if ('ResizeObserver' in window && el) {
      let elWidth = el.clientWidth;
      let elHeight = el.clientHeight;
      observer = new (window as any).ResizeObserver(() => {
        if (fixedTargetHeight) {
          return;
        }
        if (el.clientWidth === elWidth && el.clientHeight === elHeight) {
          return;
        }
        elWidth = el.clientWidth;
        elHeight = el.clientHeight;
        computeIdealSize();
      });
      observer.observe(el);
    }
    return () => {
      if (el && observer) {
        observer.unobserve(el);
      }
    };
  }, [textboxRef]);

  useEffect(computeIdealSize, [textboxRef, text]);

  return { textboxRef, fontSize, remainingSpace };
}
