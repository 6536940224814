import React from 'react';
import styled from 'styled-components/macro';

import { Clickable, colors, FlexRow, Heading1, HideMobile, margins, media, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { OldModal } from 'js/components/shared/OldModal';
import { ObjectiveDetails } from 'js/components/shared/objectives/ObjectiveDetails';
import { SmallArrow } from 'js/components/shared/SmallArrow';

import { ModalContainer } from '../styles';

export const GoalDetailModal = ({
  isOpen,
  closeModals,
  selectedGoal,
  userGoalDetails,
  selectedGoalDetails,
  setSelectedGoalDetails,
  clickBackToGoals,
  deleteGoal,
  saveGoal,
  userGoals,
}: any) => {
  if (!selectedGoal) return null;
  return (
    <OldModal
      isOpen={isOpen}
      modalTitle="All goal details"
      onClose={closeModals}
      width="903px"
      options={
        userGoals.length > 1
          ? [
              { selected: false, label: 'Delete goal', onClick: () => deleteGoal(false) },
              { selected: false, label: 'Change goal', onClick: clickBackToGoals },
            ]
          : [{ selected: false, label: 'Change goal', onClick: clickBackToGoals }]
      }
    >
      <HideMobile style={{ height: 24 }}>
        <ChangeObjectiveContainer>
          <Clickable onClick={clickBackToGoals}>
            <FlexRow>
              <SmallArrow color={colors.primaryMain} direction="left" />

              <Text color={colors.primaryMain}>Change goal</Text>
            </FlexRow>
          </Clickable>
        </ChangeObjectiveContainer>
      </HideMobile>

      <ModalContainer>
        <Heading1 style={{ marginBottom: margins.size3, color: colors.blackMain }}>
          {selectedGoal.name}
        </Heading1>

        <ObjectiveDetails
          profile
          objectives={[selectedGoal]}
          selectedObjectiveDetails={
            selectedGoalDetails.length ? selectedGoalDetails : userGoalDetails
          }
          setSelectedObjectiveDetails={(details: any) => setSelectedGoalDetails(details)}
        />

        <SaveButton maxWidth="350px" onClick={saveGoal}>
          Save
        </SaveButton>
      </ModalContainer>
      {userGoals.length > 1 && (
        <HideMobile>
          <DeleteObjectiveContainer>
            <Clickable onClick={() => deleteGoal(false)}>
              <Text color={colors.tertiary2Main}>Delete goal</Text>
            </Clickable>
          </DeleteObjectiveContainer>
        </HideMobile>
      )}
    </OldModal>
  );
};

const ChangeObjectiveContainer = styled.div`
  margin-left: ${margins.size4};
  text-align: left;
`;

const DeleteObjectiveContainer = styled.div`
  margin: 0px ${margins.size4} ${margins.size4} ${margins.size4};
  display: flex;
  flex-direction: row-reverse;
`;

const SaveButton = styled(Button)`
  margin-top: ${margins.size6};
  ${media.mobile} {
    margin-bottom: ${margins.size6};
  }
`;
