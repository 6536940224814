import React from 'react';
import styled from 'styled-components/macro';

import { Card, Heading1, Heading2, Text, margins, colors } from 'css/css';

import { GreyPage } from 'js/components/shared/page-wrappers';

export const TermsOfService = () => (
  <GreyPage>
    <Card maxWidth="900px" padding={margins.size6}>
      <Heading1 style={{ marginBottom: margins.size4 }}>Terms of Service</Heading1>

      <div style={{ textAlign: 'left', maxWidth: '600px', margin: 'auto' }}>
        <SubHeading>Terms</SubHeading>

        <Text>
          By accessing the website at{' '}
          <a style={{ color: colors.primaryMain }} href="https://lunchclub.com">
            https://lunchclub.com
          </a>
          , you are agreeing to be bound by these terms of service, all applicable laws and
          regulations, and agree that you are responsible for compliance with any applicable local
          laws. If you do not agree with any of these terms, you are prohibited from using or
          accessing this site. The materials contained in this website are protected by applicable
          copyright and trademark law.
        </Text>

        <SubHeading>Use License</SubHeading>

        <Text>
          <ul>
            <li>
              Permission is granted to temporarily download one copy of the materials (information
              or software) on Elliot Technologies, Inc. dba Lunchclub&apos;s website for personal,
              non-commercial transitory viewing only. This is the grant of a license, not a transfer
              of title, and under this license you may not:
            </li>

            <ul>
              <li>modify or copy the materials;</li>

              <li>
                use the materials for any commercial purpose, or for any public display (commercial
                or non-commercial);
              </li>

              <li>
                attempt to decompile or reverse engineer any software contained on Elliot
                Technologies, Inc.&apos;s website;
              </li>

              <li>remove any copyright or other proprietary notations from the materials; or</li>

              <li>
                transfer the materials to another person or &quot;mirror&quot; the materials on any
                other server.
              </li>
            </ul>

            <li>
              This license shall automatically terminate if you violate any of these restrictions
              and may be terminated by Elliot Technologies, Inc. dba Lunchclub at any time. Upon
              terminating your viewing of these materials or upon the termination of this license,
              you must destroy any downloaded materials in your possession whether in electronic or
              printed format.
            </li>
          </ul>
        </Text>

        <SubHeading>Disclaimer</SubHeading>

        <Text>
          <ul>
            <li>
              The materials on Elliot Technologies, Inc. dba Lunchclub&apos;s website are provided
              on an &apos;as is&apos; basis. Elliot Technologies, Inc. dba Lunchclub makes no
              warranties, expressed or implied, and hereby disclaims and negates all other
              warranties including, without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose, or non-infringement of intellectual
              property or other violation of rights.
            </li>

            <li>
              Further, Elliot Technologies, Inc. dba Lunchclub does not warrant or make any
              representations concerning the accuracy, likely results, or reliability of the use of
              the materials on its website or otherwise relating to such materials or on any sites
              linked to this site.
            </li>
          </ul>
        </Text>

        <SubHeading>Limitations</SubHeading>

        <Text>
          In no event shall Elliot Technologies, Inc. dba Lunchclub or its suppliers be liable for
          any damages (including, without limitation, damages for loss of data or profit, or due to
          business interruption) arising out of the use or inability to use the materials on Elliot
          Technologies, Inc.&apos;s website, even if Elliot Technologies, Inc. dba Lunchclub or a
          Elliot Technologies, Inc. authorized representative has been notified orally or in writing
          of the possibility of such damage. Because some jurisdictions do not allow limitations on
          implied warranties, or limitations of liability for consequential or incidental damages,
          these limitations may not apply to you.
        </Text>

        <SubHeading>Accuracy of materials</SubHeading>

        <Text>
          The materials appearing on Elliot Technologies, Inc. dba Lunchclub&apos;s website could
          include technical, typographical, or photographic errors. Elliot Technologies, Inc. dba
          Lunchclub does not warrant that any of the materials on its website are accurate, complete
          or current. Elliot Technologies, Inc. may make changes to the materials contained on its
          website at any time without notice. However Elliot Technologies, Inc. dba Lunchclub does
          not make any commitment to update the materials.
        </Text>

        <SubHeading>Links</SubHeading>

        <Text>
          Elliot Technologies, Inc. dba Lunchclub has not reviewed all of the sites linked to its
          website and is not responsible for the contents of any such linked site. The inclusion of
          any link does not imply endorsement by Elliot Technologies, Inc. dba Lunchclub of the
          site. Use of any such linked website is at the user&apos;s own risk.
        </Text>

        <SubHeading>Modifications</SubHeading>

        <Text>
          Elliot Technologies, Inc. dba Lunchclub may revise these terms of service for its website
          at any time without notice. By using this website you are agreeing to be bound by the then
          current version of these terms of service.
        </Text>

        <SubHeading>Governing Law</SubHeading>

        <Text>
          These terms and conditions are governed by and construed in accordance with the laws of
          the state of California and you irrevocably submit to the exclusive jurisdiction of the
          courts in that State or location.
        </Text>
      </div>
    </Card>
  </GreyPage>
);

const SubHeading = styled(Heading2)`
  color: ${colors.primaryMain};
  margin-bottom: ${margins.size4};
  margin-top: ${margins.size5};
`;
