import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';
import { DailyParticipant } from '@daily-co/daily-js';

import {
  Heading1,
  margins,
  colors,
  media,
  globalTransitionSettings,
  borders,
  fontSizes,
} from 'css/css';

import { useMove } from 'js/util/custom-hooks';
import { getIsIOSApp, getIsMobile } from 'js/util/util';
import { Avatar } from 'js/components/shared/Avatar';

import microphoneOff from 'img/call/microphone-off.svg';

import { useCallContext } from './CallContext';
import { useCallPermissionsContext } from './CallPermissionsContext';

interface Props {
  className?: string;
  isLocal?: boolean;
  participant?: DailyParticipant;
  avatar?: string;
  isScreenShareOn?: boolean;
  onClick?: () => void;
  fullScreenWidth: number;
  fullScreenHeight: number;
}

export const Video: React.FC<Props> = ({
  className,
  isLocal,
  participant,
  avatar,
  isScreenShareOn,
  onClick,
  fullScreenWidth,
  fullScreenHeight,
}) => {
  const [size, setSize] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  const { sidebarView, setAreControlsVisible } = useCallContext();

  const { isAudioOn, isVideoOn } = useCallPermissionsContext();

  const isMobile = getIsMobile();

  useEffect(() => {
    if (!isMobile && isScreenShareOn) {
      const videoWidth = 168;
      setSize(videoWidth);
    } else {
      const newHeight = Math.floor(fullScreenHeight / 2);
      const newWidth = Math.floor(newHeight / ASPECT_RATIO);
      setSize(isMobile || fullScreenWidth <= newWidth ? fullScreenWidth : newWidth);
    }
  }, [fullScreenWidth, fullScreenHeight, isScreenShareOn]);

  useEffect(() => {
    if (isLocal) return;
    if (audioRef?.current && participant?.audioTrack) {
      audioRef.current.srcObject = new MediaStream([participant.audioTrack]);
    }
  }, [participant?.audioTrack]);

  useEffect(() => {
    if (videoRef?.current && participant?.videoTrack) {
      videoRef.current.srcObject = new MediaStream([participant.videoTrack]);
    }
  }, [participant?.videoTrack]);

  // Audio stream cleanup
  useEffect(
    () => () => {
      if (!audioRef.current) {
        return;
      }

      const audioStream = audioRef.current.srcObject;

      if (audioStream) {
        const audioTracks = (audioStream as MediaStream).getTracks();

        audioTracks.forEach(track => {
          track.stop();
        });
      }
    },
    [audioRef.current],
  );

  // Video stream cleanup
  useEffect(
    () => () => {
      if (!videoRef.current) {
        return;
      }

      const videoStream = videoRef.current.srcObject;

      if (videoStream) {
        const videoTracks = (videoStream as MediaStream).getTracks();

        videoTracks.forEach(track => {
          track.stop();
        });
      }
    },
    [videoRef.current],
  );

  const isLocalVideoOff = isLocal && !isVideoOn;
  const isRemoteVideoOff = !isLocal && !participant?.video;
  const isLocalMuted = isLocal && !isAudioOn;
  const isRemoteMuted = !isLocal && participant && !participant.audio;

  const { x, y, handleMouseMove } = useMove();
  useEffect(() => {
    if (!isMobile) {
      setAreControlsVisible(true);
    }
  }, [x, y]);

  return (
    <VideoContainer
      className={className}
      onClick={onClick}
      size={size}
      isLocal={isLocal}
      isSidebarVisible={!!sidebarView}
      onMouseMove={handleMouseMove}
      isScreenShareOn={isScreenShareOn}
    >
      <VideoElement
        ref={videoRef}
        isMirrored={isLocal}
        muted
        autoPlay
        playsInline
        radius={isScreenShareOn}
      />
      <audio ref={audioRef} autoPlay playsInline />
      {(isLocalVideoOff || isRemoteVideoOff) && (
        <VideoOff
          avatar={avatar}
          isPartnerUnavailable={!isLocal && !participant}
          isScreenShareOn={isScreenShareOn}
          isSidebarVisible={!!sidebarView}
        />
      )}
      {(isLocalMuted || isRemoteMuted) && <Mute />}
    </VideoContainer>
  );
};

const ASPECT_RATIO = 0.5625;

interface VideoContainer {
  size: number;
  isLocal?: boolean;
  isSidebarVisible: boolean;
  isScreenShareOn?: boolean;
}

const getPxSize = (p: VideoContainer) => `${p.size}px`;

const VideoContainer = styled.div<VideoContainer>`
  position: relative;
  width: ${getPxSize};
  height: calc(${getPxSize} * ${p => (p.isScreenShareOn ? 1 : ASPECT_RATIO)});
  transition: ${globalTransitionSettings};
  margin-bottom: ${p => p.isScreenShareOn && margins.size2};

  ${media.mobile} {
    width: ${p => (!p.isLocal ? `${p.size}px` : `calc(${p.size}px * 0.33)`)};
    height: ${p => (p.isLocal ? `calc(${p.size}px * 0.33 * 1.2)` : '100%')};
    position: absolute;
    top: ${p => p.isLocal && margins.size3};
    right: ${p => p.isLocal && margins.size3};
    border: ${p => p.isLocal && borders.standard};
    margin: 0;
    margin-top: ${p => p.isLocal && getIsIOSApp() && margins.size4};
  }
`;

export const VideoElement = styled.video<{ isMirrored?: boolean; radius?: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: ${colors.blackMain};
  box-shadow: inset 0px 0px 5px #000;
  transform: ${p => p.isMirrored && 'scale(-1, 1)'};
  border-radius: ${p => p.radius && '10px'};

  &::-webkit-media-controls {
    display: none;
  }
`;

const VideoOff: React.FC<{
  avatar?: string;
  isPartnerUnavailable: boolean;
  isScreenShareOn?: boolean;
  isSidebarVisible?: boolean;
}> = ({ avatar, isPartnerUnavailable, isScreenShareOn, isSidebarVisible }) => {
  const { match } = useCallContext();
  return (
    <VideoOffContainer border={isSidebarVisible && isScreenShareOn} radius={isScreenShareOn}>
      {isPartnerUnavailable ? (
        <Unselectable>
          <Heading1
            color={colors.whiteMain}
            style={isScreenShareOn ? { fontSize: fontSizes.size1, margin: margins.size1 } : {}}
          >
            Waiting for {match?.first_name || 'your match'} to join...
          </Heading1>
        </Unselectable>
      ) : (
        <Unselectable>
          <Avatar src={avatar} size4 />
        </Unselectable>
      )}
    </VideoOffContainer>
  );
};

const VideoOffContainer = styled.div<{ border?: boolean; radius?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${colors.blackMid};
  ${p => p.border && `border: 1px solid ${colors.blackMain}`};
  border-radius: ${p => p.radius && '10px'};
`;

const Mute = () => (
  <MuteContainer>
    <MuteIcon />
  </MuteContainer>
);

const MuteContainer = styled.div`
  position: absolute;
  left: ${margins.size2};
  bottom: ${margins.size2};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${colors.tertiary2Main};
`;

const MuteIcon = styled(SVG).attrs(() => ({ src: microphoneOff }))`
  width: 16px;
  color: ${colors.whiteMain};
`;

export const Unselectable = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
