import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { margins, FlexColumn, Heading3, colors } from 'css/css';

import { Button } from 'js/components/shared/Button';

import { emptyProfileSvg } from 'img/registration-v2';

import { RegistrationStepProps } from '../MobileRegistrationContent';

import { OnboardingFlowPage } from './OnboardingFlowPage';
import { ProfilePictureUploader } from './ProfilePictureUploader';
import { ProgressBar } from './ProgressBar';
import { NUX_HEADER_STEPS, OnboardingSteps, Seperator } from './shared';

export const StepProfilePictureMobile: React.FC<RegistrationStepProps> = ({
  values,
  setValues,
  submitUserInfo,
}) => {
  const history = useHistory();

  const onNext = async () => {
    await submitUserInfo(
      { interests: values.selectedInterests.map(interest => interest.name) },
      () => {
        history.push(`/registration/name${history.location.search}`);
      },
    );
  };

  return (
    <OnboardingFlowPage
      step={OnboardingSteps.Profile}
      onNext={onNext}
      onPrev={() => history.push(`/registration/interests${history.location.search}`)}
      title={NUX_HEADER_STEPS.Profile.title}
      subtitle={NUX_HEADER_STEPS.Profile.subtitle}
    >
      <StepProfilePictureContainer>
        <ProgressBar numCompleted={0} total={4} />
        <ProfilePictureUploader onDidUpload={(image: string) => setValues({ ...values, image })}>
          {(uploading, onPickFile) => (
            <PictureEditorContainer>
              <img
                src={values.image || emptyProfileSvg}
                alt={values.image ? 'profile' : 'placeholder'}
                style={{ height: '96px', width: '96px', borderRadius: '50%' }}
              />
              <Seperator />
              <Heading3 style={{ marginBottom: margins.size2 }}>Choose a profile picture!</Heading3>
              {values.image ? (
                <Button large loading={uploading} onClick={onPickFile}>
                  Change picture
                </Button>
              ) : (
                <Button large loading={uploading} onClick={onPickFile}>
                  Upload picture
                </Button>
              )}
            </PictureEditorContainer>
          )}
        </ProfilePictureUploader>
      </StepProfilePictureContainer>
    </OnboardingFlowPage>
  );
};

const PictureEditorContainer = styled(FlexColumn)`
  justify-content: flex-start;
  width: 100%;
`;

const StepProfilePictureContainer = styled(FlexColumn)`
  background: ${colors.whiteMain};
  padding: ${margins.size2};
  margin: ${margins.size2};
  gap: ${margins.size2};
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
`;
