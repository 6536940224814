import React from 'react';
import styled from 'styled-components/macro';

import { margins, FlexColumn, FlexRow, Heading2, Text, colors } from 'css/css';

import { educationPage } from 'js/util/strings';
import { WhiteCardAction } from 'js/components/shared/MobileModal';

import Weekly from 'img/verify/weekly-mobile.svg';
import Intro from 'img/verify/intro-mobile.svg';
import VideoMeet from 'img/verify/video-meet-mobile.svg';

const Images: { [key: string]: string } = {
  weekly: Weekly,
  intro: Intro,
  meet: VideoMeet,
};

export const StartGuideMobile: React.FC<{ onSubmit: () => void }> = ({ onSubmit }) => {
  return (
    <StartGuideWrap>
      <div className="centered">
        <Heading2>{educationPage.mainTitle}</Heading2>
        <FlexRow justifyContent="space-between" $wrap style={{ marginBottom: margins.size5 }}>
          {educationPage.sections.map(section => (
            <FlexColumn style={{ margin: 'auto', maxWidth: '70vw' }} key={section.key}>
              <img
                src={Images[section.key]}
                alt={section.subtitle}
                style={{ marginBottom: '3vh', marginTop: '5vh' }}
              />
              <Text color={colors.blackMain}>{section.subtitle}</Text>
            </FlexColumn>
          ))}
        </FlexRow>
      </div>
      <WhiteCardAction title="Get started" onClick={onSubmit} />
    </StartGuideWrap>
  );
};

const StartGuideWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  & > .centered {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
