import React, { useRef } from 'react';
import styled from 'styled-components/macro';

import { FlexRow, Heading2, margins, Text, Link, colors, globalTransitionSettings } from 'css/css';

import { useMountEffect } from 'js/util/custom-hooks';
import { getIsMobile } from 'js/util/util';
import { useUser } from 'js/providers/UserProvider';
import { Avatar } from 'js/components/shared/Avatar';

interface Props {
  firstName: string;
  lastName: string;
  rank: number;
  profile: string;
  ConnectionStats: React.FunctionComponent;
  image?: string;
  numRows?: number;

  setContainerHeight?: (height: number) => void;
}

export const LeaderboardRow: React.FC<Props> = ({
  firstName,
  lastName,
  profile,
  rank,
  image,
  ConnectionStats,
  numRows,
  setContainerHeight,
}) => {
  const user = useUser();
  const isOwnProfile = profile === user.profile_id;
  const isMobile = getIsMobile();

  const containerRef = useRef<HTMLDivElement>(null);
  useMountEffect(() => {
    if (setContainerHeight && containerRef.current && numRows) {
      const containerHeight = containerRef.current.offsetHeight * numRows;
      setContainerHeight(containerHeight);
    }
  });

  return (
    <ConnectionProfileWrapper isOwnProfile={isOwnProfile} ref={containerRef}>
      <Link to={`/member/${profile}`}>
        <FlexRow justifyContent="space-between" width="100%">
          <FlexRow>
            <Heading2 style={{ width: '3em' }}>{rank}</Heading2>
            <Avatar size1={isMobile} size2={!isMobile} src={image} />
            <Name>
              {firstName} {firstName?.length + lastName?.length < 20 && lastName}
            </Name>
          </FlexRow>
          <ConnectionStats />
        </FlexRow>
      </Link>
    </ConnectionProfileWrapper>
  );
};

const ConnectionProfileWrapper = styled.div<{ isOwnProfile: boolean }>`
  padding: ${margins.size3} 32px;
  background-color: ${p => p.isOwnProfile && colors.greyLight};
  transition: ${globalTransitionSettings};
  &:hover {
    cursor: pointer;
    background-color: ${p => (p.isOwnProfile ? colors.greyMain : colors.greyLight)};
  }
`;

const Name = styled(Text)`
  margin-left: ${margins.size3};
`;
