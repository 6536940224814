import React from 'react';
import styled from 'styled-components/macro';

import {
  borders,
  colors,
  globalTransitionSettings,
  margins,
  FlexRow,
  Heading3,
  SubText,
} from 'css/css';

import { DeletionReason } from 'js/util/api';

interface Props {
  title: string;
  reasons: DeletionReason[];
  selectedReasons: number[];
  selectReason: (id: number) => void;
}
export const ReasonSelector: React.FC<Props> = ({
  title,
  reasons,
  selectedReasons,
  selectReason,
}) => (
  <>
    <Heading3 style={{ marginTop: margins.size3, marginBottom: margins.size2 }}>{title}</Heading3>
    <FlexRow $wrap>
      {reasons.map(reason => (
        <ReasonButton
          key={reason.id}
          onClick={() => selectReason(reason.id)}
          isSelected={selectedReasons.includes(reason.id)}
        >
          <SubText>{reason.name}</SubText>
        </ReasonButton>
      ))}
    </FlexRow>
  </>
);

const ReasonButton = styled.button<{ isSelected: boolean }>`
  border: ${borders.secondary};
  border-radius: 5px;
  background-color: ${p => (p.isSelected ? colors.secondaryMid : colors.whiteMain)};
  padding: ${margins.size1} ${margins.size2};
  width: fit-content;
  cursor: pointer;
  margin-right: ${margins.size1};
  margin-bottom: ${margins.size1};
  transition: ${globalTransitionSettings};
  :hover {
    background-color: ${p => (p.isSelected ? colors.secondaryMid : `${colors.secondaryMid}4d`)};
  }
  > p {
    color: ${p => (p.isSelected ? colors.whiteMain : colors.secondaryMid)};
    text-transform: lowercase;
    overflow: hidden;
    white-space: nowrap;
  }
  :focus {
    outline: none;
  }
`;
