import React, { forwardRef, useImperativeHandle } from 'react';

import { MobileFocusBehavior, useMobileFocusingBehavior } from 'js/util/use-mobile-focus-behavior';

interface MobileFocusingTextareaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  mobileFocusBehavior: MobileFocusBehavior;
  mobileFocusLabel: string;
  value?: string;
  resetValue?: (value: string) => void;
  buttonText?: string;
  characterCount?: { headline: string; min: number; max: number };
  buttonOnClick?: () => void;
  isButtonInvalid?: boolean;
  isEmpty?: boolean;
}

export const MobileFocusingTextarea = forwardRef(
  (
    {
      mobileFocusBehavior,
      mobileFocusLabel,
      buttonText,
      characterCount,
      buttonOnClick,
      isButtonInvalid,
      isEmpty,
      value,
      ...rest
    }: MobileFocusingTextareaProps,
    externalRef,
  ) => {
    const ref = React.createRef<HTMLTextAreaElement>();
    const focus = useMobileFocusingBehavior(
      ref,
      {
        behavior: mobileFocusBehavior || 'if-under-keyboard',
        label: mobileFocusLabel,
        isTextArea: true,
      },
      buttonText,
      characterCount,
      buttonOnClick,
      isButtonInvalid,
    );

    useImperativeHandle(externalRef, () => ref.current);

    return (
      <>
        {focus.background}
        <textarea
          {...rest}
          value={isEmpty && !focus.final ? '' : value}
          ref={ref}
          style={{
            ...(rest.style || {}),
            ...focus.styles,
          }}
          onFocus={e => {
            focus.onFocus(e);
            if (rest.onFocus) {
              rest.onFocus(e);
            }
          }}
          onBlur={e => {
            focus.onBlur();
            if (rest.onBlur) {
              rest.onBlur(e);
            }
          }}
        />
      </>
    );
  },
);
