import React from 'react';

import { apiFailure } from 'js/util/strings';

import { Logo } from './Logo';

export const APIFailure = () => (
  <div style={{ textAlign: 'center' }}>
    <Logo centered />

    <p style={styles.message}>{apiFailure.message}</p>
  </div>
);

const styles = {
  message: {
    marginTop: 20,
  },
};
