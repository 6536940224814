import React, { useState } from 'react';
import styled from 'styled-components/macro';

import {
  Clickable,
  colors,
  margins,
  FlexRow,
  Heading3,
  Text,
  borders,
  media,
  HideMobile,
} from 'css/css';

import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { Button } from 'js/components/shared/Button';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { getObjectives } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';
import { objectiveIcons as goalIcons } from 'js/util/util';

import { Body, Title } from '../styles';
import { GoalModal } from '../components/GoalModal';
import { GoalDetailModal } from '../components/GoalDetailModal';

interface Goal {
  id: number;
  questions: { id: number }[];
}

export const Goals: React.FC = () => {
  const { objective_ids: userGoals, objective_info: userGoalDetails } = useUser();

  const { updateUser } = useUserContextProvider();
  const [goals, setGoals] = useState<Goal[]>([]);
  const [addingGoal, setAddingGoal] = useState(false);
  const [addingNewGoal, setAddingNewGoal] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState<Goal>();
  const [addingGoalDetails, setAddingGoalDetails] = useState(false);
  const [selectedGoalDetails, setSelectedGoalDetails] = useState<any[]>([]);

  useMountEffect(() => {
    const loadAllGoals = async () => {
      const res = await getObjectives();
      if (res.ok) setGoals(res.getJson);
    };
    loadAllGoals();
  });

  const changeSelectedGoal = (passedGoals: Goal[]) => {
    // Selected hands back an array of selections, essentially need to "undo" this work.
    if (!selectedGoal) return setSelectedGoal(passedGoals[0]);
    const newGoal = passedGoals.filter(goal => goal.id !== selectedGoal.id);
    return setSelectedGoal(newGoal[0] || null);
  };

  const closeModals = () => {
    setSelectedGoal(undefined);
    setAddingGoal(false);
    setAddingGoalDetails(false);
    setAddingNewGoal(false);
  };

  const nextModal = () => {
    if (selectedGoal) {
      setAddingGoal(false);
      setAddingGoalDetails(true);
    }
  };

  const clickBackToGoals = async () => {
    await deleteGoal(true);
    setAddingGoal(true);
    setSelectedGoalDetails([]);
    setAddingGoalDetails(false);
  };

  const clickGoalDetails = (goal: Goal, goalDetails: any) => {
    setSelectedGoal(goal);
    setSelectedGoalDetails(goalDetails);
    setAddingGoalDetails(true);
  };

  const deleteGoal = async (reselecting: any) => {
    const newUserGoals = userGoals.filter(goal => goal.id !== selectedGoal?.id);
    const goalDetailQuestionIds = selectedGoal?.questions.map(question => question.id);
    const newUserGoalDetails = userGoalDetails.filter(
      detail => !goalDetailQuestionIds?.includes(detail.question_id),
    );

    updateUser({
      objectiveIds: newUserGoals.map(obj => obj.id),
      objectiveInfo: newUserGoalDetails,
    }).then(() => {
      if (!reselecting) closeModals();
    });
  };

  const saveGoal = async () => {
    if (userGoals.length === 1 && !addingNewGoal) {
      // Special case where user is "swapping" their last goal.
      // Required as BE does not allow "no goals".
      updateUser({
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        objectiveIds: [selectedGoal].map(obj => obj.id),
        objectiveInfo: [...selectedGoalDetails],
      }).then(() => closeModals());
    } else {
      const newUserGoals = [...userGoals, selectedGoal];
      const updatedGoalDetailsDict: Map<number, any> = new Map();
      userGoalDetails.forEach(obj => {
        updatedGoalDetailsDict.set(obj.question_id as number, obj);
      });
      selectedGoalDetails.forEach(obj => {
        updatedGoalDetailsDict.set(obj.question_id as number, obj);
      });
      const newUserGoalDetails: Array<any> = [];
      updatedGoalDetailsDict.forEach(value => {
        newUserGoalDetails.push(value);
      });
      updateUser({
        objectiveIds: newUserGoals.map(obj => obj?.id) as number[],
        objectiveInfo: newUserGoalDetails,
      }).then(() => closeModals());
    }
  };

  if (!userGoals?.length || !goals.length) return <SVGLoader center={false} />;
  return (
    <>
      <HideMobile>
        <Title>Your goals</Title>
      </HideMobile>
      <Body>
        Your goals will never be shared with anyone and are only used to make your matches relevant.
      </Body>
      <Body>Add more details to your goals to let us know what you are looking for!</Body>
      {userGoals.map((goal, i) => {
        const ids = goal.questions.map(question => question.id);
        const goalDetails = userGoalDetails.filter(detail => ids.includes(detail.question_id));
        const hasDetails = !!goalDetails.length;
        return (
          <GoalItem key={goal.id} marginBottom={i !== 2}>
            <FlexRow style={{ justifyContent: 'space-between', width: '100%' }}>
              <FlexRow>
                <GoalIcon
                  src={goalIcons.find(icon => icon.id === goal.id)?.imgSrc}
                  alt={goal.name}
                />
                <Text
                  style={{
                    textAlign: 'left',
                    marginLeft: margins.size3,
                    marginRight: margins.size2,
                  }}
                  color={colors.blackMain}
                >
                  {goal.name}
                </Text>
              </FlexRow>
              <Button
                small
                secondary={hasDetails}
                onClick={() => clickGoalDetails(goal, goalDetails)}
                style={{ margin: 0 }}
              >
                details
              </Button>
            </FlexRow>
          </GoalItem>
        );
      })}
      {userGoals.length < 3 && (
        <Clickable
          onClick={() => {
            setAddingNewGoal(true);
            setAddingGoal(true);
          }}
        >
          <GoalItem>
            <Heading3 color={colors.primaryMain}>+ add new goal</Heading3>
          </GoalItem>
        </Clickable>
      )}
      <GoalModal
        isOpen={addingGoal}
        goals={goals}
        userGoals={userGoals}
        closeModals={closeModals}
        selectedGoal={selectedGoal}
        setSelectedGoal={(goal: Goal[]) => changeSelectedGoal(goal)}
        nextModal={nextModal}
      />
      <GoalDetailModal
        isOpen={addingGoalDetails}
        closeModals={closeModals}
        selectedGoal={selectedGoal}
        userGoals={userGoals}
        userGoalDetails={userGoalDetails}
        selectedGoalDetails={selectedGoalDetails}
        setSelectedGoalDetails={setSelectedGoalDetails}
        clickBackToGoals={clickBackToGoals}
        deleteGoal={deleteGoal}
        saveGoal={saveGoal}
      />
    </>
  );
};

const GoalIcon = styled.img`
  height: 27px;
  width: 27px;
  min-height: 27px;
  min-width: 27px;
`;

const GoalItem = styled.div<{
  marginBottom?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 54px;
  width: 100%;
  max-width: 490px;
  padding: ${margins.size3};
  padding-left: ${margins.size3};

  background-color: ${colors.whiteMain};

  border: ${borders.variation};
  border-radius: 5px;
  margin-bottom: ${p => (p.marginBottom ? margins.size1 : '0px')};

  ${media.mobile} {
    border: none;
    padding: ${margins.size2} 0;
    border-bottom: ${borders.variation};
    margin-bottom: 0;
    &:last-child {
      border-bottom: 0;
    }
  }
`;
