import React, { useState } from 'react';

import { FlexColumn, Text } from 'css/css';

import Warn from 'img/weekly/warn.svg';

import { TimeslotPicker } from '../pickers/TimeslotPicker';
import { Locale, LocaleTimeslotsByDay } from '../types';
import { LocationSelector } from '../components';

import { WarningBox } from './styles';

interface Props {
  selectedLocale?: number | null;
  setSelectedLocale: (locale: number | null) => void;
  locales: Locale[];
  localeTimeslotsByDay: LocaleTimeslotsByDay[];
  selectedTimeslots: string[];
  clickTimeslot: (timeslot: string) => void;
  warnDeadline?: boolean;
  newUser: boolean;
  maxMeetings: number;
  selectedNumberMeetings: number;
  busyTimeslots: string[];
  autopilotVersion: number | undefined;
  onCoachingPlan?: boolean;
}

export const TimeslotMeetingQuestion: React.FC<Props> = ({
  selectedLocale,
  setSelectedLocale,
  locales,
  localeTimeslotsByDay,
  selectedTimeslots,
  clickTimeslot,
  warnDeadline,
  newUser,
  maxMeetings,
  selectedNumberMeetings,
  busyTimeslots,
  autopilotVersion,
  onCoachingPlan,
}) => {
  const [openTrays, setOpenTrays] = useState<number[]>([0]);

  const toggleTray = (tray: number) => {
    setOpenTrays(
      openTrays.includes(tray) ? openTrays.filter(t => t !== tray) : [...openTrays, tray],
    );
  };

  return (
    <>
      {!newUser && !onCoachingPlan && (
        <LocationSelector
          selectedLocale={selectedLocale}
          numMeetings={selectedNumberMeetings}
          locales={locales}
          setSelectedLocale={setSelectedLocale}
        />
      )}
      {warnDeadline && selectedLocale === null && (
        <WarningBox>
          <FlexColumn>
            <img src={Warn} alt="warn" />
            <Text>The deadline has just passed, you&apos;re now signing up for next week!</Text>
          </FlexColumn>
        </WarningBox>
      )}
      <TimeslotPicker
        timeslotData={localeTimeslotsByDay}
        selected={selectedTimeslots}
        onClick={clickTimeslot}
        toggleTray={toggleTray}
        openTrays={openTrays}
        selectedNumberMeetings={selectedNumberMeetings}
        maxMeetings={maxMeetings}
        busyTimeslots={busyTimeslots}
        autopilotVersion={autopilotVersion}
      />
    </>
  );
};
