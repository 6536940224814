import React from 'react';
import { useHistory } from 'react-router';

import { Text, Card, Heading1, margins } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { GreyPage } from 'js/components/shared/page-wrappers';

export const LocaleNotAvailable = ({ locale }: any) => {
  const history = useHistory();

  const actualLocale = locale && locale.name;
  if (actualLocale)
    return (
      <GreyPage>
        <Card style={{ paddingBottom: margins.size6, paddingTop: margins.size6 }}>
          <Heading1>{locale.name} requires more members before launch!</Heading1>
          <Text>Lunchclub isn&apos;t active in {locale.name} yet!</Text>
          <Text style={{ marginBottom: margins.size3 }}>Bring Lunchclub to {locale.name}:</Text>
          <Button onClick={() => history.push('/invite')}>Invite friends</Button>
        </Card>
      </GreyPage>
    );
  return (
    <GreyPage>
      <Card style={{ paddingBottom: margins.size6, paddingTop: margins.size6 }}>
        <Heading1>Lunchclub isn&apos;t active in your locale yet!</Heading1>
        <Text style={{ marginBottom: margins.size3 }}>Help bring Lunchclub to your locale</Text>
        <Button onClick={() => history.push('/invite')}>Invite friends</Button>
      </Card>
    </GreyPage>
  );
};
