import React from 'react';
import styled from 'styled-components/macro';

import { colors, Heading2 } from 'css/css';

import { useSharedCallContext } from 'js/components/call/SharedCallContext';
import { useUser } from 'js/providers/UserProvider';

const Call: React.FC = () => {
  const user = useUser();
  const { match, url, feedbackCode } = useSharedCallContext();

  if (user && match && url && window.bridge?.showCall) {
    window.bridge.showCall(user, match, url, feedbackCode);
  }

  return (
    <Container>
      <Heading2 color={colors.whiteMain}>Loading call...</Heading2>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.blackMain};
`;

export default Call;
