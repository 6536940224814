import React, { useMemo, useState } from 'react';
import styled from 'styled-components/macro';

import {
  colors,
  margins,
  FlexRow,
  Text,
  ClickableText,
  globalTransitionSettings,
  borders,
} from 'css/css';

interface ContainerStyle {
  padding?: string;
  alignItems?: string;
}

interface CheckboxStyle {
  width?: string;
  height?: string;
}

interface Props {
  desc: string | JSX.Element;
  hiddenDesc?: string;
  checked: boolean;
  fullWidth?: boolean;
  containerStyle?: ContainerStyle;
  checkboxStyle?: CheckboxStyle;
  borderColor?: string;
  setCheckValue: (checked: boolean) => void;
}

export const Checkbox: React.FC<Props> = ({
  desc,
  hiddenDesc,
  checked,
  fullWidth = false,
  containerStyle,
  checkboxStyle,
  borderColor,
  setCheckValue,
}) => {
  const [showDesc, setShowDesc] = useState(false);
  const toggleCheck = () => setCheckValue(!checked);
  const id = useMemo(() => `${Math.random()}`, []);

  return (
    <FlexRow
      alignItems={containerStyle?.alignItems ? containerStyle?.alignItems : 'flex-start'}
      width={fullWidth ? '100%' : 'unset'}
      padding={containerStyle?.padding ? containerStyle?.padding : '0'}
    >
      <StyledCheckbox
        checked={checked}
        borderColor={borderColor}
        width={checkboxStyle?.width}
        height={checkboxStyle?.height}
        onClick={toggleCheck}
        id={id}
      >
        <Check viewBox="0 0 24 29">
          <polyline points="20 6 9 17 4 12" />
        </Check>
      </StyledCheckbox>

      <div style={{ textAlign: 'left' }}>
        <label
          style={{ display: 'initial', margin: 0, userSelect: 'none', wordWrap: 'break-word' }}
          htmlFor={id}
        >
          <Text>{desc}</Text>
        </label>
        {hiddenDesc && (
          <>
            <HiddenText color={colors.blackLight} hide={!showDesc}>
              {hiddenDesc}
            </HiddenText>

            <ClickableText onClick={() => setShowDesc(!showDesc)}>
              {showDesc ? 'Show less' : 'Learn more...'}
            </ClickableText>
          </>
        )}
      </div>
    </FlexRow>
  );
};

const Check = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const StyledCheckbox = styled.button<{
  checked: boolean;
  borderColor?: string;
  width?: string;
  height?: string;
}>`
  display: inline-block;
  min-width: 16px;
  min-height: 16px;
  width: ${p => (p.width ? p.width : '16px')};
  height: ${p => (p.height ? p.height : '16px')};
  padding: 0px;
  background: ${p => (p.checked ? colors.primaryMain : colors.whiteMain)};
  border: ${p => (p.borderColor ? `1px solid ${p.borderColor}` : borders.standard)};
  border-radius: 5px;
  margin-right: ${margins.size2};
  cursor: pointer;
  margin-top: 3px;
  transition: ${globalTransitionSettings};
  &:active,
  &:focus {
    outline: none;
  }
  ${Check} {
    visibility: ${p => (p.checked ? 'visible' : 'hidden')};
  }
`;

const HiddenText = styled(Text)<{ hide: boolean }>`
  max-height: ${p => (p.hide ? '0px' : '500px')};
  opacity: ${p => (p.hide ? 0 : 1)};
  transition: ${globalTransitionSettings};
  overflow: hidden;
`;
