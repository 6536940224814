import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { margins, FlexRow, SubText, Heading3, colors } from 'css/css';

import noteSvg from 'img/feedback/private-note.svg';

import { Container, ExpandingContainer, ClickableContainer, Icon } from './styles';

interface Props {
  setInviteNote: (customNote: string) => void;
  inviteNote: string;
}

export const EditInvite: React.FC<Props> = ({ setInviteNote, inviteNote }) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <Container marginTop="15px">
      <ClickableContainer onClick={() => setExpanded(!expanded)}>
        <FlexRow>
          <Icon src={noteSvg} alt="Note" />
          <Heading3>Write a message for your invites</Heading3>
        </FlexRow>
      </ClickableContainer>

      <ExpandingContainer expanded={expanded}>
        <SubText>The note below will be sent to your invites.</SubText>
        <NoteEditArea
          value={inviteNote}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setInviteNote(e.target.value)}
        />
      </ExpandingContainer>
    </Container>
  );
};

const NoteEditArea = styled.textarea`
  height: 75px;
  width: 100%;
  background-color: ${colors.greyLight};
  color: ${colors.blackMid};
  text-align: left;
  padding: ${margins.size3};
  margin: ${margins.size3} auto;
  overflow: scroll;
  resize: none;
  border: none;
  outline: none;
`;
