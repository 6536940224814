import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { margins, Heading2, Text } from 'css/css';

import { CoachingPlanAvatars } from 'js/components/coaching_plan/CoachingPlanAvatars';
import { CoachingPlanMatchMeta } from 'js/api/coaching-plan';

interface Props {
  onAutopilot: boolean;
  onCoachingPlan: boolean;
  coachingPlanSignupType: number | undefined;
  matches: CoachingPlanMatchMeta[];
}

export const ScheduleHeader: React.FC<Props> = ({
  onAutopilot,
  onCoachingPlan,
  coachingPlanSignupType,
  matches,
}) => (
  <>
    {onCoachingPlan || coachingPlanSignupType ? (
      <>
        <CoachingPlanAvatars matches={matches} />
        <Heading style={{ marginTop: margins.size2 }}>Schedule your Curated Matches</Heading>
      </>
    ) : (
      <Heading>Schedule your matches</Heading>
    )}
    <div style={{ width: '100%' }}>
      {onCoachingPlan ? (
        <Text style={{ marginBottom: margins.size3, textAlign: 'left' }}>
          You&apos;re on Curated Matches so there&apos;s no need to submit your availability. If you
          would like to change your default setting, you can pass for the week or change times for
          this upcoming week here, or opt out of Curated Matches entirely from the{' '}
          <Link to="/settings">settings page</Link>.
        </Text>
      ) : (
        onAutopilot &&
        !coachingPlanSignupType && (
          <Text style={{ marginBottom: margins.size3, textAlign: 'left' }}>
            You&apos;re on autopilot already so there&apos;s no need to submit your availability. If
            you would like to change your default setting, you can pass for the week or change times
            for this upcoming week here, or opt out of autopilot entirely from the{' '}
            <Link to="/settings">settings page</Link>.
          </Text>
        )
      )}
    </div>
  </>
);

const Heading = styled(Heading2)`
  text-align: left;
  width: 100%;
  margin-bottom: ${margins.size3};
`;
