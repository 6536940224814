import React from 'react';
import styled from 'styled-components/macro';

import { margins, colors, SubText } from 'css/css';

import { Dropdown } from './dropdown';

export const DropdownMenu = ({ top, right, position, width, margin, menuItems, isOpen }: any) => (
  <Dropdown
    position={position}
    top={top}
    right={right}
    width={width}
    margin={margin}
    isOpen={isOpen}
  >
    {menuItems.map((item: any, idx: any) => (
      <Wrapper key={item.label} onClick={() => item.onClick()}>
        <MenuItem>
          <SubText color={item.selected ? colors.primaryMain : colors.blackMid}>
            {item.label}
          </SubText>
        </MenuItem>

        {idx < menuItems.length - 1 && <hr style={{ margin: '0px' }} />}
      </Wrapper>
    ))}
  </Dropdown>
);

const MenuItem = styled.div`
  padding: ${margins.size2} 0;
  text-align: left;
`;

const Wrapper = styled.div`
  margin: 0 -${margins.size3};
  padding: 0 ${margins.size3};
  &:hover {
    background-color: ${colors.greyLight};
    cursor: pointer;
  }
`;
