import React, { useEffect, useState } from 'react';
import { Moment } from 'moment';

import { margins, FlexColumn, Heading1, Heading2, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { Modal } from 'js/components/shared/NewModal';
import { useUser } from 'js/providers/UserProvider';
import { postTestActivity } from 'js/util/api';
import { getIsMobile, INTERNATIONAL_TIME_LOCALES } from 'js/util/util';

import autopilotSvg from 'img/weekly/autopilot.svg';

interface Props {
  showModal: boolean;
  timeslot: Moment;
  accept: () => void;
  decline: () => void;
}

export const AutopilotHabitualModal: React.FC<Props> = ({
  showModal,
  timeslot,
  accept,
  decline,
}) => {
  const [modalOpen, setModalOpen] = useState(showModal);
  const user = useUser();

  useEffect(() => {
    setModalOpen(showModal);
  }, [showModal]);

  const handleClose = () => {
    const platform = window?.bridge ? 'ios' : getIsMobile() ? 'mobile' : 'desktop';

    postTestActivity('autopilot-existing', `${platform}-decline`);
    decline();
    setModalOpen(false);
  };

  const show24hourTime = INTERNATIONAL_TIME_LOCALES.includes(user.locale);

  return (
    <Modal showModal={modalOpen} handleClose={handleClose}>
      <FlexColumn style={{ textAlign: 'center' }}>
        <Heading1 style={{ margin: `${margins.size2} 0px` }}>Lunchclub on autopilot</Heading1>
        <img src={autopilotSvg} alt="" />

        <Heading2 style={{ marginBottom: margins.size2 }}>
          It looks like this is your preferred time to meet!
        </Heading2>
        <Text style={{ marginBottom: margins.size2 }}>
          We will send you a new match every week by default.
          <br />
          You can opt-out or change at any time.
        </Text>

        <Text style={{ margin: `${margins.size4} 0px` }}>
          Your weekly time:{' '}
          <span style={{ fontWeight: 'bold' }}>
            {show24hourTime ? timeslot.format('dddd, HH:mm') : timeslot.format('dddd, hh:mma')}
          </span>
        </Text>

        <Button large onClick={accept} style={{ marginTop: margins.size3 }}>
          Turn on autopilot
        </Button>
      </FlexColumn>
    </Modal>
  );
};
