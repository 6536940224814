import React, { useState, useEffect, createContext, useContext } from 'react';

import { availability } from 'types/availability';
import { emptyUser, UserMe } from 'types/user';

import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { getElliotToken, getParameterByName, isProduction, TEST_BUCKETS } from 'js/util/util';
import { useResource } from 'js/util/use-resource';
import { postUserInfo, PostUserInfoPayload } from 'js/util/api';

const noop = () => {
  // eslint-disable-next-line no-console
  console.error('No parent UserProvider found!');
  return Promise.reject();
};

// TODO: Rename UserContextProvider
const UserContext = createContext<{
  testBuckets: Record<string, boolean>;
  user: UserMe | undefined;
  fetchTestBuckets: () => Promise<{ test_ids: number[] }>;
  updateUser: (user: PostUserInfoPayload) => Promise<UserMe | undefined>;
  fetchUser: () => Promise<UserMe>;
  userAvailability: availability | undefined;
  updateUserAvailability: () => Promise<availability>;
}>({
  testBuckets: {},
  fetchTestBuckets: noop,
  user: undefined,
  updateUser: noop,
  fetchUser: noop,
  userAvailability: undefined,
  updateUserAvailability: noop,
});

export const useTestBuckets = () => {
  const { testBuckets } = useContext(UserContext);
  return testBuckets;
};

export const useUser = () => {
  // For developer simplicity in child components, we want to always return a defined User.

  // We use a variety of methods to ensure this:
  // 1. Loading the UserProvider component as we fetch user/me
  // 2. Blocking authenticated page renders on existance, and non empty UserMe

  // This is the only blocking call to render the page. All other data is loaded asynchronously
  // and must absorb the non-guarantees.
  const { user } = useContext(UserContext);
  return user || emptyUser;
};

export const useUserContextProvider = () => useContext(UserContext);

export const useUserAvailability = () => {
  const { userAvailability } = useContext(UserContext);
  return userAvailability;
};

export const UserProvider: React.FC = ({ children }) => {
  const isAuthenticated = !!getElliotToken();
  const [loading, setLoading] = useState(isAuthenticated);

  const [testBucketsRes, testBucketOps] = useResource<{
    test_ids: number[];
  }>('discover/user/test_buckets');
  const [testBuckets, setTestBuckets] = useState({});

  useEffect(() => {
    let testBucketIds = testBucketsRes?.test_ids || [];

    // You can pass this param to force tests on non-prod builds.
    const param = getParameterByName('testBuckets');
    if (param && !isProduction()) {
      testBucketIds = param.split(',').map(bucket => parseInt(bucket, 10)) || [];
    }

    const participatingBuckets = {
      tenStarTest: testBucketIds.includes(TEST_BUCKETS.TEN_STAR_RATING_TEST),
      conversationRatingTest: testBucketIds.includes(TEST_BUCKETS.CONVERSATION_RATING_TEST),
      disableInviteAllTest: testBucketIds.includes(TEST_BUCKETS.DISABLE_POND_ALL),
      slantOptInTest: testBucketIds.includes(TEST_BUCKETS.SLANT_OPT_IN_TEST),
      fpmInviteTestInvitePage: testBucketIds.includes(TEST_BUCKETS.NUX_MATCH_INVITE_TEST),
      newUserForcedAutopilot: testBucketIds.includes(TEST_BUCKETS.NEW_USER_FORCED_AUTOPILOT),
      endorsementFeedTest: testBucketIds.includes(TEST_BUCKETS.ENDORSEMENT_FEED_TEST),
      discoverBookingTest: testBucketIds.includes(TEST_BUCKETS.DISCOVER_BOOKING_TEST),
      cohesiveAutopilotTest: testBucketIds.includes(TEST_BUCKETS.COHESIVE_AUTOPILOT_TEST),
    };

    setTestBuckets(participatingBuckets);
  }, [testBucketsRes]);

  const [userRes, userOpt] = useResource<UserMe>('discover/user/me');
  const [user, setUser] = useState<UserMe>();

  useEffect(() => {
    if (userRes) {
      setUser(userRes);
      setLoading(false);
    }
  }, [userRes]);

  const updateUser = async (newUser: PostUserInfoPayload) => {
    const res = await postUserInfo(newUser);
    if (res.ok) {
      return userOpt.refresh();
    }
    return Promise.reject();
  };

  const fetchTestBuckets = () => testBucketOps.refresh();

  const fetchUser = () => {
    const combinedPromise = Promise.all([userOpt.refresh(), testBucketOps.refresh()]);
    return combinedPromise.then(([userRet]) => userRet);
  };

  // TODO: Capitalize "availability" interface
  const [availabilityRes, availabilityOpt] = useResource<availability>(
    'discover/user/concierge_availability',
  );
  const [userAvailability, setUserAvailability] = useState<availability>();

  useEffect(() => {
    if (availabilityRes) {
      const hasSignedUpWeekly = !!(
        availabilityRes?.time_slots?.length ||
        availabilityRes?.passed ||
        availabilityRes?.slant_opt_in
      );
      setUserAvailability({ ...availabilityRes, has_signed_up_weekly: hasSignedUpWeekly });
    }
  }, [availabilityRes]);

  const updateUserAvailability = () => availabilityOpt.refresh();

  const userContextValue = {
    testBuckets,
    fetchTestBuckets,
    user,
    updateUser,
    fetchUser,
    userAvailability,
    updateUserAvailability,
  };

  return loading ? (
    <SVGLoader />
  ) : (
    <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>
  );
};
