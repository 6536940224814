import Founder from '../../../../img/weekly/pick-options/founder.svg';
import GoToMarket from '../../../../img/weekly/pick-options/market.svg';
import Investor from '../../../../img/weekly/pick-options/investor.svg';
import LunchclubsPick from '../../../../img/weekly/pick-options/lunchclubs.svg';
import ProductExpert from '../../../../img/weekly/pick-options/product.svg';
import TechExpert from '../../../../img/weekly/pick-options/tech.svg';

export const MEETING_OPTIONS = [
  {
    id: 0,
    option: `Pass for a week`,
  },
  {
    id: 1,
    option: 1,
  },
  {
    id: 2,
    option: 2,
  },
  {
    id: 3,
    option: 3,
  },
  {
    id: 4,
    option: 4,
    cost: 10,
  },
  {
    id: 5,
    option: 5,
    cost: 20,
  },
  {
    id: 6,
    option: 6,
    cost: 30,
  },
];

export const PICK_OPTIONS = [
  {
    id: 0,
    name: "Lunchclub's pick",
    src: LunchclubsPick,
  },
  {
    id: 1,
    name: 'Technical expert',
    cost: 30,
    src: TechExpert,
  },
  {
    id: 2,
    name: 'Product expert',
    cost: 30,
    src: ProductExpert,
  },
  {
    id: 3,
    name: 'Go-to-market expert',
    cost: 30,
    src: GoToMarket,
  },
  {
    id: 4,
    name: 'Founder',
    cost: 30,
    src: Founder,
  },
  {
    id: 5,
    name: 'Investor',
    cost: 30,
    src: Investor,
  },
];
