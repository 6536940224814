import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { colors, FlexColumn, fontSizes, margins, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import CarouselIndicator from 'js/components/shared/carousel/Indicator';
import useRotating from 'js/util/hooks/useRotating';

import valuePropFrame1 from 'img/landing-page/mobile/value-prop-frame-1.svg';
import valuePropFrame2 from 'img/landing-page/mobile/value-prop-frame-3.svg';
import valuePropFrame3 from 'img/landing-page/mobile/value-prop-frame-2.svg';
import squiggle from 'img/landing-page/mobile/squiggle.svg';
import lunchclubLogoSVG from 'img/mobile-root/landing-page/lunchclub-logo.svg';
import wordmarkSVG from 'img/mobile-root/landing-page/hero-wordmark.svg';

import { MobileRootContainer, UserState } from '.';

interface LandingPageProps {
  setUserState: React.Dispatch<React.SetStateAction<UserState>>;
}

export const LandingPage: React.FC<LandingPageProps> = ({ setUserState }) => {
  const [content, index] = useRotating(HOW_IT_WORKS_CONTENT, 5000);

  return (
    <MobileRootContainer color={colors.primaryMain}>
      <Squiggle src={squiggle} />
      <Hero>
        <img src={wordmarkSVG} alt="Lunchclub logo" />
        <Logo src={lunchclubLogoSVG} alt="Lunchclub" />
      </Hero>
      <About>
        <AboutBox key={index} {...content} />
      </About>
      <SignupContainer>
        <Button
          onClick={() => setUserState('register-email')}
          style={{ color: colors.blackMain }}
          secondary
          large
        >
          Create an account
        </Button>
        <Button
          onClick={() => setUserState('login-email')}
          style={{ backgroundColor: colors.blackMain, marginTop: margins.size1 }}
          large
        >
          Log in
        </Button>
      </SignupContainer>
    </MobileRootContainer>
  );
};

const Squiggle = styled.img`
  position: fixed;
  width: 100vw;
  top: 145px;
`;

const Hero = styled(FlexColumn)`
  margin-top: 20px;
  justify-content: flex-end;
  z-index: 0;
  flex: 1;
`;

const Logo = styled.img`
  margin-top: 12px;
`;

const About = styled(FlexColumn)`
  margin: 0px 16px;
  padding: 45px 24px 25px 24px;
  justify-content: center;
  align-items: center;
`;

interface AboutContent {
  img: string;
  step: number;
  text: string;
}
const AboutBox = ({ img, step, text }: AboutContent) => (
  <>
    <AboutContainer>
      <AboutImage src={img} alt={text} />
      <AboutText>{text}</AboutText>
    </AboutContainer>
    <AboutCarouselContainer>
      <CarouselIndicator sections={HOW_IT_WORKS_CONTENT.length} activeIndex={step - 1} />
    </AboutCarouselContainer>
  </>
);

const fadeInOut = keyframes`
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
      opacity: 0;
    }
`;

const AboutContainer = styled(FlexColumn)`
  animation: ${fadeInOut} 6s ease-in-out;
`;

const AboutImage = styled.img`
  height: 200px;
  width: 200px;
  margin: 20px;
`;

const AboutText = styled(Text)`
  font-size: ${fontSizes.size3};
  text-align: center;
  color: ${colors.whiteMain};
  opacity: 70%;
  white-space: pre-wrap;
`;

const AboutCarouselContainer = styled.div`
  margin-top: 20px;
`;

const SignupContainer = styled(FlexColumn)`
  height: 140px;
  justify-content: flex-end;
  padding: 0px ${margins.size3};
  margin-bottom: ${margins.size4};
  z-index: 1;
`;

const HOW_IT_WORKS_CONTENT: AboutContent[] = [
  {
    img: valuePropFrame1,
    step: 1,
    text: "Tell us your background, goals,\nand what you're excited about",
  },
  {
    img: valuePropFrame2,
    step: 2,
    text: 'Our AI matches you with just the\nright person for a 1:1 conversation',
  },
  {
    img: valuePropFrame3,
    step: 3,
    text: 'Your feedback improves our matches for you each week',
  },
];
