import styled from 'styled-components/macro';

import { globalTransitionSettings, colors, margins, FlexColumn, FlexRow, borders } from 'css/css';

type Container = {
  marginTop: string;
  hasNote?: boolean;
};

export const Container = styled.div<Container>`
  display: flex;
  flex-direction: column;
  transition: ${globalTransitionSettings};
  min-height: fit-content;
  width: 100%;
  justify-content: center;
  border: ${borders.variation};
  border-radius: 5px;
  background-color: ${colors.whiteMain};
  overflow: visible;
  margin-top: ${p => p.marginTop || 'unset'};
  &:hover {
    background-color: ${colors.greyMain};
  }
`;

type ExpandingContainer = {
  expanded: boolean;
};
export const ExpandingContainer = styled(FlexColumn)<ExpandingContainer>`
  transition: ${globalTransitionSettings};
  overflow: hidden;
  width: 100%;
  max-height: ${p => (p.expanded ? '500px' : '0px')};
  align-items: flex-start;
  padding: 0px ${margins.size3} 0px;
`;

export const ClickableContainer = styled(FlexRow)`
  width: 100%;
  padding: ${margins.size3} 0px;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;

export const Icon = styled.img`
  width: 17px;
  height: 17px;
  min-height: 17px;
  min-width: 17px;
  margin: 0px ${margins.size3};
`;
