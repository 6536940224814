import React, { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';

import { AssetPrefetcher } from 'js/components/shared/AssetPrefetcher';
import { getEndorsementFeed, getFeed, postFeedDeckAnswer, postImpressions } from 'js/util/api';
import { useInterval, useMountEffect } from 'js/util/custom-hooks';
import { AllCardTypes, Card, MinimalEndorsementCard } from 'js/components/homepage/types';
import { unique } from 'js/util/unique';

const PREFETCH_URLS = ['/invite', '/weekly'];

interface Impression {
  id: string;
  timestamp: number;
}

const getFeedCardImages = (imagePairs: { [imageName: string]: string }, card: any) => {
  let cardImages = {};
  if (card.data.user) {
    const { profile, image } = card.data.user;
    if (profile && image) {
      cardImages = {
        ...cardImages,
        ...{ [card.data.user?.profile || '']: card.data.user?.image || '' },
      };
    }
  }
  if (card.data.friend_1) {
    const { profile, image } = card.data.friend_1;
    if (profile && image) {
      cardImages = {
        ...cardImages,
        ...{ [card.data.friend_1?.profile || '']: card.data.friend_1?.image || '' },
      };
    }
  }
  if (card.data.friend_2) {
    const { profile, image } = card.data.friend_2;
    if (profile && image) {
      cardImages = {
        ...cardImages,
        ...{ [card.data.friend_2?.profile || '']: card.data.friend_2?.image || '' },
      };
    }
  }
  if (card.card_type === AllCardTypes.Content) {
    cardImages = {
      ...cardImages,
      ...{ [card.card_id]: card.data.image },
    };
  }
  return { ...imagePairs, ...cardImages };
};

// eslint-disable-next-line no-console
const noop = () => console.error('No parent FeedContextProvider found!');

// TODO: Rename FeedContextProvider
const FeedContext = createContext<{
  receiveImpression: (id: string) => void;
  feedCards: Card[];
  completedCards: Set<string>;
  fetchFeedCardsIfNeeded: () => void;
  removeCard: (cardId: string) => void;
  changeCardProperty: (cardId: string, key: string, value: string | boolean) => void;
  completeCard: (cardId: string) => void;
  shouldShowDeckNotif: boolean;
  setShouldShowDeckNotif: React.Dispatch<React.SetStateAction<boolean>>;
  initialFeedLength: number;
}>({
  receiveImpression: noop,
  feedCards: [],
  completedCards: new Set(),
  fetchFeedCardsIfNeeded: noop,
  removeCard: noop,
  changeCardProperty: noop,
  completeCard: noop,
  shouldShowDeckNotif: true,
  setShouldShowDeckNotif: noop,
  initialFeedLength: 0,
});

export const useFeedContextProvider = () => useContext(FeedContext);

export const useCardInteractions = () => {
  const { removeCard, changeCardProperty, completeCard } = useFeedContextProvider();

  const actions = {
    removeCard(cardId: string) {
      removeCard(cardId);
    },

    changeCardProperty(cardId: string, key: string, value: string | boolean) {
      changeCardProperty(cardId, key, value);
    },

    completeCard(cardId: string) {
      completeCard(cardId);
    },
  };
  return actions;
};

export const FeedProvider: React.FC = ({ children }) => {
  // const feedCards = useSelector((state: any) => state.feed?.fetchedCards?.cards);

  const [fetchingFeedCards, setFetchingFeedCards] = useState(false);
  const [fetchEndorsementPromise, setFetchEndorsementPromise] = useState<null | Promise<void>>(
    null,
  );
  const [hasFetchedEndorsements, setHasFetchedEndorsements] = useState(false);

  const [initialFeedCards, setInitialFeedCards] = useState<Card[]>([]);
  const [endorsementCards, setEndorsementCards] = useState<MinimalEndorsementCard[]>([]);
  const [completedCards, setCompletedCards] = useState<Set<string>>(new Set());
  const [shouldShowDeckNotif, setShouldShowDeckNotif] = useState(true);
  const [removedCards, setRemovedCards] = useState<string[]>([]);
  const [changedPropertyCards, setChangedPropertyCards] = useState<any>({});

  const processFeedCards = async () => {
    const res = await getFeed();
    if (res.ok) {
      await fetchEndorsementCards();
      setInitialFeedCards(res.getJson?.cards || []);
      setCompletedCards(new Set(res.getJson?.completed_card_ids));
      setShouldShowDeckNotif(res.getJson?.should_show_deck_notif);
    }
  };

  const fetchEndorsementCards = () => {
    if (fetchEndorsementPromise) {
      return fetchEndorsementPromise;
    }
    const promise = processEndorsementCards();
    setFetchEndorsementPromise(promise);
    return promise;
  };

  const processEndorsementCards = async () => {
    const res = await getEndorsementFeed(30, true);
    if (res.ok) {
      const newEndorsementCards = [...endorsementCards, ...res.getJson.cards];
      setEndorsementCards(unique(newEndorsementCards, ({ card_id }) => card_id));
      setHasFetchedEndorsements(true);
    }
    setFetchEndorsementPromise(null);
  };

  useMountEffect(() => {
    if (PREFETCH_URLS.includes(window.location.pathname) && !fetchingFeedCards) {
      setFetchingFeedCards(true);
      processFeedCards().then(() => setFetchingFeedCards(false));
    }
  });

  const fetchFeedCardsIfNeeded = async () => {
    if (!fetchingFeedCards) {
      setFetchingFeedCards(true);
      await processFeedCards();
      setFetchingFeedCards(false);
    }
  };

  const removeCard = (cardId: string) => {
    setRemovedCards([...removedCards, cardId]);
  };

  const changeCardProperty = (cardId: string, key: string, value: string | boolean) => {
    const newCards = {
      ...changedPropertyCards,
      [cardId]: {
        ...changedPropertyCards[cardId],
        [key]: value,
      },
    };
    setChangedPropertyCards(newCards);
  };

  const completeCard = async (cardId: string) => {
    const res = await postFeedDeckAnswer({ cardId, status: true });
    if (res.ok) {
      const newCompletedCards = new Set([...completedCards, cardId]);
      const isLastCard =
        newCompletedCards.size === initialFeedCards.length + endorsementCards.length;

      if (newCompletedCards.size >= initialFeedCards.length + endorsementCards.length - 10) {
        if (isLastCard) {
          await fetchEndorsementCards();
        } else {
          fetchEndorsementCards();
        }
      }

      setCompletedCards(newCompletedCards);
    }
  };

  const [feedCardImages, setFeedCardImages] = useState<{ [imageName: string]: string }>({});

  useEffect(() => {
    // Fetch and store feedcards once.
    if (initialFeedCards.length && Object.keys(feedCardImages).length === 0) {
      setFeedCardImages(initialFeedCards.reduce(getFeedCardImages, {}));
    }
  }, [initialFeedCards]);

  const [impressionsState, setImpressionsState] = useState<Impression[]>([]);

  useInterval(() => {
    const sendImpressions = async (impressions: Impression[]) => {
      postImpressions({ impressions });
    };

    const impressions = [...impressionsState];
    setImpressionsState([]); // Reset early to avoid any additions during await.
    if (impressions && impressions.length) {
      sendImpressions(impressions);
    }
  }, 5000);

  const receiveImpression = (id: string) => {
    const newImpression: Impression = { id, timestamp: moment().valueOf() / 1000 };
    setImpressionsState([...impressionsState, newImpression]);
  };

  const feedContextValue = {
    receiveImpression,
    feedCards: !hasFetchedEndorsements ? [] : [...initialFeedCards, ...endorsementCards],
    completedCards,
    removeCard,
    changeCardProperty,
    completeCard,
    fetchFeedCardsIfNeeded,
    shouldShowDeckNotif,
    setShouldShowDeckNotif,
    initialFeedLength: initialFeedCards.length,
  };

  return (
    <FeedContext.Provider value={feedContextValue}>
      <AssetPrefetcher images={feedCardImages} />
      {children}
    </FeedContext.Provider>
  );
};
