import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';

import { FlexColumn, Heading2, margins } from 'css/css';

import { Modal } from 'js/components/shared/NewModal';
import { Stars } from 'js/components/shared/selectors/Stars';

import { useCallContext } from './CallContext';
import { useCallPermissionsContext } from './CallPermissionsContext';

export const CallFeedbackModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [didMatchJoin, setDidMatchJoin] = useState(false);
  const { call, startTime, feedbackCode, match } = useCallContext();
  const { endCall } = useCallPermissionsContext();

  const numParticipants = Object.values(call?.participants() ?? {}).length;
  const isMatchInCall = Object.values(call?.participants() ?? {}).some(
    participant => participant.user_name === match?.profile_id,
  );

  useEffect(() => {
    if (!didMatchJoin && isMatchInCall) {
      setDidMatchJoin(true);
    }
  }, [isMatchInCall, match]);

  useEffect(() => {
    // Show feedback modal when the participant leaves after 20mins
    const timeRemaining = moment.utc(moment()).diff(moment.utc(startTime), 'minutes');
    const didMatchLeave = didMatchJoin && numParticipants === 1;

    if (feedbackCode && startTime && didMatchLeave && timeRemaining >= 20) {
      setShowModal(true);
    }
  }, [feedbackCode, startTime, numParticipants, didMatchJoin]);

  return (
    <StyledModal showModal={showModal} handleClose={() => setShowModal(false)}>
      <FlexColumn>
        <Heading2 style={{ margin: `${margins.size3} 0px`, textAlign: 'center' }}>
          How relevant was your match with {match?.first_name || ''}?
        </Heading2>
        <Stars
          updateStarCount={(n: number) => endCall(n)}
          starSize="58px"
          containerStyle={{ margin: `${margins.size3} 0px` }}
        />
      </FlexColumn>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  padding-top: 0;
  align-items: center;
  > div {
    overflow-y: auto;
  }
`;
