import React, { useState } from 'react';

import { colors, Heading2, margins, Text } from 'css/css';

import { apiFailure } from 'js/util/strings';
import { useNotifContext } from 'js/util/notif-context';
import { postPersonalityQuestionResponse, postSurveyQuestionResponse } from 'js/util/api';
import { ColoredClickable, DeckCardContainer } from 'js/components/homepage/styles';
import {
  Buttons,
  Emoji,
  feedActionWrapper,
  yesNoButtons,
} from 'js/components/homepage/shared/DeckCardButtons';
import { AllCardTypes, FeedSession, QuestionCards } from 'js/components/homepage/types';
import { CARD_TRANSITION_LEAVE_DURATION } from 'js/components/homepage/constants';

interface QuestionCard {
  card: QuestionCards;
  completeCard: (cardId: string) => void;
}

export const QuestionCard: React.FC<QuestionCard> = ({ card, completeCard }) => {
  const { showNotif } = useNotifContext();

  const [selected, setSelected] = useState<number | null>();
  const [loading, setLoading] = useState(false);

  const { card_type: cardType, data, card_id: cardId } = card;
  const postQuestionResponse =
    cardType === AllCardTypes.Personality
      ? postPersonalityQuestionResponse
      : postSurveyQuestionResponse;

  const { question: questionText, question_id: questionId, options } = data;
  const postResponse = (optionId: number | null) =>
    postQuestionResponse({
      questionId,
      optionId,
    });

  const clickOption = async (optionId: number | null) => {
    if (loading || answered) return;
    setLoading(true);
    const res = await postResponse(optionId);
    if (res.ok) {
      setSelected(optionId);
      setTimeout(() => completeCard(cardId), CARD_TRANSITION_LEAVE_DURATION);
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
    setLoading(false);
  };

  const skipQuestionText = 'Skip question';
  const skipQuestion = feedActionWrapper(
    () => {
      clickOption(null);
    },
    skipQuestionText,
    card.id,
  );

  const answered = selected !== undefined;
  const optionIds = Object.keys(options);
  const numOfOptions = optionIds.length;

  const isYesNoQuestion = card.card_type === AllCardTypes.Survey && card.data.is_binary_options;
  const noId = Number(optionIds[0]);
  const yesId = Number(optionIds[1]);

  const feedSession: FeedSession =
    cardType === AllCardTypes.Personality ? 'Personality' : 'Regular';
  const isEmojiQuestion = feedSession === 'Personality' || isYesNoQuestion;

  const buttonEmojis: Emoji[] = ['Sad', 'Okay', 'Meh', 'SlightSmile', 'Happy'];
  const buttons: Buttons =
    isEmojiQuestion && !isYesNoQuestion
      ? {
          kind: 'emoji',
          values: Object.keys(options)
            .map(Number)
            .map((choiceId: number, i: number) => ({
              handleClick: () => clickOption(choiceId),
              labelAbove: options[choiceId][1],
              labelBelow: options[choiceId][0],
              activeColor:
                i / numOfOptions < 1 / 3
                  ? colors.tertiary2Main
                  : i / numOfOptions < 2 / 3
                  ? colors.tertiary3Main
                  : colors.tertiary1Main,
              emoji: buttonEmojis[i],
            })),
        }
      : isEmojiQuestion && isYesNoQuestion
      ? {
          kind: 'emoji',
          values: yesNoButtons(b => clickOption(b ? yesId : noId), {
            no: options[noId][0],
            yes: options[yesId][0],
          }),
        }
      : {
          kind: 'regular',
          values: Object.keys(options)
            .map(Number)
            .map((choiceId: number) => ({
              handleClick: () => clickOption(choiceId),
              label: options[choiceId][0],
            })),
        };

  return (
    <DeckCardContainer
      deckCardId={card.id}
      isLoading={loading}
      isSelected={answered}
      buttons={buttons}
      feedSession={feedSession}
      minDesktopHeight="325px"
    >
      {cardType === AllCardTypes.Personality && (
        <Text style={{ marginBottom: margins.size2, color: colors.blackLight }}>
          How accurately does this describe you?
        </Text>
      )}
      <Heading2 style={{ textAlign: 'center' }}>{questionText}</Heading2>
      {cardType === AllCardTypes.Survey && (
        <ColoredClickable onClick={skipQuestion} style={{ marginTop: margins.size2 }}>
          <Text style={{ textDecoration: 'underline' }}>{skipQuestionText}</Text>
        </ColoredClickable>
      )}
    </DeckCardContainer>
  );
};
