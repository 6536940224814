import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { FlexColumn, media } from 'css/css';

import { useDidUpdateEffect } from 'js/util/custom-hooks';
import {
  AllowedEndorsements,
  getAllowedEndorsements,
  getEndorsementFeed,
  postTestActivity,
} from 'js/util/api';
import { getIsMobile } from 'js/util/util';
import { Backdrop } from 'js/components/shared/MobileModal';
import { MinimalEndorsementCard, MinimalUserDict } from 'js/components/homepage/types';
import { useTestBuckets } from 'js/providers/UserProvider';
import { unique } from 'js/util/unique';
import { useDataContext } from 'js/providers/DataContextProvider';

import { Feed } from './Feed';
import { Intro } from './Intro';
import { EndorsementSender } from './EndorsementSender';
import {
  MODAL_LARGE_TRANSITION_DURATION,
  MODAL_SMALL_TRANSITION_DURATION,
  NUMBER_OF_AVATARS,
  SMALL_NUM_OF_ENDORSEMENT_SUGGESTIONS,
  LARGE_NUM_OF_ENDORSEMENT_SUGGESTIONS,
} from './constants';

type EndorsementModalStates =
  | 'Closed'
  | 'Opening'
  | 'Intro'
  | 'Feed'
  | 'ClosingFromFeed'
  | 'EndorsementSender'
  | 'ClosingFromEndorsementSender';

export const getModalTransitionDuration = (isFullScreen: boolean) =>
  isFullScreen ? MODAL_LARGE_TRANSITION_DURATION : MODAL_SMALL_TRANSITION_DURATION;

export const useEndorsementModal = () => {
  const { endorsementFeedTest } = useTestBuckets();
  const {
    wasEndorsementModalImmediatelyClosed,
    setWasEndorsementModalImmediatelyClosed,
  } = useDataContext();

  const [state, setState] = useState<EndorsementModalStates>('Closed');
  const [feedCards, setFeedCards] = useState<MinimalEndorsementCard[]>([]);
  const [allowedEndorsements, setAllowedEndorsements] = useState<AllowedEndorsements[]>([]);
  const [endorsementCategories, setEndorsementCategories] = useState<string[]>([]);
  const [sampleUsers, setSampleUsers] = useState<MinimalUserDict[]>([]);
  const [canBeClosed, setCanBeClosed] = useState(false);
  const modalContainerRef = useRef<HTMLDivElement>(null);

  const isOpen = state === 'Intro' || state === 'Feed' || state === 'EndorsementSender';
  const shouldDisplayFeed = state === 'Feed' || state === 'ClosingFromFeed';
  const shouldDisplayEndorsementSender =
    state === 'EndorsementSender' || state === 'ClosingFromEndorsementSender';
  const isMobile = getIsMobile();
  const isFullScreen = shouldDisplayEndorsementSender || shouldDisplayFeed || !isMobile;
  const transitionDuration = getModalTransitionDuration(isFullScreen);

  // returns unique endorsement categories in ascending order in terms of length
  const getEndorsementCategories = (cards: MinimalEndorsementCard[]) => {
    const cardCategories = cards.map(card => card.data.category);
    const uniqueCategories = unique(cardCategories, x => x);
    return uniqueCategories.sort((a, b) => a.length - b.length);
  };

  // (tries to) returns 'numOfUsers' unique users that have pictures with the original order of 'cards'
  const getSampleUsers = (cards: MinimalEndorsementCard[], numOfUsers: number) => {
    const users = cards.map(card => card.data.user);
    const usersWithPictures = users.filter(user => user.image !== '');

    const uniqueUsers = unique(usersWithPictures, user => user.profile);
    return uniqueUsers.slice(0, numOfUsers);
  };

  const closeModal = () => {
    if (canBeClosed && (state === 'Intro' || state === 'Feed' || state === 'EndorsementSender')) {
      if (state === 'Intro') {
        setWasEndorsementModalImmediatelyClosed(true);
        setState('Closed');
      } else if (state === 'Feed') {
        setState('ClosingFromFeed');
      } else {
        setState('ClosingFromEndorsementSender');
      }
    }
  };

  const openModal = () => {
    setState('Intro');
  };

  const loadEndorsementFeed = async () => {
    const numOfEndorsementSuggestions = endorsementFeedTest
      ? LARGE_NUM_OF_ENDORSEMENT_SUGGESTIONS
      : SMALL_NUM_OF_ENDORSEMENT_SUGGESTIONS;
    const res = await getEndorsementFeed(numOfEndorsementSuggestions, false);

    if (res.ok) {
      const { cards } = res.getJson;
      if (cards.length === 0) {
        setState('Closed');
      } else {
        setFeedCards(cards);
        setEndorsementCategories(getEndorsementCategories(cards));
        setSampleUsers(getSampleUsers(cards, NUMBER_OF_AVATARS));
        openModal();
      }
    }
  };

  const loadAllowedEndorsements = async () => {
    const res = await getAllowedEndorsements();
    if (res.ok) {
      setAllowedEndorsements(res.getJson);
    }
  };

  useDidUpdateEffect(() => {
    if (state === 'Opening') {
      loadEndorsementFeed();
      loadAllowedEndorsements();
      if (window.bridge) {
        window.bridge.setShrinkViewportForKeyboard(false);
      }
    }
    if (
      state === 'Closed' ||
      state === 'ClosingFromFeed' ||
      state === 'ClosingFromEndorsementSender'
    ) {
      if (window.bridge) {
        window.bridge.setShrinkViewportForKeyboard(true);
      }
    }

    postTestActivity('endorsement-modal', state);
  }, [state]);

  const tryToOpenModal = () => {
    if (!wasEndorsementModalImmediatelyClosed) {
      setState('Opening');
    }
  };
  const modal = (
    <>
      <Backdrop
        isOpen={isOpen}
        onClick={() => {
          if (isMobile) {
            closeModal();
          }
        }}
        transitionDuration={transitionDuration}
        blur={15}
        style={{ zIndex: 4 }}
      />
      <ModalContainer
        $isOpen={isOpen}
        $isFullScreen={isFullScreen}
        ref={modalContainerRef}
        onTransitionEnd={event => {
          if (modalContainerRef.current === event.target) {
            setCanBeClosed(b => !b);
          }
        }}
      >
        {shouldDisplayFeed ? (
          <Feed
            closeModal={closeModal}
            cards={feedCards}
            deckCompletedCallback={() => {
              if (allowedEndorsements.length > 0) {
                setState('EndorsementSender');
              }
            }}
          />
        ) : shouldDisplayEndorsementSender ? (
          <EndorsementSender allowedEndorsements={allowedEndorsements} closeModal={closeModal} />
        ) : (
          <Intro
            handleClick={() => setState('Feed')}
            closeModal={closeModal}
            sampleUsers={sampleUsers}
            endorsementCategories={endorsementCategories}
          />
        )}
      </ModalContainer>
    </>
  );

  return [tryToOpenModal, modal] as const;
};

export const ModalContainer = styled(FlexColumn)<{ $isOpen: boolean; $isFullScreen: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transform: ${p => (p.$isOpen ? 'translate(0, 0)' : 'translate(0, 100%)')};
  transition: transform ${p => getModalTransitionDuration(p.$isFullScreen)}ms ease-in-out;
  ${p => p.$isFullScreen && 'height: 100%'};
  max-height: 100%;
  overflow-y: auto;
  border-radius: 10px 10px 0px 0px;
  ${media.desktop} {
    width: 600px;
    margin: 0 auto;
    justify-content: center;
  }
`;
