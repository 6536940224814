import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import Select, { OptionTypeBase, components } from 'react-select';
import SVG from 'react-inlinesvg';

import { margins, colors, fonts } from 'css/css';

import { filterOptions, useAutoCompleteSearch } from 'js/util/react-select';
import { SmallArrow } from 'js/components/shared/SmallArrow';

import searchIcon from 'img/shared/search.svg';

type CitySelectProps = {
  onSubmitCustom: (googlePlaceId: string) => void;
};

const searchResultsToOptions = (results: any) =>
  results.map((res: any) => ({ label: res.description, value: res.place_id }));

export const CitySelect: React.FC<CitySelectProps> = ({ onSubmitCustom }) => {
  const { query, setSearchQuery, searchResults, isDebouncing, error } = useAutoCompleteSearch();

  const didLoseFocus = useRef(false);

  return (
    <StyledSelect
      placeholder="Find your city!"
      inputValue={query}
      theme={(theme: any) => ({
        ...theme,

        colors: {
          ...theme.colors,
          primary: colors.blackLight,
          primary50: colors.blackLight,
        },
      })}
      isLoading={isDebouncing}
      isDisabled={error}
      options={searchResultsToOptions(searchResults)}
      menuIsOpen={searchResults.length > 0 && !isDebouncing}
      onChange={(locale: OptionTypeBase) => onSubmitCustom(locale.value)}
      filterOption={filterOptions}
      onInputChange={(input: string) => {
        if (!didLoseFocus.current) {
          setSearchQuery(input);
        }
      }}
      onBlur={() => {
        didLoseFocus.current = true;
      }}
      onFocus={() => {
        didLoseFocus.current = false;
      }}
      styles={cityStyles}
      components={{
        Input: CustomInput,
        Option: CustomOption,
        NoOptionsMessage: () => null, // NoOptionsMessage should never be displayed since the menu is closed when there are no search results
      }}
    />
  );
};

const CustomInput = (props: any) => (
  <>
    <StyledSearchIcon />
    <components.Input {...props} />
  </>
);

const CustomOption = (props: any) => {
  const { isFocused } = props;
  return (
    <div
      style={{
        padding: margins.size1,
        backgroundColor: isFocused ? colors.greyMain : '',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <components.Option {...props} />
        <SmallArrow
          style={{ marginRight: margins.size3 }}
          direction="right"
          color={colors.blackMid}
        />
      </div>
    </div>
  );
};

const StyledSearchIcon = styled(SVG).attrs({ src: searchIcon, alt: 'Search Icon' })`
  width: 18px;
  height: 17px;
  & > circle {
    stroke: ${colors.blackMain};
  }
  & > line {
    stroke: ${colors.blackMain};
  }
`;

const StyledSelect = styled(Select)`
  width: 250px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  color: ${colors.blackLight};
`;

const cityStyles = {
  input: (provided: any) => ({
    ...provided,
    color: colors.blackMid,
    marginLeft: margins.size3,
    display: 'flex',
    flexGrow: 1,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: colors.blackMid,
    fontFamily: fonts.semiBold,
    textAlign: 'left',
    backgroundColor: state.isFocused ? colors.greyMain : '',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: colors.blackMid,
    fontFamily: fonts.semiBold,
    marginLeft: '34px', // Search Icon width + Input's left margin
  }),
  singleValue: (provided: any) => ({
    ...provided,
    marginLeft: '34px', // Search Icon width + Input's left margin
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  control: (provided: any) => ({
    ...provided,
    borderStyle: 'none',
    backgroundColor: colors.greyLight,
    padding: margins.size2,
    borderRadius: '5px',
  }),
};
