import React from 'react';
import styled from 'styled-components/macro';

import { ClickableCard, FlexRow, margins, CardText, colors } from 'css/css';

import { hubArtIcons } from 'js/util/util';
import { SmallArrow } from 'js/components/shared/SmallArrow';

export const City = ({ locale, onClick }: any) => {
  const graphicImg = hubArtIcons.find((h: any) => h.id === locale.id)?.imgSrc;

  return (
    graphicImg && (
      <CityCard onClick={() => onClick(locale.id)}>
        <img src={graphicImg} alt={locale.name} />

        <FlexRow>
          <CardText>{locale.name}</CardText>

          <SmallArrow
            direction="right"
            color={colors.blackMid}
            style={{ marginLeft: margins.size3, marginTop: '2px' }}
          />
        </FlexRow>
      </CityCard>
    )
  );
};

const CityCard = styled(ClickableCard)`
  margin-bottom: ${margins.size1};
  padding-left: ${margins.size4};
  padding-right: ${margins.size3};
  justify-content: space-between;
`;
