import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';

import {
  colors,
  FlexRow,
  globalTransitionSettings,
  margins,
  media,
  FlexColumn,
  SubText,
  Text,
  Heading3,
  Clickable,
} from 'css/css';

import { Button } from 'js/components/shared/Button';
import { postFeedAction } from 'js/util/api';
import { AutoselectableOption } from 'js/components/shared/AutoselectableOption';

import {
  ThumbsDownEmoji,
  ThumbsUpEmoji,
  ThumbsDownEmojiActive,
  ThumbsUpEmojiActive,
  SadEmojiActive,
  SadEmoji,
  OkayEmoji,
  MehEmojiActive,
  SlightSmileEmojiActive,
  SlightSmileEmoji,
  MehEmoji,
  OkayEmojiActive,
  HappyEmoji,
  HappyEmojiActive,
} from 'img/homepage/feed';

import { FeedActionType } from '../types';
import { ColoredClickable } from '../styles';

export const feedActionWrapper = (
  handleClick: () => void,
  response: string,
  deckCardId?: number,
) => async () => {
  if (deckCardId) {
    await postFeedAction(deckCardId, { type: FeedActionType.Respond, response });
  }
  handleClick();
};

export type Emoji = 'ThumbsDown' | 'ThumbsUp' | 'Sad' | 'Okay' | 'Meh' | 'SlightSmile' | 'Happy';

const emojiSource = (emoji: Emoji, isActive: boolean) => {
  switch (emoji) {
    case 'ThumbsDown':
      return isActive ? ThumbsDownEmojiActive : ThumbsDownEmoji;
    case 'ThumbsUp':
      return isActive ? ThumbsUpEmojiActive : ThumbsUpEmoji;
    case 'Sad':
      return isActive ? SadEmojiActive : SadEmoji;
    case 'Okay':
      return isActive ? OkayEmojiActive : OkayEmoji;
    case 'Meh':
      return isActive ? MehEmojiActive : MehEmoji;
    case 'SlightSmile':
      return isActive ? SlightSmileEmojiActive : SlightSmileEmoji;
    default:
      return isActive ? HappyEmojiActive : HappyEmoji;
  }
};

type RegularButton = {
  handleClick: () => void;
  label: string;
};

type EmojiButton = {
  handleClick: () => void;
  emoji: Emoji;
  labelBelow: string;
  labelAbove: string;
  activeColor: string;
};

type SeparateButton = { handleClick: () => void; label: string; icon?: string };

export type Buttons =
  | { kind: 'emoji'; values: EmojiButton[]; isCentered?: boolean; handleSkip?: () => void }
  | { kind: 'regular'; values: RegularButton[] }
  | { kind: 'separate'; values: SeparateButton[] };

export const DeckCardButtons = ({
  deckCardId,
  buttons,
}: {
  deckCardId?: number;
  buttons: Buttons;
}) =>
  buttons.kind === 'regular' ? (
    <RegularButtons deckCardId={deckCardId} buttons={buttons.values} />
  ) : buttons.kind === 'separate' ? (
    <SeparateButtons deckCardId={deckCardId} buttons={buttons.values} />
  ) : (
    <EmojiButtons
      deckCardId={deckCardId}
      buttons={buttons.values}
      isCentered={buttons.isCentered ?? false}
      handleSkip={buttons.handleSkip}
    />
  );

const RegularButtons = ({
  deckCardId,
  buttons,
}: {
  deckCardId?: number;
  buttons: RegularButton[];
}) => {
  const [selected, setSelected] = useState<number>();

  return (
    <RegularButtonsContainer>
      {buttons.map((button, i) => {
        const handleClick = feedActionWrapper(
          () => {
            setSelected(i);
            button.handleClick();
          },
          button.label,
          deckCardId,
        );

        return (
          <AutoselectableOption
            key={button.label}
            selected={selected === i}
            onClick={handleClick}
            text={button.label}
          />
        );
      })}
    </RegularButtonsContainer>
  );
};

const RegularButtonsContainer = styled(FlexColumn)`
  padding: 0 ${margins.size4} ${margins.size4} ${margins.size4};
  background-color: ${colors.whiteMain};
  border-radius: 0px 0px 10px 10px;
`;

const getSeperateButtonColor = (isSelected: boolean) =>
  isSelected ? colors.primaryDark : colors.primaryMain;
const SeparateButtons = ({
  deckCardId,
  buttons,
}: {
  deckCardId?: number;
  buttons: SeparateButton[];
}) => {
  const [selected, setSelected] = useState<number>();

  return (
    <SeparateButtonsContainer>
      {buttons.map((button, index) => {
        const { label, icon } = button;
        const isSelected = index === selected;
        const color = getSeperateButtonColor(isSelected);

        const handleClick = feedActionWrapper(
          () => {
            button.handleClick();
            setSelected(index);
          },
          label,
          deckCardId,
        );

        return icon !== undefined ? (
          <Button
            key={label}
            style={{
              marginBottom: margins.size2,
              ...(isSelected
                ? { backgroundColor: color }
                : { boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)' }),
            }}
            onClick={handleClick}
          >
            <FlexRow>
              <img src={icon} alt={label} style={{ margin: margins.size2 }} />
              {label}
            </FlexRow>
          </Button>
        ) : (
          <ClickableHeading text={label} handleClick={handleClick} isSelected={isSelected} />
        );
      })}
    </SeparateButtonsContainer>
  );
};

const SeparateButtonsContainer = styled(FlexColumn)`
  padding: 0 ${margins.size4} ${margins.size4} ${margins.size4};
  background-color: ${colors.whiteMain};
  ${media.desktop} {
    border-radius: 0px 0px 10px 10px;
  }
`;

interface ClickableHeadingProps {
  handleClick: () => void;
  isSelected: boolean;
  text: string;
}

const ClickableHeading: React.FC<ClickableHeadingProps> = ({ handleClick, isSelected, text }) => (
  <Clickable onClick={handleClick} style={{ marginTop: margins.size3 }}>
    <HoverableHeading isSelected={isSelected}>{text}</HoverableHeading>
  </Clickable>
);

const HoverableHeading = styled(Heading3)<{ isSelected: boolean }>`
  color: ${p => getSeperateButtonColor(p.isSelected)};
  ${media.desktop} {
    &:hover {
      color: ${getSeperateButtonColor(true)};
    }
  }
`;

const yesButton = (f: () => Promise<void>, label: string): EmojiButton => ({
  handleClick: f,
  emoji: 'ThumbsUp',
  labelBelow: label,
  labelAbove: '',
  activeColor: colors.tertiary1Main,
});

const noButton = (f: () => Promise<void>, label: string): EmojiButton => ({
  handleClick: f,
  emoji: 'ThumbsDown',
  labelBelow: label,
  labelAbove: '',
  activeColor: colors.tertiary2Main,
});

export const yesNoButtons = (
  f: (b: boolean) => Promise<void>,
  labels: { no: string; yes: string } = { no: 'no', yes: 'yes' },
) => [noButton(() => f(false), labels.no), yesButton(() => f(true), labels.yes)];

const EMOJI_CONTAINER_HORIZONTAL_PADDING = margins.size5;
const EMOJI_CONTAINER_HORIZONTAL_PADDING_MOBILE = margins.size4;
const EMOJI_BUTTON_SIZE = 56;
const EMOJI_DESCRIPTION_TRIANGLE_HEIGHT = parseInt(margins.size3, 10);
const EMOJI_DESCRIPTION_TRIANGLE_WIDTH = parseInt(margins.size4, 10);

interface EmojiButtonsProps {
  deckCardId?: number;
  buttons: EmojiButton[];
  isCentered: boolean;
  handleSkip?: () => void;
}

const EmojiButtons: React.FC<EmojiButtonsProps> = ({
  deckCardId,
  buttons,
  isCentered,
  handleSkip,
}) => {
  const [hoveringOrActiveOn, setHoveringOrActiveOn] = useState<number | null>(null);
  const numOfButtons = buttons.length;
  return (
    <EmojiButtonsContainer $hasTopPadding={buttons[0].labelAbove !== ''}>
      <EmojiButtonsRow $isCentered={isCentered}>
        {buttons.map((button: EmojiButton, i) => {
          const handleClick = feedActionWrapper(
            () => {
              setHoveringOrActiveOn(i);
              button.handleClick();
            },
            button.labelBelow !== '' ? button.labelBelow : button.labelAbove,
            deckCardId,
          );

          return (
            <FlexColumn style={{ width: EMOJI_BUTTON_SIZE }}>
              <EmojiDescription
                isDisplayed={hoveringOrActiveOn === i && button.labelAbove !== ''}
                isFirst={i === 0}
                isLast={i === numOfButtons - 1}
              >
                <Text style={{ color: colors.whiteMain }}>{button.labelAbove}</Text>
              </EmojiDescription>
              <EmojiButton
                onMouseEnter={() => {
                  setHoveringOrActiveOn(i);
                }}
                onClick={handleClick}
                onMouseLeave={() => {
                  setHoveringOrActiveOn(null);
                }}
                activeColor={button.activeColor}
                activeState={
                  hoveringOrActiveOn === null
                    ? 'NoOneActive'
                    : hoveringOrActiveOn === i
                    ? 'Active'
                    : 'Inactive'
                }
              >
                <EmojiIcon
                  src={emojiSource(button.emoji, hoveringOrActiveOn !== null)}
                  activeColor={button.activeColor}
                  isActive={hoveringOrActiveOn === null ? null : hoveringOrActiveOn === i}
                />
              </EmojiButton>
              <EmojiLabel isDisplayed={hoveringOrActiveOn === null}>{button.labelBelow}</EmojiLabel>
            </FlexColumn>
          );
        })}
      </EmojiButtonsRow>
      {handleSkip && (
        <ColoredClickable onClick={handleSkip} style={{ margin: `${margins.size3} 0px` }}>
          <Text style={{ textDecoration: 'underline' }}>Skip</Text>
        </ColoredClickable>
      )}
    </EmojiButtonsContainer>
  );
};

const EmojiDescription = styled.div<{ isDisplayed: boolean; isFirst: boolean; isLast: boolean }>`
  position: absolute;
  top: -30px;
  left: ${p => p.isFirst && EMOJI_CONTAINER_HORIZONTAL_PADDING};
  right: ${p => p.isLast && EMOJI_CONTAINER_HORIZONTAL_PADDING};
  background-color: ${colors.blackMid};
  border-radius: 12px;
  width: fit-content;
  padding: ${margins.size2} ${margins.size3};
  transition: ${globalTransitionSettings};
  opacity: ${p => (p.isDisplayed ? '1' : '0')};
  &:before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-top: ${EMOJI_DESCRIPTION_TRIANGLE_HEIGHT}px solid ${colors.blackMid};
    border-right: ${EMOJI_DESCRIPTION_TRIANGLE_WIDTH}px solid transparent;
    border-bottom: ${EMOJI_DESCRIPTION_TRIANGLE_HEIGHT}px solid transparent;
    border-left: ${EMOJI_DESCRIPTION_TRIANGLE_WIDTH}px solid transparent;
    bottom: -20px;
    left: ${p => p.isFirst && `${EMOJI_BUTTON_SIZE / 2 - EMOJI_DESCRIPTION_TRIANGLE_WIDTH}px`};
    left: ${p => !p.isLast && !p.isFirst && `calc(50% - ${EMOJI_DESCRIPTION_TRIANGLE_WIDTH}px)`};
    right: ${p => p.isLast && `${EMOJI_BUTTON_SIZE / 2 - EMOJI_DESCRIPTION_TRIANGLE_WIDTH}px`};
  }
  ${media.mobile} {
    left: ${p => p.isFirst && EMOJI_CONTAINER_HORIZONTAL_PADDING_MOBILE};
    right: ${p => p.isLast && EMOJI_CONTAINER_HORIZONTAL_PADDING_MOBILE};
  }
`;

const EmojiLabel = styled(SubText)<{ isDisplayed: boolean }>`
  height: 40px;
  padding-top: ${margins.size2};
  transition: ${globalTransitionSettings};
  opacity: ${p => (p.isDisplayed ? '1' : '0')};
  width: max-content;
`;

const EmojiButtonsContainer = styled(FlexColumn)<{ $hasTopPadding: boolean }>`
  position: relative;
  width: 100%;
  padding: ${p => (p.$hasTopPadding ? margins.size4 : '0px')} ${EMOJI_CONTAINER_HORIZONTAL_PADDING}
    0px ${EMOJI_CONTAINER_HORIZONTAL_PADDING};
  background-color: ${colors.whiteMain};

  ${media.mobile} {
    padding: ${p => (p.$hasTopPadding ? `calc(${margins.size2} + ${margins.size4})` : '0px')}
      ${EMOJI_CONTAINER_HORIZONTAL_PADDING_MOBILE} 0px ${EMOJI_CONTAINER_HORIZONTAL_PADDING_MOBILE};
  }
  ${media.desktop} {
    border-radius: 0px 0px 10px 10px;
  }
`;

const EmojiButtonsRow = styled(FlexRow)<{ $isCentered: boolean }>`
  width: 100%;
  justify-content: ${p => (p.$isCentered ? 'center' : 'space-between')};
  gap: ${p => (p.$isCentered ? margins.size4 : '0px')};
`;

type ActiveState = 'NoOneActive' | 'Inactive' | 'Active';

type EmojiButtonProps = {
  activeColor: string;
  activeState: ActiveState;
};

const emojiButtonColor = (
  activeState: ActiveState,
  initialColor: string,
  activeColor: string,
  inactiveColor: string,
) =>
  activeState === 'NoOneActive'
    ? initialColor
    : activeState === 'Active'
    ? activeColor
    : inactiveColor;

const EmojiButton = styled(Button)<EmojiButtonProps>`
  background-color: ${p =>
    emojiButtonColor(p.activeState, colors.whiteMain, p.activeColor, colors.greyLight)};
  width: ${EMOJI_BUTTON_SIZE}px;
  height: ${EMOJI_BUTTON_SIZE}px;
  border-radius: 36px;
  padding: 10px;
  box-shadow: ${p => p.activeState === 'NoOneActive' && '0px 4px 10px rgba(0, 0, 0, 0.15)'};
  &:hover {
    background-color: ${p =>
      emojiButtonColor(p.activeState, colors.whiteMain, p.activeColor, colors.greyLight)};
  }
`;

const EmojiIcon = styled(SVG)<EmojiButtonProps>`
  & path {
    fill: ${p => p.isActive !== null && !p.isActive && colors.blackLight};
  }
`;
