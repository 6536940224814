import React from 'react';
import { InView } from 'react-intersection-observer';

import { useFeedContextProvider } from 'js/providers/FeedProvider';

interface ImpressionTrackerProps {
  impressionId: string;
  style?: React.CSSProperties;
  handleClick?: () => void;
}

export const ImpressionTracker: React.FC<ImpressionTrackerProps> = ({
  impressionId,
  style,
  handleClick,
  children,
}) => {
  const { receiveImpression } = useFeedContextProvider();

  const trackView = (id: string) => {
    receiveImpression(id);
  };

  return (
    <InView
      key={impressionId}
      onChange={inView => inView && trackView(impressionId)}
      triggerOnce
      threshold={0.5}
      style={style}
      onClick={handleClick}
    >
      {children}
    </InView>
  );
};
