import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';

import {
  Clickable,
  colors,
  FlexColumn,
  globalTransitionSettings,
  Heading3,
  margins,
  SubText,
  Text,
  FlexRow,
  fonts,
  fontSizes,
} from 'css/css';

import Channel from 'types/channel';

import { useUser } from 'js/providers/UserProvider';
import { confirmRescheduleMeeting, convertTimeslots, confirmReconnectMeeting } from 'js/util/api';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { useNotifContext } from 'js/util/notif-context';
import { Button } from 'js/components/shared/Button';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { consolidateTimeslots, EVENT_STATUS } from 'js/util/util';

import { PendingMessage } from '../ConversationContainer';

import { sortTimeslots } from './TimeslotsConfirmationInput';

interface Props {
  timeslots: string[];
  changeTimes: () => void;
  setPendingMessage: React.Dispatch<React.SetStateAction<PendingMessage | undefined>>;
  inCall: boolean;
  isFirstTimeScheduling: boolean;
  isReconnect: boolean;
  selectedChannel: Channel;
  userTimezone: string;
  isUserScheduleInitiator: boolean;
  openScheduler: ({
    isReconnecting,
    isEditingTimes,
  }: {
    isReconnecting: boolean;
    isEditingTimes: boolean;
  }) => void;
}

export const SchedulerRequest: React.FC<Props> = ({
  timeslots,
  changeTimes,
  setPendingMessage,
  inCall,
  isFirstTimeScheduling,
  isReconnect,
  selectedChannel,
  userTimezone,
  isUserScheduleInitiator,
  openScheduler,
}) => {
  const [selectedTimeslot, setSelectedTimeslot] = useState('');
  const [consolidatedTimeslots, setConsolidatedTimeslots] = useState<Record<string, string[]>>({});
  const [isConfirming, setIsConfirming] = useState(false);

  const { updateChannel } = useChatContext();
  const { profileId, reconnectHeaderSource, matchCode, firstName } = selectedChannel;
  const { showNotif } = useNotifContext();

  const user = useUser();
  const currentTime = moment
    .utc()
    .tz(user.locale_info.timezone)
    .format('YYYY-MM-DD HH:mm:ss');

  const convertTimeslotsToUserTimezone = async (passedTimeslots: string[]) => {
    const res = await convertTimeslots({
      timeslots: passedTimeslots,
      profileId,
    });
    if (!res.ok) {
      return;
    }
    const convertedTimeslots = consolidateTimeslots(res.getJson.timeslots);
    setConsolidatedTimeslots(convertedTimeslots);
  };

  useEffect(() => {
    if (!isUserScheduleInitiator) {
      convertTimeslotsToUserTimezone(timeslots);
    } else {
      const convertedTimeslots = consolidateTimeslots(timeslots);
      setConsolidatedTimeslots(convertedTimeslots);
    }
  }, [timeslots]);

  const getHeaderCopy = () => {
    if (isUserScheduleInitiator) {
      if (isReconnect) {
        return 'You requested to reconnect';
      }
      if (isFirstTimeScheduling) {
        return 'You shared some times to meet';
      }
      return 'You requested to reschedule';
    }
    if (isReconnect) {
      return `${firstName} requested to reconnect`;
    }
    if (isFirstTimeScheduling) {
      return `${firstName} shared some times to meet`;
    }
    return `${firstName} requested to reschedule`;
  };

  const confirmTimeslot = async ({
    matchProfileId,
    matchId,
    timeslot,
    isReconnecting,
    source,
  }: {
    matchProfileId: string | undefined;
    matchId: string | null;
    timeslot: string;
    isReconnecting: boolean;
    source: number | null;
  }) => {
    const showError = () => {
      showNotif({
        message:
          'There is a technical issue, please try again. If issues persist, contact support@lunchclub.ai',
        level: 'error',
      });
    };

    if (!matchProfileId || !matchId || !timeslot) {
      showError();
      return;
    }

    setIsConfirming(true);
    const timeslotInUTC = moment
      .tz(timeslot, user.locale_info.timezone)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');

    const res = isReconnecting
      ? await confirmReconnectMeeting({
          matchId,
          timeslot,
          source,
        })
      : await confirmRescheduleMeeting({ matchId, timeslot });
    if (res.ok) {
      setPendingMessage({
        profileId: matchProfileId,
        message: '',
        messageAttributes: {
          inCall,
          confirmedTimeslot: timeslotInUTC,
          isReconnecting,
          isScheduling: false,
        },
        conversationAttributes: {
          isScheduling: false,
          isReconnecting: false,
          hasPrevScheduled: true,
          ...(!isReconnecting && { metDate: timeslotInUTC }),
          ...(isReconnecting && { reconnectDate: timeslotInUTC }),
        },
      });
      if (selectedChannel) {
        updateChannel(matchProfileId, (ch: Channel) => ({
          ...ch,
          metDate: timeslot,
          isMatchPreviouslyRescheduled: true,
          userEventStatus: EVENT_STATUS.confirmed,
          matchUserEventStatus: EVENT_STATUS.confirmed,
        }));
      }
    } else {
      showError();
    }
    setIsConfirming(false);
  };

  const cancelSchedule = async () => {
    setPendingMessage({
      profileId,
      message: '',
      messageAttributes: {
        inCall: false,
        canceledSchedule: true,
        isReconnecting: isReconnect,
        isScheduling: false,
      },
      conversationAttributes: { isScheduling: false },
    });
  };

  if (!selectedChannel) {
    return null;
  }

  return (
    <>
      <Heading3 style={{ marginRight: margins.size4 }}>{getHeaderCopy()}</Heading3>

      <Text>
        {isUserScheduleInitiator
          ? 'Your selected times'
          : `Select a time to meet! These times are in ${userTimezone}`}
      </Text>
      {sortTimeslots(Object.keys(consolidatedTimeslots)).map(t => (
        <FlexColumn
          style={{ width: '100%', textAlign: isUserScheduleInitiator ? 'left' : 'center' }}
        >
          <Text color={colors.blackMain} style={{ width: '100%', marginTop: margins.size3 }}>
            {t}
          </Text>
          <TimeslotsContainer $isUserScheduleInitiator={isUserScheduleInitiator}>
            {consolidatedTimeslots[t].map((time: string) => {
              const hasTimePassed = moment(time).isBefore(moment(currentTime));
              const isTimeslotInvalid = isUserScheduleInitiator || hasTimePassed;
              return (
                <Timeslot
                  $isUserScheduleInitiator={isUserScheduleInitiator}
                  noOutline={isTimeslotInvalid || !(selectedTimeslot === time)}
                  $isInvalid={isTimeslotInvalid}
                  $isSelected={selectedTimeslot === time}
                  onClick={() => {
                    if (!isUserScheduleInitiator && !hasTimePassed) {
                      setSelectedTimeslot(prev => (prev === time ? '' : time));
                    }
                  }}
                >
                  <TimeslotText
                    $isSelected={selectedTimeslot === time}
                    $hasPassed={hasTimePassed}
                    $isUserScheduleInitiator={isUserScheduleInitiator}
                  >
                    {moment(time).format('h:mm a')}
                  </TimeslotText>
                </Timeslot>
              );
            })}
          </TimeslotsContainer>
        </FlexColumn>
      ))}
      {isUserScheduleInitiator ? (
        <FlexRow style={{ marginTop: margins.size4 }}>
          <Button secondary style={{ marginRight: margins.size1 }} onClick={() => cancelSchedule()}>
            Cancel {isFirstTimeScheduling ? 'schedule' : isReconnect ? 'reconnect' : 'reschedule'}
          </Button>
          <Button
            secondary
            onClick={() => openScheduler({ isReconnecting: true, isEditingTimes: true })}
          >
            Edit times
          </Button>
        </FlexRow>
      ) : (
        <>
          {isConfirming ? (
            <div style={{ alignSelf: 'center', marginTop: margins.size3 }}>
              <SVGLoader center={false} small />
            </div>
          ) : (
            <ButtonsContainer>
              {selectedTimeslot ? (
                <Button
                  large
                  invalid={!selectedTimeslot || isConfirming}
                  onClick={() =>
                    confirmTimeslot({
                      matchProfileId: profileId,
                      matchId: matchCode,
                      timeslot: selectedTimeslot,
                      isReconnecting: isReconnect,
                      source: reconnectHeaderSource,
                    })
                  }
                >
                  Confirm
                </Button>
              ) : (
                <>
                  <SubText>None of these times work?</SubText>
                  <Clickable
                    onClick={changeTimes}
                    style={{
                      color: colors.blackMain,
                      borderBottom: `1px solid ${colors.blackMain}`,
                    }}
                  >
                    Select more availabilities
                  </Clickable>
                </>
              )}
            </ButtonsContainer>
          )}
        </>
      )}
    </>
  );
};

const TimeslotsContainer = styled(FlexColumn)<{ $isUserScheduleInitiator: boolean }>`
  width: 100%;
  margin-top: ${margins.size2};
  overflow-y: scroll;
  ${p =>
    p.$isUserScheduleInitiator && `flex-direction: row; flex-wrap: wrap; gap: ${margins.size2}`}
`;

const TimeslotText = styled(Heading3)<{
  $isSelected: boolean;
  $hasPassed: boolean;
  $isUserScheduleInitiator: boolean;
}>`
  color: ${p =>
    p.$isSelected ? colors.whiteMain : p.$hasPassed ? colors.blackLight : colors.primaryMain};
  transition: ${globalTransitionSettings};
  ${p =>
    p.$isUserScheduleInitiator &&
    `color: ${colors.blackMain}; font-family: ${fonts.regular}; font-size: ${fontSizes.size2}`}
`;

const Timeslot = styled(Clickable)<{
  $isSelected: boolean;
  $isInvalid: boolean;
  $isUserScheduleInitiator: boolean;
}>`
  padding: ${margins.size2};
  width: 100%;
  background-color: ${p => (p.$isSelected ? colors.primaryMain : colors.greyLight)};
  transition: ${globalTransitionSettings};
  height: 44px;
  ${p => p.$isInvalid && 'cursor: default;'}
  &:first-child {
    border-radius: 10px 10px 0px 0px;
  }
  &:last-child {
    border-radius: 0px 0px 10px 10px;
  }
  &:only-child {
    border-radius: 10px;
  }

  ${p =>
    p.$isUserScheduleInitiator &&
    `width: 100px;  
    border-radius: 10px;
     &:first-child {
       border-radius: 10px;
  }
     &:last-child {
       border-radius: 10px;
  }; 
 `}
`;

const ButtonsContainer = styled(FlexColumn)`
  margin-top: ${margins.size4};
  width: 100%;
  height: 45px;
`;
