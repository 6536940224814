import styled from 'styled-components/macro';

import {
  borders,
  colors,
  FlexColumn,
  FlexRow,
  globalTransitionSettings,
  Heading3,
  margins,
} from 'css/css';

import { Arrow } from 'js/components/shared/SmallArrow';

export const ChooseText = styled(Heading3)`
  color: ${colors.blackMain};
  transition: ${globalTransitionSettings};
  white-space: nowrap;
`;

type SelectButtonType = {
  selectedLabel?: string;
};
export const SelectButton = styled(FlexRow)<SelectButtonType>`
  ${p => p.selectedLabel && `display: none`};
  transition: ${globalTransitionSettings};
  padding: ${p => (p.selectedLabel ? `${margins.size2} ${margins.size3}` : margins.size2)};
  justify-content: center;
  border: ${borders.variation};
  background-color: ${colors.whiteMain};
  border-radius: 5px;
  &:hover ${ChooseText} {
    color: ${colors.whiteMain};
  }
  &:hover ${Arrow} {
    & > path {
      fill: ${colors.whiteMain};
    }
  }
`;

export const SelectMenu = styled(FlexColumn)`
  position: absolute;
  top: 54px;
  left: 0;
  background-color: ${colors.whiteMain};
  width: 100%;
  border-radius: 5px;
  border: ${borders.variation};
  z-index: 2;
  max-height: 150px;
  overflow: scroll;
  padding: ${margins.size1} 0px;
`;

export const SelectOption = styled(FlexRow)`
  width: 100%;
  height: 32px;
  min-height: 32px;
  padding: ${margins.size2} ${margins.size4};
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: ${colors.greyLight};
  }
`;

export const CheckIconWrapper = styled.div`
  width: '16px';
  margin-left: 0;
`;
