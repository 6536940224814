import { Endorsement } from './endorsements';
import { Objective } from './objectives';
import { UserInterest } from './interests';

interface LocaleInfo {
  id: number;
  is_active: number;
  is_featured: number;
  locale_block_expire: string;
  name: string;
  neighborhoods: any[]; // TODO TYPE.
  timezone: string;
  video_neighborhood_id: number;
}

export interface ProductUser extends BaseUser {
  blocked: boolean;
  network_type: 0 | 1 | 2 | 3 | 4;
  is_active: boolean;
}

export interface UserMe extends BaseUser {
  // Set by user
  email: string;
  primary_google_email: string;
  secondary_google_emails: string[];
  objective_ids: Objective[];
  objective_info: any[]; // TODO
  visual_settings: {
    [key: string]: boolean | string | string[];
  };
  snooze_until: string;
  is_involuntary_invites_disabled: boolean;
  is_reconnect_prescheduler_disabled: boolean;
  is_morpheus: boolean;
  autopilot_paused_until: string | null;

  // Derived by us
  invite_code: string;
  clubpoints: number;
  user_type: 2 | 3 | 4;
  was_matched: boolean;
  has_google: boolean;
  has_user_connected_contacts: boolean;
  is_banned: number; // TODO: cast to boolean;
  test_bucket: number; // TODO: REMOVE
  verified: boolean; // TODO: REMOVE

  last_match: ProductUser | null;
}

interface BaseUser {
  // Updated by user
  first_name: string;
  last_name: string;
  image: string;
  headline: string;
  title: string;
  company: string;
  organizations: number[]; // Communities
  interests: UserInterest[];

  ask_me_about: string;
  just_learned: string;
  like_to_learn: string;
  side_hustle: string;
  top_of_mind: string;

  twitter_url: string;
  github_url: string;
  angellist_url: string;
  instagram_url: string;
  personal_url: string;
  linkedin_url: string;

  // Derived by us
  profile_id: string; // TODO: Dedup use of profile, profile_id, public_id
  public_id: string;
  streak: number;
  connections: number;
  num_meetings: number;
  endorsements: Endorsement[];
  new_year_goals: string[];

  // Locales
  locale: number;
  city: string;
  locale_info: LocaleInfo;

  inviter: any; // TODO: TYPE THIS (ProductUser ideally)
}

export const emptyUser: UserMe = {
  first_name: '',
  last_name: '',
  image: '',
  headline: '',
  title: '',
  company: '',
  organizations: [],
  interests: [],

  ask_me_about: '',
  just_learned: '',
  like_to_learn: '',
  side_hustle: '',
  top_of_mind: '',

  twitter_url: '',
  github_url: '',
  angellist_url: '',
  instagram_url: '',
  personal_url: '',
  linkedin_url: '',

  email: '',
  primary_google_email: '',
  secondary_google_emails: [],
  objective_ids: [],
  objective_info: [],
  visual_settings: {},
  snooze_until: '',
  is_involuntary_invites_disabled: false,
  is_reconnect_prescheduler_disabled: false,
  is_morpheus: false,
  autopilot_paused_until: null,

  // Derived by us
  profile_id: '',
  public_id: '',
  streak: 0,
  connections: 0,
  num_meetings: 0,
  endorsements: [],
  new_year_goals: [],
  invite_code: '',
  clubpoints: 0,
  user_type: 2,
  was_matched: false,
  has_google: false,
  has_user_connected_contacts: false,
  is_banned: 0,
  test_bucket: 0,
  verified: false,

  // Locales
  locale: 0,
  city: '',
  locale_info: {
    id: 0,
    is_active: 0,
    is_featured: 0,
    locale_block_expire: '',
    name: '',
    neighborhoods: [],
    timezone: '',
    video_neighborhood_id: 0,
  },

  inviter: null,
  last_match: null,
};
