import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

import { colors } from 'css/css';

import { APIFailure } from 'js/components/shared/APIFailure';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { useUserContextProvider, useUser, useTestBuckets } from 'js/providers/UserProvider';
import { verifyUser } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';
import { getIsMobile, getTokenURLParameter } from 'js/util/util';

import { StartGuide } from './StartGuide';
import { GoogleAuth } from './GoogleAuth';
import { StartGuideMobile } from './StartGuideMobile';

export const Verify: React.FC = () => {
  const history = useHistory();
  const { fetchUser, userAvailability } = useUserContextProvider();
  const user = useUser();
  const { cohesiveAutopilotTest } = useTestBuckets();

  const [loading, setLoading] = useState(true);
  const [completedNUX, setCompletedNUX] = useState(false);
  const [googleEmailAddress, setGoogleEmailAddress] = useState('');
  const [hasFetchedEmail, setHasFetchedEmail] = useState(false);
  const [canSkipGoogle, setCanSkipGoogle] = useState(true);
  const [hasApiFailed, setHasApiFailed] = useState(false);

  const passedToken = getTokenURLParameter();
  if (!passedToken) history.push('/');

  useMountEffect(() => {
    const verifyPassedToken = async () => {
      const res = await verifyUser(passedToken);
      if (res.ok) {
        await fetchUser();
        // getJson.email === null if not a google email or already gauthed
        setGoogleEmailAddress(res.getJson.email || '');
        setHasFetchedEmail(true);
        // Adds a skip button to the gauth page
        setCanSkipGoogle(res.getJson.allow_skip || false);
      } else {
        setHasApiFailed(true);
      }
    };
    if (passedToken) verifyPassedToken().then(() => setLoading(false));
  });

  const moveToNextPage = () => {
    fetchUser().then(() => {
      const hasSeenThisFlow =
        user.visual_settings && 'has_completed_autopilot_flow' in user.visual_settings;
      const isNewUser = !user.was_matched && !userAvailability?.time_slots.length;
      if (cohesiveAutopilotTest && !hasSeenThisFlow && isNewUser) {
        history.push('/autopilot');
      } else {
        history.push('/weekly?from=registration');
      }
    });
  };

  useEffect(() => {
    if (completedNUX && hasFetchedEmail && !googleEmailAddress) {
      moveToNextPage();
    }
  }, [completedNUX, hasFetchedEmail, googleEmailAddress]);

  if (loading) return <SVGLoader />;
  if (hasApiFailed) return <APIFailure />;

  return (
    <PageContainer>
      {completedNUX && googleEmailAddress ? (
        <GoogleAuth
          canSkipGoogle={canSkipGoogle || getIsMobile()}
          googleEmailAddress={googleEmailAddress}
          onSkipOrFinish={moveToNextPage}
        />
      ) : getIsMobile() ? (
        <StartGuideMobile onSubmit={() => setCompletedNUX(true)} />
      ) : (
        <StartGuide onSubmit={() => setCompletedNUX(true)} />
      )}
    </PageContainer>
  );
};

const PageContainer = styled.div`
  color: ${colors.primaryMain};
  text-align: center;
  margin: 0 auto;
  max-width: 900px;
  height: 100%;
`;
