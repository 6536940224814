import { apiPost } from 'js/util/api';

export type CoachingPlanCoaches = 'EXPERT' | 'INVESTOR' | 'WILDCARD' | 'FOUNDER';
export interface CoachingPlanMatchMeta {
  display_name: string;
  type_name: CoachingPlanCoaches;
  type_value: number;
  count: number;
}

export interface CoachingPlanEligibilityDetails {
  plural: string;
  singular: string;
  matches: CoachingPlanMatchMeta[];
}

export interface CoachingPlanEligibilityResult {
  eligible: boolean;
  type?: number;
  details?: CoachingPlanEligibilityDetails;
}

export const postAcceptCoachingPlan = (type: number) =>
  apiPost('discover/coaching_plan/accept', { plan_id: type });

export const postExitCoachingPlan = () => apiPost('discover/coaching_plan/exit', {});

export const postCoachingPlanWishes = (suggested: string, desired: string) => {
  apiPost('discover/coaching_plan/wishes', {
    suggested,
    desired,
  });
};
