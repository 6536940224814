import React from 'react';
import styled from 'styled-components/macro';

import { margins, colors, media, globalTransitionSettings } from 'css/css';

import { Dropdown } from 'js/components/shared/dropdown';

import { DropdownProfile } from './DropdownProfile';
import { ProfileMenuItem } from './ProfileMenu';
import { MenuText } from './styles';

interface Props {
  top: string;
  right?: string;
  bottom?: string;
  position: string;
  width: string;
  borderRadius: string;
  isOpen: boolean;
  user: any;
  menuItems: ProfileMenuItem[];
  hideMenu: () => void;
}

export const NavDropdownMenu: React.FC<Props> = ({
  top,
  right,
  bottom,
  position,
  width,
  borderRadius,
  isOpen,
  user,
  menuItems,
  hideMenu,
}) => {
  const getProfileMenuItem = () => (
    <>
      <MenuItem>
        <DropdownProfile user={user} />
      </MenuItem>
    </>
  );

  const getDefaultMenuItem = (item: ProfileMenuItem) =>
    'icon' in item && (
      <MenuItem padding={`${margins.size2} ${margins.size3}`}>
        <img src={item.icon} alt="icon" style={{ padding: '3px', marginRight: margins.size3 }} />
        <MenuText> {item.label} </MenuText>
      </MenuItem>
    );

  const getMenuItem = (item: ProfileMenuItem) => {
    switch (item.type) {
      case 'profile':
        return getProfileMenuItem();
      default:
        return getDefaultMenuItem(item);
    }
  };

  const handleClick = (onClick: () => void) => () => {
    hideMenu();
    if (onClick) {
      onClick();
    }
  };

  return (
    <Dropdown
      position={position}
      top={top}
      bottom={bottom}
      right={right}
      width={width}
      margin="0"
      borderRadius={borderRadius}
      isOpen={isOpen}
    >
      {menuItems.map(item => (
        <Wrapper key={item.label} onClick={handleClick(item.onClick)}>
          {getMenuItem(item)}
        </Wrapper>
      ))}
    </Dropdown>
  );
};

const Wrapper = styled.div`
  z-index: 2;
`;

const MenuItem = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: row;
  padding: ${margins.size2} ${margins.size4};
  align-items: center;
  transition: ${globalTransitionSettings}
  ${Wrapper}:hover & {
    background-color: ${colors.blackMid};
    cursor: pointer;
  }
  ${media.mobile} {
    width: 100%;
    margin: 0;
    border-radius: 0;
    padding: ${p => p.padding || margins.size3};
  }
`;
