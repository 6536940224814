import React, { createContext, useContext, useEffect } from 'react';

import { isIOSMobile, isSafari } from 'js/components/callv2/utils/browser';
import { useCallV2Context } from 'js/components/callv2/contexts/CallContext';
import { useUIStateContext } from 'js/components/callv2/contexts/UIStateContext';
import {
  useDeviceState,
  TUseDeviceState,
} from 'js/components/callv2/contexts/MediaDevicesContext/useDeviceState';

interface ContextValue {
  camError: TUseDeviceState['camError'];
  cams: TUseDeviceState['cams'];
  currentCam: TUseDeviceState['currentCam'];
  currentMic: TUseDeviceState['currentMic'];
  currentSpeaker: TUseDeviceState['currentSpeaker'];
  deviceState: TUseDeviceState['deviceState'];
  micError: TUseDeviceState['micError'];
  mics: TUseDeviceState['mics'];
  promptForAccess(): void;
  refreshDevices: TUseDeviceState['refreshDevices'];
  selectCamera: TUseDeviceState['selectCamera'];
  selectMic: TUseDeviceState['selectMic'];
  selectSpeaker: TUseDeviceState['selectSpeaker'];
  speakers: TUseDeviceState['speakers'];
  areDevicesBlocked: boolean;
}

// eslint-disable-next-line no-console
const noop = () => console.error('No parent MediaDevicesContextProvider found!');
const noopPromise = () => Promise.reject(new Error('No parent MediaDevicesContextProvider found!'));

const MediaDevicesContext = createContext<ContextValue>({
  camError: null,
  cams: [],
  currentCam: null,
  currentMic: null,
  currentSpeaker: null,
  deviceState: 'loading',
  micError: null,
  mics: [],
  promptForAccess: noop,
  refreshDevices: noopPromise,
  selectCamera: noopPromise,
  selectMic: noopPromise,
  selectSpeaker: noop,
  speakers: [],
  areDevicesBlocked: false,
});

export const MediaDevicesContextProvider: React.FC = ({ children }) => {
  const { state } = useCallV2Context();

  const {
    setShowDeviceInUseModal,
    setShowDeviceNotFoundModal,
    setShowUnblockModal,
    showUnblockModal,
  } = useUIStateContext();

  const {
    camError,
    cams,
    currentCam,
    currentMic,
    currentSpeaker,
    deviceState,
    micError,
    mics,
    refreshDevices,
    speakers,
    selectCamera,
    selectMic,
    selectSpeaker,
  } = useDeviceState();

  /**
   * Clean up modals, when errors are resolved.
   */
  useEffect(() => {
    if (camError !== 'blocked' && micError !== 'blocked') {
      setShowUnblockModal(false);
    }
    if (camError !== 'in-use' && micError !== 'in-use') {
      setShowDeviceInUseModal(false);
    }
    if (camError !== 'not-found' && micError !== 'not-found') {
      setShowDeviceNotFoundModal(false);
    }
  }, [
    camError,
    micError,
    setShowUnblockModal,
    setShowDeviceInUseModal,
    setShowDeviceNotFoundModal,
  ]);

  /**
   * Automatically show modal when selected device is in use.
   */
  useEffect(() => {
    if (state !== 'joined' || camError !== 'in-use') return;
    setShowDeviceInUseModal(true);
  }, [camError, setShowDeviceInUseModal, state]);

  const promptForAccess = () => {
    if (isSafari() || isIOSMobile()) {
      if (showUnblockModal) {
        window.location.reload();
      } else {
        setShowUnblockModal(true);
      }
    } else {
      setShowUnblockModal(true);
    }
  };

  useEffect(() => {
    // Prompt user for microphone & camera access on initial render
    (async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      refreshDevices();
    })();
  }, []);

  const isAudioBlocked = mics.length === 0 || micError === 'blocked';
  const isVideoBlocked = cams.length === 0 || camError === 'blocked';
  const areDevicesBlocked = isAudioBlocked || isVideoBlocked;

  return (
    <MediaDevicesContext.Provider
      value={{
        camError,
        cams,
        currentCam,
        currentMic,
        currentSpeaker,
        deviceState,
        micError,
        mics,
        promptForAccess,
        refreshDevices,
        selectCamera,
        selectMic,
        selectSpeaker,
        speakers,
        areDevicesBlocked,
      }}
    >
      {children}
    </MediaDevicesContext.Provider>
  );
};

export const useMediaDevicesContext = () => useContext(MediaDevicesContext);
