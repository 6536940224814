import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { colors, globalTransitionSettings, margins, media, FlexRow, Heading4, Text } from 'css/css';

import { NetworkCard } from 'js/components/shared/user-cards/NetworkCard';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { ViewAll } from 'js/components/shared/ViewAll';

const ROW_HEIGHT = 234;
const CARD_WIDTH = 215;
const CARD_GAP = 4;
const calculateRowWidth = (numCards: any) => CARD_WIDTH * numCards + CARD_GAP * (numCards - 1);

export const PastInvites = ({ pastLoading, pastInvitees }: any) => {
  const [viewingAll, setViewingAll] = useState(false);
  const containerRef = useRef(null);

  return (
    <AlignmentWrapper noFeed={!pastInvitees.length}>
      <FlexRow
        style={{ marginBottom: margins.size2, marginTop: margins.size3 }}
        justifyContent="space-between"
      >
        <StyledText>Past Invites ({pastInvitees.length})</StyledText>

        <ViewAll
          noMobileDisplay
          noDisplay={pastLoading || !pastInvitees.length}
          onClick={() => setViewingAll(!viewingAll)}
          viewingAll={viewingAll}
        />
      </FlexRow>

      <VariableContainer ref={containerRef} viewingAll={viewingAll}>
        {pastLoading ? (
          <SVGLoader center={false} />
        ) : pastInvitees.length ? (
          <Grid>
            {pastInvitees.map((invitee: any) => (
              <NetworkCard
                key={invitee.email}
                name={invitee.name}
                email={invitee.email}
                image={invitee.image}
                profileId={invitee.profile}
                numMatches={invitee.num_matches}
                eventType={invitee.event_type}
                eventDate={invitee.last_match || invitee.verified}
                urlRef="invite"
              />
            ))}
          </Grid>
        ) : (
          <StyledEmpty>
            <Text>
              You haven&apos;t invited anyone yet!
              <br />
              Invite someone now and earn some Clubpoints.
            </Text>
          </StyledEmpty>
        )}
      </VariableContainer>
    </AlignmentWrapper>
  );
};

const StyledText = styled(Heading4)`
  max-width: 872px;
  letter-spacing: 1px;
  position: relative;
  text-align: left;
  ${media.mobile} {
    width: 100%;
    display: inline-block;
    margin: auto;
  }
`;

const StyledEmpty = styled.div`
  background-color: ${colors.greyMain};
  color: ${colors.blackMid};
  height: 91px;
  max-width: 872px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: auto;
  ${media.mobile} {
    width: 100%;
    padding: 0px;
  }
`;

type VariableContainerTypes = {
  viewingAll: boolean;
};
const VariableContainer = styled.div<VariableContainerTypes>`
  transition: ${globalTransitionSettings};
  overflow: hidden;
  margin: 0 auto;
  ${media.mobile} {
    max-height: 'auto';
  }
  ${media.desktop} {
    max-height: ${p => (p.viewingAll ? '9999px' : `${ROW_HEIGHT}px`)};
  }
`;

type AlignmentWrapperTypes = {
  noFeed: boolean;
};
const AlignmentWrapper = styled.div<AlignmentWrapperTypes>`
  margin: 0 auto;
  max-width: '872px';
  ${media.desktop} {
    max-width: ${p => (p.noFeed ? '872px' : calculateRowWidth(2))}px;
    @media screen and (min-width: 933px) {
      max-width: ${p => (p.noFeed ? '872px' : calculateRowWidth(3))}px;
      @media screen and (min-width: 1145px) {
        min-width: ${calculateRowWidth(4)}px;
      }
    }
  }
`;

const Grid = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, ${CARD_WIDTH}px);
  grid-gap: ${margins.size1};
  justify-content: center;
  ${media.mobile} {
    grid-template-columns: repeat(auto-fill, 100%);
  }
`;
