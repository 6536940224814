import React from 'react';
import styled from 'styled-components/macro';

import { colors, fontSizes, Text } from 'css/css';

const Badge = styled(Text)<{ hasMany: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => (p.hasMany ? '1.8em' : '1.33em')};
  height: 1.33em;
  top: -0.33em;
  right: ${p => (p.hasMany ? '-0.66em' : '-0.33em')};
  border-radius: 1em;
  font-size: ${fontSizes.size1};
  color: ${colors.whiteMain};
  background-color: ${colors.tertiary2Main};
`;

export const NotificationBadge: React.FC<{ count: number }> = ({ count }) =>
  !count ? null : (
    <Badge bold hasMany={count > 9}>
      {count > 9 ? '9+' : count}
    </Badge>
  );
