import React from 'react';
import styled from 'styled-components/macro';

import { colors, Clickable, globalTransitionSettings } from 'css/css';

interface Props {
  onClick: () => void;
  isOn: boolean;
  styles?: React.CSSProperties;
}

export const ToggleSwitch: React.FC<Props> = ({ isOn, onClick, styles = {} }: any) => (
  <Clickable onClick={onClick} style={styles}>
    <Switcher isOn={isOn}>
      <Dot isOn={isOn} />
    </Switcher>
  </Clickable>
);

type isOnType = { isOn: boolean };
const Switcher = styled.div<isOnType>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  background-color: ${p => (p.isOn ? colors.primaryMain : colors.blackLight)};
  height: 23px;
  width: 34px;
  min-width: 34px;
`;

const Dot = styled.div<isOnType>`
  border-radius: 50%;
  transition: ${globalTransitionSettings};
  background-color: ${colors.whiteMain};
  width: 19px;
  height: 19px;
  margin: 2px;
  margin-left: ${p => (p.isOn ? '13px' : '2px')};
`;
