import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import moment from 'moment';

import { FlexRow, Heading4, margins } from 'css/css';

import { LunchclubMatch } from 'types/matches';
import { PastAvailability } from 'types/availability';

import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { GreyPage, MobilePageHeader } from 'js/components/shared/page-wrappers';
import { PICK_OPTIONS } from 'js/components/weekly/pickers/options';
import { useTestBuckets, useUser, useUserAvailability } from 'js/providers/UserProvider';
import { getMatchFeedback, getMatchFeedbackCode } from 'js/util/api';
import { useDataContext } from 'js/providers/DataContextProvider';
import { addParameterToURL, getIsMobile, getParameterByName } from 'js/util/util';
import shouldRedirectToWeekly from 'js/util/weekly-redirect';

import { Locale } from '../weekly/types';

import { AvailabilityComponent } from './components/AvailabilityComponent';
import { MobileMatchesCarousel } from './components/MobileMatchesCarousel';
import { AutopilotMeetings } from './AutopilotMeetings';

export const Meetings = () => {
  const history = useHistory();
  const user = useUser();

  const { allTimeslots, allLocales } = useDataContext();
  const { cohesiveAutopilotTest } = useTestBuckets();

  const [pastAvailability, setPastAvailability] = useState<PastAvailability>();
  const [pastMatches, setPastMatches] = useState<LunchclubMatch[]>([]);
  const [missingFeedback, setMissingFeedback] = useState<string[]>([]);

  const [loading, setLoading] = useState(true);
  const [userSubmitingFeedback, setUserSubmittingFeedback] = useState(false);
  const userAvailability = useUserAvailability();
  const hasCompletedAutopilotFlow = user.visual_settings?.has_completed_autopilot_flow;
  const isMobile = getIsMobile();

  useEffect(() => {
    const shouldRedirect = async () => {
      const redirect = !cohesiveAutopilotTest && (await shouldRedirectToWeekly(userAvailability));
      if (
        redirect ||
        (cohesiveAutopilotTest &&
          !hasCompletedAutopilotFlow &&
          !userAvailability?.time_slots.length &&
          !userAvailability?.availability_autopilot?.autopilot &&
          !user.autopilot_paused_until)
      )
        history.push('/weekly');
      return redirect;
    };

    const getPastAvailability = () => {
      let targetAvailability = userAvailability;
      if (targetAvailability?.autopilot && targetAvailability?.availability_autopilot) {
        targetAvailability = targetAvailability.availability_autopilot; // Prefer autopilot over all
      }

      if (targetAvailability) {
        const {
          time_slots: timeslots,
          max_matches: maxMatches,
          weekly_objective: mainObjective,
          target_role: pickMatch,
          target_locale: targetLocale,
          passed,
          slant_opt_in: slantOptIn,
          autopilot,
        } = targetAvailability;
        const getLocaleForId = (id: number | null, locales: Locale[]) =>
          locales.find(l => l.id === id);

        const getPickForId = (id: number) =>
          (PICK_OPTIONS.find(option => option.id === id + 1) || PICK_OPTIONS[0]).name;

        const pick = getPickForId(pickMatch !== null ? pickMatch : -1); // -1 will return LUNCHCLUB PICK
        const targetLocaleName = getLocaleForId(targetLocale, allLocales);

        setPastAvailability({
          meetings: maxMatches,
          primaryObjective: (mainObjective && mainObjective.name) || '',
          pick,
          timeslots,
          passed,
          targetLocale: targetLocaleName?.name,
          slantOptIn,
          autopilot,
        });
      }
    };

    const loadPastMatches = async () => {
      const res = await getMatchFeedback();
      if (res.ok) setPastMatches(res.getJson);
    };

    const loadOutstandingFeedback = async () => {
      const res = await getMatchFeedbackCode();
      if (res.ok) setMissingFeedback(res.getJson.feedback_code);
    };

    if (allTimeslots.length && userAvailability !== undefined) {
      shouldRedirect().then(async wasRedirected => {
        getPastAvailability();
        if (wasRedirected) {
          setLoading(false);
        }
        await Promise.all([loadPastMatches(), loadOutstandingFeedback()]);
        setLoading(false);
      });
    }
  }, [allTimeslots, userAvailability]);

  if (loading)
    return (
      <GreyPage>
        <SVGLoader />
      </GreyPage>
    );

  const locale = userAvailability?.target_locale || user.locale;
  const pastRescheduleDeadline = allTimeslots[locale]?.warnDeadline;

  if (userSubmitingFeedback) {
    const maxSessionFeedback = getParameterByName('msf');
    history.push(
      addParameterToURL(
        'msf',
        maxSessionFeedback,
        addParameterToURL('ref', 'meetings', `/feedback?feedback_code=${missingFeedback[0]}`),
      ),
    );
    return (
      <GreyPage>
        <SVGLoader />
      </GreyPage>
    );
  }

  if (cohesiveAutopilotTest) {
    return (
      <GreyPage fixedFullHeight={isMobile} style={{ display: 'flex', flexDirection: 'column' }}>
        <AutopilotMeetings
          userAvailability={userAvailability}
          upcomingMatches={pastMatches.filter(
            match =>
              moment
                .tz(match.time_slot_with_exact_time, user.locale_info.timezone)
                .add(2, 'hours') >= moment.tz(user.locale_info.timezone),
          )}
        />
      </GreyPage>
    );
  }

  if (isMobile) {
    return (
      <GreyPage fixedFullHeight style={{ display: 'flex', flexDirection: 'column' }}>
        <MobilePageHeader>Upcoming Matches</MobilePageHeader>
        <MobileMatchesCarousel
          availability={pastAvailability}
          matches={pastMatches}
          setUserSubmittingFeedback={setUserSubmittingFeedback}
          missingFeedback={missingFeedback}
          pastRescheduleDeadline={pastRescheduleDeadline}
          coachingPlanDetails={userAvailability?.coaching_plan_details}
        />
      </GreyPage>
    );
  }

  return (
    <GreyPage>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <MeetingHeader top>One on one meetings</MeetingHeader>

        {userAvailability?.coaching_plan_details?.on_coaching_plan && (
          <MeetingHeader top>Curated Matches is turned on!</MeetingHeader>
        )}

        {!userAvailability?.coaching_plan_details?.on_coaching_plan &&
          userAvailability?.availability_autopilot?.time_slots[0] && (
            <MeetingHeader top>Autopilot is turned on!</MeetingHeader>
          )}
      </FlexRow>
      <AvailabilityComponent
        pastAvailability={pastAvailability}
        pastMatches={pastMatches}
        setUserSubmittingFeedback={setUserSubmittingFeedback}
        missingFeedback={missingFeedback}
        coachingPlanDetails={userAvailability?.coaching_plan_details}
      />
    </GreyPage>
  );
};

type MeetingHeaderTypes = {
  top?: boolean;
};
export const MeetingHeader = styled(Heading4)<MeetingHeaderTypes>`
  text-align: left;
  margin-top: ${p => (p.top ? '0px' : margins.size3)};
  margin-bottom: ${margins.size2};
`;
