import React, { useEffect, useState } from 'react';

import { margins, FlexColumn, Heading1, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { Modal } from 'js/components/shared/NewModal';

interface Props {
  showModal: boolean;
  join: () => void;
  decline: () => void;
}

export const SlantMatchOptInModal: React.FC<Props> = ({ showModal, join, decline }) => {
  const [modalOpen, setModalOpen] = useState(showModal);

  useEffect(() => {
    setModalOpen(showModal);
  }, [showModal]);

  const handleClose = () => {
    decline();
    setModalOpen(false);
  };

  return (
    <Modal showModal={modalOpen} handleClose={handleClose}>
      <FlexColumn>
        <Heading1 style={{ margin: `${margins.size3} 0px`, textAlign: 'center' }}>
          Best match anytime?
        </Heading1>
        <Text style={{ textAlign: 'center' }}>
          Sign up for us to send you a match and you can schedule the time yourself!
        </Text>

        <Button large onClick={join} style={{ marginTop: margins.size3 }}>
          Sign me up for a match
        </Button>
      </FlexColumn>
    </Modal>
  );
};
