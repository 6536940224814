import Bowser from 'bowser';
import React, { useState, useEffect } from 'react';

import { Browser } from 'js/components/callv2/utils/browser';

import { BrowserIcon } from './BrowserIcon';

const FALLBACK_BROWSER = Browser.CHROME;

const SUPPORTED_BROWSERS = [
  Browser.EDGE,
  Browser.CHROME,
  Browser.FIREFOX,
  Browser.OPERA,
  Browser.SAFARI,
];

export const useBrowser = () => {
  const [browser, setBrowser] = useState<Bowser.Parser.ParsedResult>();

  useEffect(() => {
    setBrowser(Bowser.parse(navigator.userAgent));
  }, []);

  const browserName = browser?.browser?.name ?? '';
  const browserVersion = browser?.browser.version ?? '';

  const icon = <BrowserIcon browserName={browserName} browserVersion={browserVersion} />;

  const name = SUPPORTED_BROWSERS.includes(browserName as any)
    ? browser?.browser?.name
    : FALLBACK_BROWSER;

  return { name, icon };
};
