import React from 'react';

import { colors, margins, Text } from 'css/css';

import { SmallArrow } from 'js/components/shared/SmallArrow';
import { SelectOptionType } from 'js/components/weekly/types';
import {
  SelectButton,
  ChooseText,
  SelectMenu,
  SelectOption,
} from 'js/components/shared/preferenceStyles';

interface Props {
  selectedLabel: string;
  isSelectOpen: boolean;
  onChange: (option: SelectOptionType) => void;
  options: SelectOptionType[];
  customButton?: any;
}

export const CustomSelect: React.FC<Props> = ({
  selectedLabel,
  isSelectOpen,
  onChange,
  options,
  customButton,
}) => (
  <>
    {customButton || (
      <SelectButton selectedLabel={selectedLabel}>
        <ChooseText>{selectedLabel ? 'Clear' : 'Choose'}</ChooseText>
        {!selectedLabel && (
          <div style={{ marginLeft: margins.size1, marginBottom: '3px' }}>
            <SmallArrow direction={isSelectOpen ? 'down' : 'right'} color={colors.blackMain} />
          </div>
        )}
      </SelectButton>
    )}
    {isSelectOpen && (
      <SelectMenu role="listbox">
        {options.map(option => (
          <SelectOption key={option.id} onClick={() => onChange(option)} role="option">
            <Text color={colors.blackMain}>{option.label}</Text>
          </SelectOption>
        ))}
      </SelectMenu>
    )}
  </>
);
