import fasteq from 'fast-deep-equal';

import { ParticipantsState, ParticipantsStateAction } from './types';
import {
  getScreenId,
  getScreenItem,
  getId,
  getNewParticipant,
  getUpdatedParticipant,
} from './utils';

export const initialParticipantsState: ParticipantsState = {
  lastPendingUnknownActiveSpeaker: null,
  participants: [
    {
      id: 'local',
      isActiveSpeaker: false,
      isCamMuted: true,
      isLoading: true,
      isLocal: true,
      isMicMuted: true,
      isScreenshare: false,
      name: '',
    },
  ],
  screens: [],
};

// --- Reducer and helpers --

export const participantsReducer = (
  prevState: ParticipantsState,
  action: ParticipantsStateAction,
): ParticipantsState => {
  switch (action.type) {
    case 'ACTIVE_SPEAKER': {
      const { participants, ...state } = prevState;
      if (!action.id)
        return {
          ...prevState,
          lastPendingUnknownActiveSpeaker: null,
        };
      const date = new Date();
      const isParticipantKnown = participants.some(p => p.id === action.id);
      return {
        ...state,
        lastPendingUnknownActiveSpeaker: isParticipantKnown
          ? null
          : {
              date,
              id: action.id,
            },
        participants: participants.map(p => ({
          ...p,
          isActiveSpeaker: p.id === action.id,
          lastActiveDate: p.id === action.id ? date : p?.lastActiveDate,
        })),
      };
    }
    case 'PARTICIPANT_JOINED': {
      const item = getNewParticipant(action.participant);

      const participants = [...prevState.participants];
      const screens = [...prevState.screens];

      const isPendingActiveSpeaker = item.id === prevState.lastPendingUnknownActiveSpeaker?.id;
      if (isPendingActiveSpeaker) {
        item.isActiveSpeaker = true;
        item.lastActiveDate = prevState.lastPendingUnknownActiveSpeaker?.date;
      }

      if (item.isCamMuted) {
        participants.push(item);
      } else {
        const firstInactiveCamOffIndex = prevState.participants.findIndex(
          p => p.isCamMuted && !p.isLocal && !p.isActiveSpeaker,
        );
        if (firstInactiveCamOffIndex >= 0) {
          participants.splice(firstInactiveCamOffIndex, 0, item);
        } else {
          participants.push(item);
        }
      }

      // // Participant is sharing screen
      if (action.participant.screen) {
        screens.push(getScreenItem(action.participant));
      }

      return {
        ...prevState,
        lastPendingUnknownActiveSpeaker: isPendingActiveSpeaker
          ? null
          : prevState.lastPendingUnknownActiveSpeaker,
        participants,
        screens,
      };
    }
    case 'PARTICIPANT_UPDATED': {
      const item = getUpdatedParticipant(action.participant, prevState.participants);
      const { id } = item;
      const screenId = getScreenId(id);

      const participants = [...prevState.participants];
      const idx = participants.findIndex(p => p.id === id);
      participants[idx] = item;

      const screens = [...prevState.screens];
      const screenIdx = screens.findIndex(s => s.id === screenId);

      if (action.participant.screen) {
        const screenItem = getScreenItem(action.participant);
        if (screenIdx >= 0) {
          screens[screenIdx] = screenItem;
        } else {
          screens.push(screenItem);
        }
      } else if (screenIdx >= 0) {
        screens.splice(screenIdx, 1);
      }

      const newState = {
        ...prevState,
        participants,
        screens,
      };

      if (fasteq(newState, prevState)) {
        return prevState;
      }

      return newState;
    }
    case 'PARTICIPANT_LEFT': {
      const id = getId(action.participant);
      const screenId = getScreenId(id);

      return {
        ...prevState,
        participants: [...prevState.participants].filter(p => p.id !== id),
        screens: [...prevState.screens].filter(s => s.id !== screenId),
      };
    }

    default:
      throw new Error();
  }
};
