import { useHistory } from 'react-router';

import { useMountEffect } from 'js/util/custom-hooks';
import { getParameterByName } from 'js/util/util';

export const RedirectDiscover = () => {
  const history = useHistory();

  useMountEffect(() => {
    const tab = getParameterByName('tab');
    const inviteEmail = getParameterByName('invite_email');
    if (tab === 'invite') {
      history.push('/invite');
    } else if (inviteEmail) {
      history.push(`/invite?invite_email=${inviteEmail}`);
    } else history.push('/home');
  });

  return null;
};
