import React from 'react';
import moment from 'moment';
import styled from 'styled-components/macro';

import { colors, FlexColumn, FlexRow, Heading3, margins, media, Text } from 'css/css';

import { CoachingPlanDetails } from 'types/availability';

import { capitaliseFirstLetter } from 'js/util/util';

const slantMatchTimeCopy = 'Find a time with your match manually';

const formatTimeslot = (timeslot: string) => moment(timeslot).format('ddd, MMM Do | h:mma');

interface Props {
  thisWeeksAvailability: any;
  coachingPlan?: CoachingPlanDetails;
}

export const ExistingMeetingCard: React.FC<Props> = ({ thisWeeksAvailability, coachingPlan }) => {
  const doneText = (done: boolean) => {
    if (done) return 'Matched!';
    return 'Pending!';
  };

  const {
    meetings,
    primaryObjective,
    pick,
    timeslots,
    targetLocale,
    slantOptIn,
  } = thisWeeksAvailability;

  const CoachingPlanMatchesLineUp = () => {
    if (coachingPlan === undefined) return <></>;
    return (
      <FlexColumn style={{ marginBottom: margins.size2, alignItems: 'start' }}>
        <Row
          title="Curated Matches"
          last
          value={`
          ${capitaliseFirstLetter(coachingPlan.matches[0].type)} : ${doneText(
            coachingPlan.matches[0].done,
          )}`}
        />
        {coachingPlan.matches.slice(1).map(match => (
          <AdditionalRows>
            {`${capitaliseFirstLetter(match.type)} : ${doneText(match.done)}`}
          </AdditionalRows>
        ))}
      </FlexColumn>
    );
  };

  const Times = () => {
    return (
      <>
        <Row title="Times" value={formatTimeslot(timeslots[0]) || slantMatchTimeCopy} last />
        {timeslots.slice(1).map((timeslot: any) => (
          <AdditionalRows>{formatTimeslot(timeslot)}</AdditionalRows>
        ))}
      </>
    );
  };

  return (
    <>
      <Row title="Meetings" value={meetings} />
      {!coachingPlan?.on_coaching_plan && (
        <Row title="Primary Objective" value={primaryObjective || 'No objective chosen'} />
      )}
      <Row title="Pick" value={pick} />
      {targetLocale && <Row title="Target city" value={targetLocale} />}
      {coachingPlan?.on_coaching_plan && <CoachingPlanMatchesLineUp />}
      <Times />
      {slantOptIn && timeslots[0] && <AdditionalRows>{slantMatchTimeCopy}</AdditionalRows>}
    </>
  );
};

const Row = ({ title, value, last }: any) => (
  <MeetingRow last={last}>
    <Heading>{title}</Heading>

    <Text color={colors.blackLight}>{value}</Text>
  </MeetingRow>
);

type MeetingRowTypes = {
  last?: boolean;
};
const MeetingRow = styled(FlexRow)<MeetingRowTypes>`
  margin-bottom: ${p => (p.last ? '0px' : margins.size2)};
`;

const Heading = styled(Heading3)`
  width: 250px;
  ${media.mobile} {
    width: 150px;
  }
`;

const AdditionalRows = styled(Text)<MeetingRowTypes>`
  color: ${colors.blackLight};
  margin-left: 250px;
  ${media.mobile} {
    margin-left: 150px;
  }
`;
