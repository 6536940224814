import React from 'react';
import styled from 'styled-components/macro';

import {
  borders,
  colors,
  globalTransitionSettings,
  margins,
  media,
  FlexColumn,
  FlexRow,
  Heading3,
  Text,
} from 'css/css';

import { Clubpoints } from 'js/components/shared/Clubpoints';

import Indicator from 'img/weekly/indicator.svg';

import { AUTOPILOT_TESTS } from '..';
import { DayOption, LocaleTimeslotsByDay } from '../types';

import { MEETING_OPTIONS } from './options';

interface Props {
  timeslotData: LocaleTimeslotsByDay[];
  selected: (string | number)[];
  onClick: (timeslot: string) => void;
  toggleTray: (tray: number) => void;
  openTrays: number[];
  selectedNumberMeetings: number;
  maxMeetings: number;
  busyTimeslots: string[];
  autopilotVersion: number | undefined;
}

export const TimeslotPicker: React.FC<Props> = ({
  timeslotData,
  selected,
  onClick,
  openTrays,
  toggleTray,
  selectedNumberMeetings,
  maxMeetings,
  busyTimeslots,
  autopilotVersion,
}) => {
  const clubpointCost = MEETING_OPTIONS[selectedNumberMeetings].cost;
  const atMaxMeetingNum = selectedNumberMeetings >= maxMeetings;

  // Array of all time options
  const allTimes: string[] = [];
  timeslotData.map(day => day.options.map(option => allTimes.push(option.label)));

  // Array of unique time options sorted by time
  const uniqueAllTimes = allTimes
    .filter((time, i, times) => times.indexOf(time) === i)
    .sort(
      (time1, time2) =>
        Date.parse(`1970/01/01 ${time1.slice(0, -2)} ${time1.slice(-2)}`) -
        Date.parse(`1970/01/01 ${time2.slice(0, -2)} ${time2.slice(-2)}`),
    );

  const searchTime = (time: string, data: LocaleTimeslotsByDay) =>
    data.options.filter(option => option.label === time)[0];

  const openColumn = (idx: number) => {
    if (openTrays.includes(idx)) return;
    toggleTray(idx);
  };

  const forcedAutopilotTest = autopilotVersion === AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX;

  let signUpText = 'Choose a time to meet!';
  if (selectedNumberMeetings > 0) {
    signUpText = `Signing up for ${selectedNumberMeetings} ${
      selectedNumberMeetings > 1 ? `matches` : `match`
    }`;
    if (forcedAutopilotTest) {
      signUpText = `${signUpText} per week`;
    }
  } else if (forcedAutopilotTest) {
    signUpText = 'Choose weekly times to meet!';
  } else if (maxMeetings > 1) {
    signUpText = 'Choose times to meet!';
  }

  const getAnnotedTimeLabel = (day: DayOption) => {
    if (busyTimeslots.length) {
      if (busyTimeslots.includes(day.option)) return day.label;
      return (
        <>
          <img src={Indicator} style={{ marginRight: margins.size1 }} alt="" />
          {day.label}
        </>
      );
    }
    return day.label;
  };

  return (
    <>
      <Container>
        {timeslotData.map((column, i) => {
          const openTray = openTrays.includes(i);
          return (
            <React.Fragment key={column.id}>
              <Column
                role="group"
                openTray={openTray}
                onClick={() => openColumn(i)}
                numCol={timeslotData.length}
              >
                <TextBox flexDirection="column">
                  <Heading3>{column.header.slice(0, 3)}</Heading3>
                  <Text>{column.header.slice(3)}</Text>
                </TextBox>

                <Tray $openTray={openTray}>
                  {uniqueAllTimes.map((time, k) => {
                    const option = searchTime(time, column);
                    if (option) {
                      return (
                        <WeeklyButton
                          role="checkbox"
                          aria-checked={selected.includes(option.option)}
                          key={option.option}
                          selected={selected.includes(option.option)}
                          first={column.options[0] === option}
                          onClick={() => onClick(option.option)}
                          openTray={openTray}
                          atMaxMeetingNum={atMaxMeetingNum}
                          disabled={!selected.includes(option.option) && atMaxMeetingNum}
                          numCol={timeslotData.length}
                        >
                          <ButtonText
                            atMaxMeetingNum={atMaxMeetingNum}
                            selected={selected.includes(option.option)}
                          >
                            <FlexRow justifyContent="center">{getAnnotedTimeLabel(option)}</FlexRow>
                          </ButtonText>
                        </WeeklyButton>
                      );
                    }
                    return <FillerWeeklySlot key={time} first={k === 0} />;
                  })}
                </Tray>
              </Column>
            </React.Fragment>
          );
        })}
      </Container>

      <MeetingTextBox>
        <FlexRow>
          <Text color={colors.blackMain} bold>
            {signUpText}
          </Text>
          {clubpointCost && <Clubpoints clubpoints={clubpointCost} />}
        </FlexRow>
      </MeetingTextBox>
    </>
  );
};

const Container = styled(FlexRow)`
  width: 600px;
  padding: ${margins.size3} ${margins.size2};
  border: ${borders.standard};
  border-radius: 5px 5px 0px 0px;
  max-width: 600px;
  justify-content: center;
  transition: ${globalTransitionSettings};
  ${media.mobile} {
    padding: 0;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
`;

type ColumnTypes = {
  numCol: number;
  openTray: boolean;
};
const Column = styled(FlexColumn)<ColumnTypes>`
  padding: 0px ${p => (p.numCol > 5 ? margins.size1 : margins.size2)};
  ${media.mobile} {
    width: 100%;
    height: 100%;
    cursor: ${p => (p.openTray ? 'default' : 'pointer')};
    padding: ${margins.size3};
    padding-left: ${margins.size4};
  }
`;

type OpenTrayType = { $openTray?: boolean };
const TextBox = styled(FlexRow)<OpenTrayType>`
  margin-bottom: ${margins.size2};
  width: 100%;
  justify-content: center;
  ${media.mobile} {
    width: 100%;
    margin-bottom: ${p => (p.$openTray ? margins.size2 : '0px')};
    justify-content: left;
  }
`;

const Tray = styled(FlexColumn)<OpenTrayType>`
  transition: ${globalTransitionSettings};
  width: 100%;
  ${media.mobile} {
    height: ${p => (p.$openTray ? '100%' : '0px')};
  }
`;

type ButtonTextTypes = {
  atMaxMeetingNum: boolean;
  selected: boolean;
};
const ButtonText = styled(Text)<ButtonTextTypes>`
  margin: auto;
  transition: ${globalTransitionSettings};
  color: ${p =>
    p.selected ? colors.whiteMain : p.atMaxMeetingNum ? colors.blackLight : colors.blackMain};
  ${media.mobile} {
    width: 82px;
  }
`;

type WeeklyButtonTypes = {
  selected: boolean;
  first: boolean;
  atMaxMeetingNum: boolean;
  openTray: boolean;
  numCol: number;
};
const WeeklyButton = styled.button<WeeklyButtonTypes>`
  transition: ${globalTransitionSettings};
  width: 86px;
  height: 36px;
  border: none;
  border-radius: 5px;
  background-color: ${p => (p.selected ? colors.secondaryMid : colors.whiteMain)};
  margin-top: ${p => (p.first ? '0px' : margins.size1)};
  cursor: ${p => (p.selected ? 'pointer' : p.atMaxMeetingNum ? 'default' : 'pointer')};
  padding: 1px ${p => (p.numCol > 5 ? margins.size2 : '12px')};
  white-space: nowrap;
  :focus {
    outline: none;
  }
  ${media.mobile} {
    display: ${p => (p.openTray ? 'initial' : 'none')};
    width: 100%;
    border: ${p => (p.selected ? borders.secondary : borders.standard)};
  }

  ${p =>
    !p.atMaxMeetingNum &&
    `&:hover {
  background-color: ${p.selected ? colors.secondaryMid : `${colors.secondaryMid}4d`};
  ${ButtonText}{
    color: ${p.selected ? colors.whiteMain : colors.secondaryMid};
  }`}
`;

type FillerWeeklySlotTypes = { first: boolean };
const FillerWeeklySlot = styled.div<FillerWeeklySlotTypes>`
  width: 100%;
  height: 36px;
  border: none;
  visibility: hidden;
  margin-top: ${p => (p.first ? '0px' : margins.size1)};
  cursor: 'default';

  ${media.mobile} {
    display: none;
  }
`;

const MeetingTextBox = styled(FlexRow)`
  width: 600px;
  max-width: 600px;
  height: 40px;
  padding: 0px ${margins.size3};
  border: 1px solid ${colors.greyMain};
  border-radius: 0px 0px 5px 5px;
  margin-top: -1px;
  justify-content: space-between;
  ${media.mobile} {
    max-width: 100%;
  }
`;
