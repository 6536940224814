import React from 'react';
import styled from 'styled-components/macro';

import { borders, FlexColumn, FlexRow, Heading3, margins, media, Text } from 'css/css';

import { ToggleSwitch } from 'js/components/shared/ToggleSwitch';

export const ToggleSection = ({
  onClick,
  isOn,
  headingTitle,
  subtitle,
  hasBorder = false,
  containerStyle = {},
}: {
  onClick: () => void;
  isOn: boolean;
  headingTitle: string;
  subtitle?: string[] | string;
  hasBorder?: boolean;
  containerStyle?: React.CSSProperties;
}) => (
  <SettingsSectionContainer $hasBorder={hasBorder} style={containerStyle}>
    <FlexRow justifyContent="space-between" style={{ width: '100%' }}>
      <Heading3>{headingTitle}</Heading3>
      <ToggleSwitch onClick={onClick} isOn={isOn} />
    </FlexRow>
    {subtitle !== undefined &&
      (typeof subtitle === 'string' ? (
        <Text style={{ marginTop: margins.size2 }}>{subtitle}</Text>
      ) : (
        <Text style={{ marginTop: margins.size2 }}>
          {subtitle.map(option => (
            <div key={option}>{option}</div>
          ))}
        </Text>
      ))}
  </SettingsSectionContainer>
);

const SettingsSectionContainer = styled(FlexColumn)<{ $hasBorder?: boolean }>`
  width: 100%;
  align-items: flex-start;
  padding: 0 ${margins.size3};
  margin-top: ${margins.size3};

  ${media.desktop} {
    ${p =>
      p.$hasBorder &&
      `
  margin-top: ${margins.size1};
  padding: ${margins.size3};
  border: ${borders.variation};
  border-radius: 5px;
`}
  }
  ${media.mobile} {
    padding: 0;
  }
`;
