import React, { createRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { FlexColumn, margins, Heading3 } from 'css/css';

import { addHttpsToURL, validateSocial } from 'js/util/util';
import { useNotifContext } from 'js/util/notif-context';
import { Avatar } from 'js/components/shared/Avatar';
import { useMountEffect } from 'js/util/custom-hooks';

import { emptyProfileSvg } from 'img/registration-v2';

import { RegistrationStepProps } from '../MobileRegistrationContent';

import { OnboardingFlowPage } from './OnboardingFlowPage';
import {
  InputRow,
  CheckmarkAnimated,
  LinkText,
  StepMobileContainer,
  Seperator,
  WhiteInputGroupBox,
  OnboardingSteps,
  NUX_HEADER_STEPS,
} from './shared';
import { ProgressBar } from './ProgressBar';

export const StepLinkedinMobile: React.FC<RegistrationStepProps> = ({
  values,
  setValues,
  submitUserInfo,
}) => {
  const [isMobileKeyboardOpen, setIsMobileKeyboardOpen] = useState(false);

  const history = useHistory();
  const { showNotif } = useNotifContext();
  const { title, subtitle } = NUX_HEADER_STEPS.Profile;

  useMountEffect(() => {
    if (window.bridge) {
      window.bridge.setShrinkViewportForKeyboard(true);
    }
    return () => {
      if (window.bridge) {
        window.bridge.setShrinkViewportForKeyboard(false);
      }
    };
  });

  const onNext = async () => {
    await submitUserInfo(
      {
        profileUrls: {
          linkedin_url: addHttpsToURL(values.linkedinLink),
          twitter_url: '',
        },
      },
      () => {
        history.push(`/registration/headline${history.location.search}`);
      },
    );
  };

  const invalidOnNext = () => {
    showNotif({
      message: 'Please enter a valid LinkedIn link (https://linkedin.com/in/yourProfile)',
      level: 'error',
    });
  };

  const isLinkedinRequired = values.requireLinkedin;
  const isLinkedInLinkValid = validateSocial(values.linkedinLink, 'linkedin');
  const isNextInvalid =
    !isLinkedInLinkValid &&
    (isLinkedinRequired || (!isLinkedinRequired && values.linkedinLink.length > 0));

  const linkedinEl = createRef<HTMLInputElement>();

  return (
    <OnboardingFlowPage
      step={OnboardingSteps.Profile}
      onNext={onNext}
      invalidOnNext={invalidOnNext}
      onPrev={() => history.push(`/registration/name${history.location.search}`)}
      nextDisabled={isLinkedinRequired && !isLinkedInLinkValid}
      nextInvalid={isNextInvalid}
      title={title}
      subtitle={subtitle}
      isMobileKeyboardOpen={isMobileKeyboardOpen}
    >
      <StepMobileContainer style={{ alignItems: 'center' }}>
        <ProgressBar total={4} numCompleted={2} />
        <Avatar
          src={values.image || emptyProfileSvg}
          alt={values.image ? 'profile' : 'placeholder'}
          size5
        />
        <Seperator />
        <InputContainer>
          <Heading3 style={{ marginTop: margins.size3 }}>
            What is your LinkedIn URL? ({isLinkedinRequired ? 'Required' : 'Optional'})
          </Heading3>
          <WhiteInputGroupBox style={{ padding: '12px' }}>
            <InputRow htmlFor="linkedinURL">
              <div style={{ flex: 1 }}>
                <input
                  type="url"
                  id="linkedinURL"
                  ref={linkedinEl}
                  spellCheck="false"
                  enterKeyHint="done"
                  placeholder="linkedin.com/in/yourProfile"
                  value={values.linkedinLink}
                  onFocus={() => setIsMobileKeyboardOpen(true)}
                  onChange={e => setValues({ ...values, linkedinLink: e.target.value })}
                  onKeyUp={e => {
                    if ((e.key === 'Enter' || e.key === 'Return') && !isNextInvalid) {
                      onNext();
                    }
                  }}
                  style={{ backgroundColor: 'transparent', width: '100%' }}
                />
              </div>
              {isLinkedInLinkValid && <CheckmarkAnimated />}
            </InputRow>
          </WhiteInputGroupBox>
          <LinkText
            href="https://www.linkedin.com/mwlite/profile/edit/contact-info/"
            target="_blank"
            rel="noreferrer"
          >
            Find your LinkedIn profile url
          </LinkText>
        </InputContainer>
      </StepMobileContainer>
    </OnboardingFlowPage>
  );
};

const InputContainer = styled(FlexColumn)`
  width: 100%;
  gap: ${margins.size2};
`;
