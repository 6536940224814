import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { margins, Heading1 } from 'css/css';

import { postUserImage } from 'js/util/api';
import { compressImage } from 'js/util/util';
import { useNotifContext } from 'js/util/notif-context';
import { AvatarEditor } from 'js/components/shared/profile-upload/AvatarEditor';
import { OldModal } from 'js/components/shared/OldModal';

export const ProfilePictureUploader: React.FC<{
  onDidUpload: (value: string) => void;
  children: (uploading: boolean, onPickFile: () => void) => React.ReactNode;
}> = ({ onDidUpload, children }) => {
  const { showNotif } = useNotifContext();
  const [uploading, setUploading] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (!file && fileInput.current) {
      fileInput.current.value = '';
    }
  }, [file, fileInput]);

  const startCroppingPicture = async (event: any) => {
    const imageFile = event.target.files[0];
    const compressedBlob = await compressImage(imageFile);
    if (!compressedBlob) {
      showNotif({ message: 'Please upload a valid image file', level: 'error' });
      return;
    }
    Object.assign(compressedBlob, {
      lastModifiedDate: new Date(),
      webkitRelativePath: '',
    });
    // convert blob to file
    // @ts-ignore
    const compressedFile = new File([compressedBlob], compressedBlob.name);
    setFile(compressedFile);
  };

  const submitCroppedProfilePicture = async (editorRef: any) => {
    setUploading(true);
    const base64 = editorRef.current.getImageScaledToCanvas().toDataURL();
    const resp = await fetch(base64);

    const data = new FormData();
    const blob = Object.assign(await resp.blob(), {
      lastModifiedDate: new Date(),
      name: file?.name,
    });
    data.append('file', blob);

    const res = await postUserImage(data);
    if (res.status !== 200) {
      showNotif({ message: 'Sorry, there was an issue uploading your image', level: 'error' });
    } else {
      onDidUpload(res.getJson.url);
    }
    setUploading(false);
    setFile(null);
  };

  return (
    <>
      {children(uploading, () => fileInput.current && fileInput.current.click())}

      <input
        style={{ display: 'none' }}
        type="file"
        accept="image/*"
        onChange={startCroppingPicture}
        ref={fileInput}
      />
      <OldModal isOpen={!!file} onClose={() => setFile(null)} canClose modalTitle="Crop picture">
        <Heading1
          style={{
            marginLeft: '0px',
            marginRight: '0px',
            textAlign: 'center',
            marginTop: margins.size5,
          }}
        >
          Edit your profile picture
        </Heading1>

        <EditorContainer>
          <AvatarEditor
            userImage={file}
            onSubmit={submitCroppedProfilePicture}
            onCancel={() => setFile(null)}
          />
        </EditorContainer>
      </OldModal>
    </>
  );
};

const EditorContainer = styled.div`
  display: flex;
  padding: ${margins.size3};
  margin-top: ${margins.size3};
  align-items: center;
  justify-content: center;
`;
