import styled from 'styled-components/macro';

import { colors, fonts, margins, globalTransitionSettings, borders } from 'css/css';

import checkmarkSvgLarge from 'img/shared/checkmark-large.svg';
import { checkmarkSvg } from 'img/registration-v2';
import {
  starActiveSvg,
  starInactiveSvg,
  profileActiveSvg,
  profileInactiveSvg,
  locationActiveSvg,
  locationInactiveSvg,
  autopilotActiveSvg,
  autopilotInactiveSvg,
} from 'img/registration-v2/progress-header';

interface Step {
  alt: string;
  title: string;
  subtitle: string;
  iconActive: string;
  iconInactive: string;
}

export const NUX_HEADER_STEPS: Record<string, Step> = {
  Location: {
    alt: 'Location',
    title: 'Where do you call home?',
    subtitle: 'You can update this later!',
    iconActive: locationActiveSvg,
    iconInactive: locationInactiveSvg,
  },
  GoalsAndInterests: {
    alt: 'Goals and Interests',
    title: 'Tell us your goals and interests!',
    subtitle: 'You can update this later!',
    iconActive: starActiveSvg,
    iconInactive: starInactiveSvg,
  },
  Profile: {
    alt: 'Profile',
    title: 'Let’s get your profile set up!',
    subtitle: 'You can update this later!',
    iconActive: profileActiveSvg,
    iconInactive: profileInactiveSvg,
  },
};

const AUTOPILOT_HEADER_STEP: Step = {
  alt: 'Autopilot',
  title: 'Let’s set up your availabilities!',
  subtitle: 'You can update this later!',
  iconActive: autopilotActiveSvg,
  iconInactive: autopilotInactiveSvg,
};

export const getNuxAutopilotHeaderSteps = () => ({
  ...NUX_HEADER_STEPS,
  Autopilot: AUTOPILOT_HEADER_STEP,
});

export enum OnboardingSteps {
  Location = 0,
  ObjectivesAndInterests = 1,
  Profile = 2,
  Autopilot = 3,
}

export const ObjectivesAndInterestsDecs = {
  Objectives: {
    title: 'What are you looking to do?',
    subtitle: 'These are private. Select up to 3!',
  },
  Interests: {
    title: 'What do you like talking about?',
  },
};

export const InputMeta = styled.div`
  color: ${colors.blackLight};
  font-size: 15px;
  line-height: 19px;
`;
export const InputPrompt = styled.div`
  color: ${colors.blackMid};
  line-height: 19px;
  font-family: ${fonts.regular};
  font-size: 16px;
  text-align: center;
`;

export const InputRow = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 0;
  & input {
    width: 70vw;
    border: none;
    border-bottom: ${borders.transparent};
    border-radius: 0;
    padding: 0;
    font-size: 16px;
    font-family: ${fonts.extraBold};
    ::placeholder {
      color: ${colors.blackMid};
      font-family: ${fonts.regular};
      text-align: center;
    }
    &:focus {
      outline: 0;
    }
  }
  & textarea {
    width: 100%;
    border: none;
    outline: 0;

    ::placeholder {
      color: ${colors.blackMid};
    }
  }
`;

export const WhiteInputGroupBox = styled.div`
  background-color: ${colors.greyLight};
  border-radius: 10px;
  padding: ${margins.size3};
  align-self: stretch;
`;

export const CheckmarkAnimated = styled.div<{ $large?: boolean }>`
  animation: SubtlePop 300ms;
  background: url(${p => (p.$large ? checkmarkSvgLarge : checkmarkSvg)});
  width: ${p => (p.$large ? '49px' : '24px')};
  height: ${p => (p.$large ? '48px' : '24px')};
  transform: scale(1);
  opacity: 1;

  @keyframes SubtlePop {
    0% {
      transform: scale(0.5);
      opacity: 0.6;
    }
    40% {
      transform: scale(1.2) rotate(12deg);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export const LinkText = styled.a`
  color: ${colors.blackLight};
  transition: ${globalTransitionSettings};
  text-decoration: underline;
  &:hover {
    color: ${colors.blackMid};
  }
`;

export const Seperator = styled.div`
  height: 0px;
  width: 100%;
  border: 1px solid ${colors.greyLight};
  margin: ${margins.size3} 0px;
`;

export const StepMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: white;
  margin: 0px ${margins.size2};
  padding: ${margins.size2};
  max-height: fit-content;
  margin-bottom: ${margins.size2};
  transition: ${globalTransitionSettings};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
