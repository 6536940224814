import React, { useState } from 'react';
import Linkify from 'react-linkify';
import moment from 'moment';

import Channel, { LCMessageType, MorpheusMessageType, SuperMessage } from 'types/channel';

import { EVENT_STATUS, LC_BOT_PUBLIC_ID, linkifyDecorator, NETWORK_TYPES } from 'js/util/util';
import { useUser } from 'js/providers/UserProvider';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { postEndorsement, postAcceptPreschedule } from 'js/util/api';
import { apiFailure } from 'js/util/strings';
import { useDataContext } from 'js/providers/DataContextProvider';
import { useNotifContext } from 'js/util/notif-context';

import endorsementIcon from 'img/chat/endorsement.svg';
import endorsementAddedIcon from 'img/chat/endorsement-added.svg';
import calendarIcon from 'img/chat/calendar-grey.svg';

import { SchedulerMessage } from './SchedulerMessage';
import { LunchclubBotMessage } from './LunchclubBotMessage';
import { LunchclubSchedulerMessage } from './LunchclubSchedulerMessage';
import { IntroMessage } from './IntroMessage';
import { MessageBubble, getIsEndorsementAcceptedMessage } from './BaseMessage';
import { MorphFeedbackMessage } from './MorphFeedbackMessage';

interface ContentProps {
  m: SuperMessage;
  isMyMessage: boolean;
  isFirstTimeScheduling: boolean;
  selectedChannel: Channel;
  userTimezone: string;
  ref: React.RefObject<HTMLDivElement>;
  lastSchedulerMessage?: SuperMessage;
  openEndorsementModal?: () => void;
  openScheduler?: ({
    isReconnecting,
    isEditingTimes,
  }: {
    isReconnecting: boolean;
    isEditingTimes: boolean;
  }) => void;
  currentMatchChannel?: Channel;
  replaceNameWithLink: (message: string, matchChannel?: Channel) => React.ReactNode;
  noBody?: boolean;
  isUpcoming: boolean;
}

export const MessageContent: React.FC<ContentProps> = ({
  m,
  isMyMessage,
  isFirstTimeScheduling,
  selectedChannel,
  userTimezone,
  ref,
  lastSchedulerMessage,
  openEndorsementModal,
  openScheduler,
  currentMatchChannel,
  replaceNameWithLink,
  noBody,
  isUpcoming,
}) => {
  const [isAddingEndorsement, setIsAddingEndorsement] = useState(false);
  const [hasClickedConfirmPreschedule, setHasClickedConfirmPreschedule] = useState(false);
  const [isConfirmMeetingLoading, setIsConfirmMeetingLoading] = useState(false);

  const user = useUser();
  const { showNotif } = useNotifContext();
  const { confirmCall, updateChannel, updateLCMessages } = useChatContext();
  const { setShouldFetchEndorsements } = useDataContext();
  const {
    conversation,
    matchCode,
    firstName,
    profileId,
    avatar,
    userEventStatus,
  } = selectedChannel;

  const isMessageFromLunchclub = m.author === 'Lunchclub' || m.author === LC_BOT_PUBLIC_ID;

  const messageType = (m.attributes as any)?.messageType;
  const schedulerTimeslots = (m?.attributes as any)?.schedulerTimeslots;

  const isIntro = messageType === LCMessageType.INTRO;
  const isMorpheusMessage = messageType === LCMessageType.MORPHEUS;
  const morpheusType = (m?.attributes as any)?.morpheusType;
  const isInBotChannel = selectedChannel.networkType === NETWORK_TYPES.LC_BOT;

  const isEndorsement = messageType === LCMessageType.ENDORSEMENT;
  const isPrescheduledReconnect = messageType === LCMessageType.PRESCHEDULED_RECONNECT;
  const isSuggestedTimeMessage =
    isMorpheusMessage && morpheusType === MorpheusMessageType.PROMPT_VIDEO_CALL;

  const isScheduling = (conversation?.attributes as any)?.isScheduling;
  const isReconnecting = (conversation?.attributes as any)?.isReconnecting;

  const isEndorsementAcceptedMessage = getIsEndorsementAcceptedMessage(m);
  const isEndorsementAccepted: boolean | undefined = (m.attributes as any)?.endorsement_accepted;
  const endorsedBy: boolean | undefined = (m.attributes as any)?.endorsed_by;
  const endorsementCategory: string | undefined = (m.attributes as any)?.endorsement_category;

  const isPrescheduledReconnectAccepted = (m.attributes as any)?.preschedule_accepted;
  const prescheduledReconnectTimeslot = (m.attributes as any)?.locale_timeslot;

  const prescheduledSuggestedTimeslot = (m.attributes as any)?.suggestedTimeslot;
  const isUserConfirmed = userEventStatus === EVENT_STATUS.confirmed;

  const isMorphFeedbackMessage =
    isInBotChannel && isMorpheusMessage && morpheusType === MorpheusMessageType.FEEDBACK_RESPONSE;

  const addEndorsement = async (event: React.MouseEvent) => {
    event.stopPropagation();

    const endorsementId = (m.attributes as any)?.endorsement_id;
    if (!endorsementId) {
      return;
    }

    setIsAddingEndorsement(true);
    const res = await postEndorsement({
      endorsementId,
      isAccepted: true,
    });
    if (res.ok) {
      await updateLCMessages();
      if (openEndorsementModal) {
        openEndorsementModal();
      }
      setShouldFetchEndorsements(true);
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
    setIsAddingEndorsement(false);
  };

  const acceptPrescheduledReconnect = async (event: React.MouseEvent, message: SuperMessage) => {
    event.stopPropagation();
    const prescheduleId = (m.attributes as any)?.preschedule_id;

    if (!prescheduleId || !matchCode) {
      return;
    }
    setHasClickedConfirmPreschedule(true);
    const res = await postAcceptPreschedule({
      prescheduleId,
      matchCode,
    });
    if (res.ok) {
      // eslint-disable-next-line no-param-reassign
      (message.attributes as any).preschedule_accepted = true;
      updateChannel(profileId, (ch: Channel) => ({
        ...ch,
        userEventStatus: EVENT_STATUS.confirmed,
      }));
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
    setHasClickedConfirmPreschedule(false);
  };

  const confirmSuggestedMeeting = async (e: React.MouseEvent) => {
    if (!selectedChannel.matchCode) {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
      return;
    }
    e.stopPropagation();
    setIsConfirmMeetingLoading(true);
    confirmCall(profileId);
    setIsConfirmMeetingLoading(false);
  };

  if (isIntro) {
    return (
      <>
        <IntroMessage message={m} />
      </>
    );
  }

  if (isEndorsement) {
    return (
      <LunchclubBotMessage
        message={m}
        onButtonClick={addEndorsement}
        buttonText={isEndorsementAccepted ? '' : 'Add endorsement to profile'}
        isLoading={isAddingEndorsement}
        icon={endorsementIcon}
        headerText={endorsementCategory}
        subHeaderText={`Endorsed by ${endorsedBy || firstName}!`}
        isPrivate
      />
    );
  }

  if (isEndorsementAcceptedMessage) {
    return (
      <LunchclubBotMessage
        icon={endorsementAddedIcon}
        avatar={{
          iconTop: 13.5,
          iconLeft: 20,
          ...(messageType === LCMessageType.ENDORSEMENT_ACCEPTED_BY_THEM
            ? { src: avatar, id: profileId }
            : { src: user.image, id: user.public_id }),
        }}
        headerText={endorsementCategory}
        subHeaderText={m.body}
        isMyMessage={isMyMessage}
      />
    );
  }

  if (isPrescheduledReconnect) {
    return (
      <>
        <LunchclubBotMessage message={m} />
        {!!prescheduledReconnectTimeslot && (
          <LunchclubSchedulerMessage
            isMyMessage={isMyMessage}
            timeslot={moment(prescheduledReconnectTimeslot).format('MMM DD h:mma')}
            userTimezone={userTimezone}
            onButtonClick={e => acceptPrescheduledReconnect(e, m)}
            buttonText={
              isPrescheduledReconnectAccepted ? 'You confirmed the call!' : 'Confirm call'
            }
            wasButtonClicked={isPrescheduledReconnectAccepted}
            isLoading={hasClickedConfirmPreschedule}
            openScheduler={() => {
              if (openScheduler) {
                openScheduler({ isEditingTimes: false, isReconnecting: true });
              }
            }}
            isActive={
              !lastSchedulerMessage ||
              moment(m.dateUpdated).isAfter(moment(lastSchedulerMessage.dateUpdated))
            }
          />
        )}
      </>
    );
  }

  if (isSuggestedTimeMessage) {
    return (
      <>
        {prescheduledSuggestedTimeslot ? (
          <>
            <LunchclubSchedulerMessage
              isMyMessage={false}
              timeslot={moment
                .utc(prescheduledSuggestedTimeslot)
                .tz(user.locale_info.timezone)
                .format('MMM DD h:mma')}
              userTimezone={userTimezone}
              onButtonClick={e => confirmSuggestedMeeting(e)}
              buttonText={isUserConfirmed ? 'You confirmed the call!' : 'Confirm call'}
              wasButtonClicked={isUserConfirmed}
              isLoading={isConfirmMeetingLoading}
              openScheduler={() => {
                if (openScheduler) {
                  openScheduler({ isEditingTimes: false, isReconnecting: false });
                }
              }}
              isActive={
                isUpcoming &&
                (!lastSchedulerMessage ||
                  moment(m.dateUpdated).isAfter(moment(lastSchedulerMessage.dateUpdated)))
              }
            />
          </>
        ) : (
          <LunchclubBotMessage
            onButtonClick={() => {
              if (openScheduler) {
                openScheduler({ isReconnecting: false, isEditingTimes: false });
              }
            }}
            buttonText="Select availabilities"
            icon={calendarIcon}
            headerText={`Select times to chat with ${firstName}`}
          />
        )}
      </>
    );
  }

  if (!m.body && (m.attributes as any).confirmedTimeslot) {
    return (
      <SchedulerMessage
        isMyMessage={isMyMessage}
        isFirstTimeScheduling={isFirstTimeScheduling}
        isReconnecting={isReconnecting}
        type="confirmed"
        timeslots={[]}
        meetingTime={moment
          .utc((m.attributes as any).confirmedTimeslot)
          .tz(user.locale_info.timezone)
          .format('dddd MMM DD h:mma')}
        userTimezone={userTimezone}
        isLastSchedulerMessage={false}
        isScheduling={false}
      />
    );
  }

  if (!m.body && (m.attributes as any).canceledSchedule) {
    return (
      <SchedulerMessage
        isMyMessage={isMyMessage}
        isFirstTimeScheduling={isFirstTimeScheduling}
        isReconnecting={isReconnecting}
        type="canceled"
        timeslots={[]}
        meetingTime=""
        userTimezone=""
        isLastSchedulerMessage={false}
        isScheduling={false}
      />
    );
  }

  if (isMorphFeedbackMessage) {
    return <MorphFeedbackMessage m={m} />;
  }

  return (
    <>
      {m.body && !noBody && (
        <MessageBubble ref={ref} $isMyMessage={isMyMessage}>
          <Linkify componentDecorator={linkifyDecorator}>
            {isMessageFromLunchclub ? replaceNameWithLink(m.body, currentMatchChannel) : m.body}
          </Linkify>
        </MessageBubble>
      )}
      {!!schedulerTimeslots?.length && (
        <SchedulerMessage
          isMyMessage={isMyMessage}
          isFirstTimeScheduling={isFirstTimeScheduling}
          isReconnecting={isReconnecting}
          type="request"
          timeslots={schedulerTimeslots}
          meetingTime=""
          userTimezone={userTimezone}
          isLastSchedulerMessage={lastSchedulerMessage === m}
          isScheduling={isScheduling}
        />
      )}
    </>
  );
};
