import React, { useState } from 'react';
import styled from 'styled-components/macro';

import {
  borders,
  margins,
  media,
  FlexColumn,
  globalTransitionSettings,
  ClickableText,
} from 'css/css';

import { useNotifContext } from 'js/util/notif-context';

import upArrow from 'img/registration/dialogue-triangle.svg';

import { Nav } from './Nav';

export const IconCarousel = ({
  pages,
  onFinish,
  onBack = null,
  style = null,
  completeLater,
  buttonLoading,
}: any) => {
  const { showNotif } = useNotifContext();
  const [index, setIndex] = useState(0);

  const lastPage = pages.length - 1;
  const firstPage = 0;

  const nextPage = async () => {
    if (pages[index].validate && !pages[index].validate()) {
      showNotif({
        message: pages[index].invalidMessage,
        level: 'error',
      });
      return null;
    }
    if (pages[index].submit) await pages[index].submit();
    if (index < lastPage) return setIndex(index + 1);
    return onFinish();
  };

  const prevPage = () => {
    if (index > firstPage) return setIndex(index - 1);
    return onBack();
  };

  const iconClick = (i: any) => {
    if (isClickable(i)) {
      if (pages[index].submit && pages[index].validate && pages[index].validate())
        pages[index].submit();
      setIndex(i);
    }
  };

  const isClickable = (i: any) => {
    if (i < index) {
      return true;
    }
    if (!pages[index].validate) {
      return true;
    }
    if (pages[index].validate() && i - index === 1) {
      return true;
    }
    return false;
  };

  return (
    // @ts-ignore
    <div as={style}>
      <ProgressContainer>
        {pages.map((page: any, i: any) => (
          <FlexColumn>
            <ClickableIcon
              alt={page.name}
              selected={index === i}
              clickable={isClickable(i)}
              onClick={() => iconClick(i)}
              src={page.icon}
            />

            {index === i && <UpArrow src={upArrow} />}
          </FlexColumn>
        ))}
      </ProgressContainer>

      <PageContainer>{pages[index].component}</PageContainer>
      {completeLater && (
        <div style={{ marginTop: `-${margins.size5}` }}>
          <ClickableText onClick={() => onFinish()}>Complete later</ClickableText>
        </div>
      )}

      <Nav
        onNext={nextPage}
        onBack={prevPage}
        nextInvalid={pages[index].validate ? !pages[index].validate() : false}
        loading={buttonLoading}
        invalidMessage={pages[index].invalidMessage}
      />
    </div>
  );
};

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px;
`;

const PageContainer = styled.div`
  border: ${borders.standard};
  box-sizing: border-box;
  border-radius: 3px;
  padding: ${margins.size5} ${margins.size6};
  margin-bottom: ${margins.size6};
  margin-left: auto;
  margin-right: auto;
  max-width: 650px;

  ${media.mobile} {
    padding: ${margins.size5} ${margins.size4};
  }
`;

type ClickableIconTypes = {
  clickable: boolean;
  selected: boolean;
};
const ClickableIcon = styled.img<ClickableIconTypes>`
  width: 48px;
  height: 48px;
  margin: 0 ${margins.size2};
  transition: ${globalTransitionSettings};
  cursor: ${p => (p.clickable ? 'pointer' : 'default')};
  filter: ${p => (p.selected ? '' : 'grayscale(100%) opacity(30%)')};
`;

const UpArrow = styled.img`
  margin-bottom: -1px;
`;
