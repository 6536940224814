import React from 'react';
import styled from 'styled-components/macro';

import { borders, colors, FlexRow, margins, Text } from 'css/css';

interface Props {
  onClick: () => void;
  optionText: string;
  isSelected: boolean;
  isTextLeftAligned?: boolean;
  hasBottomBorder?: boolean;
}

export const SelectOption: React.FC<Props> = ({
  onClick,
  isSelected,
  isTextLeftAligned = false,
  optionText,
  hasBottomBorder = false,
}) => {
  return (
    <SelectOptionContainer
      onClick={onClick}
      $isSelected={isSelected}
      $isTextLeftAligned={isTextLeftAligned}
      $hasBottomBorder={hasBottomBorder}
    >
      <Text>{optionText}</Text>
    </SelectOptionContainer>
  );
};

const SelectOptionContainer = styled(FlexRow)<{
  $isSelected: boolean;
  $isTextLeftAligned: boolean;
  $hasBottomBorder?: boolean;
}>`
  border: ${p => (p.$isSelected ? borders.transparent : borders.variation)};
  justify-content: ${p => (p.$isTextLeftAligned ? 'flex-start' : 'center')};
  border-top: ${borders.transparent};
  border-bottom: ${borders.transparent};
  width: 100%;
  padding: ${margins.size3};
  text-align: ${p => (p.$isTextLeftAligned ? 'left' : 'center')};
  ${p => p.$isSelected && `background-color: ${colors.blackMain};`}

  ${Text} {
    color: ${p => (p.$isSelected ? colors.whiteMain : colors.blackMid)};
  }

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-top: ${borders.variation};
    ${p => p.$isSelected && `border: ${borders.transparent};`}
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: ${borders.variation};
    ${p => p.$isSelected && `border: ${borders.transparent};`}
  }

  &:not(:last-child) {
    ${p => p.$hasBottomBorder && `border-bottom: ${borders.variation};`}
  }

  &:hover {
    cursor: pointer;
    background-color: ${p => (p.$isSelected ? colors.blackMain : 'transparent')};
  }
`;
