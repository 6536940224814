import React, { useState, useRef } from 'react';

import { colors, margins } from 'css/css';

import { useOutsideAlerter } from 'js/util/custom-hooks';
import { getIsMobile } from 'js/util/util';

import { SmallArrow } from '../shared/SmallArrow';

import { NavDropdownMenu } from './NavDropdownMenu';
import { NavIconContainer } from './NavIconContainer';
import { ProfileMenuItem } from './ProfileMenu';
import { MobileNavDropdownMenu } from './MobileNavDropdownMenu';

interface Props {
  title?: string;
  menuItems: ProfileMenuItem[];
  user?: any;
  isExpandable?: boolean;
  notificationCount?: number;
  onClickOpen?: () => void;
}

export const BaseMenu: React.FC<Props> = ({
  title,
  menuItems,
  user,
  isExpandable,
  notificationCount,
  children,
  onClickOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const hideMenu = () => setIsOpen(false);

  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(menuRef, hideMenu);

  const isMobile = getIsMobile();

  const handleNavIconClick = () => {
    if (!isOpen && onClickOpen) onClickOpen();
    setIsOpen(prev => !prev);
  };

  return (
    <>
      <div ref={menuRef} title={title}>
        <NavIconContainer
          isExpandable={isExpandable}
          notificationCount={notificationCount}
          onClick={handleNavIconClick}
        >
          {children}
          {isExpandable && !isMobile && (
            <SmallArrow
              direction="down"
              color={colors.whiteMain}
              style={{ marginLeft: margins.size2 }}
            />
          )}
        </NavIconContainer>
        {isMobile ? (
          <MobileNavDropdownMenu
            isOpen={isOpen}
            user={user}
            menuItems={menuItems}
            onClose={hideMenu}
          />
        ) : (
          <NavDropdownMenu
            user={user}
            menuItems={menuItems}
            isOpen={isOpen}
            hideMenu={hideMenu}
            position="absolute"
            width="328px"
            top={margins.size6}
            bottom={undefined}
            right="0px"
            borderRadius="10px"
          />
        )}
      </div>
    </>
  );
};
