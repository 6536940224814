import React from 'react';
import styled from 'styled-components/macro';

import { FlexRow, margins, media, globalTransitionSettings } from 'css/css';

import { Control as BaseControl } from './Control';

interface Props {
  className?: string;
  isVisible?: boolean;
  allowScreenShare?: boolean;
}
export const Controls: React.FC<Props> = ({ className, isVisible, allowScreenShare }) => (
  <ControlsContainer className={className} isVisible={isVisible}>
    <Control type="audio" />
    <Control type="video" />
    {allowScreenShare ? <Control type="screen" /> : undefined}
    <Control type="end" isLast />
  </ControlsContainer>
);

const ControlsContainer = styled(FlexRow)<{ isVisible?: boolean }>`
  width: fit-content;
  position: absolute;
  margin: 0 auto;
  bottom: ${margins.size4};
  opacity: ${p => (p.isVisible ? 100 : 0)};
  transition: ${globalTransitionSettings};

  ${media.mobile} {
    top: 85%;
    bottom: 15%;
  }
`;

const Control = styled(BaseControl)<{ isLast?: boolean }>`
  margin-right: ${p => (p.isLast ? '0px' : margins.size3)};
`;
