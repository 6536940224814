import React from 'react';
import styled from 'styled-components/macro';

import {
  Heading1,
  margins,
  Heading2,
  Card,
  Text,
  Link,
  fonts,
  colors,
  FlexRow,
  FlexColumn,
  media,
} from 'css/css';
import { underlineAnimation } from 'css/animations';

import { GreyPage } from 'js/components/shared/page-wrappers';
import { Button } from 'js/components/shared/Button';

import { Tray } from './tray';

export const Faq = () => {
  const showMatches = window.location.hash === '#matches';
  return (
    <GreyPage>
      <Container>
        <Heading1 style={{ marginBottom: margins.size4 }}>FAQ</Heading1>

        <Heading2 style={{ marginBottom: margins.size2 }}>Account</Heading2>

        <Tray
          title="How do I change the email to my account?"
          height="150px"
          initialOpen={!showMatches}
        >
          <Text>
            You can change account where you receive emails from Lunchclub under{' '}
            <PageLink to="/settings">Settings</PageLink> → primary email.
          </Text>

          <Text style={{ marginTop: margins.size2 }}>
            You can also connect additional Google accounts to your Lunchclub account to add and
            invite more connections. This will help our AI learn about who is in your network and
            find more relevant matches for you! We do not contact any of your connections without
            your permission.
          </Text>
        </Tray>

        <Tray title="How can I edit my objectives?" height="145px">
          <Text>
            You can edit your objectives under <PageLink to="/settings">Settings</PageLink> in the
            top right corner when logged in. You can also add details to your objectives in the same
            page. The more information you give us, the more we can cater your matches to you! Each
            week, you can select your primary objective for the week. This means that the algorithm
            will lean towards matching you for that objective over the others.
          </Text>
        </Tray>

        <Tray title="How can I pause my account?" height="50px">
          <Text>
            If you’re looking to pause meetings for a few weeks, you can select “snoozed” on the{' '}
            <PageLink to="/settings">Settings</PageLink> page and pause emails for 1-4 weeks.
          </Text>
        </Tray>

        <Tray title="How can I change my main hub?" height="95px">
          <Text>
            If you’ve relocated, you can update your account hub under{' '}
            <PageLink to="/settings">Settings</PageLink> in the top right corner when logged in. If
            you’d like to meet more people outside of your immediate hub, you can also toggle “best
            match globally” when signing up each week on the{' '}
            <PageLink to="/weekly">Weekly page</PageLink>.
          </Text>
        </Tray>

        <Tray title="How can I delete my account?" height="150px">
          <Text>
            You can delete your account under <PageLink to="/settings">Settings</PageLink> in the
            top right corner when logged in. This will permanently delete all records of previous
            matches and Clubpoints, so you may consider signing up for 1-2 more matches to spend
            them on meeting a founder or investor.
          </Text>

          <Text style={{ marginTop: margins.size2 }}>
            You’re also welcome to rejoin with the same email at any time—we’ll miss you!
          </Text>
        </Tray>

        <Tray title="How is my Google information used?" height="140px">
          <Text>
            Your contact and calendar data helps us schedule meetings and ensure that your matches
            are relevant (not matching you with people you already know, for instance). We do not
            contact any of your connections without your permission. We are serious about your
            privacy and will never share your private data with other users or third parties without
            your consent. You can find more information on our{' '}
            <PageLink to="/privacy">privacy policy</PageLink>.
          </Text>
        </Tray>

        <Tray title="How do I report abuse?" height="50px">
          <Text>
            If you had an experience that you would like to report to us, please click contact us
            below, and we’ll look into this right away.
          </Text>
        </Tray>

        <Heading2 id="matches" style={{ marginBottom: margins.size2, marginTop: margins.size3 }}>
          Matches
        </Heading2>

        <Tray title="Is the matching really done by AI?" height="20px" initialOpen={showMatches}>
          <Text>Yes! Our matches are always picked by our AI.</Text>
        </Tray>

        <Tray
          title="How can I make my matches more relevant?"
          height="280px"
          initialOpen={showMatches}
        >
          <Text>
            Our AI makes the best, mutually-relevant matches using the information you provide for
            us through your profile, feedback, and contacts. You can improve the relevancy of your
            matches by:
            <ol style={{ marginTop: margins.size2 }}>
              <li>Keeping your profile headline updated with your current role or title </li>

              <li>Adding conversation starters to your profile</li>

              <li>Keeping your interests updated in your profile</li>

              <li>Browsing the homepage and letting us know which users are relevant to you</li>

              <li>Taking more meetings and completing the post-meeting feedback form </li>

              <li>Connecting more Google accounts</li>

              <li>Adding more specificity to your objective details</li>
            </ol>
          </Text>
        </Tray>

        <Tray
          title="What happens if I don't find my match relevant?"
          height="160px"
          initialOpen={showMatches}
        >
          <Text>
            Our users often tell us that their matches surprise them, so give it a shot! If you do
            feel the match was a poor fit after meeting, let us know by using the post-meeting
            review we email after each meeting — it will help our AI learn and improve match quality
            for you and all of our users.
          </Text>

          <Text style={{ marginTop: margins.size2 }}>
            Please note that our{' '}
            <PageLink to="/community-guidelines">community guidelines</PageLink> do prohibit and
            penalize ghosting.
          </Text>
        </Tray>

        <Heading1 style={{ marginBottom: margins.size4, marginTop: margins.size6 }}>
          Contact Us
        </Heading1>

        <FlexRow adapting>
          <FlexColumn alignItems="initial">
            <Heading2>Customer service</Heading2>

            <Text style={{ marginBottom: margins.size2 }}>
              Have a question not covered above? Send us a message! Someone on our team will get in
              contact with you as soon as we can! Contact us with the button below or at
              support@lunchclub.ai
            </Text>

            <a
              style={{ textDecoration: 'none' }}
              href="mailto:support@lunchclub.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button large>Contact us</Button>
            </a>
          </FlexColumn>

          <MobileContact alignItems="initial">
            <Heading2>Product Feedback</Heading2>

            <Text style={{ marginBottom: margins.size2 }}>
              Do you have feedback or suggestions for us? Let us know here! We love hearing from you
              to improve your Lunchclub experience! Send feedback with the button below or at
              support+product@lunchclub.ai
            </Text>

            <a
              style={{ textDecoration: 'none' }}
              href="mailto:support+product@lunchclub.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button large>Write feedback</Button>
            </a>
          </MobileContact>
        </FlexRow>
      </Container>
    </GreyPage>
  );
};

const Container = styled(Card)`
  padding: ${margins.size6};
  text-align: left;
  ${media.mobile} {
    padding: ${margins.size4};
  }
`;

const PageLink = styled(Link)`
  font-family: ${fonts.bold};
  color: ${colors.primaryMain};
  ${underlineAnimation}
`;

const MobileContact = styled(FlexColumn)`
  margin-left: ${margins.size4};
  ${media.mobile} {
    margin-top: ${margins.size4};
    margin-left: 0px;
  }
`;
