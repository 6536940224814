import React from 'react';

import { Link } from 'css/css';

export const ConditionalLink = ({ to, style, onClick, link, children }: any) =>
  link ? (
    <Link to={to} style={style} onClick={onClick}>
      {children}
    </Link>
  ) : (
    children
  );
