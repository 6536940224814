import React from 'react';
import styled from 'styled-components/macro';

import { Clickable, colors, FlexRow, Heading3, margins } from 'css/css';

import { LargeArrow } from 'js/components/shared/LargeArrow';

import { lightbulbIcon } from 'img/homepage/feed';
import cross from 'img/weekly/x.svg';

interface TopProps {
  closeModal: () => void;
  button?:
    | {
        kind: 'explanation';
        openHeader?: (continuation?: () => void) => void;
        closeHeader?: (continuation?: () => void) => void;
      }
    | { kind: 'back'; handleClick: () => void; text?: string; color?: string };
  header?: string;
}

export const Top: React.FC<TopProps> = ({ closeModal, button, header }) => {
  const hasHeader = !!header;
  const hasButton = button !== undefined;
  const hasButtonText = hasButton && button.kind === 'back' && !!button.text;

  return (
    <TopContainer
      onClick={
        hasButton && button.kind === 'explanation'
          ? () => {
              if (button.closeHeader) {
                button.closeHeader();
              }
            }
          : () => undefined
      }
    >
      <FlexRow style={{ justifyContent: hasButton ? 'space-between' : 'flex-end' }}>
        {hasButton && (
          <Clickable
            onClick={
              button.kind === 'explanation'
                ? (event: React.MouseEvent) => {
                    if (button.openHeader) {
                      button.openHeader();
                    }
                    event.stopPropagation();
                  }
                : button.handleClick
            }
          >
            <FlexRow>
              {button.kind === 'explanation' ? (
                <img src={lightbulbIcon} alt="Open explanation header" />
              ) : (
                <LargeArrow direction="left" color={button.color ?? colors.blackMain} />
              )}
              {hasButtonText && (
                <Heading3
                  style={{ color: button.color ?? colors.blackMain, marginLeft: margins.size3 }}
                >
                  {button.text}
                </Heading3>
              )}
            </FlexRow>
          </Clickable>
        )}

        {hasHeader && <Heading3>{header}</Heading3>}

        <Clickable onClick={closeModal}>
          <img src={cross} alt="Close modal" />
        </Clickable>
      </FlexRow>
    </TopContainer>
  );
};

const TopContainer = styled.div`
  background-color: ${colors.whiteMain};
  width: 100%;
  padding: ${margins.size4} ${margins.size3} 0px ${margins.size3};
  border-radius: 10px 10px 0px 0px;
`;
