import React, { useMemo } from 'react';
import moment from 'moment';
import styled from 'styled-components/macro';

import { FlexColumn, Heading3, margins, Heading2, media, colors, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { AutopilotStates, AutopilotStatuses } from 'js/components/autopilot/constants';
import { useDataContext } from 'js/providers/DataContextProvider';
import { useUser } from 'js/providers/UserProvider';
import { getIsMobile, INTERNATIONAL_TIME_LOCALES } from 'js/util/util';

import sadManSvg from 'img/meeting/sad-man.svg';
import { waitingSvg } from 'img/weekly-v2';

interface Props {
  timeslots?: string[];
  autopilotState: AutopilotStatuses;
  onClick: ({ enableViaLink }: { enableViaLink?: boolean }) => void;
}

export const UpcomingAvailabilities: React.FC<Props> = ({ timeslots, autopilotState, onClick }) => {
  const user = useUser();
  const isMobile = getIsMobile();

  const { allLocales } = useDataContext();
  const userLocale = allLocales.find(rawLocale => rawLocale.id === user.locale);
  const is24Hour = useMemo(
    () => !!userLocale?.id && INTERNATIONAL_TIME_LOCALES.includes(userLocale.id),
    [userLocale],
  );

  return (
    <WhiteContainer>
      <FlexColumn style={{ width: '100%' }}>
        <img
          src={
            autopilotState === AutopilotStatuses.on && !!timeslots?.length ? waitingSvg : sadManSvg
          }
          alt="coffee cup"
        />
        {autopilotState === AutopilotStatuses.on &&
          !!timeslots?.length &&
          isMobile &&
          !!timeslots[0] && (
            <>
              <Heading3
                color={colors.blackMid}
                key={timeslots[0]}
                style={{ marginLeft: margins.size2, marginBottom: margins.size1 }}
              >
                {moment(timeslots[0]).format('ddd, MMM Do')},{' '}
                {moment(timeslots[0]).format(is24Hour ? 'H:mm' : 'h:mm a')}
              </Heading3>
              <Text style={{ marginTop: margins.size2 }}>Your matches will be ready soon!</Text>
            </>
          )}
        {AutopilotStates[autopilotState].title && (
          <Heading2>{AutopilotStates[autopilotState].title}</Heading2>
        )}
        {AutopilotStates[autopilotState].text && (
          <Text style={{ marginTop: margins.size2, marginBottom: margins.size4 }}>
            {AutopilotStates[autopilotState].text}
          </Text>
        )}
        {AutopilotStates[autopilotState].buttonText && (
          <Button onClick={onClick}>{AutopilotStates[autopilotState].buttonText}</Button>
        )}
      </FlexColumn>
    </WhiteContainer>
  );
};

const WhiteContainer = styled(FlexColumn)`
  background-color: ${colors.whiteMain};
  padding: ${margins.size4};
  flex: 1;
  border-radius: 10px;
  justify-content: center;
  gap: ${margins.size3};
  width: 600px;
  margin: auto;
  scroll-snap-align: center;

  ${media.mobile} {
    width: 100%;
    height: 100%;
    flex: auto;
    flex-shrink: 0;
    margin-right: ${margins.size1};
    scroll-snap-align: center;
    overflow-y: scroll;
    padding: ${margins.size3};
    scrollbar-color: transparent;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar:horizontal {
      height: 0;
    }
  }
`;
