import styled from 'styled-components/macro';

import { media, margins, Text } from 'css/css';

export const ExplanationText = styled(Text)`
  margin-top: ${margins.size3};
  ${media.mobile} {
    margin-top: ${margins.size2};
  }
`;
