import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router';

import {
  colors,
  margins,
  Heading3,
  SubText,
  Text,
  FlexColumn,
  FlexRow,
  Heading1,
  fontSizes,
  Clickable,
} from 'css/css';

import {
  PersonalityType,
  getPersonalityTestResults,
  PersonalityTestResults,
  postTestActivity,
} from 'js/util/api';
import { APIFailure } from 'js/components/shared/APIFailure';
import { Button } from 'js/components/shared/Button';
import { useMountEffect } from 'js/util/custom-hooks';
import { getIsMobile } from 'js/util/util';
import { Backdrop } from 'js/components/shared/MobileModal';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import {
  DECK_CARD_CONTAINER_CONTENT_PADDING,
  DECK_CARD_CONTAINER_WIDTH,
} from 'js/components/homepage/styles';

import {
  driveIcon,
  influenceIcon,
  supportIcon,
  clarityIcon,
  cross,
  truityLogo,
} from 'img/homepage/feed';

const sortedBreakdown: PersonalityType[] = ['Drive', 'Influence', 'Support', 'Clarity'];

const getTruityLink = (personalityType: PersonalityType) =>
  `https://www.truity.com/page/truity-lunchclub-${personalityType.toLowerCase()}`;

const getPersonalityColor = (personalityType: string) => {
  switch (personalityType) {
    case 'Drive':
      return colors.tertiary2Main;
    case 'Influence':
      return colors.tertiary1Main;
    case 'Support':
      return colors.secondaryMid;
    default:
      return colors.tertiary3Main;
  }
};

const getPersonalityIcon = (personalityType: PersonalityType) => {
  switch (personalityType) {
    case 'Drive':
      return driveIcon;
    case 'Influence':
      return influenceIcon;
    case 'Support':
      return supportIcon;
    default:
      return clarityIcon;
  }
};

export const PersonalityResultCard: React.FC<{ isFirstTime: boolean }> = ({ isFirstTime }) => {
  const [personalityTestResults, setPersonalityTestResults] = useState<PersonalityTestResults>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiError, setApiError] = useState(false);
  const history = useHistory();
  const isMobile = getIsMobile();

  useMountEffect(() => {
    const loadPersonalityTestResults = async () => {
      const res = await getPersonalityTestResults();
      if (res.ok) {
        setPersonalityTestResults(res.getJson);
      } else {
        setApiError(true);
      }
    };
    loadPersonalityTestResults().then(() => {
      if (isFirstTime) {
        setTimeout(() => setIsModalOpen(true), 300);
      }
    });
  });

  if (personalityTestResults === undefined && apiError) return <APIFailure />;

  if (personalityTestResults === undefined)
    // if not apiError, then it's loading
    return isMobile ? (
      <ResultCardContainerMobile>
        <SVGLoader />
      </ResultCardContainerMobile>
    ) : (
      <SVGLoader />
    );

  const {
    scores,
    personality_type: personalityType,
    same_personality_type: percentUserWhoSharePersonalityType,
    mostly_met: mostlyMetType,
    mostly_met_letter: mostlyMetLetter,
  } = personalityTestResults;

  const sharedContent = (
    <FlexColumn style={{ gap: margins.size3, width: '100%', borderRadius: 10 }}>
      <FlexColumn style={{ gap: margins.size1 }}>
        <Heading1
          color={colors.whiteMain}
          style={{
            textAlign: 'center',
            margin: 0,
            fontSize: isMobile ? fontSizes.size5 : fontSizes.size8,
          }}
        >
          {personalityType}
        </Heading1>
        <SubText color={colors.whiteMain}>Your personality type</SubText>
      </FlexColumn>

      <FlexColumn style={{ gap: margins.size1, width: '100%' }}>
        <StatContainer>
          <Text color={colors.whiteMain} style={{ textAlign: 'center' }}>
            {percentUserWhoSharePersonalityType}% of Lunchclub community has your personality type!
          </Text>
        </StatContainer>
        {mostlyMetType && (
          <StatContainer>
            <Text color={colors.whiteMain} style={{ textAlign: 'center' }}>
              You’ve mostly met people who have personality type {mostlyMetLetter}, {mostlyMetType}.
            </Text>
          </StatContainer>
        )}
      </FlexColumn>

      <FlexColumn style={{ gap: margins.size2, width: '100%' }}>
        <SubText color={colors.whiteMain} style={{ textAlign: 'center' }}>
          Your personality breakdown:
        </SubText>
        <BreakdownContainer>
          {sortedBreakdown.map(key => (
            <FlexRow justifyContent="space-between" width="100%">
              <Text color={colors.whiteMain}>{key}</Text>
              <Text color={colors.whiteMain}>{scores !== null ? scores[key] : ''}</Text>
            </FlexRow>
          ))}
        </BreakdownContainer>
      </FlexColumn>

      <FlexColumn style={{ gap: margins.size2 }}>
        <SubText color={colors.whiteMain}>Powered by</SubText>
        <img src={truityLogo} alt="Truity logo" />
        <a
          href={getTruityLink(personalityType)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => postTestActivity('personality_test', 'truity')}
        >
          <Text bold color={colors.whiteMain} style={{ textDecoration: 'underline' }}>
            Learn more about DISC
          </Text>
        </a>
      </FlexColumn>
    </FlexColumn>
  );

  const resultCardModal = (
    <ResultCardModalContainer isOpen={isModalOpen}>
      <ResultCardModal
        style={{
          backgroundColor: getPersonalityColor(personalityType),
        }}
      >
        <div style={{ padding: margins.size4 }}>
          <FlexRow width="100%" justifyContent="flex-end">
            <Clickable onClick={() => setIsModalOpen(false)}>
              <img src={cross} alt="Close" />
            </Clickable>
          </FlexRow>

          {sharedContent}
        </div>

        <InviteButton onClick={() => history.push('/invite')}>
          <Heading3 color={colors.primaryMain}>Invite friends to find out their DISC</Heading3>
        </InviteButton>
      </ResultCardModal>
    </ResultCardModalContainer>
  );

  const resultCardMobile = (
    <>
      <ResultCardContainerMobile>
        <Heading1 style={{ textAlign: 'left', marginTop: 'calc(env(safe-area-inset-top))' }}>
          Daily Picks
        </Heading1>
        <Text style={{ marginBottom: margins.size2 }}>
          You’ve completed your DISC personality test! Come back tomorrow for your Daily Picks!
        </Text>

        <ResultCardMobile style={{ backgroundColor: getPersonalityColor(personalityType) }}>
          <FlexColumn style={{ gap: margins.size3, marginBottom: 'auto', marginTop: 'auto' }}>
            <img src={getPersonalityIcon(personalityType)} alt="Personality type icon" />
            <FlexColumn style={{ gap: margins.size1 }}>
              <Heading1
                color={colors.whiteMain}
                style={{
                  margin: 0,
                }}
              >
                {personalityType}
              </Heading1>
              <SubText color={colors.whiteMain}>Your personality type</SubText>
            </FlexColumn>
          </FlexColumn>

          <ResultCardButton onClick={() => setIsModalOpen(true)}>
            <Heading3 color={colors.primaryMain}>Learn more</Heading3>
          </ResultCardButton>
        </ResultCardMobile>
      </ResultCardContainerMobile>

      <Backdrop isOpen={isModalOpen} style={{ zIndex: 1 }} />

      {resultCardModal}
    </>
  );

  const resultCardDesktop = (
    <ResultCardContainerDesktop $backgroundColor={getPersonalityColor(personalityType)}>
      <img src={getPersonalityIcon(personalityType)} alt="Personality type icon" />
      {sharedContent}
    </ResultCardContainerDesktop>
  );

  return isMobile ? resultCardMobile : resultCardDesktop;
};

const InviteButton = styled(Button)`
  background-color: ${colors.whiteMain};
  width: 100%;
  border-radius: 0;
  padding: 18px;
  padding-bottom: calc(max(18px, env(safe-area-inset-bottom) + 10px));
  height: fit-content;
  &:hover {
    background-color: ${colors.greyLight};
  }
`;

const ResultCardContainerMobile = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${margins.size4} ${margins.size3};
  background-color: ${colors.greyLight};
`;

const ResultCardMobile = styled(FlexColumn)`
  border-radius: 12px;
  flex-grow: 1;
  padding: 24px;
`;

const ResultCardButton = styled(Button)`
  background-color: ${colors.whiteMain};
  width: 100%;
  &:hover {
    background-color: ${colors.greyLight};
  }
`;

const ResultCardModalContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transform: ${({ isOpen }) => (isOpen ? 'translate(0, 0)' : 'translate(0, 100%)')};
  transition: transform 300ms ease-in-out;
  max-height: 100%;
  overflow-y: auto;
`;

const ResultCardModal = styled(FlexColumn)`
  border-radius: 12px 12px 0px 0px;
  width: 100%;
  justify-content: center;
`;

const BreakdownContainer = styled(FlexColumn)`
  padding: ${margins.size4};
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const StatContainer = styled(FlexColumn)`
  padding: ${margins.size3};
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const ResultCardContainerDesktop = styled(FlexColumn)<{ $backgroundColor: string }>`
  justify-content: space-around;
  background-color: ${p => p.$backgroundColor};
  border-radius: 10px;
  width: ${DECK_CARD_CONTAINER_WIDTH}px;
  padding: ${DECK_CARD_CONTAINER_CONTENT_PADDING}px;
  margin: 0 auto;
`;
