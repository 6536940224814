import React from 'react';
import styled from 'styled-components/macro';

import { colors, margins, media, FlexRow, Text, Card } from 'css/css';

import { Partnership, PartnershipTheme } from 'types/partnership-whitelabel';

import { getIsMobile } from 'js/util/util';

import { hero, mobileHero } from 'img/landing-page';

import { MainHeading } from '../style';
import { LandingPageNav as Nav } from '../components/Nav';
import { RegisterEmail } from '../components/RegisterEmail';
import { RegisterGoogle } from '../components/RegisterGoogle';
import { AccountLogin } from '../components/AccountLogin';

export const Hero = ({
  copy,
  partnership,
}: {
  copy: { header: string; subHeader: string };
  partnership: Partnership | undefined;
}) => {
  const theme = partnership?.theme || {
    backgroundColor: colors.greyLight,
    headerColor: colors.blackMain,
    descriptionColor: colors.blackMid,
    logInButtonColor: 'transparent',
  };

  return (
    <Container theme={theme}>
      <Nav partnership={partnership} />
      <Content>
        <MobileSpacer>
          <TitleHeader theme={theme}>{copy.header}</TitleHeader>
          <ParagraphMedium theme={theme} big={copy.subHeader.length < 60}>
            {copy.subHeader}
          </ParagraphMedium>
          <TestContainer>
            <RegisterGoogle />
            <DividerText>OR</DividerText>
            <RegisterEmail />
          </TestContainer>
          {!getIsMobile() && <AccountLogin partnership={partnership} />}
        </MobileSpacer>
        <ImageContainer>
          <HeroImage
            src={partnership?.hero || (getIsMobile() ? mobileHero : hero)}
            alt="Connect with others on Lunchclub"
          />
        </ImageContainer>
      </Content>
    </Container>
  );
};

const Container = styled.div<{ theme: PartnershipTheme }>`
  width: 100%;
  background-color: ${p => p.theme.backgroundColor};
  padding: ${margins.size4} ${margins.size5} 100px ${margins.size5};

  ${media.mobile} {
    padding: ${margins.size3} 0px;
  }
`;

const Content = styled(FlexRow)`
  max-width: 1400px;
  margin: 0 auto;
  margin-top: ${margins.size4};
  ${media.mobile} {
    flex-direction: column;
  }
`;

const MobileSpacer = styled.div`
  width: 40%;
  ${media.mobile} {
    width: 100%;
    padding: 0px ${margins.size3};
  }
`;

const TitleHeader = styled(MainHeading)<{ theme: PartnershipTheme }>`
  color: ${p => p.theme.headerColor};
  ${media.mobile} {
    margin-top: ${margins.size5};
  }
`;

const ParagraphMedium = styled(Text)<{ big: boolean; theme: PartnershipTheme }>`
  margin-top: 24px;
  width: 82%;
  font-size: ${p => (p.big ? '26px' : '18px')};
  line-height: 1.2;
  color: ${p => p.theme.descriptionColor};
  ${media.mobile} {
    font-size: ${p => (p.big ? '20px' : '14px')};
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 60%;
  overflow: hidden;
  ${media.mobile} {
    width: 100%;
    margin-top: ${margins.size5};
  }
`;

const HeroImage = styled.img`
  position: relative;
  width: 650px;
  ${media.mobile} {
    width: 125%;
    right: 10%;
    overflow: hidden;
  }
`;

const TestContainer = styled(Card)`
  border: none;
  padding: ${margins.size4};
  margin-top: ${margins.size4};
  margin-bottom: ${margins.size2};
  box-shadow: 0px 0px 23px rgba(38, 45, 68, 0.1);
`;

const DividerText = styled(Text)`
  text-align: center;
  margin-top: ${margins.size2};
  margin-bottom: -${margins.size3};
`;
