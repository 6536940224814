import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { borders, colors, fonts, margins, Clickable } from 'css/css';

import { UserMe } from 'types/user';
import { Interests } from 'types/interests';

import { SimpleModalWrap, useSimpleModal, WhiteCardHeader } from 'js/components/shared/MobileModal';
import { getUpdatedGoalsList } from 'js/components/profile/ProfileNewYearGoals';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { cleanNames, validateHeadlineLength, validateSocial } from 'js/util/util';

import xWhiteSvg from 'img/invite/x-white.svg';
import {
  angellistIcon,
  githubIcon,
  linkedinIcon,
  personalIcon,
  twitterIcon,
} from 'img/shared/social-icons';
import DeleteGoal from 'img/profile/cross.svg';
import { iconInterestsSvg, iconNewYearGoalsSvg } from 'img/profile-v2';

import { WhiteCardAction } from '../shared/MobileModal';
import { InputRow, InputMeta, CheckmarkAnimated } from '../registration/mobile/shared';
import { TextInput } from '../shared/TextInput';
import { HeadlineMobileInput } from '../registration/mobile/HeadlineMobileInput';
import { MobileFocusingTextarea } from '../shared/MobileFocusingTextarea';
import { InterestItem, InterestsGallery } from '../registration/mobile/StepInterestsMobile';
import { Button } from '../shared/Button';

import { ConversationStarterConfigMobile, NEW_YEAR_GOALS_LIMIT } from './constants';

function validate(user: UserMe) {
  if (!user.first_name.trim().length || !user.last_name.trim().length) {
    return `Please enter a valid first and last name`;
  }
  if (!validateHeadlineLength(user.headline.length, 0)) {
    return `Please make sure your introduction has an appropriate length`;
  }
  if (user.github_url && !validateSocial(user.github_url, 'github')) {
    return `Please make sure your GitHub URL is correct.`;
  }
  if (user.twitter_url && !validateSocial(user.twitter_url, 'twitter')) {
    return `Please make sure your Twitter URL is correct.`;
  }
  if (user.linkedin_url && !validateSocial(user.linkedin_url, 'linkedin')) {
    return `Please make sure your LinkedIn URL is correct.`;
  }
  if (user.angellist_url && !validateSocial(user.angellist_url, 'angel')) {
    return `Please make sure your AngelList URL is correct.`;
  }
  if (user.instagram_url && !validateSocial(user.instagram_url, 'instagram')) {
    return `Please make sure your Instagram URL is correct.`;
  }
  if (user.interests.length === 0) {
    return 'You must have at least one interest.';
  }

  return null;
}

export const ProfileEditorMobile: React.FC<{ onDone: () => void }> = ({ onDone }) => {
  const user = useUser();
  const { updateUser } = useUserContextProvider();

  const [values, setValues] = useState({ ...user });
  const [addingStarter, setAddingStarter] = useState<string>();

  const initialHeadlineValue = `${cleanNames(values.first_name)} is `;
  const displayedValue = `${initialHeadlineValue}${values.headline.substring(
    initialHeadlineValue.length,
  )}`;
  const isEmpty = displayedValue.trim() === initialHeadlineValue.trim();

  const error = validate(values);
  const goalsList = useMemo(
    () => [
      ...(values.new_year_goals ?? []).filter(Boolean),
      ...Array(
        Math.max(NEW_YEAR_GOALS_LIMIT - (values.new_year_goals ?? []).filter(Boolean).length, 0),
      ).fill(''),
    ],
    [values.new_year_goals],
  );

  const onSave = async () => {
    const {
      twitter_url,
      linkedin_url,
      angellist_url,
      github_url,
      instagram_url,
      personal_url,
    } = values;

    await updateUser({
      firstName: values.first_name,
      lastName: values.last_name,
      title: values.title,
      company: values.company,
      headline: values.headline,
      askMeAbout: values.ask_me_about,
      justLearned: values.just_learned,
      likeToLearn: values.like_to_learn,
      sideHustle: values.side_hustle,
      topOfMind: values.top_of_mind,
      newYearGoals: values.new_year_goals.filter(Boolean),
      profileUrls: {
        twitter_url,
        linkedin_url,
        angellist_url,
        github_url,
        instagram_url,
        personal_url,
      },
    });
  };

  const UsedStarters = ConversationStarterConfigMobile.filter(
    ({ key }) => !!values[key] || addingStarter === key,
  );
  const UnusedStarters = ConversationStarterConfigMobile.filter(({ key }) => !values[key]);
  return (
    <div>
      <InputGroupBox style={{ display: 'flex', flexDirection: 'column', gap: margins.size3 }}>
        <InputRow htmlFor="firstName">
          <div style={{ flex: 1 }}>
            <InputMeta>First Name</InputMeta>
            <SuperboldTextInput
              type="text"
              id="firstName"
              autoComplete="given-name"
              enterKeyHint="next"
              mobileFocusLabel="My first name"
              value={values.first_name}
              onChange={str => setValues({ ...values, first_name: str })}
            />
          </div>
        </InputRow>
        <InputRow htmlFor="lastName">
          <div style={{ flex: 1 }}>
            <InputMeta>Last Name</InputMeta>
            <SuperboldTextInput
              type="text"
              id="lastName"
              autoComplete="family-name"
              enterKeyHint="next"
              mobileFocusLabel="My last name"
              value={values.last_name}
              onChange={str => setValues({ ...values, last_name: str })}
            />
          </div>
        </InputRow>

        <InputRow htmlFor="title">
          <div style={{ flex: 1 }}>
            <InputMeta>My role</InputMeta>
            <SuperboldTextInput
              type="text"
              id="title"
              enterKeyHint="next"
              mobileFocusLabel="My role"
              value={values.title}
              onChange={str => setValues({ ...values, title: str })}
            />
          </div>
        </InputRow>

        <InputRow htmlFor="company">
          <div style={{ flex: 1 }}>
            <InputMeta>My company</InputMeta>
            <SuperboldTextInput
              type="text"
              id="company"
              enterKeyHint="next"
              mobileFocusLabel="My company"
              value={values.company}
              onChange={str => setValues({ ...values, company: str })}
            />
          </div>
        </InputRow>
      </InputGroupBox>

      <InputGroupBox>
        <InputRow>
          <div
            style={{
              flex: 1,
              minHeight: 200,
              display: 'flex',
              flexDirection: 'column',
              gap: margins.size2,
            }}
          >
            <InputMeta>How I&apos;m introduced</InputMeta>
            <HeadlineMobileInput
              value={displayedValue}
              onChange={e => setValues({ ...values, headline: e.target.value })}
              resetValue={originalValue => setValues({ ...values, headline: originalValue })}
              bold
              color={colors.blackMid}
              isEmpty={isEmpty}
              initialValue={initialHeadlineValue}
            />
          </div>
        </InputRow>
      </InputGroupBox>

      <InputGroupBox>
        <InputRow>
          <div style={{ flex: 1 }}>
            <InputMeta>My social links</InputMeta>
            <SocialLinkRow
              icon={linkedinIcon}
              value={values.linkedin_url}
              placeholder="Paste your LinkedIn link"
              onChange={url => setValues({ ...values, linkedin_url: url })}
            />
            <SocialLinkRow
              icon={twitterIcon}
              value={values.twitter_url}
              placeholder="Paste your Twitter link"
              onChange={url => setValues({ ...values, twitter_url: url })}
            />
            <SocialLinkRow
              icon={angellistIcon}
              value={values.angellist_url}
              placeholder="Paste your AngelList link"
              onChange={url => setValues({ ...values, angellist_url: url })}
            />
            <SocialLinkRow
              icon={githubIcon}
              value={values.github_url}
              placeholder="Paste your GitHub link"
              onChange={url => setValues({ ...values, github_url: url })}
            />
            <SocialLinkRow
              icon={personalIcon}
              value={values.personal_url}
              placeholder="Paste your personal link"
              onChange={url => setValues({ ...values, personal_url: url })}
            />
          </div>
        </InputRow>
      </InputGroupBox>

      <InputGroupBox>
        <InputMeta style={{ display: 'flex', alignItems: 'center', gap: margins.size3 }}>
          <img src={iconInterestsSvg} alt="icon" />
          <div style={{ flex: 1 }}>My Interests</div>
        </InputMeta>
        <InterestsEditor
          selected={values.interests}
          setSelected={interests => setValues({ ...values, interests })}
        />
      </InputGroupBox>

      {UsedStarters.map(starter => (
        <QuestionInput
          {...starter}
          value={values[starter.key] || ''}
          setValue={value => {
            setValues({ ...values, [starter.key]: value });
          }}
          autoFocus={addingStarter === starter.key}
          onFocus={() => {
            // This prevents the starter from being removed from `UsedStarters`
            // if you erase all the text while editing.
            setAddingStarter(starter.key);
          }}
          onBlur={() => setAddingStarter(undefined)}
          onChange={value => {
            setValues({ ...values, [starter.key]: value });
          }}
        />
      ))}

      {UnusedStarters.length > 0 && (
        <div
          style={{
            fontSize: 16,
            color: colors.blackMid,
            paddingLeft: margins.size3,
            marginBottom: margins.size1,
          }}
        >
          Add a conversation starter
        </div>
      )}
      <StartersCarousel>
        {UnusedStarters.map(starters => (
          <StartersCarouselItem key={starters.key} onClick={() => setAddingStarter(starters.key)}>
            <img src={starters.icon} alt="icon" />
            <div>{starters.title}</div>
          </StartersCarouselItem>
        ))}
      </StartersCarousel>

      <InputGroupBox>
        <InputMeta style={{ display: 'flex', alignItems: 'center', gap: margins.size3 }}>
          <img src={iconNewYearGoalsSvg} alt="New Year Goals Icon" />
          <div style={{ flex: 1 }}>Goals this year</div>
        </InputMeta>
        {goalsList.map(
          (newYearGoal, index) =>
            (index <= 1 || !!goalsList[index - 1]) && (
              <NewYearGoalRow
                newYearGoal={newYearGoal}
                index={index}
                onChange={(value: string) =>
                  setValues({
                    ...values,
                    new_year_goals: getUpdatedGoalsList(goalsList, index, value),
                  })
                }
              />
            ),
        )}
      </InputGroupBox>

      {error && (
        <div
          style={{
            border: borders.error,
            borderRadius: 8,
            margin: margins.size3,
            background: colors.tertiary2Light,
            color: colors.tertiary2Main,
            padding: `${margins.size2} ${margins.size3}`,
          }}
        >
          {error}
        </div>
      )}
      <StickyBottomContainer>
        <WhiteCardAction
          disabled={!!error}
          title="Update profile"
          onClick={onSave}
          flashCompleteTitle="Saved!"
          onFlashComplete={onDone}
        />
      </StickyBottomContainer>
    </div>
  );
};

const SuperboldTextInput = styled(TextInput)`
  font-family: ${fonts.extraBold};
  background: ${colors.whiteMain};
  height: initial;
`;

const StickyBottomContainer = styled.div`
  position: sticky;
  bottom: 0px;
`;

const SocialLinkRow: React.FC<{
  value: string;
  placeholder: string;
  icon: string;
  onChange: (value: string) => void;
}> = ({ value, onChange, placeholder, icon }) => {
  return (
    <SocialLinkRowContainer>
      <img src={icon} alt="icon" />
      <TextInput placeholder={placeholder} value={value} onChange={onChange} />
      {value ? <CheckmarkAnimated /> : null}
    </SocialLinkRowContainer>
  );
};

const SocialLinkRowContainer = styled.div`
  display: flex;
  gap: ${margins.size2};
  align-items: center;
  height: 38px;
  input {
    background: ${colors.whiteMain};
    font-family: ${fonts.regular};
    flex: 1;
    width: initial;
    &::placeholder {
      opacity: 0.5;
      text-align: left;
    }
  }
`;

const QuestionInput: React.FC<{
  icon: string;
  title: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  autoFocus: boolean;
  onFocus: () => void;
  onBlur: () => void;
  onChange: (value: string) => void;
}> = ({ value, setValue, onChange, onFocus, onBlur, icon, title, autoFocus }) => {
  const textboxRef = useRef<HTMLTextAreaElement>();
  const [height, setHeight] = useState(0);
  const sizingDiv = useMemo(() => document.createElement('div'), []);
  useEffect(() => {
    sizingDiv.style.position = 'absolute';
    sizingDiv.style.left = '-1000px';
    sizingDiv.style.top = '0px';
    document.body.appendChild(sizingDiv);
    return () => sizingDiv.remove();
  }, []);

  useEffect(() => {
    if (!textboxRef.current || !sizingDiv) {
      return;
    }

    const div = sizingDiv;
    const computed = getComputedStyle(textboxRef.current);
    div.style.padding = computed.padding;
    div.style.whiteSpace = computed.whiteSpace;
    div.style.textAlign = computed.textAlign;
    div.style.fontFamily = computed.fontFamily;
    div.style.fontFeatureSettings = computed.fontFeatureSettings;
    div.style.fontWeight = computed.fontWeight;
    div.style.fontSize = computed.fontSize;
    div.style.width = `${textboxRef.current.clientWidth}px`;
    div.textContent = value;
    setHeight(div.clientHeight + 5);
  }, [value]);

  return (
    <InputGroupBox>
      <InputRow>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: margins.size2 }}>
          <InputMeta style={{ display: 'flex', alignItems: 'center', gap: margins.size3 }}>
            <img src={icon} alt="icon" />
            <div style={{ flex: 1 }}>{title}</div>
          </InputMeta>
          <MobileFocusingTextarea
            ref={textboxRef}
            value={value}
            resetValue={originalValue => setValue(originalValue)}
            autoFocus={autoFocus}
            mobileFocusBehavior="always"
            mobileFocusLabel={title}
            onChange={e => onChange(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
            style={{ height, fontFamily: fonts.extraBold, fontSize: 16, color: colors.blackMain }}
            buttonText="Done"
          />
        </div>
      </InputRow>
    </InputGroupBox>
  );
};

const StartersCarousel = styled.div`
  display: flex;
  gap: 4px;
  overflow: scroll;
  padding: 0 ${margins.size3};
  margin-bottom: ${margins.size3};

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const StartersCarouselItem = styled.div`
  width: 136px;
  height: 136px;
  flex-shrink: 0;
  border-radius: 10px;
  padding: ${margins.size3};
  padding-top: ${margins.size4};
  background: ${colors.whiteMain};
  text-align: center;
  & > div {
    margin-top: ${margins.size3};
    font-family: ${fonts.extraBold};
    font-size: 16px;
  }
`;

const InputGroupBox = styled.div`
  background: white;
  border-radius: 15px;
  margin: ${margins.size3};
  padding: ${margins.size4};
  align-self: stretch;
`;

const DeleteButton = styled(Clickable)`
  width: 40px;
`;

const InterestsEditor: React.FC<{
  selected: Interests[];
  setSelected: (i: Interests[]) => void;
}> = ({ selected, setSelected }) => {
  const { openModal, closeModal, wrapperProps } = useSimpleModal();

  return (
    <>
      <SimpleModalWrap
        {...wrapperProps}
        cardStyle={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 100px)' }}
        actions={<WhiteCardAction title="Edit interests" onClick={closeModal} />}
      >
        <WhiteCardHeader title="Edit interests" onClose={closeModal} />
        <div style={{ flex: 1, overflowY: 'scroll', minHeight: 0, marginBottom: -margins.size3 }}>
          <InterestsGallery selected={selected} setSelected={setSelected} />
        </div>
      </SimpleModalWrap>
      <div style={{ marginTop: margins.size2 }}>
        {selected.map(interest => (
          <InterestItem key={interest.id} selected>
            <div
              role="button"
              tabIndex={-1}
              style={{ padding: 8, margin: -8, marginRight: -2 }}
              onClick={() => setSelected(selected.filter(i => i.id !== interest.id))}
            >
              <img alt="remove" src={xWhiteSvg} />
            </div>
            {interest.name}
          </InterestItem>
        ))}
      </div>
      {wrapperProps.state !== 'open' && (
        <Button secondary large style={{ background: 'transparent' }} onClick={openModal}>
          Edit interests
        </Button>
      )}
    </>
  );
};

const NewYearGoalRow: React.FC<{
  newYearGoal: string;
  index: number;
  onChange: (value: string) => void;
}> = ({ newYearGoal, index, onChange }) => {
  return (
    <SocialLinkRowContainer>
      <TextInput
        placeholder={`My goal #${index + 1}`}
        value={newYearGoal || ''}
        onChange={value => onChange(value)}
      />
      {newYearGoal ? (
        <DeleteButton onClick={() => onChange('')}>
          <img src={DeleteGoal} alt="delete" />
        </DeleteButton>
      ) : null}
    </SocialLinkRowContainer>
  );
};
