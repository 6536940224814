import React from 'react';
import styled from 'styled-components/macro';

import { Text, Heading1, Heading2, media, margins, colors } from 'css/css';

import { finalizeRegistration } from 'js/util/strings';
import { GoogleSignIn } from 'js/components/shared/GoogleSignIn';

import { connector, google, lock, lunchclub } from 'img/verify';

interface Props {
  canSkipGoogle: boolean;
  googleEmailAddress: string;
  onSkipOrFinish: () => void;
}

export const GoogleAuth: React.FC<Props> = ({
  canSkipGoogle,
  googleEmailAddress,
  onSkipOrFinish,
}) => (
  <GauthContainer>
    <Heading1 style={{ color: colors.primaryMain }}>Finish your registration</Heading1>
    <ImageContainer>
      <Icon src={lunchclub} alt="Lunchclub logo" />
      <img style={{ width: 90, margin: margins.size4 }} src={connector} alt="Arrow" />
      <Icon src={google} alt="Google logo" />
    </ImageContainer>
    <Heading2 style={{ marginBottom: margins.size2 }} color={colors.primaryMain}>
      {finalizeRegistration.gmailExplainTitle}
    </Heading2>
    <Text>{finalizeRegistration.gmailExplainBody}</Text>
    {canSkipGoogle && (
      <Text>
        {finalizeRegistration.skipMessage}
        <SkipButton onClick={onSkipOrFinish}>{finalizeRegistration.skipButton}</SkipButton>
      </Text>
    )}
    <img style={{ width: 24, margin: margins.size4 }} src={lock} alt="lock" />
    <Text style={{ marginBottom: margins.size4 }}>{finalizeRegistration.wereSerious}</Text>
    <GoogleSignIn
      buttonText={finalizeRegistration.finishSignIn}
      onLogIn={onSkipOrFinish}
      email={googleEmailAddress}
      isRegistering
    />
  </GauthContainer>
);

const GauthContainer = styled.div`
  max-width: 450px;
  margin: auto;
  margin-top: ${margins.size6};
  ${media.mobile} {
    padding: 10px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  max-width: 450px;
  margin: ${margins.size5} auto;
  ${media.mobile} {
    margin: ${margins.size3} auto;
  }
`;

const Icon = styled.img`
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
`;

const SkipButton = styled.button`
  color: ${colors.primaryMain};
  background-color: ${colors.whiteMain};
  cursor: pointer;
  outline: none;
  border: none;
  text-decoration: underline;
`;
