import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

import { colors, fonts } from 'css/css';

import { Avatar } from 'js/components/shared/Avatar';
import { Clubpoints } from 'js/components/shared/Clubpoints';
import { Backdrop } from 'js/components/shared/MobileModal';

import { ProfileMenuItem } from './ProfileMenu';

interface Props {
  user?: any;
  isOpen: boolean;
  menuItems: ProfileMenuItem[];
  onClose: () => void;
}

export const MobileNavDropdownMenu: React.FC<Props> = ({ menuItems, user, isOpen, onClose }) => {
  const history = useHistory();

  const handleClick = (onClick: () => void) => () => {
    onClose();
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <Backdrop isOpen={isOpen} onClick={onClose} />
      <MobileNavDropdownSheet isOpen={isOpen}>
        <MenuItem onClick={handleClick(() => history.push('/profile'))}>
          <Avatar
            src={user.image}
            ownProfile
            style={{
              maxWidth: 30,
              minWidth: 30,
              minHeight: 30,
              maxHeight: 30,
              border: '2px solid white',
              marginLeft: 1,
              marginRight: 1,
            }}
          />
          <div style={{ width: 20 }} />
          <div style={{ color: 'white', textDecoration: 'none' }}>
            {user.first_name} {user.last_name}
          </div>
          <div style={{ flex: 1 }} />
          <Clubpoints />
        </MenuItem>

        {menuItems
          .filter(i => i.type !== 'profile')
          .map(item => (
            <MenuItem key={item.label} onClick={handleClick(item.onClick)}>
              <img src={item.icon} alt="icon" style={{ width: 32, height: 32 }} />
              <div style={{ width: 20 }} />
              {item.label}
            </MenuItem>
          ))}
      </MobileNavDropdownSheet>
    </>
  );
};

const MenuItem = styled.div`
  display: flex;
  line-height: 44px;
  font-family: ${fonts.semiBold};
  font-size: 18px;
  align-items: center;
  cursor: default;
  user-select: none;
`;

const MobileNavDropdownSheet = styled.div<{ isOpen: boolean }>`
  background-color: ${colors.blackMain};
  padding: 20px;
  padding-bottom: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  transform: ${({ isOpen }) => (isOpen ? 'translate(0, 0)' : 'translate(0, 100%)')};
  transition: transform 300ms ease-in-out;
  font-family: ${fonts.regular};
`;
