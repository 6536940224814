import React from 'react';

import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';

import { CallV1Page } from '../callv1';
import { CallV2Page } from '../callv2';

import { useSharedCallContext } from './SharedCallContext';

export const CallVersionSelector: React.FC = () => {
  const { isV2 } = useSharedCallContext();

  return isV2 === undefined ? <SVGLoader /> : isV2 ? <CallV2Page /> : <CallV1Page />;
};
