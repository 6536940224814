import React, { createContext, createRef, useContext } from 'react';
import NotificationSystem from 'react-notification-system';

import { colors, FlexRow, margins, Text } from 'css/css';

import { useWindowSize } from 'js/util/custom-hooks';

import errorSvg from 'img/shared/notification/error.svg';
import successSvg from 'img/shared/notification/success.svg';

interface ContextProps {
  showNotif: {
    (notif: NotificationSystem.Notification): void;
  };
}

const NotifContext = createContext<ContextProps>({
  showNotif: () => undefined,
});

export const useNotifContext = () => useContext(NotifContext);

export const NotifProvider: React.FC = ({ children }) => {
  const notifSystem = createRef<NotificationSystem.System>();
  const [width] = useWindowSize();

  const showNotif = (notif: NotificationSystem.Notification) => {
    if (!notifSystem.current) {
      return;
    }

    const { message: originalMessage, level } = notif;
    const notifLevel = level || 'success';
    const newNotif = { ...notif, message: '', level: notifLevel };

    switch (notifLevel) {
      case 'success': {
        notifSystem.current.addNotification({
          position: 'tc',
          dismissible: false,
          children: (
            <FlexRow>
              <img style={{ marginRight: margins.size3 }} src={successSvg} alt="success" />
              <Text>{originalMessage}</Text>
            </FlexRow>
          ),
          ...newNotif,
        });
        break;
      }
      case 'error': {
        notifSystem.current.addNotification({
          position: 'tc',
          dismissible: false,
          children: (
            <FlexRow>
              <img style={{ marginRight: margins.size3 }} src={errorSvg} alt="success" />
              <Text>{originalMessage}</Text>
            </FlexRow>
          ),
          ...newNotif,
        });
        break;
      }
      default: {
        notifSystem.current.addNotification({
          position: 'tc',
          dismissible: false,
          ...notif,
        });
      }
    }
  };

  const contextValue = { showNotif };
  if (width <= 0) return <></>;

  const marginOffset = width >= 600 ? '-300px' : `-${width / 2}px`;
  const styles = {
    Containers: {
      DefaultStyle: {
        width: '100%',
        maxWidth: '600px',
      },

      tc: {
        margin: `0px ${marginOffset}`,
      },
    },
    NotificationItem: {
      DefaultStyle: {
        borderRadius: 10,
        display: 'inline-table',
        padding: margins.size3,
        marginTop: `calc(${margins.size2} + env(safe-area-inset-top))`,
      },

      success: {
        backgroundColor: colors.whiteMain,
        border: 'none',
        boxShadow: 'rgb(0 0 0 / 90%) 0px 0px 1px',
      },

      error: {
        backgroundColor: colors.whiteMain,
        border: 'none',
        boxShadow: 'rgb(0 0 0 / 90%) 0px 0px 1px',
      },
    },
  };

  return (
    <NotifContext.Provider value={contextValue}>
      <NotificationSystem ref={notifSystem} style={styles} />
      {children}
    </NotifContext.Provider>
  );
};
