import React from 'react';
import styled from 'styled-components/macro';

import { colors, margins } from 'css/css';

interface Props {
  position?: string;
  top?: string;
  right?: string;
  bottom?: string;
  width?: string;
  margin?: string;
  borderRadius?: string;
  isOpen: boolean;
}

export const Dropdown: React.FC<Props> = ({
  position,
  top,
  right,
  bottom,
  width,
  margin,
  borderRadius,
  children,
  isOpen,
}) => (
  <HamburgerMenu
    position={position}
    top={top}
    bottom={bottom}
    right={right}
    width={width}
    borderRadius={borderRadius}
    margin={margin}
    isOpen={isOpen}
  >
    {children}
  </HamburgerMenu>
);

const HamburgerMenu = styled.div<Props>`
  position: ${p => p.position || 'relative'};
  top: ${p => p.top || '0px'};
  right: ${p => p.right || '0px'};
  bottom: ${p => p.bottom || 'unset'};
  width: ${p => p.width || 'unset'};
  margin: ${p => p.margin || `0 ${margins.size1} 0 0`};
  border-radius: 0 0 ${p => p.borderRadius || '5px'} ${p => p.borderRadius || '5px'};
  z-index: -1;
  background-color: ${colors.blackMain};
  padding: ${margins.size3} 0;
  overflow-y: scroll;
  max-height: 420px;
  color: white;
  transform: ${p => (p.isOpen ? 'translate(0, 0)' : 'translate(0, -100%)')};
  transition: 300ms ease-in-out;
`;
