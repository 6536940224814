import React from 'react';
import styled from 'styled-components/macro';

import { FlexColumn, FlexRow, margins, colors, globalTransitionSettings, media } from 'css/css';

import { Banner } from 'js/components/navbar/banner';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { useUser } from 'js/providers/UserProvider';
import { getIsMobile } from 'js/util/util';

import { useCallV2Context } from './contexts/CallContext';
import { AudioTracks } from './components/AudioTracks';
import { Controls } from './components/Controls';
import { Sidebar } from './components/Sidebar';
import { Tile } from './components/Tile';
import { Tray } from './components/Tray';
import { useParticipantsContext } from './contexts/ParticipantsContext';
import { useUIStateContext } from './contexts/UIStateContext';
import { useActiveSpeakerTracker } from './hooks/useActiveSpeakerTracker';
import { useDailyTracks } from './hooks/useDailyTracks';
import { useJoinSound } from './hooks/useJoinSound';

export const Call: React.FC = () => {
  const user = useUser();

  const { match, state } = useCallV2Context();
  const { localParticipant, matchParticipant, screen } = useParticipantsContext();

  const { isBannerVisible, setIsBannerVisible, sidebarView } = useUIStateContext();

  const localAvatar = user?.image;
  const partnerAvatar = match?.image;
  const isMobile = getIsMobile();

  useDailyTracks();
  useJoinSound();
  useActiveSpeakerTracker();

  return (
    <>
      <Banner setIsBannerVisible={setIsBannerVisible} />
      {['ready', 'joining'].includes(state) ? (
        <Fullscreen isBannerVisible={isBannerVisible}>
          <SVGLoader />
          <Sidebar localAvatar={localAvatar} partnerAvatar={partnerAvatar} />
        </Fullscreen>
      ) : (
        <Fullscreen
          isBannerVisible={isBannerVisible}
          isScreenShareOn={!isMobile && !!screen}
          isSidebarOn={!!sidebarView}
        >
          <AudioTracks />
          {isMobile ? (
            <>
              <Tile participant={matchParticipant} avatarImg={partnerAvatar} />
              <Tile isLocal participant={localParticipant} avatarImg={localAvatar} />
            </>
          ) : (
            <TilesContainer isSidebarVisible={sidebarView !== null}>
              <FlexRow style={{ position: 'relative' }}>
                <Tile participant={screen} isScreen />
                {!!screen && !sidebarView && <div style={{ marginRight: margins.size2 }} />}
                <FlexColumn style={screen && sidebarView ? { position: 'absolute', right: 0 } : {}}>
                  <Tile participant={matchParticipant} avatarImg={partnerAvatar} />
                  <Tile isLocal participant={localParticipant} avatarImg={localAvatar} />
                </FlexColumn>
              </FlexRow>
            </TilesContainer>
          )}
          <Tray />
          <Sidebar localAvatar={localAvatar} partnerAvatar={partnerAvatar} />
          <Controls />
        </Fullscreen>
      )}
    </>
  );
};

// TODO: deal with this 56px hardcoded banner height
const Fullscreen = styled(FlexColumn)<{
  isBannerVisible: boolean;
  isScreenShareOn?: boolean;
  isSidebarOn?: boolean;
}>`
  position: relative;
  height: calc(100% - ${p => (p.isBannerVisible ? '56px' : '0px')});
  justify-content: center;
  background-color: ${colors.black};
  max-width: 100vw;
  overflow: hidden;
  transition: ${globalTransitionSettings};

  padding-left: ${margins.size3};
  padding-right: ${margins.size3};
  ${p =>
    p.isScreenShareOn &&
    `
    padding-bottom: 88px;
    padding-top: ${margins.size4};
  `}

  ${p => p.isSidebarOn && `padding-right: 452px;`}

  ${media.mobile} {
      padding: 0;
  }
`;

const TilesContainer = styled.div<{
  isSidebarVisible: boolean;
}>`
  transition: ${globalTransitionSettings};
`;
