import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

import {
  Card,
  Text,
  margins,
  FlexRow,
  FlexColumn,
  Heading2,
  Heading3,
  colors,
  borders,
} from 'css/css';

import { getTopInviters } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';
import { LeaderboardRow } from 'js/components/connections/components/LeaderboardRow';
import { GreyPage } from 'js/components/shared/page-wrappers';
import { Avatar } from 'js/components/shared/Avatar';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { Button } from 'js/components/shared/Button';
import { getIsMobile } from 'js/util/util';

import ladder from 'img/superinviters/ladder.svg';
import inviteEnvelope from 'img/shared/invite-envelope.svg';

export interface TopInviterInfo {
  converted_invites: number;
  first_name: string;
  image: string;
  last_name: string;
  profile: string;
  rank?: number;
}

export const SuperInviters: React.FC = () => {
  const history = useHistory();
  const [top50, setTop50] = useState<TopInviterInfo[]>();
  const [me, setMe] = useState<TopInviterInfo>();
  const [localeName, setLocaleName] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  useMountEffect(() => {
    const fetchTopInviters = async () => {
      const res = await getTopInviters();
      if (res.ok) {
        const { getJson } = res;
        setTop50(getJson.leaderboard);
        setMe(getJson.user_info);
        setLocaleName(getJson.locale);
        setIsLoading(false);
      }
    };
    fetchTopInviters();
  });

  const InviteCount = ({ numInvitees }: { numInvitees: number }) => (
    <InviteCountWrapper title="Users invited">
      <img src={inviteEnvelope} alt="" /> <Text>{numInvitees}</Text>
    </InviteCountWrapper>
  );

  const SelfRow = ({
    myInfo: { rank, image, first_name, last_name, converted_invites },
  }: {
    myInfo: TopInviterInfo;
  }) => (
    <SelfRowWrapper>
      <FlexRow>
        <SelfRank>{rank || ' '}</SelfRank>
        <Avatar size2 src={image} />
        <SelfName>
          {first_name} {last_name}
        </SelfName>
      </FlexRow>
      <InviteCount numInvitees={converted_invites} />
    </SelfRowWrapper>
  );

  return (
    <GreyPage fixedFullHeight>
      <Container>
        <FlexRow style={{ margin: `${margins.size3} 0px` }}>
          <MainHeading style={{ width: '100%', textAlign: 'left' }}>
            Superinviters in {localeName}!
          </MainHeading>
          <Button
            style={{ marginLeft: margins.size2 }}
            maxWidth="240px"
            onClick={() => history.push('/invite?ref=superinviters')}
          >
            <FlexRow justifyContent="center">
              Climb {!getIsMobile() && 'to the top'}
              <LadderImage />
            </FlexRow>
          </Button>
        </FlexRow>
        <WrapperCard>
          {isLoading || !top50 || !me ? (
            <SVGLoader />
          ) : (
            <>
              <SelfRow myInfo={me} />
              <FlexColumn alignItems="stretch">
                {top50.map((user, idx) => (
                  <LeaderboardRow
                    firstName={user.first_name}
                    lastName={user.last_name}
                    image={user.image}
                    profile={user.profile}
                    ConnectionStats={() => <InviteCount numInvitees={user.converted_invites} />}
                    rank={idx + 1}
                  />
                ))}
              </FlexColumn>
            </>
          )}
        </WrapperCard>
      </Container>
    </GreyPage>
  );
};

const WrapperCard = styled(Card)`
  height: 80vh;
  overflow-y: scroll;
`;

const SelfRowWrapper = styled(FlexRow)`
  justify-content: space-between;
  position: sticky;
  top: 0px;
  width: 100%;
  padding: ${margins.size3} 32px;
  border-bottom: ${borders.standard};
  background-color: ${colors.whiteMain};
`;

const InviteCountWrapper = styled(FlexRow)`
  justify-content: space-between;
  min-width: 45px;
`;

const SelfRank = styled(Heading2)`
  color: ${colors.primaryMain};
  width: 3em;
  margin: 0px;
`;

const SelfName = styled(Heading3)`
  margin-left: ${margins.size3};
  text-align: left;
`;

const MainHeading = styled(Heading2)`
  width: 100%;
  text-align: left;
  margin: auto;
`;

const Container = styled(Heading2)`
  max-width: 600px;
  margin: auto;
`;

const LadderImage = styled.img.attrs(() => ({ src: ladder, alt: '' }))`
  width: 16px;
  height: 16px;
  margin-left: ${margins.size2};
`;
