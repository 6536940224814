import React, { CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { Transition, TransitionStatus } from 'react-transition-group';

import { colors, FlexColumn, Heading2, margins } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { CodeInput } from 'js/components/shared/CodeInput';

import bgDetailSVG from 'img/mobile-root/landing-page/bg-detail.svg';
import wordmarkSVG from 'img/mobile-root/landing-page/hero-wordmark.svg';

import { MobileRootContainer, TRANSITION_DURATION, UserState } from '.';

const defaultButtonStyle = {
  marginTop: margins.size3,
  transition: `opacity 300ms ease-in-out`,
  opacity: 0,
};

const buttonTransitionStyles: Record<TransitionStatus, CSSProperties> = {
  unmounted: {},
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

interface EnterEmailProps {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  userState: UserState;
  onSubmit: () => void;
}

export const EnterCode: React.FC<EnterEmailProps> = ({ code, setCode, userState, onSubmit }) => (
  <MobileRootContainer color={colors.blackMain}>
    <BackgroundArt src={bgDetailSVG} alt="" />
    <Container>
      <Logo src={wordmarkSVG} alt="Lunchclub logo" />
      <Heading2 style={{ marginBottom: margins.size3 }} color={colors.whiteMain}>
        Enter code from your email
      </Heading2>
      <CodeInput setCode={setCode} onSubmit={onSubmit} />
      <Transition in={!!code.length} timeout={TRANSITION_DURATION}>
        {state => (
          <Button
            onClick={onSubmit}
            invalid={code.length !== 6}
            loading={userState === 'code-loading'}
            style={{
              ...defaultButtonStyle,
              ...buttonTransitionStyles[state],
            }}
            large
          >
            Log in
          </Button>
        )}
      </Transition>
    </Container>
  </MobileRootContainer>
);

const Container = styled(FlexColumn)`
  height: 100vh;
  padding: ${margins.size3};
  justify-content: center;
  z-index: 1;
`;

const Logo = styled.img`
  width: 64px;
  height: 64px;
  margin-bottom: ${margins.size3};
`;

const BackgroundArt = styled.img`
  position: fixed;
  width: 100vw;
  top: 25px;
`;
