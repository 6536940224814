import React from 'react';
import styled from 'styled-components/macro';

import { margins, FlexRow, SubText, Text, FlexColumn, colors, media } from 'css/css';

import { Button } from 'js/components/shared/Button';

import { WeeklyQuestionType } from '../types';

interface Props {
  question: WeeklyQuestionType;
  onClick: (option: number) => void;
  selected?: number;
}

export const WeeklyQuestion: React.FC<Props> = ({ question, onClick, selected }) => (
  <QuestionContainer>
    <Text>{question.text}</Text>

    <SubText>(Your answer will help us improve your Lunchclub experience)</SubText>

    <FlexRow
      style={{
        marginTop: margins.size3,
        flexDirection: question.options.length > 2 ? 'column' : 'row',
      }}
      role="radiogroup"
    >
      {question.options.map((q: { id: number; name: string }) => (
        <Button
          secondary
          large
          aria-checked={selected === q.id}
          style={{ marginRight: margins.size1 }}
          key={q.id}
          onClick={() => onClick(q.id)}
          active={selected === q.id}
        >
          {q.name}
        </Button>
      ))}
    </FlexRow>
  </QuestionContainer>
);

const QuestionContainer = styled(FlexColumn)`
  width: 100%;
  background-color: ${colors.greyLight};
  border-radius: 5px;
  text-align: left;
  padding: ${margins.size3};
  align-items: flex-start;
  margin: ${margins.size1};
  ${media.mobile} {
    margin: 0;
  }
`;
