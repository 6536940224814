import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { borders, colors, FlexRow, fonts, Heading3, margins, Text } from 'css/css';

import { useUser } from 'js/providers/UserProvider';
import {
  describeWeeklyTimeslots,
  getParameterByName,
  INTERNATIONAL_TIME_LOCALES,
} from 'js/util/util';
import { ToggleSwitch } from 'js/components/shared/ToggleSwitch';
import {
  DarkCardBackground,
  WhitePrompt,
  WhiteCard,
  WhiteCardHeader,
  WhiteCardListItem,
  WhiteCardListSeparator,
  WhiteSubprompt,
  WhiteCardAction,
} from 'js/components/shared/MobileModal';
import { postTestActivity } from 'js/util/api';
import { CoachingPlanOptInMobile } from 'js/components/coaching_plan/CoachingPlanOptInMobile';
import { CoachingPlanEligibilityResult } from 'js/api/coaching-plan';

import ClubpointIcon from 'img/navbar/clubpoints-white.svg';
import autopilotSvg from 'img/weekly/autopilot.svg';
import indicator from 'img/weekly/indicator.svg';
import { bestAnywhereSvg, currentCitySvg, homeCitySvg, aCitySvg } from 'img/weekly-v2';

import { CheckmarkAnimated } from '../registration/mobile/shared';
import { AvailibilityExplanation } from '../shared/availibility-explanation';

import { Locale } from './types';
import { MEETING_OPTIONS } from './pickers/options';

import { AUTOPILOT_TESTS, TIME_SELECTION_MIN } from '.';

const HEIGHT = `calc(min(500px, 100vh - 180px))`;

interface MobileMatchesModalProps {
  shortWeekly: boolean;
  homeLocale: Locale | null;
  localTimezone: string;
  currentSelectedLocale: Locale | null;
  localeTimeslotsByDay: {
    id: number;
    header: string;
    options: { id: number; option: string; label: string }[];
  }[];
  selectedTimeslots: string[];
  clickTimeslot: (timeslotOption: string) => void;
  targetLocale: Locale | null;
  changeTargetLocaleId: (localeId: number | null) => void;
  activeLocales: Locale[];
  onSubmit: () => Promise<void>;
  busyTimeslots: string[];

  // Slant test
  slantOptInTest: boolean;
  slantOptInChecked: boolean;
  onToggleSlantOptIn: (value: boolean) => void;
  showAutopilot: boolean;
  autopilotTimeslots: string[];
  submitWithAutopilot: () => void;

  autopilotVersion: number | undefined;

  // coaching plan
  onCoachingPlan: boolean;
  coachingPlanSignupType: number | undefined;
  showCoachingPlan: boolean;
  acceptCoachingPlan: () => void;
  declineCoachingPlan: () => void;
  submitWithCoachingPlan: () => void;
  coachingPlanEligibility?: CoachingPlanEligibilityResult;
}

function costNoticeFor(meetingCount: number, locale: Locale | null, homeLocaleId: number) {
  const lastIndexWithoutCost = MEETING_OPTIONS.findIndex(a => !!a.cost) - 1;
  const extraMeetingsCost = MEETING_OPTIONS[meetingCount].cost || 0;
  const extraMeetings = meetingCount - lastIndexWithoutCost;

  const localeCost = locale === null || locale.id === homeLocaleId ? 0 : 5 * meetingCount;
  const costEl = (
    <ClubpointsNoticePrice>
      {localeCost + extraMeetingsCost}
      <img src={ClubpointIcon} alt="points" />
    </ClubpointsNoticePrice>
  );

  return localeCost && extraMeetingsCost ? (
    <span>
      Using {costEl} to target {locale?.name} for&nbsp;{meetingCount}&nbsp;matches
    </span>
  ) : localeCost ? (
    <span>
      Using {costEl} to target {locale?.name}
    </span>
  ) : extraMeetingsCost ? (
    <span>
      Using {costEl} for {extraMeetings} extra meeting
      {extraMeetings > 1 ? 's' : ''}
    </span>
  ) : (
    undefined
  );
}

enum ModalStep {
  LOCALE_SELECTION = 0,
  TIMESLOT_SELECTION = 1,
  OPTIONAL_SERVICES = 2,
  AUTOPILOT = 3,
}

export const MobileMatchesModal: React.FC<MobileMatchesModalProps> = ({
  shortWeekly,
  homeLocale,
  localTimezone,
  activeLocales,
  targetLocale,
  changeTargetLocaleId,
  currentSelectedLocale,
  localeTimeslotsByDay,
  selectedTimeslots,
  clickTimeslot,
  busyTimeslots,
  onSubmit,
  slantOptInTest,
  slantOptInChecked,
  onToggleSlantOptIn,
  showAutopilot,
  autopilotTimeslots,
  submitWithAutopilot,
  autopilotVersion,
  onCoachingPlan,
  showCoachingPlan,
  coachingPlanSignupType,
  acceptCoachingPlan,
  declineCoachingPlan,
  submitWithCoachingPlan,
  coachingPlanEligibility,
}) => {
  const [step, setStep] = React.useState<ModalStep>(
    (shortWeekly && !coachingPlanEligibility?.eligible) || onCoachingPlan
      ? ModalStep.TIMESLOT_SELECTION
      : ModalStep.LOCALE_SELECTION,
  );
  const history = useHistory();
  const user = useUser();
  const initialLocale = React.useRef(currentSelectedLocale);
  const isEditingPreviousChoices = React.useRef(selectedTimeslots.length > 0);
  const didComeFromMeetings = getParameterByName('ref') === 'meetings';
  const [autopilotOptIn, setAutopilotOptIn] = React.useState(true);

  const platform = window?.bridge ? 'ios' : 'mobile';

  useEffect(() => {
    if (showAutopilot) {
      setStep(ModalStep.AUTOPILOT);
    }
  }, [showAutopilot]);

  const onClose = () => {
    if (showAutopilot) {
      if (autopilotVersion === AUTOPILOT_TESTS.AUTOPILOT_HABITUAL) {
        postTestActivity('new-user-autopilot', `${platform}-decline`);
      } else if (autopilotVersion === AUTOPILOT_TESTS.AUTOPILOT_ALL) {
        postTestActivity('autopilot-all', `${platform}-decline`);
      }
    }
    history.push(didComeFromMeetings ? '/meetings' : '/home');
  };
  const onPrev = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      onClose();
    }
  };

  const featuredLocales = activeLocales
    .filter(l => l.large)
    .sort((a, b) => (a.name || '').localeCompare(b.name || ''));

  const onPickLocale = (locale: Locale | null) => {
    changeTargetLocaleId(locale && locale.id !== undefined ? locale.id : null);
  };

  const [activeDayIdx, setActiveDayIdx] = React.useState(0);

  const selectedTimeslotsWithSlant = selectedTimeslots.length + (slantOptInChecked ? 1 : 0);
  const notice = costNoticeFor(selectedTimeslotsWithSlant, currentSelectedLocale, user.locale);

  const submitButton = (
    <WhiteCardAction
      disabled={
        (!isEditingPreviousChoices && selectedTimeslots.length < TIME_SELECTION_MIN) ||
        (!!coachingPlanSignupType && !selectedTimeslotsWithSlant)
      }
      title={
        coachingPlanSignupType
          ? 'Sign up for Curated Matches'
          : selectedTimeslotsWithSlant < TIME_SELECTION_MIN
          ? isEditingPreviousChoices
            ? 'Pass for the week'
            : `Choose at least ${TIME_SELECTION_MIN} time slot`
          : onCoachingPlan
          ? `Update Curated Matches time${selectedTimeslotsWithSlant > 1 ? 's' : ''} `
          : showAutopilot
          ? autopilotOptIn
            ? 'Turn on autopilot'
            : 'Skip autopilot'
          : autopilotVersion === AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX
          ? autopilotTimeslots.length > 1
            ? 'Confirm weekly times'
            : 'Confirm weekly time'
          : `Sign up for ${selectedTimeslotsWithSlant} match${
              selectedTimeslotsWithSlant > 1 ? 'es' : ''
            }`
      }
      onClick={
        onCoachingPlan
          ? submitWithCoachingPlan
          : coachingPlanSignupType
          ? onSubmit
          : autopilotVersion === AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX
          ? submitWithAutopilot
          : showAutopilot
          ? autopilotOptIn
            ? submitWithAutopilot
            : onClose
          : onSubmit
      }
      flashCompleteTitle={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {selectedTimeslotsWithSlant ? `You've signed up!` : `You've passed for the week.`}
          <div style={{ width: 10 }} />
          <CheckmarkAnimated />
        </div>
      }
    />
  );

  const continueWithAutopilotButton = (
    <WhiteCardAction
      disabled={!isEditingPreviousChoices && selectedTimeslots.length < TIME_SELECTION_MIN}
      title="Continue"
      onClick={onClose}
      flashCompleteTitle={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {selectedTimeslotsWithSlant ? `You've signed up!` : `You've passed for the week.`}
          <div style={{ width: 10 }} />
          <CheckmarkAnimated />
        </div>
      }
    />
  );

  const busyTimeslotsExist = busyTimeslots.length > 0;
  const show24hourTime = INTERNATIONAL_TIME_LOCALES.includes(user.locale);

  return (
    <DarkCardBackground>
      <Step
        active={
          step === ModalStep.LOCALE_SELECTION && (showCoachingPlan || !!coachingPlanSignupType)
        }
      >
        {coachingPlanEligibility?.eligible && !!coachingPlanEligibility?.details && (
          <CoachingPlanOptInMobile
            details={coachingPlanEligibility.details}
            accept={() => {
              acceptCoachingPlan();
              setStep(ModalStep.TIMESLOT_SELECTION);
            }}
            decline={() => {
              declineCoachingPlan();
            }}
            planType={coachingPlanEligibility.type || 0}
          />
        )}
      </Step>
      <Step
        active={
          step === ModalStep.LOCALE_SELECTION && !(showCoachingPlan || coachingPlanSignupType)
        }
      >
        <WhitePrompt>Where would you like your match to be?</WhitePrompt>
        <WhiteCard
          style={{ height: HEIGHT, display: 'flex', flexDirection: 'column', padding: '16px 0px' }}
        >
          <WhiteCardHeader onClose={onClose} title="Virtual Matches" localeSelector />
          <TargetScrollFadeWrap>
            <TargetsScrollWrap>
              <WhiteCardListItem
                icon={bestAnywhereSvg}
                title="Best match anywhere"
                onClick={() => onPickLocale(null)}
                selected={targetLocale === null}
              />
              {initialLocale.current?.large ? (
                <WhiteCardListItem
                  icon={currentCitySvg}
                  title="Current city"
                  subtitle={initialLocale.current.name}
                  onClick={() => onPickLocale(initialLocale.current)}
                  selected={targetLocale?.id === initialLocale.current.id}
                />
              ) : (
                undefined
              )}
              {homeLocale && initialLocale.current?.name !== homeLocale.name && homeLocale.large ? (
                <WhiteCardListItem
                  icon={homeCitySvg}
                  title="Home city"
                  subtitle={homeLocale.name}
                  onClick={() => onPickLocale(homeLocale)}
                  selected={targetLocale?.id === homeLocale.id}
                />
              ) : (
                undefined
              )}
              <WhiteCardListSeparator style={{ paddingLeft: '16px', marginBottom: '4px' }}>
                Target a city
              </WhiteCardListSeparator>
              {featuredLocales.map((l: Locale) => (
                <WhiteCardListItem
                  icon={aCitySvg}
                  title={l.name || ''}
                  subtitle=""
                  onClick={() => onPickLocale(l)}
                  selected={targetLocale?.id === l.id && l.id !== initialLocale.current?.id}
                />
              ))}
            </TargetsScrollWrap>
          </TargetScrollFadeWrap>
        </WhiteCard>
        <WhiteCardAction title="Next" onClick={() => setStep(ModalStep.TIMESLOT_SELECTION)} />
      </Step>
      <Step active={step === ModalStep.TIMESLOT_SELECTION}>
        <WhitePrompt>
          {coachingPlanSignupType || onCoachingPlan
            ? 'Schedule your Curated Matches'
            : autopilotVersion === AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX
            ? 'Schedule your weekly time'
            : 'Schedule your matches'}
        </WhitePrompt>
        <WhiteSubprompt>Times are in {localTimezone} based on your home city</WhiteSubprompt>
        <WhiteCard style={{ height: HEIGHT, display: 'flex', flexDirection: 'column' }}>
          <WhiteCardHeader
            onPrev={shortWeekly || onCoachingPlan || coachingPlanSignupType ? undefined : onPrev}
            onClose={onClose}
            title="Virtual Matches"
            subtitle={targetLocale?.name || 'Best match anywhere'}
          />
          <div style={{ height: 8, flexShrink: 0 }} />
          {localeTimeslotsByDay.length === 0 ? (
            <Text style={{ textAlign: 'center', marginTop: margins.size3 }}>
              The deadline has just passed, you&apos;re now signing up for next week!
            </Text>
          ) : (
            <CalendarDays>
              {localeTimeslotsByDay.map(({ id, header, options }, idx) => (
                <Day
                  header={header}
                  key={id}
                  active={activeDayIdx === idx}
                  picked={options.filter(o => selectedTimeslots.includes(o.option)).length}
                  onClick={() => setActiveDayIdx(idx)}
                />
              ))}
            </CalendarDays>
          )}
          <CalendarDayTimes>
            {(localeTimeslotsByDay[activeDayIdx]?.options || []).map(option => (
              <Time
                selected={selectedTimeslots.includes(option.option)}
                onClick={() => clickTimeslot(option.option)}
              >
                {busyTimeslotsExist && !busyTimeslots.includes(option.option) && (
                  <img src={indicator} style={{ marginLeft: -12, paddingRight: 6 }} alt="free" />
                )}
                {option.label}
              </Time>
            ))}
          </CalendarDayTimes>
          <AvailibilityExplanation
            shouldDisplay={busyTimeslotsExist}
            contentStyle={{ paddingBottom: 50 /* for clubpoints notice */ }}
          />
        </WhiteCard>
        {notice && <ClubpointsNotice>{notice}</ClubpointsNotice>}
        {slantOptInTest ? (
          <WhiteCardAction title="Next" onClick={() => setStep(ModalStep.OPTIONAL_SERVICES)} />
        ) : (
          submitButton
        )}
      </Step>

      <Step active={step === ModalStep.OPTIONAL_SERVICES}>
        <WhitePrompt>Anything else we should know?</WhitePrompt>
        <WhiteSubprompt>You&apos;re almost there!</WhiteSubprompt>
        <WhiteCard style={{ height: HEIGHT }}>
          <WhiteCardHeader onPrev={onPrev} onClose={onClose} title="Anything else?" />
          <div style={{ height: 8 }} />
          <Option
            isToggled={slantOptInChecked}
            onClick={() => onToggleSlantOptIn(!slantOptInChecked)}
          >
            <div style={{ flex: 1 }}>
              <Heading3 style={{ marginBottom: margins.size1 }}>Additional match</Heading3>
              <Text color={colors.blackMain}>
                Send me my best match and I&apos;ll figure out the time
              </Text>
            </div>
            <div style={{ width: 30, marginLeft: margins.size2 }}>
              <ToggleSwitch
                isOn={slantOptInChecked}
                onClick={() => onToggleSlantOptIn(!slantOptInChecked)}
              />
            </div>
          </Option>
        </WhiteCard>
        {notice && <ClubpointsNotice>{notice}</ClubpointsNotice>}
        {submitButton}
      </Step>

      <Step active={step === ModalStep.AUTOPILOT}>
        {autopilotVersion === AUTOPILOT_TESTS.AUTOPILOT_FORCED_NUX ? (
          <>
            <WhitePrompt>Welcome to Lunchclub</WhitePrompt>
            <WhiteSubprompt>New Connections Every Week</WhiteSubprompt>
            <WhiteCard
              style={{
                height: HEIGHT,
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <WhiteCardHeader onClose={onClose} title="It's a habit" />
              <div style={{ height: 8, flexShrink: 0 }} />
              <img
                style={{ width: '50%', height: '50%', margin: `${margins.size4} auto` }}
                src={autopilotSvg}
                alt=""
              />
              <Heading3 style={{ marginBottom: margins.size2 }}>
                You will receive{' '}
                {autopilotTimeslots.length > 1 ? 'Lunchclub matches' : 'a Lunchclub match'} every
                week on {describeWeeklyTimeslots(autopilotTimeslots, show24hourTime)}
              </Heading3>
              <Text style={{ marginBottom: margins.size4 }}>
                You can change this or skip a week at any time.
              </Text>
            </WhiteCard>
            {continueWithAutopilotButton}
          </>
        ) : (
          <>
            <WhitePrompt>Lunchclub on autopilot</WhitePrompt>
            <WhiteSubprompt>
              {autopilotVersion === AUTOPILOT_TESTS.AUTOPILOT_HABITUAL
                ? ' It looks like this is your preferred time to meet!'
                : 'Leave the work to Lunchclub!'}
            </WhiteSubprompt>
            <WhiteCard
              style={{
                height: HEIGHT,
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <WhiteCardHeader onClose={onClose} title="Make it a habit?" />
              <div style={{ height: 8, flexShrink: 0 }} />
              <img
                style={{ width: '50%', height: '50%', margin: `${margins.size4} auto` }}
                src={autopilotSvg}
                alt=""
              />
              <Heading3 style={{ marginBottom: margins.size2 }}>
                We can send you a new match every week
              </Heading3>
              <Text style={{ marginBottom: margins.size5 }}>
                You can opt-out or change at any time.
              </Text>
              <FlexRow width="100%" justifyContent="space-between">
                <Text>Every {describeWeeklyTimeslots(autopilotTimeslots, show24hourTime)}</Text>
                <ToggleSwitch
                  isOn={autopilotOptIn}
                  onClick={() => setAutopilotOptIn(!autopilotOptIn)}
                />
              </FlexRow>
            </WhiteCard>
            {submitButton}
          </>
        )}
      </Step>
    </DarkCardBackground>
  );
};

const Step = styled.div<{ active: boolean }>`
  transition: opacity 150ms linear;
  opacity: ${p => (p.active ? 1 : 0)};
  pointer-events: ${p => (p.active ? 'inherit' : 'none')};
  bottom: 0;
  position: absolute;
  transition-delay: ${p => (p.active ? '0' : '150ms')};
  width: 100%;

  ${WhitePrompt}, ${WhiteSubprompt} {
    opacity: ${p => (p.active ? 1 : 0)};
    transition: opacity 150ms linear;
    transition-delay: ${p => (p.active ? '150ms' : '0')};
  }
`;

const Option = styled.div<{ isToggled: boolean }>`
  padding: ${margins.size3} ${margins.size4};
  border: ${p =>
    p.isToggled ? `2px solid ${colors.primaryMain}` : `1px solid ${colors.greyMain}`};
  border-radius: 12px;
  display: flex;
`;

const CalendarDays = styled.div`
  display: flex;
  flex-direction: row;
  user-select: none;
  margin: 0 -2px;
`;

const Day: React.FC<{ header: string; active?: boolean; picked: number; onClick: () => void }> = ({
  active,
  header,
  picked,
  onClick,
}) => {
  const [dayOfWeek, , date] = header.split(' ');

  return (
    <DayWrap active={active} onClick={onClick}>
      <div className="square">
        <div className="dayOfWeek">{dayOfWeek}</div>
        <div className="date">{date}</div>
      </div>
      <div className={`picked ${picked > 0 && 'populated'}`}>{picked}</div>
    </DayWrap>
  );
};

const DayWrap = styled.div<{ active?: boolean }>`
  flex: 1;
  margin: 0 2px;

  .square {
    padding: 5px 0;
    background: ${p => (p.active ? colors.primaryMain : 'transparent')};
    border-radius: 13px;
    margin-bottom: 5px;
    color: ${p => (p.active ? colors.whiteMain : colors.blackMain)};
    text-align: center;
    .dayOfWeek {
      opacity: 0.6;
      padding: 2px 0;
    }
    .date {
      font-family: ${fonts.extraBold};
    }
  }

  .picked {
    border-radius: 13px;
    color: ${colors.whiteMain};
    margin-bottom: 5px;
    text-align: center;
    font-family: ${fonts.extraBold};
    &.populated {
      background: ${colors.secondaryMain};
    }
  }
`;

const CalendarDayTimes = styled.div`
  display: block;
  user-select: none;
  overflow: scroll;
`;

const Time = styled.div<{ selected?: boolean }>`
  background: ${p => (p.selected ? colors.primaryMain : colors.whiteMain)};
  border: ${p => (p.selected ? 'none' : `1px solid ${colors.greyMain}`)};
  color: ${p => (p.selected ? colors.whiteMain : colors.blackMain)};
  border-top: ${borders.transparent};
  border-bottom: ${borders.transparent};
  text-align: center;
  padding: 12px;
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 1px solid ${p => (p.selected ? 'transparent' : colors.greyMain)};
  }
  &:last-child {
    border-bottom: 1px solid ${p => (p.selected ? 'transparent' : colors.greyMain)};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const TargetScrollFadeWrap = styled.div`
  position: relative;
  flex: 1;
  min-height: 0;

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 90%
    );
    width: 100%;
    height: 2em;
  }
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
    width: 100%;
    height: 1em;
  }
`;
const TargetsScrollWrap = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  max-height: 100%;
  padding-top: 12px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ClubpointsNotice = styled.div`
  background: ${colors.tertiary3Main};
  text-align: center;
  color: ${colors.whiteMain};
  position: absolute;
  width: 100vw;
  transform: translateY(-100%);
  padding: ${margins.size3};
`;

const ClubpointsNoticePrice = styled.span`
  font-family: ${fonts.semiBold};
  align-items: center;
  display: inline-flex;
`;
