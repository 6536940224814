import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

import { Heading1, margins, colors, globalTransitionSettings } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { useUIStateContext } from 'js/components/callv2/contexts/UIStateContext';
import { useMediaDevicesContext } from 'js/components/callv2/contexts/MediaDevicesContext';

import settingsCameraIcon from 'img/call/settings-video.svg';
import settingsSpeakerIcon from 'img/call/settings-speaker.svg';
import settingsMicrophoneIcon from 'img/call/settings-microphone.svg';

export const SettingsModal = () => {
  const { setShowSettingsModal, showSettingsModal } = useUIStateContext();

  const {
    camError,
    cams,
    currentCam,
    currentMic,
    currentSpeaker,
    micError,
    mics,
    selectCamera,
    selectMic,
    selectSpeaker,
    speakers,
    areDevicesBlocked,
  } = useMediaDevicesContext();

  const handleCamChange = (id: string) => {
    selectCamera(cams.find(({ deviceId }) => deviceId === id)?.deviceId);
  };
  const handleMicChange = (id: string) => {
    selectMic(mics.find(({ deviceId }) => deviceId === id)?.deviceId);
  };
  const handleSpeakerChange = (id: string) => {
    selectSpeaker(speakers.find(({ deviceId }) => deviceId === id)?.deviceId);
  };

  const camOptions = useMemo(() => {
    if (areDevicesBlocked) return <option selected>Camera blocked</option>;

    switch (camError) {
      case 'in-use':
        return <option selected>Camera in use</option>;
      case 'not-found':
        return <option selected>Camera not found</option>;
      default:
        return getOptions(cams);
    }
  }, [areDevicesBlocked, camError, cams]);

  const micOptions = useMemo(() => {
    if (areDevicesBlocked) return <option selected>Microphone blocked</option>;

    switch (micError) {
      case 'in-use':
        return <option selected>Microphone in use</option>;
      case 'not-found':
        return <option selected>Microphone not found</option>;
      default:
        return getOptions(mics);
    }
  }, [areDevicesBlocked, micError, mics]);

  const speakerOptions = useMemo(() => {
    if (areDevicesBlocked) return <option selected>Microphone blocked</option>;

    return getOptions(speakers);
  }, [areDevicesBlocked, micError, speakers]);

  const handleClose = () => setShowSettingsModal(false);

  return (
    <Backdrop isVisible={showSettingsModal} onClick={handleClose}>
      <Content isVisible={showSettingsModal} onClick={e => e.stopPropagation()}>
        <Heading1 style={{ marginBottom: margins.size3 }}>Settings</Heading1>
        <Select
          icon={settingsCameraIcon}
          onChange={e => handleCamChange(e.target.value)}
          disabled={areDevicesBlocked}
          value={currentCam?.deviceId}
        >
          {camOptions}
        </Select>
        <Select
          icon={settingsSpeakerIcon}
          onChange={e => handleSpeakerChange(e.target.value)}
          disabled={areDevicesBlocked}
          value={currentSpeaker?.deviceId}
        >
          {speakerOptions}
        </Select>
        <Select
          icon={settingsMicrophoneIcon}
          onChange={e => handleMicChange(e.target.value)}
          disabled={areDevicesBlocked}
          value={currentMic?.deviceId}
        >
          {micOptions}
        </Select>
        <Button onClick={handleClose}>Done</Button>
      </Content>
    </Backdrop>
  );
};

const getOptions = (devices: MediaDeviceInfo[]) =>
  devices.map(device => (
    <option key={device.deviceId} value={device.deviceId}>
      {device.label}
    </option>
  ));

const Backdrop = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: ${globalTransitionSettings};
  opacity: ${p => (p.isVisible ? 1 : 0)};
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
`;

const Content = styled.div<{ isVisible: boolean }>`
  width: 600px;
  background-color: ${colors.whiteMain};
  padding: ${margins.size4};
  border-radius: 5px;
  text-align: start;

  transition: ${globalTransitionSettings};
  transform: translateY(${p => (p.isVisible ? 0 : '-50px')});
`;

const Select = styled.select<{ icon: string }>`
  color: #444;
  margin-bottom: ${margins.size3};
  padding: ${margins.size2};
  padding-left: 30px;
  width: 100%;
  border: 1px solid ${colors.greyMain};
  border-radius: 5px;
  background-image: url(${p => p.icon}),
    linear-gradient(${colors.whiteMain} 0%, ${colors.greyLight} 100%);
  background-repeat: no-repeat;
  background-position: left ${margins.size2} top 50%, 0 0;

  &:hover {
    cursor: pointer;
    border: 1px solid ${colors.primaryMain};
  }
`;
