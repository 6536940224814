import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { margins, colors, fonts, media, fontSizes } from 'css/css';

import { Navbar } from 'js/components/navbar';

interface BasicPageProps {
  showHeader?: boolean;
}
export const BasicPage: React.FC<BasicPageProps> = ({ children, showHeader = true }) => (
  <>
    <ContentContainer>{children}</ContentContainer>
    {showHeader && <Navbar />}
  </>
);

interface GreyPageProps {
  fixedFullHeight?: boolean;
  style?: React.CSSProperties;
  className?: string;
  shouldDisplayNavbar?: boolean;
  setNavbarHeaderHeight?: (height: number) => void;
}
export const GreyPage: React.FC<GreyPageProps> = ({
  children,
  fixedFullHeight = false,
  style,
  className,
  shouldDisplayNavbar = true,
  setNavbarHeaderHeight,
}) => {
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (setNavbarHeaderHeight) {
      setNavbarHeaderHeight(headerHeight);
    }
  }, [headerHeight, setNavbarHeaderHeight]);

  return (
    <GreyPageContainer>
      <GreyPageContent headerHeight={headerHeight} fixedFullHeight={fixedFullHeight}>
        <ContentContainer
          fixedFullHeight={fixedFullHeight}
          headerHeight={headerHeight}
          style={style}
          className={className}
        >
          {children}
        </ContentContainer>
      </GreyPageContent>
      {shouldDisplayNavbar && <Navbar setHeaderHeight={setHeaderHeight} isGreyPage />}
    </GreyPageContainer>
  );
};

const GreyPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

interface GreyPageContentProp {
  headerHeight: number;
  fixedFullHeight: boolean;
}
const GreyPageContent = styled.div<GreyPageContentProp>`
  flex: 1;
  padding-top: ${p => p.headerHeight}px;
  background-color: ${colors.greyLight};

  ${media.mobile} {
    padding-top: 0;
    padding-bottom: ${p => p.headerHeight}px;
    ${p => (p.fixedFullHeight ? 'min-height: 0;' : '')}
  }
`;

const ContentContainer = styled.div<{
  fixedFullHeight?: boolean;
  headerHeight?: number;
}>`
  font-family: ${fonts.regular};
  color: ${colors.primaryMain};
  padding-bottom: ${margins.size7};
  padding-top: ${margins.size4};
  width: 70%;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  ${media.mobile} {
    width: 100%;
    padding: ${margins.size4} ${margins.size3};
    text-align: center;
    padding-top: calc(max(${margins.size4}, env(safe-area-inset-top)));
    user-select: none;
    overflow: auto;
  }

  ${p =>
    p.fixedFullHeight &&
    `
    padding: 0;
    width: 100%;
    max-width: 100%;
    height: calc(100vh - ${p.headerHeight}px);
    ${media.mobile} {
      height: 100%;
    }
  `}
`;

export const MobilePageHeader = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.size5};
  color: ${colors.blackMain};
  text-align: left;
  margin-bottom: ${margins.size3};
  margin-top: 20px;
  display: flex;
  user-select: none;
`;
