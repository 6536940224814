import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { margins, MainHeading } from 'css/css';

import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { GreyPage } from 'js/components/shared/page-wrappers';
import { useUser } from 'js/providers/UserProvider';
import { Login } from 'js/components/shared/Login';
import { postUserUnsubscribe } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';
import { getElliotToken, getParameterByName, lunchclubTypes } from 'js/util/util';

export const Unsubscribe: React.FC = () => {
  const user = useUser();
  const history = useHistory();
  const [loggingIn, setLoggingIn] = useState(false);
  const [loading, setLoading] = useState(true);

  const unsubTokenFromURL = getParameterByName('unsub_token');
  const canUnsubscribe = unsubTokenFromURL || getElliotToken();

  useMountEffect(() => {
    if (!canUnsubscribe) {
      setLoggingIn(true);
    } else if (unsubTokenFromURL) {
      postUserUnsubscribe({
        token: unsubTokenFromURL,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (user) {
      const { user_type: userType } = user;
      if (userType === lunchclubTypes.verified) {
        // TODO: Pass specific email unsubscribe type to auto-unsub.
        history.push('/settings?tab=notifications');
      } else if (userType === lunchclubTypes.unverified || userType === lunchclubTypes.waitlisted) {
        postUserUnsubscribe({});
        setLoading(false);
      }
    }
  }, [user]);

  if (loggingIn) return <Login handleLogin={() => setLoggingIn(false)} />;
  if (loading) return <SVGLoader />;
  return (
    <GreyPage>
      <MainHeading style={{ marginTop: margins.size7, marginBottom: margins.size7 }}>
        Email unsubscribed
      </MainHeading>
    </GreyPage>
  );
};
