import React, { useEffect, useRef } from 'react';

interface Props {
  track: MediaStreamTrack;
}

export const AudioTrack: React.FC<Props> = ({ track }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (!audioRef.current) return () => undefined;

    let playTimeout: NodeJS.Timeout;
    const handleCanPlay = () => {
      playTimeout = setTimeout(() => {
        // eslint-disable-next-line no-console
        console.error('Failed to play audio');
      }, 1500);
    };
    const handlePlay = () => {
      clearTimeout(playTimeout);
    };

    audioRef.current.addEventListener('canplay', handleCanPlay);
    audioRef.current.addEventListener('play', handlePlay);
    audioRef.current.srcObject = new MediaStream([track]);

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('canplay', handleCanPlay);
        audioRef.current.removeEventListener('play', handlePlay);
      }
    };
  }, [audioRef, track]);

  return track ? <audio autoPlay playsInline ref={audioRef} /> : null;
};
