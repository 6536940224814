import React from 'react';

import PreferenceArrow from 'img/weekly/preferences-arrow.svg';

import { SpecifyMatchesHeading, PreferencesArrow } from './styles';

interface Props {
  shortWeekly: boolean;
  isPreferencesOpen: boolean;
  setIsPreferencesOpen: (isPreferencesOpen: boolean) => void;
}

export const PreferencesDropdown: React.FC<Props> = ({
  isPreferencesOpen,
  setIsPreferencesOpen,
  shortWeekly,
}) =>
  !shortWeekly ? (
    <SpecifyMatchesHeading onClick={() => setIsPreferencesOpen(!isPreferencesOpen)}>
      <PreferencesArrow src={PreferenceArrow} $isPreferencesOpen={isPreferencesOpen} />
      Specify your matches
    </SpecifyMatchesHeading>
  ) : null;
