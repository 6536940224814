import {
  DailyEvent,
  DailyEventObject,
  DailyEventObjectActiveSpeakerChange,
} from '@daily-co/daily-js';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { sortByKey } from 'js/components/callv2/utils/sorting';
import { useUser } from 'js/providers/UserProvider';

import { useCallV2Context } from '../CallContext';

import { initialParticipantsState, participantsReducer } from './participantsState';
import { Participant } from './types';
import { isLocalId } from './utils';

interface ContextValue {
  activeParticipant?: Participant;
  allParticipants: Participant[];
  currentSpeaker?: Participant;
  localParticipant?: Participant;
  matchParticipant?: Participant;
  screen?: Participant;
  participants: Participant[];
  doubleJoin?: boolean;
  isLocalSpeaking?: boolean;
}

// eslint-disable-next-line no-console

export const ParticipantsContext = createContext<ContextValue>({
  allParticipants: [],
  participants: [],
  doubleJoin: false,
});

export const ParticipantsContextProvider: React.FC = ({ children }) => {
  const user = useUser();

  const { daily, networkState, videoQuality, match } = useCallV2Context();
  const [state, dispatch] = useReducer(participantsReducer, initialParticipantsState);
  const [isLocalSpeaking, setIsLocalSpeaking] = useState<boolean | undefined>(undefined);

  /**
   * ALL participants (incl. shared screens) in a convenient array
   */
  const allParticipants = useMemo(() => state?.participants, [state?.participants]);
  const allScreens = useMemo(() => state?.screens, [state?.screens]);

  /**
   * The participant who most recently got mentioned via a `active-speaker-change` event
   */
  const activeParticipant = useMemo(() => state.participants.find(p => p.isActiveSpeaker), [
    state.participants,
  ]);

  /**
   * The local participant
   */
  const localParticipant = useMemo(() => allParticipants.find(p => p.isLocal), [allParticipants]);

  /**
   * The match participant
   */
  const matchParticipant = useMemo(() => {
    const activeMatchParticipant = allParticipants.find(
      p =>
        match &&
        user &&
        p.name !== user.profile_id &&
        !p.isLocal &&
        (!p.isCamMuted || !p.isMicMuted),
    );
    if (activeMatchParticipant) return activeMatchParticipant;
    return allParticipants.find(p => match && p.name === match.profile_id);
  }, [allParticipants, match, user]);

  /**
   * True if user already in the call
   */
  const doubleJoin = useMemo(
    () => allParticipants.some(p => user && p.name === user.profile_id && !p.isLocal),
    [allParticipants, user],
  );

  useEffect(() => {
    if (user && daily && localParticipant && localParticipant.name !== user.profile_id) {
      daily.setUserName(user.profile_id);
    }
  }, [allParticipants, user, daily, localParticipant]);

  const screen = useMemo(() => allScreens.find(p => p.isScreenshare), [allScreens]);

  /**
   * The participant who should be rendered prominently right now
   */
  const currentSpeaker = useMemo(() => {
    /**
     * Ensure activeParticipant is still present in the call.
     * The activeParticipant only updates to a new active participant so
     * if everyone else is muted when AP leaves, the value will be stale.
     */
    const isPresent = state.participants.some(p => p?.id === activeParticipant?.id);

    const displayableParticipants = state.participants.filter(p => !p?.isLocal);

    if (
      !isPresent &&
      displayableParticipants.length > 0 &&
      displayableParticipants.every(p => p.isMicMuted && !p.lastActiveDate)
    ) {
      // Return first cam on participant in case everybody is muted and nobody ever talked
      // or first remote participant, in case everybody's cam is muted, too.
      return displayableParticipants.find(p => !p.isCamMuted) ?? displayableParticipants?.[0];
    }

    const sorted = displayableParticipants
      .sort((a, b) => sortByKey(a, b, 'lastActiveDate'))
      .reverse();

    return isPresent ? activeParticipant : sorted?.[0] ?? localParticipant;
  }, [activeParticipant, localParticipant, state.participants]);

  const handleNewParticipantsState = useCallback(
    <T extends DailyEvent>(event?: DailyEventObject<T>) => {
      switch (event?.action) {
        case 'participant-joined':
          dispatch({
            type: 'PARTICIPANT_JOINED',
            participant: event.participant,
          });

          break;
        case 'participant-updated':
          dispatch({
            type: 'PARTICIPANT_UPDATED',
            participant: event.participant,
          });
          break;
        case 'participant-left':
          dispatch({
            type: 'PARTICIPANT_LEFT',
            participant: event.participant,
          });
          break;
        default:
          break;
      }
    },
    [daily, dispatch],
  );

  /**
   * Start listening for participant changes, when the daily is set.
   */
  useEffect(() => {
    if (!daily) return () => undefined;

    const events: DailyEvent[] = [
      'joined-meeting',
      'participant-joined',
      'participant-updated',
      'participant-left',
    ];

    // Use initial state
    handleNewParticipantsState();

    // Listen for changes in state
    events.forEach(event => daily.on(event, handleNewParticipantsState));

    // Stop listening for changes in state
    return () => {
      events.forEach(event => daily.off(event, handleNewParticipantsState));
    };
  }, [daily, handleNewParticipantsState]);

  const participantIds = useMemo(() => state.participants.map(p => p.id).join(','), [
    state.participants,
  ]);

  const setBandWidthControls = useCallback(() => {
    if (typeof rtcpeers === 'undefined') return;
    const sfu = rtcpeers?.soup;
    // @ts-ignore
    const isSFU = rtcpeers?.currentlyPreferred?.typeName?.() === 'sfu';
    if (!isSFU) return;

    const ids = participantIds.split(',');

    ids.forEach(id => {
      if (isLocalId(id)) return;

      if (
        // weak or bad network
        (['low', 'very-low'].includes(networkState) && videoQuality === 'auto') ||
        // Low quality or Bandwidth saver mode enabled
        ['bandwidth-saver', 'low'].includes(videoQuality)
      ) {
        sfu.setPreferredLayerForTrack(id, 'cam-video', 0);
      }

      // Grid view settings are handled separately in GridView
      // Mobile view settings are handled separately in MobileCall
    });
  }, [currentSpeaker?.id, networkState, participantIds, videoQuality]);

  useEffect(() => {
    setBandWidthControls();
  }, [setBandWidthControls]);

  useEffect(() => {
    if (!daily) return () => undefined;
    const handleActiveSpeakerChange = (event?: DailyEventObjectActiveSpeakerChange) => {
      /**
       * Ignore active-speaker-change events for the local user.
       * Our UX doesn't ever highlight the local user as the active speaker.
       */
      const localId = daily.participants().local.session_id;

      setIsLocalSpeaking(
        !event?.activeSpeaker?.peerId ? undefined : event.activeSpeaker.peerId === localId,
      );

      if (localId === event?.activeSpeaker?.peerId) return;

      dispatch({
        type: 'ACTIVE_SPEAKER',
        id: event?.activeSpeaker?.peerId ?? '',
      });
    };
    daily.on('active-speaker-change', handleActiveSpeakerChange);
    return () => {
      daily.off('active-speaker-change', handleActiveSpeakerChange);
    };
  }, [daily]);

  return (
    <ParticipantsContext.Provider
      value={{
        activeParticipant,
        allParticipants,
        currentSpeaker,
        localParticipant,
        matchParticipant,
        screen,
        participants: state.participants,
        doubleJoin,
        isLocalSpeaking,
      }}
    >
      {children}
    </ParticipantsContext.Provider>
  );
};

export const useParticipantsContext = () => useContext(ParticipantsContext);
