import React from 'react';
import styled from 'styled-components/macro';

import { colors, globalTransitionSettings, margins, media, FlexRow, Link, Text } from 'css/css';

import { Partnership } from 'types/partnership-whitelabel';

export const AccountLogin: React.FC<{
  partnership?: Partnership;
}> = ({ partnership }) => (
  <Container>
    <Text color={partnership?.theme.descriptionColor || colors.blackMid}>
      Already have an account?&nbsp;
    </Text>
    <Link to="/login">
      <LoginText>Log in here.</LoginText>
    </Link>
  </Container>
);

const Container = styled(FlexRow)`
  ${media.mobile} {
    margin-top: ${margins.size2};
  }
`;

const LoginText = styled(Text)`
  color: ${colors.primaryMain};
  transition: ${globalTransitionSettings};
  text-decoration: underline;
  text-decoration-color: ${colors.primaryMain};
  &:hover {
    text-decoration-color: ${colors.primaryMain};
    color: ${colors.primaryMain};
  }
`;
