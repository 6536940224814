import React, { useState } from 'react';
import styled from 'styled-components/macro';
import cloneDeep from 'lodash/cloneDeep';

import {
  borders,
  colors,
  margins,
  media,
  fontSizes,
  Clickable,
  EditTextArea,
  FadedText,
  FlexRow,
  Heading2,
  Heading3,
  ShowMobile,
  globalTransitionSettings,
  Text,
} from 'css/css';

import rocket from 'img/profile/conversation-starters/new-project.svg';
import ask from 'img/profile/conversation-starters/ask.svg';
import puzzle from 'img/profile/conversation-starters/learn.svg';
import bulb from 'img/profile/conversation-starters/mind.svg';
import guitar from 'img/profile/conversation-starters/just-learned.svg';

import { Button } from '../Button';
import { DesktopEditButton } from '../DesktopEditButton';
import { SaveAndCancel } from '../selectors/SaveAndCancel';

export const ConversationStarters = ({
  profileConversations,
  conversations,
  startAddingConversation,
  onChangeConversation,
  addingConversation,
  addConversation,
  editConversation,
  saveConversation,
  deleteConversation,
  isPublic,
  setPublic,
  canEdit = true,
}: any) => {
  const conversationsInit = [
    {
      id: 'likeToLearn',
      imgSrc: puzzle,
      title: "I'd like to learn about...",
      placeholder: 'I want to understand more about psychology.',
      selected: false,
    },
    {
      id: 'askMeAbout',
      imgSrc: ask,
      title: 'Ask me about...',
      placeholder:
        'Transitioning from the service industry to real estate, or how to dominate at scrabble.',
      selected: false,
    },
    {
      id: 'topOfMind',
      imgSrc: bulb,
      title: 'Top of mind for me...',
      placeholder: "I've been thinking about productivity apps and the Pomodoro Technique.",
      selected: false,
    },
    {
      id: 'justLearned',
      imgSrc: guitar,
      title: 'Something I just learned...',
      placeholder:
        'Nearly all the citrus we eat are hybrids descended from just 5 core citrus fruits, none of which are an orange!',
      selected: false,
    },
    {
      id: 'sideHustle',
      imgSrc: rocket,
      title: 'My side hustle...',
      placeholder: 'Teaching piano lessons over zoom.',
      selected: false,
    },
  ];

  const [conversationsMap, setConversationsMap] = useState(conversationsInit);

  const onClickConversation = (conversationID: any) => {
    const convIndex = conversationsMap.findIndex((conv: any) => conv.id === conversationID);
    const clonedMap = cloneDeep(conversationsMap);
    clonedMap[convIndex].selected = !clonedMap[convIndex].selected;
    setConversationsMap(clonedMap);
  };

  if (profileConversations) {
    const currentConversations = conversationsMap.filter(conversation =>
      Object.keys(conversations).includes(conversation.id),
    );
    const remainingConversations = conversationsMap.filter(
      conversation => !Object.keys(conversations).includes(conversation.id),
    );

    const canAddMore = currentConversations.length < 5 && canEdit;
    return (
      <>
        {!isPublic && canEdit && currentConversations.length > 0 && (
          <Conversation
            clickable
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              backgroundColor: colors.greyLight,
              marginBottom: margins.size1,
            }}
          >
            <div style={{ width: '275px', margin: '0 auto' }}>
              <Text color={colors.blackLight} style={{ marginBottom: margins.size1 }}>
                Your conversation starters are not visible to people outside your network
              </Text>

              <Clickable onClick={setPublic}>
                <Heading3 color={colors.primaryMain}>Make public now</Heading3>
              </Clickable>
            </div>
          </Conversation>
        )}
        {currentConversations.map(conversation => {
          const conversationDetails = conversations[conversation.id];

          return (
            <Conversation style={{ marginBottom: margins.size1 }} key={conversation.id}>
              {!canEdit && <div style={{ height: 24 }} />}

              <DesktopEditButton
                show={!conversationDetails.editing && canEdit}
                startEditing={() => editConversation(conversation.id, conversationDetails.value)}
              />

              <ConversationBox editing={conversationDetails.editing}>
                <FlexRow>
                  <img
                    src={conversation.imgSrc}
                    alt={conversation.title}
                    style={{ marginRight: margins.size3 }}
                  />

                  <Heading2>{conversation.title}</Heading2>
                </FlexRow>
                {conversationDetails.editing ? (
                  <>
                    <EditTextArea
                      value={conversationDetails.value}
                      style={{ marginTop: margins.size3 }}
                      onChange={(e: any) => editConversation(conversation.id, e.target.value)}
                    />

                    <SaveAndCancel
                      editing={conversationDetails.editing}
                      clickCancel={() => deleteConversation(conversation.id)}
                      clickSave={() => saveConversation(conversation.id, conversationDetails.value)}
                      cancelLabel="Delete"
                    />
                  </>
                ) : (
                  <>
                    <FadedText style={{ marginTop: margins.size2, marginLeft: 40 }}>
                      {conversations[conversation.id].value}
                    </FadedText>
                    {canEdit && (
                      <ShowMobile>
                        <Button
                          style={{ marginTop: margins.size4 }}
                          onClick={() =>
                            editConversation(conversation.id, conversationDetails.value)
                          }
                        >
                          Edit conversation starter
                        </Button>
                      </ShowMobile>
                    )}
                  </>
                )}
              </ConversationBox>
            </Conversation>
          );
        })}
        {canAddMore && addingConversation && (
          <Conversation>
            <ConversationBox editing style={{ display: 'flex', flexDirection: 'column' }}>
              {remainingConversations.map(remainingConversation => (
                <NewConversation
                  key={remainingConversation.id}
                  onClick={() => addConversation(remainingConversation.id)}
                >
                  <NewConversationBox style={{ margin: margins.size4 }}>
                    <img
                      src={remainingConversation.imgSrc}
                      alt={remainingConversation.title}
                      style={{ marginRight: margins.size3 }}
                    />

                    <Heading2>{remainingConversation.title}</Heading2>
                  </NewConversationBox>
                </NewConversation>
              ))}
            </ConversationBox>
          </Conversation>
        )}
        {canAddMore && !addingConversation && (
          <Clickable onClick={startAddingConversation} style={{ width: '100%' }}>
            <Conversation
              clickable
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Heading3 color={colors.primaryMain}>+ Add a conversation starter</Heading3>
            </Conversation>
          </Clickable>
        )}
      </>
    );
  }

  return (
    <>
      {conversationsMap.map(conversation => (
        <ConversationItem
          key={conversation.id}
          onClick={(event: any) => {
            // Only trigger if we click the parent element, not the child text box
            if (event.target.type === 'textarea') {
              return;
            }
            onClickConversation(conversation.id);
          }}
        >
          <FlexRow>
            <div style={{ marginRight: margins.size3 }}>
              <img
                style={{ width: 24, height: 24 }}
                src={conversation.imgSrc}
                alt={conversation.title}
              />
            </div>

            <Heading3 style={{ textAlign: 'left' }}>{conversation.title}</Heading3>
          </FlexRow>

          {conversation.selected && (
            <ConversationTextBox
              placeholder={conversation.placeholder}
              value={conversations[conversation.id].value}
              onChange={(event: any) => {
                onChangeConversation(conversation.id, event.target.value);
              }}
            />
          )}
        </ConversationItem>
      ))}
    </>
  );
};

const NewConversationBox = styled(FlexRow)`
  margin: ${margins.size4};
  ${media.mobile} {
    margin: ${margins.size3};
  }
`;

const ConversationItem = styled.div`
  border: ${borders.standard};
  box-sizing: border-box;
  border-radius: 3px;
  padding: ${margins.size4} ${margins.size4};
  margin: ${margins.size1} 0;
  cursor: pointer;
`;

const ConversationTextBox = styled.textarea`
  outline: none;
  border: none;
  height: 80px;
  margin-top: ${margins.size3};
  width: 100%;
  padding: ${margins.size3};
  margin-bottom: ${margins.size3};
  background: ${colors.greyLight};
  resize: none;
  border-radius: 3px;
  font-size: ${fontSizes.size2};
  color: ${colors.blackMid};
  &::placeholder {
    color: ${colors.blackLight};
  }
`;

type ConversationTypes = { clickable?: boolean };
const Conversation = styled.div<ConversationTypes>`
  background-color: ${colors.whiteMain};
  min-height: 125px;
  max-width: 600px;
  border: ${borders.standard};
  border-radius: 5px;
  text-align: right;
  margin: 0 auto;
  transition: ${globalTransitionSettings};
  &: hover {
    background-color: ${p => p.clickable && colors.greyLight};
  }
`;

type ConversationBoxTypes = { editing: boolean };
const ConversationBox = styled.div<ConversationBoxTypes>`
  padding: 0px;
  text-align: left;
  margin: ${p =>
    p.editing ? margins.size4 : `0px ${margins.size4} ${margins.size4} ${margins.size4}`};
  ${media.mobile} {
    margin-top: ${margins.size4};
  }
`;

const NewConversation = styled.button`
  border: ${borders.standard};
  border-radius: 5px;
  background-color: ${colors.whiteMain};
  margin-bottom: ${margins.size2};
  :focus {
    outline: none;
  }
  transition: ${globalTransitionSettings};
  cursor: pointer;
  &:hover {
    background-color: ${colors.greyLight};
  }
`;
