import React from 'react';
import styled from 'styled-components/macro';

import {
  Card,
  margins,
  Heading1,
  Text,
  FlexColumn,
  fonts,
  colors,
  fontSizes,
  globalTransitionSettings,
} from 'css/css';

import { GreyPage } from 'js/components/shared/page-wrappers';
import { useUser } from 'js/providers/UserProvider';

const FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSfVNNZIaSpvOGOpMftktIaVI-MpPyYbUZAp4Ag91xf4ami8PA/viewform?entry.1584333928=';

export const Snowflaked = () => {
  const { email } = useUser();
  return (
    <GreyPage>
      <Card style={{ paddingTop: margins.size6, paddingBottom: margins.size6 }}>
        <Heading1>Tell us more about you!</Heading1>

        <FlexColumn style={{ maxWidth: '450px', margin: '0 auto' }}>
          <Text>
            We need a bit more information before we can start curating relevant matches for you and
            would love to learn more about your professional background and goals.
          </Text>

          <ButtonLink href={`${FORM_URL}${email}`} target="_blank" rel="noopener noreferrer">
            Tell us more
          </ButtonLink>

          <Text style={{ marginTop: margins.size3 }}>
            If you have already filled out this form,{' '}
            <span style={{ fontFamily: fonts.bold }}> there is no need to resubmit!</span> We are
            reviewing these as quickly as possible and we will reach back shortly.
          </Text>
        </FlexColumn>
      </Card>
    </GreyPage>
  );
};

const ButtonLink = styled.a`
  width: 100%;
  display: inline;
  max-width: 350px;
  margin: 0 auto;
  margin-top: ${margins.size3};
  padding: ${margins.size2} 0;
  background-color: ${colors.primaryMain};
  color: ${colors.whiteMain};
  border-radius: 5px;
  border: none;
  box-shadow: none;
  font-family: ${fonts.regular};
  font-size: ${fontSizes.size2};
  cursor: 'pointer';
  transition: ${globalTransitionSettings};
  &:hover {
    background-color: ${colors.primaryDark};
    color: ${colors.whiteMain};
    text-decoration: none;
  }
`;
