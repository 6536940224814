import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { useHistory } from 'react-router';

import {
  FlexRow,
  FlexColumn,
  Heading3,
  Text,
  margins,
  fonts,
  globalTransitionSettings,
  colors,
  media,
  fontSizes,
  SubText,
} from 'css/css';

import Channel, { LCMessageType, SuperMessage } from 'types/channel';

import { Avatar as BaseAvatar } from 'js/components/shared/Avatar';
import { useUser } from 'js/providers/UserProvider';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { getIsMobile, getParameterByName } from 'js/util/util';

import { ChatTabs } from '.';

interface Props {
  previewChannel: Channel;
  filteredChannels: Channel[];
  setOpenTab: React.Dispatch<React.SetStateAction<ChatTabs>>;
}

export const ConversationPreview: React.FC<Props> = ({
  previewChannel,
  filteredChannels,
  setOpenTab,
}) => {
  const [isUnread, setIsUnread] = useState(false);
  const history = useHistory();
  const user = useUser();
  const { selectedProfileId, setSelectedProfileId } = useChatContext();
  const myProfileId = user.profile_id;
  const channelRef = useRef<HTMLDivElement>(null);
  const linkedProfileId = getParameterByName('chat_id');
  const isMobile = getIsMobile();

  useEffect(() => {
    if (!channelRef.current) {
      return;
    }
    if (
      (linkedProfileId && linkedProfileId === previewChannel.profileId) ||
      selectedProfileId === previewChannel.profileId
    ) {
      channelRef.current.scrollIntoView({
        behavior: isMobile ? 'auto' : 'smooth',
        block: 'center',
      });
    }
  }, [previewChannel, linkedProfileId, filteredChannels]);

  useEffect(() => {
    (async () => {
      if (!previewChannel.conversation?.attributes) return;

      const myAttributes = (previewChannel.conversation.attributes as Record<
        string,
        { hasRead: boolean }
      >)[myProfileId];
      setIsUnread(myAttributes && !myAttributes?.hasRead);
    })();
  }, [previewChannel]);

  const getlatestMessageFromChannel = (channel: Channel) => {
    const potentialLatestMessages = [
      channel.localMessages[channel.localMessages.length - 1],
      channel.messages[channel.messages.length - 1],
      channel.lcMessages[channel.lcMessages.length - 1],
    ];
    const sorted = potentialLatestMessages
      .filter(x => x !== undefined)
      .sort((a, b) => (moment(a.dateUpdated).isAfter(moment(b.dateUpdated)) ? 1 : -1));
    return sorted[sorted.length - 1];
  };

  const getPreviewText = (latestMessage: SuperMessage) => {
    const attributes = latestMessage?.attributes as any;
    const latestMessageType: LCMessageType = attributes?.messageType;
    const latestMessageEndorsementCategory: string | undefined = attributes?.endorsement_category;

    const lastBody = attributes?.multipleMessages
      ? attributes.multipleMessages[attributes.multipleMessages.length - 1]
      : latestMessage?.body;

    switch (latestMessageType) {
      case LCMessageType.ENDORSEMENT:
        return `Endorsed you for ${latestMessageEndorsementCategory ?? 'a skill'}...`;
      case LCMessageType.ENDORSEMENT_ACCEPTED_BY_ME:
        return `You added ${previewChannel.firstName}'s ${latestMessageEndorsementCategory ??
          ''} endorsement to your profile!`;
      case LCMessageType.ENDORSEMENT_ACCEPTED_BY_THEM:
        return `Added your ${latestMessageEndorsementCategory ?? ''} endorsement to their profile!`;
      default:
        return attributes?.confirmedTimeslot
          ? 'Schedule confirmed!'
          : attributes?.canceledSchedule
          ? 'Schedule request canceled.'
          : !latestMessage?.body
          ? 'Start chatting!'
          : `${latestMessage.author === myProfileId ? 'You: ' : ''}${lastBody}`;
    }
  };

  const latestMessage = getlatestMessageFromChannel(previewChannel);
  const previewText = getPreviewText(latestMessage);
  const latestTime = moment(
    latestMessage
      ? latestMessage.dateUpdated
      : (previewChannel.conversation?.attributes as any)?.metDate
      ? (previewChannel.conversation?.attributes as any)?.metDate
      : previewChannel.metDate,
  );
  const createdTime = moment(latestMessage?.dateUpdated || previewChannel.created);
  const lastUpdated = (() => {
    if (latestTime.isAfter(moment())) {
      return 'Upcoming';
    }
    if (createdTime.isAfter(moment().subtract(1, 'minute'))) {
      return 'Now';
    }
    if (createdTime.isAfter(moment().subtract(1, 'hour'))) {
      return `${moment().diff(latestTime, 'minutes')}m`;
    }
    if (createdTime.isSame(moment(), 'day')) {
      return latestTime.format('h:mm A');
    }
    return createdTime.format('MMM D');
  })();

  const handleClick = () => {
    history.push(`/chat/${previewChannel.profileId}`);
    setSelectedProfileId(previewChannel.profileId);
    setOpenTab(ChatTabs.Conversation);

    (async () => {
      const hasRead = (previewChannel?.conversation?.attributes as any)?.[myProfileId]?.hasRead;
      if (previewChannel.conversation && !hasRead) {
        await previewChannel.conversation.updateAttributes({
          ...previewChannel.conversation.attributes,
          [myProfileId]: { hasRead: true },
        });
        setIsUnread(false);
      }
    })();
  };

  const handleMouseDown = (e: React.MouseEvent) => e.preventDefault();

  return (
    <SafariEdgeCaseWrapper
      role="button"
      isSelected={selectedProfileId === previewChannel.profileId}
      onClick={handleClick}
      tabIndex={0}
      ref={channelRef}
      onMouseDown={handleMouseDown}
    >
      <Container>
        <Avatar src={previewChannel.avatar} avatarId={previewChannel.profileId} size3 />
        {isMobile ? (
          <TextColumn style={{ gap: 4 }}>
            <FlexRow>
              <EllipsisHeading>{previewChannel.name}</EllipsisHeading>
            </FlexRow>
            <InfoContainer>
              <PreviewTextMobile isUnread={isUnread}>{previewText}</PreviewTextMobile>
              <LastUpdated isUnread={isUnread}>{lastUpdated}</LastUpdated>
            </InfoContainer>
          </TextColumn>
        ) : (
          <TextColumn>
            <FlexRow>
              <EllipsisHeading>{previewChannel.name}</EllipsisHeading>
            </FlexRow>
            <InfoContainer>
              <PreviewText isUnread={isUnread}>{previewText}</PreviewText>
              <LastUpdated isUnread={isUnread}>{lastUpdated}</LastUpdated>
            </InfoContainer>
          </TextColumn>
        )}
        <UnreadIndicator isVisible={isUnread} />
      </Container>
    </SafariEdgeCaseWrapper>
  );
};

const SafariEdgeCaseWrapper = styled.div<{ isSelected: boolean }>`
  width: 100%;
  border-radius: 5px;
  transition: ${globalTransitionSettings};

  background-color: ${p => (p.isSelected ? colors.greyMain : colors.whiteMain)};

  &:hover {
    cursor: pointer;
    background-color: ${p => (p.isSelected ? colors.greyMain : colors.whiteMain)};
    ${media.desktop} {
      background-color: ${p => (p.isSelected ? colors.greyMain : colors.greyLight)};
    }
  }
  ${media.mobile} {
    outline: 0;
  }
`;

const Container = styled(FlexRow)`
  width: 100%;
  padding: ${margins.size2};
`;

const InfoContainer = styled(FlexRow)`
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
`;

const Avatar = styled(BaseAvatar)`
  margin-right: ${margins.size3};
`;

const TextColumn = styled(FlexColumn)`
  width: 100%;
  align-items: start;
  overflow: hidden;
`;

const EllipsisHeading = styled(Heading3)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
`;

const EllipsisSubText = styled(SubText)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
`;

const PreviewText = styled(EllipsisSubText)<{ isUnread: boolean }>`
  font-family: ${p => (p.isUnread ? fonts.bold : fonts.regular)};
  color: ${colors.blackMid};
`;

const PreviewTextMobile = styled(EllipsisSubText)<{ isUnread: boolean }>`
  font-family: ${p => (p.isUnread ? fonts.bold : fonts.regular)};
  color: ${p => (p.isUnread ? colors.blackMain : colors.blackLight)};
  font-size: ${fontSizes.size1};
`;

const LastUpdated = styled(Text)<{ isUnread: boolean }>`
  width: 90px;
  text-align: right;
  font-size: ${fontSizes.size1};
  ${media.mobile} {
    color: ${p => (p.isUnread ? colors.blackMain : colors.blackLight)};
  }
`;

const UnreadIndicator = styled.div<{ isVisible: boolean }>`
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
  min-width: 8px;
  width: 8px;
  height: 8px;
  margin-left: ${margins.size3};
  border-radius: 50%;
  background-color: ${colors.primaryMain};
`;
