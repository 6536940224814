import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { FlexRow, margins, Heading1 } from 'css/css';

import { useUserContextProvider } from 'js/providers/UserProvider';
import { postUserImage } from 'js/util/api';
import { useNotifContext } from 'js/util/notif-context';
import { compressImage } from 'js/util/util';

import DefaultProfile from 'img/shared/default-picture.svg';

import { Button } from '../Button';
import { OldModal } from '../OldModal';

import { AvatarEditor } from './AvatarEditor';

export default ({ currentImage, onDelete, onUpload, children, buttonRowMargin }: any) => {
  const { showNotif } = useNotifContext();
  const { fetchUser, updateUser } = useUserContextProvider();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [editingPicture, setEditingPicture] = useState(false);
  const [image, setImage] = useState(DefaultProfile);
  const fileInput = useRef<HTMLInputElement>(null);

  const startCroppingPicture = async (event: any) => {
    const imageFile = event.target.files[0];
    const compressedBlob = await compressImage(imageFile);
    if (!compressedBlob) {
      showNotif({ message: 'Please upload a valid image file', level: 'error' });
      return;
    }
    // convert blob to file
    // @ts-ignore
    compressedBlob.lastModifiedDate = new Date();
    // @ts-ignore
    compressedBlob.webkitRelativePath = '';
    // @ts-ignore
    const compressedFile = new File([compressedBlob], compressedBlob.name);
    await setImage(compressedFile);
    setEditingPicture(true);
  };

  const submitCroppedProfilePicture = async (editorRef: any) => {
    setButtonLoading(true);
    const base64 = editorRef.current.getImageScaledToCanvas().toDataURL();
    const resp = await fetch(base64);
    const blob = await resp.blob();

    const data = new FormData();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastModifiedDate' does not exist on type... Remove this comment to see the full error message
    blob.lastModifiedDate = new Date();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Blob'.
    blob.name = image.name;
    data.append('file', blob);

    const res = await postUserImage(data);
    if (res.status === 200)
      fetchUser().then(() => {
        if (onUpload) onUpload(res.getJson.url);
        setEditingPicture(false);
        setButtonLoading(false);
      });
    else {
      showNotif({ message: 'Sorry, there was an issue uploading your image', level: 'error' });
      setEditingPicture(false);
      setButtonLoading(false);
    }
  };

  const deleteImage = () => {
    updateUser({ removeImage: true });
    if (onDelete) onDelete();
    setButtonLoading(false);
  };

  return (
    <>
      <ProfileImage src={currentImage || DefaultProfile} />
      {children}

      <FlexRow
        style={{
          marginTop: buttonRowMargin || margins.size4,
          maxWidth: '350px',
          margin: '0 auto',
        }}
      >
        <Button
          large
          loading={buttonLoading}
          onClick={() => fileInput.current && fileInput.current.click()}
        >
          Upload image
        </Button>

        {!!currentImage && (
          <Button secondary large onClick={deleteImage} style={{ marginLeft: margins.size2 }}>
            Remove image
          </Button>
        )}

        <input
          style={{ display: 'none' }}
          type="file"
          onChange={startCroppingPicture}
          ref={fileInput}
        />
      </FlexRow>

      <OldModal
        isOpen={editingPicture}
        onClose={() => {
          setButtonLoading(false);
          setEditingPicture(false);
        }}
        canClose
        modalTitle="Crop picture"
      >
        <Heading1
          style={{
            marginLeft: '0px',
            marginRight: '0px',
            textAlign: 'center',
            marginTop: margins.size5,
          }}
        >
          Edit your profile picture
        </Heading1>

        <EditorContainer>
          <AvatarEditor
            userImage={image}
            onSubmit={submitCroppedProfilePicture}
            onCancel={() => {
              setButtonLoading(false);
              setEditingPicture(false);
            }}
          />
        </EditorContainer>
      </OldModal>
    </>
  );
};

const EditorContainer = styled.div`
  display: flex;
  padding: ${margins.size3};
  margin-top: ${margins.size3};
  align-items: center;
  justify-content: center;
`;

const ProfileImage = styled.img`
  width: 96px;
  height: 96px;
  border-radius: 50%;
  display: flex;
  margin: ${margins.size4} auto;
  object-fit: cover;
`;
