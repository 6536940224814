import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router';

import { margins, Text, ClickableText, colors, Heading3 } from 'css/css';

import { UserState } from 'js/components/mobile-root';
import { Button } from 'js/components/shared/Button';
import { CodeInput } from 'js/components/shared/CodeInput';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { apiFailure, onboarding } from 'js/util/strings';
import { useNotifContext } from 'js/util/notif-context';
import { createOrActivateToken, postUserLogin } from 'js/util/api';
import { getElliotToken, setElliotToken } from 'js/util/util';

import { verifySvgv2 } from 'img/registration-v2';

interface StepVerifyProps {
  tempTokenDefault: string;
}
export const StepVerifyMobile: React.FunctionComponent<StepVerifyProps> = ({
  tempTokenDefault,
}) => {
  const history = useHistory();
  const { showNotif } = useNotifContext();
  const { fetchUser, updateUserAvailability } = useUserContextProvider();
  const user = useUser();

  const [userState, setUserState] = useState<UserState>('landing');
  const [code, setCode] = useState('');
  const [tempToken, setTempToken] = useState(tempTokenDefault);
  const [swappedToken, setSwappedToken] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [validatingCode, setValidatingCode] = useState(false);

  const { verification } = onboarding;

  const sendLoginEmail = async (): Promise<boolean> => {
    if (sendingEmail) return false;
    if (!user) {
      await fetchUser();
    }
    if (!user || !user.email) return false;
    setSendingEmail(true);

    const res = await postUserLogin({
      email: user.email,
      isFromApp: !!window.bridge,
    });
    if (res.ok) {
      // Temporarily invalid refresh token, verified by code later.
      setTempToken(res.getJson.token);
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
    setSendingEmail(false);
    return true;
  };

  const handleClick = (event: any) => {
    event.preventDefault();
    sendLoginEmail().then(r => {
      if (r) {
        showNotif({
          message: 'Verification email sent.',
          level: 'success',
        });
      } else {
        showNotif({
          message: apiFailure.message,
          level: 'error',
        });
      }
    });
  };

  useEffect(() => {
    if (swappedToken) {
      Promise.all([fetchUser(), updateUserAvailability()]).then(() => {
        history.replace(`/verify?token=${getElliotToken()}`);
      });
    }
  }, [swappedToken]);

  const validateCode = async () => {
    if (!code || code.length !== 6) return;
    if (userState === 'code-loading') return;
    setValidatingCode(true);
    setUserState('code-loading');
    const res = await createOrActivateToken(tempToken, true, code);
    if (res.ok) {
      setElliotToken(res.getJson.auth_token);
      setSwappedToken(true);
    } else {
      showNotif({ message: 'Invalid code', level: 'error' });
      setUserState('login-code');
    }
    setValidatingCode(false);
  };

  return (
    <StartVerifyWrap>
      <div className="centered">
        <img src={verifySvgv2} alt="verify" />

        <Heading3 style={{ marginBottom: margins.size3, marginTop: margins.size4 }}>
          {verification.title}
        </Heading3>
        <CodeInput setCode={setCode} onSubmit={validateCode} />
        <Text
          color={colors.blackLight}
          style={{ marginTop: margins.size4, marginBottom: margins.size6 }}
        >
          {verification.subDescription[0]}
          <b>{verification.subDescription[1]}</b>
          {verification.subDescription[2]}
        </Text>

        <ClickableText big onClick={handleClick} style={{ maxWidth: 250 }}>
          {verification.buttonText}
        </ClickableText>

        <Button
          onClick={validateCode}
          invalid={code.length !== 6}
          loading={validatingCode}
          maxWidth="100%"
          style={{ marginTop: margins.size6 }}
        >
          Continue
        </Button>
      </div>
    </StartVerifyWrap>
  );
};

const StartVerifyWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  & > .centered {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: ${margins.size4};
  }
`;
