import React, { useState } from 'react';
import styled from 'styled-components/macro';

import {
  Clickable,
  colors,
  FadedText,
  FlexColumn,
  fontSizes,
  Heading3,
  margins,
  media,
  SubText,
} from 'css/css';

import { postContentCardResponse, postFeedAction } from 'js/util/api';
import { apiFailure } from 'js/util/strings';
import { useNotifContext } from 'js/util/notif-context';
import { ContentCardData, FeedActionType } from 'js/components/homepage/types';
import {
  ColoredClickable,
  DeckCardContainer,
  HeightContainer,
  CardQuestionWithHeightContainer,
} from 'js/components/homepage/styles';
import { CARD_TRANSITION_LEAVE_DURATION } from 'js/components/homepage/constants';
import { Buttons, feedActionWrapper } from 'js/components/homepage/shared/DeckCardButtons';
import { getIsMobile } from 'js/util/util';

type ContentCardResponse = 'No' | 'Maybe' | 'Yes' | 'Skip';

const getResponseNumber = (response: ContentCardResponse) => {
  switch (response) {
    case 'No':
      return 0;
    case 'Maybe':
      return 1;
    case 'Yes':
      return 2;
    default:
      return 3;
  }
};

interface ContentCardProps {
  deckCardId: number;
  cardId: string;
  data: ContentCardData;
  completeCard: (cardId: string) => void;
}

export const ContentCard: React.FC<ContentCardProps> = ({
  deckCardId,
  cardId,
  data,
  completeCard,
}) => {
  const { showNotif } = useNotifContext();

  const [selectedResponse, setSelectedResponse] = useState<ContentCardResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deckCardContentHeight, setDeckCardContentHeight] = useState<number>();
  const [questionHeight, setQuestionHeight] = useState<number>();

  const isAnswered = selectedResponse !== null;
  const { content_id: contentId, headline, text, image, url } = data;
  const buttons: Buttons = {
    kind: 'emoji',
    values: [
      {
        handleClick: () => respond('No'),
        emoji: 'Sad',
        labelBelow: 'no',
        labelAbove: '',
        activeColor: colors.tertiary2Main,
      },
      {
        handleClick: () => respond('Maybe'),
        emoji: 'Meh',
        labelBelow: 'maybe',
        labelAbove: '',
        activeColor: colors.tertiary3Main,
      },
      {
        handleClick: () => respond('Yes'),
        emoji: 'Happy',
        labelBelow: 'yes',
        labelAbove: '',
        activeColor: colors.tertiary1Main,
      },
    ],
  };
  const SKIP_HEIGHT = 37;

  const respond = async (response: ContentCardResponse) => {
    if (isLoading || isAnswered) {
      return;
    }
    setIsLoading(true);
    const res = await postContentCardResponse({ contentId, response: getResponseNumber(response) });
    if (res.ok) {
      setSelectedResponse(response);
      setTimeout(() => completeCard(cardId), CARD_TRANSITION_LEAVE_DURATION);
    } else {
      showNotif({
        message: apiFailure.message,
        level: 'error',
      });
    }
    setIsLoading(false);
  };

  const skip = feedActionWrapper(() => respond('Skip'), 'Skip', deckCardId);

  const handleBoxClick = () => {
    window.open(url);
    postFeedAction(deckCardId, { type: FeedActionType.OpenUrl, url });
  };
  return (
    <DeckCardContainer
      deckCardId={deckCardId}
      isLoading={isLoading}
      isSelected={isAnswered}
      buttons={buttons}
      feedSession="Regular"
      setContentHeight={setDeckCardContentHeight}
    >
      <CardQuestionWithHeightContainer setHeight={setQuestionHeight}>
        Would you want to see more like this?
      </CardQuestionWithHeightContainer>
      {deckCardContentHeight && questionHeight && (
        <ContentBox
          image={image}
          text={text}
          headline={headline}
          maxHeight={deckCardContentHeight - questionHeight - SKIP_HEIGHT}
          handleClick={handleBoxClick}
        />
      )}

      <ColoredClickable onClick={skip} style={{ marginTop: margins.size3 }}>
        <SubText style={{ textDecoration: 'underline' }}>Skip</SubText>
      </ColoredClickable>
    </DeckCardContainer>
  );
};

interface ContentBoxProps {
  image: string;
  headline: string;
  text: string;
  maxHeight: number;
  handleClick: () => void;
}

const CONTENT_BOX_PADDING = parseInt(margins.size4, 10);
const CONTENT_BOX_PADDING_MOBILE = parseInt(margins.size3, 10);

const ContentBox: React.FC<ContentBoxProps> = ({
  image,
  headline,
  text,
  maxHeight,
  handleClick,
}) => {
  const [topContentHeight, setTopContentHeight] = useState<number>();

  const isMobile = getIsMobile();
  const READ_MORE_HEIGHT = 21;
  const maxInnerHeight =
    maxHeight - 2 * (isMobile ? CONTENT_BOX_PADDING_MOBILE : CONTENT_BOX_PADDING);

  return (
    <ShadowContainer>
      <Clickable onClick={handleClick} style={{ width: '100%' }}>
        <ContentBoxContainer maxHeight={maxHeight}>
          <FlexColumn>
            <HeightContainer setHeight={setTopContentHeight}>
              <ContentImage src={image} alt={headline} mobileMaxHeight={(maxInnerHeight * 2) / 3} />
              <Heading3 style={{ marginBottom: margins.size1 }}>{headline}</Heading3>
            </HeightContainer>
            {topContentHeight && (
              <TruncatedFadedText
                mobileMaxHeight={maxInnerHeight - topContentHeight - READ_MORE_HEIGHT}
              >
                {text}
              </TruncatedFadedText>
            )}
            {isMobile && <Heading3 color={colors.primaryMain}>Read more</Heading3>}
          </FlexColumn>
        </ContentBoxContainer>
      </Clickable>
    </ShadowContainer>
  );
};

const ShadowContainer = styled.div`
  border-radius: 10px;
  ${media.desktop} {
    &:hover {
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    }
  }
`;

const TruncatedFadedText = styled(FadedText)<{ mobileMaxHeight: number }>`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${margins.size1};
  ${media.mobile} {
    max-height: ${p => p.mobileMaxHeight}px;
    font-size: ${fontSizes.size1};
  }
`;

const ContentImage = styled.img<{ mobileMaxHeight: number }>`
  margin-bottom: ${margins.size2};
  overflow: hidden;
  max-width: 100%;
  ${media.mobile} {
    max-height: ${p => p.mobileMaxHeight}px;
  }
`;

const ContentBoxContainer = styled.div<{ maxHeight: number }>`
  background-color: ${colors.greyLight};
  border-radius: 10px;

  ${media.mobile} {
    max-height: ${p => p.maxHeight}px;
  }
  ${media.desktop} {
    padding: ${CONTENT_BOX_PADDING}px;
  }
  ${media.mobile} {
    padding: ${CONTENT_BOX_PADDING_MOBILE}px;
    &:active {
      background-color: ${colors.greyMain};
    }
  }
`;
