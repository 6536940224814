import React, { useEffect, useRef, useState, createRef, useMemo } from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';
import moment from 'moment';
import { Message } from '@twilio/conversations/lib/message';
import { Transition } from 'react-transition-group';
import _ from 'lodash';

import { FlexColumn, margins, media, Text, Heading3, colors, SubText } from 'css/css';

import Channel, {
  BackendMessage,
  LCMessageType,
  LocalMessage,
  MorpheusMessageType,
  SuperMessage,
} from 'types/channel';

import { useMountEffect, useOutsideAlerter } from 'js/util/custom-hooks';
import { useUser } from 'js/providers/UserProvider';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { NETWORK_TYPES } from 'js/util/util';

import logo from 'img/chat/lunchclub_logo.svg';

import { SchedulerTimeslotsModal } from './scheduler/timeslots-modal/SchedulerTimeslotsModal';
import { transitionSettings } from './ConversationContainer';
import { BaseMessage } from './messages/BaseMessage';

const APPROACHING_TOP_HEIGHT = 500;

interface Props {
  lcMessages: BackendMessage[];
  localMessages: LocalMessage[];
  messages: Message[];
  updateReadStatus: (profileId: string, channel: Channel) => void;
  setIsSchedulerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSchedulerOpen: boolean;
  setIsTimeslotsConfirmationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isFirstTimeScheduling: boolean;
  selectedChannel: Channel;
  userTimezone: string;
  inputHeight: number;
  isOpen: boolean;
  setLastSchedulerMessage: React.Dispatch<React.SetStateAction<SuperMessage | undefined>>;
  inCall: boolean;
  openEndorsementModal: () => void;
  timeslotsSuggestedByMatch: string[];
  isUserScheduleInitiator: boolean;
  openScheduler: ({
    isReconnecting,
    isEditingTimes,
  }: {
    isReconnecting: boolean;
    isEditingTimes: boolean;
  }) => void;
  setShouldDisplayInputInBotChannel: React.Dispatch<React.SetStateAction<boolean>>;
  areBubblesVisible?: boolean;
  isUpcoming: boolean;
  isRespondingToIcebreaker: boolean;
  setIsRespondingToIcebreaker: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConversationBody: React.FC<Props> = ({
  lcMessages,
  localMessages,
  messages,
  updateReadStatus,
  setIsSchedulerOpen,
  isSchedulerOpen,
  setIsTimeslotsConfirmationVisible,
  isFirstTimeScheduling,
  selectedChannel,
  userTimezone,
  inputHeight,
  isOpen,
  setLastSchedulerMessage,
  inCall,
  openEndorsementModal,
  timeslotsSuggestedByMatch,
  isUserScheduleInitiator,
  openScheduler,
  setShouldDisplayInputInBotChannel,
  areBubblesVisible,
  isUpcoming,
  isRespondingToIcebreaker,
  setIsRespondingToIcebreaker,
}) => {
  const [clickedMessageIdx, setClickedMessageIdx] = useState<number | null>(null);
  const [messageRefs, setMessageRefs] = useState([]);

  const { fetchPreviousPage, channels } = useChatContext();
  const { isBlocked, profileId, conversation } = selectedChannel;
  const messageRef = useRef<HTMLDivElement>(null);
  const currMessageRef = clickedMessageIdx ? messageRefs[clickedMessageIdx] : messageRef;
  const containerRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(currMessageRef, () => setClickedMessageIdx(null));

  // Messages are reversed so that loading older messages doesnt change scroll height
  const sortedMessages = useMemo(
    () =>
      [...messages, ...lcMessages, ...localMessages].sort((a, b) =>
        moment(b.dateUpdated).diff(a.dateUpdated),
      ),
    [messages, lcMessages, localMessages],
  );

  const [icebreakerResponses, allMessages] = _.partition(
    sortedMessages,
    m =>
      (m.attributes as any).messageType === LCMessageType.MORPHEUS &&
      (m.attributes as any).morpheusType === MorpheusMessageType.ICEBREAKER_RESPONSE,
  );

  const lastMessage = sortedMessages[0] as Message | BackendMessage | LocalMessage | undefined;
  const lastLocalMessage = localMessages[localMessages.length - 1];
  const user = useUser();
  const myProfileId = user.profile_id;
  const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
  const isInBotChannel = selectedChannel.networkType === NETWORK_TYPES.LC_BOT;

  const lastSchedulerMessage = useMemo(
    () => allMessages.find(m => (m?.attributes as any)?.schedulerTimeslots?.length),
    [allMessages],
  );

  const lastSchedulingStatus = useMemo(
    () =>
      (allMessages.find(m => 'isScheduling' in (m?.attributes as any))?.attributes as any)
        ?.isScheduling,
    [allMessages],
  );

  useEffect(() => {
    setMessageRefs(prevState =>
      Array(messages.length)
        .fill(null)
        .map((__, i) => prevState[i] || createRef()),
    );
  }, [messages.length]);

  useEffect(() => {
    if (containerRef.current && lastMessage?.sid !== lastLocalMessage?.sid) {
      containerRef.current.scrollTo(0, containerRef.current.offsetHeight);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (!!lastLocalMessage && containerRef.current) {
      containerRef.current.scrollTo(0, containerRef.current.offsetHeight);
    }
  }, [lastLocalMessage]);

  useEffect(() => {
    if (lastSchedulingStatus === undefined || !conversation) {
      return;
    }
    const updateConversation = async () => {
      await conversation.updateAttributes({
        ...conversation.attributes,
        isScheduling: lastSchedulingStatus,
      });
    };
    updateConversation();
  }, [lastSchedulingStatus, conversation]);

  useEffect(() => {
    if (!lastSchedulerMessage) {
      return;
    }
    setLastSchedulerMessage(lastSchedulerMessage);
  }, [lastSchedulerMessage]);

  useEffect(() => {
    const lastMessageAttributes = lastMessage?.attributes as any;

    const lastMessageIsFromUser = lastMessage?.author === user.profile_id;
    const lastMessageFromLighter = lastMessageAttributes?.messageType === LCMessageType.LIGHTER;

    setShouldDisplayInputInBotChannel(lastMessageFromLighter || lastMessageIsFromUser);
  }, [lastMessage, isInBotChannel]);

  useMountEffect(() => {
    if (window.bridge) {
      window.bridge.setShrinkViewportForKeyboard(true);
    }
    return () => {
      if (window.bridge) {
        window.bridge.setShrinkViewportForKeyboard(false);
      }
    };
  });

  const handleScroll: React.UIEventHandler<HTMLDivElement> = e => {
    const scrollTop = Math.abs(e.currentTarget.scrollTop);
    const approachingTop =
      Math.abs(e.currentTarget.scrollHeight - scrollTop - e.currentTarget.clientHeight) <
      APPROACHING_TOP_HEIGHT;

    if (!approachingTop || !selectedChannel.messagePaginator?.hasPrevPage) return;
    fetchPreviousPage(profileId);
  };

  const shouldInsertTimeDiv = (index: number) => {
    if (index === allMessages.length - 1) return true;
    const prevTime = moment(allMessages[index + 1].dateUpdated);
    const currentTime = moment(allMessages[index].dateUpdated);
    const diff = moment.duration(currentTime.diff(prevTime)).asMinutes();
    return diff >= 60;
  };

  const shouldDisplayAvatar = (idx: number) => {
    const currentMessage = allMessages[idx];
    const nextMessage = allMessages[idx - 1];
    const previousMessage = allMessages[idx + 1];
    if (previousMessage?.author === 'Lunchclub' && !nextMessage) {
      return true;
    }
    if (nextMessage) {
      const currentTime = moment(allMessages[idx].dateUpdated);
      const nextTime = moment(nextMessage.dateUpdated);
      const diff = moment.duration(nextTime.diff(currentTime)).asMinutes();
      if (!(diff >= 60) && nextMessage.author === currentMessage.author) {
        return false;
      }
    }
    return true;
  };

  const shouldInsertGap = (idx: number) => {
    if (idx === allMessages.length - 1) return false;
    return allMessages[idx + 1].author !== allMessages[idx].author;
  };

  return (
    <Transition in={isOpen} appear timeout={transitionSettings.timeout} unmountOnExit>
      {state => (
        <>
          {isSchedulerOpen && (
            <Backdrop
              onClick={() => {
                setIsSchedulerOpen(false);
                setIsTimeslotsConfirmationVisible(true);
              }}
            >
              <SchedulerTimeslotsModal
                setIsTimeslotsConfirmationVisible={setIsTimeslotsConfirmationVisible}
                userTimezone={userTimezone}
                closeScheduler={() => setIsSchedulerOpen(false)}
                timeslotsSuggestedByMatch={timeslotsSuggestedByMatch}
                isUserScheduleInitiator={isUserScheduleInitiator}
                selectedChannel={selectedChannel}
              />
            </Backdrop>
          )}
          <Container
            style={transitionSettings.styles[state]}
            ref={containerRef}
            onScroll={handleScroll}
            onClick={() => {
              updateReadStatus(myProfileId, selectedChannel);
            }}
          >
            {isBlocked ? (
              <div style={{ margin: 'auto' }}>
                <Heading3>You&apos;ve blocked {selectedChannel.firstName}</Heading3>
                <Text>Unblock to see the messages.</Text>
              </div>
            ) : (
              <FirefoxScroller shouldScroll={isFirefox}>
                <MessagesContainer
                  ref={messageRef}
                  $inputHeight={inputHeight}
                  $inCall={inCall}
                  $shouldAddMargin={!areBubblesVisible}
                >
                  {allMessages.map((m, i) => {
                    const messageType = (m.attributes as any)?.messageType;
                    const isMyMessage =
                      m.author === myProfileId ||
                      messageType === LCMessageType.ENDORSEMENT_ACCEPTED_BY_ME;

                    const currentMatchId = (m.attributes as any)?.matchProfileId;
                    const currentMatchChannel = channels.find(c => c.profileId === currentMatchId);

                    return (
                      <BaseMessage
                        m={m}
                        index={i}
                        isMyMessage={isMyMessage}
                        isFirstTimeScheduling={isFirstTimeScheduling}
                        selectedChannel={selectedChannel}
                        userTimezone={userTimezone}
                        ref={messageRefs[i]}
                        clickedMessageIdx={clickedMessageIdx}
                        shouldDisplayAvatar={shouldDisplayAvatar}
                        shouldInsertTimeDiv={shouldInsertTimeDiv}
                        shouldInsertGap={shouldInsertGap}
                        clickMessage={({ e, idx }: { e: React.MouseEvent; idx: number }) => {
                          e.stopPropagation();
                          setClickedMessageIdx(prevState => (prevState === idx ? null : idx));
                        }}
                        openScheduler={openScheduler}
                        lastSchedulerMessage={lastSchedulerMessage}
                        openEndorsementModal={openEndorsementModal}
                        lastMessage={lastMessage}
                        currentMatchChannel={currentMatchChannel}
                        isUpcoming={isUpcoming}
                        icebreakerResponses={icebreakerResponses}
                        isRespondingToIcebreaker={isRespondingToIcebreaker}
                        setIsRespondingToIcebreaker={setIsRespondingToIcebreaker}
                      />
                    );
                  })}
                  {selectedChannel.isMorpheusMatch && !user.is_morpheus && (
                    <>
                      <BonusMatchText>Get to know each other over chat first</BonusMatchText>
                      <BonusMatchText>This is a bonus match!</BonusMatchText>
                    </>
                  )}
                </MessagesContainer>
              </FirefoxScroller>
            )}
          </Container>
        </>
      )}
    </Transition>
  );
};

const Container = styled(FlexColumn)`
  flex: 1;
  flex-direction: column-reverse;
  width: 100%;
  padding: ${margins.size2} ${margins.size3};
  padding-top: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: flex-start;

  ${media.mobile} {
    transition: all 300ms ease-out;
  }
`;

const FirefoxScroller = styled.div<{ shouldScroll: boolean }>`
  width: 100%;
  ${p => p.shouldScroll && 'overflow-y: scroll'};
`;

const MessagesContainer = styled(FlexColumn)<{
  $inputHeight: number;
  $inCall: boolean;
  $shouldAddMargin: boolean;
}>`
  width: 100%;
  flex-direction: column-reverse;
  margin-top: ${margins.size2};
  ${p =>
    p.$shouldAddMargin &&
    `margin-bottom: calc(${p.$inputHeight}px + ${margins.size3} + ${p.$inCall ? 68 : 0}px)`};
  ${media.mobile} {
    padding-top: ${margins.size6};
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 10;
  display: flex;
  align-items: flex-start;
  padding-top: 80px;
  overflow-y: scroll;

  ${media.mobile} {
    display: flex;
    padding: 0;
    align-items: flex-end;
    flex-direction: column;
  }
`;

export const LCLogo = styled(SVG).attrs(() => ({
  src: logo,
  'alt-text': 'Message from Lunchclub',
}))`
  margin-right: ${margins.size2};
  height: 24px;
  width: 24px;
`;

const BonusMatchText = styled(SubText)`
  max-height: 30px;
  align-self: center;
  padding: ${margins.size1};
  opacity: 1;
  overflow: hidden;
  color: ${colors.blackMid};
  margin-top: -8px;
`;
