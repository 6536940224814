import React from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';

import { Heading3, margins, colors, FlexColumn } from 'css/css';

import { Button } from 'js/components/shared/Button';

import screenShareIcon from 'img/call/screen-share-big.svg';

interface LocalScreenShareProps {
  onClick?: () => void;
}

export const LocalScreenShare: React.FC<LocalScreenShareProps> = ({ onClick }) => {
  return (
    <LocalScreenShareContainer>
      <ScreenShareIcon />
      <Unselectable>
        <Heading3 color={colors.whiteMain}>You are sharing your screen!</Heading3>
      </Unselectable>
      <Button onClick={onClick} style={{ marginTop: margins.size4 }}>
        Stop Sharing
      </Button>
    </LocalScreenShareContainer>
  );
};

const LocalScreenShareContainer = styled(FlexColumn)`
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background-color: ${colors.blackMid};
`;

const ScreenShareIcon = styled(SVG).attrs({
  src: screenShareIcon,
  'alt-text': 'Screen Share icon',
})``;

const Unselectable = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
