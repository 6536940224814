import React from 'react';
import styled from 'styled-components/macro';

import { margins, Text } from 'css/css';

import { Browser, isIOSMobile, isSafari } from 'js/components/callv2/utils/browser';

export const MobileChromeUnblockMessage: React.FC<{ browserIcon: React.ReactElement }> = ({
  browserIcon,
}) => (
  <MessageList>
    <li>
      <Text>Tap the lock icon {browserIcon} in your browser&apos;s address bar</Text>
    </li>
    <li>
      <Text>Tap Permissions</Text>
    </li>
    <li>
      <Text>Tap Camera and Microphone and Allow both</Text>
    </li>
    <li>
      <Text>Tap Try again below to refresh</Text>
    </li>
  </MessageList>
);

export const SafariOrIOSMobileUnblockMessage = () => (
  <MessageList>
    <li>
      <Text>Click Grant access below</Text>
    </li>
    <li>
      <Text>When prompted, click Allow</Text>
    </li>
  </MessageList>
);

interface Props {
  browserName?: string;
  browserIcon: React.ReactElement;
}

export const GenericUnblockMessage: React.FC<Props> = ({ browserName, browserIcon }) => (
  <MessageList>
    <li>
      <Text>Click the camera icon {browserIcon} in your browser&apos;s address bar</Text>
    </li>

    <li>
      <Text>
        {browserName === Browser.CHROME
          ? 'Select “Always allow”, then click Done'
          : browserName === Browser.FIREFOX
          ? 'Click X for any “Blocked” devices under Permissions'
          : browserName === Browser.EDGE
          ? 'Select “Always allow”, then click Done'
          : browserName === Browser.OPERA
          ? 'Select “Allow” for both camera and microphone'
          : 'Select “Always allow”, then click Done'}
      </Text>
    </li>
    {(isSafari() || isIOSMobile()) && (
      <li>
        <Text>Click Grant access then select Allow</Text>
      </li>
    )}
    <li>
      <Text>Refresh the page</Text>
    </li>
  </MessageList>
);

const MessageList = styled.ol`
  padding: 0;

  > :not(first-of-type) {
    margin-top: ${margins.size2};
  }
`;
