import React, { useState } from 'react';
import styled from 'styled-components/macro';

import {
  Heading3,
  FlexRow,
  globalTransitionSettings,
  Clickable,
  margins,
  media,
  colors,
} from 'css/css';

import { SmallArrow } from '../shared/SmallArrow';

interface Props {
  title: string;
  height: string;
  initialOpen?: boolean;
}

export const Tray: React.FC<Props> = ({ title, children, height, initialOpen = false }: any) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  return (
    <>
      <Clickable onClick={() => setIsOpen(!isOpen)}>
        <FlexRow>
          <SmallArrow
            style={{ margin: margins.size2 }}
            direction={isOpen ? 'down' : 'right'}
            color={colors.blackLight}
          />
          <Heading3 style={{ textAlign: 'left' }}>{title}</Heading3>
        </FlexRow>
      </Clickable>
      <ContentBox $openTray={isOpen} $height={height}>
        {children}
      </ContentBox>
    </>
  );
};

type ContentBoxType = {
  $openTray: boolean;
  $height: string;
};
const ContentBox = styled.div<ContentBoxType>`
  transition: ${globalTransitionSettings};
  margin-left: 36px;
  text-align: left;
  max-height: ${p => (p.$openTray ? p.$height : '0px')};
  opacity: ${p => (p.$openTray ? 1 : 0)};
  overflow: ${p => (p.$openTray ? 'initial' : 'hidden')};
  margin-bottom: ${margins.size3};

  ${media.mobile} {
    height: ${p => (p.$openTray ? 'fit-content' : '0px')};
    transition: none;
    max-height: unset;
  }
`;
