export const urls: Record<string, string> = {
  development: 'https://staging.elliot.ai',
  // development: 'http://localhost:901',
  staging: 'https://staging.elliot.ai',
  test1: 'https://test1.elliot.ai',
  test2: 'https://test2.elliot.ai',
  test3: 'https://test3.elliot.ai',
  test4: 'https://test4.elliot.ai',
  production: 'https://prod.elliot.ai',
  testmaster: 'https://test-master.elliot.ai',
  testmaster2: 'https://test-master-2.elliot.ai',
  testmaster3: 'https://test-master-3.elliot.ai',
};
