import React from 'react';
import styled from 'styled-components/macro';

import { colors, FlexColumn, Heading2, margins, media, Text } from 'css/css';

import { MinimalUserDict } from 'js/components/homepage/types';
import { getIsMobile } from 'js/util/util';
import { Button } from 'js/components/shared/Button';
import {
  InterestsScroller,
  LightGreyButton,
  ProfileAvatarImage,
} from 'js/components/profile/ProfileMobile';

import { Top } from './Top';
import {
  CATEGORY_MIN_THRESHOLD,
  ENDORSEMENT_MODAL_DESKTOP_BODY_HEIGHT,
  NUMBER_OF_AVATARS,
} from './constants';

interface IntroProps {
  handleClick: () => void;
  closeModal: () => void;
  sampleUsers: MinimalUserDict[];
  endorsementCategories: string[];
}

export const Intro: React.FC<IntroProps> = ({
  handleClick,
  closeModal,
  sampleUsers,
  endorsementCategories,
}) => {
  const isMobile = getIsMobile();

  return (
    <>
      {!isMobile && <Top closeModal={closeModal} />}
      <IntroContainer>
        <Avatars users={sampleUsers} />
        <FlexColumn style={{ gap: margins.size2, ...(isMobile ? {} : { width: 376 }) }}>
          <Heading2>Endorse your friends?</Heading2>
          <Text>
            We think that some of your friends could be endorsed
            {endorsementCategories.length >= CATEGORY_MIN_THRESHOLD
              ? ' for these skills and more!'
              : '!'}
          </Text>
          <Endorsements categories={endorsementCategories} />
        </FlexColumn>
        <Button large={isMobile} onClick={handleClick}>
          Endorse my friends
        </Button>
      </IntroContainer>
    </>
  );
};

const IntroContainer = styled(FlexColumn)`
  width: 100%;
  justify-content: center;
  background-color: ${colors.whiteMain};
  gap: ${margins.size3};
  padding: ${margins.size4} ${margins.size3}
    calc(max(env(safe-area-inset-bottom), ${margins.size4})) ${margins.size3};
  ${media.desktop} {
    height: ${ENDORSEMENT_MODAL_DESKTOP_BODY_HEIGHT}px;
    border-radius: 0px 0px 10px 10px;
  }
`;

const Avatars = ({ users }: { users: MinimalUserDict[] }) => {
  const DISTANCE_BETWEEN_AVATARS = 66;
  const slicedUsers = users.slice(0, NUMBER_OF_AVATARS);
  const avatarUsers = (slicedUsers as { profile: string; image: string }[]).concat(
    [...Array(NUMBER_OF_AVATARS - slicedUsers.length).keys()].map(i => ({
      image: '',
      profile: i.toString(),
    })),
  );

  return (
    <div style={{ position: 'relative' }}>
      <>
        {avatarUsers.map((user, index) => (
          <ProfileAvatarImage
            src={user.image}
            avatarId={user.profile}
            style={{
              position: index === 0 ? 'relative' : 'absolute',
              transform: 'translate(0)',
              left: DISTANCE_BETWEEN_AVATARS * (index - 1),
              filter: 'drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.1))',
            }}
          />
        ))}
      </>
    </div>
  );
};

const Endorsements = ({ categories }: { categories: string[] }) =>
  categories.length >= CATEGORY_MIN_THRESHOLD ? (
    <EndorsementsContainer>
      {categories
        .sort((a, b) => a.length - b.length)
        .map(category => (
          <LightGreyButton>
            <Text>{category}</Text>
          </LightGreyButton>
        ))}
    </EndorsementsContainer>
  ) : null;

const EndorsementsContainer = styled(InterestsScroller)`
  height: 37px;
  justify-content: center;
  overflow: hidden;
`;
