import React from 'react';

import { margins, FlexRow, FlexColumn, Link, BUTTON_HEIGHT } from 'css/css';

import { ProductUser } from 'types/user';

import ProfileUpload from 'js/components/shared/profile-upload';
import { useUser } from 'js/providers/UserProvider';
import { extractProfiles, addParameterToURL } from 'js/util/util';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { Avatar } from 'js/components/shared/Avatar';
import { Button } from 'js/components/shared/Button';

import sendMessageSvg from 'img/profile/send-message.svg';

import { Socials } from './Socials';

interface Props {
  editing?: boolean;
  passedUser?: ProductUser;
  style?: React.CSSProperties;
  showSocials: boolean;
  showChatButton: boolean;
}

export const Hero: React.FC<Props> = ({
  editing,
  passedUser,
  style,
  showSocials,
  showChatButton,
}) => {
  const user = useUser();
  const selectedUser = passedUser || user;
  const profileId = selectedUser.profile_id;
  const { image } = selectedUser;
  const profiles = extractProfiles(selectedUser);
  const { channels } = useChatContext();
  const isConnectionInChat = channels.find(ch => ch.profileId === selectedUser?.profile_id);

  if (editing) return <ProfileUpload currentImage={image} />;
  return (
    <FlexRow justifyContent="space-between" alignItems="flex-end" style={style}>
      <Avatar
        style={{ marginRight: margins.size3 }}
        size6
        src={image}
        avatarId={selectedUser.profile_id}
      />
      <FlexColumn alignItems="flex-end" style={{ width: '100%' }}>
        <FlexRow justifyContent="flex-end" style={{ width: '100%', marginBottom: margins.size4 }}>
          {showSocials && <Socials profiles={profiles} />}
        </FlexRow>
        {showChatButton && isConnectionInChat ? (
          <Link to={addParameterToURL('chat_id', profileId, '/chat')}>
            <Button>
              <img src={sendMessageSvg} alt="Send message" style={{ marginRight: margins.size2 }} />
              Message
            </Button>
          </Link>
        ) : (
          <div style={{ height: BUTTON_HEIGHT }} />
        )}
      </FlexColumn>
    </FlexRow>
  );
};
