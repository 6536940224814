import React from 'react';

import { postExitCoachingPlan } from 'js/api/coaching-plan';
import { useUserContextProvider } from 'js/providers/UserProvider';
import { useNotifContext } from 'js/util/notif-context';

import { SettingsSectionsTitles } from '../constants';

import { ToggleSection } from './shared-components/ToggleSection';

export const CoachingPlanUnsubscribe: React.FC = () => {
  const { fetchUser, userAvailability, updateUserAvailability } = useUserContextProvider();
  const { showNotif } = useNotifContext();

  const isOnCoachingPlan = Boolean(userAvailability?.coaching_plan_details?.on_coaching_plan);

  const postDeleteCoachingPlan = async () => {
    const res = await postExitCoachingPlan();
    if (!res.ok) {
      showNotif({
        message: 'Failed to opt out of Curated Matches',
        level: 'error',
      });
    }
    await Promise.all([fetchUser(), updateUserAvailability()]);
  };

  if (!isOnCoachingPlan) return null;

  return (
    <ToggleSection
      headingTitle={SettingsSectionsTitles.COACHING_PLAN}
      subtitle="Opt-out of Curated Matches."
      onClick={postDeleteCoachingPlan}
      isOn={isOnCoachingPlan}
    />
  );
};
