import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import styled, { keyframes } from 'styled-components/macro';
import moment from 'moment';

import {
  FlexColumn,
  colors,
  margins,
  SubText,
  media,
  globalTransitionSettings,
  FlexRow,
  lineHeights,
  fonts,
  Clickable,
  Heading3,
} from 'css/css';

import Channel, { LCMessageType, MorpheusMessageType, SuperMessage } from 'types/channel';

import { LC_BOT_PUBLIC_ID } from 'js/util/util';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { useUser } from 'js/providers/UserProvider';

import LCLogo from 'img/chat/lunchclub_logo.svg';
import ElliotAILogo from 'img/chat/elliot_ai_logo.svg';

import { Avatar } from '../../shared/Avatar';

import { LCBotMultipleMessages } from './LCBotMultipleMessages';
import { MatchChannelLink } from './MatchChannelLink';
import { MessageContent } from './MessageContent';
import { IcebreakerQuestion } from './IcebreakerQuestion';

export const getIsEndorsementAcceptedMessage = (m: SuperMessage) =>
  [LCMessageType.ENDORSEMENT_ACCEPTED_BY_THEM, LCMessageType.ENDORSEMENT_ACCEPTED_BY_ME].includes(
    (m.attributes as any)?.messageType,
  );

interface Props {
  m: SuperMessage;
  index: number;
  isMyMessage: boolean;
  isFirstTimeScheduling: boolean;
  selectedChannel: Channel;
  userTimezone: string;
  ref: React.RefObject<HTMLDivElement>;
  clickedMessageIdx: number | null;
  shouldDisplayAvatar: (i: number) => boolean;
  shouldInsertTimeDiv: (i: number) => boolean;
  shouldInsertGap: (i: number) => boolean;
  clickMessage?: ({ e, idx }: { e: React.MouseEvent; idx: number }) => void;
  lastSchedulerMessage?: SuperMessage;
  openEndorsementModal?: () => void;
  lastMessage?: SuperMessage;
  openScheduler?: ({
    isReconnecting,
    isEditingTimes,
  }: {
    isReconnecting: boolean;
    isEditingTimes: boolean;
  }) => void;
  currentMatchChannel?: Channel;
  isUpcoming: boolean;
  icebreakerResponses?: SuperMessage[];
  isRespondingToIcebreaker: boolean;
  setIsRespondingToIcebreaker: React.Dispatch<React.SetStateAction<boolean>>;
}
export const BaseMessage: React.FC<Props> = ({
  m,
  index,
  isMyMessage,
  isFirstTimeScheduling,
  selectedChannel,
  userTimezone,
  ref,
  clickedMessageIdx,
  shouldDisplayAvatar,
  shouldInsertTimeDiv,
  shouldInsertGap,
  clickMessage,
  lastSchedulerMessage,
  openEndorsementModal,
  lastMessage,
  openScheduler,
  currentMatchChannel,
  isUpcoming,
  icebreakerResponses,
  isRespondingToIcebreaker,
  setIsRespondingToIcebreaker,
}) => {
  const { lastMessageStatus } = useChatContext();
  const { profileId, avatar } = selectedChannel;

  const history = useHistory();
  const user = useUser();

  const hasAvatar = shouldDisplayAvatar(index);
  const lastWeek = moment().subtract(6, 'days');

  const messageType = (m.attributes as any)?.messageType;
  const isMessageFromLunchclub = m.author === 'Lunchclub' || m.author === LC_BOT_PUBLIC_ID;
  const isMorpheusMessage = messageType === LCMessageType.MORPHEUS;

  const isIntro = messageType === LCMessageType.INTRO;
  const isMorpheusIntroMessage = user.is_morpheus && isIntro;

  const isIcebreakerResponse =
    isMorpheusMessage &&
    (m?.attributes as any)?.morpheusType === MorpheusMessageType.ICEBREAKER_RESPONSE;

  const icebreakerQuestion = (m.attributes as any)?.icebreaker_question;
  const hasUserResponded = icebreakerResponses?.some(r => r.author === user.profile_id);
  const isLastMessage = lastMessage === m;

  useEffect(() => {
    setIsRespondingToIcebreaker(isLastMessage && !hasUserResponded && !!icebreakerQuestion);
  }, [isLastMessage, hasUserResponded, selectedChannel]);

  const formatTimestamp = (time: Date | string) =>
    moment(time).isBefore(lastWeek)
      ? moment(time).format('MMM DD, YYYY, h:mm a')
      : moment(time).format('ddd, h:mm a');

  const multipleMessages = (m?.attributes as any)?.multipleMessages || [];

  const replaceNameWithLink = (message: string, matchChannel?: Channel) => {
    const nameIndex = message.indexOf('@matchName');
    const settingsLinkIndex = message.indexOf('settings');
    const feedLinkIndex = message.indexOf('homepage');
    if (nameIndex !== -1 && matchChannel) {
      return (
        <span>
          {message.slice(0, nameIndex)}
          <MatchChannelLink matchChannel={matchChannel} />
          {message.slice(nameIndex + '@matchName'.length)}
        </span>
      );
    }
    if (settingsLinkIndex !== -1) {
      return (
        <span>
          {message.slice(0, settingsLinkIndex)}
          <Clickable onClick={() => history.push('/settings')}>
            <Heading3 color={colors.primaryMain}>settings</Heading3>
          </Clickable>
          {message.slice(settingsLinkIndex + 'settings'.length)}
        </span>
      );
    }
    if (feedLinkIndex !== -1) {
      return (
        <span>
          {message.slice(0, feedLinkIndex)}
          <Clickable onClick={() => history.push('/home')}>
            <Heading3 color={colors.primaryMain}>homepage</Heading3>
          </Clickable>
          {message.slice(feedLinkIndex + 'homepage'.length)}
        </span>
      );
    }
    return message;
  };

  return (
    <React.Fragment key={m.sid}>
      <MessageTimestamp
        $shouldShowTimestamp={clickedMessageIdx === index && !isIcebreakerResponse}
        $isMyMessage={isMyMessage}
      >
        {moment(m.dateUpdated).format('MMM DD, YYYY, h:mm a')}
      </MessageTimestamp>
      {isMorpheusIntroMessage && !!icebreakerQuestion && (
        <IcebreakerQuestion
          question={icebreakerQuestion}
          responses={icebreakerResponses}
          selectedChannel={selectedChannel}
          hasUserResponded={hasUserResponded}
          userTimezone={userTimezone}
          lastMessage={lastMessage}
          isRespondingToIcebreaker={isRespondingToIcebreaker}
          setIsRespondingToIcebreaker={setIsRespondingToIcebreaker}
        />
      )}
      {isMyMessage && (
        <LastMessageStatus
          $shouldShowTimestamp={
            lastMessage === m &&
            ((m as any).message_type === 'local' || lastMessageStatus === 'sent')
          }
          $isMyMessage={isMyMessage}
        >
          {lastMessageStatus}
          {lastMessageStatus === 'sending' &&
            new Array(3).fill('.').map((el, idx) => {
              return <Ellipsis i={idx}>{el}</Ellipsis>;
            })}
        </LastMessageStatus>
      )}
      <MessageContainer $isMyMessage={isMyMessage}>
        {!isMyMessage && hasAvatar && (
          <Avatar
            src={
              isMessageFromLunchclub && !getIsEndorsementAcceptedMessage(m)
                ? user.is_morpheus
                  ? ElliotAILogo
                  : LCLogo
                : avatar
            }
            size1
            avatarId={profileId}
            style={{ marginRight: margins.size2, marginBottom: margins.size1 }}
          />
        )}
        <FlexColumn
          onClick={e => {
            if (clickMessage) {
              clickMessage({ e, idx: index });
            }
          }}
          alignItems={isMyMessage ? 'flex-end' : 'flex-start'}
          style={{
            width: '100%',
            marginLeft: `${!isMyMessage && hasAvatar ? 0 : '32px'}`,
          }}
        >
          {multipleMessages.length &&
          (messageType === LCMessageType.MORPHEUS ||
            messageType === LCMessageType.COACHING_PLAN) ? (
            <LCBotMultipleMessages
              m={m}
              messages={multipleMessages}
              matchChannel={currentMatchChannel}
              isMyMessage={isMyMessage}
              isFirstTimeScheduling={isFirstTimeScheduling}
              selectedChannel={selectedChannel}
              userTimezone={userTimezone}
              ref={ref}
              lastSchedulerMessage={lastSchedulerMessage}
              openEndorsementModal={openEndorsementModal}
              openScheduler={openScheduler}
              currentMatchChannel={currentMatchChannel}
              replaceNameWithLink={replaceNameWithLink}
              isUpcoming={isUpcoming}
            />
          ) : (
            <MessageContent
              m={m}
              isMyMessage={isMyMessage}
              isFirstTimeScheduling={isFirstTimeScheduling}
              selectedChannel={selectedChannel}
              userTimezone={userTimezone}
              ref={ref}
              lastSchedulerMessage={lastSchedulerMessage}
              openEndorsementModal={openEndorsementModal}
              openScheduler={openScheduler}
              currentMatchChannel={currentMatchChannel}
              replaceNameWithLink={replaceNameWithLink}
              isUpcoming={isUpcoming}
            />
          )}
        </FlexColumn>
      </MessageContainer>
      {shouldInsertTimeDiv(index) ? (
        <BreakTimestamp>{formatTimestamp(m.dateUpdated)}</BreakTimestamp>
      ) : shouldInsertGap(index) ? (
        <BreakTimestamp />
      ) : null}
    </React.Fragment>
  );
};

const MessageContainer = styled(FlexRow)<{ $isMyMessage?: boolean }>`
  align-items: flex-end;
  align-self: ${p => (p.$isMyMessage ? 'flex-end' : 'flex-start')};
  width: 100%;

  ${media.mobile} {
    min-width: -webkit-fill-available;
  }
`;

const MessageTimestamp = styled(SubText)<{ $shouldShowTimestamp: boolean; $isMyMessage?: boolean }>`
  height: ${p => (p.$shouldShowTimestamp ? '30px' : '0px')};
  align-self: ${p => (p.$isMyMessage ? 'flex-end' : 'flex-start')};
  margin-left: ${p => !p.$isMyMessage && '32px'};
  padding: 1px;
  opacity: ${p => (p.$shouldShowTimestamp ? 1 : 0)};
  overflow: 'hidden';
  transition: ${globalTransitionSettings};
`;

const LastMessageStatus = styled(MessageTimestamp)`
  margin-right: ${margins.size2};
  ${p => !p.$shouldShowTimestamp && 'padding: 0;'}
`;

export const MessageBubble = styled.div<{ $isMyMessage?: boolean }>`
  margin-top: 0px;
  padding: ${margins.size2} ${margins.size3};
  border-radius: 20px;
  margin-left: ${p => (p.$isMyMessage ? margins.size5 : '0px')};
  margin-right: ${p => (p.$isMyMessage ? '0px' : margins.size5)};
  color: ${p => (p.$isMyMessage ? colors.whiteMain : colors.blackMid)};
  background-color: ${p => (p.$isMyMessage ? colors.primaryMain : colors.greyLight)};
  text-align: left;
  word-break: break-word;
  max-width: 530px;
  line-height: ${lineHeights.header};
  font-family: ${fonts.regular};
  white-space: pre-wrap;
  a {
    color: ${p => (p.$isMyMessage ? colors.whiteMain : colors.blackMid)};
    text-decoration: underline;
  }
`;

const BreakTimestamp = styled(SubText)`
  max-height: 30px;
  align-self: center;
  padding: ${margins.size1};
  opacity: 1;
  overflow: hidden;
`;

const blink = () => keyframes`
0% {
    color: ${colors.blackLight}
}
50% {
    color: ${colors.whiteMain}
}
100% {
    color: ${colors.blackLight}
}
`;

const Ellipsis = styled.span<{ i: number }>`
  animation: ${blink()} 1.5s linear ${p => p.i * 200 + 800}ms;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
`;
