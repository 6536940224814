import React from 'react';
import styled from 'styled-components/macro';

import { colors, fonts, margins, Text } from 'css/css';

import { AvailibilityExplanation } from 'js/components/shared/availibility-explanation';

interface Props {
  localTimezone: string;
  targetLocale: string;
  hasAvailableTimes: boolean;
  availableTimesLoaded: boolean;
}
export const MeetingFooter: React.FC<Props> = ({
  localTimezone,
  targetLocale,
  hasAvailableTimes,
  availableTimesLoaded,
}) => (
  <Container>
    <Text color={colors.blackLight} style={{ marginTop: margins.size2 }}>
      These are the best times for <BoldText>{targetLocale}</BoldText> (in {localTimezone}). Each
      meeting is 45 minutes!
    </Text>
    {hasAvailableTimes && (
      <AvailibilityExplanation
        shouldDisplay={availableTimesLoaded}
        contentStyle={{ marginBottom: margins.size2 }}
      />
    )}
  </Container>
);

const Container = styled.div`
  width: 100%;
  text-align: left;
`;

const BoldText = styled.span`
  font-family: ${fonts.bold};
`;
