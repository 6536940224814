import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import SVG from 'react-inlinesvg';

import {
  FlexRow,
  FlexColumn,
  Heading3,
  Text,
  margins,
  borders,
  media,
  Clickable,
  colors,
  Link,
} from 'css/css';

import Channel from 'types/channel';

import { NETWORK_TYPES, getIsMobile } from 'js/util/util';
import { Avatar as BaseAvatar } from 'js/components/shared/Avatar';
import { useChatContext } from 'js/providers/ChatContextProvider';
import { LargeArrow } from 'js/components/shared/LargeArrow';

import seeMore from 'img/chat/more-button.svg';

import { ChatTabs } from '..';

interface Props {
  selectedChannel: Channel;
  isFirstTimeScheduling: boolean;
  isUpcoming: boolean;
  metDate?: string;
  setIsSeeMoreDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenTab?: React.Dispatch<React.SetStateAction<ChatTabs>>;
}

export const TopContainer: React.FC<Props> = ({
  selectedChannel,
  isFirstTimeScheduling,
  isUpcoming,
  metDate,
  setIsSeeMoreDropdownOpen,
  setOpenTab,
}) => {
  const { avatar, name, matchCode, profileId, isBlocked, networkType } = selectedChannel;
  const { setIsPopupOpen } = useChatContext();
  const isMobileAndInCall = () => !!window.bridge?.exitChat;
  const isInCallMobile = isMobileAndInCall();

  const meetingDateString = isFirstTimeScheduling
    ? ''
    : isUpcoming
    ? `Scheduled for ${moment(metDate).format('MMM D YYYY')}`
    : `Met on ${moment(metDate).format('MMM D YYYY')}`;

  const shouldHideSeeMoreButton = networkType === NETWORK_TYPES.LC_BOT;

  return (
    <Container>
      {getIsMobile() && (
        <BackButton
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();

            if (isInCallMobile && window.bridge?.exitChat) {
              window.bridge.exitChat();
            } else if (setOpenTab) {
              setOpenTab(ChatTabs.List);
            }
          }}
        >
          <LargeArrow direction="left" color={colors.blackMain} />{' '}
        </BackButton>
      )}
      <Link
        to={`/member/${profileId}?ref=chat`}
        style={{ pointerEvents: isInCallMobile ? 'none' : 'initial' }}
      >
        <FlexRow>
          <Avatar src={avatar} size3 avatarId={profileId} />
          <TextColumn>
            <FlexRow>
              <Name>{name}</Name>
            </FlexRow>
            {networkType !== NETWORK_TYPES.LC_BOT && (
              <Text>
                {matchCode
                  ? meetingDateString
                  : networkType === NETWORK_TYPES.INVITER
                  ? 'Invited you'
                  : networkType === NETWORK_TYPES.INVITEE
                  ? 'Invited by you'
                  : 'Your contact'}
                {isBlocked && <span style={{ color: colors.tertiary2Main }}>(Blocked)</span>}
              </Text>
            )}
          </TextColumn>
        </FlexRow>
      </Link>
      {!shouldHideSeeMoreButton && (
        <SeeMoreContainer
          onClick={() => {
            setIsSeeMoreDropdownOpen(prev => !prev);
            setIsPopupOpen(false);
          }}
          onMouseDown={e => e.stopPropagation()}
        >
          <SeeMoreButton src={seeMore} alt="See more" />
        </SeeMoreContainer>
      )}
    </Container>
  );
};

const Container = styled(FlexRow)`
  width: 100%;
  padding: ${margins.size3} ${margins.size2};
  border-bottom: ${borders.standard};
  align-items: center;
  ${media.mobile} {
    padding: ${margins.size2};
  }
`;

const BackButton = styled.div`
  padding: ${margins.size2} ${margins.size3};
  cursor: pointer;
`;

const Avatar = styled(BaseAvatar)`
  margin-right: ${margins.size3};
`;

const TextColumn = styled(FlexColumn)`
  flex: 1;
  align-items: start;
  overflow: hidden;
  text-align: start;
`;

const Name = styled(Heading3)`
  text-align: left;
`;

const SeeMoreContainer = styled(Clickable)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  margin-right: ${margins.size2};
  margin-left: auto;
  border-radius: 50%;

  :hover {
    background-color: ${colors.primaryLight};
  }
`;

const SeeMoreButton = styled(SVG)`
  & > rect {
    fill: none;
  }
`;
