import React from 'react';
import styled from 'styled-components/macro';

import { colors, margins, media, FlexRow, Text, Card } from 'css/css';

import { postTestActivity } from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';
import { getIsMobile } from 'js/util/util';

import { MainHeading } from '../style';
import { LandingPageNav as Nav } from '../components/Nav';
import { RegisterEmail } from '../components/RegisterEmail';
import { RegisterGoogle } from '../components/RegisterGoogle';
import { AccountLogin } from '../components/AccountLogin';
import { AppLinks } from '../components/AppLinks';
import { AnimatedAbout } from '../components/AnimatedAbout';

export const SinglePageHero = ({ copy }: { copy: { header: string; subHeader: string } }) => {
  const urlParams = 'in_single_page_landing';
  useMountEffect(() => {
    postTestActivity('landing_page', urlParams || '');
  });

  const isMobile = getIsMobile();

  return (
    <Container>
      <Nav />
      <Content>
        <MobileSpacer>
          <TitleHeader>{copy.header}</TitleHeader>
          <ParagraphMedium>{copy.subHeader}</ParagraphMedium>
          <SignupContainer>
            <RegisterGoogle urlParams={urlParams} />
            <DividerText>OR</DividerText>
            <RegisterEmail urlParams={urlParams} />
          </SignupContainer>
          {!isMobile && <AccountLogin />}
          {!isMobile && <AppLinks />}
        </MobileSpacer>
        <AnimatedAbout />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: ${margins.size4} ${margins.size5} 100px ${margins.size5};
  background: linear-gradient(90deg, ${colors.greyLight} 50%, ${colors.whiteMain} 50%);
  flex: 1;

  ${media.mobile} {
    background: none;
    padding: ${margins.size3} 0px;
  }
`;

const Content = styled(FlexRow)`
  max-width: 1400px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  ${media.mobile} {
    flex-direction: column;
  }
`;

const MobileSpacer = styled.div`
  width: 100%;
  margin-top: ${margins.size4};
  padding-right: ${margins.size7};
  ${media.mobile} {
    width: 100%;
    padding: 0px ${margins.size3};
  }
`;

const TitleHeader = styled(MainHeading)`
  margin-top: ${margins.size5};
  ${media.mobile} {
    margin-top: 0px;
  }
`;

const ParagraphMedium = styled(Text)`
  margin-top: ${margins.size4};
  width: 82%;
  font-size: 18px;
  line-height: 1.2;
  color: ${colors.blackMid};
  ${media.mobile} {
    font-size: 14px;
    width: 100%;
  }
`;

const SignupContainer = styled(Card)`
  border: none;
  padding: ${margins.size4};
  margin-top: ${margins.size4};
  margin-bottom: ${margins.size2};
  box-shadow: 0px 0px 23px rgba(38, 45, 68, 0.1);
`;

const DividerText = styled(Text)`
  text-align: center;
  margin-top: ${margins.size2};
  margin-bottom: -${margins.size3};
`;
