import React, { useEffect, useState } from 'react';

import { margins, FlexColumn, Heading1, Heading2, Text } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { Modal } from 'js/components/shared/NewModal';
import { useUser } from 'js/providers/UserProvider';
import { describeWeeklyTimeslots, INTERNATIONAL_TIME_LOCALES } from 'js/util/util';

import autopilotSvg from 'img/weekly/autopilot.svg';

interface Props {
  showModal: boolean;
  timeslots: string[];
  accept: () => void;
}

export const NewUserForcedAutopilotModal: React.FC<Props> = ({ showModal, timeslots, accept }) => {
  const user = useUser();

  const [modalOpen, setModalOpen] = useState(showModal);

  useEffect(() => {
    setModalOpen(showModal);
  }, [showModal]);

  const show24hourTime = INTERNATIONAL_TIME_LOCALES.includes(user.locale);

  return (
    <Modal showModal={modalOpen}>
      <FlexColumn style={{ textAlign: 'center' }}>
        <Heading1 style={{ margin: `${margins.size2} 0px` }}>Welcome to Lunchclub</Heading1>
        <img src={autopilotSvg} alt="" />
        <Heading2 style={{ marginBottom: margins.size2 }}>New Connections Every Week</Heading2>
        <Text style={{ marginBottom: margins.size2 }}>
          You will receive {timeslots.length > 1 ? 'new matches' : 'a new match'} every week on{' '}
          {describeWeeklyTimeslots(timeslots, show24hourTime)}
          . Lunchclub uses hundreds of parameters to find the best possible match.
          <br />
          Sit back, relax, and let us take it from here.
        </Text>

        <Button large onClick={accept} style={{ marginTop: margins.size3 }}>
          Continue
        </Button>
      </FlexColumn>
    </Modal>
  );
};
