import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { FlexRow, Heading3, margins, Text, FlexColumn, Clickable } from 'css/css';

import {
  SimpleModalWrap,
  useSimpleModal,
  WhiteCardAction,
  WhiteCardHeader,
} from 'js/components/shared/MobileModal';
import {
  getUserUnsubscriptionsMobile,
  postUserUnsubscriptionsMobile,
  UnsubscribeTypes,
} from 'js/util/api';
import { useMountEffect } from 'js/util/custom-hooks';
import { ToggleSwitch } from 'js/components/shared/ToggleSwitch';
import { useResource } from 'js/util/use-resource';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { getIsIOSApp } from 'js/util/util';
import { SettingsSection } from 'js/components/settings/components/shared-components/EditableSection';

import ChatIcon from 'img/settings/chat.svg';
import NewFeaturesIcon from 'img/settings/new-features.svg';

import { SubscribeAllText } from './Notifications';

const ICON_MAP: { [key: string]: string } = {
  '2': NewFeaturesIcon,
  '3': ChatIcon,
};

const unsubTitles: { [key: string]: string } = getIsIOSApp()
  ? {
      email: 'Email notifications',
      notif: 'Push notifications',
    }
  : {
      email: 'Email notifications',
    };

export const NotificationsMobile: React.FC = () => {
  const [selectedUnsubscribeTypeId, setSelectedUnsubscribeTypeId] = useState<string>('');
  const [unsubscribeTypes] = useResource<UnsubscribeTypes>(`discover/unsubscribe_types`);
  const [userUnsubscribeIds, setUserUnsubscribeIds] = useState<Record<string, number[]>>({
    email: [],
    notif: [],
  });
  const [updatedUnsubscribeIds, setUpdatedUnsubscribeIds] = useState<Record<string, number[]>>({
    email: [],
    notif: [],
  });

  useMountEffect(() => {
    const loadUserUnsubscriptions = async () => {
      const res = await getUserUnsubscriptionsMobile();
      if (res.ok) {
        setUserUnsubscribeIds(res.getJson);
        setUpdatedUnsubscribeIds(res.getJson);
      }
    };
    loadUserUnsubscriptions();
  });

  const { openModal, closeModal, wrapperProps } = useSimpleModal();

  if (!unsubscribeTypes) {
    return <SVGLoader center={false} />;
  }

  const isMobileApp = getIsIOSApp();
  const getHasUnsubscribedAll = (category: string) => {
    return (
      updatedUnsubscribeIds[category].length ===
      Object.keys(unsubscribeTypes).filter(unsubscribeType => unsubscribeType !== '0').length
    );
  };

  const unsubscribedAllEmails = getHasUnsubscribedAll('email');
  const unsubscribedAllNotifs = getHasUnsubscribedAll('notif');

  const icon =
    selectedUnsubscribeTypeId &&
    selectedUnsubscribeTypeId in ICON_MAP &&
    ICON_MAP[selectedUnsubscribeTypeId];

  const isSubscribed = (category: string) => {
    const selectedUnsubscribeTypeIdNum = parseInt(selectedUnsubscribeTypeId, 10);
    return !(
      updatedUnsubscribeIds[category].includes(selectedUnsubscribeTypeIdNum) ||
      getHasUnsubscribedAll(category)
    );
  };

  const submitNewSubscriptionTypes = async () => {
    await postUserUnsubscriptionsMobile(updatedUnsubscribeIds);
    setUserUnsubscribeIds(updatedUnsubscribeIds);
    setSelectedUnsubscribeTypeId('');
  };

  const toggleSubscriptionType = (category: string, unsubscriptionId: string) => {
    const unsubscriptionIdInt = parseInt(unsubscriptionId, 10);
    setUpdatedUnsubscribeIds(prev => {
      return {
        ...prev,
        [category]: prev[category].includes(unsubscriptionIdInt)
          ? prev[category].filter(id => id !== unsubscriptionIdInt)
          : [...prev[category], unsubscriptionIdInt],
      };
    });
    // submitNewSubscriptionTypes();
  };

  const toggleUnsubscribeAll = (category: string) => {
    if (!unsubscribeTypes) return;
    if (!getHasUnsubscribedAll(category)) {
      setUpdatedUnsubscribeIds(prev => {
        return {
          ...prev,
          [category]: [
            ...Object.keys(unsubscribeTypes)
              .filter(unsubscribeType => unsubscribeType !== '0')
              .map(unsubscribeType => parseInt(unsubscribeType, 10)),
          ],
        };
      });
    } else {
      setUpdatedUnsubscribeIds(prev => {
        return {
          ...prev,
          [category]: [],
        };
      });
    }
    submitNewSubscriptionTypes();
  };

  const getCategoriesSubtextCopy = (unsubscriptionId: string) => {
    const unsubscriptionIdInt = parseInt(unsubscriptionId, 10);
    if (
      isMobileApp &&
      !updatedUnsubscribeIds.email.includes(unsubscriptionIdInt) &&
      !updatedUnsubscribeIds.notif.includes(unsubscriptionIdInt)
    ) {
      return 'Push and email notifications';
    }
    if (!updatedUnsubscribeIds.email.includes(unsubscriptionIdInt)) {
      return 'Email notifications only';
    }
    if (!updatedUnsubscribeIds.notif.includes(unsubscriptionIdInt) && isMobileApp) {
      return 'Push notifications only';
    }
    return 'No notifications';
  };

  const handleEditClick = (unsubscriptionId: string) => {
    setSelectedUnsubscribeTypeId(unsubscriptionId);
    openModal();
  };

  const handleCloseModal = () => {
    setUpdatedUnsubscribeIds(userUnsubscribeIds);
    closeModal();
  };

  return (
    <div>
      {selectedUnsubscribeTypeId && (
        <SimpleModalWrap
          {...wrapperProps}
          actions={
            <WhiteCardAction title="Update preferences" onClick={submitNewSubscriptionTypes} />
          }
        >
          <WhiteCardHeader title="Edit notifications" onClose={handleCloseModal} />
          <div style={{ textAlign: 'center', marginBottom: margins.size3 }}>
            {!!icon && <Icon src={icon} alt={unsubscribeTypes[selectedUnsubscribeTypeId].title} />}
            <Heading3>{unsubscribeTypes[selectedUnsubscribeTypeId].title}</Heading3>
            <Text style={{ padding: `${margins.size1} ${margins.size2}` }}>
              {unsubscribeTypes[selectedUnsubscribeTypeId].subtitle}
            </Text>
          </div>
          <FlexColumn>
            {Object.keys(unsubTitles).map(unsubType => (
              <FlexRow
                width="100%"
                justifyContent="space-between"
                style={{ padding: margins.size2 }}
              >
                <Text>{unsubTitles[unsubType]}</Text>
                <ToggleSwitch
                  styles={{ minWidth: '30px' }}
                  isOn={isSubscribed(unsubType)}
                  onClick={() => toggleSubscriptionType(unsubType, selectedUnsubscribeTypeId)}
                />
              </FlexRow>
            ))}
          </FlexColumn>
        </SimpleModalWrap>
      )}

      {Object.keys(unsubscribeTypes)
        .filter(unsubscribeType => unsubscribeType !== '0')
        .sort((a, b) => {
          const textA = unsubscribeTypes[a].title;
          const textB = unsubscribeTypes[b].title;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map(unsubscribeType => {
          const { title } = unsubscribeTypes[unsubscribeType];
          return (
            <SettingsSection
              onClick={() => handleEditClick(unsubscribeType)}
              headingTitle={title}
              selectedOptions={getCategoriesSubtextCopy(unsubscribeType)}
            />
          );
        })}
      <Clickable
        onClick={() => toggleUnsubscribeAll('email')}
        style={{ width: 'fit-content', marginTop: margins.size1 }}
      >
        <SubscribeAllText>
          {`${unsubscribedAllEmails ? 'Re' : 'Un'}subscribe ${
            unsubscribedAllEmails ? 'to' : 'from'
          } all optional emails`}
        </SubscribeAllText>
      </Clickable>
      {isMobileApp && (
        <Clickable
          onClick={() => toggleUnsubscribeAll('notif')}
          style={{ width: 'fit-content', marginTop: margins.size1 }}
        >
          <SubscribeAllText>
            {`${unsubscribedAllNotifs ? 'Re' : 'Un'}subscribe ${
              unsubscribedAllNotifs ? 'to' : 'from'
            } all push notifications`}
          </SubscribeAllText>
        </Clickable>
      )}
    </div>
  );
};

const Icon = styled.img`
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  margin: ${margins.size3};
`;
