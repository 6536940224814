import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import { colors, fonts, margins, SubText } from 'css/css';

import { CoachingPlanDetails, PastAvailability } from 'types/availability';
import { LunchclubMatch } from 'types/matches';

import { Button } from 'js/components/shared/Button';
import { useUser } from 'js/providers/UserProvider';
import { addParameterToURL } from 'js/util/util';

import { coffeeSvg, waitingSvg } from 'img/weekly-v2';

import { ProfileAvatarImage, ProfileBackground, ProfileMobile } from '../../profile/ProfileMobile';
import { useFullscreenOnScroll } from '../use-fullscreen-on-scroll';

import { FeedbackCard } from './FeedbackCard';

const formatTimeslot = (timeslot: string) => moment(timeslot).format('ddd, MMM Do | h:mma');

interface MobileMatchesCarouselProps {
  availability?: PastAvailability;
  matches: LunchclubMatch[];
  pastRescheduleDeadline: boolean;
  setUserSubmittingFeedback: React.Dispatch<React.SetStateAction<boolean>>;
  missingFeedback: string[];
  coachingPlanDetails?: CoachingPlanDetails;
}

export const MobileMatchesCarousel: React.FC<MobileMatchesCarouselProps> = ({
  availability,
  matches,
  pastRescheduleDeadline,
  setUserSubmittingFeedback,
  missingFeedback,
  coachingPlanDetails,
}) => {
  const user = useUser();
  const matchesToReview = matches.filter(match => missingFeedback.includes(match.feedback_code));

  const matchesInFuture = matches.filter(
    match =>
      moment.tz(match.time_slot_with_exact_time, user.locale_info.timezone).add(2, 'hours') >=
      moment.tz(user.locale_info.timezone),
  );

  const matchesTbd = (availability?.timeslots || []).filter(
    t => !matchesInFuture.some(m => m.time_slot_with_exact_time === t),
  );

  // We show the "upcoming slant match" card if you opted in and the number of assigned match profiles
  // is less than or equal to the number of TBD match timeslots. eg: If you have 2 future matches and
  // only 1 future timeslot, we know the slant match is one of them and no longer need the TBD card.
  const slantMatchTbd = availability?.slantOptIn && matchesInFuture.length <= matchesTbd.length;

  return (
    <>
      {missingFeedback.length ? (
        <FeedbackCard
          matchesToReview={matchesToReview}
          startSubmittingFeedback={setUserSubmittingFeedback}
        />
      ) : availability?.passed ? (
        <>
          <MatchesCarouselContainer>
            {matchesInFuture.map(match => (
              <CarouselMatchProfileCard key={match.match_user.public_id} match={match} />
            ))}
            <CarouselCard empty>
              <EmptyContent>
                <img src={coffeeSvg} alt="coffee cup" />
                <h2>You passed for the week!</h2>
                {pastRescheduleDeadline ? (
                  <p>Sign up for next week below.</p>
                ) : (
                  <p>
                    Changed your mind?
                    <br />
                    Sign up for a match!
                  </p>
                )}
              </EmptyContent>
            </CarouselCard>
          </MatchesCarouselContainer>
          <Link to={addParameterToURL('ref', 'meetings', `/weekly`)}>
            <Button large>
              {pastRescheduleDeadline ? `Sign up for next week` : `Sign up for this week`}
            </Button>
          </Link>
        </>
      ) : matchesInFuture.length || availability?.timeslots.length || slantMatchTbd ? (
        <>
          <MatchesCarouselContainer>
            {matchesInFuture.map(match => (
              <CarouselMatchProfileCard key={match.match_user.public_id} match={match} />
            ))}
            {matchesTbd.map(t => (
              <CarouselMatchTBDCard
                key={t}
                time={formatTimeslot(t)}
                targetLocale={availability?.targetLocale}
                autopilot={availability?.autopilot}
                coachingPlanDetails={coachingPlanDetails}
              />
            ))}
            {slantMatchTbd && (
              <CarouselSlantMatchCard key="slant" targetLocale={availability?.targetLocale} />
            )}
          </MatchesCarouselContainer>
          {(matchesTbd.length > 0 || slantMatchTbd) && (
            <Link to={addParameterToURL('ref', 'meetings', `/weekly`)}>
              <Button large secondary style={{ marginBottom: margins.size2 }}>
                Edit availability
              </Button>
            </Link>
          )}
        </>
      ) : (
        <>
          <MatchesCarouselContainer>
            <CarouselCard empty>
              <EmptyContent>
                <img src={coffeeSvg} alt="coffee cup" />
                <h2>You in next week?</h2>
                <p>Sign up for matches below</p>
              </EmptyContent>
            </CarouselCard>
          </MatchesCarouselContainer>
          <Link to={addParameterToURL('ref', 'meetings', `/weekly`)}>
            <Button large>
              {pastRescheduleDeadline ? `Sign up for next week` : `Sign up for this week`}
            </Button>
          </Link>
        </>
      )}
    </>
  );
};

export const CarouselMatchProfileCard: React.FC<{ match: LunchclubMatch }> = ({ match }) => {
  const user = match.match_user;

  const { containerStyles, containerProps } = useFullscreenOnScroll();

  return (
    <CarouselCard style={containerStyles} {...containerProps}>
      <ProfileBackground>
        <ProfileAvatarImage alt="profile" src={user.image} avatarId={match.match_user.public_id} />
      </ProfileBackground>

      <ProfileMobile
        profile={user}
        note={
          <div style={{ color: colors.blackMain, opacity: 0.7, marginBottom: margins.size2 }}>
            {`Meeting on ${moment(match.time_slot_with_exact_time).format('ddd MMM DD, h:mma')}`}
          </div>
        }
        endorsements={[]}
      />

      <div style={{ marginTop: margins.size2, color: colors.blackMain, opacity: 0.7 }}>
        Need to reschedule?{' '}
        <Link to={`/chat?chat_id=${user.public_id}`}>Let {user.first_name} know!</Link>
      </div>
      <div style={{ flex: 1 }} />
    </CarouselCard>
  );
};
const CarouselMatchTBDCard: React.FC<{
  time: string;
  targetLocale?: string;
  autopilot?: boolean;
  coachingPlanDetails?: CoachingPlanDetails;
}> = ({ time, targetLocale, autopilot, coachingPlanDetails }) => (
  <CarouselCard empty>
    <EmptyContent autopilot={autopilot}>
      <img style={{ maxWidth: '70%' }} src={waitingSvg} alt="Match coming soon!" />
      <h2>{time.replace(' | ', ' at ').replace(':00', '')}</h2>
      <div>{targetLocale ? `Match from ${targetLocale}` : 'Best match anywhere'}</div>
      <p style={autopilot ? { marginBottom: 0 } : {}}>Your match will be ready for you soon!</p>
      {coachingPlanDetails ? (
        <SubText style={{ padding: '4px 8px' }}>
          You&apos;re on Curated Matches and set to meet at this time each week until your plan
          finishes. You can change your availability for the week before 8pm on Tuesday, or opt-out
          of Curated Matches entirely on your settings page.
        </SubText>
      ) : (
        autopilot && (
          <SubText style={{ padding: '4px 8px' }}>
            You&apos;re on autopilot and set to meet at this time each week. You can change your
            availability for the week before 8pm on Tuesday, or opt-out of autopilot entirely on
            your settings page.
          </SubText>
        )
      )}
    </EmptyContent>
  </CarouselCard>
);

const CarouselSlantMatchCard: React.FC<{ targetLocale?: string }> = ({ targetLocale }) => (
  <CarouselCard empty>
    <EmptyContent>
      <img style={{ maxWidth: '70%' }} src={waitingSvg} alt="Match coming soon!" />
      <h2>Time to be determined</h2>
      <div>{targetLocale ? `Match from ${targetLocale}` : 'Best match anywhere'}</div>
      <p>
        Your match will be ready for
        <br />
        you soon!
      </p>
    </EmptyContent>
  </CarouselCard>
);

const MatchesCarouselContainer = styled.div`
  padding: 0 ${margins.size3};
  margin: 0 -${margins.size3};
  margin-bottom: ${margins.size2};
  flex: 1;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  display: flex;
  justify-content: stretch;
  align-self: stretch;

  scrollbar-color: transparent;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar:horizontal {
    height: 0;
  }
  &:after {
    content: ' ';
    width: 16px;
    flex-shrink: 0;
  }
`;

const CarouselCard = styled.div<{ empty?: boolean; floatingFullscreen?: string }>`
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 10px;
  margin-right: ${margins.size1};
  background: ${colors.whiteMain};
  scroll-snap-align: center;
  justify-content: ${p => (p.empty ? `center` : 'flex-start')};
  overflow-y: scroll;

  ${ProfileBackground} {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

const EmptyContent = styled.div<{ autopilot?: boolean }>`
  align-self: center;
  text-align: center;
  font-size: 15px;
  line-height: 19px;
  color: ${colors.blackMain};

  h2 {
    padding-top: ${p => (p.autopilot ? '0px' : margins.size4)};
    font-family: ${fonts.extraBold};
    font-size: 17px;
  }
  p {
    padding-top: ${p => (p.autopilot ? margins.size3 : margins.size4)};
    line-height: 1.45;
    opacity: 0.7;
  }
`;
