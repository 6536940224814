import React from 'react';
import styled from 'styled-components/macro';

import { Heading3, Text as CssText, margins, fontSizes, colors, media } from 'css/css';

import { Button as SharedButton } from 'js/components/shared/Button';

export const BorderText = styled(Heading3)`
  padding: ${margins.size1} ${margins.size2};
  color: ${colors.whiteMain};
  border-radius: 5px;
  border: 1px solid ${colors.whiteMain};
  ${media.mobile} {
    padding: 0;
    text-align: center;
    border: none;
  }
`;

export const Text = styled(CssText)`
  margin: 0 ${margins.size2};
  color: ${colors.whiteMain};
`;

export const BoldText = styled(Heading3)`
  font-weight: bold;
  color: ${colors.whiteMain};
`;

export const Button: React.FC<{ onClick: () => void }> = ({ onClick, children }) => (
  <SharedButton
    onClick={onClick}
    style={{ margin: `0 ${margins.size2}`, lineHeight: fontSizes.size2, boxShadow: 'none' }}
    secondary
  >
    {children}
  </SharedButton>
);
