import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { Card, margins, Heading1, Text, media } from 'css/css';

import { Button } from 'js/components/shared/Button';
import { Headline } from 'js/components/shared/Headline';
import { GreyPage } from 'js/components/shared/page-wrappers';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { validateHeadlineLength } from 'js/util/util';

export const MissingHeadline = () => {
  const { first_name: firstName } = useUser();
  const { updateUser } = useUserContextProvider();

  const [headline, setHeadline] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);

  const onSubmit = async () => {
    if (!validateHeadlineLength(headline.length)) {
      return;
    }
    setButtonLoading(true);
    const fullHeadline = `${firstName} is ${headline}`;
    updateUser({
      headline: fullHeadline,
    }).then(() => setButtonLoading(false));
  };

  return (
    <GreyPage>
      <Container>
        <Heading1>Introduce yourself</Heading1>

        <Text style={{ marginBottom: margins.size3 }}>
          {`Before we start curating relevant matches for you, tell us how you'd like to be intro'd!
            The following email will be sent to your next match.`}
        </Text>

        <Headline firstName={firstName} headline={headline} setHeadline={setHeadline} />
        <Button
          style={{ marginTop: margins.size3 }}
          onClick={onSubmit}
          invalid={!validateHeadlineLength(headline.length)}
          loading={buttonLoading}
        >
          Continue
        </Button>
      </Container>
    </GreyPage>
  );
};

const Container = styled(Card)`
  padding: ${margins.size5} ${margins.size6};
  max-width: 650px;
  ${media.mobile} {
    padding: ${margins.size5} ${margins.size4};
  }
  margin-bottom: ${margins.size6};
`;
