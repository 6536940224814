import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { FlexRow, colors, borders } from 'css/css';

import { CurrentMatch } from './current-match';

interface Props {
  setIsBannerVisible?: (isVisible: boolean) => void;
}
export const Banner: React.FC<Props> = ({ setIsBannerVisible }) => {
  const [hasCurrentMatch, setHasCurrentMatch] = useState(false);

  useEffect(() => {
    if (setIsBannerVisible) setIsBannerVisible(hasCurrentMatch);
  }, [hasCurrentMatch]);

  return (
    <Container isVisible={hasCurrentMatch}>
      <CurrentMatch setHasCurrentMatch={setHasCurrentMatch} />
    </Container>
  );
};

const Container = styled(FlexRow)<{ isVisible: boolean }>`
  ${p => !p.isVisible && 'display: none'};
  justify-content: center;
  align-items: center;
  height: 56px;
  min-height: 56px;
  color: ${colors.whiteMain};
  background-color: #31b776;
  border-top: ${borders.standard};
`;
