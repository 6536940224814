import {
  DailyEventObjectCameraError,
  DailyEventObjectFatalError,
  DailyEventObjectParticipant,
} from '@daily-co/daily-js';
import Bowser from 'bowser';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getIsMobile } from 'js/util/util';
import { getDefaultCallDevices, postDefaultCallDevices } from 'js//util/api';
import { isIOSMobile, isSafari } from 'js/components/callv2/utils/browser';

import { useCallV2Context } from '../CallContext';

export type DeviceState = 'loading' | 'pending' | 'error' | 'granted' | 'not-supported';

export type DeviceError = 'blocked' | 'not-found' | 'in-use';

const REREQUEST_INTERVAL = 2000;

export type TUseDeviceState = {
  camError: DeviceError | null;
  cams: MediaDeviceInfo[];
  currentCam: MediaDeviceInfo | null;
  currentMic: MediaDeviceInfo | null;
  currentSpeaker: MediaDeviceInfo | null;
  deviceState: DeviceState;
  micError: DeviceError | null;
  mics: MediaDeviceInfo[];
  refreshDevices: () => Promise<any>;
  setCurrentCam: React.Dispatch<React.SetStateAction<MediaDeviceInfo | null>>;
  setCurrentMic: React.Dispatch<React.SetStateAction<MediaDeviceInfo | null>>;
  setCurrentSpeaker: React.Dispatch<React.SetStateAction<MediaDeviceInfo | null>>;
  speakers: MediaDeviceInfo[];
  selectCamera: (newCam: string | undefined) => Promise<void>;
  selectMic: (newMic: string | undefined) => Promise<void>;
  selectSpeaker: (newSpeaker: string | undefined) => void;
};

export const useDeviceState = (): TUseDeviceState => {
  const { daily } = useCallV2Context();
  const [deviceState, setDeviceState] = useState<TUseDeviceState['deviceState']>('loading');
  const [currentCam, setCurrentCam] = useState<TUseDeviceState['currentCam']>(null);
  const [currentMic, setCurrentMic] = useState<TUseDeviceState['currentMic']>(null);
  const [currentSpeaker, setCurrentSpeaker] = useState<TUseDeviceState['currentSpeaker']>(null);
  const [cams, setCams] = useState<TUseDeviceState['cams']>([]);
  const [mics, setMics] = useState<TUseDeviceState['mics']>([]);
  const [speakers, setSpeakers] = useState<TUseDeviceState['speakers']>([]);
  const [camError, setCamError] = useState<TUseDeviceState['camError']>(null);
  const [micError, setMicError] = useState<TUseDeviceState['micError']>(null);

  const [didSetDevices, setDidSetDevices] = useState<boolean>(false);

  const isMobile = getIsMobile();

  const selectCamera = useCallback<TUseDeviceState['selectCamera']>(
    async newCam => {
      if (!daily || !newCam || newCam === currentCam?.deviceId) return;
      const { camera } = await daily.setInputDevicesAsync({
        videoDeviceId: newCam,
      });
      setCurrentCam(camera as MediaDeviceInfo);
    },
    [daily, currentCam, setCurrentCam],
  );

  const selectMic = useCallback<TUseDeviceState['selectMic']>(
    async newMic => {
      if (!daily || !newMic || newMic === currentMic?.deviceId) return;
      const { mic } = await daily.setInputDevicesAsync({
        audioDeviceId: newMic,
      });
      setCurrentMic(mic as MediaDeviceInfo);
    },
    [daily, currentMic, setCurrentMic],
  );

  const selectSpeaker = useCallback<TUseDeviceState['selectSpeaker']>(
    newSpeaker => {
      if (!daily || !newSpeaker || newSpeaker === currentSpeaker?.deviceId) return;
      const speaker = speakers.find(d => d.deviceId === newSpeaker);
      daily.setOutputDevice({
        outputDeviceId: newSpeaker,
      });
      setCurrentSpeaker(speaker as MediaDeviceInfo);
    },
    [daily, currentSpeaker, setCurrentSpeaker],
  );

  const updateDeviceState = async () => {
    if (
      typeof navigator?.mediaDevices?.getUserMedia === 'undefined' ||
      typeof navigator?.mediaDevices?.enumerateDevices === 'undefined'
    ) {
      setDeviceState('not-supported');
      return;
    }
    try {
      const { isAudioEnabled, isVideoEnabled, devices } = await getDevicePermissions();

      const availableCameras = devices.filter(d => d.kind === 'videoinput' && d.label !== '');
      const availableMicrophones = devices.filter(d => d.kind === 'audioinput' && d.label !== '');
      const availableSpeakers = devices.filter(d => d.kind === 'audiooutput' && d.label !== '');

      setCams(availableCameras);
      setMics(availableMicrophones);
      setSpeakers(availableSpeakers);

      if (!didSetDevices) {
        const res = await getDefaultCallDevices(isMobile);
        const { video_device_id, speaker_device_id, microphone_device_id } = res?.getJson;
        if (isVideoEnabled) {
          const cameraDeviceId = findDeviceIdWithPriority(availableCameras, video_device_id);
          selectCamera(cameraDeviceId);
        }
        if (isAudioEnabled) {
          const speakerDeviceId = findDeviceIdWithPriority(availableSpeakers, speaker_device_id);
          selectSpeaker(speakerDeviceId);
          const microphoneDeviceId = findDeviceIdWithPriority(
            availableMicrophones,
            microphone_device_id,
          );
          selectMic(microphoneDeviceId);
        }
        setDidSetDevices(true);
      }
    } catch (e) {
      setDeviceState('not-supported');
    }
  };

  // NOTE: This isn't being called correctly when daily !== null
  const updateDeviceErrors = useCallback(() => {
    if (!daily) return;
    const { tracks } = daily.participants().local;

    if (tracks.video?.blocked?.byPermissions) {
      setCamError('blocked');
    } else if (tracks.video?.blocked?.byDeviceMissing) {
      setCamError('not-found');
    } else if (tracks.video?.blocked?.byDeviceInUse) {
      setCamError('in-use');
    }
    if (['loading', 'off', 'playable', 'sendable'].includes(tracks.video.state)) {
      setCamError(null);
    }

    if (tracks.audio?.blocked?.byPermissions) {
      setMicError('blocked');
    } else if (tracks.audio?.blocked?.byDeviceMissing) {
      setMicError('not-found');
    } else if (tracks.audio?.blocked?.byDeviceInUse) {
      setMicError('in-use');
    }
    if (['loading', 'off', 'playable', 'sendable'].includes(tracks.audio.state)) {
      setMicError(null);
    }
  }, [camError, daily, micError]);

  const handleParticipantUpdated = useCallback(
    (event?: DailyEventObjectParticipant) => {
      if (!daily || deviceState === 'not-supported' || !event?.participant.local) return;

      switch (event?.participant?.tracks.video.state) {
        case 'blocked':
          setDeviceState('error');
          break;
        case 'off':
        case 'playable':
          updateDeviceState();
          setDeviceState('granted');
          break;
        default:
          break;
      }
      updateDeviceErrors();
    },
    [daily, deviceState, updateDeviceErrors, updateDeviceState],
  );

  useEffect(() => {
    if (!daily) return () => undefined;

    let pendingAccessTimeout: NodeJS.Timeout;

    const handleJoiningMeeting = () => {
      pendingAccessTimeout = setTimeout(() => {
        setDeviceState('pending');
      }, 2000);
    };

    const handleJoinedMeeting = () => {
      clearTimeout(pendingAccessTimeout);
      updateDeviceState();
    };

    daily.on('joining-meeting', handleJoiningMeeting);
    daily.on('joined-meeting', handleJoinedMeeting);
    daily.on('participant-updated', handleParticipantUpdated);
    return () => {
      daily.off('joining-meeting', handleJoiningMeeting);
      daily.off('joined-meeting', handleJoinedMeeting);
      daily.off('participant-updated', handleParticipantUpdated);
    };
  }, [daily, handleParticipantUpdated, updateDeviceState]);

  useEffect(() => {
    if (!daily) return () => undefined;

    const handleCameraError = (event?: DailyEventObjectCameraError) => {
      switch (event?.error?.type) {
        case 'cam-in-use':
          setDeviceState('error');
          setCamError('in-use');
          break;
        case 'mic-in-use':
          setDeviceState('error');
          setMicError('in-use');
          break;
        case 'cam-mic-in-use':
          setDeviceState('error');
          setCamError('in-use');
          setMicError('in-use');
          break;
        default:
          switch (event?.errorMsg?.errorMsg) {
            case 'devices error':
              setDeviceState('error');
              setCamError(event?.errorMsg?.videoOk ? null : 'not-found');
              setMicError(event?.errorMsg?.audioOk ? null : 'not-found');
              break;
            case 'not allowed':
              setDeviceState('error');
              updateDeviceErrors();
              break;
            default:
              break;
          }
          break;
      }
    };

    const handleError = (event?: DailyEventObjectFatalError) => {
      switch (event?.errorMsg) {
        case 'not allowed':
          setDeviceState('error');
          updateDeviceErrors();
          break;
        default:
          break;
      }
    };

    const handleStartedCamera = () => {
      updateDeviceErrors();
    };

    daily.on('camera-error', handleCameraError);
    daily.on('error', handleError);
    daily.on('started-camera', handleStartedCamera);
    return () => {
      daily.off('camera-error', handleCameraError);
      daily.off('error', handleError);
      daily.off('started-camera', handleStartedCamera);
    };
  }, [daily, updateDeviceErrors]);

  const browser = useMemo(() => Bowser.parse(navigator.userAgent), []);

  /**
   * Automatically re-request cam access.
   */
  useEffect(() => {
    if (!daily || !camError) return () => undefined;

    let interval: any;
    switch (camError) {
      case 'in-use':
        if (browser?.browser?.name === 'Firefox') return () => undefined;
      // eslint-disable-next-line no-fallthrough
      case 'blocked':
        if (isSafari() || isIOSMobile()) return () => undefined;
        interval = setInterval(() => {
          try {
            daily.setLocalVideo(true);
            updateDeviceErrors();
          } catch {
            // eslint-disable-next-line no-console
            console.debug('Failed to set local video');
          }
        }, REREQUEST_INTERVAL);
        break;
      default:
        updateDeviceState();
        break;
    }
    return () => {
      clearInterval(interval);
    };
  }, [browser, camError, daily, updateDeviceErrors]);

  /**
   * Automatically re-request mic access.
   */
  useEffect(() => {
    if (!daily || !micError) return () => undefined;

    let interval: any;
    switch (micError) {
      case 'in-use':
        if (browser?.browser?.name === 'Firefox') return () => undefined;
      // eslint-disable-next-line no-fallthrough
      case 'blocked':
        if (isSafari() || isIOSMobile()) return () => undefined;
        interval = setInterval(() => {
          try {
            daily.setLocalAudio(true);
            updateDeviceErrors();
            // eslint-disable-next-line no-empty
          } catch {}
        }, REREQUEST_INTERVAL);
        break;
      default:
        updateDeviceState();
        break;
    }
    return () => {
      clearInterval(interval);
    };
  }, [browser, micError, daily, updateDeviceErrors, updateDeviceState]);

  useEffect(() => {
    if (currentCam?.deviceId && currentMic?.deviceId && currentSpeaker?.deviceId) {
      postDefaultCallDevices({
        videoDeviceId: currentCam.deviceId,
        speakerDeviceId: currentSpeaker.deviceId,
        microphoneDeviceId: currentMic.deviceId,
        isMobile,
      });
    }
  }, [currentCam?.deviceId, currentMic?.deviceId, currentSpeaker?.deviceId]);

  return {
    camError,
    cams,
    currentCam,
    currentMic,
    currentSpeaker,
    deviceState,
    micError,
    mics,
    refreshDevices: updateDeviceState,
    setCurrentCam,
    setCurrentMic,
    setCurrentSpeaker,
    speakers,
    selectCamera,
    selectMic,
    selectSpeaker,
  };
};

export const getDevicePermissions = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();

  const isAudioEnabled = !!devices.find(d => d.kind === 'audioinput' && d.label !== '');

  // Disabling audio also prevents video from working on Dailyco's side
  const isVideoEnabled =
    isAudioEnabled && !!devices.find(d => d.kind === 'videoinput' && d.label !== '');

  return { isAudioEnabled, isVideoEnabled, devices };
};

const findDeviceIdWithPriority = (devices: MediaDeviceInfo[], defaultDeviceId: string | null) => {
  const chosenDeviceId =
    !!defaultDeviceId && devices.find(d => d.deviceId === defaultDeviceId)?.deviceId;
  if (chosenDeviceId) return chosenDeviceId;
  return devices.find(d => d?.deviceId)?.deviceId;
};
