import React, { useRef, useState, useEffect } from 'react';

import { margins, Text } from 'css/css';

import {
  SimpleFullHeightModalWrap,
  useSimpleModal,
  WhiteCardAction,
  WhiteCardHeader,
} from 'js/components/shared/MobileModal';
import { getIsMobile, validateEmail } from 'js/util/util';
import { useNotifContext } from 'js/util/notif-context';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { GrayEmailInput } from 'js/components/mobile-root';
import { SettingsSection } from 'js/components/settings/components/shared-components/EditableSection';

import emailSvg from 'img/settings/email.svg';

import { Body } from '../styles';
import { SectionsTitles } from '../constants';

import { SettingsOption } from './SettingsOption';

export const PrimaryEmailEditor: React.FC = () => {
  const user = useUser();

  const { openModal, closeModal, wrapperProps } = useSimpleModal();
  const { showNotif } = useNotifContext();
  const { updateUser } = useUserContextProvider();

  const inputRef = useRef<HTMLInputElement>();
  const [savingEmail, setSavingEmail] = useState(false);
  const [editingEmail, setEditingEmail] = useState(user.email);

  useEffect(() => {
    setEditingEmail(user.email);
  }, [user.email]);

  const updateUserEmail = async (email: string) => {
    if (savingEmail) return;
    setSavingEmail(true);

    if (!validateEmail(email)) {
      showNotif({
        message: 'Please enter a valid email address',
        level: 'error',
      });
      setSavingEmail(false);
      throw new Error('Please enter a valid email address');
    }

    updateUser({ primaryEmail: email }).then(() => setSavingEmail(false));
  };

  if (getIsMobile()) {
    return (
      <div>
        <SimpleFullHeightModalWrap
          {...wrapperProps}
          actions={
            <WhiteCardAction
              title="Send confirmation email"
              disabled={!validateEmail(editingEmail)}
              onClick={async () => updateUserEmail(editingEmail)}
              flashCompleteTitle="Confirmation sent!"
              onFlashComplete={closeModal}
            />
          }
        >
          <WhiteCardHeader title="Change primary email" onClose={closeModal} />
          <div style={{ textAlign: 'center', marginBottom: margins.size3 }}>
            <GrayEmailInput
              ref={inputRef}
              type="email"
              enterKeyHint="done"
              value={editingEmail}
              onChange={setEditingEmail}
              onEnter={() => updateUserEmail(editingEmail)}
              placeholder="Enter new email address"
              style={{ boxSizing: 'border-box' }}
              maxWidthMobile="1000px"
            />

            <img
              src={emailSvg}
              alt="email"
              style={{ marginBottom: margins.size3, marginTop: margins.size4 }}
            />
            <Text style={{ maxWidth: 300, margin: 'auto' }}>
              This is the address for Lunchclub communications. For Google accounts, it is also
              where we place calendar holds and scheduled meetings.
            </Text>
          </div>
          <div style={{ flex: 1 }} />
        </SimpleFullHeightModalWrap>
        <SettingsSection
          onClick={() => {
            openModal();
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }, 300);
          }}
          headingTitle={SectionsTitles.PRIMARY_EMAIL}
          selectedOptions={user.email}
        />
      </div>
    );
  }
  return (
    <>
      <SettingsOption
        title="Primary email"
        bodyComponent={
          <Body>
            This is the address for Lunchclub communications. For Google accounts, it is also where
            we place calendar holds and scheduled meetings.
          </Body>
        }
        values={[user.email]}
        loading={savingEmail}
        onChangeValue={email => email && updateUserEmail(email)}
        style={{ marginBottom: margins.size4 }}
      />
    </>
  );
};
