import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { margins, FlexColumn, borders, FlexRow, Heading2, Heading3, Text, colors } from 'css/css';

import { useMountEffect } from 'js/util/custom-hooks';
import { Avatar } from 'js/components/shared/Avatar';

import pendingMatchSvg from 'img/meeting/upcoming-match-avatar.svg';

interface PendingMatchProps {
  timeslot: string;
  initialOpen: boolean;
  userTimezone: string;
  is24Hour: boolean;
}

export const PendingMatch: React.FC<PendingMatchProps> = ({
  timeslot,
  initialOpen,
  userTimezone,
  is24Hour,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useMountEffect(() => {
    if (initialOpen) setIsOpen(true);
  });

  const cardComponent = isOpen && (
    <>
      <CardComponentContainer>
        <FlexRow style={{ marginBottom: margins.size2, width: 'fit-content' }}>
          <Avatar size2 src={pendingMatchSvg} alt="Pending match" />
          <FlexColumn alignItems="flex-start" style={{ paddingLeft: margins.size3 }}>
            <Heading3>Upcoming Match</Heading3>
            <Text color={colors.blackLight}>
              Stay tuned to find out who you’ll be meeting with next
            </Text>
          </FlexColumn>
        </FlexRow>
      </CardComponentContainer>
    </>
  );

  return (
    <Container onClick={() => setIsOpen(!isOpen)}>
      <Heading2 style={{ marginBottom: isOpen ? margins.size2 : '0px' }}>
        {moment(timeslot).format(`ddd MMM. D, ${is24Hour ? 'H:mm' : 'h:mm a'}`)} {userTimezone}
      </Heading2>
      {cardComponent}
    </Container>
  );
};

const Container = styled(FlexColumn)`
  align-items: flex-start;
  width: 600px;
  border-radius: 10px;
  border: ${borders.variation};
  background-color: ${colors.whiteMain};
  padding: ${margins.size4};
  cursor: pointer;
  margin-top: ${margins.size1};
  text-align: left;
`;

const CardComponentContainer = styled(Container)`
  width: 100%;
  padding: ${margins.size3};
  margin-bottom: ${margins.size3};
`;
