import styled from 'styled-components/macro';

import { colors, Heading2, margins, media, Text } from 'css/css';

export const Title = styled(Heading2)`
  color: ${colors.blackMain};
  margin-bottom: ${margins.size1};
`;

export const Body = styled(Text)`
  margin-bottom: ${margins.size2};
`;

export const ButtonIcon = styled.img`
  height: 27px;
  width: 27px;
  min-height: 27px;
  min-width: 24px;
`;

export const ModalContainer = styled.div`
  margin: 40px ${margins.size7} ${margins.size6} ${margins.size7};
  ${media.mobile} {
    margin: ${margins.size3};
  }
`;
