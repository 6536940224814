import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import {
  borders,
  colors,
  fonts,
  globalTransitionSettings,
  margins,
  FlexRow,
  Heading3,
  Text,
  fontSizes,
} from 'css/css';

import { Clubpoints } from 'js/components/shared/Clubpoints';
import { useOutsideAlerter, useWindowSize } from 'js/util/custom-hooks';
import { createSelectOptions, sortSelectObjects } from 'js/util/util';
import { Arrow } from 'js/components/shared/SmallArrow';
import { SelectButton, ChooseText, CheckIconWrapper } from 'js/components/shared/preferenceStyles';

import Check from 'img/weekly/check-icon.svg';

import { Option, SelectOptionType } from '../weekly/types';

import { CustomSelect } from './CustomSelect';

interface Props {
  imageSrc?: string;
  imageAlt?: string;
  questionText?: string;
  subtitle?: string;
  isSelected?: boolean;
  selectOption: (option?: any) => void;
  cost?: number;
  selectedOption?: number | string | null;
  options?: Option[];
  children?: any;
  marginBottom?: boolean;
  disabled?: boolean;
  sortObjects?: boolean;
  customCopy?: string;
  selectedNumberMeetings?: number;
  id?: number;
  shouldResetOnClick?: boolean;
  shouldOutlineSelected?: boolean;
}

export const Preference: React.FC<Props> = ({
  imageSrc,
  imageAlt,
  questionText,
  subtitle,
  isSelected,
  selectOption,
  cost,
  selectedOption = null,
  options = [],
  children,
  marginBottom = false,
  disabled = false,
  sortObjects = true,
  customCopy,
  selectedNumberMeetings,
  id,
  shouldResetOnClick = true,
  shouldOutlineSelected = true,
}) => {
  const isSelect = !!options.length;

  // Handles the highlighting and states of the button.
  const [isHovered, setIsHovered] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const containerRef = useRef<HTMLInputElement>(null);
  useOutsideAlerter(containerRef, () => setIsSelectOpen(false));
  useEffect(() => {
    setIsSelectOpen(false);
  }, [selectedOption]);

  // Magic numbers that make everything line up, useEffect adapts as screen changes.
  const [width] = useWindowSize();
  const [dropDownWidth, setDropDownWidth] = useState(590);
  const [dropDownOffset, setDropDownOffset] = useState(-451);
  const [diamondOffset, setDiamondOffset] = useState(118);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const diff = dropDownWidth - containerWidth;
      setDropDownWidth(containerWidth);
      setDropDownOffset(dropDownOffset + diff);
      setDiamondOffset(diamondOffset - diff / 2);
    }
  }, [width]);

  let selectOptions = options.map(createSelectOptions);
  if (sortObjects) selectOptions = selectOptions.sort(sortSelectObjects);
  const selectedLabel = selectOptions.find(option => option.value === selectedOption)?.label || '';
  const isActive = isSelected || isSelectOpen || isHovered || selectedLabel;

  const handleClick = () => {
    // resets the box to default if selected
    if (shouldResetOnClick && (isSelected || (selectedOption !== null && selectedLabel)))
      selectOption(null);
    else if (isSelect) setIsSelectOpen(!isSelectOpen);
    else selectOption(id);
  };

  return (
    <PreferenceContainer
      selectedLabel={selectedLabel}
      isSelected={isSelected}
      ref={containerRef}
      isActive={isActive}
      isHovered={isHovered}
      disabled={disabled}
      isSelect={isSelect}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      marginBottom={marginBottom}
      aria-checked={isSelected || selectedLabel}
      aria-haspopup={isSelect && 'listbox'}
      aria-expanded={isSelectOpen}
      $shouldOutlineSelected={shouldOutlineSelected}
    >
      <FlexRow style={{ textAlign: 'left' }}>
        {imageSrc ? (
          <PreferenceIcon src={imageSrc} alt={imageAlt} />
        ) : (
          <div style={{ width: '24px' }} />
        )}
        {isSelect && selectedLabel ? (
          <>
            <Text color={colors.blackMain}>
              {customCopy}
              {subtitle}
              <PreferenceSelectedLabel>{selectedLabel}</PreferenceSelectedLabel>
            </Text>
          </>
        ) : isSelect ? (
          <Heading3 color={colors.secondaryMid}>{questionText}</Heading3>
        ) : isSelected ? (
          <PreferenceSelectedLabel>{questionText}</PreferenceSelectedLabel>
        ) : (
          <Text color={colors.blackMain}>{questionText}</Text>
        )}
      </FlexRow>
      {isSelect ? (
        <>
          <FlexRow
            justifyContent="flex-end"
            style={{ marginRight: margins.size2, marginLeft: 'auto' }}
          >
            {!!selectedOption && !!cost && <Clubpoints clubpoints={cost} />}
            {!!selectedOption && !!selectedNumberMeetings && selectedNumberMeetings > 1 && (
              <Text style={{ marginLeft: margins.size1 }}>(x{selectedNumberMeetings})</Text>
            )}
          </FlexRow>
          <FlexRow justifyContent="space-evenly">
            <CustomSelect
              selectedLabel={selectedLabel}
              options={selectOptions}
              isSelectOpen={isSelectOpen}
              onChange={(locale: SelectOptionType) => selectOption(locale.value)}
            />
            <CheckIconWrapper>
              {selectedLabel ? <img src={Check} alt="check-icon" /> : ''}
            </CheckIconWrapper>
          </FlexRow>
        </>
      ) : (
        <>
          <CheckIconWrapper>
            {isSelected ? <img src={Check} alt="check-icon" /> : ''}
          </CheckIconWrapper>
        </>
      )}
      {children}
    </PreferenceContainer>
  );
};

type PreferenceContainerTypes = {
  isActive: boolean;
  isSelect: boolean;
  isHovered: boolean;
  marginBottom: boolean;
  disabled: boolean;
  selectedLabel: string;
  isSelected?: boolean;
  $shouldOutlineSelected?: boolean;
};
const PreferenceContainer = styled(FlexRow)<PreferenceContainerTypes>`
  transition: ${globalTransitionSettings};
  position: relative;
  padding: ${margins.size3};
  max-height: 54px;
  width: 100%;
  justify-content: space-between;
  border: ${p =>
    p.$shouldOutlineSelected && (p.isSelected || p.selectedLabel)
      ? borders.secondaryThick
      : borders.variation};
  border-radius: 5px;
  margin-bottom: ${margins.size1};
  background-color: ${p =>
    (p.isActive || p.isHovered) && !p.disabled ? colors.greyMain : colors.whiteMain};
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  overflow: visible;

  &:hover {
    background-color: ${colors.greyMain};
    ${SelectButton} {
      display: flex;
      background-color: ${colors.primaryMain};
    }
    ${ChooseText} {
      color: ${colors.whiteMain};
    }
    ${Arrow} {
      & > path {
        fill: ${colors.whiteMain};
      }
    }
    ${CheckIconWrapper} {
      display: ${p => p.isSelect && 'none'};
    }
  }
`;

const PreferenceIcon = styled.img`
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  margin-right: ${margins.size3};
`;

const PreferenceSelectedLabel = styled.span`
  font-family: ${fonts.semiBold};
  color: ${colors.blackMain};
  text-decoration: underline;
  font-size: ${fontSizes.size2};
`;
