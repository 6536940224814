import { DailyTrackState } from '@daily-co/daily-js';
import React, { useEffect, useMemo } from 'react';
import { Portal } from 'react-portal';
import styled from 'styled-components/macro';

import { useTracksContext } from 'js/components/callv2/contexts/TracksContext';

import { AudioTrack } from './AudioTrack';

type Tracks = Record<string, DailyTrackState>;

export const AudioTracks: React.FC = () => {
  const { audioTracks } = useTracksContext();

  const renderedTracks: Tracks = useMemo(
    () =>
      Object.entries(audioTracks).reduce(
        (tracks, [id, track]) => ({
          ...tracks,
          [id]: track,
        }),
        {},
      ),
    [audioTracks],
  );

  useEffect(() => {
    const playTracks = () => {
      // Assuming all elements are HTMLAudioElements
      // @ts-ignore
      document.querySelectorAll('.audioTracks audio').forEach(async (audio: HTMLAudioElement) => {
        try {
          if (audio.paused && audio.readyState === audio.HAVE_ENOUGH_DATA) {
            await audio?.play();
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Failed to play audio tracks');
        }
      });
    };

    // NOTE: IE doesn't support this
    navigator.mediaDevices.addEventListener('devicechange', playTracks);
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', playTracks);
    };
  }, []);

  return (
    <Portal key="AudioTracks">
      <Container className="audioTracks">
        {Object.entries(renderedTracks).map(([id, track]) => (
          // persistentTrack is not a field for type DailyTrackState
          // @ts-ignore
          <AudioTrack key={id} track={track.persistentTrack} />
        ))}
      </Container>
    </Portal>
  );
};

const Container = styled.div`
  position: absolute;
  visibility: hidden;
`;
