import React from 'react';
import styled from 'styled-components/macro';

import {
  Clickable,
  FlexRow,
  Heading3,
  colors,
  margins,
  FlexColumn,
  Text,
  SubText,
  media,
} from 'css/css';

import { Button } from 'js/components/shared/Button';

import calendar from 'img/chat/calendar-grey.svg';

interface Props {
  isMyMessage: boolean;
  timeslot: string;
  userTimezone: string;
  onButtonClick: (e: React.MouseEvent) => void;
  buttonText: string;
  wasButtonClicked: boolean;
  isLoading: boolean;
  openScheduler: () => void;
  isActive: boolean;
}

export const LunchclubSchedulerMessage: React.FC<Props> = ({
  isMyMessage,
  timeslot,
  userTimezone,
  onButtonClick,
  buttonText,
  wasButtonClicked,
  isLoading,
  openScheduler,
  isActive,
}) => {
  const header = 'Suggested a time';
  const body = `${`${timeslot} ${userTimezone}`}`;

  return (
    <MessageContainer>
      <ConfirmationMessage style={{ alignSelf: isMyMessage ? 'flex-end' : 'flex-start' }}>
        <img src={calendar} alt="Calendar" />
        <FlexColumn alignItems="flex-start">
          <Heading3>{header}</Heading3>
          {body && <Text>{body}</Text>}
        </FlexColumn>
      </ConfirmationMessage>

      {isActive && (
        <>
          <StyledButton
            large
            onClick={onButtonClick}
            invalid={wasButtonClicked}
            loading={isLoading}
            $wasButtonClicked={wasButtonClicked}
          >
            {buttonText}
          </StyledButton>
          {!wasButtonClicked && (
            <OtherButton
              onClick={e => {
                e.stopPropagation();
                openScheduler();
              }}
            >
              <SubText color={colors.blackMain} style={{ textDecoration: 'underline' }}>
                Find other availabilities
              </SubText>
            </OtherButton>
          )}
        </>
      )}
    </MessageContainer>
  );
};

const MessageContainer = styled(FlexColumn)`
  background-color: ${colors.greyLight};
  border-radius: 20px;
  padding: ${margins.size3};
  margin-top: ${margins.size1};
  gap: ${margins.size2};
  align-items: flex-start;
`;

const ConfirmationMessage = styled(FlexRow)`
  background-color: ${colors.greyLight};
  align-items: center;
  margin-top: ${margins.size1};
  gap: ${margins.size2};
`;

const getButtonBackgroundColor = (wasButtonClicked: boolean) =>
  wasButtonClicked ? colors.whiteMain : colors.primaryMain;

const StyledButton = styled(Button)<{ $wasButtonClicked: boolean }>`
  background-color: ${p => getButtonBackgroundColor(p.$wasButtonClicked)};
  color: ${p => (p.$wasButtonClicked ? colors.blackLight : colors.whiteMain)};
  ${media.mobile} {
    &:hover {
      background-color: ${p => getButtonBackgroundColor(p.$wasButtonClicked)};
    }
  }
`;

const OtherButton = styled(Clickable)`
  &:hover > * {
    color: ${colors.primaryMain};
  }
`;
