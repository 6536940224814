import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { colors, margins, media, FlexRow, globalTransitionSettings } from 'css/css';

import { TextInput } from 'js/components/shared/TextInput';

import searchIcon from 'img/shared/search.svg';
import searchIconLight from 'img/shared/search-light.svg';

interface Props {
  onChange?: (query: string) => void;
  onSubmit?: (query: string) => void;
  onBlur?: () => void;
  presetQuery?: string;
  placeholderText?: string;
  isDark?: boolean;
  clearOnBlur?: boolean;
}

export const SearchBar: React.FC<Props> = ({
  onChange,
  onSubmit,
  presetQuery,
  isDark,
  placeholderText,
  clearOnBlur,
  onBlur,
}) => {
  const [query, setQuery] = useState<string>(presetQuery || '');

  const handleBlur = () => {
    if (clearOnBlur) setQuery('');
    if (onBlur) onBlur();
  };

  return (
    <SearchContainer>
      <Container>
        <FlexRow style={{ position: 'relative', width: '100%', paddingLeft: margins.size2 }}>
          <StyledTextInput
            id="search_input"
            placeholder={placeholderText || 'Search'}
            onChange={e => {
              if (onChange !== undefined) onChange(e);
              setQuery(e);
            }}
            value={query}
            maxWidth="100%"
            maxWidthMobile="100%"
            onEnter={() => onSubmit && onSubmit(query)}
            type="search"
            enterKeyHint="search"
            name="search"
            $isDark={isDark}
            autoComplete="off"
            color={isDark ? colors.whiteMain : colors.blackMid}
            onBlur={handleBlur}
          />
        </FlexRow>
      </Container>
    </SearchContainer>
  );
};

const SearchContainer = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  transition: ${globalTransitionSettings};
`;

const Container = styled.div`
  width: 338px;
  overflow: hidden;
  height: fit-content;
  border-radius: 5px;

  ${media.mobile} {
    width: 100%;
  }
`;

const StyledTextInput = styled(TextInput)<{ $isDark?: boolean }>`
  background-color: ${p => (p.$isDark ? colors.blackMid : colors.greyLight)};
  background-image: url(${p => (p.$isDark ? searchIcon : searchIconLight)});
  background-size: 16px;
  background-repeat: no-repeat;
  text-indent: ${margins.size4};
  background-position: ${margins.size3} center;
  transition: ${globalTransitionSettings};
  font-weight: 800;

  &::placeholder {
    opacity: 50%;
  }
  &:focus {
    background-image: none;
    background-size: 0px;
    text-indent: 0px;
  }
  &:-webkit-autofill {
    background-size: 0px;
    background-image: none;
    text-indent: 0px;
  }
`;
