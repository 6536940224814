import React from 'react';
import styled from 'styled-components/macro';

import { colors, Text, Clickable, media } from 'css/css';
import { underlineAnimation } from 'css/animations';

export const ViewAll = ({ onClick, noDisplay, noMobileDisplay, viewingAll }: any) => {
  return (
    <ViewAllClickable noDisplay={noDisplay} onClick={onClick} noMobileDisplay={noMobileDisplay}>
      <AnimatedText bold color={colors.primaryMain}>
        {viewingAll ? 'view less' : 'view all'}
      </AnimatedText>
    </ViewAllClickable>
  );
};

const AnimatedText = styled(Text)`
  ${underlineAnimation}
`;

type ViewAllClickableTypes = { noDisplay: boolean; noMobileDisplay: boolean };
const ViewAllClickable = styled(Clickable)<ViewAllClickableTypes>`
  color: ${colors.primaryMain};
  display: ${p => (p.noDisplay ? 'none' : 'inline')};
  ${media.mobile} {
    display: ${p => (p.noMobileDisplay ? 'none' : 'inline')};
  }

  :focus {
    outline: none;
  }
`;
