import React, { useEffect, useState } from 'react';
import format from 'string-format';
import styled from 'styled-components/macro';

import { Card, colors, Heading1, margins, media, Text } from 'css/css';

import { Invitee, InviteSuggestionResponse } from 'types/invites';

import { APIFailure } from 'js/components/shared/APIFailure';
import { GreyPage } from 'js/components/shared/page-wrappers';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import {
  getInvitees,
  getPastInvitees,
  postDeclineInvitees,
  postIntroResponse,
  postInvitees,
  updateMobileContactsInvitee,
} from 'js/util/api';
import { useDebounce, useMountEffect } from 'js/util/custom-hooks';
import { invitePage } from 'js/util/strings';
import { useNotifContext } from 'js/util/notif-context';
import {
  getIsIOSApp,
  getIsMobile,
  getParameterByName,
  INTRO_RESPONSES,
  INVITE_SOURCES,
  scrollToTopIfNeeded,
} from 'js/util/util';
import { useDataContext } from 'js/providers/DataContextProvider';

import clubpointsSvg from 'img/navbar/clubpoints.svg';

import { InviteGadget } from './InviteGadget';
import { EditInvite } from './EditInvite';
import { InviteSuggestions } from './InviteSuggestions';
import { InviteSuggestionsMobileEmail } from './InviteSuggestionsMobileEmail';
import { InviteSuggestionsMobileContacts } from './InviteSuggestionsMobileContacts';
import { PastInvites } from './PastInvites';
import { InviteMobileTab } from './InviteMobileTab';
import {
  getInviteSuggestionsCache,
  setInviteSuggestionsCache,
  MAX_DISPLAYED_INVITE_SUGGESTIONS,
} from './utils';

const PREFILLED_INVITE_NOTE =
  "Hey, I've been using Lunchclub for a while and thought you would like it as well!";

interface Props {
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  isModal?: boolean;
}

export const Invite: React.FC<Props> = ({ isModal, setIsLoading }) => {
  const { fetchUser } = useUserContextProvider();
  const { showNotif } = useNotifContext();

  const user = useUser();

  const { invite_code: inviteCode, first_name: firstName, clubpoints } = user;
  const isMobile = getIsMobile();
  const isIOSApp = getIsIOSApp();

  const MAX_LOADED_INVITE_SUGGESTIONS = isMobile ? 30 : MAX_DISPLAYED_INVITE_SUGGESTIONS * 2;

  const [selectedTab, setSelectedTab] = useState<'email' | 'contact'>('email');

  const [confirmedInviteAll, setConfirmedInviteAll] = useState(
    !!user.visual_settings?.confirmed_invite_all,
  );
  const [inviteSuggestions, setInviteSuggestions] = useState<Invitee[]>([]);
  const [pastInvites, setPastInvites] = useState([]);

  const [inviteSuggestionsLoading, setInviteSuggestionsLoading] = useState(true);
  const [nInviteSuggestionsLoading, setNInviteSuggestionsLoading] = useState(false);
  const [pastInvitesLoading, setPastInvitesLoading] = useState(true);
  const [invitingAll, setInvitingAll] = useState(false);
  const [hasLoadedAllSuggestions, setHasLoadedAllSuggestions] = useState(false);

  const [excludedEmails, setExcludedEmails] = useState<string[]>([]);
  const [apiFailure, setApiFailure] = useState(false);

  const [inviteNote, setInviteNote] = useState(PREFILLED_INVITE_NOTE);

  const { navbarIconClicked, setNavbarIconClicked } = useDataContext();
  const debouncedInviteSuggestions = useDebounce(inviteSuggestions.length, 3000);

  const inviteLink = format(invitePage.link, inviteCode);
  const inviteText = `${firstName} has invited you to join the club! Sign up for Lunchclub:`;

  const sendMobileInvite = async (phoneNumber: string) => {
    const res = await updateMobileContactsInvitee(phoneNumber, InviteSuggestionResponse.INVITED);
    let link = inviteLink; // offline invite link
    if (res.status === 200) {
      const { invite_link: pondInviteLink } = res.getJson;
      if (pondInviteLink) {
        link = pondInviteLink;
      }
    }
    window.location.href = `sms:${phoneNumber}&body=${inviteText} ${link}`;
  };

  useMountEffect(() => {
    handleInviteEmailParam();
    handleIntroEmailParam();
    loadMaxInviteSuggestions(false, true);
    loadPastInvites();
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    scrollToTopIfNeeded(navbarIconClicked, 'invite', null, setNavbarIconClicked);
  }, [navbarIconClicked]);

  useEffect(() => {
    if (inviteSuggestionsLoading || invitingAll || nInviteSuggestionsLoading) return;
    if (debouncedInviteSuggestions < MAX_LOADED_INVITE_SUGGESTIONS) {
      loadNInviteSuggestions(MAX_LOADED_INVITE_SUGGESTIONS - inviteSuggestions.length);
    }
  }, [debouncedInviteSuggestions]);

  const handleInviteEmailParam = () => {
    const inviteEmail = getParameterByName('invite_email');
    if (inviteEmail) {
      const note = getParameterByName('note');
      const inviteSource = getParameterByName('invite_source');
      if (note && inviteSource) {
        sendInviteEmail({
          emails: [inviteEmail],
          customNote: note,
          source: parseInt(inviteSource, 10),
        });
      } else if (inviteSource) {
        sendInviteEmail({
          emails: [inviteEmail],
          source: parseInt(inviteSource, 10),
        });
      } else {
        sendInviteEmail({ emails: [inviteEmail], source: INVITE_SOURCES.POND });
      }
    }
  };

  const handleIntroEmailParam = () => {
    const suggestionId = getParameterByName('suggestion_id');
    const declineSuggestionId = getParameterByName('decline_suggestion_id');
    if (suggestionId) {
      postIntroResponse({
        responseId: INTRO_RESPONSES.ACCEPT,
        suggestionId: parseInt(suggestionId, 10),
      });
    } else if (declineSuggestionId) {
      postIntroResponse({
        responseId: INTRO_RESPONSES.REJECT,
        suggestionId: parseInt(declineSuggestionId, 10),
      });
    }
  };

  const loadMaxInviteSuggestions = async (isShuffling = false, canUseCache = false) => {
    setInviteSuggestionsLoading(true);
    if (canUseCache && getInviteSuggestionsCache(setInviteSuggestions)) {
      setInviteSuggestionsLoading(false);
      if (setIsLoading) {
        setIsLoading(false);
      }
      return;
    }

    const res = await getInvitees({
      inviteeNum: MAX_LOADED_INVITE_SUGGESTIONS,
      excludeEmails: extractEmails(inviteSuggestions),
      isShuffling,
    });
    if (validateStatus(res.status)) {
      setInviteSuggestions(res.getJson);
      setInviteSuggestionsCache(res.getJson);
    } else {
      setApiFailure(true);
    }
    setInviteSuggestionsLoading(false);
    if (setIsLoading) {
      setIsLoading(false);
    }
  };

  const loadPastInvites = async () => {
    const res = await getPastInvitees();
    if (validateStatus(res.status)) {
      setPastInvites(res.getJson.invitees);
    } else {
      setApiFailure(true);
    }
    setPastInvitesLoading(false);
  };

  const extractEmails = (users: Invitee[]) => users.map(u => u.email);

  const validateStatus = (status: number) => status >= 200 && status < 400;

  const loadNInviteSuggestions = async (n: number) => {
    if (nInviteSuggestionsLoading) return;
    setNInviteSuggestionsLoading(true);
    const res = await getInvitees({
      inviteeNum: n,
      excludeEmails: [...excludedEmails.slice(-10), ...extractEmails(inviteSuggestions)],
    });
    if (validateStatus(res.status)) {
      if (!res.getJson.length) {
        setHasLoadedAllSuggestions(true);
      }
      setInviteSuggestions([...inviteSuggestions, ...res.getJson]);
      setInviteSuggestionsCache([...inviteSuggestions, ...res.getJson]);
    } else setApiFailure(true);
    setNInviteSuggestionsLoading(false);
  };

  const removeFromInvitees = (emails: string[]) => {
    const newInvitees = inviteSuggestions.filter(x => !emails.includes(x.email));
    setExcludedEmails([...excludedEmails, ...emails]);
    setInviteSuggestions(newInvitees);
    setInviteSuggestionsCache(newInvitees);
  };

  const inviteAll = async (inviteesList: Invitee[]) => {
    if (invitingAll) return;
    setInvitingAll(true);
    sendInviteEmail({
      emails: inviteesList.map(invitee => invitee.email),
      customNote: inviteNote,
      source: INVITE_SOURCES.POND_ALL,
    });
    setInvitingAll(false);
  };

  const sendInviteEmail = async ({
    emails,
    customNote,
    source,
  }: {
    emails: string[];
    customNote?: string;
    source: number;
  }) => {
    const note = customNote || inviteNote;
    removeFromInvitees(emails);
    if (emails.length === 1 && excludedEmails?.includes(emails[0])) {
      return;
    }
    setExcludedEmails([...excludedEmails, ...emails]);
    showNotif({
      message: `Sending ${emails.length > 1 ? 'invites' : 'invite'} to ${emails.join(', ')}`,
      level: 'success',
    });
    const res = await postInvitees({
      emails,
      source,
      note,
    });
    if (!validateStatus(res.status)) setApiFailure(true);
    else fetchUser();
  };

  const skipInvite = async (email: string) => {
    if (!excludedEmails.includes(email)) {
      postDeclineInvitees([email]);
    }
    removeFromInvitees([email]);
  };

  if (apiFailure) {
    return (
      <GreyPage>
        <APIFailure />
      </GreyPage>
    );
  }

  if (isMobile) {
    return (
      <GreyPage
        shouldDisplayNavbar={!isModal}
        fixedFullHeight
        style={{
          display: 'flex',
          backgroundColor: isIOSApp ? colors.greyMain : colors.whiteMain,
          flexDirection: 'column',
          padding: 0,
        }}
      >
        <InviteFriendsHeading
          style={{
            textAlign: 'center',
            margin: isModal ? 0 : '0 30px',
          }}
        >
          <Heading1 style={{ marginTop: isModal ? 0 : margins.size4 }}>
            Invite your friends
          </Heading1>
          <p style={{ color: colors.blackLight }}>
            Get 5 Clubpoints when they join, and 5 more when they take a meeting!
          </p>
          <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <img src={clubpointsSvg} alt="points" style={{ width: 18, marginRight: 10 }} />
            <span style={{ color: colors.blackLight }}> You have {clubpoints} Clubpoints</span>
          </div>
        </InviteFriendsHeading>
        <Text style={{ marginBottom: margins.size3 }} />
        {isIOSApp && <InviteMobileTab selectedTab={selectedTab} setSelectedTab={setSelectedTab} />}
        <InviteGadget
          isMobile
          inviteLink={inviteLink}
          inviteText={inviteText}
          sendInvite={(email: string) =>
            sendInviteEmail({ emails: [email], source: INVITE_SOURCES.MANUAL })
          }
          sendMobileInvite={sendMobileInvite}
          isEmailTab={selectedTab === 'email'}
        />
        {selectedTab === 'email' ? (
          <>
            <InviteSuggestionsMobileEmail
              isLoading={inviteSuggestionsLoading}
              isLoadingMoreInvites={nInviteSuggestionsLoading}
              inviteSuggestions={inviteSuggestions}
              loadNInviteSuggestions={loadNInviteSuggestions}
              hasLoadedAllSuggestions={hasLoadedAllSuggestions}
              skipInvite={skipInvite}
              sendInvite={(email: string) =>
                sendInviteEmail({ emails: [email], source: INVITE_SOURCES.POND })
              }
            />
          </>
        ) : (
          <InviteSuggestionsMobileContacts sendMobileInvite={sendMobileInvite} />
        )}
      </GreyPage>
    );
  }

  return (
    <GreyPage>
      <StyledCard>
        <>
          <StyledHeading style={{ textAlign: 'left' }}>{invitePage.header}</StyledHeading>

          <Text style={{ marginBottom: margins.size4, textAlign: 'left' }}>
            Invite your friends, earn 5 Clubpoints when they sign up, and earn 5 more when they take
            their first meeting.
          </Text>

          <InviteGadget
            inviteText={inviteText}
            inviteLink={inviteLink}
            sendInvite={(email: string) =>
              sendInviteEmail({ emails: [email], source: INVITE_SOURCES.MANUAL })
            }
            sendMobileInvite={sendMobileInvite}
            isEmailTab
          />

          <EditInvite setInviteNote={setInviteNote} inviteNote={inviteNote} />
        </>
      </StyledCard>
      {!!inviteSuggestions?.length && (
        <InviteSuggestions
          loading={inviteSuggestionsLoading}
          inviteSuggestions={inviteSuggestions}
          sendInvite={(email: string) =>
            sendInviteEmail({ emails: [email], source: INVITE_SOURCES.POND })
          }
          inviteAll={inviteAll}
          skipInvite={skipInvite}
          shuffleInviteSuggestions={() => loadMaxInviteSuggestions(true, false)}
          inviteAllLoading={invitingAll}
          confirmedInviteAll={confirmedInviteAll}
          setConfirmedInviteAll={setConfirmedInviteAll}
          MAX_DISPLAYED_INVITE_SUGGESTIONS={MAX_DISPLAYED_INVITE_SUGGESTIONS}
          MAX_LOADED_INVITE_SUGGESTIONS={MAX_LOADED_INVITE_SUGGESTIONS}
        />
      )}

      <PastInvites pastLoading={pastInvitesLoading} pastInvitees={pastInvites} />
    </GreyPage>
  );
};

type StyledCardTypes = {
  loading?: boolean;
  hasNoScroll?: boolean;
};
const StyledCard = styled(Card)<StyledCardTypes>`
  width: 100%;
  max-width: 872px;
  margin-bottom: ${margins.size2};
  min-height: ${p => (p.loading ? '390px' : '')};
  padding: 30px 175px;
  @media screen and (max-width: 1050px) {
    padding: ${margins.size6};
  }
  ${media.mobile} {
    padding: ${margins.size4};
    ${p => p.loading && 'min-height: 530px;'}
    ${p => p.hasNoScroll && 'height: calc(100vh - 108px); overflow: hidden;'}
  }
`;

const StyledHeading = styled(Heading1)`
  margin-bottom: ${margins.size3};
`;

const InviteFriendsHeading = styled.div`
  ${media.mobile} {
    padding-top: env(safe-area-inset-top);
  }
`;
