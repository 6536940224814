import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';

import {
  colors,
  margins,
  FlexRow,
  Heading3,
  SubText,
  Heading2,
  Clickable,
  FlexColumn,
} from 'css/css';

import { Interests, UserInterest, GroupedInterests } from 'types/interests';
import { ProductUser } from 'types/user';

import { TextInput } from 'js/components/shared/TextInput';
import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { useUser, useUserContextProvider } from 'js/providers/UserProvider';
import { getInterests } from 'js/util/api';
import { useMountEffect, useOutsideAlerter } from 'js/util/custom-hooks';
import { useNotifContext } from 'js/util/notif-context';

import DeleteInterest from 'img/profile/cross.svg';
import InterestsIcon from 'img/profile/interests.svg';

import { InterestsDropdown } from './InterestsDropdown';
import { LineBreak } from './shared';

export const ProfileInterests: React.FC<{ editing?: boolean; passedUser?: ProductUser }> = ({
  editing,
  passedUser,
}) => {
  const { showNotif } = useNotifContext();
  const { updateUser } = useUserContextProvider();
  const user = useUser();
  const { interests: userInterests } = passedUser || user;

  const [submitingInterests, setSubmittingInterests] = useState(false);
  const [newInterest, setNewInterest] = useState('');
  const [allInterests, setAllInterests] = useState<GroupedInterests>({});
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [filteredInterests, setFilteredInterests] = useState<GroupedInterests>({});

  const userInterestsRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(containerRef, () => setIsSelectOpen(false));

  const dropdownOffset = !userInterestsRef.current ? 0 : userInterestsRef.current.clientHeight + 79;

  const submitCleanup = () => {
    setNewInterest('');
    setSubmittingInterests(false);
  };

  const updateInterest = (interestStr: any) => {
    if (submitingInterests || !(newInterest || interestStr)) return null;
    setSubmittingInterests(true);
    const interestNames = userInterests.map((interest: any) => interest.name.toLowerCase());
    const cleanedString: string = interestStr.toLowerCase().trim();
    setFilteredInterests({});
    if (interestNames.includes(cleanedString)) {
      if (userInterests.length === 1) {
        showNotif({ message: 'You must have at least one interest.', level: 'error' });
        return submitCleanup();
      }
      return updateUser({
        interests: interestNames.filter((interest: any) => interest !== cleanedString),
      }).then(() => submitCleanup());
    }
    return updateUser({
      interests: [...(interestNames as string[]), cleanedString],
    }).then(() => submitCleanup());
  };

  useMountEffect(() => {
    const fetchInterests = async () => {
      const res = await getInterests();
      if (res.ok) {
        setAllInterests(res.getJson);
      }
    };
    fetchInterests();
  });

  const matches = (keyword: string, text: string) => {
    if (keyword.length === 0) {
      return false;
    }
    return text.toLowerCase().includes(keyword.toLowerCase());
  };

  const filterRequirement = (interest: Interests, newUserInput: string) => {
    const key = interest.name;
    if (matches(newUserInput, key)) return true;
    return false;
  };

  const onChange = (newUserInput: string) => {
    if (!newUserInput) {
      setFilteredInterests({});
    } else {
      const userInterestIds = userInterests.map((interest: UserInterest) => interest.id);
      const newFilteredInterests: { [key: string]: Interests[] } = {};
      Object.entries(allInterests).forEach(([category, interestsList]) => {
        newFilteredInterests[category] = interestsList.filter(
          interest =>
            filterRequirement(interest, newUserInput) && !userInterestIds.includes(interest.id),
        );
      });
      setFilteredInterests(newFilteredInterests);
    }
    setNewInterest(newUserInput);
  };

  if (!userInterests?.length) return <SVGLoader />;

  if (editing)
    return (
      <>
        <Heading2 style={{ marginBottom: margins.size3 }}>Interests</Heading2>

        <FlexRow $wrap ref={userInterestsRef}>
          {userInterests.map((interest: UserInterest) => (
            <Clickable noOutline key={interest.id} onClick={() => updateInterest(interest.name)}>
              <InterestButton>
                <FlexRow>
                  <img style={{ marginRight: margins.size1 }} src={DeleteInterest} alt="" />
                  <InterestText>{interest.name}</InterestText>
                </FlexRow>
              </InterestButton>
            </Clickable>
          ))}
        </FlexRow>

        <FlexColumn ref={containerRef}>
          <TextInput
            height="40px"
            placeholder="Add another interest"
            value={newInterest}
            onChange={onChange}
            onEnter={() => updateInterest(newInterest)}
            maxWidth="100%"
            onClick={() => setIsSelectOpen(!isSelectOpen)}
          />
          {isSelectOpen && (
            <InterestsDropdown
              allInterests={allInterests}
              updateInterest={updateInterest}
              userInterests={userInterests}
              dropdownOffset={dropdownOffset}
              filteredInterests={filteredInterests}
            />
          )}
        </FlexColumn>

        <LineBreak />
      </>
    );
  if (userInterests && userInterests.length)
    return (
      <>
        <FlexRow style={{ marginBottom: margins.size2 }}>
          <img style={{ marginRight: margins.size3 }} src={InterestsIcon} alt="" />

          <Heading3>I&apos;m interested in</Heading3>
        </FlexRow>

        <IndentedRow $wrap>
          {userInterests.map((interest: any) => (
            <InterestButton key={interest.id}>
              <InterestText>{interest.name}</InterestText>
            </InterestButton>
          ))}
        </IndentedRow>
      </>
    );

  if ((!userInterests || !userInterests.length) && !passedUser)
    return <Heading3 color={colors.secondaryMid}>Click edit to add interests.</Heading3>;

  return null;
};

const IndentedRow = styled(FlexRow)`
  position: relative;
  left: ${margins.size4};
  margin-left: ${margins.size3};
  margin-right: ${margins.size4};
  margin-bottom: ${margins.size4};
`;

const InterestButton = styled.div`
  margin-bottom: ${margins.size1};
  margin-right: ${margins.size1};
  cursor: default;
  border: none;
  padding: 14px 8px;
  background-color: ${colors.secondaryLight};
  border-radius: 5px;
  height: 24px;
  display: flex;
  align-items: center;
  :focus {
    outline: none;
  }
`;

const InterestText = styled(SubText)`
  color: ${colors.secondaryMid};
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap;
`;
