import {
  useEffect,
  useRef,
  useLayoutEffect,
  useState,
  useCallback,
  EffectCallback,
  DependencyList,
} from 'react';

export const useInterval = (callback: any, delay: any) => {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (delay !== null && savedCallback.current !== null) {
      const tick = () => savedCallback.current && savedCallback.current();
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => undefined;
  }, [delay]);
};

export const useTimeout = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return undefined;
    }

    const id = setTimeout(() => savedCallback.current(), delay);

    return () => clearTimeout(id);
  }, [delay]);
};

// Only run this effect on initial mount (~componentDidMount)
export const useMountEffect = (fun: EffectCallback) => useEffect(fun, []);

// Run this effect only after the initial render (~componentDidUpdate)
export const useDidUpdateEffect = (fun: EffectCallback, inputs: DependencyList) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return fun();
    }
    didMountRef.current = true;
    return undefined;
  }, inputs);
};

// Hook that runs function on clicks outside of the passed ref
export const useOutsideAlerter = (ref: any, handleClick: any) => {
  const handleClickOutside = (e: any) => {
    if (ref?.current && !ref.current.contains(e.target)) handleClick();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

// Hook that runs on window resize
export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const useDebounce = (value: any, delay: any) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

// Hook that calls when user scrolls to the bottom
export const useBottomScrollListener = (onBottom: any, offset = 0) => {
  const containerRef = useRef(null);
  const handleOnScroll = useCallback(() => {
    if (containerRef.current != null) {
      const scrollNode = containerRef.current;
      const scrollContainerBottomPosition = Math.round(
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        scrollNode.scrollTop + scrollNode.clientHeight,
      );
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const scrollPosition = Math.round(scrollNode.scrollHeight - offset);

      if (scrollPosition <= scrollContainerBottomPosition) onBottom();
    } else {
      const scrollNode = document.scrollingElement || document.documentElement;
      const scrollContainerBottomPosition = Math.round(scrollNode.scrollTop + window.innerHeight);
      const scrollPosition = Math.round(scrollNode.scrollHeight - offset);

      if (scrollPosition <= scrollContainerBottomPosition) onBottom();
    }
  }, [offset, onBottom]);

  useEffect(() => {
    const ref = containerRef.current;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'addEventListener' does not exist on type... Remove this comment to see the full error message
    if (ref != null) ref.addEventListener('scroll', handleOnScroll);
    else window.addEventListener('scroll', handleOnScroll);

    return () => {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      if (ref != null) ref.removeEventListener('scroll', handleOnScroll);
      else window.removeEventListener('scroll', handleOnScroll);
    };
  }, [handleOnScroll]);

  return containerRef;
};

// courtesy of https://stackoverflow.com/a/56406823
export const useWindowActive = () => {
  const [windowIsActive, setWindowIsActive] = useState<boolean>(true);
  const handleActivity = (forcedFlag: any) => {
    if (typeof forcedFlag === 'boolean') {
      setWindowIsActive(forcedFlag);
    } else {
      setWindowIsActive(document.visibilityState === 'visible');
    }
  };

  useEffect(() => {
    const handleActivityFalse = () => handleActivity(false);
    const handleActivityTrue = () => handleActivity(true);

    document.addEventListener('visibilitychange', handleActivity);

    document.addEventListener('focus', handleActivityTrue);
    document.addEventListener('blur', handleActivityFalse);

    window.addEventListener('focus', handleActivityTrue);
    window.addEventListener('blur', handleActivityFalse);

    return () => {
      window.removeEventListener('blur', handleActivity);
      window.removeEventListener('focus', handleActivityFalse);

      document.removeEventListener('blur', handleActivityFalse);
      document.removeEventListener('focus', handleActivityTrue);

      document.removeEventListener('visibilitychange', handleActivityTrue);
    };
  }, []);

  return windowIsActive;
};

export const useMove = () => {
  const [mouseLocation, setMouseLocation] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e: React.MouseEvent) => {
    e.persist();
    setMouseLocation({ x: e.clientX, y: e.clientY });
  };
  return {
    x: mouseLocation.x,
    y: mouseLocation.y,
    handleMouseMove,
  };
};
