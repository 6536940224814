export const EVENT_TYPES = {
  MEETING: 0,
  JOINED: 1,
  PENDING: 2,
  SUCCESS_STORY: 3,
  ANNIVERSARY: 4,
  MATCH_EXPERIENCE: 5,
  STREAK: 6,
};

export const SEARCH_CARD_TYPE = {
  USER: 0,
  PHANTOM: 1,
};

export const USER_PAGE_LINK = '/member/';
