import React, { useEffect, useState, createRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { colors, FlexRow, Heading3, margins } from 'css/css';

import { GroupedInterests, Interests } from 'types/interests';

import { SVGLoader } from 'js/components/shared/loaders/SVGLoader';
import { useResource } from 'js/util/use-resource';

import {
  business,
  invest,
  lifestyle,
  socialImpact,
  sports,
  tech,
} from 'img/registration-v2/interests';

import { RegistrationStepProps } from '../MobileRegistrationContent';

import { OnboardingFlowPage } from './OnboardingFlowPage';
import { ProgressBar } from './ProgressBar';
import {
  NUX_HEADER_STEPS,
  ObjectivesAndInterestsDecs,
  OnboardingSteps,
  StepMobileContainer,
} from './shared';

const MobileInterestIcons: { [title: string]: string } = {
  Business: business,
  'Science & Tech': tech,
  Lifestyle: lifestyle,
  'Social Causes': socialImpact,
  'Sports & Entertainment': sports,
  'Investing & Finance': invest,
};

export const StepInterestsMobile: React.FC<RegistrationStepProps> = ({
  values,
  setValues,
  submitUserInfo,
}) => {
  const history = useHistory();

  const onNext = async () => {
    await submitUserInfo(
      { interests: values.selectedInterests.map(interest => interest.name) },
      () => {
        history.push(`/registration/profile-picture${history.location.search}`);
      },
    );
  };

  return (
    <OnboardingFlowPage
      step={OnboardingSteps.ObjectivesAndInterests}
      onNext={onNext}
      onPrev={() => history.push(`/registration/objectives${history.location.search}`)}
      nextDisabled={values.selectedInterests.length === 0}
      title={NUX_HEADER_STEPS.GoalsAndInterests.title}
      subtitle={NUX_HEADER_STEPS.GoalsAndInterests.subtitle}
    >
      <InterestsGallery
        selected={values.selectedInterests}
        setSelected={selectedInterests => setValues({ ...values, selectedInterests })}
      />
    </OnboardingFlowPage>
  );
};

export const InterestsGallery: React.FC<{
  selected: Interests[];
  setSelected: (items: Interests[]) => void;
}> = ({ selected, setSelected }) => {
  const [interests] = useResource<GroupedInterests>('discover/interests');
  const [expandedSection, setExpandedSection] = useState<null | string>(null);

  useEffect(() => {
    if (interests) {
      setExpandedSection(Object.keys(interests)[0]);
    }
  }, [interests]);

  return (
    <StepMobileContainer style={{ overflowY: 'scroll' }}>
      <ProgressBar numCompleted={1} total={2} />
      <Heading3 style={{ alignSelf: 'center', paddingBottom: margins.size3 }}>
        {ObjectivesAndInterestsDecs.Interests.title}
      </Heading3>
      {interests ? (
        <InterestsContainer>
          {Object.entries(interests).map(([section, items]) => (
            <InterestSection
              key={section}
              title={section}
              items={items}
              expanded={expandedSection === section}
              onToggleExpanded={() =>
                setExpandedSection(expandedSection === section ? null : section)
              }
              selected={selected}
              onToggleItem={item =>
                setSelected(
                  selected.some(i => i.id === item.id)
                    ? selected.filter(v => v.id !== item.id)
                    : [...selected, item],
                )
              }
            />
          ))}
        </InterestsContainer>
      ) : (
        <SVGLoader />
      )}
    </StepMobileContainer>
  );
};

const InterestSection: React.FC<{
  title: string;
  expanded: boolean;
  onToggleExpanded: () => void;
  items: Interests[];
  selected: Interests[];
  onToggleItem: (item: Interests) => void;
}> = ({ title, selected, items, onToggleItem, expanded, onToggleExpanded }) => {
  const selectedCount = items.filter(i => selected.some(s => s.id === i.id)).length;
  const expandingRef = createRef<HTMLDivElement>();
  const [expandedHeight, setExpandedHeight] = useState(0);

  // Measuring the size of the expanded area is necessary because the hero image within the section
  // stretches to fill the width of the screen and it's not clear how tall it is. You can't animate
  // from 0px tall to "auto" pixels tall - we need to know what our animation target size is in px.
  useEffect(() => {
    if (!expandingRef.current) {
      return;
    }
    const height = expandingRef.current.scrollHeight;
    if (height !== expandedHeight) {
      setExpandedHeight(height);
    }
  }, [setExpandedHeight, expandingRef, expandedHeight]);

  return (
    <InterestSectionContainer $isOpen={expanded}>
      <InterestSectionHeader
        role="button"
        tabIndex={0}
        style={{ outline: 'none' }}
        onClick={onToggleExpanded}
      >
        <FlexRow>
          <img
            src={MobileInterestIcons[title] || ''}
            alt={MobileInterestIcons[title] || ''}
            style={{ marginRight: margins.size3 }}
          />
          <Heading3>{title}</Heading3>
        </FlexRow>
        {selectedCount > 0 && <SectionCount $isExpanded={expanded}>{selectedCount}</SectionCount>}
      </InterestSectionHeader>
      <InterestItemsExpandedArea
        style={{ maxHeight: expanded ? expandedHeight : 0 }}
        ref={expandingRef}
      >
        <InterestItemsTray>
          {items.map(item => (
            <InterestItem
              key={item.id}
              selected={selected.some(i => i.id === item.id)}
              onClick={() => onToggleItem(item)}
            >
              {item.name.toLowerCase()}
            </InterestItem>
          ))}
        </InterestItemsTray>
      </InterestItemsExpandedArea>
    </InterestSectionContainer>
  );
};

const InterestsContainer = styled.div`
  overflow-y: scroll;
  padding-bottom: 80px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const InterestItemsExpandedArea = styled.div`
  display: block;
  transition: max-height 200ms ease-out;
  overflow-y: hidden;
  margin-left: -${margins.size2};
`;

const InterestItemsTray = styled(FlexRow)`
  flex-wrap: wrap;
  scrollbar-color: transparent;
  scrollbar-width: none;
  padding-top: ${margins.size2};
  padding-bottom: ${margins.size4};
  ::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar:horizontal {
    height: 0;
  }
`;

const InterestSectionHeader = styled.div`
  padding-bottom: ${margins.size3};
  display: flex;
  justify-content: space-between;
`;

const InterestSectionContainer = styled.div<{ $isOpen: boolean }>`
  background: ${p => (p.$isOpen ? colors.greyMain : colors.whiteMain)};
  padding: ${margins.size3};
  padding-bottom: 0;
  user-select: none;

  &:first-child {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  &:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    ${InterestItemsTray} {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
`;

export const InterestItem = styled.div<{ selected: boolean }>`
  display: inline-flex;
  background: ${({ selected }) => (selected ? colors.blackMain : colors.whiteMain)};
  color: ${({ selected }) => (selected ? colors.whiteMain : colors.blackMain)};
  padding: 10px ${margins.size3};
  border-radius: 8px;
  margin-right: ${margins.size1};
  margin-bottom: ${margins.size1};
  align-items: center;
`;

const SectionCount = styled.div<{ $isExpanded: boolean }>`
  text-align: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  line-height: 24px;
  color: ${p => (p.$isExpanded ? colors.blackMain : colors.whiteMain)};
  font-size: 14px;
  background: ${p => (p.$isExpanded ? colors.whiteMain : colors.secondaryMain)};
  animation: SubtlePop 300ms;

  @keyframes SubtlePop {
    0% {
      transform: scale(0.5);
      opacity: 0.6;
    }
    40% {
      transform: scale(1.2) rotate(12deg);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;
